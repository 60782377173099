import Ajv from 'ajv'

const ajv = new Ajv()

export function ensureDataValidatesAgainstSchema(data: any, schema: object, contextDescription: string) {
  const { ok, error } = validateDataAgainstSchema(data, schema)

  if (!ok) {
    throw new Error(`Data not matching schema: ${error.toString()} in ${contextDescription}`)
  }
}

export function validateDataAgainstSchema(data: any, schema: object) {
  const isValid = ajv.validate(schema, data)

  if (isValid) {
    return { 
      ok: true,
    }
  } else {
    return {
      ok: false,
      error: ajv.errorsText()
    }
  }
}
