export default function createEventForwarders(events, source) {
  const forwarders = {}

  for (const eventName in events) {
    forwarders[eventName] = (...args) => {
      source.$emit(eventName, ...args)
    }
  }
  
  return forwarders
}
