import { runDynamicCode, CompilationError } from '@aeppic/shared/dynamic/dynamic-code'
// import Vue from '../../externals/vue.js'

import type { AmdOptions, NamedExports } from './types.js'

export { CompilationError }

export interface ScriptExports {
  exports: NamedExports
}

export function importCommonJSExportsFromScript(code: string, options: { scriptIdentifier: string, globals?: NamedExports, requires?: NamedExports }): NamedExports {
  const require = buildScriptRequireFunction({ globals: options.globals, requires: options.requires })
  const module: ScriptExports = { exports: {} }

  const injectedGlobals = {
    ...options.globals,
    module,
    exports: module.exports,
    require,
  }

  runDynamicCode(`packages`, options.scriptIdentifier, code, injectedGlobals)

  if (module.exports?.__esModule && module.exports?.default) {
    return module.exports.default
  }

  return module.exports
}

function buildScriptRequireFunction(options: { globals?: NamedExports, requires?: NamedExports }) {
  const originalRequire = options.globals?.require ?? options.globals?.window?.require

  const require: any = function require(name: any, dependencies: any, callback: any) {   
    if (options.requires && name in options.requires) {
      return options.requires?.[name]
    }

    return originalRequire?.(name, dependencies, callback)
  }

  return require

  // if (!options?.amd) {
  //   return require
  // }

  // require.config = (config: any) => {
  //   const cfg = JSON.parse(JSON.stringify(config))
    
  //   if (Number.isNaN(Number(cfg.waitSeconds))) {
  //     cfg.waitSeconds = 0 // 0: disable timeout
  //   }

  //   return originalRequire.config(cfg)
  // }

  // require.nextTick = (fn: any) => {
  //   return originalRequire.nextTick(fn)
  // }

  // if (options?.amd?.config) {
  //   const newConfig = {...options.amd.config}
    
  //   if (newConfig.paths) {
  //     for (let key in newConfig.paths) {
  //       if (newConfig.paths.hasOwnProperty(key)) {
  //         newConfig.paths[key] = `package://${encodeURI(options.packageIdentifier)}/${newConfig.paths[key]}`
  //       }
  //     }
  //   }
  //   console.log('AMD')
  //   console.dir(options.amd.config)
  //   console.dir(newConfig)
    
  //   debugger

  //   require.config(newConfig)
  // }

  // return require
}
