import * as Types from '@aeppic/types'

import { waitForNextTick } from '@aeppic/shared/wait-for-tick'
import { EventEmitter } from '@aeppic/shared/event-emitter'
import { UniqueItemProcessingQueue } from '@aeppic/shared/processing-queue'
import { buildDeferred, Deferred } from '@aeppic/shared/defer'

import { IServerAdapter, IDocumentLookup } from '../shared/types/server-adapter.js'
import { Change, FindOptions, getDocumentId, Query } from '../model'
import { WebSocketConnection } from './websocket'
import { EditableDocument } from '../model/editable-document'
import { ExecuteOptions } from './commands'
import { Offline } from './offline.js'
import { deprecated } from '@aeppic/shared/deprecation'

export type Fetch = typeof fetch

const URL_SPLIT_REGEX = /^(https?:\/\/)([^:^/]*)(:(\d+))?.*$/i
const MAX_FLUSH_SIZE = 256 * 1024 * 1024

declare const __non_webpack_require__: typeof require

const waitForNextSearch = waitForNextTick
const waitForNextFetches = waitForNextTick

function generateDefaultQueryTag() {
  return 'JOINED_QUERIES'
}

interface OutstandingQuery {
  scheduledAt: number
  params: {
    q: string|Query.Query
    options: FindOptions
  },
  resolve: (documents: Types.Document[]) => void
  reject: (error: any) => void
}

interface OutstandingQueries {
  queries: OutstandingQuery[]
  executionPromise?: Promise<any>
  resolve?: (documents: Types.Document[]) => void
  reject?: (error: any) => void
}


interface OutstandingRequest {
  /**
   * Key identifying identical requests. Requests with identical keys only get answered once
   * previous calls return the default result 
   */
  deDuplicationKey?: string 
  requestUrl: string
  requestOptions: {}
  deferred?: Deferred<Response>  
}

type HasAccessRequestInfo = {
  documentId: string
  rights: string[]|null
  deferred: Deferred<boolean>
}

export class ServerAdapter extends EventEmitter implements IServerAdapter {
  private WebSocket: typeof WebSocket

  private _apiUrl = '/api'
  private _wsUrl = null
  
  private _headers: Headers|any
  private _pendingChanges: Change[] = []
  private _pendingFlush: Promise<void> = null
  private _connection: WebSocketConnection = null
  private _developerConnection: WebSocketConnection = null
  private _developerSessionId: string = null
  private _connected = false
  private _interrupted = false
  private _serverId = null
  private _clientId = null
  private _lastOpIndex = null
  
  private _detectedMissingChanges = false
  private _outstandingQueries = new Map<string, OutstandingQueries>()

  private _pendingRequestsDedup = new Map<string, OutstandingRequest>()
  private _pendingRequests: OutstandingRequest[] = []
  private _hasAccessQueue = new UniqueItemProcessingQueue<HasAccessRequestInfo>(
    'HasAccessQueue',
    {
      keyLookup: (info) => info.documentId + '|' + (info?.rights?.join(',') ?? 'READ'),
      groupProcessingAction: (bulk) => this._sendHasRightCalls(bulk),
      minTimeBetweenProcessingBatchesInMs: 10,
      maxProcessingBatchSize: 10,
    })

  get isOnline() {
    return this._connected && !this._interrupted
  }

  private _offline: Offline

  setOffline(offline: Offline) {
    this._offline = offline
  }

  constructor(apiUrl: string, private fetch: Fetch, wsUrl?: string, webSocket?: typeof WebSocket) {
    super()

    if (webSocket) {
      this.WebSocket = webSocket
    }

    if (!this.WebSocket && typeof WebSocket !== 'undefined') {
      this.WebSocket = WebSocket
    }

    if (apiUrl) {
      this._apiUrl = apiUrl
    }

    if (wsUrl) {
      this._wsUrl = wsUrl 
    } else if (apiUrl[0] === '/') {
      const secure = document.location.protocol === 'https:' ? 's' : ''
      const port = document.location.port ? `:${document.location.port}` : ''
      this._wsUrl = wsUrl || `ws${secure}://${document.location.hostname}${port}`
    } else if (apiUrl.substr(0, 4) === 'http') {
      const match = apiUrl.match(URL_SPLIT_REGEX)
      
      if (match) {
        const secure = apiUrl.substr(0, 6) === 'https:' ? 's' : ''
        const hostname = match[2]
        const port = match[4] ? `:${parseInt(match[4], 10)}` : ''

        this._wsUrl = wsUrl || `ws${secure}://${hostname}${port}`
      }
    }
    
    if (!this._wsUrl) {
      console.warn('Websocket connection not configured')
    }

    if (typeof Headers !== 'undefined') {
      this._headers = new Headers()
      this._headers.append('accept', 'application/json')
      this._headers.append('content-type', 'application/json')
    } else {
      this._headers = {}
      this._headers['accept'] = 'application/json'
      this._headers['content-type'] = 'application/json'
    }
  }


  
  /**
   * Connect to the server to receive live changes
   */
  public connect() {
    if (this.WebSocket) {
      this._connection = this._connect()
    } else {
      console.warn('Will not connect to server via websocket since not available')
    }
  }

  public sendToWebsocket(message) {
    if (!this._connection || !this._connected) {
      throw new Error('Not connected')
    }

    if (typeof message !== 'string') {
      message = JSON.stringify(message)
    }

    if (message[0] !== '{') {
      throw new Error('The message must be a single JSON object')
    } 

    this._connection.send(message)
  }

  private _sendClientIdToServerWebsocket() {
    if (this._clientId) {
      this._connection?.send(JSON.stringify({ type: 'set-client-id', clientId: this._clientId }))
    }
  }

  private _connect() {
    if (!this._wsUrl) {
      return
    }

    const self = this
    
    const connection = new WebSocketConnection(this._wsUrl, {
      WebSocket: this.WebSocket,
      connected() {
        self._connected = true
        self._interrupted = false
        
        self._sendClientIdToServerWebsocket()
        self.emit('connection:status:changed', {connected: self._connected, interrupted: self._interrupted, error: false})
      },
      textReceived(text) {
        // console.log('received', text)

        let message = null

        try {
          if (text[0] === '{') {
            const parsedData = JSON.parse(text)
            
            if (parsedData.type) {
              message = parsedData
            }
          }
        } catch (error) {
          console.error('Error parsing incoming websocket message', error)
          self.emit('connection:parse:error', {connected: self._connected, interrupted: self._interrupted, error: true, errorMessage: error})
        }

        if (message) {
          if (self._serverId && message.serverId && message.serverId !== self._serverId) {
            self._announceServerChange()
          } else if (message.serverId) {
            self._serverId = message.serverId
          }

          if (message.type === 'ops') {
            const ops = message.ops

            if (self._lastOpIndex == null && message.opCount) {
              self._lastOpIndex = message.opCount
            }

            for (const op of ops) {
              processNextOp(op)
            }
          } else if (message.document) {
            const op = message
            processNextOp(op)
          } else {
            self.emit('server:message', message)
          }      
        }
      },
      interrupted() {
        self._interrupted = true
        self._connected = false
        self.emit('connection:status:changed', {connected: self._connected, interrupted: self._interrupted, error: false})        
      },
      disconnected() {
        // console.log('disconnected')
        self._connected = false
        self.emit('connection:status:changed', {connected: self._connected, interrupted: self._interrupted, error: false})
      }
    })

    function processNextOp(op) {
      if (self._lastOpIndex != null && op.index) {
        const expectedOpIndex = self._lastOpIndex + 1

        if (op.index <= self._lastOpIndex) {
          return
        }

        if (op.index !== expectedOpIndex) {
          if (!self._detectedMissingChanges) {
            self._announceMissedChanges()

            self._detectedMissingChanges = true
            
            self._reconnect()
          }

          return
        } else if (self._detectedMissingChanges) {
          self._announceMissedChangesFound()
        }
      }

      self._lastOpIndex = op.index

      try {
        self.emit('change', op)
      } catch (error) {
        console.error('Error handling update event', error)
      }
    }

    connection.connect()

    return connection
  }

  disconnect() {
    // TODO: Flush
    if (this._connection) {
      this._connection.disconnect()
      this._connection = null
      this._lastOpIndex = null
    }
  }

  /**
   * Connect to the server for remote development
   */
  public connectDeveloper(sessionId: string) {
    if (this.WebSocket) {
      this._developerConnection = this._connectDeveloper(sessionId)
    } else {
      console.log('Will not connect to server via websocket since not available')
    }
  }

  public sendToDeveloperWebsocket(message) {
    if (!this._developerConnection || !this._developerConnection || !this._developerSessionId) {
      return
    }

    if (typeof message !== 'object') {
      throw new Error('Developer message must be an object')
    }

    message.sessionId = this._developerSessionId
    const messageText = JSON.stringify(message)

    if (messageText[0] !== '{') {
      throw new Error('The message must be a single JSON object')
    } 

    this._developerConnection.send(messageText)
  }

  private _connectDeveloper(sessionId) {
    if (!this._wsUrl) {
      return
    }

    const self = this
    
    const connection = new WebSocketConnection(this._wsUrl, {
      WebSocket: this.WebSocket,
      protocol: `${sessionId}.developer.aeppic`,
      connected() {
        // console.log('developer connected')
      },
      textReceived(text) {
        let message = null
        // console.log('message received')

        try {
          if (text[0] === '{') {
            message = JSON.parse(text)
          }
          else {
            message = text
          }

          self.emit('developer:message', message)
          // if (message && message.sessionId && (message.sessionId === self._developerSessionId)) {
          //   self.emit('developer:message', message)
          // }
        } catch (error) {
          console.error('Error parsing incoming websocket message', error)
          self.emit('connection:parse:error', {connected: self._connected, interrupted: self._interrupted, error: true, errorMessage: error})
        }
      },
      interrupted() {
        console.log('developer connection interrupted')
      },
      disconnected() {
        // console.log('developer disconnected')
      }
    })

    connection.connect()
    this._developerSessionId = sessionId

    return connection
  }

  disconnectDeveloper() {
    if (this._developerConnection) {
      this._developerConnection.disconnect()
      this._developerConnection = null
    }
  }
  
  private _announceServerChange() {
    this.emit('server:changed')
  }

  private _announceMissedChanges() {
    this.emit('changes:missed')
  }
  
  private _announceMissedChangesFound() {
    this.emit('changes:missed-found')
  }

  private _reconnect() {
    this.disconnect()
    
    this.connect()
  }

  writeChanges(changes: Change[]) {
    if (!changes || changes.length === 0) {
      return
    }
    
    if (this._offline?.enabled) {
      this._offline.enqueueChanges(changes).catch(error => {
        console.error('Error enqueuing changes', error)
        this.emit('changes:offline:enqueue:error', error)
      })
    }

    this._pendingChanges.push(...changes)

    this.flush()
    // tslint:disable-next-line
    // TODO: Error handling
    // this.fetch(`${this.apiUrl}/docs`, { method: 'POST', headers: this.headers,  body: JSON.stringify(saveRequest), credentials: 'include' })
  }
  
  async getDocuments(documentLookups: IDocumentLookup[]): Promise<Types.Document[]> {
    // TODO: Optimize call-chain to here to prevent double/triple lookups 
    // console.log('getDocuments', documentLookups)
    
    const getRequest = {
      documents: documentLookups
    }

    // TODO: Error handling
    const response = await this.fetch(`${this._apiUrl}/docs/_get`, { method: 'POST', headers: this._headers,  body: JSON.stringify(getRequest), credentials: 'include' })
    const documents = await response.json()

    return documents
  }

  public async getFormVersions(formIdentifier: Types.IdOrReference): Promise<string[]> {
    const id = getDocumentId(formIdentifier)
    const response = await this.fetch(`${this._apiUrl}/forms/${id}/versions`, { method: 'GET', headers: this._headers, credentials: 'include' })
    const versions = await response.json()
    return versions
  }

  public setCookies(cookies) {
    // console.log(cookies)
    if (typeof Headers !== 'undefined') {
      if (!cookies) {
        this._headers.delete('Cookie')
      } else {
        this._headers.append('Cookie', cookies)
      }
    } else {
      if (!cookies) {
        delete this._headers.Cookie 
      } else {
        this._headers['Cookie'] = cookies
      }
    }
  }

  public setClientId(clientId) {
    this._clientId = clientId
    this._sendClientIdToServerWebsocket()

    if (typeof Headers !== 'undefined') {
      if (!clientId) {
        this._headers.delete('X-Client-Id')
      } else {
        this._headers.append('X-Client-Id', clientId)
      }
    } else {
      if (!clientId) {
        delete this._headers['X-Client-Id']
      } else {
        this._headers['X-Client-Id'] = clientId
      }
    }
  }

  public flush() {
    if (!this._pendingFlush) {
      this._pendingFlush = new Promise((resolve, reject) => {
        if (!this._pendingChanges || this._pendingChanges.length === 0) {
          this._pendingFlush = null
          resolve()
        }

        waitForNextTick().then(async () => {
          // tslint:disable-next-line
          if (!this._pendingChanges || this._pendingChanges.length === 0) {
            this._pendingFlush = null
            return
          }

          // console.log('flushing', this._pendingChanges.length)
          
          const changes = this._pendingChanges
          this._pendingChanges = []

          try {
            const response = await this._sendChangesToServer(changes)

            if (!response || !response.ok) {
              // tslint:disable-next-line
              console.error('could not send changes', changes, response.status, response.statusText)

              // tslint:disable-next-line
              await response.text()
            }

            this._pendingFlush = null
            resolve()

            if (this._pendingChanges && this._pendingChanges.length > 0) {
              this.flush()
            }
          } catch (error) {
            // tslint:disable-next-line
            console.error('ERROR flushing', error)

            this._pendingFlush = null
            reject(error)
          }
        })
      })
    }

    return this._pendingFlush
  }

  upload(dataUrl: string, content?: ArrayBuffer|File|Blob|string|Buffer|NodeJS.ReadableStream, channel?: string) {
    if (typeof content === 'string' && content.startsWith('data:')) {
      return this._uploadDataUrl(dataUrl, content, channel)
    } else if (typeof File !== 'undefined' && content instanceof File) {
      return this._upload(dataUrl, content, channel)
    } else if (typeof Blob !== 'undefined' && content instanceof Blob) {
      return this._upload(dataUrl, content, channel)
    } else if (typeof Buffer !== 'undefined' && Buffer.isBuffer(content)) {
      return this._upload(dataUrl, content, channel, { filename: 'buffer.dat', contentType: 'application/octet-stream', knownLength: content.length })
    } else if (content && (<NodeJS.ReadableStream>content).pipe) {
      return this._upload(dataUrl, <NodeJS.ReadableStream>content, channel)
    } else {
      throw new Error('unsupported type')
    } 
  }

  private _uploadDataUrl(dataUrl: string, object: string, channel?: string): Promise<void> {
    return this.fetch(object, { credentials: 'include' })
          .then(res => res.arrayBuffer())
          .then(buf => new Blob([buf]))
          .then(blob => {
            return this._upload(dataUrl, blob, channel)
          })
  }
  
  private _upload(dataUrl: string, object: File | Blob | Buffer | NodeJS.ReadableStream, channel: string = '_default', extra?: any): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      let formData: FormData
      
      if (typeof FormData !== 'undefined') {
        formData = new FormData()
      } else {
        const req = typeof __non_webpack_require__ === 'undefined' ? require : __non_webpack_require__
        const NodeJsFormData = req('form-data')
        formData = new NodeJsFormData()
      }
      
      formData.append('dataUrl', dataUrl)
      formData.append('file', <any> object, extra)
      formData.append('channel', channel)
      
      if (typeof XMLHttpRequest !== 'undefined') {
        const uploadRequest = new XMLHttpRequest()
        uploadRequest.withCredentials = true
        
        uploadRequest.upload.onprogress = (progressEvent) => {
          // keep record of progress
          console.log(progressEvent)
          
          if (progressEvent.lengthComputable) {
            let progressPercent = progressEvent.loaded / progressEvent.total
            this.emit('upload:progress', dataUrl, progressPercent)
          } else {
            this.emit('upload:progress', dataUrl)
          }
        }

        uploadRequest.onloadstart = (e) => {
          // keep record of progress
          console.log('started')
          const progressPercent = 0
          this.emit('upload:progress', dataUrl, progressPercent)
        }

        uploadRequest.onloadend = (e) => {
          // keep record of progress
          console.log('ended')
          this.emit('upload:progress', dataUrl, 1)
          if (uploadRequest.status >= 200 && uploadRequest.status < 300) {
            this.emit('uploaded', null, dataUrl)
            resolve()
          } else {
            this.emit('uploaded', { errorCode: uploadRequest.status }, dataUrl)
            reject('Error code')
          }
        }

        uploadRequest.onerror = (e) => {
          console.log('failed')

          reject('Could not upload')
        }

        uploadRequest.open('POST', `${this._apiUrl}/_upload`)
        uploadRequest.send(formData) 
      } else {
        const headers = { ...this._headers, ...(<any>formData).getHeaders() }

        try {
          this.emit('upload:progress', dataUrl, 0)

          const response = await this.fetch(`${this._apiUrl}/_upload`, { method: 'POST', headers, body: formData })

          if (response.ok) {
            const output = await response.text()
            this.emit('uploaded', null, dataUrl)
            resolve()
          } else {
            this.emit('uploaded', { errorCode: response.status }, dataUrl)
            reject('Could not upload')
          }
        } catch (error) {
          this.emit('uploaded', { errorCode: 0 }, dataUrl)
          reject('Could not upload')
        }
      }
    })
  }

  private _sendChangesToServer(changes: Change[]) {
    // tslint:disable-next-line
    console.log('writing to server', changes.length)
    
    const request = {
      sets: [
        { changes }
      ]
    }

    // TODO: stream upload (partition on caller side already to ensure small upload size)

    return this.fetch(`${this._apiUrl}/docs/_changes`, { method: 'POST', headers: this._headers, body: JSON.stringify(request), credentials: 'include' })
  }

  async execute(commandIdentifier: Types.Document|Types.IdOrReference, targetDocumentIdentifier: Types.IdOrReference|EditableDocument, commandParameters: any = {}, options: ExecuteOptions = {}) {
    const targetIdentifier = EditableDocument.isEditableDocument(targetDocumentIdentifier) ? targetDocumentIdentifier.cloneAsDocument() : targetDocumentIdentifier
    const targetId = typeof targetIdentifier === 'string' ? targetIdentifier : targetIdentifier.id
    
    let targetDocument = null

    if (typeof targetIdentifier !== 'string') {
      if ((<Types.Document>targetIdentifier).data) {
        targetDocument = targetIdentifier
      }
    }

    const commandId = typeof commandIdentifier === 'string' ? commandIdentifier : commandIdentifier.id

    const request = {
      target: targetDocument,
      parameters: commandParameters, 
      options: options,
    }

    const response = await this.fetch(`${this._apiUrl}/docs/${targetId}/_executeCommand?commandId=${commandId}`, { method: 'POST', headers: this._headers, body: JSON.stringify(request), credentials: 'include' })
    const result = await response.json()

    return result
  }

  async find(query: string|Query.Query, options: FindOptions = {}): Promise<Types.Document[]> {
    // TODO: Optimize call-chain to here to prevent double/triple lookups 
    // console.log('query', queryString)
    const params = {
      q: query,
      options
    }

    return new Promise((resolve, reject) => {
      const newQuery = {
        scheduledAt: Date.now(),
        params,
        resolve,
        reject
      }
      
      const queryGroupTag = options.tag || generateDefaultQueryTag()
      let scheduledQueries = this._outstandingQueries.get(queryGroupTag)
      
      if (!scheduledQueries) {
        scheduledQueries = {
          queries: []
        }
        this._outstandingQueries.set(queryGroupTag, scheduledQueries)
      }

      scheduledQueries.queries.push(newQuery)        
      this._triggerQueries(queryGroupTag)
    })
  }

  _triggerQueries(tag: string) {
    const BATCH_SIZE = 5
    const scheduledQueries = this._outstandingQueries.get(tag)
    
    if (!scheduledQueries.executionPromise) {
      const promise = new Promise((resolve, reject) => {
        scheduledQueries.resolve = resolve
        scheduledQueries.reject = reject
      })
      scheduledQueries.executionPromise = promise

      waitForNextSearch().then(async () => {
        this._outstandingQueries.delete(tag)

        for (let startQueryIndex = 0; startQueryIndex < scheduledQueries.queries.length; startQueryIndex += BATCH_SIZE) {
          const queryBatch = scheduledQueries.queries.slice(startQueryIndex, startQueryIndex + BATCH_SIZE)

          const body = JSON.stringify(queryBatch.map(q => q.params))
          const start = Date.now()
          const response = await this.fetch(`${this._apiUrl}/_search?`, { headers: this._headers, method: 'POST', credentials: 'include', body })
          const duration = Date.now() - start
          
          if (response.status !== 200) {
            for (let queryIndex = startQueryIndex; queryIndex < startQueryIndex + queryBatch.length; queryIndex ++) {
              const query = scheduledQueries.queries[queryIndex]
              query.reject('response error')
            }

            continue
          }

          const responseBody = await response.json()
          const parsedDuration = Date.now() - start

          // PERFORMANCE CHECK
          //
          let check = false
          if (check && duration > 2000) {
            console.warn('Slow query', duration, parsedDuration, queryBatch.map(b => b.params))
          }

          for (let queryIndex = startQueryIndex; queryIndex < startQueryIndex + queryBatch.length; queryIndex ++) {
            const query = scheduledQueries.queries[queryIndex]
            const result = responseBody.results[queryIndex - startQueryIndex]

            if (result.error) {
              query.reject(result.error)
            } else {
              query.resolve(result.documents)
            }
          }
        }
      })
    }
  }
  
  async hasRight(documentIdentifier: Types.IdOrReference, optionalRightIdentifiers?: Types.IdOrReference[]): Promise <boolean> {
    const documentId = getDocumentId(documentIdentifier)
    
    if (!documentId) {
      console.warn('Invalid parameter hasRight must be called on a document')
      return false
    }

    const rightIds = optionalRightIdentifiers?.map(r => getDocumentId(r)) ?? null

    if (rightIds) {
      if (rightIds.length === 0) {
        throw new Error('Invalid parameter. Right Ids null/undefined for read access check or an array of ids/references/documents')
      }

      if (rightIds.some(r => !r)) {
        throw new Error('Invalid parameter. Right Ids must be id/references/documents')
      }
    }
    
    const deferred = buildDeferred<boolean>()

    const request: HasAccessRequestInfo = {
      documentId,
      rights: rightIds,
      deferred
    }

    this._hasAccessQueue.enqueue(request)

    return deferred.promise
  }

  private async _sendHasRightCalls(hasAccessCalls: HasAccessRequestInfo[][]) {
    const requestUrl = `${this._apiUrl}/docs/_hasRight`

    const checks = hasAccessCalls.map(infoCallGroup => {
      // Each call inside a group wants to know about the same document and right
      // The key function ensures that
      // Each group has at least 1 item inside it too (the processing queue ensures that)
      return { documentId: infoCallGroup[0].documentId, rights: infoCallGroup[0].rights }
    })

    const body = JSON.stringify({ checks })
  
    const requestOptions = { method: 'POST', headers: this._headers, credentials: 'include', body }

    try {
      const response = await this._scheduleFetch({ requestUrl, requestOptions })
      
      if (response.status !== 200) {
        throw new Error('Did not receive 200 code for _hasAccess call')
      }

      const result = await response.json()

      if (result.length !== hasAccessCalls.length) {
        throw new Error('Invalid response. Sizes do not match')
      }

      for (let i = 0; i < hasAccessCalls.length; i ++) {
        for (const individualCall of hasAccessCalls[i]) {
          individualCall.deferred.resolve?.(result[i])
        }
      }
    } catch (error) {
      console.error('Could not check access', error, checks)
      
      for (const groupOfIdenticalIndividualCalls of hasAccessCalls) {
        for (const individualCall of groupOfIdenticalIndividualCalls) {
          individualCall.deferred.reject?.(error)
        }
      }
    }
  }
  
  async _scheduleFetch(requestDefinition: OutstandingRequest): Promise<Response> {
    if (requestDefinition.deDuplicationKey) {
      const existingRequest = this._pendingRequestsDedup.get(requestDefinition.deDuplicationKey)

      if (existingRequest) {
        return existingRequest.deferred.promise
      }
    }
    
    requestDefinition.deferred = buildDeferred<Response>()

    if (requestDefinition.deDuplicationKey) {
      this._pendingRequestsDedup.set(requestDefinition.deDuplicationKey, requestDefinition)
    } else {
      this._pendingRequests.push(requestDefinition)
    }

    this._performFetches()
    
    return requestDefinition.deferred.promise
  }

  _performFetches() {
    waitForNextFetches().then(async () => {
      const standardRequests = this._pendingRequests
      this._pendingRequests = []

      for (const request of standardRequests) {
        try {
          const response = await this.fetch(request.requestUrl, request.requestOptions)
          request.deferred.resolve(response)
        } catch (error) {
          console.error('Error performing fetch', error.toString())
          request.deferred.reject(error)
        }
      }

      const deDuppedRequests = Array.from(this._pendingRequestsDedup.values())
      this._pendingRequestsDedup.clear()
  
      for (const request of deDuppedRequests) {
        try {
          const response = await this.fetch(request.requestUrl, request.requestOptions)
          request.deferred.resolve(response)
        } catch (error) {
          console.error('Error performing fetch', error.toString())
          request.deferred.reject(error)
        }
      }
    })
  }
}

function encodeQueryStringParameters(parameters) {
  const parts = []

  for (const parameterName of Object.keys(parameters)) {
    const value = parameters[parameterName]

    if (value && value.toString) {
      parts.push(`${parameterName}=${encodeURIComponent(value.toString())}`)
    }
  }

  return parts.join('&')
}

function toSortQueryString(sortOption: FindOptions['sort']) {
  if (!sortOption) {
    return null
  }
  
  if (typeof sortOption === 'string') {
    return sortOption
  }

  const fields = Array.isArray(sortOption) ? sortOption : [sortOption]

  const parts = []

  for (const fieldSortInfo of fields) {
    if (!fieldSortInfo) {
      continue
    }

    if (typeof fieldSortInfo === 'string') {
      parts.push(fieldSortInfo)
      continue
    }

    if ('fallthrough' in fieldSortInfo) {
      const fallthroughFieldsEncoded = fieldSortInfo.fallthrough.join('|')
      if (fieldSortInfo.descending === true) {
        parts.push(`${fallthroughFieldsEncoded} desc`)
      } else {
        parts.push(`${fallthroughFieldsEncoded}`)
      }
    } else  if ('field' in fieldSortInfo) {
      if (fieldSortInfo.descending === true) {
        parts.push(`${fieldSortInfo.field} desc`)
      } else if (fieldSortInfo.descending == null || fieldSortInfo.descending === false) {
        parts.push(fieldSortInfo.field)
      } else {
        // TODO: Log error ?
        console.error('Invalid sort configuration')
      }
    } else  if ('fields' in fieldSortInfo) {
      if (fieldSortInfo.descending === true) {
        parts.push(`${fieldSortInfo.fields} desc`)
      } else if (fieldSortInfo.descending == null || fieldSortInfo.descending === false) {
        parts.push(fieldSortInfo.fields)
      } else {
        // TODO: Log error ?
        console.error('Invalid sort configuration')
      }
    }
  }

  return parts.join(',')
}
