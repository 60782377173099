import * as Types from '@aeppic/types'
import { DiffPatcher } from 'jsondiffpatch/dist/jsondiffpatch.umd.slim.js'

import { hasId } from './is.js'

const configuredDiffPatcher = new DiffPatcher({
  arrays: {
    detectMove: true
  },
  objectHash: (object: object|Types.AddressField|Types.FileField|Types.ImageField|Types.GeoLocationField|Types.ReferenceField, index: number) => {
    if (hasId(<Types.Reference>object)) {
      const { id } = <Types.Reference>object

      return id
    }

    return `$$index:${index}`
  }
})

export function diff (left: any, right: any) {
  return configuredDiffPatcher.diff(left, right)
}
