import * as Types from '@aeppic/types'

import * as Model from '../../model/model'

export default function sort(list: Types.Document[], sortInfo: string|Model.FieldSortInfo|(string|Model.FieldSortInfo)[]) {
  if (!sortInfo) {
    return list
  }

  const clone = list.slice(0)

  Model.sort(clone, sortInfo)
  return clone
}
