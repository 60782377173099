import * as Types from '@aeppic/types'

export default class ReadOnlyReference implements Types.Reference {
  private __ref: Types.Reference

  constructor(ref: Types.Reference) {
    this.__ref = ref
  }

  get id() {
    return this.__ref.id
  }

  get v() {
    return this.__ref.v
  }

  get text() {
    return this.__ref.text
  }
}
