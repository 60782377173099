import type { Change } from '../model/change-tracker.js'
import { AeppicUiInterface } from './aeppic.js'

type PendingChanges = {
  index: number
  changes: Change[]
}

export class Offline {
  private _available: boolean = false
  private _enabled: boolean = false
  private _changeQueue: OfflineChangeQueue = new OfflineChangeQueue()
  
  constructor(private _aeppic: AeppicUiInterface) {}

  get available() {
    return this._available
  }

  async testAvailability() {
    let available = false

    try {
      if (!this._changeQueue.isAvailable) {
        return
      }

      // Do we support service workers
      const hasServiceWorker = 'serviceWorker' in navigator
      
      if (!hasServiceWorker) {
        return
      }

      // Is the service worker registered?
      const hasServiceWorkerRegistration = await navigator.serviceWorker.getRegistration()

      if (!hasServiceWorkerRegistration) {
        return
      }

      // Is it active?
      const activeServiceWorker = hasServiceWorkerRegistration.active

      if (!activeServiceWorker) {
        return
      }

      if (!activeServiceWorker.state || activeServiceWorker.state !== 'activated') {
        return
      }

      available = true
    } catch (e) {
      console.error('Error while testing offline availability', e)
    } finally {
      this._available = available
    }

    return this._available
  }

  get enabled() {
    return this._available && this._enabled
  }

  enable() {
    this._enabled = true
  }

  disable() {
    this._enabled = false
  }

  async enqueueChanges(changes: Change[]) {
    if (!this.enabled) {
      throw new Error('Offline is not enabled')
    }

    await this._changeQueue.enqueueChanges(changes)
  }

  // async readNextPendingChanges(): Promise<PendingChanges> {
    
  // }

  async consumeChanges(pending: PendingChanges) {
    
  }
}

export class OfflineChangeQueue {
  private _queueDbName: string = 'aeppic-offline'
  private _queueDbSchemaVersion = 1
  private _queueName: string = 'change-queue'
  private _db: IDBDatabase

  constructor() {     
  }

  get isAvailable() {
    // Is indexedDB available?
    const hasIndexedDB = 'indexedDB' in window 
     
    if (!hasIndexedDB) {
      return false
    }

    return true
 }

  async enqueueChanges(changes: Change[]) {
    await this._ensureReady()

    const transaction = this._db.transaction(this._queueName, 'readwrite')
    const store = transaction.objectStore(this._queueName)

    const addRequest = store.add(changes)
    return requestToPromise(addRequest)
  }

  private async _ensureReady() {
    if (this._db) {
      return
    }

    this._db = await this._openQueue()
  }

  private async _openQueue() {
    return new Promise<IDBDatabase>(resolve => {
      const openRequest = indexedDB.open(this._queueDbName, this._queueDbSchemaVersion);

      openRequest.onupgradeneeded = (event: IDBVersionChangeEvent) => {
        const db = (event.target as IDBOpenDBRequest).result
        this._createSchema(db)
      }

      openRequest.onsuccess = (event: Event) => {
        resolve((event.target as IDBOpenDBRequest).result)
      }

      openRequest.onerror = (event: Event) => {
        console.error('Error while opening offline change queue', event)
        resolve(null)
      }
    })
  }

  private _createSchema(newDatabase: IDBDatabase) {
    newDatabase.createObjectStore(this._queueName, { keyPath: 'id', autoIncrement: true })
  }

}

async function requestToPromise<T>(request: IDBRequest<T>): Promise<T> {
  return new Promise((resolve, reject) => {
    /* eslint-disable unicorn/prefer-add-event-listener */
    request.onsuccess = () => {
      resolve(request.result)
    }

    request.onerror = () => {
      reject(request.error)
    }
    /* eslint-enable */
  })
}
