import * as Types from '@aeppic/types'
import { isReference } from './is.js'
import { Form } from './form.js'
import { QueryScopes } from './query/fluent.js'
import { Query } from './index.js'

/**
 * Resolves a document path starting from a root document by traversing nested properties based on the provided query string.
 * 
 * @param {Types.IdOrReference} root - The root document ID or reference from which to start resolving the path.
 * @param {string} query - The query string representing the path to resolve (e.g., 'data.a -> data.b -> data.c').
 * @param {Object} options - An object containing options for resolving the document path.
 * @param {Function} options.getDocument - A function that retrieves a document based on its ID or reference.
 * @param {Function} [options.warn] - An optional function for logging warnings or messages during the resolution process.
 * 
 * @returns {Promise<Types.Document>} The resolved document at the end of the path, or null if any step in the path resolution fails.
 */
export async function resolveDocumentPath(root: Types.IdOrReference, query: string, options: {
  get: (id: Types.IdOrReference) => Promise<Types.Document>,
  getAll: (ids: Types.IdOrReference[]) => Promise<Types.Document[]>,
  resolve(start: Types.IdOrReference, path: string): Promise<Types.Document>,
  findOne(query: Query.Query): Promise<Types.Document>
  getFormForDocument: (id: Types.IdOrReference) => Promise<Form>,
  warn?: (...args: any[]) => void 
}): Promise<Types.Document> {
  if (typeof query !== 'string') {
    throw new Error('Query must be a string (e.g data.a -> data.b -> data.c)')
  }
  
  let current: Types.Document = await options.get(root)

  if (!current) {
    return null
  }
  
  let steps = query.split('->').map(step => step.trim())

  for (let i = 0; i < steps.length; i++) {
    const step = steps[i].trim()

    // Is it a related document ?
    const firstCharacterIsUppercase = step[0].toUpperCase() === step[0]

    if (firstCharacterIsUppercase) {
      // Find relation on current document form
      const form = await options.getFormForDocument(current)
      const relations = await options.getAll(form.data.relatedDocuments as Types.Reference[])
      const relation = await relations.find(relation => relation.data.name === step)

      if (!relation) {
        options.warn?.('Relation', step, 'not found on at step', i, 'of', steps)
        return null
      }

      if (relation.data.multiple) {
        options.warn?.('Relation', step, 'cannot be used to find single document (multiple is checked) at step', i, 'of', steps)
        return null
      }

      switch(relation.data.type) {
        case 'RESOLVE': {
          current = await options.resolve(current.id, relation.data.resolve as string)
          break
        }
        case 'STATIC': {
          current = await options.get(relation.data.reference as Types.Reference)
          break
        }
        case 'ANCESTOR_FORM': {
          let matchingAncestor = null
          
          for (let i = current.a_forms.length - 1; i >= 0; i--) {
            const formId = current.a_forms[i]
            
            for (const formRefsToLookFor of relation.data.ancestorForms as Types.Reference[]) {
              if (formId === formRefsToLookFor.id) {
                matchingAncestor = current.a[i]
                break
              }
            }

            if (matchingAncestor) {
              break
            }
          }

          if (matchingAncestor) {
            current = await options.get(matchingAncestor)
          } else {
            options.warn?.('Ancestor form not found on at step', i, 'of', steps)
            return null
          }

          break
        }
        case 'QUERY': {
          const definedScope = relation.data.queryScope
          const scopes = new QueryScopes(current.id)
          
          const scope = definedScope === 'CHILDREN' ? scopes.children()
                    : definedScope === 'DESCENDANTS' ? scopes.descendants()
                    : definedScope === 'GLOBAL' ? scopes.global()
                    : null

          if (!scope) {
            options.warn?.('Unknown scope', definedScope, 'found on at step', i, 'of', steps)
            return null
          }

          const fn = getCompiledFunction(relation.data.query as string, { identifyingDocument: current, identifyingField: 'query' })

          if (!fn) {
            return null
          }

          current = await options.findOne(fn(scope))
          break
        }
        default: {
          options.warn?.('Unknown relation type', relation.data.type, 'found on at step', i, 'of', steps)
          return null
        }
      }
      
    } else {
      // Move throught the nested values of this document 
      let value: any = lookupDocumentPath(current, step, options)

      // Ensure current is a Types.Reference
      if (typeof value !== 'string' && !isReference(value)) {
        options.warn?.('Expected a stirng or reference, but got:', current, 'at step', i, 'of', steps)
        return null
      }

      // Get the document from Aeppic.get
      current = await options.get(value)
    }

    // If the document is null, return null
    if (current === null) {
        return null
    }
  }

  return current
}



/**
 * Resolves a document path starting from a root document by traversing nested properties based on the provided query string.
 * 
 * @param {Types.IdOrReference} root - The root document ID or reference from which to start resolving the path.
 * @param {string} query - The query string representing the path to resolve (e.g., 'data.a -> data.b -> data.c').
 * @param {Object} options - An object containing options for resolving the document path.
 * @param {Function} options.getDocument - A function that retrieves a document based on its ID or reference.
 * @param {Function} [options.warn] - An optional function for logging warnings or messages during the resolution process.
 * 
 * @returns {Promise<Types.Document>} The resolved document at the end of the path, or null if any step in the path resolution fails.
 */
export function resolveDocumentPathSync(root: Types.IdOrReference, query: string, options: {
  get: (id: Types.IdOrReference) => Types.Document,
  getAll: (ids: Types.IdOrReference[]) => Types.Document[],
  resolve(start: Types.IdOrReference, path: string): Types.Document,
  findOne(query: Query.Query): Types.Document
  getFormForDocument: (id: Types.IdOrReference) => Form,
  warn?: (...args: any[]) => void
}): Types.Document {
  if (typeof query !== 'string') {
    throw new Error('Query must be a string (e.g data.a -> data.b -> data.c)')
  }
  
  let current: Types.Document = options.get(root)

  if (!current) {
    return null
  }
  
  let steps = query.split('->').map(step => step.trim())

  for (let i = 0; i < steps.length; i++) {
    const step = steps[i].trim()

    // Is it a related document ?
    const firstCharacterIsUppercase = step[0].toUpperCase() === step[0]

    if (firstCharacterIsUppercase) {
      // Find relation on current document form
      const form = options.getFormForDocument(current)
      const relations = options.getAll(form.data.relatedDocuments as Types.Reference[])
      const relation = relations.find(relation => relation.data.name === step)

      if (!relation) {
        options.warn?.('Relation', step, 'not found on at step', i, 'of', steps)
        return null
      }

      if (relation.data.multiple) {
        options.warn?.('Relation', step, 'cannot be used to find single document (multiple is checked) at step', i, 'of', steps)
        return null
      }

      switch(relation.data.type) {
        case 'RESOLVE': {
          current = options.resolve(current.id, relation.data.resolve as string)
          break
        }
        case 'STATIC': {
          current = options.get(relation.data.reference as Types.Reference)
          break
        }
        case 'ANCESTOR_FORM': {
          let matchingAncestor = null
          
          for (let i = current.a_forms.length - 1; i >= 0; i--) {
            const formId = current.a_forms[i]
            
            for (const formRefsToLookFor of relation.data.ancestorForms as Types.Reference[]) {
              if (formId === formRefsToLookFor.id) {
                matchingAncestor = current.a[i]
                break
              }
            }

            if (matchingAncestor) {
              break
            }
          }

          if (matchingAncestor) {
            current = options.get(matchingAncestor)
          } else {
            options.warn?.('Ancestor form not found on at step', i, 'of', steps)
            return null
          }

          break
        }
        case 'QUERY': {
          const definedScope = relation.data.queryScope
          const scopes = new QueryScopes(current.id)
          
          const scope = definedScope === 'CHILDREN' ? scopes.children()
                    : definedScope === 'DESCENDANTS' ? scopes.descendants()
                    : definedScope === 'GLOBAL' ? scopes.global()
                    : null

          if (!scope) {
            options.warn?.('Unknown scope', definedScope, 'found on at step', i, 'of', steps)
            return null
          }

          const fn = getCompiledFunction(relation.data.query as string, { identifyingDocument: current, identifyingField: 'query' })

          if (!fn) {
            return null
          }

          current = options.findOne(fn(scope))
          break
        }
        default: {
          options.warn?.('Unknown relation type', relation.data.type, 'found on at step', i, 'of', steps)
          return null
        }
      }
      
    } else {
      // Move throught the nested values of this document 
      let value: any = lookupDocumentPath(current, step, options)

      // Ensure current is a Types.Reference
      if (typeof value !== 'string' && !isReference(value)) {
        options.warn?.('Expected a stirng or reference, but got:', current, 'at step', i, 'of', steps)
        return null
      }

      // Get the document from Aeppic.get
      current = options.get(value)
    }

    // If the document is null, return null
    if (current === null) {
        return null
    }
  }

  return current
}


const COMPILED_FUNCTIONS = new Map<string,Function>()

function getCompiledFunction(query: string, { identifyingDocument, identifyingField }: { identifyingDocument: Types.Document, identifyingField: string }) {
  const key = `${identifyingDocument.id}@${identifyingDocument.v}:${query}}`
  if (COMPILED_FUNCTIONS.has(key)) {
    return COMPILED_FUNCTIONS.get(key)
  }

  const identifier = `${identifyingDocument.id}@${identifyingDocument.v} -> ${identifyingField}`
  const functionBody = `return scope.${query.trim()}`
  const fullDefinition = `// INFO: ${identifyingDocument.data.name} Field: ${identifyingField}\n\n${functionBody}\n//# sourceURL=${identifier}`

  try {
    const fn = new Function('scope', fullDefinition) as any
    fn.displayName = `RelationResolveFunction_${identifier}`
    COMPILED_FUNCTIONS.set(key, fn)
    return fn as Function
  } catch (compileError) {
    // tslint:disable-next-line no-console
    console.error('Error preparing dynamic code', compileError, fullDefinition)
    COMPILED_FUNCTIONS.set(key, null)
    return null
  }
}

function lookupDocumentPath(document: Types.Document, path: string, { warn }: { warn?: (...args: any[]) => void }) {
  let value: any = document

  // Split the current step into parts
  let parts = path.split('.')

  for (let j = 0; j < parts.length; j++) {
    if (value === null) {
      warn?.("Expected a value but got 'null'")
      return null
    }

    if (typeof value !== 'object') {
      warn?.("Expected an object but got a", typeof value)
      return null
    }

    // Get the next part of the step
    let part = parts[j]

    // If the document is null or the part is not in the root, return null
    if (!(part in value)) {
      warn?.('Part', part, 'not in', value)
      return null
    }

    // Update the current document
    value = value[part]    
  }

  return value
}

