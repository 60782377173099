import * as Types from '@aeppic/types'

import ReadOnlyReference from './read-only-reference.js'
import Reference from './reference.js'
import ChangeSourceInfo from './change-source-info.js'

export function data(data): any {
  // TODO: Improve by writing form-info aware default function
  return JSON.parse(JSON.stringify(data))
}

// export function typedRef(ref: Types.Reference): Reference {
//   return new Reference( ref.id, ref.v, ref.text, ref.client )
// }

// export function typedRefs(refs: Types.Reference[]): Reference[] {
//   return refs.map(typedRef)
// }

export function ref(ref: Types.Reference): Types.Reference {
  return {
    id: ref.id,
    v: ref.v,
    text: ref.text,
  }
}

export function refs(refs: (Types.Reference|Reference)[]): Types.Reference[] {
  if (!refs) {
    return []
  }
  return refs.map(ref)
}

export function stamps(stamps: Types.Stamp[]): Types.Stamp[] {
  if (stamps == null) {
    return
  }

  return stamps.map(stamp => {
    return {
      id: stamp.id,
      v: stamp.v,
      targetVersion: stamp.targetVersion,
      text: stamp.text,
      type: stamp.type,
      added: stamp.added
    }
  })
}

export function stampData(stampData: Types.StampData): Types.StampData {
  if (!stampData) {
    return
  }

  return {
    documents: stampData.documents,
    signature: stampData.signature,
  }
}

export function lockRefs(refs: Types.Reference[]): Types.Reference[] {
  if (!refs) {
    return []
  }
  return refs.map(ref)
}

export function lockRefsReadOnly(refs: Types.Reference[]): ReadOnlyReference[] {
  if (!refs) {
    return []
  }
  return refs.map((r) => new ReadOnlyReference(r))
}

// export function typedChangeSource(changeSource): ChangeSourceInfo {
//   return new ChangeSourceInfo(
//     changeSource.at,
//     changeSource.by,
//     changeSource.from,
//     changeSource.client
//   )
// }

export function changeSource(changeSource: Types.ChangeSourceInfo): Types.ChangeSourceInfo {
  if (!changeSource) {
    return { at: '', by: '', from: '', client: ''}
  }

  return {
    ...changeSource
  }
}

/** Clone array of array of locks (based on refs) [[]] */
export function inheritedLocks(inheritedLocks: Types.Reference[][]): Types.Reference[][] {
  if (!inheritedLocks) {
    return []
  }

  return inheritedLocks.map(refs => !refs ? [] : refs.map(r => {return {id: r.id, v: r.v, text: r.text }}) )
}

export function locks(locks) {
  if (!locks) {
    return []
  }

  return locks.slice(0)
}
