
  export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ae-popup',{attrs:{"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"base",fn:function(popup){return [_c('div',{on:{"!blur":function($event){return popup.closeOnBlur.apply(null, arguments)},"!focus":function($event){return popup.show.apply(null, arguments)}}},[_vm._t("label",function(){return [_c('a',{style:(_vm.selectStyle),on:{"click":function($event){return popup.toggle()}}},[_vm._v("Select an entry")])]},{"open":popup.open,"show":popup.show,"close":popup.close,"toggle":popup.toggle,"position":popup.position,"styling":_vm.styling,"isOpen":popup.isOpen})],2)]}},{key:"popup",fn:function(popup){return [_c('ul',{style:([_vm.listStyle, { width: popup.baseWidth + 'px' }])},[(_vm.limitedItems.length)?_c('div',[_vm._l((_vm.limitedItems),function(item){return _vm._t("item",function(){return [_c('li',{staticStyle:{"cursor":"pointer","padding":"1rem"},on:{"click":function($event){popup.close(); _vm.selected(item)}}},[_vm._v("\n            "+_vm._s(item && item.display)+"\n          ")])]},{"item":item,"selected":_vm.selected,"close":popup.close,"position":popup.position})}),_vm._v(" "),(_vm.limitedItems.length < _vm.items.length)?_vm._t("result-limited",function(){return [_c('li',[_vm._v("Result is limited to "+_vm._s(_vm.limit)+" entries.")])]}):_vm._e()],2):_c('div',[_vm._t("no-item",function(){return [_c('li',{staticStyle:{"padding":"1rem","cursor":"not-allowed"}},[_vm._v("No results.")])]})],2)])]}}],null,true)})},
  staticRenderFns: [],
  /* tslint:enable */
  
    props: {
      disabled: {
        type: Boolean,
        default: false
      },
      items: {
        type: Array,
        default: () => []
      },
      limit: {
        type: Number,
        default: 50
      },
      styling: {
        type: Object,
        default: () => ({
          maxHeight: '18rem',
          maxWidth: '18rem',
          minWidth: '10rem'
        })
      },
    },
    computed: {
      listStyle() {
        return {
          'list-style': 'none',
          'border-radius': '.25em',
          'box-shadow': '0 2px 8px rgba(0, 0, 0, 0.2)',
          background: '#fff',
          'font-family': 'inherit',
          color: 'inherit',
          overflow: 'auto',
          width: this.styling.minWidth,
          'max-height': this.styling.maxHeight,
          '-webkit-overflow-scrolling': 'touch',
          padding: 0,
          margin: 0
        }
      },
      limitedItems() {
        const items = Array.from(this.items)
        if (this.limit === 0) {
          return items
        }

        return items.splice(0, this.limit)
      },
      selectStyle() {
        return `display: inline-block; padding: 0.5rem 0 0.5rem 1em; background: #f6f6f6; border: .18em solid #ddd; cursor: pointer; color: #444; text-decoration: none; white-space: nowrap; max-width: 100%; overflow: hidden; text-overflow: ellipsis; min-width: ${this.styling.minWidth};`
      }
    },
    methods: {
      selected(item) {
        this.$emit('select', item)
      }
    }
  }

