import uuid from  '@aeppic/shared/uuid'

const iconFetchPromises = {}
const fetchAndCreateSvgSymbolPromises = {}

export default <any>{
  inject: ['getAeppicContext'],
  data() {
    return {
      Aeppic: this.getAeppicContext('ae-icon'),
      baseClass: '',
      iconName: '',
      fetchFailed: false,
      svgSymbolElem: null,
      svgStandalone: ''
    }
  },
  props: {
    standalone: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    symbolElementClass() {
      if (this.standalone) {
        this.$_createStandaloneSvg()
        return
      }
 
       this.$_fetchAndCreateSvgSymbol()
    }
  },
  render(createElement) {
    if (this.svgSymbolElem && this.svgSymbolElem.firstChild) {
      return createElement('svg', {
        style: {
          fill: 'currentColor'
        },
        staticClass: 'ae-icon svg-inline--fa fa-w-16',
      }, [
          createElement('use', {
            attrs: {
              'xlink:href': `#${this.svgSymbolElem.firstChild.getAttribute('id')}`
            }
          })
        ])
    }


    if (this.svgStandalone) {
      const { attributes, innerHTML } = getSvgContents(this.svgStandalone)

      return createElement('svg', {
        style: {
          fill: 'currentColor'
        },
        attrs: attributes,
        // NOTE: is fa-w-16 always working?
        staticClass: 'ae-icon svg-inline--fa fa-w-16',
        domProps: {
          innerHTML,
        }
      })
    }

    const aeIconStatus = this.fetchFailed ? 'ae-icon--failed' : 'ae-icon--no-icon'

    return createElement('svg', {
      key: this.refreshKey,
      staticClass: `ae-icon ${aeIconStatus} svg-inline--fa fa-w-16`
    })
  },
  computed: {
    baseIconUrl() {
      if (!this.Aeppic.VueComponentOptions || !this.Aeppic.VueComponentOptions.aeIcon) {
        return null
      }

      const baseClassToUrlExtension = this.Aeppic.VueComponentOptions.aeIcon.baseClassToUrlExtension
      const baseIconPath = this.Aeppic.VueComponentOptions.aeIcon.baseIconPath

      if (!this.baseClass || baseClassToUrlExtension === null || baseIconPath === null) {
        return null
      }

      const extension = baseClassToUrlExtension[this.baseClass]
      if (!extension) {
        return null
      }

      return `${baseIconPath}/${extension}`
    },
    symbolElementClass() {
      if (!this.baseClass || !this.iconName) {
        return null
      }

      return `${this.baseClass}.${this.baseClass}-${this.iconName}`
    }
  },
  created() {
    this.$_fetchAndCreateSvgSymbol = async () => {
      if (!this.symbolElementClass) {
        this.svgSymbolElem = null
        return
      }

      if (!fetchAndCreateSvgSymbolPromises[this.symbolElementClass]) {
        fetchAndCreateSvgSymbolPromises[this.symbolElementClass] = new Promise<void>(async (resolve) => {
          const responseAsText = await this.$_fetchIcon()

          const element = this.$_createSvgSymbol(responseAsText)

          resolve(element)
        })
      }

      this.svgSymbolElem = await fetchAndCreateSvgSymbolPromises[this.symbolElementClass]
    }

    this.$_fetchIcon = async () => {
      if (!this.baseIconUrl || !this.iconName) {
        return
      }

      const iconNameWithBaseUrl = `${this.baseIconUrl}/${this.iconName}.svg`

      if (!iconFetchPromises[iconNameWithBaseUrl]) {
        const fetchPromise = new Promise(async (resolve) => {
          const response = await this.Aeppic.fetch(iconNameWithBaseUrl)

          if (!response.ok) {
            return resolve({
              ok: false
            })
          }

          const text = await response.text()

          return resolve({
            ok: true,
            responseAsText: text
          })
        })

        iconFetchPromises[iconNameWithBaseUrl] = fetchPromise.then((result) => {
          iconFetchPromises[iconNameWithBaseUrl] = result
          return result
        })
      }

      const { ok, responseAsText } = await iconFetchPromises[iconNameWithBaseUrl]

      if (!ok) {
        this.fetchFailed = true
      }

      return responseAsText
    }

    this.$_createSvgSymbol = (svgString) => {
      const svgElem = (<any>document.createElementNS)('http://www.w3.org/2000/svg', 'svg', { force: true })
      svgElem.style.display = 'none'
      svgElem.classList.add('ae-icon', 'ae-icon-symbol', this.baseClass, `${this.baseClass}-${this.iconName}`)
      this.$root.$el.appendChild(svgElem)

      const svgSymbolElem = (<any>document.createElementNS)('http://www.w3.org/2000/svg', 'svg', { force: true })
      svgSymbolElem.id = uuid()
      svgSymbolElem.classList.add(this.baseClass, `${this.baseClass}-${this.iconName}`, 'ae-icon', 'svg-inline--fa', 'fa-w-16')

      if (!svgString) {
        this.$root.$el.removeChild(svgElem)
        return
      }

      svgElem.appendChild(svgSymbolElem)

      const { attributes, innerHTML } = getSvgContents(svgString)

      for (let attributeName in attributes) {
        svgSymbolElem.setAttribute(attributeName, attributes[attributeName])
      }

      svgSymbolElem.innerHTML = innerHTML

      return svgElem
    }

    this.$_getBaseClassAndIconName = () => {
      let baseClass
      let iconName

      const classes = this.$el.classList
      const noIconNames = this.Aeppic.VueComponentOptions && this.Aeppic.VueComponentOptions.aeIcon && this.Aeppic.VueComponentOptions.aeIcon.noIconNames || []

      for (let i = 0; i < classes.length; i++) {
        const cssClass = classes[i]

        if (/^fa[a-zA-Z]?$/.exec(cssClass)) {
          baseClass = cssClass
          continue
        }

        const iconNameMatch = /^fa-([a-zA-Z0-9-]+)$/.exec(cssClass)
        if (iconNameMatch && iconNameMatch[1] && noIconNames.indexOf(iconNameMatch[1]) === -1) {
          iconName = iconNameMatch[1]
        }

        if (iconName && baseClass) {
          break
        }
      }

      return { baseClass, iconName }
    }

    this.$_createStandaloneSvg = async () => {
      this.svgStandalone = await this.$_fetchIcon()
    }
  },
  mounted() {
    const { baseClass, iconName } = this.$_getBaseClassAndIconName()

    this.baseClass = baseClass
    this.iconName = iconName

    if (this.standalone) {
       this.$_createStandaloneSvg()
       return
    }

    this.$_fetchAndCreateSvgSymbol()
    
    if (typeof MutationObserver !== 'undefined') {
      const observer = new MutationObserver((mutationList) => {
        const { baseClass, iconName } = this.$_getBaseClassAndIconName()

        this.baseClass = baseClass
        this.iconName = iconName
      })
    
      observer.observe(this.$el, {
        attributeFilter: ['class'],
        attributes: true
      })
    } else {
      console.warn('[ae-icon]: MutationObserver not available. No support for v-if/v-else nor v-bind:class!')
    }
  }
}

function getSvgContents(svgString: string) {
  const container = (<any>document.createElement)('div', { force: true })
  const svg = (<any>document.createElementNS)('http://www.w3.org/2000/svg', 'svg', { force: true })

  container.appendChild(svg)
  container.firstChild.outerHTML = svgString

  const nodeAttributes = container.firstChild.attributes || {}
  const attributes = {}

  for (let i = 0; i < nodeAttributes.length; i++) {
    const attribute = nodeAttributes[i]
    attributes[attribute.name] = attribute.value
  }

  return {
    attributes,
    innerHTML: container.firstChild.innerHTML
  }
}
