import * as Types from '@aeppic/types'

export type RevisionType = 'user' | 'merge' | 'calculations' | 'formatting' | 'optional-fields'

export default class Revision {
  constructor(public data, public type: RevisionType,  public source: Types.ChangeSourceInfo) {
    Object.freeze(this)
    Object.freeze(this.source)
  }
}
