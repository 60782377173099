// import { importCommonJSExportsFromScript } from './import-commonjs.js'
// import { NamedExports, PackageExports, PackageInfo } from './types.js'

import { ImportPackageFileFunction, NamedExports, PackageExports, PackageInfo } from "./types.js"

// const PACKAGE_ASSET_PREFIX = 'package://'

export type Options = {
  pathToUrl: (packageReference: { id: string, v: string }, relativeFilePath: string) => string
  importFunction: ImportPackageFileFunction
}

export async function importSourceFile(packageInfo: PackageInfo, path: string, options: Options): Promise<PackageExports> {
  const relativeCodeSourcePath = packageInfo.exports[path]

  try {
    const exports = await options.importFunction(
      packageInfo.document,
      {
        filePath: relativeCodeSourcePath,
        type: packageInfo.type,
      }
    ) ?? {}

    const components: NamedExports = {}

    if (packageInfo.components) {
      const onlyOneComponentExported = packageInfo.components.length === 1

      if (onlyOneComponentExported) {
        const defaultComponent = packageInfo.components[0]
        components[defaultComponent] = exports[defaultComponent] ?? exports?.default ?? exports
      } else {
        for (const namedComponent of packageInfo.components) {
          components[namedComponent] = exports[namedComponent]
        }
      }
    }

    if ('Lifecycle' in exports) {
      await exports.Lifecycle?.onLoaded?.({
        resolvePathToUrl: (relativePath: string) => options.pathToUrl(packageInfo.document, relativePath)
      })
    }

    return {
      exports,
      components,
    }
  } catch (error) {
    console.error('Could not import source file', packageInfo, path, '->', relativeCodeSourcePath, ':', error.toString())
    
    return {
      exports: {},
      components: {},
    }
  }
}

// export async function importPackageExports(packageInfo: PackageInfo, path: string, options: Options): Promise<PackageExports> {
//   const relativeCodeSourcePath = packageInfo.exports[path]
  
//   const exports = await options.importFunction(
//     packageInfo.identifier,
//     relativeCodeSourcePath,
//     packageInfo.type 
//   ) ?? {}

//   const components: NamedExports = {}

//   for (const namedComponent of packageInfo.components) {
//     components[namedComponent] = exports[namedComponent]
//   }

//   if ('Lifecycle' in exports) {
//     await exports.Lifecycle?.onLoaded?.({
//       resolvePathToUrl: (relativePath: string) => options.pathToUrl(packageInfo.identifier, relativePath)
//     })
//   }

//   return {
//     exports,
//     components,
//   }
// }
// // async function loadLegacy(commonJsFile): NamedExports {
// //   const packageIdentifier = `Package ${this._packageDocument.data.name} (${this._packageDocument.id})`
// //   const mainFile = await this._fileLoader(this._packageDocument.id, info.main)
  
// //   const options = { amd: info.amd, globals: {
// //     Aeppic: this._aeppic?.contextify(packageIdentifier),
// //     ...getBrowserContextGlobals(),
// //   }}
  

// //   const components = []
// //   const exports = []
  
// //   const module = {
// //     packageRelativePath: info.main,
// //     exports
// //   }

// //   const scriptExports = loadExportsFromScript(this._packageDocument.id, mainFile, options)

// //   for (const [name, type] of Object.entries(info.exports)) {
// //     if (type === 'module') {
// //       exports[name] = extractNamedExport(scriptExports, name)
// //     } else if (type === 'vue') {
// //       components[name] = extractNamedExport(scriptExports, name)
// //     }
// //   }

// //   return {
// //     name: this._packageDocument.data.name,
// //     info,
// //     components,
// //     modules: [module],
// //   }
// // }


// // /**
// //  * Assume a `module.exports` or ESM module and 
// //  * 
// //  * @param exports 
// //  * @param name 
// //  * @returns 
// //  */
// // function extractNamedExport(exports: any, name: string) {
// //   if (exports?.__esModule) {
// //     return exports[name] ?? exports.default ?? exports
// //   } else {
// //     return exports?.[name] ?? exports
// //   }
// // }

// // //   async correctStylePaths(packageId: string, packageRelativeFilePath: string, css: string) {
// // //     const resolvableFileUrl = this.buildPackageRelativeUrl(packageId, packageRelativeFilePath)

// // //     let correctedCss = ''
    
// // //     const baseUrlRelativeToFileMatch = /.*?(?!.*\/)/.exec(resolvableFileUrl)
    
// // //     if (baseUrlRelativeToFileMatch) {
// // //       const baseUrlRelativeToFile = baseUrlRelativeToFileMatch[0]
// // //       correctedCss = css.replace(/url\(['"]?(?!.*data|http)(.*?)\)/g, `url(${baseUrlRelativeToFile}$1)`)
// // //     }

// // //     return correctedCss || css
// // //   }

// // //   async readTextFileFromPackage(packageId: string, packageRelativeFilePath: string): Promise<string> {
// // //     const resolvableScriptUrl = this.buildPackageRelativeUrl(packageId, packageRelativeFilePath)
// // //     const request = await this._options.load(resolvableScriptUrl)

// // //     if (!request.ok) {
// // //       throw new Error(`Could not load file ${resolvableScriptUrl} from package ${packageId}`)
// // //     }
    
// // //     return request.text()
// // //   }

// // //   buildPackageRelativeUrl(packageId: string, packageRelativeUrl: string): string {
// // //     assertUrlValidity(packageRelativeUrl)
    
// // //     if (typeof this._options.fileUrl === 'string') {
// // //       return `${this._options.fileUrl}/${packageId}/${packageRelativeUrl}`
// // //     } else if (typeof this._options.fileUrl === 'function') {
// // //       return this._options.fileUrl(packageId, packageRelativeUrl)
// // //     } else {
// // //       throw new Error('fileUrl not correctly defined. Should be a baseUrl or a function')
// // //     }
// // //   }
// // // }

// // // function assertUrlValidity(packageUrl: string) {
// // //   let valid = packageUrl != null && !packageUrl.startsWith('/') && !packageUrl.startsWith('http')

// // //   if (!valid) {
// // //     throw new Error('Url is not a valid url within a package. It needs to be a relative url.')
// // //   }
