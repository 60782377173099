import * as Types from '@aeppic/types'

import uuid from '@aeppic/shared/uuid'
import getNow from '@aeppic/shared/now'

import * as clone from './clone.js'

import { NewDocumentOptions } from './types.js'
import { Form } from './form.js'

export function newDocument(form: Form, parent: Types.Document, options: NewDocumentOptions, changeSourceInfo: Types.ChangeSourceInfo): Types.Document {
  let { isoTz, ticks } = getNow()

  if (!options.setTimestamps) {
    ticks = null
  }

  if (parent && parent.id !== 'root' && !parent.a) {
    console.warn('Parent should have ancestors since its not the root', parent)
  }

  let newDocumentAncestors = []
  let newDocumentAncestorForms = []
  let newDocumentInheritedLocks = []

  if (parent && parent.id !== 'root' && parent.a) {
    newDocumentAncestors = [...parent.a, parent.id]
    newDocumentAncestorForms = [...parent.a_forms, parent.f.id]
    newDocumentInheritedLocks = [...parent.inheritedLocks]
  }
  
  if (parent && parent.id !== 'root') {
    newDocumentInheritedLocks.push(parent.locks)
  }

  let id

  if (options && options.id) {
    id = options.id
  } else {
    id = uuid()
  }

  const validFirstCharacters = /^[a-z0-9]/.test(id)

  if (!validFirstCharacters) {
    throw new Error(`Document ids must start with lowercase letters or numbers => ${id}`)
  }

  const newDocument: Types.Document = {
    id,
    v: 'initial',
    p: parent ? parent.id : null,
    f: {
      id: form.id,
      v: form.v,
      text: <string>form.name
    },
    data: form.cloneDocumentData(form.info.default),
    locks: [],
    created: changeSourceInfo,
    modified: changeSourceInfo,
    t: ticks,
    ct: ticks,
    a_depth: newDocumentAncestors.length,
    a: newDocumentAncestors,
    a_forms: newDocumentAncestorForms,
    inheritedLocks: newDocumentInheritedLocks,
    stamps: [],
  }

  if (options.cloneOf) {
    newDocument.cloneOf = options.cloneOf
    newDocument.originalCloneOf = options.originalCloneOf || options.cloneOf
  }

  return newDocument
}

/** 
 * Clone a document perfectly. The cloned object will, by default, have the SAME id. Use `options.generateId` if an actually new document is desired.
 * This will also correctly set cloneOf, previous and version then. 
 */ 
export function cloneDocument(document: Types.Document, form: Form, options?: { id?: string, generateId?: boolean, data?: any, modified?: Types.ChangeSourceInfo, created?: Types.ChangeSourceInfo, setTimestamps?: boolean, fieldsNotToClone?: Array<string>, doNotCloneLocks?: boolean, hidden?: boolean, readonly?: boolean }): Types.Document {
  
  options = options || {}
  let data = document.data
  let created = options.created || clone.changeSource(document.created)
  let modified = options.modified || clone.changeSource(document.modified)
  const fieldsNotToClone = options && options.fieldsNotToClone
  
  if (options && options.data) {
    data = options.data
  }

  if (options.id === document.id) {
    options.id = null
  }

  const id = options.generateId ? uuid() : options.id || document.id 
  const version = (options.generateId || options.id) ? 'initial' : document.v
  const previous = (options.generateId || options.id) ? undefined : document.previous

  const cloneOf = (id === document.id) ? document.cloneOf : { id: document.id, v: document.v, text: document.data.name && typeof document.data.name === 'string' ? document.data.name.slice(0, 50) : '' }
  const originalCloneOf = document.originalCloneOf ? document.originalCloneOf : cloneOf

  const documentClone: Types.Document = {
    id,
    v: version,
    cloneOf,
    originalCloneOf,
    previous,
    p: document.p,
    f: { id: form.id, v: form.v, text: form.name },
    data: form.cloneDocumentData(data, fieldsNotToClone),
    locks: (options && options.doNotCloneLocks === true) ? [] : clone.lockRefs(document.locks),
    created,
    modified,
    t: options.setTimestamps ? new Date().valueOf() : null,
    ct: document.ct,
    a_depth: document.a_depth,
    a: document.a ? document.a.slice(0) : null,
    a_forms: document.a_forms ? document.a_forms.slice(0) : null,
    inheritedLocks: document.inheritedLocks ? clone.inheritedLocks(document.inheritedLocks) : null,
    stamps: [],
  }

  if (document.readonly) {
    documentClone.readonly = true
  }

  if (document.hidden) {
    documentClone.hidden = true
  }

  if (options.hidden != null) {
    documentClone.hidden = options.hidden
  }
  
  if (options.readonly != null) {
    documentClone.readonly = options.readonly
  }

  // Clone meta field information.
  // The modified field is NOT cloned 
  if (document.meta?.fields) {
    documentClone.meta = {
      fields: JSON.parse(JSON.stringify(document.meta.fields))
    }
  }

  if (id === document.id) {
    if (document.stampData) {
      documentClone.stampData = document.stampData
    }

    if (document.stamps) {
      documentClone.stamps = document.stamps
    }
  }

  return documentClone
}
