import Types from '@aeppic/types'
import { DateTime, Duration as DateTimeDuration, Interval as DateTimeInterval } from 'luxon'

import { EditableDocument } from '../model/index.js' 
import type { Write } from '../model/index.js' 
import { InMemoryLog, Logger, VoidLogger, buildLogger } from '../model/log.js'

import { runCode } from './dynamic-code.js'
import { asReference } from '../model/model.js'

export function RaiseUnsupportedFunction() {
  throw new Error('Unsupported function error')
}

const DEFAULT_ACTION_GLOBALS = {
  log: VoidLogger(),
  context: {},
  params: {},
  DateTime,
  DateTimeDuration,
  DateTimeInterval,
  Math,
  setTimeout: RaiseUnsupportedFunction,
  setInterval: RaiseUnsupportedFunction,
  window: undefined,
}

export interface ActionOptions {
  isolation: 'best' | 'vm' | 'none',
  index?: number
  Logger?: Logger,
  defaultGlobals?: ActionGlobals
}

export interface ActionResult {
  action: Types.Reference
  ok: boolean
  error?: string
  value?: any
  log?: object[]
  writes?: Write[]
}

export interface ExecuteOptions {
  checkMode?: boolean
  noIsolation?: boolean
  logLimit?: number
  verbose?: boolean
  navigation?: boolean
}

export interface ActionGlobals {
  [key: string]: any
}

export interface CommandActionContext {
  document: EditableDocument
  target: EditableDocument
  value: EditableDocument|any
  params: object
}

export const DEFAULT_ACTION_OPTIONS: ActionOptions = {
  isolation: 'best',
  index: -1,
}

export async function runActions(actions: Types.Document[], globals: ActionGlobals, specifiedOptions: Partial<ActionOptions> = {}): Promise<ActionResult[]> {
  const options: ActionOptions = { ...DEFAULT_ACTION_OPTIONS, ...specifiedOptions }

  const results: ActionResult[] = [] 
  let index = 0
  
  for (const action of actions) {
    if (!action) {
      throw new Error('Could not run unresolved action')
    }

    const currentActionResult = await runAction(action, globals, { ...options, index })
    
    results.push(currentActionResult)
    index += 1

    if (!currentActionResult.ok) {
      break
    }
  }

  return results
}

export async function runAction(action: Types.Document|EditableDocument, globals: ActionGlobals,  { isolation, index, Logger }: ActionOptions): Promise<ActionResult> {
  const log = buildLogger(Logger, { method: '_runAction', action: { id: action.id, v: action.v, text: action.data?.name ?? '' } })

  try {
    const actualGlobals = { ...DEFAULT_ACTION_GLOBALS, ...globals }
    return { action: asReference(action),  ... await runCode(action, actualGlobals, { isolation, Logger: log }) }
  } catch (error) {
    log.error({ type: 'action:run:error', error }, 'Error running action %s', action.id)
    return { action: asReference(action), ok: false, error: error.message }
  }
}