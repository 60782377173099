import * as Types from '@aeppic/types'

import uuid from '@aeppic/shared/uuid'
import version from '@aeppic/shared/version'
import getNow from '@aeppic/shared/now'

import stringify from 'fast-safe-stringify'

import { ActionGlobals } from 'dynamic/run-action.js'

import { NewDocumentOptions, ChangeDocumentFormOptions } from './types.js'

// import log from './log'
import * as clone from './clone.js' 
import { readId } from './id.js'

import { NotificationReason  } from './notification-reason.js'

import * as Document from './document.js'
import { EditableDocument } from './editable-document.js'
import { default as Revision, RevisionType } from './revision.js'

import { DocumentIndexer, BTreeDocumentIndex } from './indexing/index.js'
import { MatchOptions, MatchQueryFunction, Query, QueryMatcher, QueryBuilder, isQueryBuilder, isQuery, parseQueryAndFilter, Scopes, buildIteratorForGraph, isIteratorOverAllDocuments, enumerateGraphIterator, DocumentMatchIterator, GlobalQueryCache } from './query/index.js'

// import ChangeSourceInfo from './change-source-info.js'
import { default as ChangeTracker } from './change-tracker.js'
import type { Change } from './change-tracker.js'

import * as Watch from './document-watch.js'
import * as QueryWatch from './query-watch.js'

import type { FilterFunction } from './filter.js'
import { FormVersions, Form } from './form.js'
import { isForm, isStrictReference } from './is.js'
import { resolveDocumentPathSync } from './resolve-path.js'

import { Recycler, RECYCLER_ID } from './recycler.js'

import { isReference, isDocument, isReferenceField } from './is.js'

import { diff } from './diff.js'

export { FilterFunction, Watch, QueryWatch }

import * as sha256 from './hash/sha256.js'

import resolveField, { resolveFirstNonEmptyField } from './query/resolve-field.js'
import { deprecated } from '@aeppic/shared/deprecation'

export enum DocumentImportResult {
  Empty                     = 0,
  Imported                  = 1,
  Outdated                  = 2,
  Duplicate                 = 4,
  DeniedParentMissing       = 8,
  FormMissing               = 16,
  ImportFormsDirectoryRequiresForm = 32,
}

// const FORM_FORM = { id: 'form', v: 'bootstrap', previous: null, f: { id: 'form', v: 'bootstrap', text: 'Form' }, p: 'system-folder-forms-folder', data: { name: 'Form', definition: '[Name][name] [Description][description] [Definition][definition] [Functions][functions]' }, form: true, locks: [], created: { at: '', by: '', from: '', client: 'BOOTSTRAP' }, modified: { at: '', by: '', from: '', client: 'BOOTSTRAP' }, t: 1, ct: 1, a: [], a_depth: 0, a_forms: [], inheritedLocks: [] }
const FORM_FORM = { id: 'form', v: 'initial', previous: null, f: { id: 'form', v: 'initial', text: 'Form' }, p: 'system-folder-forms-folder', data: { name: 'Form', definition: '[Name][name] [Description][description] [Definition][definition] [Functions][functions]' }, form: true, locks: [], created: { at: '', by: '', from: '', client: 'BOOTSTRAP' }, modified: { at: '', by: '', from: '', client: 'BOOTSTRAP' }, t: 1, ct: 1, a: [], a_depth: 0, a_forms: [], inheritedLocks: [] }


const SCRUB_DELAY = 1000

const COMMITTED_DOCUMENTS_SCRUB_BEGIN = 500 // Begin scrubbing when this many items
const COMMITTED_DOCUMENTS_SCRUB_END   = 100 // Finish when only this many left
const COMMITTED_MAX_AGE = 5 * 60 * 1000     // How many minutes before items may get scrubbed 

function NOT_IMPLEMENTED(): never {
  throw new Error('Not implemented')
}
interface RefListChanges {
  added: Types.Reference[]
  updated: Types.Reference[]
  removed: Types.Reference[]
} 

export interface LockChanges {
  added: Types.Reference[]
  updated: Types.Reference[]
  removed: Types.Reference[]
}
export interface SaveOptions {
  // Provided a document has no merge conflicts of any sort the version can be pre-defined
  version?: string
  stampData?: Types.StampData
  /**
   * This flag is used to indicate that the modified source information
   * should NOT be updated. This has an affect when the Feature.MetaModified is enabled.
   * In this case the modified inside the meta field is changed instead of the default modified.
   * 
   * The use case for this is when the change to be saved does not
   * affect user data per-se and from the perspective of a user
   * the document was not modified in any meaningful manner.
   * 
   * This feature should only be used when it is reasonable to ensure that
   * this assumption is true. 
   * 
   * It is used for example when the feature MetaModified is enabled,
   * a document is moved, or a document's form is updated. These actions
   * do either not change the document, or are system actions usually not
   * performed by users. 
   * 
   * It could be used for refactoring of forms, where the data logically
   * remains, but might need to be refactored without changing the data from
   * the perspective of a user.
   * 
   * Note: Whenever fields inside data change any stamp applied will not validate anymore.
   */
  keepModified?: boolean
}

function asText(name: any): string {
  if (typeof name === 'string') {
    return name.slice(0, 50)
  }

  return ''
}

export function asReference(document: Types.Document | Types.Reference) {
  if (isStrictReference(document)) {
    return document
  }

  return {
    id: document.id,
    v: document.v,
    text: asText(document.data.name)
  }
}

// export type AccessCheckResult = 'OK' | 'ACCOUNT_UNKNOWN' | 'TARGET_UNKNOWN' | 'NO_KEYS' 

export interface ModelOptions {
  clientId?: string

  createFileURL?: typeof URL.createObjectURL

  /**
   * Whether or not to set `document.t` and `document.ct` based on local device time
   * 
   * document.t (lastModified Timestamp) and `.ct` (lastCreated Timestamp) should be set
   * only on the authoritative data store for a document. Usually this would be the server.
   */
  setTimestamps?: boolean

  /**
   * Import documents in order and don't try to ignore imported documents
   * based on `document.t` timestamp
   * 
   * Usually documents with a lesser document.t than the existing one are ignored.
   *
   * Note: If the existing `document.t` is `null` this would mean the data was created locally
   * and will be overwritten by imported documents. The local change would already be
   * communicated to the server anyway and the server/persistence layer will handle the conflict
   * resolution
   */
  ignoreTimestamp?: boolean

  defaultPageLimit?: number

  searchAllFields?: boolean

  dontFilterReads?: boolean

  /** 
   * In order to have more visibility on which options can be turned on/off via
   * typical feature flags. We now have features
   */
  features?: Feature[]|Set<Feature>

  /*
   * Optional callback to react to documents updated/deleted hard e.g for reference counting files
   */
  onDocumentAdded?: (document: Types.Document) => void
  onDocumentDeletedHard?: (document: Types.Document) => void
}

const DEFAULT_MODEL_OPTIONS: ModelOptions = {
  defaultPageLimit: 100
}

interface CommittedDocument {
  document: EditableDocument
  lastCommittedAt: number 
  lastAccessedAt: number
}


export type BasicFieldSortInfo = {
  descending?: boolean
  missingToEnd?: boolean
}

export type SingleFieldSortInfo = BasicFieldSortInfo & {
  field: string
}

export type MultiFallThroughFieldSortInfo = BasicFieldSortInfo & {
  // List of field names to sort by  
  fields: string[]
}

export type FallThroughFieldSortInfo = BasicFieldSortInfo & {
  // List of field names to sort by  
  fallthrough: string[]
}

export type FieldSortInfo = SingleFieldSortInfo | MultiFallThroughFieldSortInfo | FallThroughFieldSortInfo

export interface FindOptions {
  start?: number
  size?: number
  sort?: string | FieldSortInfo | (string | FieldSortInfo)[]
  field?: string
  fields?: string[]
  omitField?: string
  omitFields?: string[]
  searchAllFields?: boolean
  includeHidden?: boolean
  /** Milliseconds until timeout */
  timeout?: number
  /**
   * Find Operations can be tagged with an arbitrary string. It CAN be used to group queries against a backend to ensure
   * queries are efficiently batched together
   */
  tag?: string
}

export type PickFieldsOptions = { fields?: string[] } | { omitFields?: string[] }

export interface UndeleteOptions {
  isSingleUndelete: boolean 
  undeletePlaceholderId?: string
  newParentId?: string
}

export type GetOptions = {
  noImport?: boolean
  noLoad?: boolean
  getSpecificVersion?: boolean,
  include?: {
    // When getting data from the 
    // server each document will be
    // access checked for the specified
    // rights.
    //
    // Either true to check all rights
    // or an array of rights to check
    // 
    // The data is NOT returned with the document
    // it is cached in the access cache on the client
    //
    accessRights?: boolean|'all'|string[],
    controlIds?: boolean,
  }
}

export interface CloneOptions {
  id?: string
  fieldsNotToClone?: string[]
  doNotCloneLocks?: boolean
  setTimestamps?: boolean
  readonly?: boolean
  hidden?: boolean
}

export const DEFAULT_FIND_OPTIONS: FindOptions = {
  start: 0,
  size: 100
}

export interface EvictOptions {
  onlyDescendants?: boolean
}

export interface LimitedAccessOptions {
  includeAncestors?: 'readonly' | 'wiped'
  includeApps?: 'all' | Types.IdOrReference[]
  backedByRootDocumentId: string
}

export class Snapshot {
  public id = uuid()
  public createdAt = new Date().valueOf()
  public added = new Map<string, string>()
  public deleted = new Map<string, Types.Document>()
  public undeleted = new Map<string, Types.Document>()
  public updated = new Map<string, Types.Document>()
  public deletedHard = new Map<string, Types.Document>()
  
  constructor(private name: string) {
    Object.freeze(this)
  }

  has(id: string) {
    return (
      this.added.has(id) || this.deleted.has(id) || this.undeleted.has(id) || this.updated.has(id) || this.deletedHard.has(id)
    )
  }


  /**
   * Returns true if the given parentId was affected by changes. 
   * 
   * It is quite possible though that a document was added to a parent
   * and then moved to some other location. It will still be listed
   * in the original parent here. To find out the current location or
   * data perform a model lookup 
   * 
   * When a document was added, deleted or updated after this snapshot
   * but subsequently deleted hard before the next snapshot it will not
   * be listed in this snapshot though
   *
   * @param parentId
   * @returns
   */
  hasChangesInParent(parentId: string) {
    for (const [addedDocumentId, addedToParentId] of this.added) {
      if (parentId === addedToParentId) {
        return true
      }
    }
  }

  // @internal
  addDocument(id: string, parentId: string) {
    if (this.has(id)) {
      return
    }
    
    this.added.set(id, parentId)
  }

  // @internal
  deleteDocument(document: Types.Document) {
    if (this.has(document.id)) {
      return
    }

    this.deleted.set(document.id, document)
  }

  // @internal
  undeleteDocument(document: Types.Document) {
    if (this.has(document.id)) {
    }

    this.undeleted.set(document.id, document)
  }
  
  // @internal
  deleteDocumentHard(document: Types.Document) {
    if (this.added.has(document.id)) {
      this.added.delete(document.id)  
      return
    } else if (this.updated.has(document.id)) {
      this.updated.delete(document.id)  
      return
    } else if (this.deleted.has(document.id)) {
      this.deleted.delete(document.id)  
      return
    } else if (this.undeleted.has(document.id)) {
      this.undeleted.delete(document.id)  
      return
    } else if (this.deletedHard.has(document.id)) {
      return
    }

    this.deletedHard.set(document.id, document)
  }

  // @internal
  updateDocument(document: Types.Document) {
    if (this.has(document.id)) {
      return
    }

    this.updated.set(document.id, document)
  }

  get hasChanges() {
    return (
      this.added.size || this.deleted.size || this.undeleted.size || this.updated.size || this.deletedHard.size
    )
  }
}

export interface StampOptions {
  id?: string
  workflow?: Types.IdOrReference|Types.Document
  /* 
   * Used for correlating stamps on different documents belonging to the same workflow run
   */
  workflowUid?: string
  /*
   * In order to save extended information specific to the stamp
   * pass in a document of the appropriate type
   * 
   * The document data passed in will be encoded as an embedded additionalData
   * field. 
   * 
   * Adding a reference would be more complicated as it would require including
   * the additional document hash which is not yet supported.
   * 
   * If an id, reference, or document is passed in, the data is extracted from
   * those.
   * 
   * The encoded schema is `{ f: { ... }, data: { ... } }` where f links to
   * the form referenced by the stamp types form additionalDataForm form ref
   * 
   * The form id has to match or the stamping cannot succeed
   */
  additionalDataDocument?: { 
    f: Types.Reference,
    data: object
  }

  /*
   * Mark the stamp document as hidden. This is useful for stamps that are
   * not meant to be shown to the user and are for internal purposes.
   */
  hidden?: boolean

  comment?: string
}

export interface Write {
  type: 'save' | 'saveAll' | 'upgrade' | 'change' | 'delete' | 'deleteHard' | 'move' | 'stamp'
  arguments: any[]
}

//#region Feature
export enum Feature {
  MetaModified = 1,
  Adjust
}
//#endregion Feature

export default class Model {
  private _options: ModelOptions
  private _defaultFindOptions: FindOptions
  private _defaultMatchOptions: MatchOptions

  private _snapshots: Snapshot[] = []

  private _forms = new Map<string, FormVersions>()
  private _documents = new Map<string, Types.Document>()
  private _indexer = new DocumentIndexer()
  private _securityKeyIndex = new BTreeDocumentIndex('data.lock.id')
  private _committedDocuments = new Map<string, CommittedDocument>()
  private _scrubbing = {
    scheduled: false,
    pending: false
  }
  
  private _from = ''
  
  private _dynamic: {clientId: string, accountId: string, backedBy: string, impersonatedBy: string, limitOptions?: LimitedAccessOptions, lastStats?: any } = {
    clientId: '',
    accountId: '',
    backedBy: '',
    impersonatedBy: '',
    limitOptions: null,
    lastStats: null,
  }
  
  private _recycler = new Recycler()

  private _changeTracker = new ChangeTracker()
  private _watchNotifier = new Watch.Notifier()
  private _revisionWatchNotifier = new Watch.Notifier()
  private _queryWatchNotifier = new QueryWatch.Notifier()

  private _createFileURL: typeof DEFAULT_MODEL_OPTIONS.createFileURL
  private _getFormBound: any = null
  private _enabledFeatures = new Set<Feature>()
  
  private _isFeatureEnabled(feature: Feature): boolean {
    return this._enabledFeatures.has(feature)
  }

  // private _jsondiffpatch: ReturnType<JsonDiffPatch> = null

  constructor(options: ModelOptions) {
    GlobalQueryCache.reset()
    
    if (typeof global !== 'undefined') {
      global?.gc?.()
    }

    this._options = {...DEFAULT_MODEL_OPTIONS, ...options}

    // Copy features
    if (this._options.features) {
      for (let feature of this._options.features) {
        this._enabledFeatures.add(feature)
      }
    }

    if (this._enabledFeatures.size > 0) {
      console.log('Model:Features', Array.from(this._enabledFeatures.values()).join(','))
    }

    this._defaultFindOptions = { ...DEFAULT_FIND_OPTIONS, ...{ size: this._options.defaultPageLimit, searchAllFields: this._options.searchAllFields || false }}
    this._defaultMatchOptions = { searchAllFields: this._options.searchAllFields } // , lookupForm: this._options.lookupForm }

    this._dynamic.clientId = this._options.clientId || uuid()

    this._createFileURL = this._options.createFileURL

    this._addForm(FORM_FORM)

    this._indexer.createIndex('p')
    this._indexer.createIndex('f.id')
    this._indexer.createIndex('a')
    // this._indexer.createIndex('modified.at', { type: 'prefix', length: '0000-00-00T'.length })
    this._indexer.createIndex('data.name', { type: 'prefix', length: 5  })
    this._indexer.createIndex('data.accounts.id', { inclusionFilter: isKey })
    this._indexer.createIndex('data.groups.id', { inclusionFilter: isKey })

    this._getFormBound = this.getFormStrict.bind(this)
    // this._jsondiffpatch = new JsonDiffPatch()

    Object.preventExtensions(this)
    Object.freeze(this)

    function isKey(doc) {
      return doc && doc.f.id === 'key-form'
    }
  }

  clear() {
    this._recycler.clear()
    this._forms.clear()
    this._committedDocuments.clear()
    this._securityKeyIndex.clear()
    this._documents.clear()
    this._indexer.clear()
  }

  uuid() {
    return uuid()
  }
  
  listFeatures() {
    return Array.from(this._enumerateFeatures())
  }

  *_enumerateFeatures() {
    for (const enabledFeature of this._enabledFeatures.values()) {
       yield enabledFeature
    }
  }
  
  cleanupWatchers() {
    this._watchNotifier.cleanup()
    this._queryWatchNotifier.cleanup()
    this._revisionWatchNotifier.cleanup()
  }

  _readAccountInfoId(): string {
    if (this._dynamic.backedBy || this._dynamic.impersonatedBy || this._dynamic.limitOptions) {
      const limitOptions = this._readLimitOptionsId()
      return `A:${ this._dynamic.accountId }|B:${ this._dynamic.backedBy }|I:${ this._dynamic.impersonatedBy }${ limitOptions ? 'L:' + limitOptions : '' }`
    } else {
      return `A:${ this._dynamic.accountId }`
    }
  }

  _readLimitOptionsId(): string {
    if (!this._dynamic.limitOptions) {
      return ''
    } else {
      return `L:${this._dynamic.limitOptions.backedByRootDocumentId} [${this._dynamic.limitOptions.includeAncestors ? 'ANC' : ''}${this._dynamic.limitOptions.includeApps ? 'APP' : ''}]`
    }
  }

  setAccountInfo(account: string|Types.Reference, backedBy?: string|Types.Reference, impersonatedBy?: string|Types.Reference, limitOptions?: LimitedAccessOptions) {
    this._dynamic.accountId = toDocumentId(account) 
    this._dynamic.backedBy = toDocumentId(backedBy)
    this._dynamic.impersonatedBy = toDocumentId(impersonatedBy)
    this._dynamic.limitOptions = limitOptions
  }

  resetAccount() {
    this._dynamic.accountId = null
    this._dynamic.backedBy = null
    this._dynamic.impersonatedBy = null
    this._dynamic.limitOptions = null
  }

  @deprecated('Model', 'hasAccess', 'hasRight')
  hasAccess(targetDocumentIdentifier: string|Types.Reference, desiredRight?: Types.IdOrReference|Types.IdOrReference[]): boolean { 
    return this.hasRight(targetDocumentIdentifier, desiredRight as any)
  }
  
  hasRight(targetDocumentIdentifier: string|Types.Reference, desiredRight?: Types.IdOrReference): boolean
  hasRight(targetDocumentIdentifier: string|Types.Reference, desiredRights?: Types.IdOrReference[]): boolean
  hasRight(targetDocumentIdentifier: string|Types.Reference, arg2?: Types.IdOrReference|Types.IdOrReference[]): boolean {
    const targetDocument = this._getDocumentInsecure(targetDocumentIdentifier)
    const desiredRights = arg2 ? (Array.isArray(arg2) ? arg2 : [arg2]) : null 
    return this._hasRightTargetingDocument(targetDocument, desiredRights)
  }

  private _hasRightTargetingDocument(targetDocument: Types.Document, desiredRights: Types.IdOrReference[]): boolean { // , optionalAccountIdentifier?: string|Types.Reference): boolean {
     if (!targetDocument) {
      return false
    }

    if (!isLockedDocument(targetDocument)) {
      return true
    }

    const desiredRightsIds = desiredRights?.map(r => getDocumentId(r)) ?? null

    if (desiredRightsIds) {
      if (desiredRightsIds.length === 0) {
        throw new Error('No rights specified. If read access is desired do not pass in any rights (null/undefined)')
      }
      if (desiredRightsIds.some(r => !r)) {
        throw new Error('Invalid right specified')
      }
    }

    const accountIdentifier = /*optionalAccountIdentifier ||*/ this._dynamic.accountId 
    const backingAccountIdentifier = this._dynamic.backedBy
    
    const account = this._getDocumentInsecure(accountIdentifier)
    const backingAccount = this._getDocumentInsecure(backingAccountIdentifier)
    
    if (!account && !backingAccount) {
      return false
    } 
    
    if (isAdmin(account) || isAdmin(backingAccount)) {
      return true
    }

    // TODO: Better solution needed. Otherwise profile won't be findable
    //
    // if (isSensitiveDocument(targetDocument)) {
    //   return false
    // }

    if (this._hasAccountAccessToThisDocumentWithTheRights(account, targetDocument, desiredRightsIds)) {
      return true
    }

    // const keys = this._getAccountKeys(account)

    // if (this._keysAllowsAccessToDocumentAtLocation(keys, targetDocument, desiredRightsId)) {
    //   return true
    // }
    
    if (backingAccount) {
      const backingAccountKeys = this._getAccountKeys(backingAccount)

      if (this._dynamic.limitOptions && this._dynamic.limitOptions.backedByRootDocumentId) {
        if (targetDocument.id === this._dynamic.limitOptions.backedByRootDocumentId ||
            isDocumentDescendentOf(targetDocument, this._dynamic.limitOptions.backedByRootDocumentId)) {
          if (this._keysAllowsAccessToDocumentAtLocation(backingAccountKeys, targetDocument)) {
            return true
          }
        }
      } else {
        if (this._keysAllowsAccessToDocumentAtLocation(backingAccountKeys, targetDocument)) {
          return true
        }
      }
    }

    return false
  }

  private _findKeysForLocks(lockRefs: Types.Reference[], account: Types.Document): false|null|Types.Document[] {
    if (!lockRefs || lockRefs.length === 0) {
      return null
    }

    let matchingKeys = null 
    
    for (const lockRef of lockRefs) {
      const keys = this._securityKeyIndex.get(lockRef.id)

      if (!keys) {
        continue
      }

      const accountFilteredKeys = this._filterKeysForAccount(keys, account)

      if (accountFilteredKeys.length === 0) {
        continue
      }

      if (!matchingKeys) {
        matchingKeys = accountFilteredKeys
      } else {
        matchingKeys.push(...accountFilteredKeys)
      }
    }

    if (matchingKeys === null) {
      return false
    } else {
      // console.log('Found matching keys (INH)', lockRefs, matchingKeys.map(k => [k.id, k.data.lock.id]))
      return matchingKeys
    }
  }

  private _filterKeysForAccount(keys: Types.Document[], account: Types.Document) {
    const filtered = []
    let accountGroups = null

    for (const key of keys) {
      let hasAccess = false

      if (key.data.accounts == null) {
        console.warn(`Key: ${key.id} needs to be upgraded. Missing accounts field`)
        continue
      }

      for (const accountRef of <Types.Reference[]> key.data.accounts) {
        if (accountRef.id === account.id) {
          filtered.push(key)
          hasAccess = true
          break
        }
      }

      if (hasAccess) {
        continue
      }

      if (accountGroups === null) {
        accountGroups = this.getAccountGroups(account)
      }

      if (accountGroups.length === 0) {
        continue
      }

      if (key.data.groups == null) {
        console.warn(`Key: ${key.id} needs to be upgraded. Missing groups field`)
        continue
      }

      for (const groupRef of <Types.Reference[]> key.data.groups) {
        for (const accountGroup of accountGroups) {
          if (groupRef.id === accountGroup.id) {
            filtered.push(key)
            hasAccess = true
            break
          }
        }

        if (hasAccess) {
          break
        }
      }
    }

    return filtered
  }

  private _hasAccountAccessToThisDocumentWithTheRights(account: Types.Document, targetDocument: Types.Document, desiredRights?: Types.IdOrReference[]): boolean {
    // console.log('testing', targetDocument.inheritedLocks, targetDocument.locks)
    
    let closestMatchingKeys = null
    
    for (const inheritedLocksSlice of targetDocument.inheritedLocks) {
      // console.log('testing', inheritedLocksSlice, account.id)
      closestMatchingKeys = this._findKeysForLocks(inheritedLocksSlice, account)

      if (closestMatchingKeys === false) {
        return false
      }

      // console.log('Keys for locks (DIR)', inheritedLocksSlice, closestMatchingKeys.map(k => [k.id, k.data.lock.id]))
    }

    if (targetDocument.locks && targetDocument.locks.length > 0) {
      closestMatchingKeys = this._findKeysForLocks(targetDocument.locks, account)

      if (closestMatchingKeys === false) {
        return false
      }

      // console.log('Keys for locks (DIR)', targetDocument.locks, closestMatchingKeys.map(k => [k.id, k.data.lock.id]))
    }

    if (closestMatchingKeys === null) {
      // console.log('No matching keys found')
      return true
    }

    // Rights
    if (desiredRights) {
      for (const desiredRight of desiredRights) {
        const desiredRightId = getDocumentId(desiredRight)

        for (const key of closestMatchingKeys) {
          // Direct rights on the key
          for (const rightRef of key.data.rights) {
            if (rightRef.id === desiredRightId) {
              // console.log('Found direct rights on the key', desiredRightId, key.id, key.data.lock.id)
              return true
            }
          }

          // Role based rights
          if (key.data.roles) {
            for (const roleRef of key.data.roles as Types.Reference[]) {
              const role = this.get(roleRef)

              if (!role) {
                continue
              }

              for (const rightRef of role.data.rights as Types.Reference[]) {
                if (rightRef.id === desiredRightId) {
                  // console.log('Found role based rights on the key', desiredRightId, key.id, key.data.lock.id)
                  return true
                }
              }
            }
          }
        }
      }

      // console.log('No matching rights found', desiredRights)
      return false
    }

    // console.log('No rights specified')
    return true
  }

  private _keysAllowsAccessToDocumentAtLocation(keys: Types.Document[], targetDocument: Types.Document, desiredRightsId?: string) {
     // Direct locks
     if (this._keysAllowAccess(keys, targetDocument.locks, desiredRightsId) === false) {
      return false
    }

    // Inherited locks
    for (const locks of targetDocument.inheritedLocks) {
      if (this._keysAllowAccess(keys, locks) === false) {
        return false
      }
    }

    // Closest inherited locks to the target document is rightmost `inheritedLocks`
    if (desiredRightsId && !targetDocument.locks.length) {
      for (let i = targetDocument.inheritedLocks.length; i >= 0; i -= 1) {
        const locks = targetDocument.inheritedLocks[i]

        if (locks == null || locks.length === 0) {
          continue
        }

        if (this._keysAllowAccess(keys, locks, desiredRightsId) === false) {
          return false
        }

        break
      }
    }

    return true
  }

  private _keysAllowAccess(keys, locks: Types.Reference[], desiredRightsId?: string) {
    if (!locks || locks.length === 0) {
      return true
    }

    for (const lock of locks) {
      for (const key of keys) {
        if (key.data.lock && key.data.lock.id === lock.id) {
          if (!desiredRightsId) {
            return true
          }

          for (const right of key.data.rights) {
            if (right.id === desiredRightsId) {
              // Ensure the right actually exists. Not usable in incomplete models. 
              if (this._documents.has(right.id)) {
                return true
              }
            }
          }
        }
      }
    }

    return false
  }

  getAccountKeys(accountIdentifier: string|Types.Reference) {
    const account = this.get(accountIdentifier)
   
    return this._getAccountKeys(account)
  }

  private _getAccountKeys(account: Types.Document) {
    if (!account || !account.data.keys) {
      return []
    }

    const keysTowardsAccount = this._getMatchingDocuments('data.accounts.id', account.id)
    const keysFromAccount = this._getAllDocumentsInsecure(<Types.Reference[]>account.data.keys)
    const indirect = Array.from(this._enumerateIndirectAccountKeys(account))

    const all = [...keysTowardsAccount, ...keysFromAccount, ...indirect] .filter(k => k != null)
    return all
  }

  private *_enumerateIndirectAccountKeys(account: Types.Document): IterableIterator<Types.Document> {
    const groups = this._getAccountGroups(account)
    const keys = new Set<string>()

    for (const g of groups) {
      yield *this._getMatchingDocuments('data.groups.id', g.id)
      yield *this._getAllDocumentsInsecure(<Types.Reference[]>g.data.keys)
    }
  }

  private *_getMatchingDocuments(field: string, value: string): IterableIterator<Types.Document> {
    for (const documentId of this._indexer.indexMap[field].getEntries(value)) {
      yield this._documents.get(documentId)
    }
  }

  public getAccountGroups(accountIdentifier: Types.IdOrReference): Types.Document[] {
    const account = this._getDocumentInsecure(accountIdentifier)
    
    if (!account) {
      return null
    }

    return this._getAccountGroups(account)
  }

  private _getAccountGroups(account: Types.Document) {
    const directGroups = this._getAllDocumentsInsecure(<Types.Reference[]> account.data.groups).filter(g => g != null)
    const allGroups = Array.from(this._walkGroups(directGroups))

    // TODO: Automatic assignment
    // for (const group of this.find('f.id:ab0bb646-2445-4eb3-8784-67691dbe7c08')) { // TODO: potentially limit to common ancestor along 'Security' line
    // }

    return allGroups
  }

  private *_walkGroups(groups: Types.Document[], previousGroupIds = new Set<string>()): IterableIterator<Types.Document> {
    for (const g of groups) {
      if (previousGroupIds.has(g.id)) {
        // console.warn('Duplicate group', g.id, Array.from(previousGroupIds.keys()), )
        continue
      }

      previousGroupIds.add(g.id)
      yield g

      const parentGroupRef = <Types.Reference> g.data.group
      const parentGroup = this._getDocumentInsecure(parentGroupRef)

      if (parentGroup) {
        yield *this._walkGroups([parentGroup], previousGroupIds)
      }
    }
  }

  get numberOfDocuments() {return this._documents.size}
  get numberOfForms() {return this._forms.size}

  get Recycler() {return this._recycler}

  restoreFromRecycler(documentId, parentId?: string) {
    const changes = []

    if (!this.Recycler.contains(documentId)) {
      return
    }

    let restoreTo

    for (const info of this.Recycler.restore(documentId)) {
      if (info.document.id === documentId) {
        // root item: gets moved to restore position
        restoreTo = parentId || info.parent
        
        if (!restoreTo) {
          console.error('cannot restore from recycler. no parent specified ')
          return
        }

        changes.push(...this._moveDocument(info.document, restoreTo))
      }
      else {
        // descendant item: gets imported
        this.import(info.document)
      }
    }
    return changes
  }
  
  restoreSingleDocumentFromRecycler(documentId, parentId?: string) {
    return this._restoreSingleDocumentFromRecycler(documentId, parentId)
  }

  private _restoreSingleDocumentFromRecycler(documentId: string, parentId?: string, placeholderId?: string) {
    const changes = []

    if (!this.Recycler.contains(documentId)) {
      return
    }

    const document = this.Recycler.get(documentId)

    // TODO: this is not going to work when the placeholder has been created on another client
    //       because recycler is not synced between clients right now. for the same reason,
    //       this case should not occurre currently
    let placeholderFolder = placeholderId && this.Recycler.get(placeholderId)

    for (const child of this.Recycler.enumerateChildren(document)) {
      if (!placeholderFolder) {
        const name = document.data.name || this.getDocumentForm(document).name
        const recyclerParent = this.Recycler.get(document.p) || this.get('recycler')
        
        const newPlaceholderFolder = this.new('folder', recyclerParent, {allowMissingParent: true})
        newPlaceholderFolder.data.name = `_undeleted ${name} (${document.id})`
        changes.push(...this.save(newPlaceholderFolder))

        placeholderFolder = newPlaceholderFolder.cloneAsDocument()
        placeholderFolder.a = newPlaceholderFolder.a.slice(0)
        placeholderFolder.a_forms = newPlaceholderFolder.a_forms.slice(0)
        placeholderFolder.a_depth = newPlaceholderFolder.a_depth
        // TODO: clone locks/inherited from original document instead
        placeholderFolder.inheritedLocks = newPlaceholderFolder.inheritedLocks.slice(0)

        console.log('===> created new placeholder folder', placeholderFolder.id)
      }

      const moveChanges = this._moveDocument(child, placeholderFolder, { parentIsInRecycler: true })
      changes.push(...moveChanges)
      this.Recycler.replace(child.id, moveChanges[0].document)
    }

    const info = this.Recycler.getRecycledInfo(documentId)
    const newParent = parentId || info && info.originalParentId

    if (!newParent) {
      throw new Error('no parent specified for restore from recycler')
    }
    
    changes.push(...this._moveDocument(document, newParent, {isSingleUndelete: true, undeletePlaceholderId: placeholderFolder && placeholderFolder.id}))
    
    if (placeholderFolder) {
      this.Recycler.replace(documentId, placeholderFolder)
    }
    else {
      this.Recycler.remove(documentId)
    }

    return changes
  }

  

  getAll(identifier: (Types.IdOrReference | Types.Document)[]): Types.Document[] {
    if (!identifier) {
      return []
    }

    return identifier.map(i => this.get(i))
  }

  private _getAllDocumentsInsecure(identifier: Types.IdOrReference[]): Types.Document[] {
    if (!identifier) {
      return []
    }

    return identifier.map(i => this._getDocumentInsecure(i))
  }

  get(identifier: Types.IdOrReference | Types.Document, options?: GetOptions): Types.Document {
    if (!identifier) {
      return null
    }

    let id, v

    if (isReference(identifier)) {
      id = identifier.id
      v  = identifier.v  
    }
    else {
      id = identifier
    }

    if (options && options.getSpecificVersion && v) {
      return this._getDocumentWithVersion(id, v)
    }
    else {
      return this._getDocument(id)
    }
  }

  private _getDocumentWithVersion(id, v) {
    const doc = this._getDocument(id)

    if (!v || doc.v === v) {
      return doc
    }

    if (doc.f.id !== 'form') {
      return null
    }

    const formVersions = this._forms.get(id)

    if (!formVersions) {
      return null
    }

    const form = formVersions.get(v)

    if (form) {
      return form.document
    } else {
      return null
    }
  }

  watch(identifier: Types.IdOrReference, callback: Watch.DocumentWatchCallback): Watch.IDocumentWatcher {
    const watchedDocumentId = readId(identifier)

    const watcher = this._watchNotifier.watch(watchedDocumentId, callback)
    const document = this.get(identifier)

    if (document) {
      this._watchNotifier.notifySpecificWatcher(watcher, document, NotificationReason.None)
    }

    return watcher
  }

  watchAll(callback: Watch.DocumentWatchCallback): Watch.IDocumentWatcher {
    return this._watchNotifier.watchAll(callback)
  }

  watchRevisions(identifier: Types.IdOrReference, callback: Watch.DocumentWatchCallback): Watch.IDocumentWatcher {
    const watchedDocumentId = readId(identifier)

    const watcher = this._revisionWatchNotifier.watch(watchedDocumentId, callback)

    // const document = this.getLatestRevision(identifier)

    // if (document) {
    // setTimeout(() => {
    //   watcher.notify(document, NotificationReasons.None)
    // }, 0)
    // }

    return watcher

    // TODO: Keep list of latest revision of each document so we can publish it here in the initial call
  }

  watchAllRevisions(callback: Watch.DocumentWatchCallback): Watch.IDocumentWatcher {
    return this._revisionWatchNotifier.watchAll(callback)
  }

  notifyWatchersAboutRevision(document: Types.Document, reason: NotificationReason) {
    return this._revisionWatchNotifier.notifyWatchers(document, reason)
  }

  getLatestRevision(identifier: Types.IdOrReference) {

  }

  watchMatchingDocuments(query: string | FilterFunction<Types.Document>, callback: Watch.DocumentWatchCallback): QueryWatch.IQueryWatcher {
    const filterFn = this._buildFilterFunction(query)
    return this._queryWatchNotifier.add(filterFn, callback)
  }

  flushAllPendingNotifications() {
    const w = this._watchNotifier.flushPendingNotifications()
    const r = this._revisionWatchNotifier.flushPendingNotifications()
    const q = this._queryWatchNotifier.flushPendingNotifications()

    return Promise.all([w, r, q])
  }

  editAll(identifiers: Types.IdOrReference|Types.IdOrReference[]) {
    if (Array.isArray(identifiers)) {
      return identifiers.map(i => this.edit(i))
    } else {
      return [this.edit(identifiers)]
    }
  }

  edit(identifier: Types.IdOrReference): EditableDocument {
    const document = this._getDocument(identifier)

    if (!document) {
      return null
    }

    const form = this.getDocumentForm(document)
    return this._newEditableDocument(document, form, { insertOrUpdate: false })
  }

  private _newEditableDocument(document: Types.Document, form: Form = null, { insertOrUpdate }: { insertOrUpdate: boolean } ) {
    const f = form || this.getDocumentForm(document)
    return new EditableDocument(document, f, { createFileURL: this._createFileURL, insertOrUpdate })
  }

  private _getDocument(identifier: Types.IdOrReference): Types.Document {
    const document = this._getDocumentInsecure(identifier)

    if (!document) {
      return document
    }
    
    if (this._dontFilterCurrently) {
      return document
    } else {
      if (this.hasRight(document)) {
        return document
      } else {
        return null
      }
    }
  }

  private _getDocumentInsecure(identifier: Types.IdOrReference): Types.Document {
    if (!identifier) {
      return null
    }

    if (typeof identifier === 'string') {
      return this._documents.get(identifier) || null
    } else {
      return this._documents.get(identifier.id) || null
    }
  }

  import(documentToImport: Types.Document, options?: { addIntoFormsDirectoryOnly?: boolean }): DocumentImportResult {
    if (options && options.addIntoFormsDirectoryOnly && documentToImport.f.id !== 'form') {
      return DocumentImportResult.ImportFormsDirectoryRequiresForm
    }

    // // In case of meta modified support we might need to make a change to the document.
    // // Provided we have the previous version of the document we can
    // if (this._isFeatureEnabled(Feature.MetaModified)) {
    //   const existingDocument = this._documents
    // }
    
    return this._import(documentToImport, options)
  }

  private _import(documentToImport: Types.Document, options?: { addIntoFormsDirectoryOnly?: boolean, useLegacyLoadingProtocol?: boolean }): DocumentImportResult {
    let document = documentToImport
    

    const useLegacyLoadingProtocol = options && options.useLegacyLoadingProtocol

    if (EditableDocument.isEditableDocument(documentToImport)) {
      // console.warn('Dont import editable documents')
      throw new Error('Editable documents must be saved not imported')
    }

    let importResult = DocumentImportResult.Empty

    const documentToImportHasServerTimestamp = !!documentToImport.t

    const modelVersion = this._documents.get(document.id)
    
    if (modelVersion && documentToImportHasServerTimestamp) {
      const modelVersionHasServerTimestamp = !!modelVersion.t
      const importedVersionIsExactSameVersion = modelVersion.id === documentToImport.id && modelVersion.v === documentToImport.v

      if (modelVersionHasServerTimestamp && importedVersionIsExactSameVersion) {
        importResult |= DocumentImportResult.Duplicate

        const formForModelVersion = this.getDocumentForm(modelVersion) == null

        if (formForModelVersion) {
          importResult |= DocumentImportResult.FormMissing
        }

        return importResult
      }
    }

    const isFormMissing = this.getDocumentForm(document) == null

    if (isFormMissing) {
      importResult |= DocumentImportResult.FormMissing 
    }

    const isFormItself = isForm(document)

    if (isFormItself) {
      this._addForm(document)

      if (options && options.addIntoFormsDirectoryOnly) {
        return
      }
    }

    const isParentKnown = this.isDocumentParentKnown(document, { includeRecycler: true })

    if (!isParentKnown) {
      if (isFormItself) {
        // TODO: Log ?
      } else {
        importResult |= DocumentImportResult.DeniedParentMissing
        return importResult
      }
    }
        
    if (modelVersion) {
      const versionNumberOfImportDocumentPredecessor = documentToImport.previous && documentToImport.previous.v
      
      const isDirectSuccessor = modelVersion.v === versionNumberOfImportDocumentPredecessor
      
      if (!isDirectSuccessor && documentToImportHasServerTimestamp) {
        const isOlderThanModelVersion = !modelVersion.t ? false : modelVersion.t > documentToImport.t 

        if (isOlderThanModelVersion) {
          importResult |= DocumentImportResult.Outdated
          return importResult
        }
      }
    }

    this._setDocument(document, modelVersion)
    importResult |= DocumentImportResult.Imported

    this._options.onDocumentAdded?.(document)

    return importResult
  }

  buildTemporaryForm(formDocument: Types.Document): Form {
    return new Form(formDocument, { version: version(), temporary: true })
  }

  buildTemporaryEditableDocument(form: Form): EditableDocument {
    const changeSourceInfo = this._buildNewChangeSourceInfo()
    const root = this.get('root')
    const document = Document.newDocument(form, root, { setTimestamps: this._options.setTimestamps }, changeSourceInfo)
    return this._newEditableDocument(document, form, { insertOrUpdate: false })
  }

  public isDocumentParentKnown(document: Types.Document, options = { includeRecycler: false }) {
    if (document.id !== 'root') {
      if (!this._documents.has(document.p)) {
        if (options.includeRecycler) {
          if (this._recycler.contains(document.p)) {
            return true
          }
        }
        return false
      }
    }

    return true
  }

  public isDocumentFormKnown(document: Types.Document) {
    return isWellKnownForm(document.f) || this._isKnownForm(document.f)
  }

  mergeFields(base: Types.Document, a: Types.Document, b: Types.Document) {
    // if (formsDidNotChange(base, a, b)) {
    //   return this._mergeFieldsFromSourceOntoTarget(base, a, b)
    // } else {
    //   throw new Error('Form changes are not yet supported')
    // }
    return this._mergeFieldsFromSourceOntoTarget(base, a, b)
  }

  /**
   * Merges more recent data into an editable document 
   */
  mergeChangesIntoEditableDocument(document: EditableDocument, changedSourceDocument: Types.Document) {
    if (!EditableDocument.isEditableDocument(document)) {
      throw new Error('Can only merge editable documents')
    }

    if (!changedSourceDocument) {
      throw new Error('Requires a new base document to update the document to it')
    }

    const baseDocument = document.__base
    const mergeResult = this.mergeFields(baseDocument, document, changedSourceDocument)

    if (!mergeResult) {
      this._clearRevisions(document)
      return
    }

    if (mergeResult.bChanged || mergeResult.formChanged || mergeResult.parentChanged) {
      const form = mergeResult.formChanged ? this.getDocumentForm(changedSourceDocument) : this.getDocumentForm(document)
      
      // Mark all previous changes in the editable document as a user revision 
      this._addRevision(document, 'user')

      document.updateBase(changedSourceDocument, form, mergeResult.data)

      // Marke the last change as the result of a merge
      this._addRevision(document, 'merge')
    }
  }

  // forms identical
  private _mergeFieldsFromSourceOntoTarget(base: Types.Document, a: Types.Document, b: Types.Document) {
    // const form = this.getForm(base.f)
    const form = this.getFormStrict(b.f)
    const data = {}

    let aChanged = false
    let bChanged = false
    let formChanged = !formsDidNotChange(base, a, b)
    let parentChanged = !parentsDidNotChange(base, a, b)
    let anyChangesDetected = formChanged || parentChanged

    for (const fieldInfo of form.fields) {
      const fieldHasChangedInA = Form.hasFieldChanged(fieldInfo, base.data[fieldInfo.name], a.data[fieldInfo.name])
      const fieldHasChangedInB = Form.hasFieldChanged(fieldInfo, base.data[fieldInfo.name], b.data[fieldInfo.name])

      aChanged ||= fieldHasChangedInA
      bChanged ||= fieldHasChangedInB

      let mergedValue

      // TODO: Add conflict case, right now `a` wins
      let undefinedCase = false
      
      if (fieldHasChangedInB) {
        mergedValue = b.data[fieldInfo.name]
      }
      
      if (fieldHasChangedInA) {
        mergedValue = a.data[fieldInfo.name]
      }
      
      let aUndefined = a.data[fieldInfo.name] === undefined
      let bUndefined = b.data[fieldInfo.name] === undefined

      undefinedCase = aUndefined || bUndefined

      if (!undefinedCase) {
        if (this._canMergeField(fieldInfo.name, a, b)) {
          mergedValue = this._mergeArrayFields(fieldInfo, base.data[fieldInfo.name], a.data[fieldInfo.name], b.data[fieldInfo.name])
        } 
        else if (formChanged) {
          mergedValue = Form.cloneFieldValue(fieldInfo, b.data[fieldInfo.name])
        }
        else if (fieldHasChangedInA) {
          mergedValue = Form.cloneFieldValue(fieldInfo, a.data[fieldInfo.name])
        } else if (fieldHasChangedInB) {
          mergedValue = Form.cloneFieldValue(fieldInfo, b.data[fieldInfo.name])
        }
      }

      if (fieldHasChangedInA || fieldHasChangedInB) {
        data[fieldInfo.name] = mergedValue
        anyChangesDetected = true
      } else {
        data[fieldInfo.name] = base.data[fieldInfo.name]
      }
    }

    if (anyChangesDetected) {
      return {
        aChanged,
        bChanged,
        formChanged,
        parentChanged,
        data
      }
    } else {
      return null
    }
  }

  private _canMergeField(fieldName, a, b) {
    const fieldA = this.getFormStrict(a.f).getField(fieldName)
    const fieldB = this.getFormStrict(b.f).getField(fieldName)

    return fieldA && fieldA.cardinality && (fieldA.type === fieldB.type)
  }

  private _mergeArrayFields(fieldInfo, targetBase, target, source) {
    const result = []

    // add all items from target that were not removed from source
    if (target) {
      for (const item of target) {
        const isContainedInTargetBase = targetBase.some(item2 => !Form.hasSingleValueChanged(fieldInfo, item, item2))
        const isContainedInSource = source.some(item3 => !Form.hasSingleValueChanged(fieldInfo, item, item3))
        const wasRemovedFromSource = isContainedInTargetBase && !isContainedInSource

        if (!wasRemovedFromSource) {
          result.push(Form.cloneSingleFieldValue(fieldInfo, item))
        }
      }
    }

    // add all items from source, that are not already contained in target
    if (source) {
      for (const item of source) {
        const isContainedInTargetBase = targetBase.some(item2 => !Form.hasSingleValueChanged(fieldInfo, item, item2))
        const isContainedInTarget = target.some(item3 => !Form.hasSingleValueChanged(fieldInfo, item, item3))
        const wasRemovedFromTarget = isContainedInTargetBase && !isContainedInTarget

        if (!isContainedInTarget && !wasRemovedFromTarget) {
          result.push(Form.cloneSingleFieldValue(fieldInfo, item))
        }
      }
    }

    return result
  }

  getDocumentForm(document: Types.Document): Form {
    return this.getFormStrict(document.f.id, document.f.v)
  }

  getMostRecentForm(id: string): Form {
    const mostRecentFormDocument = this._getDocument(id)
    const versions = this._forms.get(id)
    
    if (!mostRecentFormDocument || !versions) {
      // `Unknown form: ${id}`
      return null
    }
    
    const form = versions.get(mostRecentFormDocument.v)

    if (!form) {
      // throw new Error(`Unknown form version: ${id}@${mostRecentFormDocument.v}`)
      return null
    }

    return form
  }

  getFormVersions(identifier: Types.IdOrReference | Types.Document): string[] {
    let id = getDocumentId(identifier)

    const knownFormVersions = this._forms.get(id)
    return knownFormVersions.getVersions()
  }

  getForm(identifier: Types.IdOrReference | Types.Document, version?: string): Form {
    let id: string

    if (typeof identifier === 'string') {
      id = identifier
    } else {
      id = identifier.id
      version = version || identifier.v
    }

    const knownFormVersions = this._forms.get(id)

    if (!knownFormVersions) {
      return null
    }

    if (!version) {
      console.error('No form version specified to get')
      return null
    }

    const form = knownFormVersions.get(version)

    if (!form) {
      return null
    }

    return form
  }

  getFormStrict(identifier: Types.IdOrReference | Types.Document, version?: string): Form {
    let id: string

    if (typeof identifier === 'string') {
      id = identifier
    } else {
      id = identifier.id
      version = version || identifier.v
    }

    const knownFormVersions = this._forms.get(id)

    if (!knownFormVersions) {
      return null
      // throw new Error(`Unknown form: ${id}`)
    }

    const form = knownFormVersions.get(version)

    // if (!form) {
    //   throw new Error(`Unknown form version ${id}@${version}`)
    // }

    return form
  }

  getFormForDocument(document: Types.Document | EditableDocument): Form {
    if (EditableDocument.isEditableDocument(document)) {
      return document.form
    }

    return this.getForm(document.f)
  }

  takeSnapshot(name: string = '') {
    this._snapshots.push(new Snapshot(name))
  }

  get hasSnapshots() {
    return this._snapshots.length > 0
  }

  getAllSnapshots() {
    return [...this._snapshots]
  }

  getLastSnapshot() {
    if (this.hasSnapshots) {
      return this._snapshots[this._snapshots.length - 1]
    }
  }

  deleteAllSnapshots() {
    this._snapshots.length = 0 
  }

  stamp(target: Types.Document, stampTypeIdentifier: Types.IdOrReference|Types.Document, options?: StampOptions): Change[] {
    if (!target) {
      const stampId = getDocumentId(stampTypeIdentifier)
      throw new Error(`No target specified for stampType ${stampId}`)
    }

    if (EditableDocument.isEditableDocument(target)) {
      throw new Error('Cannot stamp EditableDocuments since they might merge later and invalidate the stamp. Get a reference or a saved document instead.')
    }
   
    const stampType = this.get(stampTypeIdentifier)
    
    if (stampType.f.id !== 'stamp-type') {
      throw new Error(`Stamp must specify a type of 'stamp-type' and not ${JSON.stringify(stampType)}`)
    }

    const stampDocument = this.new('stamp', target, { id: options?.id })
    stampDocument.data.type = this.asReference(stampType)

    if (options?.hidden) {
      stampDocument.hidden = true
    }

    const hashedReference = { ...this.asReference(target), hash: this.calculateHash(target) }
    
    const stampData: Types.StampData = {
      documents: [hashedReference]
    }

    if (options?.workflow) {
      stampDocument.data.workflow = this.asReference(options.workflow)
    }

    if (options?.workflowUid) {
      stampDocument.data.workflowUid = options.workflowUid
    }
        
    if (options?.additionalDataDocument) {
      const { data: stampTypeData } = stampType
      const additionalDataFormRef = <Types.Reference> stampTypeData.additionalDataForm
      
      if (options.additionalDataDocument.f.id !== additionalDataFormRef.id) {
        throw new Error(`Additional data of type ${options.additionalDataDocument.f.id} as specified is not supported by stamp-type. Expected ${additionalDataFormRef.id}`)
      }
      
      const { f, data } = options.additionalDataDocument

      stampDocument.data.additionalData = JSON.stringify({ f, data })
    } else {
      if (stampType.data.requiresData) {
        throw new Error(`Additional data required by ${stampType.data.name} (${stampType.id})`)
      } 
    }

    if (options?.comment) {
      stampDocument.data.comment = options.comment
    } else {
      if (stampType.data.requiresComment) {
        throw new Error(`Comment required by ${stampType.data.name} (${stampType.id})`)
      }
    }

    return this._saveEditableDocument(stampDocument, { stampData })
  }

  calculateHash(document: Types.Document, options?: { type?: string, alternateForm?: Types.Reference }): Types.HashInfo {
    if (options && options.type !== 'sha256') {
      throw new Error('Not implemented')
    }

    const hash = sha256.create()
    
    const formRefToInclude = (options && options.alternateForm) || document.f

    hash.update(JSON.stringify(formRefToInclude))
    hash.update(JSON.stringify(document.data))

    return {
      type: 'sha256',
      form: formRefToInclude,
      digest: hash.digest(),
      modified: this._calculateModifiedDigest(document),
    }
  }

  /**
   * Calculate a hash corresponding to the document.modified field
   * 
   * @param document 
   * @returns 
   */
  private _calculateModifiedDigest(document: Types.Document): Types.HexadecimalHashDigest {
    const modifiedHash = sha256.create()    
    modifiedHash.update(JSON.stringify(document.modified))
    
    const modifiedDigest = modifiedHash.digest()
    return modifiedDigest
  }

  verifyStamp(targetDocument: Types.Document, stampDocument: Types.Document) {
    const hashedReference = stampDocument.stampData.documents.find((ref: Types.Reference) => ref.id === targetDocument.id)

    if (!hashedReference) {
      return { verified: false, error: 'Document not included in stamp' }
    }

    if (!hashedReference.hash) {
      return { verified: false, error: 'Document included but not hashed' }
    }

    const recomputedHash = this.calculateHash(targetDocument, { type: hashedReference.hash.type })

    // Exactly the same
    const sameModified = recomputedHash.modified === hashedReference.hash.modified
    const sameDataAndForm = recomputedHash.digest === hashedReference.hash.digest
    const sameVersion = targetDocument.v === hashedReference.v
    
    const modifiedChanged = !sameModified
    const versionChanged = !sameVersion

    if (sameDataAndForm) {
      if (sameModified) {
        if (sameVersion) {
          return { verified: true }
        } else {
          return { verified: false, dataSame: true, versionChanged, message: 'Version changed' }
        }
      } else {
        return { verified: false, dataSame: true, formChanged: false, versionChanged, modifiedChanged, message: 'Modified changed' }
      }
    }

    const recomputedHashWithFormIdFromOriginalHash = this.calculateHash(targetDocument, { type: hashedReference.hash.type, alternateForm: hashedReference.hash.form })

    if (recomputedHashWithFormIdFromOriginalHash.digest === hashedReference.hash.digest) {
      const formIdSame = targetDocument.f.id === hashedReference.hash.form.id
      return { verified: false, dataSame: true, modifiedChanged, versionChanged, formChanged: true, formIdSame, message: 'Form changed', originalForm: hashedReference.hash.form }
    }

    return { verified: false, modifiedChanged, versionChanged }
  }

  save(document: Types.Document, options: SaveOptions = {}): Change[] {
    if (!EditableDocument.isEditableDocument(document)) {
      throw new Error('We can only save documents editable documents ( use edit() or new() )')
    }

    const changes = this._saveEditableDocument(document, options)
    
    this._committedDocuments.delete(document.id)

    return changes
  }

  saveDetachedVersion(document: Types.Document | EditableDocument) {
    
    this._changeTracker.startTracking()
    const changeSource = this._buildNewChangeSourceInfo()

    if (EditableDocument.isEditableDocument(document)) {
      this.commit(document as EditableDocument)
    }

    const form = this.getDocumentForm(document)
    const detachedDocumentVersion = Document.cloneDocument(document, form, { modified: changeSource, setTimestamps: this._options.setTimestamps })
    // previous ?!

    const changes = this._changeTracker.addedDetachedVersion(detachedDocumentVersion)

    if (isForm(document)) {
      this.import(document, {addIntoFormsDirectoryOnly: true})
    }

    return this._changeTracker.stopTracking()
  }

  commit(document: EditableDocument) {
    this._committedDocuments.set(document.id, {
      document,
      lastCommittedAt: Date.now(),
      lastAccessedAt: Date.now(),
    })

    this._scheduleScrubOfCommittedDocuments()

    return this._addRevision(document, 'user')
  }

  private async _scheduleScrubOfCommittedDocuments() {
    if (this._scrubbing.scheduled) {
      this._scrubbing.pending = true
      return
    }

    this._scrubbing.scheduled = true
    this._scrubbing.pending = false

    setTimeout(() => {
      try {
        this._scrubCommittedDocuments()
      } catch (error) {
        console.error('Error during scrub of old committed documents', error)
      }

      this._scrubbing.scheduled = false

      if (this._scrubbing.pending) {
        this._scheduleScrubOfCommittedDocuments()
      }
    }, SCRUB_DELAY)
  }

  private _scrubCommittedDocuments() {
    if (COMMITTED_DOCUMENTS_SCRUB_END > COMMITTED_DOCUMENTS_SCRUB_BEGIN) {
      console.warn('Error in scrubbing configuration')
    }

    if (this._committedDocuments.size < COMMITTED_DOCUMENTS_SCRUB_BEGIN) {
      return
    }

    // console.log('Performing scrub', this._committedDocuments.size)
    
    const committedDocuments = Array.from(this._committedDocuments.values())
    committedDocuments.sort((a, b) => a.lastAccessedAt - b.lastAccessedAt)

    const now = Date.now()

    for (const committedDocument of committedDocuments) {
      const age = now - committedDocument.lastAccessedAt

      if (age > COMMITTED_MAX_AGE) {
        this._committedDocuments.delete(committedDocument.document.id)
      }

      if (this._committedDocuments.size <= COMMITTED_DOCUMENTS_SCRUB_END) {
        break
      }
    }

    if (this._committedDocuments.size > COMMITTED_DOCUMENTS_SCRUB_BEGIN * 10) {
      console.warn('WARNING: Very many recent committed documents that have not been saved')
    }
  }

  private _clearRevisions(document: EditableDocument) {
    document.clearRevisions()
  }

  private _addRevision(document: EditableDocument, source: RevisionType): boolean {
    if (!EditableDocument.isEditableDocument(document)) {
      throw new Error('Only editable documents can save and have revisions')
    }

    const form = this.getDocumentForm(document)
    const changeInfo = this._buildNewChangeSourceInfo()

    let hasChanged = false

    if (form.hasChanged(document.data, document.__base.data)) {
      hasChanged = true

      const revisionData = form.cloneDocumentData(document.data)
      form.freezeData(revisionData)

      const newRevision = new Revision(revisionData, source, changeInfo)
      document.addRevision(newRevision)
    }

    document.recalculateAndValidate()

    // if (form.hasFormatting()) {
    //   const formattingChanges = form.applyFormatting(document.data)

    //   if (formattingChanges) {
    //     hasChanged = true

    //     const formattedData = form.cloneDocumentData(document.data)
    //     form.freezeData(formattedData)

    //     const newRevision = new Revision(formattedData, 'formatting', changeInfo)
    //     document.addRevision(newRevision)
    //   }
    // }

    // if (form.hasOptionalFields({ expressionsOnly: true })) {
    //   const optionalFieldsChanges = form.applyOptionalFieldRules(document.data)

    //   if (optionalFieldsChanges) {
    //     hasChanged = true

    //     const optionalFieldsData = form.cloneDocumentData(document.data)
    //     form.freezeData(optionalFieldsData)

    //     const newRevision = new Revision(optionalFieldsData, 'optional-fields', changeInfo)
    //     document.addRevision(newRevision)
    //   }
    // }

    if (    (document.hidden ?? false) != (document.__base.hidden ?? false)
        ||  (document.readonly ?? false) != (document.__base.readonly ?? false)) {
      hasChanged = true

      const newRevision = new Revision({}, source, changeInfo)
      document.addRevision(newRevision)
    }

    const locks = new Set(document.locks?.map(l => l.id))
    const oldLocks = new Set(document.__base.locks?.map(l => l.id))

    let locksChanged = false

    if (locks.size !== oldLocks.size) {
      hasChanged = true
      locksChanged = true
    } else {
      for (const lock of locks) {
        if (!oldLocks.has(lock)) {
          hasChanged = true
          locksChanged = true
          break
        }
      }
    }

    if (locksChanged) {
      const newRevision = new Revision({}, source, changeInfo)
      document.addRevision(newRevision)
    }

    if (form.hasCalculations()) {
      const calculationsCausedChanges = form.updateCalculatedFields(document.data)

      if (calculationsCausedChanges) {
        hasChanged = true

        const calculatedFieldsData = form.cloneDocumentData(document.data)
        form.freezeData(calculatedFieldsData)

        const newRevision = new Revision(calculatedFieldsData, 'calculations', changeInfo)
        document.addRevision(newRevision)
      }
    }

    if (hasChanged) {
      this._revisionWatchNotifier.notifyWatchers(document, NotificationReason.Revised)
    }

    return hasChanged
  }

  canUpgrade(identifier: Types.IdOrReference) {
    const document = this.get(identifier)
    const mostRecentForm = this.getMostRecentForm(document.f.id)

    if (!mostRecentForm) {
      return false
    }

    return document.f.v !== mostRecentForm.v
  }

  upgrade(identifier: Types.IdOrReference) {
    const document = this.get(identifier)
    const targetForm = this.getMostRecentForm(document.f.id)
    return this._changeDocumentForm(document, targetForm)
  }

  change(identifier: Types.IdOrReference, newForm: Types.IdOrReference, options: ChangeDocumentFormOptions = {}) {
    const targetForm = this.getFormStrict(newForm)

    if (!targetForm) {
      throw new Error('Unknown Form')
    }
    
    return this._changeDocumentForm(identifier, targetForm, options)
  }

  private _changeDocumentForm(identifier: Types.IdOrReference, targetForm: Form, options?: ChangeDocumentFormOptions) {
    if (!targetForm) {
      throw new Error('Cannot change form (doesn\'t exist or has been deleted)')
    }

    const document = this.edit(identifier)
    const originalForm = this.getDocumentForm(document)

    if (document.f.id === targetForm.id && document.f.v === targetForm.v) {
      return
    }

    const newData = targetForm.cloneDocumentData(targetForm.info.default)

    if (options && options.initialData) {
      targetForm.copyFieldsFromDifferentForm(newData, options.initialData, originalForm)
    } else {
      targetForm.copyFieldsFromDifferentForm(newData, document.data, originalForm)
    }

    const parentDocument = this.get(document.p)
    const originalCreatedChangeSource = clone.changeSource(document.created)
    const originalModifiedChangeSource = clone.changeSource(document.modified)

    const newDocumentBasedOnTargetForm = Document.newDocument(targetForm, parentDocument, { id: document.id, setTimestamps: this._options.setTimestamps }, originalCreatedChangeSource)

    const newChangeSource = this._buildNewChangeSourceInfo()

    newDocumentBasedOnTargetForm.v = version()
    newDocumentBasedOnTargetForm.modified = newChangeSource
    newDocumentBasedOnTargetForm.previous = { v: document.v, f: clone.ref(document.f) }
    newDocumentBasedOnTargetForm.data = newData
    newDocumentBasedOnTargetForm.locks = clone.lockRefs(document.locks)
    newDocumentBasedOnTargetForm.stampData = clone.stampData(document.stampData)

    if (this._isFeatureEnabled(Feature.MetaModified)) {
      newDocumentBasedOnTargetForm.meta = newDocumentBasedOnTargetForm.meta ?? {}
      newDocumentBasedOnTargetForm.meta.modified = newDocumentBasedOnTargetForm.modified
      newDocumentBasedOnTargetForm.modified = originalModifiedChangeSource
    }

    document.updateBase(newDocumentBasedOnTargetForm, targetForm, newData)

    this._saveEditableDocument(document, { keepModified: true })

    this._changeTracker.startTracking()
    this._changeTracker.changedForm(newDocumentBasedOnTargetForm)

    const changes = this._changeTracker.stopTracking()

    return changes
  }

  deleteHard(identifier: Types.IdOrReference) {
    this._changeTracker.startTracking()

    const documentId = readId(identifier)

    if (documentId === 'root') {
      throw new Error('Cannot delete root')
    }

    this._recycler.remove(documentId)

    const descendants = this.find(Scopes.descendants(documentId))

    for (const d of descendants) {
      this._removeDocument(d)

      this._watchNotifier.notifyWatchers(d, NotificationReason.DeletedHard)
      this._queryWatchNotifier.notifyWatchers(d, NotificationReason.DeletedHard)

      this._options.onDocumentDeletedHard?.(d)
    }
    
    const document = this._documents.get(documentId)

    if (document) {
      this._removeDocument(document)
      
      this._watchNotifier.notifyWatchers(document, NotificationReason.DeletedHard)
      this._queryWatchNotifier.notifyWatchers(document, NotificationReason.DeletedHard)

      if (isForm(document)) {
        const versions = this._forms.get(document.id)
        versions.delete()
      }

      this._snapshotDocumentDeletedHard(document)

      this._changeTracker.deletedHard(document)

      this._options.onDocumentDeletedHard?.(document)

      // TODO: What if this is a key etc. Check caches ?
      if (document.f.id === 'stamp') {
        this._deleteStampFromPrimaryTarget(document)
      }
    }

    return this._changeTracker.stopTracking()
  }

  private _insertDocument(document: Types.Document) {
    this._documents.set(document.id, document)
    this._indexer.add(document)

    if (document.f.id === 'key-form') {
      this._securityKeyIndex.add(document)
    }
  }

  private _removeDocument(document: Types.Document) {
    if (!document) {
      return
    }

    if (this._documents.has(document.id)) {
      if (document.f.id === 'key-form') {
        this._securityKeyIndex.remove(document)
      }

      this._indexer.remove(document)
      this._documents.delete(document.id)
    } 

    this.Recycler.remove(document.id)
  }

  hasChildren(identifier: Types.IdOrReference) {
    const documentId = readId(identifier)

    const childrenIterator = this._findDocuments(Scopes.children(documentId), { size: 1 })
    const firstResult = childrenIterator.next()

    return !firstResult.done
  }

  delete(identifier: Types.IdOrReference) {
    const documentId = readId(identifier)

    if (documentId === RECYCLER_ID) {
      throw new Error('Cannot delete recycler')
    }

    return this.move(documentId, RECYCLER_ID)
  }

  undelete(identifier: Types.IdOrReference, options: UndeleteOptions) {
    
    const documentId = readId(identifier)

    if (options && options.isSingleUndelete) {
      return this._restoreSingleDocumentFromRecycler(documentId, options.newParentId, options.undeletePlaceholderId)
    }
    else {
      return this.restoreFromRecycler(documentId, options && options.newParentId)
    }
  }


  move(identifier: Types.IdOrReference, newParentIdentifier: Types.IdOrReference) {
    if (!newParentIdentifier) {
      throw new Error('Not enough arguments for move')
    }

    const documentId = readId(identifier)
    const newParentId = readId(newParentIdentifier)
    const document = this._documents.get(documentId)
    
    if (!document) {
      // throw new Error(`Cannot move document ${documentId} it is not known`)
      return
    }

    return this._moveDocument(document, newParentIdentifier)
  }

  private _moveDocument(document: Types.Document, newParentIdentifier: Types.IdOrReference, options?) {
    this._changeTracker.startTracking()
    let changes: Change[] = []

    try {
      const newParentId = readId(newParentIdentifier)

      if (!document) {
        throw new Error(`Cannot move document - not specified`)
      }

      if (document.p == null || document.p === '') {
        // throw new Error(`Cannot move document without parent`)
        return null
      }

      if (document.p === newParentId) {
        return null
      }

      let newParent = this._documents.get(newParentId)

      if (!newParent && options && options.parentIsInRecycler) {
        newParent = this.Recycler.get(newParentId)
      }

      if (!newParent) {
        // throw new Error(`Cannot move document ${documentId}. Parent ${newParentId} is not known`)
        return null
      }

      if (document.id === newParent.id) {
        // throw new Error(`Cannot move document ${documentId}. Parent ${newParentId} is itself `)
        return null
      }

      // if (!options.force && !this.isDocumentWithFormAllowedAsChild(document.f, newParent)) {
      //   throw new Error(`Cannot move document with form '${document.f.id}' into parent '${newParent.id}'. Parent's form is restricted.` )
      // }

      if (newParentAncestryContains(document.id, newParent.a)) {
        // throw new Error(`Cannot move document ${documentId}. Parent ${newParentId} is descendant of document`)
        return null
      }

      const form = this.getDocumentForm(document)
      const changeSource = this._buildNewChangeSourceInfo()

      const movedDocument = Document.cloneDocument(document, form, { modified: changeSource, setTimestamps: this._options.setTimestamps })
      movedDocument.p = newParentId
      movedDocument.v = version()
      movedDocument.previous = { v: document.v, p: document.p, a: [...document.a], a_forms: [...document.a_forms] }

      if (this._isFeatureEnabled(Feature.MetaModified)) {
        movedDocument.meta = movedDocument.meta ?? {}
        movedDocument.meta.modified = movedDocument.modified
        movedDocument.modified = clone.changeSource(document.modified)
      }

      this._setDocument(movedDocument, document)

      if (newParentId === RECYCLER_ID) {
        this._snapshotDocumentDeleted(document)

        this._changeTracker.deleted(movedDocument)
      } else if ((document.a[0] === RECYCLER_ID) && (movedDocument.a[0] !== RECYCLER_ID)) {

        const isSingleUndelete = options && options.isSingleUndelete
        const undeletePlaceholderId = options && options.undeletePlaceholderId
        this._snapshotDocumentUndeleted(document)
        this._changeTracker.undeleted(movedDocument, isSingleUndelete, undeletePlaceholderId)
      } 
      else {
        if (isForm(movedDocument)) {
          this._addForm(movedDocument)
        }

        this._snapshotDocumentUpdated(document)

        this._changeTracker.moved(movedDocument)
      }
    } finally {
      changes = this._changeTracker.stopTracking()
    }

    return changes
  }

  private _updateDescendantsInheritance(document: Types.Document, previousVersion ?: Types.Document) {
    if (previousVersion) {
      let updateInheritance = false

      if (document.p !== previousVersion.p
          || document.f.id !== previousVersion.f.id
          || didAncestorsChange(document, previousVersion)
          || didLocksChange(document, previousVersion)
      ) {
        updateInheritance = true
      }

      if (!updateInheritance) {
        return
      }
    }

    const children = this.enumerateChildren(document.id)

    for (const child of children) {
      this._setDocument(child)
    }
  }

  public clone(identiferOrReference: Types.IdOrReference, options ?: CloneOptions): EditableDocument
  public clone(identiferOrReference: Types.IdOrReference, parentIdentiferOrReference ?: Types.IdOrReference, options ?: CloneOptions): EditableDocument
  public clone(identiferOrReference: Types.IdOrReference, ...args: any[]): EditableDocument {
    const documentToClone = this._getDocument(identiferOrReference)

    if (!documentToClone) {
      throw new Error('Could not find document to clone')
    }

    let parentIdentiferOrReference: Types.IdOrReference = null
    let options: CloneOptions = {}

    if (args.length === 0) {
      parentIdentiferOrReference = documentToClone.p
    } else if (args.length === 2) {
      parentIdentiferOrReference = args[0] || documentToClone.p
      options = args[1] || {}
    } else if (args.length === 1) {
      const onlyOptionalArg = args[0]

      if (typeof onlyOptionalArg === 'string' || isReference(onlyOptionalArg)) {
        parentIdentiferOrReference = onlyOptionalArg || documentToClone.p
      } else {
        parentIdentiferOrReference = documentToClone.p
        options = onlyOptionalArg || {}
      }
    } else {
      throw new Error('Incorrect number of arguments')
    }

    const parentToUse = parentIdentiferOrReference == null ? documentToClone.p : readId(parentIdentiferOrReference)

    const form = this.getDocumentForm(documentToClone)

    const changeSource = this._buildNewChangeSourceInfo()

    const fieldsNotToClone = options && options.fieldsNotToClone
    const doNotCloneLocks = options && options.doNotCloneLocks
    
    const clonedDocument = Document.cloneDocument(documentToClone, form, { id: options.id, generateId: !options.id, created: changeSource, modified: changeSource, setTimestamps: options.setTimestamps ?? this._options.setTimestamps, fieldsNotToClone, doNotCloneLocks, readonly: options.readonly, hidden: options.hidden })
    clonedDocument.p = parentToUse

    this._updateInheritedProperties(clonedDocument)

    return this._newEditableDocument(clonedDocument, form, { insertOrUpdate: false })
  }

  public cloneDeep(identiferOrReference: Types.IdOrReference, parentIdentiferOrReference ?: Types.IdOrReference, options ?: { formFieldsNotToClone?: Object, identifiersNotToClone?: Types.IdOrReference[], readonly?: boolean, hidden?: boolean }) {
    const self = this

    const identifiersNotToClone = options?.identifiersNotToClone?.map(identifier => isReference(identifier) ? identifier.id : identifier)

    const result = cloneDeepInternal(identiferOrReference, parentIdentiferOrReference, { formFieldsNotToClone: options?.formFieldsNotToClone, identifiersNotToClone, readonly: options?.readonly, hidden: options?.hidden })

    const descendantsById = {}
    for (let doc of result.documents) {
      descendantsById[doc.id] = doc
    }

    for (let doc of result.documents) {
      rewriteReferences(doc, result.copyMap, descendantsById)
    }

    return result

    function cloneDeepInternal(identiferOrReference, parentIdentiferOrReference, { formFieldsNotToClone, identifiersNotToClone, readonly, hidden }) {
      const originalRoot = self._getDocument(identiferOrReference)

      if (!originalRoot) {
        const id = identiferOrReference && isReference(identiferOrReference) ? identiferOrReference.id : identiferOrReference
        throw new Error(`Could not find document root to clone: ${id}`)
      }

      const result = {
        copyMap: {},
        root: null,
        documents: []
      }
      
      const originalRootNotToClone = identifiersNotToClone?.some(id => originalRoot.id === id || originalRoot.a.includes(id))
      if (originalRootNotToClone) {
        return result
      }

      const originalRootForm = self.getDocumentForm(originalRoot)

      let cloneOptions: CloneOptions = {
        readonly,
        hidden,
      }

      if (formFieldsNotToClone?.[originalRootForm.id]) {
        cloneOptions.fieldsNotToClone = formFieldsNotToClone[originalRootForm.id]
      }

      result.root = self.clone(originalRoot, parentIdentiferOrReference, cloneOptions)
      result.copyMap[originalRoot.id] = result.root.id
      result.documents.push(result.root)

      const children = self.enumerateChildren(originalRoot.id)
      for (let child of children) {
        const childResult = cloneDeepInternal(child, result.root, { formFieldsNotToClone, identifiersNotToClone, readonly, hidden })

        result.documents.push(...childResult.documents)
        for (let originalId in childResult.copyMap) {
          result.copyMap[originalId] = childResult.copyMap[originalId]
        }
      }

      return result
    }

    function rewriteReferences(document: EditableDocument, mapping, newDescendants) {
      const form = self.getFormStrict(document.f)

      for (let fieldInfo of form.fields) {
        if (document.data[fieldInfo.name] === undefined) {
          continue
        }

        if (!fieldInfo.cardinality) {
          document.data[fieldInfo.name] = rewriteSingleValue(document.data[fieldInfo.name], fieldInfo, mapping, newDescendants)
        }
        else {
          for (let i = 0; i < document.data[fieldInfo.name].length; i++) {
            document.data[fieldInfo.name][i] = rewriteSingleValue(document.data[fieldInfo.name][i], fieldInfo, mapping, newDescendants)
          }
        }
      }

      for (let i = 0; i < document.locks.length; i ++) {
        const originalLockId = document.locks[i].id

        const newId = originalLockId ? mapping[originalLockId] : null
        const newVersion = newId ? newDescendants[newId].v : null

        if (newId && newVersion) {
          document.locks[i].id = newId
          document.locks[i].v = newVersion
        }
      }

      // TODO: rewrite stampData
    }

    function rewriteSingleValue(value, fieldInfo, mapping, newDescendants) {
      if (value == null) {
        return value
      }

      if (fieldInfo.type === 'object') {
        if (fieldInfo.subType !== 'ref') {
          return value
        }

        if (value.id === '') {
          return value
        } 

        const originalId = value.id
        
        const newId = originalId ? mapping[originalId] : null
        const newVersion = newId ? newDescendants[newId].v : null
        
        return (newId && newVersion) ? {id: newId, v: newVersion, text: value.text} : value
      }

      if (fieldInfo.type === 'string') {
        return value ? replaceIdsInString(value, mapping) : value
      }

      return value
    }

    function replaceIdsInString(s, mapping) {
      for (let id in mapping) {
        const newId = mapping[id]
        s = s.split(id).join(newId)
      }
      return s
    }
  }

  public isAllowedChild(formIdentifier: Types.IdOrReference, parentIdentifier: Types.IdOrReference) {
    const parent = this.get(parentIdentifier)
    return this.isDocumentWithFormAllowedAsChild(formIdentifier, parent)
  }

  public isDocumentWithFormAllowedAsChild(documentFormIdentifier: Types.IdOrReference, parent: Types.Document) {
    const childFormId = readId(documentFormIdentifier)
    const parentForm = this.getDocumentForm(parent)

    if (parentForm.document.data.allowAllChildren === undefined &&
      parentForm.document.data.allowedChildrenForms === undefined) {
      return true
    }

    if (parentForm.document.data.allowAllChildren === true) {
      return true
    } else {
      const allowedChildrenForms = parentForm.allowedChildrenForms

      if (allowedChildrenForms) {
        for (const allowedFormRef of allowedChildrenForms) {
          if (childFormId === allowedFormRef.id) {
            return true
          }
        }
      }
    }

    return false
  }

  public asReference(documentIdentifier: string | Types.Document | Types.Reference) {
    const document = this.get(documentIdentifier)

    if (!document) {
      return { id: '', v: '', text: '' }
    }

    return asReference(document)
  }

  private _snapshotDocumentAdded(document: Types.Document) {
    const lastSnapshot = this.getLastSnapshot()

    if (lastSnapshot) {
      lastSnapshot.addDocument(document.id, document.p)
    }
  }

  private _snapshotDocumentUpdated(document: Types.Document) {
    const lastSnapshot = this.getLastSnapshot()

    if (lastSnapshot) {
      lastSnapshot.updateDocument(document)
    }
  }
  
  private _snapshotDocumentDeleted(document: Types.Document) {
    const lastSnapshot = this.getLastSnapshot()

    if (lastSnapshot) {
      lastSnapshot.deleteDocument(document)
    }
  }

  private _snapshotDocumentUndeleted(document: Types.Document) {
    const lastSnapshot = this.getLastSnapshot()

    if (lastSnapshot) {
      lastSnapshot.undeleteDocument(document)
    }
  }

  private _snapshotDocumentDeletedHard(document: Types.Document) {
    const lastSnapshot = this.getLastSnapshot()

    if (lastSnapshot) {
      lastSnapshot.deleteDocumentHard(document)
    }
  }

  private _saveEditableDocument(document: EditableDocument, options: SaveOptions = {}): Change[] {
    this._changeTracker.startTracking()

    let changes: Change[] = []

    try {
      this.commit(document) // commit to update calculated fields

      const modelDocument = this._documents.get(document.id)

      if (!modelDocument) {
        // unknown document, should be new and not inside the recycler
        if (document.previous) {
          throw new Error('Cannot save document since document is not known. Can only save new documents or changes to known documents')
        } else if (!this._recycler.contains(document.id)) {
          const changeSource = this._buildNewChangeSourceInfo()

          const form = this.getDocumentForm(document)
          const newModelDocument = Document.cloneDocument(document, form, { modified: changeSource, created: changeSource, setTimestamps: this._options.setTimestamps })
          
          if (options.stampData) {
            if (newModelDocument.v !== 'initial') {
              throw new Error('Cannot assign stamp data to documents that are not version `initial`')
            }
            newModelDocument.stampData = options.stampData
          }

          const importResult = this._import(newModelDocument)

          if (importResult === DocumentImportResult.Imported) {
            this._snapshotDocumentAdded(newModelDocument)

            document.updateBase(newModelDocument, form)
            document.clearRevisions()

            this._changeTracker.updated(newModelDocument, { insertOrUpdate: document.insertOrUpdateMode })
          } else {
            throw new Error(`Could not import document. DocumentImportResult: ${importResult}`)
          }
        } else {
          console.warn('Cannot save changes. Document has been deleted and is inside the recycler.')
        }
      } else if (formHasChanged(document, modelDocument)) {
        const keepModified = options.keepModified && this._isFeatureEnabled(Feature.MetaModified) 
        const changeSource = keepModified ? document.modified : this._buildNewChangeSourceInfo()

        const form = this.getDocumentForm(document)
        const newModelDocument = Document.cloneDocument(document, form, { modified: changeSource, setTimestamps: this._options.setTimestamps })
   
        if (keepModified) {
          newModelDocument.meta = newModelDocument.meta ?? {}
          newModelDocument.meta.modified = this._buildNewChangeSourceInfo()
        }

        const importResult = this._import(newModelDocument)

        if (importResult === DocumentImportResult.Imported) {
          this._snapshotDocumentAdded(newModelDocument)

          document.updateBase(newModelDocument, form)
          document.clearRevisions()

          this._changeTracker.updated(newModelDocument, { insertOrUpdate: document.insertOrUpdateMode })
        } else {
          throw new Error(`Could not import document. DocumentImportResult: ${importResult}`)
        }
      } else {
        const baseDocument = document.__base

        const dataChanges = this.mergeFields(baseDocument, document, modelDocument)
        const lockChanges = mergeLockChanges(modelDocument, document)
        
        const readonlyChanged = toBoolean(document.readonly) !== toBoolean(baseDocument.readonly)
        const hiddenChanged = toBoolean(document.hidden) !== toBoolean(baseDocument.hidden)

        const documentChanged = !!dataChanges || readonlyChanged || hiddenChanged

        if (!documentChanged && !lockChanges) {
          return []
        }

        if (formHasChanged(document, modelDocument)) {
          throw new Error('Referenced form must not have changed')
        }

        if (lockChanges && !documentChanged) {
          // only locks changed
          const form = this.getDocumentForm(modelDocument)
          
          const updatedModelDocument = Document.cloneDocument(modelDocument, form, { setTimestamps: this._options.setTimestamps })
          
          updatedModelDocument.v = version()
          updatedModelDocument.previous = {
            v: modelDocument.v
          }
          
          applyListChanges(lockChanges, updatedModelDocument.locks)
          
          updatedModelDocument.previous.locks = clone.lockRefs(modelDocument.locks)

          const importResult = this._import(updatedModelDocument)
          if (importResult === DocumentImportResult.Imported) {
            this._snapshotDocumentUpdated(modelDocument)

            document.updateBase(updatedModelDocument, form)
            document.clearRevisions()

            this._changeTracker.updated(updatedModelDocument, { insertOrUpdate: document.insertOrUpdateMode })
          } else {
            throw new Error(`Could not import document. DocumentImportResult: ${importResult}`)
          }
        } else {
          // locks not changed OR document changed

          // TODO: save revisions for documentation purposes
          const form = this.getDocumentForm(modelDocument)

          if (!dataChanges) {
            let versionToUse = null

            // If caller wants to override the version of the resulting document
            // this will only be allowed if no other change happened before
            //
            // TODO: Check whether this can be allowed if something outside of data
            // changed, such as location, locks etc
            if (options.version) {
              versionToUse = options.version
            }
            
            const changeSource = this._buildNewChangeSourceInfo()
            
            const mergedModelDocument = Document.cloneDocument(modelDocument, form, { modified: changeSource, setTimestamps: this._options.setTimestamps })
            mergedModelDocument.v = versionToUse ?? version()
            
            if (document.hidden) {
              mergedModelDocument.hidden = true
            } else {
              delete mergedModelDocument.hidden
            }

            if (document.readonly) {
              mergedModelDocument.readonly = true
            } else {
              delete mergedModelDocument.readonly
            }
            
            mergedModelDocument.previous = {
              v: modelDocument.v,
            }

            if (lockChanges) {
              applyListChanges(lockChanges, mergedModelDocument.locks)
              mergedModelDocument.previous.locks = clone.lockRefs(modelDocument.locks)
            }

            if (hiddenChanged) {
              mergedModelDocument.previous.hidden = !!modelDocument.hidden
            }
            
            if (readonlyChanged) {
              mergedModelDocument.previous.readonly = !!modelDocument.readonly
            }
          
            const importResult = this._import(mergedModelDocument)
            if (importResult === DocumentImportResult.Imported) {
              this._snapshotDocumentUpdated(modelDocument)

              document.updateBase(mergedModelDocument, form)
              document.clearRevisions()

              this._changeTracker.updated(mergedModelDocument, { insertOrUpdate: document.insertOrUpdateMode })
            } else {
              throw new Error(`Could not import document. DocumentImportResult: ${importResult}`)
            }
          } else if (dataChanges.aChanged) {
            // incoming document has changed
            const changeSource = this._buildNewChangeSourceInfo()
            
            if (isForm(document) && dataChanges.bChanged) {
              // TODO: always save directly in form history as intermediary step 
              // to ensure subsequent documents can be created (and upgraded subsequently)
              throw new Error('Form was changed by someone else and conflicted. This is not handled yet')
            }

            let versionToUse = null

            // If caller wants to override the version of the resulting document
            // this will only be allowed if no other change happened before
            //
            // TODO: Check whether this can be allowed if something outside of data
            // changed, such as location, locks etc
            if (options.version) {
              if (!dataChanges.bChanged) {
                versionToUse = options.version
              }
            }

            const mergedModelDocument = Document.cloneDocument(modelDocument, form, { data: dataChanges.data, modified: changeSource, setTimestamps: this._options.setTimestamps })

            if (document.hidden === true) {
              mergedModelDocument.hidden = true
            } else {
              delete mergedModelDocument.hidden
            }
            
            if (document.readonly === true) {
              mergedModelDocument.readonly = true
            } else {
              delete mergedModelDocument.readonly
            }
            
            mergedModelDocument.v = versionToUse ?? version()
            mergedModelDocument.previous = {
              v: modelDocument.v
            }
            
            if (lockChanges) {
              applyListChanges(lockChanges, mergedModelDocument.locks)
              mergedModelDocument.previous.locks = clone.lockRefs(modelDocument.locks)
            }

            if (hiddenChanged) {
              mergedModelDocument.previous.hidden = !!modelDocument.hidden
            }
            
            if (readonlyChanged) {
              mergedModelDocument.previous.readonly = !!modelDocument.readonly
            }
            
            mergedModelDocument.previous.patch = diff(modelDocument.data, mergedModelDocument.data)

            const importResult = this._import(mergedModelDocument)
            if (importResult === DocumentImportResult.Imported) {
              this._snapshotDocumentUpdated(modelDocument)

              document.updateBase(mergedModelDocument, form)
              document.clearRevisions()

              this._changeTracker.updated(mergedModelDocument, { insertOrUpdate: document.insertOrUpdateMode })
            } else {
              throw new Error(`Could not import document. DocumentImportResult: ${importResult}`)
            }
          } else if (!readonlyChanged && !hiddenChanged && !lockChanges && !dataChanges.aChanged) {
            // Nothing changed in the document itself, but the model has changes
            document.updateBase(modelDocument, form)
            document.clearRevisions()
          } else {
            // modelDocumentChanged but no incoming modelDocumentChanged
            // AND: hidden OR readonly OR locks changed
            const changeSource = this._buildNewChangeSourceInfo()

            let versionToUse = null

            // If caller wants to override the version of the resulting document
            // this will only be allowed if no other change happened before
            //
            // TODO: Check whether this can be allowed if something outside of data
            // changed, such as location, locks etc
            if (options.version) {
              versionToUse = options.version
            }
    
            const mergedModelDocument = Document.cloneDocument(modelDocument, form, { modified: changeSource, setTimestamps: this._options.setTimestamps })
    
            if (document.hidden === true) {
              mergedModelDocument.hidden = true
            } else {
              delete mergedModelDocument.hidden
            }
            
            if (document.readonly === true) {
              mergedModelDocument.readonly = true
            } else {
              delete mergedModelDocument.readonly
            }
            
            mergedModelDocument.v = versionToUse ?? version()

            mergedModelDocument.previous = {
              v: modelDocument.v
            }
            
            if (lockChanges) {
              applyListChanges(lockChanges, mergedModelDocument.locks)
              mergedModelDocument.previous.locks = clone.lockRefs(modelDocument.locks)
            }
    
            if (hiddenChanged) {
              mergedModelDocument.previous.hidden = !!modelDocument.hidden
            }
            
            if (readonlyChanged) {
              mergedModelDocument.previous.readonly = !!modelDocument.readonly
            }
            
            const importResult = this._import(mergedModelDocument)
    
            if (importResult === DocumentImportResult.Imported) {
              this._snapshotDocumentUpdated(modelDocument)
    
              document.updateBase(mergedModelDocument, form)
              document.clearRevisions()
    
              this._changeTracker.updated(mergedModelDocument, { insertOrUpdate: document.insertOrUpdateMode })
            } else {
              throw new Error(`Could not import document. DocumentImportResult: ${importResult}`)
            }
          }
        } 
      }

      document.clearLockChanges()
      document.clearChangeOperations()
    } finally {
      changes = this._changeTracker.stopTracking()
    }

    return changes
  }

  private _buildNewChangeSourceInfo(): Types.ChangeSourceInfo {
    const { isoTz } = getNow()

    return {
      at: isoTz,
      by: this._dynamic.accountId,
      backedBy: this._dynamic.backedBy,
      impersonatedBy: this._dynamic.impersonatedBy,
      from: this._from,
      client: this._dynamic.clientId
    }
  }

  /**
   * Evict a document and all its descendants from the cache.
   * 
   * Returns `true` if the document was evicted, `false` if the document was not found or 
   * could not be evicted (Note: the root document cannot be evicted).
   * 
   */
  evict(documentIdentifier: Types.IdOrReference, options: EvictOptions = {}) : boolean {
    const documentId = readId(documentIdentifier)

    if (documentId === 'root' && !options.onlyDescendants) {
      // tslint:disable-next-line
      console.error('Cannot evict root')
      return false
    }

    const document = this._documents.get(documentId)

    if (!document) {
      return false
    }

    const descendants = this._findDocumentsInsecure(Scopes.descendants(documentId), { size: 1E20 })

    for (const descendant of descendants) {
      this._documents.delete(descendant.id)
      this._indexer.remove(descendant)
    }

    if (!options.onlyDescendants) {
      this._documents.delete(document.id)
      this._indexer.remove(document)
    } 

    return true
  }

  // TODO: Check whether export should be authentication filtered
  exportForms(parentDocumentId = 'root', { alsoFilterSystem, includeFn }: { alsoFilterSystem: Boolean, includeFn: (id: string) => Boolean } = { alsoFilterSystem: false, includeFn: null }): (Change | Types.Document)[] {
    const forms = []

    for (const formVersions of this._forms.values()) {
      const currentFormDocument = this.get(formVersions.id)

      if (includeFn) {
        if (alsoFilterSystem === true) {
          if (includeFn(formVersions.id) === false) {
            continue
          }
        } else if (currentFormDocument) {
          if (currentFormDocument.a[0] !== 'system') {
            if (includeFn(formVersions.id) === false) {
              continue
            }
          }
        }
      }

      if (parentDocumentId != 'root' && (!currentFormDocument || !currentFormDocument.a.includes(parentDocumentId))) {
        continue
      }

      const allVersions = formVersions.getAll()

      const allVersionAsDocuments = allVersions.map(v => v.document)

      forms.push(...allVersionAsDocuments)

      if (formVersions.deleted) {
        forms.push({ operation: 'deleted', document: { id: formVersions.id } })
      }
    }

    return forms
  }

  * export(parentDocumentId = 'root'): IterableIterator<Types.Document> {
    const exportRoot = this.get(parentDocumentId)

    if (exportRoot) {
      yield exportRoot
      yield * this.recurse(exportRoot)
    }
  }

  * recurse(startDocument: Types.Document): IterableIterator<Types.Document> {
    for (const child of this.enumerateChildren(startDocument)) {
      yield child
      yield * this.recurse(child)
    }
  }

  * enumerateChildren(parentIdentifier: Types.IdOrReference): IterableIterator<Types.Document> {
    const parentId = readId(parentIdentifier)

    const childrenEnumerator = this._indexer.indexMap['p'].getEntries(parentId)
    
    if (childrenEnumerator) {
      const children = []

      for (const childId of childrenEnumerator) {
        const child = this._documents.get(childId)

        if (!child) {
          continue
        }

        if (this._dontFilterCurrently || this.hasAccess(child)) {
          children.push(child)
        }
      }

      yield * children
    }
  }

  /**
   * Create a new document.
   * 
   * @param formIdentifier - Identify the form underlying the document to create
   * @param parentIdentifier - Where to insert the document into the documen tree
   * @param options - Some options to influence the creation process
   * @returns - An `EditableDocument` which can be edited and saved (`Aeppic.save`)
   */
  new(formIdentifier: Types.IdOrReference, parentIdentifier: Types.IdOrReference | Types.Document, options: NewDocumentOptions = {}): EditableDocument {
    if (!parentIdentifier) {
      const formId = toDocumentId(formIdentifier)
      throw new Error(`Documents MUST have a valid parent (form: ${formId})`)
    }

    let parent = this.get(parentIdentifier)
    const parentId = toDocumentId(parentIdentifier)

    const now = Date.now()

    if (!parent) {
      const committedDocument = this._committedDocuments.get(parentId)
      
      if (committedDocument) {
        parent = committedDocument.document
        committedDocument.lastAccessedAt = Date.now()
      }
    }

    if (!parent) {
      if (isDocument(parentIdentifier)) {
        parent = parentIdentifier
      }
    }

    if (!parent) {
      throw new Error(`Cannot create document since parent is unknown. Commit or save the parent first`)
    }

    const formId = readId(formIdentifier)
    let form: Form

    const mostRecentForm = this.getMostRecentForm(formId)


    const skipDefaults = options?.skipDefaults === true
    const useDefaults = !skipDefaults
    
    if (options && options.exactFormVersion) {
      if (!isReference(formIdentifier)) {
        throw new Error('In order to create document matching a specific form version the identifier has to be a reference ')
      }

      if (useDefaults) {
        throw new Error('Cannot use defaults when creating a document matching a specific form version. Use `skipDefaults: true`')
      }

      form = this.getFormStrict(formIdentifier)
    } else {
      form = mostRecentForm
    }

    if (!form) {
      throw new Error(`Could not find form '${formId}'`)
    }

    // Set options defaults
    options = options || {}
    options.setTimestamps = options.setTimestamps || this._options.setTimestamps

    // Process defaults
    if (useDefaults) {
      const defaults = mostRecentForm.data.defaults

      if (defaults === 'TEMPLATE') {
        const templateReference = mostRecentForm.data.newTemplate as Types.Reference

        if (!templateReference || templateReference.id == '') {
          throw new Error(`Form '${formId}' has no template for new documents`)
        }

        const document = this.clone(templateReference, parent, { setTimestamps: options.setTimestamps })

        if (document.f.id !== formId) {
          throw new Error(`Form id mismatch in template document (${templateReference.id}). Should be '${formId}' but is '${document.f.id}'`)
        } 
        
        return document        
      }
      
      if (defaults === 'ACTION') {
        throw new Error(`This method is not implemented by the model and has to be handled outside. Use { skipDefaults: true } option to create a document of '${formId}' without defaults`)
      }
    }

    // Create a document with empty defaults
    const changeSourceInfo = this._buildNewChangeSourceInfo()

    const document = Document.newDocument(form, parent, options, changeSourceInfo)
    return this._newEditableDocument(document, form, { insertOrUpdate: options.insertOrUpdate })
  }

  private _addForm(formDocument: Types.Document) {
    let formVersions = this._forms.get(formDocument.id)

    if (formVersions == null) {
      formVersions = new FormVersions(formDocument.id)
      this._forms.set(formDocument.id, formVersions)
    }

    formVersions.add(formDocument)
  }

  /**
   * Ensure up-to-date inheritance and remember this
   * document in a frozen state  
   * 
   * Index it and list it in the list of all known documents the document. If the document was
   * indexed before it should not be removed manually before. This function will do that
   * 
   * If it is inside the recyler though don't index it normally
   * add it to the Recycler
   * 
   * Trigger updating inhertied
   * 
   * @param document 
   * @param previousVersion 
   * @returns 
   */
  private _setDocument(document: Types.Document, previousVersion ?: Types.Document) {
    document.stamps = (previousVersion && previousVersion.stamps) || document.stamps || []

    this._updateInheritedProperties(document)
    this._freezeDocument(document)

    const documentToRemove = previousVersion || document

    this._removeDocument(documentToRemove)
    this._insertDocument(document)
    
    let reason = NotificationReason.Updated
    
    this._updateDescendantsInheritance(document, previousVersion)

    if (document.a && document.a[0] === RECYCLER_ID) {
      reason = NotificationReason.Deleted

      this._removeDocument(document)
      
      let previousParentId

      if (previousVersion && previousVersion.p !== document.p) {
        previousParentId = previousVersion.p
      }
      
      this._recycler.add(document, previousParentId)
      
      this._watchNotifier.notifyWatchers(document, NotificationReason.Deleted)
      this._queryWatchNotifier.notifyWatchers(document, NotificationReason.Deleted)
    }

    this._watchNotifier.notifyWatchers(document, reason)
    this._queryWatchNotifier.notifyWatchers(document, reason)
    
    if (document.f.id === 'stamp') {
      this._projectStampInfoIntoPrimaryTarget(document)
    }

    return document
  }

  private _deleteStampFromPrimaryTarget(stampDocument: Types.Document) {
    const primaryTargetRef = stampDocument.stampData.documents[0]
    const target = this._getDocumentInsecure(primaryTargetRef)

    if (!target) {
      return
    }

    if (target.stamps) {
      const indexOfStampInTarget = target.stamps.findIndex(s => s.id === stampDocument.id)

      if (indexOfStampInTarget >= 0) {
        target.stamps.splice(indexOfStampInTarget, 1)
      
        const reason = NotificationReason.StampRemoved

        this._watchNotifier.notifyWatchers(target, reason)
        this._queryWatchNotifier.notifyWatchers(target, reason)
      }
    }
  }

  private _projectStampInfoIntoPrimaryTarget(stampDocument: Types.Document) {
    if (!stampDocument.stampData) {
      return
    }

    const inRecycler = (stampDocument.a[0] === RECYCLER_ID)
    const directlyDeleted = stampDocument.p === RECYCLER_ID
   
    if (inRecycler || directlyDeleted) {
      return this._deleteStampFromPrimaryTarget(stampDocument)
    }
    
    const primaryTargetRef = stampDocument.stampData.documents[0]
    const isBelowPrimaryTarget = (stampDocument.p === primaryTargetRef.id)
    
    if (!isBelowPrimaryTarget) {
      console.warn('Stamp must always be kept underneath its primary target unless its recycled', stampDocument.id)
      return
    }

    const target = this._getDocumentInsecure(primaryTargetRef)
  
    if (!target) {
      return
    }

    const workflowRef = (<Types.Reference>stampDocument.data.workflow)?.id ? <Types.Reference>stampDocument.data.workflow : null

    if (workflowRef && workflowRef.id) {
      const stamps = []

      for (const stamp of target.stamps) {
        let keepExistingStamp = true

        const isExactSameStamp = stamp.id === stampDocument.id
        const isStampFromSameWorkflow = stamp.workflow && stamp.workflow.id === workflowRef.id

        // Hidden can change
        if (isExactSameStamp) {
          stamp.v = stampDocument.v
          stamp.hidden = stampDocument.hidden
          
          // const isExactSameVersion = stamp.v === stampDocument.v
          // if (isExactSameVersion) {
          //   return
          // }

          keepExistingStamp = false
        } else if (isStampFromSameWorkflow) {
          const isCurrentStampBeingHandledMoreRecentThanExistingStamp = Date.parse(stampDocument.created.at) >= Date.parse(stamp.added) 

          if (isCurrentStampBeingHandledMoreRecentThanExistingStamp) {
            keepExistingStamp = false
          } else {
            return
          }
        }

        if (keepExistingStamp) {
          stamps.push(stamp)
        }
      }

      target.stamps = stamps
    } else {
      for (const stamp of target.stamps) {
        const isExactSameStamp = stamp.id === stampDocument.id

        if (isExactSameStamp) {
          // Hidden can change
          stamp.v = stampDocument.v
          stamp.hidden = stampDocument.hidden
          return
        }
      }
    }

    const stamp: Types.Stamp = {
      id: stampDocument.id,
      v: stampDocument.v,
      targetVersion: primaryTargetRef.v,
      text: typeof stampDocument.data.name === 'string' ? stampDocument.data.name : '',
      type:  <Types.Reference> stampDocument.data.type,
      added: stampDocument.created.at,
      hidden: stampDocument.hidden,
      workflow: <Types.Reference> stampDocument.data.workflow,
      workflowUid: <string> stampDocument.data.workflowUid
    }

    target.stamps.push(stamp)

    const reason = NotificationReason.StampAdded

    this._watchNotifier.notifyWatchers(target, reason)
    this._queryWatchNotifier.notifyWatchers(target, reason)
  }

  private _updateInheritedProperties(document: Types.Document) {
    if (document.a == null) {
      document.a = []
    }

    if (document.a_forms == null) {
      document.a_forms = []
    }

    if (document.inheritedLocks == null) {
      document.inheritedLocks = []
    }

  // dont change the instances of the arrays though (unless its root, root never gets updated anyway)
    if (document.id === 'root') {
      document.a = null
      document.a_forms = null
      document.a_depth = null
      document.inheritedLocks = null
      
      return
    } 
  
    const parent = this._documents.get(document.p)  || this.Recycler.get(document.p)
    
    const ancestors = []
    const ancestorForms = []
    const inheritedLocks = [] 
    
    if (parent) {
      if (parent.a) {
        ancestors.push(...parent.a, parent.id)
      }

      if (parent.a_forms) {
        ancestorForms.push(...parent.a_forms, parent.f.id)
      }

      if (parent.inheritedLocks) {
        inheritedLocks.push(...parent.inheritedLocks)
      } 

      if (parent.locks && parent.locks.length > 0) {
        inheritedLocks.push(parent.locks)
      }
    }

    document.a.splice(0, document.a.length, ...ancestors)
    document.a_forms.splice(0, document.a_forms.length, ...ancestorForms)
    document.a_depth = document.a.length
    
    document.inheritedLocks.splice(0, document.inheritedLocks.length, ...inheritedLocks)
  }

  public hasValidAncestry(identifier: string | Types.Reference | Types.Document | EditableDocument) {
    const document = isDocument(identifier) ? identifier : this.get(identifier)
    
    if (!document) {
      return false
    }
    
    if (document.id === 'root') {
      return true
    }

    if (document.p === 'root') {
      return true
    }

    // TODO: Recheck this when shared items with incomplete/shortened/scrubbed ancestry are possible 
    for (const ancestor of document.a) {
      if (!this._documents.has(ancestor)) {
        return false
      }
    }

    return true
  }

  private _freezeDocument(document: Types.Document) {
    if (Object.isFrozen(document.f)) {
      return
    }

    // Object.preventExtensions(document) // Cannot do this. Vue reactivity would be impacted

    Object.freeze(document.f)
    Object.freeze(document.created)
    Object.freeze(document.modified)
    Object.freeze(document.previous)
    Object.freeze(document.cloneOf)

    Object.freeze(document.locks)

    Object.freeze(document.data)

    const form = this.getDocumentForm(document)
    
    if (form) {
      form.freezeData(document.data)
    } else {
      for (const dataPropertyValue of Object.values(document.data)) {
        Object.freeze(dataPropertyValue)
      }
    }
  }

  // private _ensureTypedDocument(document: Types.Document): Document {
  //   if (document instanceof Document)
  //     return document

  //   return new Document(document)
  // }

  private _isKnownForm(formReference: Types.Reference) {
    const formVersions = this._forms.get(formReference.id)

    if (!formVersions) {
      return false
    }

    return formVersions.has(formReference.v)
  }

  some(queryStringOrFunction: string | FilterFunction < Types.Document > , filterString: string = ''): Boolean {
    if (typeof queryStringOrFunction === 'string') {
      const query = parseQueryAndFilter(queryStringOrFunction, filterString)
      const matches = this._findDocuments(query, { size: 1 })

      const firstMatch = matches.next()
      return !firstMatch.done
    } else {
      const query = parseQueryAndFilter(filterString)
      const documents = this._findDocuments(query, { size: 1})

      for (const document of documents) {
        const form = this.getFormStrict(document.f.id, document.f.v)
        if (queryStringOrFunction(document)) {
          return true
        }
      }
      return false
    }
  }

  resolve(start: Types.IdOrReference, path: string): Types.Document {
    return resolveDocumentPathSync(start, path, this)
  }

  resolveEdit(start: Types.IdOrReference, path: string): Types.Document {
    const doc = this.resolve(start, path)

    if (!doc) {
      return null
    }

    return this.edit(doc)
  }

  /**
   * Resolves a document path starting from a given document and then resolves a field at the end of the path.
   * 
   * @param start The starting document ID or reference.
   * @param path The path to resolve (e.g data.ref -> data.myRef -> modified.by -> data.name).
   * 
   * @returns The resolved field value or null if the path could not be resolved.
   */
  resolveToField(start: Types.IdOrReference, path: string): any {
    const parts = path.split('->')
    const lastPart = parts.pop()
    const remainingParts = parts.join('->')

    const document = this.resolve(start, remainingParts)
    
    if (document) {
      return resolveField(document, lastPart.trim())
    } else {
      return null
    }
  }

  find(query: Query, options?: FindOptions): Types.Document[]
  find(query: QueryBuilder, options?: FindOptions): Types.Document[]
  find(query: string, options?: FindOptions): Types.Document[]
  find(query: string, filterString?: string, options?: FindOptions): Types.Document[]
  find(queryArgument: Query | QueryBuilder | string , param1?: string | FindOptions, param2?: FindOptions): Types.Document[] {
    const { query, options } = toQueryAndOptions(queryArgument, param1, param2)
    
    const fullOptions = { ...this._defaultFindOptions, ...options }
    return this._find(query, fullOptions)
  }

  findOne(query: Query, options?: FindOptions): Types.Document
  findOne(query: QueryBuilder, options?: FindOptions): Types.Document
  findOne(query: string, options?: FindOptions): Types.Document
  findOne(query: string, filterString?: string, options?: FindOptions): Types.Document
  findOne(queryArgument: Query | QueryBuilder | string , param1?: string | FindOptions, param2?: FindOptions): Types.Document {
    const { query, options } = toQueryAndOptions(queryArgument, param1, param2)
    
    const fullOptions = { ...this._defaultFindOptions, ...options, size: 1 }
    const documents = this._find(query, fullOptions)
    return documents[0]
  }

  private _find(query: Query, options: FindOptions): Types.Document[] {
    const SLOW_QUERY_THRESHOLD = 150
    
    const start = Date.now()
    const documents = Array.from(this._findDocuments(query, options))

    let results = documents

    const sortStart = Date.now()

    if (options.sort === undefined) {
      results.sort(sortByNameOrSortIndex)
    } else if (options.sort !== null) {
      sort(results, options.sort)
    } 

    const sortTimeInMs = Date.now() - sortStart

    results = results.slice(0, options.size ?? this._options.defaultPageLimit ?? 10)

    const end = Date.now()
    const executionTimeInMs = end - start

    if (executionTimeInMs > SLOW_QUERY_THRESHOLD) {
      // tslint:disable-next-line
      console.warn(`Slow query 'Found #${documents.length}. Time: ${executionTimeInMs}ms Sort Time (${sortTimeInMs}) (${this._readAccountInfoId()})`)
      const slowQueryPrefixed = stringify(query, null, 2).split('\n').map(l => `Slow Query|${l}`).join('\n')
      console.warn(slowQueryPrefixed)
    }

    let fields = options.field ? [options.field] : options.fields
    
    if (fields) {
      results = results.map(d => this.pickFields(d, fields))
    } else {
      let omitFields = options.omitField ? [options.omitField] : options.omitFields
      
      if (omitFields) {
        results = results.map(d => this.omitFields(d, omitFields))
      }
    }

    return results
  }

  pickFields(document: Types.Document, fields: string[]): Types.Document { 
    return { ...document, data: pick(document.data, fields) }
  }

  omitFields(document: Types.Document, fields: string[]): Types.Document {
    return { ...document, data: omit(document.data, fields) }
  }

  private * _findDocuments(queryOrBuilder: Query|QueryBuilder, options: FindOptions): IterableIterator<Types.Document> {
    if (this._dontFilterCurrently) {
      yield * this._findDocumentsInsecure(queryOrBuilder, options)
    } else {
      for (const d of this._findDocumentsInsecure(queryOrBuilder, options)) {
        if (this.hasRight(d)) {
          yield d
        }
      }
    }  
  }

  private get _dontFilterCurrently() {
    if (this._options.dontFilterReads) {
      return true
    } else if (!this._dynamic.accountId && !this._dynamic.backedBy && !this._dynamic.impersonatedBy) {
      return true
    } else {
      return false
    } 
  }

  private * _findDocumentsInsecure(queryOrBuilder: Query|QueryBuilder, options: FindOptions): IterableIterator <Types.Document> {
    let size = options.size ?? this._options.defaultPageLimit ?? 10
    let query: Query
    let iterator: DocumentMatchIterator

    try {
      query = isQuery(queryOrBuilder) ? queryOrBuilder : queryOrBuilder.toQuery() 

      const stats = {
        query,
        options,
        fullScanDueToUnindexedQueries: false,
        numberOfDocumentsEnumerated: 0,
        numberOfDocumentsTested: 0,
        foundDocumentsCounter: 0
      }

      let abortWhenSizeReached = true

      if (options.sort && size > 0) {
        abortWhenSizeReached = false
      }

      const start = Date.now()

      iterator = buildIteratorForGraph(query.graph, query.parameters, this._indexer.indexMap)
      
      if (!iterator) {
        throw new Error('Did not get an iterator')
      }

      if (isIteratorOverAllDocuments(iterator)) {
        stats.fullScanDueToUnindexedQueries = true
      }

      const matcher = new QueryMatcher(query)

      const missingForms = new Set<string>()

      for (const document of enumerateGraphIterator(iterator, this._documents)) {
        stats.numberOfDocumentsEnumerated ++
        stats.numberOfDocumentsTested ++
        
        if (document.hidden === true && options.includeHidden !== true) {
          continue
        }

        const exactForm = this.getFormStrict(document.f)

        if (!exactForm) {
          const formIdentifierKey = document.f.id + document.f.v

          if (!missingForms.has(formIdentifierKey)) {
            console.warn(`Form ${document.f.id}@${document.f.v} is missing at least for document ${document.id}`)
            missingForms.add(formIdentifierKey)
          }
          
          continue
        }

        if (matcher.match(document)) {
          stats.foundDocumentsCounter ++
          yield document

          if (abortWhenSizeReached) {
            if (stats.foundDocumentsCounter >= size) {
              break
            }
          }

        }
      }

      const ms = Date.now() - start
      const seconds = Math.floor(ms / 1000)

      if (seconds >= 1) {
        console.log(`Slow (${ms}ms) query stats`, stats)
      }

      this._dynamic.lastStats = stats
    } catch (error) {
      console.error('Could not find documents', error.toString(), error.stack, JSON.stringify(query, null, 2), JSON.stringify(iterator, null, 2), options)
    }
  }

  get LastQueryStats() {
    return this._dynamic.lastStats || {}
  }

  private _buildFilterFunction(arg1: Query | QueryBuilder | string | FilterFunction <Types.Document>): FilterFunction<Types.Document> {
    let filterFn: FilterFunction<Types.Document>
    let queryMatcher: QueryMatcher

    if (isQuery(arg1)) {
      queryMatcher = new QueryMatcher(arg1)
    } else if (isQueryBuilder(arg1)) {
      queryMatcher = new QueryMatcher(arg1.toQuery())
    } else if (typeof arg1 === 'string') {
      const query = parseQueryAndFilter(arg1)
      queryMatcher = new QueryMatcher(query)
    } else if (typeof arg1 === 'function') {
      filterFn = arg1
      return filterFn
    } else {
      throw new Array()
    }

    return (document: Types.Document) => queryMatcher.match(document)
  }

  private _filterDocuments(documents: IterableIterator < Types.Document > , testFunction: MatchQueryFunction): Types.Document[] {
    const filteredDocuments = []

    for (const document of documents) {
      if (testFunction(document)) {
        filteredDocuments.push(document)
      }
    }

    return filteredDocuments
  }

  match(document: Types.Document, queryString: string, options: MatchOptions = {}): Boolean {
    if (!document) {
      return false
    }

    const matchOptions = { nowUtcTz: getNow().isoTz, ...options }

    const query = parseQueryAndFilter(queryString, options)
    const tester = new QueryMatcher(query)

    return tester.match(document)
  }
}

function isSameDocument(a: Types.Document, b: Types.Document) {
  return a.id === b.id && a.v === b.v && a.modified.client === b.modified.client
}

function formHasChanged(document: EditableDocument, modelDocument: Types.Document) {
  return document.f.id !== modelDocument.f.id || document.f.v !== modelDocument.f.v
}

function formsDidNotChange(base: Types.Document, ...otherDocuments: Types.Document[]) {
  for (const d of otherDocuments) {
    if (base.f.id !== d.f.id || base.f.v !== d.f.v) {
      return false
    }
  }

  return true
}

function parentsDidNotChange(base: Types.Document, ...otherDocuments: Types.Document[]) {
  for (const d of otherDocuments) {
    if (base.p !== d.p) {
      return false
    }
  }

  return true
}

function newParentAncestryContains(documentId, newParentAncestry) {
  if (!newParentAncestry) {
    return false
  }

  for (const a of newParentAncestry) {
    if (a === documentId) {
      return true
    }
  }

  return false
}

function isWellKnownForm(formReference: Types.Reference) {
  return (formReference.id === 'form' && formReference.v === 'initial')
}

function isFieldEmpty(fieldValue: string|number|boolean|Types.Reference) {
  if (fieldValue == null) {
    return true
  }

  if (typeof fieldValue === 'string') {
    return fieldValue === ''
  } else if (typeof fieldValue === 'number') {
    return Number.isNaN(fieldValue)
  } else if (typeof fieldValue === 'boolean') {
    return false
  } else if (isReferenceField(fieldValue)) {
    return fieldValue.id === ''
  }

  // TODO: Handle comparison against other types, specifically
  //       address,geolocation,etc for deviations against empty
  //       state

  return false
}

export function createSortByFallthrough(fieldNamePaths: string[], descending: boolean = false) {
  const sort = function sortByField(a: Types.Document, b: Types.Document): number {
    // Handle cases when documents themselves are not set
    if (a && !b) {
      return -1
    } else if (!a && b) {
      return 1
    } else if (!a && !b) {
      return 0
    }
    
    let aSideFieldIndex = 0
    let bSideFieldIndex = 0

    while (aSideFieldIndex < fieldNamePaths.length && bSideFieldIndex < fieldNamePaths.length) {
      const fieldAName = fieldNamePaths[aSideFieldIndex]
      const fieldBName = fieldNamePaths[bSideFieldIndex]

      // Retrieve field values for sort
      const fieldA: any = resolveField(a, fieldAName)[0]
      const fieldB: any = resolveField(b, fieldBName)[0]

      if (isFieldEmpty(fieldA)) {
        aSideFieldIndex++
        continue
      }

      if (isFieldEmpty(fieldB)) {
        bSideFieldIndex++
        continue
      }

      if (fieldA && !fieldB) {
        return 1
      } else if (!fieldA && fieldB) {
        return -1
      } else if (!fieldA && !fieldB) {
        return 0
      }

      if (typeof fieldA !== typeof fieldB) {
        return 0
      }

      if (typeof fieldA === 'string') {
        return fieldA.localeCompare(fieldB)
      } else if (typeof fieldA === 'number') {
        return fieldA - fieldB
      } else if (typeof fieldA === 'boolean') {
        console.log('test', fieldA, fieldB)
        if (fieldA === false && fieldB === true) {
          return -1
        } else if (fieldA === true && fieldB === false) {
          return 1
        }
      } else if (isReferenceField(fieldA)) {
        return fieldA.text.localeCompare(fieldB.text)
      }

      return 0
    }

    return 0
  }

  if (!descending) {
    return sort
  } else {
    return (a:Types.Document, b:Types.Document) => -1 * sort(a, b)
  }
}
 
export function createSortByField(fieldNamePaths: string[], descending: boolean = false, missingToEnd: boolean = false) {
  let [aBeforeBWithoutDescending, aAfterBWithoutDescending] =[-1,1]
  let [aBeforeB, aAfterB] = descending === false ? [-1,1] : [1,-1]
  const same = 0
  
  const mapSortResult = (result: number) => {
    if (result < 0) {
      return aBeforeB
    } else if (result > 0) {
      return aAfterB
    } else {
      return same
    }
  }


  const compareFunction = function sortByField(a: Types.Document, b: Types.Document): number {
    // Handle cases when documents themselves are not set
    if (a && !b) {
      return aBeforeB
    } else if (!a && b) {
      return aAfterB
    } else if (!a && !b) {
      return same
    }

    // Retrieve field values for sort
    const fieldA: any = resolveFirstNonEmptyField(a, fieldNamePaths)[0]
    const fieldB: any = resolveFirstNonEmptyField(b, fieldNamePaths)[0]

    if (!isFieldEmpty(fieldA) && isFieldEmpty(fieldB)) {
      if (missingToEnd) {
        return aBeforeBWithoutDescending
      } else {
        return aAfterBWithoutDescending
      }
    } else if (isFieldEmpty(fieldA) && !isFieldEmpty(fieldB)) {
      if (missingToEnd) {
        return aAfterBWithoutDescending
      } else {
        return aBeforeBWithoutDescending
      }
    } else if (isFieldEmpty(fieldA) && isFieldEmpty(fieldB)) {
      return same
    }

    if (typeof fieldA !== typeof fieldB) {
      return same
    }

    if (typeof fieldA === 'string' && typeof fieldB === 'string') {
      return mapSortResult(fieldA.localeCompare(fieldB))
    } else if (typeof fieldA === 'number' && typeof fieldB === 'number') {
      return mapSortResult(fieldA - fieldB)
    } else if (typeof fieldA === 'boolean' && typeof fieldB === 'boolean') {
      if (fieldA === false && fieldB === true) {
        return aBeforeB
      } else if (fieldA === true && fieldB === false) {
        return aAfterB
      } else {
        return same
      }
    } else if (isReferenceField(fieldA) && isReferenceField(fieldB)) {
      const textCompare = mapSortResult(fieldA.text.localeCompare(fieldB.text))
      if (textCompare === same) {
        return mapSortResult(fieldA.id.localeCompare(fieldB.id))
      } else {
        return textCompare
      }
    } else {
      // Cannot compare different field types.
      return same
    }
  }

  return compareFunction
}

function debugSort(sortFunction: (a: Types.Document, b: Types.Document) => number ): (a: Types.Document, b: Types.Document) => number {
  return (a, b) => {
    const result = sortFunction(a, b)

    const sortToText = result === 0 ? 'same'
                      :result === -1 ? 'a < b'
                      :result === 1 ? 'a > b'
                      :'unknown'

    console.log('Sort', 'a', a.id, a.data.boolean, ` - ${sortToText} - `, 'b', b.id, b.data.boolean)

    return result
  }
}

function sortByNameOrSortIndex(a, b) {
  if (a && b && a.data && b.data) {
    if (a.data.name != null && a.data.name.localeCompare && b.data.name != null && b.data.name.localeCompare) {
      return a.data.name.localeCompare(b.data.name)
    } else if (a.data.sortIndex != null && b.data.sortIndex != null) {
      return compare(a.data.sortIndex, b.data.sortIndex)
    }
  }

  if (a && b && a.id && b.id) {
    return a.id.localeCompare(b.id)
  }

  return 0
}

/* tslint:disable */
function compare(a, b) {
  if (a == b) {
    return 0
  } else if (a < b) {
    return -1
  } else {
    return 1
  }
}
/* tslint:enable */

function isTheDirectPredecessorVersion(document, comparisonDocument) {
  return comparisonDocument.previous && document.v === comparisonDocument.previous.v
}

function isTheDirectSuccessorVersion(document, potentialPredecessor) {
  return document.previous && document.previous.v === potentialPredecessor.v
}

function isLikelyToBeADeletedForm(document) {
  return (document.v !== 'initial' &&
    document.data.description === '' &&
    document.data.definition === '' &&
    document.data.name === '')
}

function didAncestorsChange(document: Types.Document, previousVersion: Types.Document) {
  return didArrayChange(document.a, previousVersion.a) || didArrayChange(document.a_forms, previousVersion.a_forms) 
}

function didLocksChange(document: Types.Document, previousVersion: Types.Document) {
  return didArrayChange(document.locks, previousVersion.locks, (refA: Types.Reference, refB: Types.Reference) => {
    return refA.id !== refB.id
  })
}

function didArrayChange(array1: any[], array2: any[], comparisonFunction?: (a: any, b: any) => boolean) {
  if (array1 === array2) {
    return false
  }

  if (array1 && !array2) {
    return true
  }

  if (!array1 && array2) {
    return true
  }

  if (array1.length !== array2.length) {
    return true
  }

  for (let i = 0, l = array1.length; i < l; i += 1) {
    const a = array1[i]
    const previousA = array2[i]

    if (!comparisonFunction) {
      if (a !== previousA) {
        return true
      }
    } else {
      if (comparisonFunction(a, previousA)) {
        return true
      }
    }
  }

  return false
}

function mergeLockChanges(baseDocument: Types.Document, newVersion: EditableDocument) {
  if (!newVersion.lockChanges.length) {
    return null
  }

  if (baseDocument.locks == null || baseDocument.locks.length === 0) {
    if (newVersion.locks.length === 0) {
      return null
    } else {
      return { added: newVersion.locks, removed: [], updated: [] }
    }
  } else {
    const added = []
    const removed = []
    const updated = []

    for (const previousLock of baseDocument.locks) {
      let found = false

      for (const newLock of newVersion.locks) {
        if (newLock.id === previousLock.id) {
          found = true

          if (newLock.v !== previousLock.v || newLock.text !== previousLock.text) {
            updated.push(newLock)
            break
          }
        }
      }

      if (!found) {
        removed.push(previousLock)
      }
    }
    
    for (const newLock of newVersion.locks) {
      let found = false
      
      for (const previousLock of baseDocument.locks) {
        if (newLock.id === previousLock.id) {
          found = true
        }
      }

      if (!found) {
        added.push(newLock)
      }
    }

    if (!added.length && !updated.length && !removed.length) {
      return null
    }

    return { added, updated, removed }
  }
}

function applyListChanges(changes: RefListChanges, list: Types.Reference[]) {
  for (const itemToRemove of changes.removed) {
    for (let i = list.length - 1; i >= 0; i -= 1) {
      const existingListEntry = list[i]

      if (existingListEntry.id === itemToRemove.id) {
        list.splice(i, 1)
      }
    }
  }

  for (const itemToUpdate of changes.updated) {
    for (let i = list.length - 1; i >= 0; i -= 1) {
      const existingListEntry = list[i]

      if (existingListEntry.id === itemToUpdate.id) {
        list.splice(i, 1, itemToUpdate)
      }
    }
  }

  for (const a of changes.added) {
    list.push(a)
  } 
}

function toDocumentId(identifier: string|Types.Reference|Types.Document|EditableDocument) {
  if (!identifier) {
    return null
  }

  if (typeof(identifier) === 'string') {
    return identifier
  } else {
    return identifier.id
  } 
}

function isLockedDocument(document: Types.Document) {
  const directlyLocked = notEmpty(document.locks)

  if (directlyLocked) {
    return true
  }

  const indirectlyLocked = hasInheritedLocks(document)
  return indirectlyLocked
}

function isSensitiveDocument(document: Types.Document) {
  return (document.f.id === 'account')
}

function isDocumentDescendentOf(document: Types.Document, potentialAncestorId: string) {
  if (potentialAncestorId === 'root' && document.p === 'root') {
    return true
  }
  
  for (const a of document.a) {
    if (a === potentialAncestorId) {
      return true
    }
  }

  return false
}

export function sort(list: Types.Document[], sortInfo: string | FieldSortInfo): void
export function sort(list: Types.Document[], sortInfo: (string | FieldSortInfo)[]): void
export function sort(list: Types.Document[], sortInfo: string | FieldSortInfo | (string|FieldSortInfo)[]): void
export function sort(list: Types.Document[], sortInfo: string | FieldSortInfo | (string|FieldSortInfo)[]): void {
  if (!sortInfo) {
    return
  }

  const sortInfos = Array.isArray(sortInfo) ? sortInfo : [ sortInfo ]

  const sortFunctions = sortInfos.filter(si => !!si).map(sortInfo => {
    if (typeof sortInfo === 'string') {
      return createSortByField([sortInfo])
    } else if ('field' in sortInfo) {
      return createSortByField([sortInfo.field], sortInfo.descending, sortInfo.missingToEnd)
    } else if ('fields' in sortInfo) {
      return createSortByField(sortInfo.fields, sortInfo.descending, sortInfo.missingToEnd)
    } else if ('fallthrough' in sortInfo){
      console.warn('DEPRECATED: Sort by fallthrough is deprecated. Use sort by field instead and specify an array of field names')
      return createSortByFallthrough(sortInfo.fallthrough, sortInfo.descending)
    } else {
      throw new Error('Invalid sort info')
    }
  })

  if (sortFunctions.length === 1) {
    list.sort(sortFunctions[0])
    return
  }
  
  list.sort((a, b) => {
    for (const sortFunction of sortFunctions) {
      const result = sortFunction(a, b)
    
      if (result < 0 || result > 0) {
        return result
      }
    }

    return 0
  })
}

export function getDocumentId(identifier: Types.IdOrReference): string {
  if (!identifier) {
    return null
  }

  let id: string

  if (typeof identifier === 'string') {
    id = identifier
  } else {
    id = identifier.id
  }

  return id
}

function hasInheritedLocks(document: Types.Document) {
  if (!document || !document.inheritedLocks) {
    return false
  }

  if (document.inheritedLocks.length === 0) {
    return false
  }

  const someAncestorHasLocks = document.inheritedLocks.filter(notEmpty).length > 0
  return someAncestorHasLocks
}

function notEmpty(locks: any[]) {
  return locks && locks.length > 0
}

export function isAdmin(accountDocument: Types.Document) {
  if (!accountDocument) {
    return false
  } else if (accountDocument.data.disabled) {
    return false
  } else if (accountDocument.data.isAdmin) {
    return true
  }

  return false
}

function nonHidden(document: Types.Document) {
  return document && document.hidden !== true
}

function toBoolean(value?: boolean) {
  if (value === true) {
    return true
  } else if (value === false) {
    return false
  } else if (value == null) {
    return false
  } else {
    throw new Error('Not safe to convert to boolean')
  }
}

export function toQueryAndOptions(queryArgument: Query | QueryBuilder | string , param1?: string | FindOptions, param2?: FindOptions): { query: Query, options: FindOptions } {
  let query: Query
  let options: FindOptions
  
  if (typeof queryArgument === 'function') {
    throw new Error('Unsupported query')
  }

  if (isQueryBuilder(queryArgument)) {
    if (typeof param1 === 'string') {
      throw new Error('Invalid argument')
    }

    query = queryArgument.toQuery()
    options = param1
  } else if (isQuery(queryArgument)) {
    if (typeof param1 === 'string') {
      throw new Error('Invalid argument')
    }
    
    query = queryArgument
    options = param1
  } else if (typeof queryArgument === 'string') {
    let filter: string = null

    if (typeof param1 === 'string') {
      filter = param1
      options = param2
    } else {
      options = param1
    }

    const matchOptions = extractMatchOptions(options)
    query = parseQueryAndFilter(queryArgument, filter, matchOptions)
  }

  return { query, options }
}

function extractMatchOptions(options: FindOptions): MatchOptions {
  return {
    searchAllFields: options?.searchAllFields
  }
}

// Pick fields from a document data object
//
// The function creates a new object with only the fields specified in the fields array.
//
// The function does not modify the input data object.
function pick(data: Types.DocumentData, fields: string[]): Types.DocumentData {
  const pickedData: Types.DocumentData = {};
  
  for (const field of fields) {
    if (data.hasOwnProperty(field)) {
      pickedData[field] = data[field];
    }
  }
  
  return pickedData;
}

// Omit fields from a document data object
//
// The function creates a new object with all fields from the input data object
// except the fields specified in the fields array.
//
// The function does not modify the input data object.
function omit(data: Types.DocumentData, fields: string[]): Types.DocumentData {
  const omittedData: Types.DocumentData = { ...data };
  for (const field of fields) {
    delete omittedData[field];
  }
  return omittedData;
}

