const HIDDEN_DRAG_IMAGE_ELEMENT_ID = 'ae-v-drag-and-drop__hidden-dragged-element-container'
const DRAG_GROUP_PREFIX = 'x-ae-dnd-group_'

const idVNodeStore = new Map()

export default {
  install: (Vue) => {
    Vue.directive('on-drop', {
      bind (el, binding, vnode) {
        el.dndOnDrop = drop.bind(null, el, binding, vnode)

        el.addEventListener('drop', el.dndOnDrop)
      },
      unbind (el, binding, vnode) {
        if (el.removeEventListener) {
          el.removeEventListener('drop', el.dndOnDrop)
        }
      }
    })

    Vue.directive('dragleave', {
      bind(el, binding, vnode) {
        el.onDragLeave = dragLeave.bind(null, el, vnode)

        el.addEventListener('dragleave', el.onDragLeave)
      },
      unbind(el, binding, vnode) {
        if (el.removeEventListener) {
          el.removeEventListener('dragleave', el.onDragLeave)
        }
      }
    })

    Vue.directive('dragover', {
      bind(el, binding, vnode) {
        el.dndOnDragOver = dragOver.bind(null, el, vnode)

        el.addEventListener('dragover', el.dndOnDragOver)
        el.addEventListener('dragenter', el.dndOnDragOver)
      },
      unbind(el, binding, vnode) {
        if (el.removeEventListener) {
          el.removeEventListener('dragover', el.dndOnDragOver)
          el.removeEventListener('dragenter', el.dndOnDragOver)
        }
      }
    })

    Vue.directive('draggable', {
      bind (el, binding, vnode) {
        const container = createAndInsertHiddenDraggedElementContainer()

        el.dndOnDragStart = dragStart.bind(null, container, el, binding, vnode)
        el.dndOnDragEnd = dragEnd.bind(null, el)

        el.setAttribute('draggable', true)
        el.addEventListener('dragstart', el.dndOnDragStart)
        el.addEventListener('dragend', el.dndOnDragEnd)
      },
      unbind (el, binding, vnode) {
        if (el.setAttribute) {
          el.setAttribute('draggable', false)
        }

        if (el.classList) {
          el.classList.remove('dragging')
        }

        if (el.dataset) {
          el.removeEventListener('dragstart', el.dndOnDragStart)
          el.removeEventListener('dragend', el.dndOnDragEnd)
        }
      }
    })

    Vue.directive('drag-item-dropped', {
      bind(el, binding, vnode) {
        if (binding.value == null) {
            return
        }

        if (typeof binding.value === 'function') {
          return
        }

        console.error('dropped can only handle function bindings')
        return
      }
    })

    Vue.directive('drag-item', {
      bind(el, binding, vnode) {
        if (binding.value == null) {
            console.error('You have to specify a value for drag-item ')
          return
        }

        idVNodeStore.set(JSON.stringify(binding.value), vnode)
      },
      update(el, binding, vnode, oldVnode) {
        if (!binding.value && oldVnode.value) {
          const oldId = JSON.stringify(oldVnode.value)
          if (idVNodeStore.has(oldId)) {
            idVNodeStore.delete(oldId)
          }

          console.error('You have to specify a value for drag-item ')
          return
        }

        idVNodeStore.set(JSON.stringify(binding.value), vnode)
      },
      unbind(el, binding, vnode) {
        if (binding.value == null) {
            return
        }

        const id = JSON.stringify(binding.value)

        if (idVNodeStore.has(id)) {
          idVNodeStore.delete(id)
        }
      }
    })

    Vue.directive('drag-group', {
      bind(el, binding, vnode) {
        if (binding.value == null) {
            return
        }

        if (typeof binding.value !== 'string' && typeof binding.value !== 'function') {
          console.error('drag-group must be of type string')
          return
        }
      },
      update(el, binding, vnode) {
        if (binding.value == null) {
            return
        }

        if (typeof binding.value !== 'string' && typeof binding.value !== 'function') {
          console.error('drag-group must be of type string')
          return
        }
      }
    })

    Vue.directive('drag-group-acceptable', {
      bind(el, binding, vnode) {
        if (binding.value == null) {
          return
        }

        if (typeof binding.value !== 'string' && typeof binding.value !== 'function' && !Array.isArray(binding.value)) {
          console.error('drag-group-acceptable value must be of type String or Array:')
          return
        }
      },
      update(el, binding, vnode) {
        if (binding.value == null) {
          return
        }

        if (typeof binding.value !== 'string' && typeof binding.value !== 'function' && !Array.isArray(binding.value)) {
          console.error('drag-group-acceptable value must be of type String or Array')
          return
        }
      }
    })
  }
}

function getDirective(vnode, name) {
    if (!vnode || !vnode.data || !vnode.data.directives) {
      return null
    }

    return vnode.data.directives.find(directive => directive.name === name)
}

function getVNode(id: string) {
  if (idVNodeStore.has(id)) {
    return idVNodeStore.get(id)
  }

  return null
}

function hoveringOverMiddle(event: MouseEvent, hoveredElement: HTMLElement) {
  const pageY = event.pageY

  const hoveredElementHeight = hoveredElement.clientHeight
  const { top } = getElementCoordinates(hoveredElement)

  const middleCoordinateYOfCard = top + (hoveredElementHeight / 2)
  return middleCoordinateYOfCard > pageY
}

function getElementCoordinates(elem) {
  const box = elem.getBoundingClientRect()

  return {
   top: box.top + pageYOffset,
   left: box.left + pageXOffset
  }
}

function createAndInsertHiddenDraggedElementContainer() {
  const alreadyInsertedContainer = document.getElementById(HIDDEN_DRAG_IMAGE_ELEMENT_ID)
  if (alreadyInsertedContainer) {
    return alreadyInsertedContainer
  }

  const container = (<any>document).createElement('div', { force: true })
  container.id = HIDDEN_DRAG_IMAGE_ELEMENT_ID
  container.style.width = '0px'
  container.style.height = '0px'
  container.style.position = 'absolute'
  container.style.top = '-500px'

  document.body.appendChild(container)

  return container
}

function setDragGhostImageWithDragTargetClone(draggedElementContainer: HTMLElement, event: DragEvent) {
  while (draggedElementContainer.firstChild) {
    draggedElementContainer.removeChild(draggedElementContainer.firstChild)
  }

  const target: any = event.target
  const draggedNode = target.cloneNode(true)

  draggedNode.style.width = target.offsetWidth + 'px'
  draggedNode.title = ''

  draggedElementContainer.appendChild(draggedNode)
  event.dataTransfer.setDragImage(draggedNode, 0, 0)
}

/**
 * The dragend DOM event is not fired in all circumstances (https://bugzilla.mozilla.org/show_bug.cgi?id=460801)
 * This handler might have to be called internally too, e.g. at the DOM drop event.
 * @param element
 * @param event original dragend DOM event, if available
 */
function dragEnd (element, event?) {
  // console.log('dragend', event)

  if (event && event.stopPropagation) {
    event.stopPropagation()
  }

  if (element && element.classList) {
    element.classList.remove('dragging')
  }

  const dragleaveEvent = new CustomEvent('dnd-dragend', {
    bubbles: true
  })

  element.dispatchEvent(dragleaveEvent)
}

function dragStart (container, element, binding, vnode, event) {
  // console.log('dragStart')

  event.stopPropagation()

  const effectAllowed = binding.value || 'all'
  event.dataTransfer.effectAllowed = effectAllowed

  const dragId = getDirective(vnode, 'drag-item').value
  event.dataTransfer.setData('x-ae-id', JSON.stringify(dragId))

  const dragGroupId = getDragGroupId(vnode)

  if (dragGroupId) {
    event.dataTransfer.setData(DRAG_GROUP_PREFIX + dragGroupId, '')
  }

  setDragGhostImageWithDragTargetClone(container, event)

  // setting the class directly causes an emit of a dragEnd event immediately after the dragStart event
  setTimeout(() => {
    event.target.classList.add('dragging')
  }, 0)
}

function drop (element, binding, vnode, event) {
  const incomingGroup = getDragGroup(event)

  const acceptedGroups = getAcceptedGroups(vnode)

  if (acceptedGroups && !acceptedGroups.includes(incomingGroup)) {
    return
  }

  event.preventDefault()
  event.stopPropagation()

  const sourceId = event.dataTransfer.getData('x-ae-id')

  binding.value.call(element, JSON.parse(sourceId))

  const sourceVNode = getVNode(sourceId)

  dragEnd(sourceVNode.elm)

  const onDroppedDirective = getDirective(sourceVNode, 'drag-item-dropped')

  if (onDroppedDirective && typeof onDroppedDirective.value === 'function') {
    onDroppedDirective.value()
  }

  dragLeave(element, vnode, event)
}

function dragLeave (element, vnode, event: DragEvent) {
  const incomingGroup = getDragGroup(event)

  const acceptedGroups = getAcceptedGroups(vnode)

  if (acceptedGroups && !acceptedGroups.includes(incomingGroup)) {
    return
  }

  if (element.contains(event.relatedTarget)) {
    return
  }

  const dragleaveEvent = new CustomEvent('dnd-dragleave', {
    bubbles: true
  })

  element.dispatchEvent(dragleaveEvent)
}

function dragOver (element, vnode, event) {
  const incomingGroup = getDragGroup(event)

  const acceptedGroups = getAcceptedGroups(vnode)

  if (acceptedGroups && !acceptedGroups.includes(incomingGroup)) {
    return
  }

  event.preventDefault()
  event.stopPropagation()

  const dragIdDirective = getDirective(vnode, 'drag-item')
  const targetId = dragIdDirective ? dragIdDirective.value : null

  if (hoveringOverMiddle(event, element)) {
    const hoverEvent = new CustomEvent('dnd-dragover--before', {
      detail: targetId,
      bubbles: true
    })
    element.dispatchEvent(hoverEvent)
  } else {
    const hoverEvent = new CustomEvent('dnd-dragover--after', {
      detail: targetId,
      bubbles: true
    })
    element.dispatchEvent(hoverEvent)
  }
}

function getAcceptedGroups(vnode) {
  return getDirectiveValueAsArray(vnode, 'drag-group-acceptable')
}

function getDragGroupId(vnode) {
  return getDirectiveValue(vnode, 'drag-group')
}

function getDirectiveValue(vnode, name) {
  const directive = getDirective(vnode, name)
  
  if (!directive || !directive.value) {
    return null
  }
  
  return typeof directive.value === 'function' ? directive.value() : directive.value
}

function getDirectiveValueAsArray(vnode, name) {
  const value = getDirectiveValue(vnode, name)
  
  if (value == null) {
    return null
  }
  
  if (Array.isArray(value)) {
    return value
  }

  return [value]
}

function getDragGroup(event: DragEvent) {
  if (!event.dataTransfer.types) {
    return
  }

  for (const type of event.dataTransfer.types) {
    if (!type.startsWith(DRAG_GROUP_PREFIX)) {
      continue
    }

    return type.substring(DRAG_GROUP_PREFIX.length)
  }
}
