import { assert } from '@aeppic/shared/assert'
import { Condition, ExplicitOperator, isIntermediaryNode, isLeafNode, MatchExpression, Query, QueryGraphNode, QueryParameters, RawFieldMatchNode, RawParsedOperator } from './types.js'

export class QueryRefiner {
  static process(queryGraph: QueryGraphNode): Query {
    if ('conditions' in queryGraph) {
      throw new Error('Already optimized')
    }

    // console.dir(queryGraph, { depth: null })
    
    const parameters: QueryParameters = {}

    const matchExpression = QueryRefiner._refineNode(queryGraph, parameters)
    
    // console.dir(matchExpression, { depth: null })

    return { 
      graph: matchExpression,
      parameters,
    }
  }

  private static _refineNode(node: QueryGraphNode, parameters: QueryParameters): MatchExpression {
    if (!node) {
      return null
    }

    if (typeof node !== 'object') {
      throw new Error('node must be an object')
    }

    if (isIntermediaryNode(node)) {
      if (node.operator === '<implicit>') {
        throw new Error('<implicit> operator not allowed in optimization needs to be replaced first')
      }

      const leftConditions = this._refineNode(node.left, parameters)
      const rightConditions = this._refineNode(node.right, parameters)

      return joinMatchExpressions(leftConditions, rightConditions, node.operator)
    } else {
      assert(isLeafNode(node))
      
      const condition = toFinalCondition(node, parameters)
      return { conditions: [condition] }
    }
  }
} 

export function toFinalCondition(node: RawFieldMatchNode, parameters: QueryParameters) {
  if ('term' in node) {
    const newParameterId = Object.keys(parameters).length
    parameters[newParameterId] = node.term

    return {
      ...node as any, term: { id: newParameterId }
    }
  } else if ('term_min' in node && 'term_max' in node) {
    const newMinParameterId = Object.keys(parameters).length
    parameters[newMinParameterId] = node.term_min

    const newMaxParameterId = Object.keys(parameters).length
    parameters[newMaxParameterId] = node.term_max

    return {
      ...node as any, term_min: { id: newMinParameterId }, term_max: { id: newMaxParameterId }
    }
  }

  throw new Error('Not implemented')
}

export function joinMatchExpressions(left: MatchExpression, right: MatchExpression, operator: RawParsedOperator = 'AND') {
  const conditions = []

  if (left) {
    conditions.push(...readConditions(left, operator))
  }

  if (right) { 
    conditions.push(...readConditions(right, operator))
  }

  if (conditions.length <= 1) {
    return { conditions }
  } else {
    return { operator, conditions }
  }
}

function readConditions(match: MatchExpression, currentOperator: RawParsedOperator): Condition[] {
  if (match.conditions.length <= 1) {
    return match.conditions
  }
  
  assert('operator' in match)

  if ('operator' in match) {
    if (match.operator === currentOperator) {
      return match.conditions
    } else {
      return [match]
    }
  }
}
