
import { loadStyle } from '../utils/css'

const STYLE = `
.ae-splitter {
  height: inherit;
  display: flex;
  min-width: 0;
}
.ae-splitter--pane {
  height: inherit;
  overflow: auto;
  display:flex;
  flex-direction:column;
}
.ae-splitter--pane > * {
  flex:1;
}
.ae-splitter--bar {
  width: 2px;
  min-width: 2px;
  background-color: #9e9e9e;
  background-color: var(--ae-separator-color, var(--ae-border-color, #9e9e9e));
  cursor: ew-resize;
}
`

export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ae-splitter",style:({ cursor: _vm.cursor, userSelect: _vm.userSelect }),on:{"mouseup":_vm.onUp,"mousemove":_vm.onMouseMove,"touchmove":_vm.onMove,"touchend":_vm.onUp}},[_c('div',{staticClass:"ae-left-pane ae-splitter--pane",class:{ 'ae-expand-region': _vm.limitExpand },style:({ 'flex-basis': _vm.percent+'%' })},[_vm._t("left-pane")],2),_vm._v(" "),_c('div',{staticClass:"ae-splitter--bar",class:{active: _vm.active},on:{"mousedown":_vm.onDown,"click":_vm.onClick,"touchstart":function($event){$event.preventDefault();return _vm.onDown.apply(null, arguments)}}}),_vm._v(" "),_c('div',{staticClass:"ae-right-pane ae-splitter--pane",class:{ 'ae-expand-region': _vm.limitExpand },staticStyle:{"flex":"1"}},[_vm._t("right-pane")],2)])},
  staticRenderFns: [],
  /* tslint:enable */
  
  inject: ['getAeppicContext'],
  props: {
    margin: {
      type: Number,
      default: 10
    },
    limitExpand: {
      type: Boolean,
      default: true
    },
    split: {
      type: Number
    }
  },
  data () {
    const Aeppic = this.getAeppicContext('ae-splitter')
    
    return {
      Aeppic,
      active: false,
      percent: 50,
      hasMoved: false
    }
  },
  beforeDestroy() {
    this.Aeppic.release()
  },
  computed: {
    userSelect () {return this.active ? 'none' : ''},
    cursor () {return this.active ? 'ew-resize' : ''},
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
    loadStyle(STYLE)
  },
  methods: {
    onClick() {
      if (!this.hasMoved) {
        this.percent = 50
        this.$emit('resize')
      }
    },
    onDown(e) {
      this.active = true
      this.hasMoved = false
    },
    onUp() {
      this.active = false
    },
    onMove(e) {
      if (this.active) {
        let offset = 0
        let target = e.currentTarget
        while (target) {
          offset += target.offsetLeft
          target = target.offsetParent
        }
        const percent =  Math.floor(((e.pageX - offset) / e.currentTarget.offsetWidth) * 10000) / 100
        if (percent > this.margin && percent < 100 - this.margin) {
          this.percent = percent
        }
        this.$emit('resize')
        this.hasMoved = true

        this.Aeppic.triggerResize()

      }
    },
    onMouseMove(e) {
      if (e.buttons === 0 || e.which === 0) {
        this.active = false
      }
      this.onMove(e)
    }
  },
  watch: {
    split: {
      handler() {
        if (this.split != null) {
          this.percent = this.split
          this.$emit('resize')
        }
      },
      immediate: true
    }
  }
}
