export const VALID_SEARCH_TYPES = ['children', 'descendants', 'ancestors', 'siblings']

export function isValidTypeOrNull(type) {
  return type == null || VALID_SEARCH_TYPES.indexOf(type) >= 0
}

export default function buildCompleteSearchString(search, filter, type, rootDocument, queryDocument) {
  const searchParts = [ search, filter ]

  if (type && rootDocument) {
    searchParts.push(filterQueryResultsByQueryType(type, rootDocument))
  } else if (queryDocument) {
    searchParts.push(filterQueryResultsByQueryType(queryDocument.data.type, rootDocument))
  }

  if (queryDocument && queryDocument.data.query && !search) {
    searchParts.push(queryDocument.data.query)
  }

  return searchParts.filter(s => !!s?.trim()).map(wrapInBrackets).join(' AND ')
}

function filterQueryResultsByQueryType(queryType, rootDocument) {
  if (!queryType) {
    return ''
  }
  
  switch (queryType) {
    case 'children':
      return `p:${rootDocument.id}`
    case 'descendants':
      return `a:${rootDocument.id}`
    case 'ancestors':
      if (!rootDocument) {
        console.error('Query type requires a valid root document to start from')
      }
      return `id:(${rootDocument.a.join(' OR ')})`
    case 'siblings': 
      if (!rootDocument) {
        console.error('Query type requires a valid root document to start from')
      }
      return `p:${rootDocument.p}`
    default:
      // tslint:disable-next-line    
      console.warn(`Ignoring query.type '${queryType}'`)
      return ''
  }
}

function wrapInBrackets(term) {
  if (!term || term.length === 0) {
    return ''
  } else {
    return `(${term})`
  }
}
