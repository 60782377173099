const StringPropertyCollectionSchema = {
  type: 'object',
  patternProperties: {
    '^.*$': { type: 'string' }
  },
  additionalProperties: true
}

const ArrayOfUniqueStringsSchema = {
  type: 'array',
  uniqueItems: true,
  items: {
    type: 'string',
    minLength: 1,
    pattern: '\\S'
  }
}

/**
 * Schema to validate old and new package manifests
 * 
 * Internally the get rewritten based on the internal
 * types. E.g `type` will never be 'vue'
 */
export const PackageManifestSchema = {
  type: 'object',
  properties: {
    type: { enum: ['module', 'vue', 'commonjs'] },
    exports: StringPropertyCollectionSchema,
    components: {
      oneOf: [StringPropertyCollectionSchema, ArrayOfUniqueStringsSchema]
    },
    assets: ArrayOfUniqueStringsSchema,
    styles: ArrayOfUniqueStringsSchema,
    amd: {
      type: 'object',
      properties: {
        config: {
          type: 'object',
          properties: {
            paths: StringPropertyCollectionSchema
          }
        }
      }
    },
    /** CommonJS compatiblity */
    main: { type: 'string' },
  },
  additionalProperties: true
}
