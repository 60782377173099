
import * as Types from '@aeppic/types'
import Vue from '../../externals/vue.js'

import { EditableDocument } from '../../model'
import DynamicComponent from '../dynamic/dynamic-component' 
import editFunctions from '../utils/edit-ref-functions'
import createEventForwarders from '../utils/create-event-forwarders'

export default {
  /* tslint:disable */
  render () {
    const _vm=this;
    const _h=_vm.$createElement;
    const _c=_vm._self._c||_h;
    
    return (_vm.dynamicComponent.id) ? _c(_vm.dynamicComponent.id,
      {
        tag: 'component',
        props: {
          'params': _vm.params,
          'layout': _vm.dynamicComponent.document
        },
        on: createEventForwarders(_vm._events, _vm)
      }) : _vm._e()
  },
  renderError(h, err) {
    return h('pre', { staticClass: 'ae-error', style: { color: 'red' }}, 'ae-layout:' + this.layoutId + ' ' +  err.stack)
  },
  staticRenderFns: [],
  /* tslint:enable */
  
  inject: ['getAeppicContext'],
  props: { 
    layoutId: String,
    params: Object,
    watchLayout: {
      type: Boolean,
      default: null
    },
    watchLayoutRevisions: {
      type: Boolean,
      default: null
    }
  },
  data() {
    const Aeppic = this.getAeppicContext('ae-layout-selector')

    const isDeveloper = Aeppic.Account && Aeppic.Account.data.allowDevMode

    const watch = typeof this.watchLayout === 'boolean' ? this.watchLayout : isDeveloper
    const watchRevisions = typeof this.watchLayoutRevisions === 'boolean' ? this.watchLayoutRevisions : isDeveloper

    const dynamicComponent = new DynamicComponent('layout', Aeppic, {
      formId: 'layout-form',
      template: LAYOUT_COMPONENT_TEMPLATE,
      exposedSymbols: '$el, $refs, $listeners, locals, Aeppic, Math, DateTime, Anime, params, layout, $emit, $on, translate, watchParam',
      watch,
      watchRevisions
    })

    return {
      Aeppic,
      dynamicComponent,
    }
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  },
  created: function() {
    // console.log('layout created')
    this.loadLayout = async () => {
      const id = this.layoutId
      
      if (id) {
        const layoutDocumentId = id
        this.dynamicComponent.refresh(layoutDocumentId)
      } else {
        this.dynamicComponent.reset()
      }
    }

    this.loadLayout()
  },
  beforeDestroy() {
    this.dynamicComponent.destroy()
    this.Aeppic.release()
  },
  watch: {
    layoutId(id) {
      this.loadLayout()
    },
    // params: {
    //   deep: true,
    //   handler() {
    //     this.render()
    //   }
    // }
  }
}

const LAYOUT_COMPONENT_TEMPLATE = `{
  template: __TEMPLATE__,
  inject: ['getAeppicContext'],
  props: ['params', 'layout'],
  data() {
    const Aeppic = this.getAeppicContext('ae-layout')

    const self = this
    const locals = __NEW_LOCALS__
    const handlers = []

    const controllerVariables = {
      get $el() { return self.$el },
      get $refs() { return self.$refs },
      $listeners: self.$listeners,
      locals,
      Aeppic,
      get DateTime() { return Aeppic.DateTime },
      get Math() { return Aeppic.Math },
      get Anime() { return Aeppic.Anime },
      get params() { return self.params },
      get layout() { return self.layout },
      translate(...args) { return self.translate(...args)},
      watchParam(...args) { return self.watchParam(...args)},
      $emit(...args) { return self.$emit(...args) },
      $on(...args) { 
        self.$parent.$on(...args)
        self.$on(...args)

        handlers.push(args)
      },
    }

    const controller = controllerClass ? new controllerClass(controllerVariables) : null

    return {
      Aeppic,
      controller,
      locals,
      handlers
    }
  },
  beforeDestroy() {
    __DESTROY_CONTROLLER__

    for (const handlerArgs of this.handlers) {
      this.$off(...handlerArgs) // remove all controller registered listeners (own scope)
      this.$parent.$off(...handlerArgs) // remove all controller registered listeners (parent scope)
    }

    this.handlers = []

    this.Aeppic.release()
    this.Aeppic = null
    this.packages = null
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  },
  computed: {
    Math() { return this.Aeppic.Math },
    DateTime() { return this.Aeppic.DateTime },
    Anime() { return this.Aeppic.Anime },
  },
  methods: {
    __METHODS__,
    startEditing(refName) {
      if (!this.$refs[refName]) {
        return
      }

      return this.$refs[refName].startEditing()
    },
    cancelEditing(refName) {
      if (!this.$refs[refName]) {
        return
      }

      return this.$refs[refName].cancelEditing()
    },
    saveAndStopEditing(refName) {
      if (!this.$refs[refName]) {
        return
      }

      return this.$refs[refName].saveAndStopEditing()
    },
    save(refName) {
      if (!this.$refs[refName]) {
        return
      }

      return this.$refs[refName].save()
    },
    translate(...args) { 
      return this.Aeppic.translate(...args) 
    },
    watchParam(name, cb, options) {
      if (!name) {
        return
      }

      let lastValue = undefined

      const cbWithPreCheck = (newValue, oldValue) => {
        if (Array.isArray(newValue) || lastValue !== newValue) {
          cb(newValue, oldValue)
        }

        lastValue = newValue
      }
      
      return this.$watch('params.' + name, cbWithPreCheck, options)
    }
  },
  components
}`

