import * as Types from '@aeppic/types'

import { ensureDataValidatesAgainstSchema } from '@aeppic/shared/schema'

import { isNamedDocument } from '../../model/is.js'
import { ImportPackageFileFunction, LegacyPackageInfo, PackageInfo, PackageManifest, PackageFileLoadInfo, GroupedAssets } from './types'

import { PackageManifestSchema } from './schema.js'

export interface Options {
  importPackageFile: ImportPackageFileFunction
}

export async function loadPackageInfos(packageDocuments: Types.Document[], options: Options) {
  if (!options) {
    throw new Error('Argument options missing')
  }
  
  const loaders = packageDocuments.map(d => loadPackageInfo(d, options))
  
  const results = await Promise.allSettled(loaders)
  
  const resolvedInfos = results.map(result => {
    return result.status === 'fulfilled' ? result.value : null
  })
  
  return resolvedInfos
}

interface IndividualPackageInfoLoaderOptions extends Options {
  packageDocument: Types.Document
}

export async function loadPackageInfo(packageDocument: Types.Document, options: Options) {
  if (!options) {
    throw new Error('Argument options missing')
  }

  const packagageLoader = new IndividualPackageInfoLoader({ packageDocument, ...options })
  return packagageLoader.load()
}

export class IndividualPackageInfoLoader {
  private _packageDocument: Types.DocumentWithName
  private _importPackageFile: ImportPackageFileFunction

  constructor({ packageDocument, importPackageFile }: IndividualPackageInfoLoaderOptions) {
    if (!isNamedDocument(packageDocument)) {
      throw new Error('Invalid argument. Package needs a name')
    }

    this._packageDocument = packageDocument
    this._importPackageFile = importPackageFile
  }
  
  async load(): Promise<PackageInfo> {
    const manifest = await this._loadManifest()
    const namedRoot = this._extractNamedRoot()

    const info: PackageInfo = {
      identifier: toIdentifier(this._packageDocument),
      document: this._packageDocument,
      ...manifest,
      exportsRoot: namedRoot,
    }
    
    return info
  }

  private _extractNamedRoot() {
    const mode = this._packageDocument.data.exportMode ?? 'FLAT'

    switch (mode) {
      case 'FLAT':
        return ''
      case 'PACKAGE_NAME':
        return this._packageDocument.data.name as string
      case 'CUSTOM_NAME':
        return this._packageDocument.data.customExportName as string
      default:
        throw new Error(`Unknown export mode: ${mode}`)
    } 
  }

  private async _loadManifest(): Promise<PackageManifest> {
    const aeppicJson = await this._importFile({ filePath: './aeppic.json', type: 'json' })

    const info = JSON.parse(aeppicJson)

    const manifest = ('main' in info) ? this._convertLegacyManifest(info) : { ...info, type: 'module' }

    this._ensureDataValidatesAgainstSchema(manifest, PackageManifestSchema)

    return manifest
  }

  private _ensureDataValidatesAgainstSchema(data: any, schema: object) {
    ensureDataValidatesAgainstSchema(data, schema, `${this._packageDocument.data.name} (${this._packageDocument.id}@${this._packageDocument.v})`)
  }

  private async _importFile(fileInfo: PackageFileLoadInfo, { optional = false } = {}): Promise<string> {
    const fileContent = await this._importPackageFile(this._packageDocument, fileInfo)

    if (!fileContent && !optional) {
      throw new Error(`Could not load file '${fileInfo.filePath}' from package '${this._packageDocument.data.name}' (${this._packageDocument.id})`)
    }

    return fileContent
  }

  private _convertLegacyManifest(info: LegacyPackageInfo): PackageManifest {
    const exports: PackageInfo['exports'] = {}
    const components: PackageInfo['components'] = []
    const styles = info.styles ?? []
    const assets = info.assets ?? []

    if (info.amd) {
      const errorMessage = 'Packages using AMD configuration are current not supported'
      throw new Error(errorMessage)
    }

    exports['.'] = toPackageRelativePath(info.main)

    for (const [name, type] of Object.entries(info.exports)) {
      if (type === 'vue') {
        components.push(name)
      }
    }

    const namedAssets = Array.isArray(assets) ? assets : convertGroupedAssetsToNamedAssets(assets)

    return {
      type: 'commonjs',
      components,
      exports,
      styles: styles.map(toPackageRelativePath),
      assets: namedAssets.map(toPackageRelativePath),
    }
  }
}

function convertGroupedAssetsToNamedAssets(groupedAssets: GroupedAssets) {
  const allNamedAssets: string[] = []

  for (const namedAssets of Object.values(groupedAssets)) {
    allNamedAssets.push(...namedAssets)
  }

  return allNamedAssets
}

function toIdentifier(packageDocument: Types.DocumentWithName) {
  return `${packageDocument.data.name} (${packageDocument.id}@${packageDocument.v})`
}

function toPackageRelativePath(pathSegment: string) {
  if (pathSegment.startsWith('.')) {
    return pathSegment
  } else {
    return `./${pathSegment}`
  }
}