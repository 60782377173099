
import { fibonacci } from '../utils/backoff'

export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_vm._t("loading"):(_vm.error)?_vm._t("error"):_vm._t("default")],2)},
  staticRenderFns: [],
  /* tslint:enable */
  
  inject: ['getAeppicContext'],
  data() {
    return {
      Aeppic: this.getAeppicContext('ae-fetch'),
      loading: true,
      error: false,
    }
  },
  props: {
    url: {
      type: String,
      required: true
    },
    fetchMethod: {
      type: String,
      default: 'GET'
    },
    documentId: String,
    retryCount: {
      type: Number,
      default: 3
    },
    retryOnStatusCodes: Array
  },
  methods: {
    async tryFetchingResource() {
      if (this.isDocumentUploading()) {
        return
      }

      if (this.$_backoff) {
        return
      }

      this.$_backoff = fibonacci({
        randomisationFactor: 0.1,
        initialDelay: 10,
        maxDelay: 5000
      })

      this.$_backoff.failAfter(this.retryCount)

      this.$_backoff.on('fail', () => {
        this.loading = false
        this.error = true
        this.unregisterUploadedEventHandler()

        this.$emit('response', {
          error: this.error,
          loading: this.loading,
          response: null
        })
      })

      this.$_backoff.on('ready', async () => {
        const { ok: isFetchable, retry, response } = await this.fetch()

        if (isFetchable) {
          this.error = false
          this.loading = false
        } else if (retry) {
          this.$_backoff.backoff()
          return
        } else {
          this.loading = false
          this.error = true
        }

        this.$_backoff.reset()
        this.unregisterUploadedEventHandler()

        this.$emit('response', {
          error: this.error,
          loading: this.loading,
          response
        })
      })

      this.$_backoff.backoff()
    },

    async fetch() {
      this.abortFetch()

      this.$_fetchAbortController = new AbortController()

      const response = await this.Aeppic.fetch(this.url, {
        method: this.fetchMethod,
        signal: this.$_fetchAbortController.signal
      })

      if (response.ok) {
        return { ok: true, retry: false, response }
      }

      if (this.retryStatusCodes) {
        const statusCodeAllowsRetry = this.retryStatusCodes.find(statusCode => statusCode === response.status)

        return { ok: false, retry: statusCodeAllowsRetry, response }
      }

      return  { ok: false, retry: true, response }
    },
    reset() {
      if (this.$_backoff) {
        this.$_backoff.reset()

        this.$_backoff = null
      }

      this.unregisterUploadedEventHandler()
      this.abortFetch()

      this.loading = true
      this.error = false

    },
    unregisterUploadedEventHandler() {
      if (this.$_uploadedEventHandler) {
        this.Aeppic.Uploads.off('uploaded', this.$_uploadedEventHandler)
      }
    },
    abortFetch() {
      if (this.$_fetchAbortController && !this.$_fetchAbortController.signal.aborted) {
        this.$_fetchAbortController.abort('abort-fetch')
      }

      this.$_fetchAbortController = null
    },
    watchUploadEvent() {
      this.unregisterUploadedEventHandler()

      if (!this.documentId) {
        return
      }

      this.$_uploadedEventHandler = uploadedDocument => {
        if (uploadedDocument && uploadedDocument.id === this.documentId) {
          this.tryFetchingResource()
        }
      }

      this.Aeppic.Uploads.on('uploaded', this.$_uploadedEventHandler)
    },
    isDocumentUploading() {
      return (this.documentId && this.Aeppic.Uploads.isDocumentUploading(this.documentId))
    },
  },
  watch: {
    documentId() {
      this.reset()
      this.watchUploadEvent()
      this.tryFetchingResource()
    },
    url() {
      this.reset()
      this.watchUploadEvent()
      this.tryFetchingResource()
    }
  },
  created() {
    this.$_fetchAbortController = null
    this.$_uploadedEventHandler = null
    this.$_backoff = null
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)

    this.watchUploadEvent()
    this.tryFetchingResource()
  },
  beforeDestroy() {
    this.reset()

    this.Aeppic.release()
  }
}
