
import { EMPTY_ITEM_THEME } from '../form/theme.js'
import { getFirstNonEmptyString }  from '../utils/lookup.js'

export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible)?_c('ae-layout',{staticClass:"ae-form-field",class:_vm.fieldTheme.classes,attrs:{"param-field":_vm.field,"param-document":_vm.document,"param-form":_vm.formToUse,"param-theme":_vm.theme,"param-readonly":_vm.readonly || _vm.field.isReadonly,"param-placeholder":_vm.placeholder,"layout-id":_vm.layoutToUseId,"watch-layout":_vm.watchLayout,"watch-layout-revisions":_vm.watchLayoutRevisions}}):_vm._e()},
  staticRenderFns: [],
  /* tslint:enable */
  
  props: {
    field: {
      type: Object, // FormFieldAtPlaceholder
      required: true
    },
    document: {
      type: Object,
      required: true
    },
    form: Object,
    theme: Object,
    layoutId: String,
    watchLayout: {
      type: Boolean,
      default: true
    },
    watchLayoutRevisions: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: Object,
      default: null
    }
  },
  computed: {
    fieldTheme() {
      return this.theme?.field ?? EMPTY_ITEM_THEME
    },
    layoutToUseId() {
      return getFirstNonEmptyString(this.layoutId, this.fieldTheme.layoutId, '7f087b7f-c6a4-49d8-803a-4486341ae23d')
    },
    formToUse() {
      return this.form ?? this.document?.form
    },
    isVisible() {
      const isVisible = this.field?.isVisible ?? true
      return isVisible && this.document && this.formToUse
    },
  }
}
