export default function generateVersion() {
  // TODO: Support web crypto and node js too
  const randHex = generateRandom64BitHex()

  if (randHex.length === 16) {
    return randHex
  } else {
    return padLeft(randHex, 16, '0')
  }
}

function generateRandom64BitHex() {
  const low = randUInt32().toString(16)
  const high = randUInt32().toString(16)
  return (high + low).toLowerCase()
}

const UINT32_MAX = 0xFFFFFFFF

function randUInt32() {
  return Math.floor(Math.random() * UINT32_MAX)
}

function padLeft(text, targetLength, padString) {
  if (text.padStart) {
    return text.padStart(targetLength, padString)
  } else {
    targetLength = targetLength >> 0 // floor if number or convert non-number to 0
    padString = String(padString || ' ')
    if (text.length > targetLength) {
      return String(text)
    } else {
      targetLength = targetLength - text.length
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length) // append to original to ensure we are longer than needed
      }
      return padString.slice(0, targetLength) + String(text)
    }
  }
}
