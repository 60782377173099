import { DateTime } from 'luxon'
import * as Types from '@aeppic/types'

import getNow from  '@aeppic/shared/now'

// import { Form } from '../form'
// import resolveField from './resolve-field'
import { isIsoDate, parseDateRange } from './parse-date.js'

// import { joinMatchExpressions as joinParsedQueries } from './refining'
import { MatchOptions, MatchQueryFunction, isFieldMatchNode, FieldMatchNode, MatchExpression, Condition, Query, QueryParameters, GenericMatchQueryFunction, isQuery, QueryParameterValue } from './types.js'
import { parse } from './parse.js'
import { extractShapeFromQuery } from './shape.js'

import { QueryCache } from './cache.js'
import * as fields from './fields.js'

// type AccessInfo = {
//   added: number
//   lastAccess: number
// }
// type QueryTestFunctionCacheEntry = AccessInfo & {
//   match: QueryTestFunction
//   parsed: ParsedQuery
// }
// type QueryIdCacheEntry = AccessInfo & {
//   id: number
//   query: string
// }

// const TestFunctionsIds = new Map<string, QueryIdCacheEntry>()
// const TestFunctionsCache = new Map<string, QueryTestFunctionCacheEntry>()

const FIELD_MATCHERS = {
  boolean: supportArrayMatches(fieldMatchesBoolean),
  truthy:  supportArrayMatches(fieldMatchesTruthy),
  wildcard: supportArrayMatches(fieldMatchesWildcard),
  range: supportArrayMatches(fieldMatchesRange),
  term: supportArrayMatches(fieldMatchesTerm),
}

const DOCUMENT_ACCESS = 'document.'
const DATA_FIELD_PREFIX = 'data.'

const HELPERS = {
}

function MATCH_NOTHING() {return false}
MATCH_NOTHING.matchFunctionId = Symbol('Match NOTHING Matcher function')

const QUERY_FUNCTION_PARAMETER_NAMES = ['document', 'parameters', 'options']
type ShapeId = NonNullable<string>

export const GlobalQueryCache = new QueryCache<CompiledQuery>()

export function parseQueryAndFilter(queryString: string, filterStringOrOptions?: MatchOptions|string, specifiedOptions?: MatchOptions): Query {
  let query: Query
  let options: MatchOptions

  if (!queryString || queryString.trim() === '') {
    if (filterStringOrOptions && typeof filterStringOrOptions === 'string' && filterStringOrOptions.trim() !== '') {
      queryString = filterStringOrOptions
      filterStringOrOptions = ''
    } else {
      return {
        graph: {
          conditions: []
        },
        parameters: [],
        options,
      }
    }
  }

  if (typeof filterStringOrOptions === 'string') {
    const fullQuery = joinQueryAndFilter(queryString, filterStringOrOptions)
    const parsedQuery = parse(fullQuery)

    return {
      graph: parsedQuery.graph,
      parameters: parsedQuery.parameters,
      options: specifiedOptions ?? {}
    }
  } else if (specifiedOptions) {
    const parsedQuery = parse(queryString)

    return {
      graph: parsedQuery.graph,
      parameters: parsedQuery.parameters,
      options: specifiedOptions ?? {}
    }
  } else {
    const parsedQuery = parse(queryString)

    return {
      graph: parsedQuery.graph,
      parameters: parsedQuery.parameters,
      options: filterStringOrOptions ?? specifiedOptions ?? {}
    }
  }
}

function matchAll(document: Types.Document) {
  return true
}

export class QueryMatcher {
  private _match: MatchQueryFunction
  private _genericMatch: GenericMatchQueryFunction

  private _query: Query
  private _shapeId: ShapeId

  constructor(query: Query) {
    if (!isQuery(query)) {
      throw new Error('Argument error. QueryMatcher requires Query as input')
    }

    this._query = query
    
    if (this._query.graph.conditions.length === 0) {
      this._shapeId = 'ALL'
      this._match = matchAll
      return
    }
    
    this._shapeId = extractShapeFromQuery(query)
    const existingMatchFunction = GlobalQueryCache.get(this._shapeId)

    if (existingMatchFunction) {
      this._genericMatch = existingMatchFunction.match
    } else {
      const match = compileQuery(query)
      const compiled: CompiledQuery = { match, shapeId: this._shapeId }
      GlobalQueryCache.set(this._shapeId, compiled)

      this._genericMatch = match
    }

    this._match = (d: Types.Document) => {
      return this._genericMatch(d, this._query.parameters, this._query.options || {})
    }
  }

  get shapeId() {
    return this._shapeId
  }

  get matchFunctionId() {
    return this._genericMatch.matchFunctionId
  }

  get query() {
    return this._query
  }

  match(document: Types.Document) {
    return this._match(document)
  }
}

type CompiledQuery = {
  // id: string
  // displayName: string
  // parsed: MatchExpression
  match: GenericMatchQueryFunction
  // fullQuery: string
  // originalQuery: string
  // originalFilter: string
  shapeId: ShapeId
  // queryFunctionId: number
}

function joinQueryAndFilter(queryString: string, filterString: string): string {
  let fullQuery = null

  if (queryString && queryString.trim() !== '') {
    fullQuery = queryString
  }

  if (filterString && filterString.trim() !== '') {
    if (fullQuery) {
      return `(${fullQuery}) AND (${filterString})`
    } else {
      return filterString
    }
  } else {
    return fullQuery
  }
}

//   const { match: matchQuery, parsed: parsedQuery, shapeId: queryShape } = compileQuery(queryString, options)
//   const { match: matchFilter, parsed: parsedFilter, shapeId: filterShape } = compileQuery(filterString, options)
//   // const displayName = `dynamic://aeppic/queries/${queryFunctionId}/${filterFunctionId}`

//   const match = joinMatchFunctions(matchQuery, matchFilter)

//   return {
//     // displayName,
//     match,
//     parsed: joinParsedQueries(parsedQuery, parsedFilter),
//     originalQuery: queryString,
//     originalFilter: filterString,
//     shapeId: queryShape + '~' + filterShape,
//   }
// }

// function joinMatchFunctions(matchQuery: MatchQueryFunction, matchFilter: MatchQueryFunction): MatchQueryFunction {
//   if (!matchFilter) {
//     return matchQuery
//   }

//   const matchFunction: any = (document: Types.Document) => {
//     const queryMatches = matchQuery(document)

//     if (queryMatches === false || !matchFilter) {
//       return queryMatches
//     }

//     return matchFilter(document)
//   }

//   matchFunction.matchFunctionId = Symbol('Joined Match Function')
//   return matchFunction
// }

function compileQuery(query: Query): GenericMatchQueryFunction {  
  // const start = process.hrtime.bigint()

  const queryFunctionDefinition = buildQueryFunctionDefinition(query)

  try {
    const innerMatchFunction = new Function('now', ...QUERY_FUNCTION_PARAMETER_NAMES, 'fieldMatchers', 'helpers', queryFunctionDefinition)
    
    const wrappedFn = <GenericMatchQueryFunction> function(document: Types.Document, parameters: QueryParameters, options: MatchOptions) {
      const now = getNow()
      return innerMatchFunction(now, document, parameters, options, FIELD_MATCHERS, HELPERS)
    }

    wrappedFn.displayName = `Query`
    wrappedFn.matchFunctionId = Symbol(`Match Function for ${wrappedFn.displayName}`)

    return wrappedFn
  } catch (compileError) {
    // tslint:disable-next-line no-console
    console.error('Error compiling dynamic code', compileError, queryFunctionDefinition)
    throw new Error('Compilation Error')
  } finally {
    // const end = process.hrtime.bigint()
    // const diffInNanoseconds = end - start
    // const totalDurationInMs = Number(diffInNanoseconds / BigInt(1000000))
    
    // console.log('compile', diffInNanoseconds, totalDurationInMs)
  }
}

function buildQueryFunctionDefinition(query: Query): string {
  const MATCH_CONDITIONS = compileMatchExpressions(query.graph, query.parameters, query.options)
  
  const definition = `
    /*
     * QUERY
     *
     * Built: ${new Date().toISOString()}
     *
     ****

     Graph:
     ${JSON.stringify(query.graph, null, 2)})

     Options:
     ${JSON.stringify(query.options, null, 2)})
     
     ****
     */
    const matches = ${MATCH_CONDITIONS || 'false'};
    return matches
  ` 
  // console.log('QUERY FUNC', query)
  // console.log(definition)

  return definition
}

function compileMatchExpressions(expression: MatchExpression, parameters: QueryParameters, options: MatchOptions) {
  const { conditions } = expression

  if (conditions.length === 1) {
    return compileMatchConditionExpression(conditions[0], parameters, options)
  }
  
  const expressions = []

  for (let i = 0; i < conditions.length; i ++) {
    const condition = conditions[i]
    const expression = compileMatchConditionExpression(condition, parameters, options)
    
    if (expression) {
      expressions.push(expression)
    }
  }

  const operator = 'operator' in expression ? expression.operator : 'AND'

  if (operator === 'AND') {
    return joinExpressions(expressions, ' && ')
  } else if (operator === 'OR') {
    return joinExpressions(expressions, ' || ')
  } else if (operator === 'NOT') {
    throw new Error('NOT operator should have been replaced with "-" termPrefix')
  } else {
    throw new Error('Unknown operator')
  }
}

function joinExpressions(expressions: string[], operator) {
  if (expressions.length === 1) {
    return expressions[0]
  } else if (expressions.length > 1) {
    return '( ' + expressions.join(operator) + ' )'
  } else {
    return '/* NO EXPRESSIONS */'
  }
}

function compileMatchConditionExpression(expression: Condition, parameters: QueryParameters, options: MatchOptions) {
  if (isFieldMatchNode(expression)) {
    const compiled = compileFieldMatchExpression(expression, parameters, options)

    if (expression.termPrefix !== '-') {
      return compiled
    } else {
      return `!(${compiled})`
    }
  } else {
    return '( ' + compileMatchExpressions(expression, parameters, options) + ' )'
  }
}

function compileFieldMatchExpression(fieldMatch: FieldMatchNode, parameters: QueryParameters, options: MatchOptions) {
  if (typeof fieldMatch.term === 'undefined' && typeof fieldMatch.term_max === 'undefined' && typeof fieldMatch.term_min === 'undefined') {
    console.warn('NOTE: Unsupported condition', fieldMatch)
    return 
  }

  if (fieldMatch.field) {
    const singleFieldMatchExpression = compileSingleFieldMatchExpression(`${toSafeFieldAccess(fieldMatch.field)}`, fieldMatch, parameters, options)
    return singleFieldMatchExpression
  } else if (options.searchAllFields === true) {
    const allFieldsMatchExpression = compileAllDataFieldsMatchExpression(fieldMatch, parameters, options)
    return allFieldsMatchExpression
  } else {
    const singleFieldMatchExpression = compileSingleFieldMatchExpression('document.data.name', fieldMatch, parameters, options)
    return singleFieldMatchExpression
  }
} 

export function toSafeFieldAccess(fieldPath: string) {
  // if (fieldPath === 'id'
  //   || fieldPath === 'a'
  //   || fieldPath === 'f.id'
  //   || fieldPath === 'stamps.id'
  //   || fieldPath === 'stamps.type.id'
  //   || fieldPath === 'hidden'
  //   || fieldPath === 'cloneOf.id'
  //   || fieldPath === 'readonly'
  //   || fieldPath.startsWith('stampData')
  //   || fieldPath.startsWith('modified')
  //   || fieldPath.startsWith('created')
  //   ) {
  //   return fieldPath
  // }
  const indexOfFirstSubField = fieldPath.indexOf('.')

  if (indexOfFirstSubField < 0) {
    return `${DOCUMENT_ACCESS}${fieldPath}`
  }
  
  const firstField = fieldPath.substr(0, indexOfFirstSubField)
  
  if (firstField === 'data' || firstField === 'stamps') {
    const firstSubFieldEnd = fieldPath.indexOf('.', indexOfFirstSubField + 1)

    if (firstSubFieldEnd > 0) {
      const firstSubField = fieldPath.substring(indexOfFirstSubField + 1, firstSubFieldEnd)
      const subAccess = fieldPath.substr(firstSubFieldEnd + 1)
    
      const safe = subAccess.replace(/\./g, '?.')
      return `${DOCUMENT_ACCESS}${firstField}.${firstSubField}?.${safe}`
    } else {
      const subAccess = fieldPath.substr(firstField.length + 1)
      const safe = subAccess.replace(/\./g, '?.')
      return `${DOCUMENT_ACCESS}${firstField}.${safe}`
    }
  } 

  const safe = DOCUMENT_ACCESS + fieldPath.replace(/\./g, '?.')
  return safe
}

function compileAllDataFieldsMatchExpression(fieldMatch: FieldMatchNode, parameters: QueryParameters, options: MatchOptions) {
  return `
  (function(){
    for (const dataFieldName of Object.keys(document.data)) {
      const field = document.data[dataFieldName]
      const matches = (${compileSingleFieldMatchExpression('field', fieldMatch, parameters, options)})

      if (matches) {
        return true
      }
    }
    return false
  })()
  `
}

export function compileSingleFieldMatchExpression(fieldPath: string, fieldMatch: FieldMatchNode, parameters: QueryParameters, options: MatchOptions): string  {
  const fieldAccessor = buildIntermediaryArraySafeAccessorForField(fieldPath)
  
  // return '((1 === 1) && console.log(options))'
  if (fields.matchesAnyTruthyValue(fieldMatch, parameters)) {
    return `fieldMatchers.truthy(${fieldAccessor}, ${JSON.stringify(fieldMatch)}, parameters)`
  } else if (fields.isRangeTerm(fieldMatch, parameters)) {
    return `fieldMatchers.range(${fieldAccessor}, ${JSON.stringify(fieldMatch)}, parameters, { nowUtcTz: now.isoTz })`
  } else if (fields.comparesToBoolean(fieldMatch, parameters)) {
    return `fieldMatchers.boolean(${fieldAccessor}, ${JSON.stringify(fieldMatch)}, parameters)`
  } else if (fields.isToBeComparedLiterally(fieldMatch, parameters)) {
    return `fieldMatchers.term(${fieldAccessor}, ${JSON.stringify(fieldMatch)}, parameters)`
  } else if (fields.isWildcardMatch(fieldMatch, parameters)) {
    return `fieldMatchers.wildcard(${fieldAccessor}, ${JSON.stringify(fieldMatch)}, parameters)`
  } else {
    return `fieldMatchers.term(${fieldAccessor}, ${JSON.stringify(fieldMatch)}, parameters)`
  }
}

function fieldMatchesTerm(fieldValue: any, condition: FieldMatchNode, parameters: QueryParameters, options: MatchOptions): boolean {
  if (fieldValue == null) {
    return false
  }

  const term = parameters[condition.term.id]
  const termIsArray = Array.isArray(term)

  const singleTermMatcher = SINGLE_TERM_MATCHERS[typeof fieldValue]
  
  if (!singleTermMatcher) {
    return false
  }

  if (!termIsArray) {
    return singleTermMatcher(fieldValue, term, options)
  } else {
    for (const singleTerm of term) {
      if (singleTermMatcher(fieldValue, singleTerm, options)) {
        return true
      }
    }
  }

  return false
}

export function buildIntermediaryArraySafeAccessorForField(fieldPath: string): string {
  if (fieldPath === 'field') {
    return fieldPath
  }

  const subFieldBeginning = fieldPath.indexOf('.', DOCUMENT_ACCESS.length)
  
  if (subFieldBeginning >= 0) {
    const firstFieldAccessorPath = fieldPath.substring(DOCUMENT_ACCESS.length, subFieldBeginning)

    if (firstFieldAccessorPath === 'stamps') {
      const innerFieldAccessor = fieldPath.substring(subFieldBeginning + 1)

      const intermediaryArrayLookup = `${DOCUMENT_ACCESS}${firstFieldAccessorPath}?.map(fieldValue => fieldValue?.${innerFieldAccessor})`
      return intermediaryArrayLookup
    } else if (firstFieldAccessorPath === 'data') {
      const dataFieldNameStart = DOCUMENT_ACCESS.length + firstFieldAccessorPath.length + 1
      const dataFieldNameEnd = fieldPath.indexOf('.', dataFieldNameStart)
      const dataFieldName = fieldPath.substring(dataFieldNameStart, dataFieldNameEnd < 0 ? undefined : dataFieldNameEnd)

      if (dataFieldNameEnd < 0) {
        return fieldPath
      }

      const dataFieldNameWithoutQuestionMarkEnd = dataFieldName.substr(0, dataFieldName.length - 1)
      const remainderFieldAccessor = fieldPath.substring(dataFieldNameEnd)

      const intermediaryArrayLookup = `${DOCUMENT_ACCESS}${DATA_FIELD_PREFIX}${dataFieldNameWithoutQuestionMarkEnd}.map(fieldValue => fieldValue?${remainderFieldAccessor})`
      return `(Array.isArray(document.data.${dataFieldNameWithoutQuestionMarkEnd}) ? ${intermediaryArrayLookup} : ${fieldPath})`
    }
  }

  return fieldPath
}

const SINGLE_TERM_MATCHERS = {
  string: _stringFieldMatchesSingleTerm,
  number: _numberFieldMatchesSingleTerm,
  object: _objectFieldMatchesSingleTerm,
}

// function _booleanFieldMatchesSingleTerm(field: any, term: string|number|boolean, options: MatchOptions) {
//   const termAsBoolean = typeof term === 'boolean' ? term : term === 'true'
    
//   if (termAsBoolean) {
//     return termAsBoolean === field
//   } else {
//     return false
//   }
// }

function _numberFieldMatchesSingleTerm(field: any, term: string|number|boolean, options: MatchOptions) {
  const termAsNumber = +term
  const termIsNumber = !isNaN(termAsNumber)
  
  if (termIsNumber) {
    return termAsNumber === field
  } else {
    return false
  }
}

function _stringFieldMatchesSingleTerm(field: any, term: any, options: MatchOptions) {
  if (field === term) {
    return true
  }

  if (term == null) {
    if (field == null) {
      return true
    }

    return false
  }

  if (typeof term === 'number') {
    return field === term.toString()
  }
  
  if (typeof term === 'string') {
    if (isIsoDate(term)) {
      const charactersToCompare = term.length
      
      if (field.length === charactersToCompare) {
        return (field === term)
      }

      const comparisonBase = field.substr(0, charactersToCompare)
      return comparisonBase === term
    } 
  
    return field.toLowerCase() === term.toLowerCase()
  }

  return field === term.toString()
}

function _objectFieldMatchesSingleTerm(field: any, term: any, options: MatchOptions) {
  if ('id' in field) {
    if (term === field.id) {
      return true
    }
  } else if ('text' in field) {
    return _stringFieldMatchesSingleTerm(field.text, term, options)
  }
}

const IS_NON_LETTER_OR_NEWLINE = /^[\W]+$/

type FieldTermMatcher = (field: any, condition: FieldMatchNode, parameters: QueryParameters, options: MatchOptions) => boolean

function supportArrayMatches(matcher: FieldTermMatcher): FieldTermMatcher {
  return function match(field: any, condition: FieldMatchNode, parameters: QueryParameters, options: MatchOptions) {
    if (field == null) {
      return false
    }

    if (!Array.isArray(field)) {
      return matcher(field, condition, parameters, options)
    }

    for (const singleFieldValue of field) {
      if (Array.isArray(singleFieldValue)) {
        for (const item of singleFieldValue) {
          if (matcher(item, condition, parameters, options)) {
            return true
          }
        }
      } else {
        if (matcher(singleFieldValue, condition, parameters, options)) {
          return true
        }
      }
    }

    return false
  }
}

function fieldMatchesRange(field: any, condition: FieldMatchNode, parameters: QueryParameters, options: MatchOptions): boolean {
  const min = parameters[condition.term_min.id]
  const max = parameters[condition.term_max.id]

  if (min === '*' && max === '*') {
    return !!field
  }

  if (typeof field === 'number') {
    return numberFieldMatchesRange(field, condition, parameters, options)
  } else if (typeof field === 'string') {
    if (isIsoDate(field)) {
      return dateFieldMatchesRange(field, condition, parameters, options)
    } else {
      return stringFieldMatchesRange(field, condition, parameters, options)
    }
  }

  return false
}

function dateFieldMatchesRange(field: string, condition: FieldMatchNode, parameters: QueryParameters, options: MatchOptions): boolean {
  const fieldDate = DateTime.fromISO(field)

  const min = parameters[condition.term_min.id]
  const max = parameters[condition.term_max.id]

  if (min !== '*') {
    const minDate = parseDateRange(min, { now: options.nowUtcTz })
    const minMatch = condition.inclusive !== false ? fieldDate >= minDate : fieldDate > minDate
    
    if (!minMatch) {
      return false
    }
  }

  if (max !== '*') {
    const maxDate = parseDateRange(max, { now: options.nowUtcTz })
    const maxMatch = condition.inclusive !== false ? fieldDate <= maxDate : fieldDate < maxDate

    if (!maxMatch) {
      return false
    }
  }

  return true
}

function numberFieldMatchesRange(field: any, condition: FieldMatchNode, parameters: QueryParameters, options: MatchOptions): boolean {
  const min = parameters[condition.term_min.id]
  const max = parameters[condition.term_max.id]

  if (min !== '*') {
    const termMinAsNumber = +min
    const termMinIsNumber = !isNaN(termMinAsNumber)

    if (!termMinIsNumber) {
      return false
    }

    const minMatch = condition.inclusive !== false ? field >= termMinAsNumber : field > termMinAsNumber

    if (!minMatch) {
      return false
    }
  }
  
  if (max !== '*') {
    const termMaxAsNumber = +max
    const termMaxIsNumber = !isNaN(termMaxAsNumber)
    
    if (!termMaxIsNumber) {
      return false
    }

    const maxMatch = condition.inclusive !== false ? field <= termMaxAsNumber : field < termMaxAsNumber

    if (!maxMatch) {
      return false
    }
  }

  return true
}

function stringFieldMatchesRange(field: string, condition: FieldMatchNode, parameters: QueryParameters, options: MatchOptions) {
  const min = parameters[condition.term_min.id]
  const max = parameters[condition.term_max.id]

  if (min !== '*') {
    const minMatch = condition.inclusive !== false ? field >= min :  field > min

    if (!minMatch) {
      return false
    }
  }
  
  if (max !== '*') {
    const maxMatch = condition.inclusive !== false ? field <= max : field < max

    if (!maxMatch) {
      return false
    }
  }

  return true
}

function fieldMatchesTruthy(field: any, condition: FieldMatchNode, parameters: QueryParameters, options: MatchOptions): boolean {
  if (!field) {
    return false
  }

  if (typeof field === 'object') {
    if ('id' in field) {
      return !!field.id
    }
  }

  return (!!field)
}

function fieldMatchesBoolean(field: string, condition: FieldMatchNode, parameters: QueryParameters, options: MatchOptions): boolean {
  const term = parameters[condition.term.id]
  
  if (typeof field !== 'boolean') {
    return false
  }

  if (typeof term === 'boolean') {
    return field === term
  } 

  if (typeof term === 'string') {
    const lowerCaseTerm = term.toLowerCase()
    
    if (field && lowerCaseTerm === 'true') {
      return true
    } else if (!field && lowerCaseTerm === 'false') {
      return true
    }
  }
  
  return false
}

function fieldMatchesWildcard(field: string|object, condition: FieldMatchNode, parameters: QueryParameters, options: MatchOptions): boolean {
  const term = parameters[condition.term.id]
  
  // Wildcard match in Refs
  if (typeof field === 'object') {
    const o: any = field
    if ('text' in o && 'id' in o) {
      if (o.id && o.text) {
        return fieldMatchesWildcard(o.text, condition, parameters, options)
      }
    } 
    return false
  }

  if (typeof field !== 'string' || typeof term !== 'string') {
    return false
  }

  const trimmedTerm = term.trim()

  if (!trimmedTerm.endsWith('*')) {
    return false
  }

  const hasLeadingWildcard = (trimmedTerm[0] === '*')

  const termOffset = hasLeadingWildcard ? 1 : 0
  const termLengthWithoutWildcards = term.length - 1 - termOffset
  const termWithoutWildcard = trimmedTerm.substr(termOffset, termLengthWithoutWildcards)

  // const isCaseSensitiveMatch = isAllUppercase(termWithoutWildcard) && termWithoutWildcard.length >= 3
  const isCaseSensitiveMatch = false

  const fieldValueToMatch = isCaseSensitiveMatch ? field : field.toLowerCase()
  const termToMatch = isCaseSensitiveMatch ? termWithoutWildcard : termWithoutWildcard.toLowerCase()

  let foundAtIndex = -1
  
  for (;;) {
    foundAtIndex = fieldValueToMatch.indexOf(termToMatch, foundAtIndex + 1)
    
    if (foundAtIndex < 0) {
      return false
    } else if (foundAtIndex === 0) {
      return true
    }

    const characterBeforeMatch = fieldValueToMatch[foundAtIndex - 1]
  
    if (hasLeadingWildcard) {
      return true
    } else if (IS_NON_LETTER_OR_NEWLINE.test(characterBeforeMatch)) {
      return true
    } else {
      continue
    }
  }
}

//   const { queries } = process(queryStringPart, filterStringPart, { omitTest: true })

//   const a = []
//   const p = []
//   const f = []
//   const ids = []

//   // TODO: Actually we would have to identify sub graphs within each query and potentially join later
//   for (const query of queries) {
//     for (const condition of query) {
//       if (condition.field === 'p') {
//         p.push(condition.term)
//       } else if (condition.field === 'a') {
//         a.push(condition.term)
//       } else if (condition.field === 'id') {
//         ids.push(condition.term)
//       } else if (condition.field === 'f.id') {
//         f.push(condition.term)
//       }
//     }
//   }

//   return { a, p, ids, f, subQueryCount: queries.length  }
// }

export type SubGraphInfo = {
  a: string[]
  p: string[]
  f: string[]
  ids: string[]
}

export function isQueryOfPartialDocuments(_query, options) {
  if (options) {
    if (options.field || options.fields || options.omitField || options.omitFields) {
      return true
    }
  }

  return false
}


export function isTimeDependentQuery(query: Query): boolean {
  for (const fieldMatcher of enumerateFieldMatcherNodes(query)) {
    if (isTimeDependentFieldMatch(fieldMatcher, query.parameters)) {
      return true
    }
  }

  return false
}

function isTimeDependentFieldMatch(fieldMatch: FieldMatchNode, parameters: QueryParameters) {
  if (fieldMatch.term) {
    return false
  }
  
  if (fieldMatch.term_min) {
    const param = parameters[fieldMatch.term_min.id]
    if (isTimeDependentParameter(param)) {
      return true
    }
  }
  
  if (fieldMatch.term_max) {
    const param = parameters[fieldMatch.term_max.id]
    if (isTimeDependentParameter(param)) {
      return true
    }
  }

  return false
}

function isTimeDependentParameter(parameter: QueryParameterValue) {
  return parameter.includes('NOW')
}

export function* enumerateFieldMatcherNodes(query: Query): IterableIterator<FieldMatchNode> {
  let nodes: [FieldMatchNode|MatchExpression] = [query.graph]

  for (;;) {
    const currentNode = nodes.shift()

    if (!currentNode) {
      break
    }

    if (isFieldMatchNode(currentNode)) {
      yield currentNode
    } else {
      for (const condition of currentNode.conditions) {
        nodes.push(condition)
      }
    }
  }
}

export function identifySubGraphsInQuery(queryOrQueryString: string|Query): SubGraphInfo {
  const info = {
    a: new Set<string>(),
    p: new Set<string>(),
    f: new Set<string>(),
    ids: new Set<string>(),
  }

  const query = typeof queryOrQueryString === 'string' ? parse(queryOrQueryString) : queryOrQueryString
  
  let nodes: [FieldMatchNode|MatchExpression] = [query.graph]

  for (const currentNode of enumerateFieldMatcherNodes(query)) {
    if (currentNode.field === 'p') {
      registerFieldInfo(query, currentNode, 'p', info.p) 
    } else if (currentNode.field === 'a') {
      registerFieldInfo(query, currentNode, 'a', info.a) 
    } else if (currentNode.field === 'id') {
      registerFieldInfo(query, currentNode, 'id', info.ids) 
    } else if (currentNode.field === 'f.id') {
      registerFieldInfo(query, currentNode, 'f.id', info.f) 
    } 
  }

  return {
    a: Array.from(info.a.values()),
    p: Array.from(info.p.values()),
    f: Array.from(info.f.values()),
    ids: Array.from(info.ids.values()),
  }
}

function registerFieldInfo(query: Query, node: FieldMatchNode, fieldName: string, register: Set<string>) {
  const value = query.parameters[node.term.id]

  if (Array.isArray(value)) {
    for (const v of value) {
      register.add(v)
    }
  } else {
    register.add(value)
  }
}
