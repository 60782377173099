import { IScopes, Scopes } from '../../model/query/fluent'
import { Logger, buildLogger } from '../../model/log'

export type ResourceType = 'TIMER' | 'INTERVAL' | 'DOCUMENT_WATCHER' | 'DOCUMENT_WATCHER_REV' | 'QUERY_SUBSCRIPTION' | 'QUERY_WATCHER' | 'GENERIC_RESOURCE' | 'OBJECTURL'

export interface Resource {
  context: Context
  type: ResourceType
  id?: string
  value: any
  created: {
    at: Date
  }
}


export interface Options {
  Logger?: Logger,
  Query?: IScopes
}

export class Context {
  private _log: Logger

  private _data = {
    namedResources: new Map<string, Resource>(),
    unnamedResources: <Resource[]> [],
    rootElement: <HTMLElement> null,
    released: false,
    domElementListeners: new Map<string, { name: string, callback: any, options?: object }>(),
    query: null as IScopes
  }

  constructor(public name: string, options: Options = {}) {
    this._log = buildLogger(options.Logger, { class: 'Aeppic', context: name })
    this._data.query = options.Query ?? Scopes
    Object.freeze(this)
  }

  get Query(): IScopes {
    return this._data.query
  }

  get Log(): Logger {
    return this._log
  }

  get namedResources() {
    return this._data.namedResources
  }

  get unnamedResources() {
    return this._data.unnamedResources
  }

  get rootElement() {
    return this._data.rootElement
  }

  set rootElement(val) {
    if (this._data.rootElement) {
      console.error('Context already set to an element')
    }

    this._data.rootElement = val
  }

  get domElementListeners() {
    return this._data.domElementListeners
  }

  release() {
    this._data.rootElement = null
    
    for (const namedResource of this._data.namedResources.values()) {
      this._freeResource(namedResource)
    }
    this._data.namedResources.clear()

    for (const unnamedResource of this._data.unnamedResources) {
      this._freeResource(unnamedResource)
    }
    this._data.unnamedResources.length = 0 

    this._data.released = true
  }

  get isReleased() {
    return this._data.released
  }

  public addNamedResource(type: ResourceType, id: string, value) {
    const r: Resource = {
      context: this,
      type,
      id,
      value,
      created: { at: new Date() }
    }
    
    const name = type + ':' + id

    this._data.namedResources.set(name, r)
  }

  public getNamedResource(type: ResourceType, id: string) {
    const name = type + ':' + id
    
    const resource = this._data.namedResources.get(name)

    if (resource) {
      return resource.value
    }
  }

  public releaseNamedResource(resourceId) {
    if (!this._data.namedResources) {
      console.warn('resource already released')
      return
    }

    const resource = this._data.namedResources.get(resourceId)

    this._freeResource(resource)
    this._data.namedResources.delete(resourceId)
  }

  public addUnnamedResource(type: ResourceType, value) {
    const r: Resource = {
      context: this,
      type,
      value,
      created: { at: new Date() }
    }

    if (!this._data.unnamedResources) {
      console.warn('cannot add resource freeing right away')
      this._freeResource(r)
      return
    }

    this._data.unnamedResources.push(r)
  }

  public releaseUnnamedResource(type: ResourceType, resource) {
    const list = this._data.unnamedResources

    if (!list) {
      console.warn('resource already released')
      return
    }

    for (let i = list.length - 1; i >= 0; i -= 1) {
      const resource = list[i]

      if (resource.type === type && resource.value === resource) {
        this._freeResource(resource)
        list.splice(i, 1)
        return
      }
    }
  }

  private _freeResource(resource: Resource) {
    switch (resource.type) {
      case 'TIMER':
        clearTimeout(resource.value)
        break
      case 'INTERVAL': 
        clearInterval(resource.value)
        break
      case 'DOCUMENT_WATCHER':
      case 'DOCUMENT_WATCHER_REV':
      case 'QUERY_WATCHER':
        resource.value.stop()
        break
      case 'QUERY_SUBSCRIPTION':
        resource.value.cancel()
        break
      case 'GENERIC_RESOURCE':
        resource.value.release()
        break
      case 'OBJECTURL':
        if (resource.value) {
          URL.revokeObjectURL(resource.value)
          // console.log('freed objecturl')
        }
        break
      default:
        throw new Error(`Unhandled resource type ${resource.type}`)
    }
    resource.value = null
  }
}

export class ContextifiedAeppicHandler<T extends Object> implements ProxyHandler<T> {
  private context: Context

  constructor(name: string, options: Options = {}) {
    this.context = new Context(name, options)
  }

  get(target: any, propertyKey: string, receiver: any) {
    if (propertyKey === 'context' || propertyKey === '_context') {
      return this.context
    }
    return Reflect.get(target, propertyKey, receiver)
  }
}

export function contextify<T extends Object>(instance: T, name: string, options: Options = {}) {
  if (name === '') {
    throw new Error('Context MUST have a valid name')
  }

  const handler = new ContextifiedAeppicHandler<T>(name, options)
  return new Proxy(instance, handler)
}
