import type * as Luxon from 'luxon'
import type { DateTime, Duration } from 'luxon'

export function formatToHumanReadableWithLuxon(luxon: Luxon, dateTimeInIsoFormat: string, { method = 'default', locale, intlOptions }: { method?: 'default'|'past', locale?: string, intlOptions?: object } = {}): string {
  const { DateTime } = luxon
  const datetime = DateTime.fromISO(dateTimeInIsoFormat)

  const now = DateTime.now()
  const diffNow = datetime.diffNow()

  const differentYear = datetime.year != now.year
  const differentMonth = datetime.month != now.month
  const differentDay = datetime.day != now.day

  const dateTimeFormatOptions = {
    ...intlOptions,
    locale,
  }

  switch (method) {
    case 'default':
    case 'past':
      return renderPastDateTime(datetime, { now, diffNow, differentYear, differentMonth, differentDay, luxon, dateTimeFormatOptions })
      break
    default:
      throw new Error(`Unknown method: ${method}`)
  }
}

function renderPastDateTime(dateTime: DateTime, { now, diffNow, differentDay, differentMonth, differentYear, luxon, dateTimeFormatOptions }: { now: DateTime, diffNow: Duration, differentYear: boolean, differentMonth: boolean, differentDay: boolean, luxon: Luxon, dateTimeFormatOptions?: object }): string {
  const { DateTime } = luxon
  const age = diffNow.negate()

  const options = dateTimeFormatOptions

  if (age.as('days') < 1) {
    if (differentDay) {
      return dateTime.toRelativeCalendar(options)
    } else {
      return dateTime.toLocaleString(DateTime.TIME_SIMPLE, options)
    }
  } else if (age.as('days') < 2) {
    return dateTime.toRelativeCalendar(options) + ' ' + dateTime.toLocaleString(DateTime.TIME_SIMPLE, options)
  } else if (age.as('days') < 4) {
    return dateTime.toRelativeCalendar(options)
  } else if (age.as('days') < 21) {
    if (differentMonth) {
      return dateTime.toLocaleString({month: 'short', day: 'numeric', weekday: 'long' }, options)
    } else {
      return dateTime.toLocaleString({day: 'numeric', weekday: 'long', hour: '2-digit', minute: '2-digit' }, options)
    }
  } else {
    if (differentYear){
      return dateTime.toLocaleString(DateTime.DATE_FULL, options)
    } else {
      return dateTime.toLocaleString({weekday: 'long', day: 'numeric', month:'short'}, options)
    }
  } 
}