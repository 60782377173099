import { parse } from 'yaml'
import { marked } from 'marked'

import isObject from 'lodash-es/isObject.js'
import isString from 'lodash-es/isString.js'
// import isArray from 'lodash-es/isArray'

export default function completeTypeParsing(formInfo) {
  completeTypeDefinitions(formInfo)
  buildDefaultObject(formInfo)
  buildSchema(formInfo)
}

const DEFAULT_MARKDOWN_OPTIONS = {
  gfm: true,
  silent: true,
}

function parseMarkdown(markdown) {
  return marked.parse(markdown, DEFAULT_MARKDOWN_OPTIONS)
}

function completeTypeDefinitions(formInfo) {
  const { fields } = formInfo
  for (const fieldName in fields) {
    if (Object.prototype.hasOwnProperty.call(fields, fieldName)) {
      const field = formInfo.fields[fieldName]
      completeTypeDefinitionForField(field)
    }
  }
}

/* eslint-disable no-param-reassign */
function completeTypeDefinitionForField(field) {
  const { typeDefinition } = field
  delete field.typeDefinition

  //
  // check quick syntaxes
  //
  if (typeDefinition.quickSyntaxText) {
    const { quickSyntaxText } = typeDefinition

    if (isDirectReference(quickSyntaxText)) {
      typeDefinition.typeReference = 'ref'
      const formId = extractFormId(quickSyntaxText)

      field.filter = []

      if (formId !== 'ref') {
        field.formRef = formId

        const nameOfFilterField = 'f.id'

        const quotedFormName = ensureQuotes(formId)
        const filter = {}
        filter[nameOfFilterField] = quotedFormName
        field.filter.push(filter)
      }
    } else {
      // default
      typeDefinition.typeReference = 'text'
    }
  }

  //
  // process type-definition
  //
  const scalarType = getScalarType(typeDefinition.typeReference)

  if (typeDefinition.cardinality) {
    field.cardinality = typeDefinition.cardinality
  }

  // This is the static analysis required. If the required
  // tag is standalong it is true. If it is linked to any conditional
  // formula it is set to false (these checks are performed against the
  // data by the form during validation). this value is helpful
  // for basic compliance via a schema.
  field.required = typeDefinition.required
  field.defaultValue = getDefault(typeDefinition.typeReference, scalarType)
  field.type = scalarType
  field.subType = getSubType(typeDefinition.typeReference)

  //
  // process default settings
  //
  if (typeDefinition.settingsText && typeDefinition.settingsText.$default?.length > 0) {
    if (field.subType === 'ref') {
      // no YAML for refs...
      if (!field.filter) {
        field.filter = []
      }

      processRefSettings(field, typeDefinition.settingsText.$default)
    } else {
      let settings
      // YAML for all the others...
      try {
        settings = parse(typeDefinition.settingsText.$default)
      } catch (e) {
        // cosonle.log(e)
        // swallow for now.
      }

      if (field.subType === 'select') {
        processSelectSettings(field, settings)
      } else if (field.subType === 'ref') {
        if (!field.filter) {
          field.filter = []
        }

        processRefSettings(field, settings)
      } else {
        field.settings = settings
      }
    }
  }

  //
  // limitation:
  // YAML settings must define a top-level object (not array or string)
  // otherwise will be overwritten here!
  //
  if (!isObject(field.settings)) {
    field.settings = {}
  }

  //
  // process labeled settings
  //

  if (typeDefinition.help) {
    field.settings.help = {
      text: typeDefinition.help,
      html: parseMarkdown(typeDefinition.help),
    }
  } else {
    field.settings.help = {
      text: '',
      html: '',
    }
  }


  for (const label in typeDefinition.settingsText) {
    if (label === '$default') {
      continue
    } else if (label === 'help') {
      // This is the extended help. The short help is directly defined inline
      // with Quotes at the end of the field definition
      field.settings.helpExtended = {
        text: typeDefinition.settingsText[label],
        html: parseMarkdown(typeDefinition.settingsText[label]),
      }
    } else if (label === 'required') {
      field.settings.required = {
        text: typeDefinition.settingsText[label],
        html: parseMarkdown(typeDefinition.settingsText[label]),
      }
    } else if (field.subType === 'select' && label.startsWith('help(') && label.endsWith(')') ) {
      const valueHelpIsProvidedFor = label.substring(5, label.length - 1)
      const option = field.settings.options.find(option => option.value === valueHelpIsProvidedFor)
      if (option) {
        option.helpExtended = {
          text: typeDefinition.settingsText[label],
          html: parseMarkdown(typeDefinition.settingsText[label]),
        }
      }
    } else if (field.subType === 'ref') {
      const markdown = typeDefinition.settingsText[label]
      const tokens = marked.lexer(markdown)
      field.settings[label] = tokens
    } else {
      // for now no other special handler. leave them raw...
      field.settings[label] = typeDefinition.settingsText[label]
    }
  }

  //
  // join filters
  //
  if (field.filter && Array.isArray(field.filter)) {
    field.joinedFilter = buildJoinedFieldFilter(field.filter)
  }
}
/* eslint-enable */

function buildJoinedFieldFilter(filter) {
  // filters = (makeQueryExpression(f) for f in filter )
  // joined = filters.join(' AND ')
  // return joined

  return filter.map(makeQueryExpression).join(' AND ')
}

function makeQueryExpression(fieldFilter) {
  // for name,value of fieldFilter
  //   return '#{name}:#{value}'

  const parts = []

  for (const key of Object.keys(fieldFilter)) {
    parts.push(`${key}:${fieldFilter[key]}`)
  }

  return parts.join(' AND ')
}

function isDirectReference(typeReference) {
  return typeReference && (typeReference.toLowerCase().indexOf('ref /') === 0 || typeReference[0] === '/')
}

function extractFormId(directReference) {
  const lowerCaseDirectReference = directReference.toLowerCase()

  if (lowerCaseDirectReference.indexOf('ref /') === 0) {
    return lowerCaseDirectReference.substr(5)
  }

  if (lowerCaseDirectReference.indexOf('/') === 0) {
    return lowerCaseDirectReference.substr(1)
  }

  return ''
}

// const isVersionedForm = function(typeReference) {
//   return typeReference.indexOf('@')>1
// }

function ensureQuotes(passedName) {
  let name = passedName
  const firstCharacter = name[0]

  if (firstCharacter !== '\'' && firstCharacter !== '\'') {
    name = `'${name}`
  }

  const lastCharacter = name[name.length - 1]

  if (lastCharacter !== '\'' && lastCharacter !== '\'') {
    name += '\''
  }

  return name
}

function getDefault(lowerCaseTypeReference, scalarType) {
  let defaultObject

  switch (lowerCaseTypeReference) {
    case 'ref':
      return {
        text: '',
        id: '',
        v: '',
      }
    case 'image':
    case 'file':
      defaultObject = {
        name: '',
        size: 0,
        type: null,
        sha1: null,
        dataUrl: null,
        thumbnailUrl: null,
        iconUrl: null,
        fileInfo: {
          created: null,
          read: null,
          modified: null,
          imported: null
        }
      }

      if (lowerCaseTypeReference === 'image') {
        defaultObject.mediaInfo = {
          width: null,
          height: null
        }
      }

      return defaultObject
    case 'geolocation':
      return {
        lat: 0.0,
        lon: 0.0
      }
    case 'address':
      return {
        street: '',
        streetNumber: '',
        postalCode: '',
        city: '',
        state: '',
        country: '',
        supplement: ''
      }
    case 'currency':
      return {
        amount: 0,
        currency: '',
        precision: 0
      }
    case 'duration': {
      return {}
    }
    default:
  }

  switch (scalarType) {
    case 'boolean':
      return false
    case 'string':
      return ''
    case 'object':
      return {}
    case 'number':
      return 0
    default:
      return null
  }
}

function getScalarType(lowerCaseTypeReference) {
  switch (lowerCaseTypeReference) {
    case 'switch':
      return 'boolean'
    case 'form':
      return 'string'
    case 'text':
      return 'string'
    case 'number':
      return 'number'
    case 'date':
      return 'string'
    case 'ref':
      return 'object'
    case 'currency':
      return 'object'
    case 'geolocation':
      return 'object'
    case 'address':
      return 'object'
    case 'file':
      return 'object'
    case 'image':
      return 'object'
    case 'duration':
      return 'object'
    case 'select':
      return 'string'
    case 'password':
      return 'string'
    default:
      return 'string'
  }
}

function getSubType(typeReference) {
  if (!typeReference) {
    return 'text'
  }

  return typeReference.toLowerCase()
}

function processSelectSettings(field, settings) {
  let options

  if (Array.isArray(settings)) {
    options = settings
  } else if (settings && Array.isArray(settings.options)) {
    options = settings.options
  }

  if (options != null) {
    processSelectSettingsOptionsArray(field, options)
  } else {
    options = []
  }
}

/* eslint-disable no-param-reassign */
function processSelectSettingsOptionsArray(field, settings) {
  let allValuesAreNumbers = true
  let hasEmptyOption = false

  field.settings = {
    options: []
  }

  for (let i = 0; i < settings.length; i += 1) {
    const setting = settings[i]

    let value
    let display

    if (isString(setting)) {
      display = setting
      value = display
    } else if (isObject(setting) && Object.keys(setting).length === 1) {
      [value] = Object.keys(setting)
      display = setting[value] || ''
    } else {
      // setting is null
      hasEmptyOption = true
      continue
    }

    const { cleanedValue, tags } = extractTagsAndCleanValue(value)

    if (tags) {
      value = cleanedValue
    }

    const option = {
      value,
      display,
      tags,
    }

    if (tags?.help && typeof tags.help === 'string') {
      option.help = {
        text: tags.help,
        html: parseMarkdown(tags.help),
      }
    }

    field.settings.options.push(option)

    if (!/^\d+$/.test(value)) {
      allValuesAreNumbers = false
    }
  }

  field.defaultValue = null

  if (allValuesAreNumbers) {
    field.type = 'number'

    for (let i = 0; i < field.settings.options.length; i += 1) {
      field.settings.options[i].value = parseInt(field.settings.options[i].value, 10)
    }
  } else if (hasEmptyOption) {
    field.settings.options.unshift({
      value: '',
      display: ''
    })
  }

  if (!field.cardinality) {
    if (field.settings.options.length) {
      field.defaultValue = field.settings.options[0].value
    }
  }
}

const TAGS_FROM_VALUE = /^\w.*\s+<(([^">]|"[^"\\]*(?:\\.[^"\\]*)*")*)>$/
const PARSE_SELECT_VALUE_TAGS = /\s*([a-zA-Z_$][a-zA-Z0-9_$-]*)(?:=("(?:[^"\\]|\\.)*"|\S+))?/g

function extractTagsAndCleanValue(value) {
  const match = TAGS_FROM_VALUE.exec(value)
  
  let tagDefinitions = null;
  let cleanedValue = value;

  if (match) {
    tagDefinitions = match[1]; // Extract the tag from the matched group

    // Remove the tag from the value (use the index of the first <)
    const tagStartIndex = match.index + match[0].indexOf('<');
    cleanedValue = value.substring(0, tagStartIndex).trim();
  }

  if (!tagDefinitions) {
    return { cleanedValue: null, tags: null }
  }

  const tags = {}
  
  // Parse the tag definitions
  let tagMatch;

  while ((tagMatch = PARSE_SELECT_VALUE_TAGS.exec(tagDefinitions)) !== null) {
    if (tagMatch[3]) {
      const name = tagMatch[2]

      if (tagMatch[3].startsWith('"')) {
        const value = tagMatch[3].substring(1, tagMatch[3].length - 1)

        // It might contain escaped " characters, so unescape them
        tags[name] = value.replace(/\\"/g, '"')
      } else {
        tags[name] = tagMatch[3]
      }
    } else if (tagMatch[2]) {
      const name = tagMatch[1]
      
      if (tagMatch[2].startsWith('"')) {
        const value = tagMatch[2].substring(1, tagMatch[2].length - 1)

        // It might contain escaped " characters, so unescape them
        tags[name] = value.replace(/\\"/g, '"')
      } else {
        tags[name] = tagMatch[2]
      }
    } else {
      tags[tagMatch[1]] = true
    }
  }

  return { cleanedValue, tags };
}

function processRefSettings(field, settingsText) {
  const filterLines = settingsText.split('\n')

  for (let i = 0; i < filterLines.length; i += 1) {
    const filterElements = filterLines[i].trim().split(':')

    const filter = {}
    filter[filterElements[0].trim()] = filterElements[1].trim()
    field.filter.push(filter)
  }
}

function buildDefaultObject(formInfo) {
  const { fields } = formInfo
  const defaultObject = {}

  Object.keys(fields).forEach((name) => {
    const field = fields[name]

    if (field.cardinality) {
      const array = []
      defaultObject[name] = array
    } else {
      const { defaultValue } = field
      defaultObject[name] = defaultValue
    }
  })

  formInfo.default = defaultObject
}

function buildSchema(formInfo) {
  const { fields } = formInfo
  const schema = {
    $schema: 'http://json-schema.org/draft-04/schema#',
    type: 'object',
    properties: {},
    required: [],
    additionalProperties: false
  }

  Object.keys(fields).forEach((name) => {
    const field = fields[name]
    schema.properties[field.name] = getSchemaForField(field)
    
    // Fields are no longer required by default. They can be defined as undefined
    // schema.required.push(field.name)
  })

  formInfo.schema = schema
}

function getSchemaForField(field) {
  // eslint-disable-next-line
  // const PATTERN_ISO_DATETIME_OR_EMPTY_STRING = '^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+)$|^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d)$|^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d)$|^$'

  const fieldSchema = {
    type: [field.type, 'null'],
    required: [],
    additionalProperties: false
  }


  if (field.subType === 'ref') {
    fieldSchema.properties = {}
    fieldSchema.properties.text = {
      type: ['string', 'null']
    }
    fieldSchema.properties.id = {
      type: ['string', 'null']
    }
    fieldSchema.properties.v = {
      type: ['string', 'null'],
      minimum: 0
    }
    fieldSchema.required.push('text')
    fieldSchema.required.push('id')
    fieldSchema.required.push('v')
  } else if (field.subType === 'file' || field.subType === 'image') {
    fieldSchema.properties = {}
    fieldSchema.properties.size = {
      type: ['integer', 'null'],
      minimum: 0
    }
    fieldSchema.properties.type = {
      type: ['string', 'null']
    }
    fieldSchema.properties.sha1 = {
      type: ['string', 'null']
    }
    fieldSchema.properties.dataUrl = {
      type: ['string', 'null'],
      format: 'uri'
    }
    fieldSchema.properties.thumbnailUrl = {
      type: ['string', 'null'],
      format: 'uri'
    }
    fieldSchema.properties.iconUrl = {
      type: ['string', 'null'],
      format: 'uri'
    }
    fieldSchema.properties.fileInfo = {
      type: 'object'
    }
    fieldSchema.required.push('size')
    fieldSchema.required.push('type')
    fieldSchema.required.push('sha1')
    fieldSchema.required.push('dataUrl')
    fieldSchema.required.push('thumbnailUrl')
    fieldSchema.required.push('iconUrl')
    fieldSchema.required.push('fileInfo')

    fieldSchema.properties.fileInfo.properties = {}
    fieldSchema.properties.fileInfo.properties.created = {
      type: ['number', 'null']
    }
    fieldSchema.properties.fileInfo.properties.modified = {
      type: ['number', 'null']
    }
    fieldSchema.properties.fileInfo.properties.imported = {
      type: ['number', 'null']
    }
    fieldSchema.properties.fileInfo.properties.read = {
      type: ['number', 'null']
    }
    fieldSchema.properties.fileInfo.required = ['created', 'modified', 'imported', 'read']
    fieldSchema.properties.fileInfo.additionalProperties = false
  } else if (field.subType === 'currency') {
    fieldSchema.properties = {}
    fieldSchema.properties.amount = {
      type: 'number',
    }
    fieldSchema.properties.precision = {
      type: 'number',
      minimum: '0',
    }
    fieldSchema.properties.currency = {
      type: 'string'
    }
    fieldSchema.required.push('amount')
    fieldSchema.required.push('precision')
    fieldSchema.required.push('currency')
  } else if (field.subType === 'geolocation') {
    fieldSchema.properties = {}
    fieldSchema.properties.lat = {
      type: ['number', 'null'],
      minimum: '-90',
      maximum: '90'
    }
    fieldSchema.properties.lon = {
      type: ['number', 'null'],
      minimum: '-180',
      maximum: '180'
    }
    fieldSchema.required.push('lat')
    fieldSchema.required.push('lon')
  } else if (field.subType === 'address') {
    fieldSchema.properties = {}
    fieldSchema.properties.street = {
      type: 'string'
    }
    fieldSchema.properties.streetNumber = {
      type: 'string'
    }
    fieldSchema.properties.postalCode = {
      type: 'string'
    }
    fieldSchema.properties.city = {
      type: 'string'
    }
    fieldSchema.properties.state = {
      type: 'string'
    }
    fieldSchema.properties.country = {
      type: 'string'
    }
    fieldSchema.properties.supplement = {
      type: 'string'
    }
    fieldSchema.required.push('street')
    fieldSchema.required.push('streetNumber')
    fieldSchema.required.push('postalCode')
    fieldSchema.required.push('city')
    fieldSchema.required.push('state')
    fieldSchema.required.push('country')
    fieldSchema.required.push('supplement')
  } else if (field.subType === 'select') {
    if (field.settings && field.settings.options) {
      if (fieldSchema.type === 'number') {
        fieldSchema.enum = [null]
      } else {
        fieldSchema.enum = [null, '']
      }

      for (const option of field.settings.options) {
        if (option.value == '') {
          continue
        }
        fieldSchema.enum.push(option.value)
      }
    }
  } else if (field.subType === 'date') {
    fieldSchema.format = 'date-time'
  }

  if (field.cardinality) {
    const emptyArraySchema = {
      type: 'array',
      maxItems: 0
    }

    const filledArraySchema = {
      type: 'array',
      items: fieldSchema,
      minItems: parseInt(field.cardinality.min, 10) || 0
    }

    const arraySchema = {
      anyOf: [emptyArraySchema, filledArraySchema]
    }

    if (field.cardinality.max && field.cardinality.max !== 'n') {
      filledArraySchema.maxItems = parseInt(field.cardinality.max, 10)
    }

    return arraySchema
  }

  return fieldSchema
}
