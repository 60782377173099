
  //
  // How it works ...
  //
  // * when an ae-expandable element is expanded, it becomes the activeExpandedElement
  // * all other ae-expandable elements which belong to the same group are gathered, 
  //   scoped by an ancestor element marked with the class ae-expandable-group-scope.
  // * the total amount of these ae-expandable elements is refered to as siblingExpandedElements.
  // * all siblingExpandedElements are elevated to the next ancestor element marked with the
  //   class ae-expand-region or ae-root 
  // * the activeExpandedElement starts out as the selectedExpandedElement. The user can change 
  //   this via a tab UI (toggle through the siblingExpandedElements)
  // * depending on whether there are more ancestor ae-expand-regions the user can expand further
  //   or collapse.
  //
import { loadStyle } from '../utils/css'
import { findAncestorWithClass } from '../utils/find-ancestor'

export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ae-expandable",attrs:{"data-ae-expandable-label":_vm.label,"data-ae-expandable-group":_vm.group || false}},[(!_vm.isExpanded)?_c('div',{staticClass:"ae-expandable__header ae-expandable__header--expandable"},[_vm._t("header",function(){return [_c('span',{staticClass:"ae-expandable__button",on:{"click":_vm.expand}},[_c('ae-icon',{staticClass:"fas fa-expand-alt"})],1)]})],2):_vm._e(),_vm._v(" "),(_vm.isExpanded)?_c('div',{staticClass:"ae-expandable__header ae-expandable__header--collapsible"},[_vm._t("expanded-header",function(){return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpanded),expression:"isExpanded"}],staticClass:"ae-expandable__labels"},[_vm._l((_vm.labels),function(label,idx){return [(idx === _vm.selectedIdx)?_c('div',{staticClass:"ae-expandable__label ae-expandable__label--selected",on:{"click":function (){ return _vm.select(idx); }}},[_vm._v(_vm._s(label)+" ")]):_c('div',{staticClass:"ae-expandable__label",on:{"click":function (){ return _vm.select(idx); }}},[_vm._v(_vm._s(label)+" ")])]})],2),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.document && _vm.document.hasRevisions),expression:"document && document.hasRevisions"}],staticClass:"ae-expandable__button",staticStyle:{"margin-right":"10px"},on:{"click":_vm.save}},[_c('ae-icon',{staticClass:"fas fa-save"})],1),_vm._v(" "),_c('div',{staticClass:"ae-expandable__button",on:{"click":_vm.collapse}},[_c('ae-icon',{staticClass:"fas fa-compress-alt"})],1),_vm._v(" "),(_vm.canExpandFurther)?_c('div',{staticClass:"ae-expandable__button",on:{"click":_vm.expand}},[_c('ae-icon',{staticClass:"fas fa-expand-alt"})],1):_vm._e()]},{"canExpandFurther":_vm.canExpandFurther})],2):_vm._e(),_vm._v(" "),_vm._t("default",function(){return [_vm._v("Please put some content in here to expand")]})],2)},
  staticRenderFns: [],
  /* tslint:enable */
  
  inject: ['getAeppicContext'],
  props: {
    group: String,
    label: {
      type: String,
      default: '[untitled]'
    },
    document: {
      type: Object,
      default: null
    } 
  },
  data() {
    return {
      Aeppic: this.getAeppicContext('ae-expandable'),
      activeExpandedElement: null, 
      selectedExpandedElement: null,
      siblingExpandedElements: [],
      previousParents: [],
      isExpanded: false,
      canExpandFurther: false,
      labels: [],
      selectedIdx: -1
    }
  },
  mounted() {
    loadStyle(STYLE)
  },
  beforeDestroy() {
    this.collapseAll()
  },
  methods: {
    toggle() {
      if (this.isExpanded) {
        this.collapse()
      } else {
        this.expand()
      }
    },

    expand() {
      if (!this.activeExpandedElement) {
        this.activeExpandedElement = this.$el // this.$slots.default[0]

        if (this.activeExpandedElement.elm) {
          this.activeExpandedElement = this.activeExpandedElement.elm
        }

        if (!this.activeExpandedElement) {
          return
        }

        this.selectedExpandedElement = this.activeExpandedElement
        this.activeExpandedElement.classList.add('ae-expandable--active')
        this.selectedExpandedElement.classList.add('ae-expandable--selected')
      }
      
      const previousExpandRegion = this.isExpanded ? this.activeExpandedElement.parentNode : null
      const nextExpandedRegion = this.findExpandedRegionContainer(this.activeExpandedElement.parentNode)

    
      if (nextExpandedRegion) {
        if (this.group && !this.isExpanded) {
          // gather siblings to expand only whilst the element is still at its original DOM position
          const siblings = this.findExpandableSiblings(this.activeExpandedElement, this.group)
          this.siblingExpandedElements.splice(0, this.siblingExpandedElements.length, ...siblings)
          this.labels.splice(0, this.labels.length, ...Array.from(siblings).map((el: any) => el.getAttribute('data-ae-expandable-label')))
          this.selectedIdx = this.elementIndexOf(this.activeExpandedElement)
        }
        if (this.siblingExpandedElements.length === 0) {
          this.siblingExpandedElements.push(this.activeExpandedElement)
        }

        let previousParentInfo = []
        this.previousParents.push(previousParentInfo)

        for (let i = 0; i < this.siblingExpandedElements.length; i++) {
          const el = this.siblingExpandedElements[i]
          previousParentInfo[i] = el.parentNode
          nextExpandedRegion.appendChild(el)
          el.classList.add('ae-expandable--expanded')
        }

        if (previousExpandRegion) {
          previousExpandRegion.classList.remove('ae-expand-region--active')
        }

        nextExpandedRegion.classList.add('ae-expand-region--active')

        this.isExpanded = true
        this.canExpandFurther = (this.findExpandedRegionContainer(nextExpandedRegion) != null)
      }
    },

    collapse() {
      if (this.isExpanded) {
        const currentParent = this.activeExpandedElement.parentNode
        currentParent.classList.remove('ae-expand-region--active')

        const previousParentInfo = this.previousParents.pop()
        this.isExpanded = this.previousParents.length > 0
        this.canExpandFurther = true

        for (let i = 0; i < this.siblingExpandedElements.length; i++) {
          const el = this.siblingExpandedElements[i]
          previousParentInfo[i].appendChild(el)
        }

        if (this.isExpanded) {
          previousParentInfo[0].classList.add('ae-expand-region--active')
        }
        else {
          for (let i = 0; i < this.siblingExpandedElements.length; i++) {
            const el = this.siblingExpandedElements[i]
            previousParentInfo[i].classList.remove('ae-expand-region--active')
            el.classList.remove('ae-expandable--expanded', 'ae-expandable--active', 'ae-expandable--selected')
          }

          this.activeExpandedElement = null
          this.selectedExpandedElement = null
          this.previousParents.length = 0
        }
      }      
    },

    collapseAll() {
      while (this.isExpanded) {
        this.collapse()
      }
    },

    save() {
      if (this.document) {
        this.Aeppic.save(this.document)
      }
    },

    async select(idx) {
      // const parent = this.activeExpandedElement.parentNode
      // const nodes = parent.querySelectorAll('.ae-expandable--expanded > *:nth-child(2)')
      
      // const oldSelectedEl = nodes[this.selectedIdx]
      // const newSelectedEl = nodes[idx]

      // if (newSelectedEl) {
      //   newSelectedEl.style.display = 'block';
      // }
      // if (oldSelectedEl) {
      //   oldSelectedEl.style.display = 'none';
      // }

      // this.selectedIdx = idx;

      this.selectedIdx = idx
      this.siblingExpandedElements[this.selectedIdx].classList.add('ae-expandable--selected')

      for (let i = 0; i < this.siblingExpandedElements.length; i++) {
        if (i !== this.selectedIdx) {
          const el = this.siblingExpandedElements[i]
          el.classList.remove('ae-expandable--selected')
        }
      }
    },

    isSelected(idx) {
      return this.siblingExpandedElements && this.siblingExpandedElements[idx] === this.selectedExpandedElement
    },

    elementIndexOf(element) {
      for (let i = 0; i < this.siblingExpandedElements.length; i++) {
        if (element === this.siblingExpandedElements[i]) {
          return i
        }
      }
      return -1
    },

    findExpandedRegionContainer(elementToExpand) {
      return findAncestorWithClass(elementToExpand, 'ae-expand-region')
    },

    findExpandableSiblings(elementToExpand, group) {
      const expandableGroupScope = findAncestorWithClass(elementToExpand, 'ae-expandable-group-scope')

      if (!expandableGroupScope) {
        return []
      }

      return expandableGroupScope.querySelectorAll(`.ae-expandable[data-ae-expandable-group="${group}"]`)
    }
  }
}

const STYLE = `


.ae-expand-region {
  position: relative;
}

.ae-root.ae-expand-region--active {
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
}



.ae-expandable--expanded {
  position:absolute;
  height: initial;  /* overrides global .ae-root>* rule when maxed out */
  top: 24px;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  flex-flow: column;
  overflow: hidden;

  /* temp, reduce visual flicker */
  background: #1e1e1e;
}

.ae-expandable--expanded > *:nth-child(2){
  display: none;
  flex: 1 1 auto;
}


.ae-expandable--expanded .ae-expandable__header {
  display: none;
}


.ae-expandable--expanded.ae-expandable--active {
  top: 0!important;
}

.ae-expandable--expanded.ae-expandable--active .ae-expandable__header {
  display: flex;
}


.ae-expandable--expanded.ae-expandable--selected {
  top: 24px;
  bottom: 0;
}

.ae-expandable--expanded.ae-expandable--selected > *:nth-child(2){
  display: block;
}





.ae-expandable__header {
  background: var(--ae-background-d1, lightgray);
  color: var(--ae-foreground-l2);
  height: 24px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  justify-content: flex-end;
  align-items: center;
}

.ae-expandable__header .ae-expandable__labels {
  display: inline-block;
  flex: 1;
}

.ae-expandable__label {
  cursor: pointer;
  display: inline-block;
  padding: 0 5px;
  text-align: center;
  font-weight:bold;
  color: var(--ae-foreground-l3);
}

.ae-expandable__label:hover {
  background: var(--ae-background-d3);
}

.ae-expandable__label--selected {
  color: var(--ae-foreground-l1);
  background: var(--ae-background-d3);
  border-top: solid 2px var(--ae-primary);
}

.ae-expandable__header .ae-expandable__button {
  background: transparent;
  border: none;
  padding: 4px;
  display: flex;
}
`

