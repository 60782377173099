import * as Types from '@aeppic/types'

export type ItemTheme = {
  classes: string[]
  layoutId: string
}

export type Theme = {
  form: ItemTheme
  sections: {
    levels: {
      0: ItemTheme
      1: ItemTheme
      2: ItemTheme
      3: ItemTheme
      4: ItemTheme
      5: ItemTheme
      6: ItemTheme
    }
  },
  field: ItemTheme
}

export const EMPTY_ITEM_THEME: ItemTheme = { classes: [], layoutId: null }

export function themeDocumentToTheme(document: Types.Document): Theme {
  const theme: Theme = {
    form: parseFromDocument(document, 'formLayout', 'formClasses'),
    sections: {
      levels: {
        0: parseFromDocument(document, 'rootSectionLayout', 'rootSectionCssClasses'),
        1: parseFromDocument(document, 'level1SectionLayout', 'level1CssClasses'),
        2: parseFromDocument(document, 'level2SectionLayout', 'level2CssClasses'),
        3: parseFromDocument(document, 'level3SectionLayout', 'level3CssClasses'),
        4: parseFromDocument(document, 'level4SectionLayout', 'level4CssClasses'),
        5: parseFromDocument(document, 'level5SectionLayout', 'level5CssClasses'),
        6: parseFromDocument(document, 'level6SectionLayout', 'level6CssClasses'),
      }
    },
    field: parseFromDocument(document, 'fieldLayout', 'fieldCssClasses')
  }
  
  return theme
}

function parseFromDocument(document: Types.Document, layoutFieldName: string, classesFieldName: string): ItemTheme {
  if (!document) {
    return {
      classes: [],
      layoutId: null
    }
  }

  const layoutReferenceField = document.data[layoutFieldName] as Types.Reference
  const classesField = document.data[classesFieldName] as string ?? ''

  return {
    classes: classesField.split(' '),
    layoutId: layoutReferenceField?.id,
  }
}