
import { setIntervalNoKeepAlive } from '@aeppic/shared/timer'

export class AccessRightCache {
  private _rights = new Map<string, Map<string, boolean>>()
  private _added: { documentId: string, added: number }[] = []
  private _maxDuration: number

  constructor({ maximumDurationToKeepRightsInCacheInMs = 30_000 } = {}) {
    this._maxDuration = maximumDurationToKeepRightsInCacheInMs
    setIntervalNoKeepAlive(() => this._expire(), 2345)
  }

  get(documentId: string, rightId: string) {
    const documentRights = this._rights.get(documentId)

    if (!documentRights) {
      return null
    }

    return documentRights.get(rightId)
  }

  set(documentId: string, rightId: string, hasRight: boolean) {
    const documentRights = this._rights.get(documentId)

    if (!documentRights) {
      this._rights.set(documentId, new Map([[rightId, hasRight]]))
    } else {
      documentRights.set(rightId, hasRight)
    }

    this._added.push({ documentId, added: Date.now() })
  }

  private _expire() {
    const now = Date.now()

    while (this._added.length > 0) {
      const { added, documentId } = this._added[0]

      const timeSinceAdded = now - added

      const thisEntryAndSubquentEntriesAreTooYoung = timeSinceAdded < this._maxDuration
      
      if (thisEntryAndSubquentEntriesAreTooYoung) {
        return
      } else {
        this._added.shift()
      }

      this.expireDocument(documentId)
    }
  }

  expireDocument(documentId: string) {
    this._rights.delete(documentId)
  }

  clear() {
    this._rights.clear()
  }
}
