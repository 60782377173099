type JsonLineObject = {
  lineNo: number
  object: Object
}

const LINE_WARNING_SIZE = 128000

export async function *readJsonlObjects(response: Response, { lineLengthWarning } = { lineLengthWarning: LINE_WARNING_SIZE }): AsyncIterableIterator<JsonLineObject> {
  let lineNo = 0

  for await (const line of readResponseLines(response)) {
    lineNo ++

    if (!line.startsWith('{')) {
      continue
    }

    if (lineLengthWarning && line.length > lineLengthWarning) {
      console.warn(`Large object at line: ${lineNo}`, line.length, line.substr(0, 80) + '...')
    }

    const object = JSON.parse(line)

    yield { lineNo, object }
  }
}

export async function *readResponseLines(response: Response) {
  const asyncTextChunkIterator = buildAsyncTextChunkReader(response)
  let partialLine = ''

  for await (const text of asyncTextChunkIterator) {
    const lines = text.split(/\n/)
    
    while (lines.length > 1) {
      const line = lines.shift()
      
      if (partialLine !== '') {
        yield partialLine + line
        partialLine = ''
      } else {
        yield line
      }
    }

    partialLine = partialLine + lines[0]
  }

  if (partialLine && partialLine.length > 0) {
    yield partialLine
  }
}

function buildAsyncTextChunkReader(response: Response | any) {
  const supportsReader = response.body && response.body.getReader && typeof ReadableStream !== 'undefined' && typeof TextDecoder !== 'undefined'
  const isNodeStream = response.body && response.body.read
  const hasText = response.text
  
  if (supportsReader) {
    return readReadableStream((<any>response.body).getReader())
  } else if (isNodeStream) {
    return readNodeStream(response.body)
  } else if (hasText) {
    return readText(response)
  }

  throw new Error('No supported reader method found')
}

async function *readReadableStream(reader: any) {
  const decoder = new TextDecoder()
    
  while (true) {
    const { done, value } = await reader.read()

    if (done) {
      break
    }

    const text = decoder.decode(value, { stream: true })

    if (text) {
      yield text
    }
  }
}

async function *readNodeStream(readableStream: any, { encoding = 'utf8' }: { encoding?: BufferEncoding } = {}) {
  const { StringDecoder } = await import('string_decoder')
  const decoder = new StringDecoder(encoding)

  for await (const chunk of readableStream) {
    const text = decoder.write(chunk)

    if (text) {
      yield text
    }
  }

  yield decoder.end()
}

async function *readText(responseBody: any, { encoding } = { encoding: 'utf8' }) {
  const text = await responseBody.text()
  yield text
}
