

const DEFAULT_MODE = 'windowed'

import { loadStyle } from '../utils/css'
import { findAncestorWithClass } from '../utils/find-ancestor'

export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"ae-dialog-placeholder"},[(_vm.isDialogMode)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],ref:"container",staticClass:"ae-dialog__container ae-dialog__backdrop",class:_vm.backdropClasses,style:(_vm.styleToApplyToBackdrop),on:{"click":_vm.clickOnBackdrop}},[_c('div',{ref:"dialog",staticClass:"ae-dialog",class:Object.assign({ 'ae-dialog--fullscreen': _vm.showFullscreen }, _vm.dialogClasses),style:(_vm.styleToApplyToDialog),on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"ae-dialog__header"},[_vm._t("header",function(){return [_vm._v(_vm._s(_vm.title))]},{"close":_vm.close})],2),_vm._v(" "),_c('div',{staticClass:"ae-dialog__body"},[_vm._t("body",null,{"close":_vm.close})],2),_vm._v(" "),_c('div',{staticClass:"ae-dialog__commands-bar"},[_c('div',{staticClass:"ae-dialog__commands"},[_vm._t("commands",null,{"close":_vm.close})],2)])])]):_c('ae-popup',{ref:"popup"},[_c('div',{staticClass:"ae-dialog ae-dialog__popup",class:Object.assign({}, _vm.dialogClasses, _vm.popupClasses),style:(_vm.styleToApplyToPopup),attrs:{"slot":"popup"},slot:"popup"},[_vm._t("header",function(){return [_vm._v(_vm._s(_vm.title))]},{"close":_vm.close}),_vm._v(" "),_vm._t("body",null,{"close":_vm.close}),_vm._v(" "),_vm._t("commands",function(){return [_vm._v("default commands content")]},{"close":_vm.close})],2)])],1)},
  staticRenderFns: [],
  /* tslint:enable */
  
  inject: ['getAeppicContext'],
  props: {
    dialogId: String,
    open: Boolean,
    fullScreen: {
      type: Boolean,
      default: null
    },
    title: String,
    adjustDialogSize: {
      type: Boolean,
      default: true
    },
    adjustDialogSizeOptions: {
      type: Object,
      default() {
        return {
          timeout: 1000,
          width: 150,
          height: 150
        }
      }
    },
    dialogSize: {
      type: Object,
      default() {
        return {
          width: '',
          height: ''
        }
      }
    },
    preferredMode: {
      type: String,
      default: DEFAULT_MODE,
    },
    backdropClose: {
      type: Boolean,
      default: true,
    },
    backdropClasses: {
      type: Object,
      default() {return {}},
    },
    backdropStyle: Object,
    dialogClasses: {
      type: Object,
      default() {return {}},      
    },
    dialogStyle: Object,
    popupClasses: {
      type: Object,
      default() {return {}},      
    },
    popupStyle: Object,
  },
  data() {
    const Aeppic = this.getAeppicContext('ae-dialog')

    return {
      Aeppic,
      defaultBackdropStyle: {
        backgroundColor: 'var(--ae-dialog-container-bg-color, rgba(0,0,0,0.1))'
      },
      defaultDialogStyle: {
        backgroundColor: 'white',
        minWidth: '600px',
        minHeight: '300px',
        width: 'initial',
        height: 'initial',
        border: '1px solid #333',        
      },
      defaultPopupStyle: {
        backgroundColor: 'white',
        border: '1px solid lightgray',        
      },
      isOpen: this.open,
      popupOpened: false,
    }
  },
  computed: {
    styleToApplyToBackdrop() {
      return this.backdropStyle || this.defaultBackdropStyle
    },
    styleToApplyToDialog() {
      const style = Object.assign({}, this.dialogStyle || this.defaultDialogStyle)

      if (this.dialogSize.width || this.dialogSize.height) {
        if (this.dialogSize.width) {
          style.width = this.dialogSize.width
        }
        if (this.dialogSize.height) {
          style.height = this.dialogSize.height
        }
      }

      return style
    },
    styleToApplyToPopup() {
      return this.popupStyle || this.defaultPopupStyle
    },
    showFullscreen() {
      if (this.fullScreen === null && this.preferredMode === DEFAULT_MODE && this.Aeppic && this.Aeppic.Device === 'Phone') {
        return true
      }

      return this.fullScreen || this.preferredMode === 'fullscreen'
    },
    isDialogMode() {
      return this.preferredMode !== 'popup'
    },
  },
  mounted() {
    // console.log('ae-dialog:mounted')
    loadStyle(CSS)
    this.container = null
    this.region = this.findDialogRegion()
    this.originalParent = this.$el.parentNode

    if (this.open) {
      this.show()
    }
  },
  beforeDestroy() {
    this.close(true)
    this.$_clearAdjustSizeTimeout()
  },
  created() {
    this.$_observer = null
    this.$_observerUnregisterTimeout = null
    this.$_adjustTimeout = null

    this.$_adjustSize = async () => {
      if (!this.$refs.dialog) {
        return
      }

      await this.Aeppic.waitForNextAnimationFrame()
      await this.Aeppic.waitForNextAnimationFrame()
      await this.Aeppic.waitForNextAnimationFrame()
      await this.Aeppic.waitForNextAnimationFrame()

      await this.Aeppic.nextRenderPass()
      await this.Aeppic.nextRenderPass()

      const { offsetWidth, clientHeight } = this.$refs.dialog

      const smallerThanMinimalSize = offsetWidth <= this.adjustDialogSizeOptions.width || clientHeight <= this.adjustDialogSizeOptions.height
      if (smallerThanMinimalSize) {
        this.$_setAdjustSizeTimeout()
        return
      }

      const { minWidth, minHeight } = this.defaultDialogStyle
      const minWidthAsNumber = Number(minWidth.replace('px', ''))
      const minHeightAsNumber = Number(minHeight.replace('px', ''))

      const sizeStabilized = offsetWidth >= minWidthAsNumber && clientHeight >= minHeightAsNumber
      if (sizeStabilized) {
        this.defaultDialogStyle.width = `${offsetWidth}px`
        // NOTE: add 1px to circumvent scrollbars due to unfavourable size roundings
        this.defaultDialogStyle.height = `${clientHeight + 1}px`

        this.$_clearAdjustSizeTimeout()
        return
      }

      // this.defaultDialogStyle.minWidth = offsetWidth + 'px'
      // this.defaultDialogStyle.minHeight = clientHeight  + 'px'

      this.$_setAdjustSizeTimeout()
    }

    this.$_setAdjustSizeTimeout = () => {
      if (this.$_adjustTimeout) {
        this.Aeppic.clearTimeout(this.$_adjustTimeout)
      }

      this.$_adjustTimeout = this.Aeppic.setTimeout(async () => {
        this.$_clearAdjustSizeTimeout()
        await this.$_adjustSize()
      }, this.adjustDialogSizeOptions.timeout) 
    }

    this.$_clearAdjustSizeTimeout = () => {
      if (this.$_adjustTimeout) {
        this.Aeppic.clearTimeout(this.$_adjustTimeout)
      }
    }
  },
  methods: {
    findDialogRegion() {
      return findAncestorWithClass(this.$el, 'ae-dialog-region')
    },
    moveToDialogRegion() {
      this.container = this.$refs.container

      if (this.container.parentNode !== this.$el) {
        return
      }

      this.region.appendChild(this.container)
    },
    moveToOriginalPosition() {
      if (this.container == null) {
        return
      }

      if (this.container.parentNode === this.$el) {
        return
      }

      this.$el.appendChild(this.container)
    },
    clickOnBackdrop() {
      if (this.backdropClose) {
        this.close()
      }
    },
    close(noEmit = false) {
      this.isOpen = false

      if (this.isDialogMode) {
        this.moveToOriginalPosition()
        // this.defaultDialogStyle.minHeight = '300px'
        // this.defaultDialogStyle.minWidth = '600px'
        this.defaultDialogStyle.height = 'initial'
        this.defaultDialogStyle.width = 'initial'
      } else {
        this.$refs.popup.close()
        this.popupOpened = false
      }

      if (!noEmit) {
        this.$emit('close')
      }
    },
    show({ from, to, noEmit = false } = { from: null, to: null, noEmit: false }) { 
      this.isOpen = true

      if (!this.isDialogMode) {
        if (from) {
          const attachedBase = from || this.$el
          this.$refs.popup.open({ target: attachedBase, data: {} })
          this.popupOpened = true

          if (!noEmit) {
            this.Aeppic.nextRenderPass().then(() => {
              this.$emit('open')
            })
          }
          return
        } else {
          console.warn('Dialog cannot open as popup without a designated `from` element')
        }
      }

      this.moveToDialogRegion()

      if (this.isDialogMode && this.adjustDialogSize && !this.showFullscreen) {
        this.$_setAdjustSizeTimeout()
      }
    }
  },
  watch: {
    open() {
      if (this.open) {
        this.show()
      } else {
        this.close()
      }
    },
  }
}

const CSS = `
.ae-dialog__container {
  z-index: 9998;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateZ(0);
  background: rgba(0,0,0,0.5);
  translate: background 0.5s ease-in;
}

.ae-dialog__header {
  grid-area: header;
  background: lightgray;
}

.ae-dialog__commands-bar {
  grid-area: commands;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.ae-dialog--fullscreen .ae-dialog__commands-bar{
  justify-content: flex-start;
}

.ae-dialog__commands { 
  flex: 1;
  display: flex;
  flex-direction: row;
}

.ae-dialog__body {
  grid-area: body;
  display: flex;
  flex-direction: column;
}

.ae-dialog--fullscreen {
  position: absolute !important;
  top:0;
  right:0;
  bottom:0;
  left:0;
  border:none !important;
  height: 100%;
  width: 100%;
  min-width: initial !important;
  min-height: initial !important;

  grid-template-rows: max-content max-content auto !important;
  grid-template-areas:
    "header"
    "commands"
    "body" !important;
}

.ae-dialog {
  display: grid;
  grid-template-rows: max-content auto max-content;
  grid-template-columns: auto;
  grid-template-areas:
    "header"
    "body"
    "commands";
  background-color: white;
  min-width: 600px;
  min-height: 300px;
  border: 1px solid #333;    
}

.ae-dialog__backdrop {
  background-color: var(--ae-dialog-container-bg-color, rgba(0,0,0,0.1));
}

.ae-dialog-region {
  position: relative;
}
`
