import * as fields from './fields.js'
import { FieldMatchNode, isFieldMatchNode,  MatchExpression,  Query, QueryParameters } from './types.js'

export function extractShapeFromQuery(query: Query) {
  const { graph, options, parameters } = query
  return extractShapeFromQueryGraph(graph, options, parameters)
}

function extractShapeFromQueryGraph(graph: MatchExpression, options: { searchAllFields?: boolean } = {}, parameters: QueryParameters) {
  const shapes = []

  for (const condition of graph.conditions) {
    if (isFieldMatchNode(condition)) {
      const fieldMatchShapeId = extractFieldMatchShape(condition, options, parameters)
      shapes.push(fieldMatchShapeId)
    } else {
      const nestedShape = extractShapeFromQueryGraph(condition, options, parameters)
      shapes.push(nestedShape)
    }
  }

  const operator = ('operator' in graph) ? graph.operator : '_'

  const shape = operator + '(' + shapes.join('|') + ')'
  // console.log(shape)

  return shape
}

function extractFieldMatchShape(fieldMatch: FieldMatchNode, options: { searchAllFields?: boolean } = {}, parameters: QueryParameters) {
  const fieldShapeId = extractFieldShape(fieldMatch, options, parameters)
  const fieldMatchOptionsShape = `${fieldMatch.fieldPrefix}|${fieldMatch.termQuotes}|${fieldMatch.similarity}|${fieldMatch.boost}|${fieldMatch.termPrefix}|${fieldMatch.inclusive}`.replace( /undefined/g, '')

  return `${fieldShapeId}{${fieldMatchOptionsShape}}`
}

function extractFieldShape(fieldMatch: FieldMatchNode, options: { searchAllFields?: boolean } = {}, parameters: QueryParameters) {
  let { field } = fieldMatch
  
  if (field === null) {
    if (options?.searchAllFields === true) {
      field = '<any>'
    } else {
      field = '<default>'
    } 
  }

  if (fields.matchesAnyTruthyValue(fieldMatch, parameters)) {
    return `${field}TRUTHY`
  }

  if (fields.isRangeTerm(fieldMatch, parameters)) {
    return `${field}[TO]`
  }

  if (fields.isToBeComparedLiterally(fieldMatch, parameters)) {
    return `${field}===`
  }

  if (fields.isWildcardMatch(fieldMatch, parameters)) {
    return `${field}*`
  }

  if (fields.comparesToBoolean(fieldMatch, parameters)) {
    return `${field}===t|f`
  }

  return field
}
