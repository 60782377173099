import { getCache } from './caches/arc_cache.js'

export default class Cache<T> {
  private _cache: ReturnType<typeof getCache> = null

  constructor(private _size: number, private _expireInSeconds: number) {
    this._cache = getCache(_size, _expireInSeconds)
  }

  add(key: string, value: T) {
    return this._cache.cache(key, value)
  }

  remove(key: string|string[]) {
    return this._cache.remove(key)
  }

  lookup(key: string, generateValue?: () => T|Promise<T>): Promise<T> {
    return new Promise(async (resolve) => {
      const lookupSuccess = this._cache.cache(key, (value: T) => {
        resolve(value)
      }) 

      if (!lookupSuccess) {
        try {
          if (generateValue) {
            const result = await generateValue()
            this._cache.cache(key, result)
          } else {
            this._cache.cache(key, null)
          }
        } catch (error) {
          // console.error('cache gen value error', error)
          this._cache.cache(key, null)
        }
      }
    })
  }
}
