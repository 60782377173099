import { DateTime } from 'luxon'

export interface ParseDateRangeOptions {
  now: string
}

interface DateRangeParts {
  baseDate?: string|undefined
  operator?: string|undefined
  operandNumber?: string|undefined
  operandType?: string|undefined
  roundDownNearestDay?: string|undefined
}

const regexpIsoDateOrIsoDateWithOffset = `(?:\\d{4}-\\d{2}-\\d{2}(?:(?:T\\d{2}:\\d{2}:\\d{2}Z)|(?:T\\d{2}:\\d{2}:\\d{2}\\+\\d{2}:?\\d{2}))?)`
const regexpDateModifier = `(?:([\\+|\\-])([0-9]+)([y,M,w,d,h,H,m,s]))?`
const regexpNowOrDate = `(now|${regexpIsoDateOrIsoDateWithOffset})`
const regexpRoundDownNearstDay = `(\\/d)?`

const dateRangeFullRegex = `${regexpNowOrDate}${regexpDateModifier}${regexpRoundDownNearstDay}`
const regexDateRange = new RegExp(dateRangeFullRegex)

export function isIsoDate(text: string) {
  return text.match(regexpIsoDateOrIsoDateWithOffset)
}

export function parseDateRange(dateRange: string, { now }: ParseDateRangeOptions): DateTime {
  if (dateRange === 'now') {
    return DateTime.fromISO(now)
  }

  if (dateRange.startsWith('*')) {
    throw new Error('Wildcard needs to be handled outside')
  }

  const dateRangeParts = parseDateRangeString(dateRange)

  if (!dateRangeParts) {
    return null
  }

  const signedValueToAdd = Number(dateRangeParts.operator + dateRangeParts.operandNumber)

  let date: DateTime = getBaseDate(dateRangeParts, now)

  if (dateRangeParts.operandType && signedValueToAdd) {
    date = modifyDate(dateRangeParts.operandType, date, signedValueToAdd)
  }

  if (dateRangeParts.roundDownNearestDay) {
    date = date.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
  }

  return date
}

function parseDateRangeString(dateRange: string): DateRangeParts {
  if (!dateRange) {
    return null
  }

  const parsed = dateRange.match(regexDateRange)
  
  if (!parsed) {
    return null
  }

  const [, baseDate, operator, operandNumber, operandType, roundDownNearestDay ] = parsed

  return { baseDate, operator, operandNumber, operandType, roundDownNearestDay }
}

function getBaseDate(date: DateRangeParts, now: string): DateTime {
  const nowAsDateTime = DateTime.fromISO(now, {
    setZone: true // use timezone provided in string
  })

  if (date.baseDate === 'now') {
    return nowAsDateTime
  }
  
  return DateTime.fromISO(date.baseDate, {
    setZone: true, // use timezone provided in string, if any
    zone: nowAsDateTime.zone // fallback to timezone provided in now
  })
}

function modifyDate(operandType, date, valueToAdd) {
  const modifierFn = dateModifierByType[operandType]

  if (!modifierFn) {
    // tslint:disable-next-line:no-console
    console.warn(`Unknown operandType "${operandType}"`)
    return
  }

  return modifierFn(date, valueToAdd)
}

const dateModifierByType = {
  y(date, valueToAdd) {
    return date.plus({ years: valueToAdd })
  },
  M(date, valueToAdd) {
    return date.plus({ month: valueToAdd })
  },
  w(date, valueToAdd) {
    return date.plus({ weeks: valueToAdd })
  },
  d(date, valueToAdd) {
    return date.plus({ days: valueToAdd })
  },
  H(date, valueToAdd) {
    return dateModifierByType.h(date, valueToAdd)
  },
  h(date, valueToAdd) {
    return date.plus({ hours: valueToAdd })
  },
  m(date, valueToAdd) {
    return date.plus({ minutes: valueToAdd })
  },
  s(date, valueToAdd) {
    return date.plus({ seconds: valueToAdd })
  },
}
