import DynamicComponent from '../dynamic/dynamic-component' 
import createEventForwarders from '../utils/create-event-forwarders'
import { isDocument } from '../../model/is'

export default {
  /* tslint:disable */
  render () {
    const _vm=this;
    const _h=_vm.$createElement;
    const _c=_vm._self._c||_h;
    
    return _vm.canRender ? _c(_vm.dynamicComponent.id,
        {
          tag: 'component',
          props: {
            'linked-document': _vm.documentToBeRendered,
            'design': _vm.dynamicComponent.document,
            'edit': _vm.edit,
            'params': _vm.params
          },
          on: createEventForwarders(_vm._events, _vm)
        }) :_vm._e()
  },
  renderError(h, err) {
    return h('pre', { staticClass: 'ae-error', style: { color: 'red' }}, 'ae-design:' + this.name + ' for ' + this.documentToBeRendered.id + '' + ' ' +  err.stack)
  },
  staticRenderFns: [],
  /* tslint:enable */
  
  inject: ['getAeppicContext'],
  props: { 
    name: {
      type: String,
      required: true
    },
    params: Object,
    documentId: String,
    document: Object,
    edit: {
      type: Boolean,
      default: false
    },
    watch: {
      type: Boolean,
      default: true
    },
    watchDesign: {
      type: Boolean,
      default: null
    },
    watchDesignRevisions: {
      type: Boolean,
      default: null
    },
  },
  data() {
    const Aeppic = this.getAeppicContext('ae-design-selector', this.documentId ?? this.document)
    
    return {
      Aeppic,
      dynamicComponent: null,
      documentToBeRendered: null,
      loadedDocument: null,
    }
  },
  computed: {
    canRender() {
      return this.dynamicComponent && this.dynamicComponent.id && this.documentToBeRendered
    },
    _documentId() {
      return this.documentId || this.document && this.document.id
    }
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  },
  created() {
    const Aeppic = this.Aeppic

    this.componentKey = Aeppic.uuid()

    const isDeveloper = Aeppic.Account && Aeppic.Account.data.allowDevMode

    const watch = typeof this.watchDesign === 'boolean' ? this.watchDesign : isDeveloper
    const watchRevisions =  Aeppic.Developer.isServerInRemoteDeveloperMode ? false
                          : typeof this.watchControlRevisions === 'boolean' ? this.watchControlRevisions
                          : isDeveloper

    this.createDynamicComponent = function () {
      return new DynamicComponent('design', this.Aeppic, {
        formId: 'design-form',
        template: DESIGN_COMPONENT_TEMPLATE,
        exposedSymbols: 'locals, Aeppic, Math, DateTime, Anime, params,$el, $refs, $listeners, document, data, design, startEditing, save, saveAndStopEditing, cancelEditing, resetEdits, isEditing, $emit, $on, translate, watchParam',
        watch,
        watchRevisions
      })
    }

    this.watcher = null

    if (this.params && Object.keys(this.params).length > 0) {
      // console.warn('Note: Please try to not use parameters on designs unless absolutely necessary.')
    }

    this.loadDocument = async function() {
      const useProvidedDocument = !this.watch ? true : this.documentToBeRendered === null

      if (useProvidedDocument && this.document && isDocument(this.document)) {
        this.loadedDocument = await this.document
      } else if (this._documentId) {
        this.loadedDocument = await this.Aeppic.get(this._documentId)
      } else {
        this.loadedDocument = null
      }

      return this.loadedDocument
    }    

    this.loadDesign = async function() {
      if (this.name) {
        const documentToRender = await this.loadDocument()

        if (documentToRender) {
          const designDocument = await this.Aeppic.Designs.findDesignForDocument(documentToRender, this.name)
          
          if (designDocument) {
            // Important: Change component id AND document to be rendered in
            // same tick to prevent mismatches.
            await this.dynamicComponent.refresh(designDocument, () => {
              this.documentToBeRendered = documentToRender
            })
          } else {
            this.dynamicComponent.reset()
          }
        } else {
          this.documentToBeRendered = null
        }
      } else {        
        this.dynamicComponent.reset()
      }
    }

    this.dynamicComponent = this.createDynamicComponent()
    this.dynamicComponent.on('invalidated', () => this.loadDesign())
    
    this.loadDesign()
  },
  beforeDestroy() {
    this.dynamicComponent.destroy()
    this.Aeppic.release()
  },
  methods: {
    startWatchingDocument() {
      this.stopWatchingDocument()

      if (!this.watch || !this._documentId) {
        return
      }

      this.watcher = this.Aeppic.watch(this._documentId, onChange.bind(this))

      function onChange(changedDocument, reason) {
        if (reason.deletedHard) {
          this.documentToBeRendered = null

          return
        }

        if ((reason.updated || reason.stampChanged) && this.loadedDocument) {
          const isSameForm = this.loadedDocument.f.id === changedDocument.f.id 
          const isExactSameForm = isSameForm && this.loadedDocument.f.v === changedDocument.f.v

          if (this.Aeppic.isEditableDocument(this.loadedDocument)) {
            if (!isExactSameForm) {
              this.Aeppic.Log.debug({ target: this.loadedDocument, source: changedDocument }, 'Integrating document that is is not the same form version')
            }

            this.Aeppic.integrate(this.loadedDocument, changedDocument)

            if (!isSameForm) {
              this.loadDesign()
            }
          } else {
            this.documentToBeRendered = changedDocument
            this.loadDesign()
          }
        }
      }
    },
    stopWatchingDocument() {
      if (this.watcher) {
        this.watcher.stop()
        this.watcher = null
      }
    }
  },
  watch: {
    name(id) {
      this.loadDesign()
    },
    watch() {
      this.startWatchingDocument()
    },
    async document() {
      // do not re-create dynamicComponent when only document of same form is changed
      if (this.documentToBeRendered && (this.document || this.documentId)) {
        const newDocument = this.document && isDocument(this.document) ? this.document : await this.loadDocument()

        if (newDocument?.f.id === this.documentToBeRendered.f.id) {
          if (newDocument !== this.documentToBeRendered) {
            this.stopWatchingDocument()
            this.documentToBeRendered = newDocument 
          }

          return
        }
      }

      this.stopWatchingDocument()
      this.loadDesign()
    },
    documentId() {
      this.stopWatchingDocument()
      this.loadDesign()
    },
    documentToBeRendered() {
      this.startWatchingDocument()
    }
  }
}


const DESIGN_COMPONENT_TEMPLATE = `{
  inject: ['getAeppicContext'],
  props: ['linkedDocument', 'design', 'edit', 'params'],
  template: __TEMPLATE__,
  beforeCreate() {
    const self = this
    
    this.createController = () => {
      self.handlers = []
      
      this._isEditing = () => {
        return this.aeLocallyEditableDocument || Aeppic.isEditableDocument(this.linkedDocument)
        // return !!this.aeLocallyEditableDocument
      }

      this._isEditingExternally = () => {
        return !this.aeLocallyEditableDocument && Aeppic.isEditableDocument(this.linkedDocument)
        // return (this._isEditing() && this.aeLocallyEditableDocument === this.linkedDocument)
      }
      
      const Aeppic = this.getAeppicContext('ae-design:' + this.design.id + ':' + this.design.data.name, this.linkedDocument)
      const locals = __NEW_LOCALS__
  
      this._getForm = () => {
        Aeppic.Log.warn('DEPRECATED: use getFormForDocument in the controller to get a form')
        return Aeppic.getDocumentForm(this.aeLocallyEditableDocument || this.linkedDocument)
      }
      
      const controllerVariables = {
        Aeppic,
        get DateTime() { return Aeppic.DateTime },
        get Math() { return Aeppic.Math },
        get Anime() { return Aeppic.Anime },
        translate(...args) { return self.translate(...args) },
        get params() { return self.params },
        get document() { return self.aeLocallyEditableDocument || self.linkedDocument },
        get data() { 
          const document = self.aeLocallyEditableDocument || self.linkedDocument
          
          if (!document) {
            return null
          }
          
          return document.data
        },
        get design() { return self.design },
        
        get locals() { return locals },
        
        watchParam(...args) { return self.watchParam(...args) },
        get isEditing() { return self._isEditing() },
        get isEditingExternally() { return self._isEditingExternally() },

        $emit(...args) { return self.$emit(...args) },
        $on(...args) { 
          self.$parent.$on(...args)
          self.$on(...args)
          
          self.handlers.push(args)
        },
        get $el() { return self.$el },
        get $refs() { return self.$refs },
        $listeners: self.$listeners,

        /* Edit methods */
        startEditing() { return self.startEditing() },
        save() { return self.save() },
        saveAndStopEditing() { return self.saveAndStopEditing() },
        cancelEditing() { return self.cancelEditing() },
        resetEdits() { return self.resetEdits() },
      }
  
      const controller = controllerClass ? new controllerClass(controllerVariables) : null

      return { controller, locals, Aeppic, handlers: self.handlers }
    }

    this._beforeDestroyController = () => {
      __DESTROY_CONTROLLER__

      for (const handlerArgs of this.handlers) {
        this.$off(...handlerArgs) // remove all controller registered listeners (own scope)
        this.$parent.$off(...handlerArgs) // remove all controller registered listeners (parent scope)
      }
  
      this.handlers = []

      this.Aeppic.release()
      this.Aeppic = null
      this.packages = null
    }
  },
  data() {
    const { controller, locals, Aeppic } = this.createController()
    
    return {
      Aeppic,
      controller,
      locals,
      packages,
      aeLocallyEditableDocument: null,
    } 
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  },
  computed: {
    Math() { return this.Aeppic.Math },
    DateTime() { return this.Aeppic.DateTime },
    Anime() { return this.Aeppic.Anime },
    isEditing() { return this._isEditing() },
    isEditingExternally() { return this._isEditingExternally() },
    document() {
      return this.aeLocallyEditableDocument || this.linkedDocument

      // if (this.isEditing) {
      //   return this.aeLocallyEditableDocument
      // } else {
      //   return this.linkedDocument
      // }
    },
    data() {
      return this.document.data
    },
    form() {
      return this._getForm()
    }
  },
  beforeDestroy() {
    this._beforeDestroyController()
  },
  methods: {
    __METHODS__,
    startEditing() {
      if (this.isEditingExternally) {
        return
      } 
      
      this.aeLocallyEditableDocument = null
      
      // NOTE: Don't use await here since this code is not further compiled down
      // yet and should be es5 compatible
      this.Aeppic.edit(this.linkedDocument.id).then((editable) => {
        this.aeLocallyEditableDocument = editable
      })
    },
    save() {
      if (this.isEditing) {
        if (this.isEditingExternally) {
          this.$emit('save', this.linkedDocument)
        } else {
          this.Aeppic.save(this.aeLocallyEditableDocument)
        }
      }
    },
    saveAndStopEditing() {
      this.save()
      this.cancelEditing()
    },
    cancelEditing() {
      if (this.isEditingExternally) {
        this.$emit('cancel-editing', this.linkedDocument)
      } else {
        this.aeLocallyEditableDocument = null
      }
    },
    resetEdits() {
      if (this.isEditingExternally) {
        this.$emit('reset-edits', this.linkedDocument)
      } else {
        this.startEditing()
      }
    },
    translate(...args) { 
      return this.Aeppic.translate(...args) 
    },
    watchParam(name, cb, options) {
      if (!name) {
        return
      }

      let lastValue = undefined

      const cbWithPreCheck = (newValue, oldValue) => {
        if (Array.isArray(newValue) || lastValue !== newValue) {
          cb(newValue, oldValue)
        }

        lastValue = newValue
      }
      
      return this.$watch('params.' + name, cbWithPreCheck, options)
    }
  },
  watch: {
    edit: {
      handler() {
        if (this.edit) {
          this.startEditing()
        } else {
          this.cancelEditing()
        }
      },
      immediate: true
    },
    linkedDocument() {
      if (this.isEditingExternally) {
        return
      } else if (this.isEditing) {
        if (this.aeLocallyEditableDocument && (this.linkedDocument.id === this.aeLocallyEditableDocument.id)) {
          this.Aeppic.integrate(this.aeLocallyEditableDocument, this.linkedDocument)          
        } else {
          this.cancelEditing()
          this.startEditing()
        }
      }

      this._beforeDestroyController()

      const { controller, locals, Aeppic } = this.createController()
      this.controller = controller
      this.locals = locals
      this.Aeppic = Aeppic

      this.Aeppic.setContextRootElement(this.$el)
    }
  },
  components
}`

