
import * as Types from '@aeppic/types'

import Vue from '../../externals/vue.js'
import buildCompleteSearchString from '../utils/build-query-string'
import { isValidTypeOrNull } from '../utils/build-query-string'

export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.subscription)?_c('div',{staticClass:"ae-query"},[_vm._t("default",null,{"documents":this.subscription.documents,"stats":this.subscription.stats,"query":this.subscription})],2):_vm._e()},
  staticRenderFns: [],
  /* tslint:enable */
  
  inject: ['getAeppicContext'],
  props: {
    search: String,
    searchOptions: Object,
    queryFilter: String,
    queryId: String,
    rootDocumentId: {
      type: String,
      default: 'root'
    },
    type: {
      type: String,
      validator: isValidTypeOrNull
    }
  },
  data() {
    return {
      Aeppic: this.getAeppicContext('ae-query'),
      rootDocument: null,
      queryDocument: null,
      subscription: null
    }
  },
  methods: {
    async setupSearch() { 
      [ this.queryDocument, this.rootDocument ] = await this.Aeppic.getAll([this.queryId, this.rootDocumentId])
      const searchString = buildCompleteSearchString(this.search, this.queryFilter, this.type, this.rootDocument, this.queryDocument)

      const searchSize = this.queryDocument && this.queryDocument.data.pageSize || this.searchOptions && this.searchOptions.size

      this.subscription = this.Aeppic.updateSubscription(this.subscription, searchString, {
        size: Number.isNaN(searchSize) ? null : searchSize,
        searchAllFields: this.searchOptions && this.searchOptions.searchAllFields === true
      })
    }
  },
  created() {
    this.setupSearch()
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  },
  beforeDestroy() {
    this.Aeppic.release()
  },
  watch: {
    search() {
      this.setupSearch()
    },
    queryId() {
      this.setupSearch()
    }
  }
}

