import * as Types from '@aeppic/types'

export type MatchConditions = {
  conditions: Condition[]
}

export type MatchExpressionMultiMatch = MatchConditions & { operator: ExplicitOperator }

export type QueryParameterKey = string|number

export type MatchExpression = MatchExpressionMultiMatch|MatchConditions
export type Condition = FieldMatchNode|MatchExpression

export function isQuery(query: any): query is Query {
  if (query && typeof query === 'object' &&  'graph' in query && 'parameters' in query) {
    return true
  } else {
    return false
  }
}

export type Query = {
  graph: MatchExpression
  parameters: QueryParameters
  options?: MatchOptions
}

// import { Form } from '../form'
// export type FormLookupFunction = (id: string, v: string) => Form|Promise<Form>
// export type DocumentLookupFunction = (id: string, v?: string) => Types.Document|Promise<Types.Document>

export type MatchOptions = {
  /*
   * Current datetime in full ISO format
   * 
   * e.g: 2017-12-07T00:00:00+01:00 OR 2032-02-01T03:02:01Z
   */
  nowUtcTz?: string
  searchAllFields?: boolean
}

export interface MatchQueryFunction {
  (document: Types.Document): boolean
  displayName?: string
}

export interface GenericMatchQueryFunction {
  (document: Types.Document, parameters: QueryParameters, options: MatchOptions): boolean
  matchFunctionId: Symbol
  displayName?: string
}

export type QueryParameterValue = string|string[]
export interface StringQueryParameters {
  [key: string]: QueryParameterValue // number|DateTime|Duration
}

export type QueryParameters = StringQueryParameters | Array<QueryParameterValue>

export type QueryParameterReference = { id: string|number }

export type RawFieldMatchNode = {
  field: string
  fieldPrefix?: string
  term?: string
  term_max?: string
  term_min?: string
  termQuotes?: string
  similarity?: number
  boost?: number
  termPrefix?: string
  inclusive?: boolean
}

export type FieldMatchNode = Pick<RawFieldMatchNode, Exclude<keyof RawFieldMatchNode, 'term' | 'term_min'|'term_max'>> & {
  term?: QueryParameterReference
  term_max?: QueryParameterReference
  term_min?: QueryParameterReference
}

export type DefaultOperator = 'AND'|'OR'
export type ExplicitOperator = 'AND'|'OR'
export type RawParsedOperator = ExplicitOperator|'NOT'

export type IntermediateNode = {
  left: QueryGraphNode
  operator?: RawParsedOperator|'<implicit>'
  right?: QueryGraphNode
}

export type QueryGraphNode = IntermediateNode | RawFieldMatchNode

export function isFieldMatchNode(object: any): object is FieldMatchNode {
  return 'field' in object
}

export function isIntermediaryNode(node: QueryGraphNode): node is IntermediateNode {
  return 'left' in node || 'operator' in node
}

export function isLeafNode(node: QueryGraphNode): node is RawFieldMatchNode {
  return !isIntermediaryNode(node)
}
