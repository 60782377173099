import { setIntervalNoKeepAlive } from  '@aeppic/shared/timer'

const ONE_SECOND_IN_MILLISECONDS = 1000
const TEN_SECONDS_IN_MILLISECONDS = 10 * ONE_SECOND_IN_MILLISECONDS
const ONE_MINUTE_IN_MILLISECONDS = 60 * ONE_SECOND_IN_MILLISECONDS
// const TWENTY_MINUTES_IN_MILLISECONDS = 20 * ONE_MINUTE_IN_MILLISECONDS
const ONE_HOUR_IN_MILLISECONDS = 60 * ONE_MINUTE_IN_MILLISECONDS

interface Entry<T> {
  /**
   * Last access
   */
  a: number
  v: T
}

interface CacheObject<T> {
  [key: string]: Entry<T>
}

export class QueryCache<T> {
  private _approxSizeLimit: number
  private _maxAge: number
  private _cleanIntervalInMs: number
  
  private _entries: CacheObject<T>
  private _size: number
  // private _mostRecentlyUsed: CacheObject<T> = Object.create(null)

  constructor({ approxSizeLimit = 100, cleanIntervalInMs = TEN_SECONDS_IN_MILLISECONDS, maxAge = ONE_HOUR_IN_MILLISECONDS } = {}) {
    this._cleanIntervalInMs = cleanIntervalInMs || TEN_SECONDS_IN_MILLISECONDS
    this._approxSizeLimit = approxSizeLimit
    this._maxAge = maxAge

    const result = setIntervalNoKeepAlive(() => this.prune(), this._cleanIntervalInMs)

    if (result && typeof result === 'object' && 'unref' in result) {
      result?.unref?.()
    }

    this.reset()
  }

  reset() {
    this._size = 0
    this._entries = Object.create(null)
  }

  set(key: string, value: T) {
    const existingEntry = this._entries[key]

    if (!existingEntry) {
      this._size ++
      this._entries[key] = {
        a: Date.now(),
        v: value,
      }
    } else {
      existingEntry.a = Date.now()
      existingEntry.v = value
    }
  }

  get(key: string): T {
    const entry = this._entries[key]

    if (!entry) {
      return
    }

    entry.a = Date.now()

    return entry.v
  }

  has(key: string): boolean {
    return this._entries[key] != null
  }

  prune() {
    if (this._size <= this._approxSizeLimit) {
      return
    }

    this.enforceMaxAge()
  }

  enforceMaxAge() {
    const now = Date.now()

    for (const [key, entry] of Object.entries(this._entries)) {
      if (this._expired(now, entry)) {
        delete this._entries[key]
        this._size --
      }
    }
  }

  _expired(now: number, entry: Entry<T>) {
    const age = now - entry.a
    return (age > this._maxAge)
  }
}
