export class AssertException extends Error {}

export function assert(condition: any, message?: string|Error|(() => Error)) {
  const conditionIsTruthy = (!!condition) 

  /* c8 ignore start */
  if (!conditionIsTruthy) {
    if (message instanceof Error) {
      throw message
    } else if (typeof message === 'function') {
      throw message()
    } else {
      throw new AssertException(message)
    }
  }
  /* c8 ignore stop */
}
