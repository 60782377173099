
import Vue from '../../externals/vue.js'
import { renderSection as renderFormSection } from '../form/legacy.js'

export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.document)?_c(_vm.sectionComponentId,{key:_vm.document.id,tag:"component",attrs:{"document":_vm.document,"form":_vm.form,"section":_vm.renderedSection,"readonly":!_vm.isEditable,"fieldConditions":_vm.fieldConditions}}):_vm._e()},
  staticRenderFns: [],
  /* tslint:enable */
  
  inject: ['getAeppicContext'],
  props: {
    form: Object,
    document: Object,
    includeSubsections: {
      type: Boolean,
      default: true 
    },
    renderTitle: {
      type: Boolean,
      default: true
    },
    section: Object,
    sectionId: String,
    readonly: {
      type: Boolean,
      default: false
    },
    fieldConditions: Object
  },
  data() {
    return {
      Aeppic: this.getAeppicContext('ae-form-section'),
      renderedSection: null,
      customSectionLayoutId: null,
      sectionComponentId: null
    }
  },
  beforeDestroy() {
    this.Aeppic.release()
  },
  computed: {
    isEditable() {
      let isEditable = this.Aeppic.isEditableDocument(this.document)

      if (this.readonly) {
        isEditable = false
      }
      
      return isEditable
    },
    isValidForm() {
      return this.form && Boolean(this.form.info)
    }
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  },
  created() {
    this.renderForm()
  },
  methods: {
    renderForm() {
      if (!this.isValidForm) {
        return
      }
      
      this.renderedSection = this.getSectionToRender()
      this.sectionComponentId = this.lookupComponentForSection(this.renderedSection)
    },
    getSectionToRender() {
      if (this.section) {
        return this.section
      } else if (this.sectionId) {
        for (const section of this.form.info.sections) {
          if (section.title === this.sectionId) { 
            return section
          }
        }
      } else {
        return this.form.info.sections[0]
      }
    },
    lookupComponentForSection(section) {
      let sectionComponentId = this.lookupSectionComponentId(this.renderedSection, this.includeSubsections, this.renderTitle)

      if (this.form.document.editVersion) {
        sectionComponentId += `_${this.form.document.editVersion}`
      }

      ensureComponent(sectionComponentId, this.form.info, this.renderedSection, { includeSubsections: this.includeSubsections, renderTitle: this.renderTitle })
      return sectionComponentId
    },
    lookupSectionComponentId(section, includeSubsections, renderTitle) {
      const computedFormId = `ae-form-section__${this.form.id}_${this.form.v}`
      const sectionId = `${this.getSectionId(section)}-${includeSubsections ? 'is' : 'os'}-${renderTitle ? 'rt' : 'ot'}`
      return `${computedFormId}__${sectionId}`
    },
    getSectionId(section) {
      for (let i = 0; i < this.form.info.sections.length; i += 1) {
        if (this.form.info.sections[i] === section) {
          return `SIDX_${i}`
        }
      }
    }
  },
  watch: {
    includeSubsections() {
      this.renderForm()
    },
    renderTitle() {
      this.renderForm()
    },
    form() {
      this.renderForm()
    },
    section() {
      this.renderForm()
    },
    sectionId() {
      this.renderForm()
    },
  }
}

function ensureComponent(id: string, formInfo: any, section: any, renderOptions: any) {
  if (!Vue.component(id)) {
    buildFormSectionComponent(id, formInfo, section, renderOptions)
  }
}

function buildFormSectionComponent(id: string, formInfo: any, section: any, renderOptions: any) {
  const sectionTemplate = renderFormSection(formInfo, section, renderOptions)

  Vue.component(id, {
    inject: ['getAeppicContext'],
    props: {
      document: Object,
      form: Object,
      section: Object,
      sectionStyle: Object,
      includeTitle: Boolean,
      includeSubsections: Boolean,
      readonly: Boolean,
      fieldConditions: Object
    },
    data: function() {
      return {
        Aeppic: this.getAeppicContext('ae-form-section-component')
      }
    },
    mounted() {
      this.Aeppic.setContextRootElement(this.$el)
    },
    beforeDestroy() {
      this.Aeppic.release()
    },    
    methods: {
      getPlaceholder(placeholderIndex) {
        return this.form.info.placeholders[placeholderIndex]
      },
      // getPlaceholderParameters(placeholderIndex) {
      //   const params = this.form.getPlaceholderControlParams(placeholderIndex)

      //   if (params) {
      //     return params
      //   }

      //   return null
      // },
      isPlaceholderReadonly(sectionIndex, placeholderIndex) {
        if (this.readonly) {
          // master render-mode is readnly
          return true
        }
        
        const placeholder = this.form.info.placeholders[placeholderIndex]
        if (this.form.shouldApplyStyleToPlaceholder(placeholder, 'readonly', this.fieldConditions)) {
          // field is readonly
          return true
        }

        const ancestorSections = this.getAncestorSectionsWithReadonlyStyle(sectionIndex)

        for (let section of ancestorSections) {
          if (this.form.shouldApplyStyleToSection(section, 'readonly', this.fieldConditions)) {
            return true
          }
        }
        
        return false
      },
      isPlaceholderHidden(placeholderIndex) {
        const placeholder = this.form.info.placeholders[placeholderIndex]
        return this.form.shouldApplyStyleToPlaceholder(placeholder, 'hidden', this.fieldConditions)
      },
      isSectionHidden(sectionIndex) {
        const section = this.form.info.sections[sectionIndex]
        return this.form.shouldApplyStyleToSection(section, 'hidden', this.fieldConditions)
      },
      shouldApplyStyleToSection(styleName, sectionIndex) {
        const section = this.form.info.sections[sectionIndex]
        return this.form.shouldApplyStyleToSection(section, styleName, this.fieldConditions)
      },
      shouldApplyStyleToPlaceholder(styleName, placeholderIndex) {
        const placeholder = this.form.info.placeholders[placeholderIndex]
        return this.form.shouldApplyStyleToPlaceholder(placeholder, styleName, this.fieldConditions)
      },
      getAncestorSectionsWithReadonlyStyle(sectionIndex) {
        const result = []

        while (sectionIndex !== undefined) {
          const section = this.form.info.sections[sectionIndex]
          if (section.style && section.style.readonly) {
            result.unshift(section)
          }
          sectionIndex = section.parentSectionIndex
        }

        return result
      }
    },
    // template: `<div class="ae-form-section">${sectionTemplate}</div>`,
    template: `${sectionTemplate}`,
    // created: function() {console.log('ae-layout:created', layoutComponentId)},
    // mounted: function() {console.log('ae-layout:mounted', layoutComponentId)}
  })
}

