import { parse as queryParse, stringify as queryStringify } from 'query-string'

import { checkRoutes } from './routing/index.js'

import { getCastingShare, getModelShare } from './route-actions/index.js'

// Expose a factory function that creates a fresh set of store, router,
// app instances on each call (which is called for each SSR request)
export function createApp({ Aeppic, uuid }, options = {}) {
  let fetchToUse = options.fetch

  if (!fetchToUse && typeof fetch !== 'undefined') {
    fetchToUse = fetch
  }

  /* eslint-disable */
  const aeppic = new Aeppic({
    el: options.el,
    auth: options.auth,
    fetch: fetchToUse,
    persistenceAdapter: options.persistenceAdapter,
    server: options.server,
    boot: options.boot,
    load: options.load,
    flags: options.flags,
    removeAfterBoot: options.removeAfterBoot,
    removeAfterLoad: options.removeAfterLoad,
    removeAfterRender: options.removeAfterRender,
    booted: options.booted,
    loaded: options.loaded || onloaded,
    searchAllFields: options.searchAllFields,
    vueComponentOptions: options.vueComponentOptions,
    Logger: options.Logger,
  })

  async function onloaded() {
    // console.log('onloaded')
    const checkRoot = await aeppic.get('root')

    if (!checkRoot) {
      console.error('Cannot find `root`', aeppic.Model.numberOfDocuments);
    }

    const url = options.url

    let initialDocumentId = ''
    let layoutId = null //'5913cb1e-9979-4eb7-84e8-488cdd658cbb'

    const match = await checkRoutes(aeppic, url)

    if (match) {
      let share = null

      if (match.route.id === '3cc5f99e-0b63-4241-b963-6aac470e050a' || match.route.id === '7d831ebf-151b-47ea-9e83-ebdc18b044dc') {
        share = await getCastingShare(aeppic, match)
      } else if (match.route.id === 'a17ed61d-4f83-43cd-81c4-f0d4a0082eac' || match.route.id === '3b0b1a68-5772-4e45-8b10-c3c8b3d0c48e') {
        share = await getModelShare(aeppic, match)
      } else if (match.route.id === '6f88557a-2386-4d3f-ae03-39d9df464960') {
        share = (await aeppic.find(`f.id:804bed89-803a-4090-8b1b-857cab8ec6dd data.identifier:${match.params.sharedMailId}`))[0]
      }

      if (share) {
        if (share.data.targetInfo) {
          initialDocumentId = share.data.targetInfo
        }

        if (match.route.data.layout) {
          layoutId = match.route.data.layout.id
        }
      } else {
        if (match.route.data.layout) {
          layoutId = match.route.data.layout.id
        }

        if (match.params.pageDocumentId) {
          initialDocumentId = match.params.pageDocumentId
        }
      }
    }

    if (!layoutId) {
      const BIZPIN_LAYOUT_ID = '5913cb1e-9979-4eb7-84e8-488cdd658cbb'
      layoutId = BIZPIN_LAYOUT_ID //'5913cb1e-9979-4eb7-84e8-488cdd658cbb'

      const parts = url.split('/')
      const lastPart = parts.pop()
      const lastPartWithoutSearch = lastPart.split('?').shift()
      const initialPath = lastPartWithoutSearch

      initialDocumentId = initialPath
    }

    startRouting(aeppic, layoutId, initialDocumentId)
  }

  return { app: aeppic._vue, aeppic: aeppic, uuid }
}

async function startRouting(aeppic, layoutId, defaultStartDocumentId) {
  aeppic.Router = {
    async onLinkClicked(event, href) {
      const now = Date.now().toString()

      const pageDocumentId = href

      window.history.pushState({ href: pageDocumentId, timestamp: now }, null, href)

      const urlHash = queryParse(href).hash
      const queryParams = queryParse(href).search

      renderPage(layoutId, pageDocumentId, {
        queryParams,
        urlHash,
        timestamp: now
      })

      if (event) {
        event.preventDefault()
      }
    },
    async onNavigateTo(pageDocumentId, queryParams) {
      const now = Date.now().toString()

      const queryString = queryStringify(queryParams)

      if (queryString) {
        window.history.pushState({ href: pageDocumentId, queryParams, timestamp: now }, null, `${pageDocumentId}?${queryString}`)
      } else {
        window.history.pushState({ href: pageDocumentId, timestamp: now }, null, pageDocumentId)
      }

      renderPage(layoutId, pageDocumentId, {
        queryParams,
        timestamp: now
      })
    }
  }

  const queryParams = getQueryParams(typeof window !== 'undefined' && window.location.search)
  const urlHash = getWindowLocationUrlHash()
  const now = Date.now().toString()

  if (typeof window !== 'undefined') {
    window.onpopstate = (e) => {
      // console.log('pop', e.state)
      if (e.state) {
        const pageDocumentId = e.state.href
        const queryParams = e.state.queryParams
        const timestamp = e.state.timestamp

        renderPage(layoutId, pageDocumentId, {
          queryParams,
          historic: true,
          timestamp
        })
        // event.preventDefault()
      }
    }

    window.history.pushState({ href: defaultStartDocumentId, queryParams, urlHash, timestamp: now }, null, window.location.href)
  }

  if (queryParams.impersonationToken) {
    await aeppic.login(null, null, false, true, queryParams.impersonationToken)
  }

  renderPage(layoutId, defaultStartDocumentId, {
    queryParams,
    urlHash,
    timestamp: now
  })

  async function renderPage(layoutId, pageDocumentId, options = {}) {
    // console.log('(app) render', pageDocumentId)
    // console.log('rendering layout', layoutId, pageDocumentId)
    aeppic.renderLayout(layoutId, {
      params: {
        pageDocumentId,
        queryParams: options.queryParams,
        urlHash: options.urlHash,
        history: {
          historic: options.historic || false,
          timestamp: options.timestamp
        }
      }
    })
  }
}

function getQueryParams(fromString) {
  if (!fromString) {
    return {}
  }

  const paramsAsRegularObject = Object.assign({}, queryParse(fromString))

  return paramsAsRegularObject
}

function getWindowLocationUrlHash() {
  if (typeof window === 'undefined') {
    return null
  }

  return window.location.hash
}
