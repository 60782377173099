interface Callback {
  (intersecting: boolean, entry): void
  _clear?: () => any
}

export class VisibilityState {
  private _el: HTMLElement
  private _observer: IntersectionObserver = null
  private _options = null
  private _callback: Callback = null

  constructor (el, options: object, vnode) {
    this._el = el
    this._observer = null

    this.createObserver(options, vnode)
  }

  createObserver (options: object, vnode) {
    if (this._observer) {
      this.destroyObserver()
    }

    this._options = processOptions(options)
    this._callback = this._options.callback

    // Throttle
    if (this._callback && this._options.throttle) {
      this._callback = throttle(this._callback, this._options.throttle)
    }

    this._observer = new IntersectionObserver(entries => {
      let entry = entries[0]
      
      if (this._callback) {
        // Use isIntersecting if possible
        // because browsers can report isIntersecting as true, but intersectionRatio as 0,
        // when something very slowly enters the viewport.
        this._callback(entry.isIntersecting && entry.intersectionRatio >= this.threshold, entry)
      }
    }, this._options.intersection)

    // Wait for the element to be in document
    vnode.context.$nextTick(() => {
      this._observer.observe(this._el)
    })
  }

  destroyObserver () {
    if (this._observer) {
      this._observer.disconnect()
    }

    // Cancel throttled call
    if (this._callback && this._callback._clear) {
      this._callback._clear()
    }
  }

  get threshold () {
    return (this._options.intersection && this._options.intersection.threshold) || 0
  }
}

export default {
  bind (el, { value }, vnode) {
    if (typeof IntersectionObserver === 'undefined') {
      console.warn('[v-observe-visibility] IntersectionObserver API is not available in your browser. Please install this polyfill: https://github.com/w3c/IntersectionObserver/tree/master/polyfill')
    } else {
      const state = new VisibilityState(el, value, vnode)
      el._vue_visibilityState = state
    }
  },

  update (el, { value }, vnode) {
    const state = el._vue_visibilityState
    if (state) {
      state.createObserver(value, vnode)
    } else {
      this.bind(el, { value }, vnode)
    }
  },

  unbind (el) {
    const state = el._vue_visibilityState
    if (state) {
      state.destroyObserver()
      delete el._vue_visibilityState
    }
  },
}

function processOptions (value: object | Function) {
  let options: object

  if (typeof value === 'function') {
    // Simple options (callback-only)
    options = {
      callback: value,
    }
  } else {
    // Options object
    options = value
  }
  return options
}

function throttle(callback, delay) {
  let timeout
  let lastState
  let currentArgs

  const throttled: Callback = (state, ...args) => {
    currentArgs = args
    
    if (timeout && state === lastState) {
      return
    }

    lastState = state
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      callback(state, ...currentArgs)
      timeout = 0
    }, delay)
  }

  throttled._clear = () => {
    clearTimeout(timeout)
  }

  return throttled
}
