/**
 * Walk an object graph given a string in dot-notation
 * 
 * @remarks
 * 
 * Returns `[undefined]` if the path cannot be fully followed
 * 
 * @example
 * 
 * ```
 * const o = {
 *   data: {
 *     address: {
 *       street: 'Main Street'
 *     }
 *   }
 * }
 *
 * const streets = Aeppic.Functions.resolveField(o, 'data.address.street')
 * assert(streets.length === 1)
 * assert(streets[0] === 'Main Street')
 * ```

 * @param object - A javascript object to traverse
 * @param path - A dot seperated path such as data.address.street
 * 
 * @returns All matching values in the graph
 */
export default function resolveField(object: object, path: string): any[] {
  const parts = path.split('.')
  let value = object

  while (parts.length > 0) {
    const part = parts.shift() 

    value = value[part]

    // if (part === 'a')
    // console.log(part, value)

    if (value == null) {
      if (part === 'raw') {
        return [value]
      }

      return [undefined]
    }

    if (parts.length === 0) {
      if (Array.isArray(value)) {
        return value
      } else {
        return [value]
      }
    }

    if (Array.isArray(value)) {
      return value.map(v => resolveField(v, parts.join('.')))
    }
  }

  if (value === object) {
    return [null]
  } else {
    return [value]
  }
}

// Return the first field that is not empty (null or undefined)
export function resolveFirstNonEmptyField(object: object, paths: string[]): any {
  for (const path of paths) {
    const fieldValues = resolveField(object, path)

    if (fieldValues[0] != null) {
      return fieldValues
    }
  }

  return [null]
}
