import { FieldMatchNode, QueryParameters } from './types'

export function isRangeTerm(fieldMatch: FieldMatchNode, parameters: QueryParameters): boolean {
  return 'term_min' in fieldMatch || 'term_max' in fieldMatch
}

export function isWildcardMatch(fieldMatch: FieldMatchNode, parameters: QueryParameters): boolean {
  const term = parameters[fieldMatch.term.id]
  return term.endsWith('*') || term.startsWith('*')
}

export function isToBeComparedLiterally(fieldMatch: FieldMatchNode, parameters: QueryParameters): boolean {
  return fieldMatch.termQuotes !== 'none'
}

export function matchesAnyTruthyValue(fieldMatch: FieldMatchNode, parameters: QueryParameters): boolean {
  if ('term' in fieldMatch) {
    const term = parameters[fieldMatch.term.id]

    if (term === '*') {
      return true
    }
  } else if ('term_min' in fieldMatch && 'term_max' in fieldMatch) {
    const min = parameters[fieldMatch.term_min?.id]
    const max = parameters[fieldMatch.term_max?.id]

    if (min === '*' && max === '*') {
      return true
    }
  }
  
  return false
}

export function comparesToBoolean(fieldMatch: FieldMatchNode, parameters: QueryParameters): boolean {
  const term = parameters[fieldMatch.term.id]

  if (Array.isArray(term)) {
    return false
  }

  if (typeof term === 'boolean') {
    return true
  }

  const lowerCaseTerm = term.toLowerCase?.()
  return lowerCaseTerm === 'true' || lowerCaseTerm === 'false'
}

// function quotedTerm(termString: string) {
//   // tslint:disable-next-line quotemark
//   const escaped = termString.replace(/'/g, "\\'") 
//   return `'${escaped}'`
// }
