
import * as Types from '@aeppic/types'
import { parse as queryParse, stringify as queryStringify } from 'query-string'

import Aeppic from './aeppic'
import UrlPattern from 'url-pattern'

import { runActions } from '../dynamic/run-action.js'
import { buildLogger, Logger, InMemoryLog } from '../model/log'
import * as Errors from './errors'

export interface EvaluationOptions {
  retainLog?: boolean
}

const DEFAULT_EVALUATION_OPTIONS: EvaluationOptions = {

}

export interface RouteParams {
  [key: string]: string|number|boolean
}

export interface RouteMatchResult {
  redirect?: string
  layout?: Types.IdOrReference
  params?: object
  url: string
  hash: ReturnType<typeof queryParse>
  query: ReturnType<typeof queryParse>
  log?: any
}

export interface IRouter {
  onNavigateTo(targetDocumentId: string, parameters?: RouteParams): void
  onLinkClicked(event, href): void
  navigateToUrl(url: string): void

  evaluate(url: string): Promise<RouteMatchResult>
}

export const DOCUMENT_ID_PLACEHOLDER = '7d4e4903-5ef8-43cd-8399-bb56318e8bd9'
export const ROUTE_QUERY_PARAMETERS_PLACEHOLDER = 'e2282f0b-f933-4b56-9366-fa60face30de'

export function ParameterizedDocumentRoute(strings: TemplateStringsArray, ...params: (string|Symbol)[]) {
  return function buildNavigateToUrl(documentId: string, routeParams: RouteParams) {
    const queryParams = queryStringify(routeParams)
    const urlParts = []

    for (let i = 0; i < strings.length; i++) {
      const fixedString = strings[i]
      urlParts.push(fixedString)

      const dynamicParameter = params[i]

      switch (dynamicParameter) {
        case DOCUMENT_ID_PLACEHOLDER:
          urlParts.push(documentId)
          break
        case ROUTE_QUERY_PARAMETERS_PLACEHOLDER:
          if (queryParams) {
            urlParts.push('?')
            urlParts.push(queryParams)
          }
          break
        default:
          urlParts.push(dynamicParameter)
      }
    }   
    
    return urlParts.join('')
  }
}

export interface RouterOptions {
  Aeppic?: Aeppic
  Logger?: Logger

  defaultLayoutId?: null
  navigateToDocumentUrlBuilder?: ReturnType<typeof ParameterizedDocumentRoute>
}

const DefaultRouterOptions: Partial<RouterOptions> = {
  navigateToDocumentUrlBuilder: ParameterizedDocumentRoute`/docs/${DOCUMENT_ID_PLACEHOLDER}${ROUTE_QUERY_PARAMETERS_PLACEHOLDER}`,
}

interface PrefixedRoutingTable {
  name: string
  prefix: string
  routes: Types.Document[]
}

export interface Route {
  templateUrl: string
  document: Types.Document
  dynamicAction?: Types.Document
}

export class DefaultRouteListBuilder {
  
  constructor(private _aeppic: Aeppic) {}

  async *enumerate(): AsyncIterableIterator<Route> {
    for await (const table of this.iterateRoutingTables()) {
      const routes = await this._toRoutes(table)
      yield *routes
    }
  }

  async *_toRoutes(table: PrefixedRoutingTable): AsyncIterableIterator<Route> {
    for (const routeDocument of table.routes) {
      const routeUrl = ensurePathPrefix(table.prefix) + ensurePathPrefix(<string> routeDocument.data.route)

      const route: Route = {
        templateUrl: routeUrl,
        document: routeDocument,
      }

      if (<boolean> routeDocument.data.isDynamic) {
        route.dynamicAction = await this._aeppic.get(<Types.Reference> routeDocument.data.action)
      }

      yield route
    }
  }

  async *iterateRoutingTables(): AsyncIterableIterator<PrefixedRoutingTable> {
    const root: any = await this._aeppic.get('root')
    
    if ('defaultApp' in root.data) {
      const prefixedRoutingTables = this._loadRoutingTablesFromApplication(root.data.defaultApp)
      yield *prefixedRoutingTables
    }

    if ('routingTables' in root.data) {
      const routingTables = this._loadRoutingTables(root.data.routingTables)
      yield *routingTables
    }
  }

  async *_loadRoutingTablesFromApplication(applicationReference: Types.Reference): AsyncIterableIterator<PrefixedRoutingTable> {
    const app = await this._aeppic.get(applicationReference)

    if ('routingTables' in app.data) {
      const routePrefix = <string>app.data.routingPrefixAlias || (<string>app.data.name).toLocaleLowerCase()
      yield * this._loadRoutingTables(<Types.Reference[]> app.data.routingTables, routePrefix)
    }
  }

  async *_loadRoutingTables(routingTables: Types.Reference[], prefix?: string): AsyncIterableIterator<PrefixedRoutingTable> {
    for (const tableReference of routingTables) {
      const loadedTable = await this._loadRoutingTable(tableReference, prefix)
      yield loadedTable
    }
  }

  async _loadRoutingTable(routingTableIdentifier: Types.IdOrReference, prefix: string = ''): Promise<PrefixedRoutingTable> {
    const table = await this._aeppic.get(routingTableIdentifier)

    if (!table) {
      return
    }

    const allRoutePrefix = ensurePathPrefix(prefix) + ensurePathPrefix(<string>table.data.sharedPrefix)
    const routes = await this._aeppic.getAll(<Types.Reference[]>table.data.routes)

    return {
      name: <string> table.data.name,
      prefix: allRoutePrefix,
      routes: routes
    }
  }
}  

function ensurePathPrefix(prefixString: string) {
  if (!prefixString) {
    return ''
  }

  if (prefixString.startsWith('/')) {
    return prefixString
  } else {
    return '/' + prefixString
  }
}

export class Router implements IRouter {
  private _window: Window
  private _aeppic: Aeppic
  private _defaultLayoutId: string
  private _navigateToDocumentUrlBuilder: ReturnType<typeof ParameterizedDocumentRoute>
  private _log: Logger
  private _routes: Route[]
  private _parsedRoutes: Map<Route, UrlPattern>

  constructor(specifiedOptions: RouterOptions = {}) {
    const options = { ...DefaultRouterOptions, ...specifiedOptions }

    this._log = buildLogger(options.Logger, { module: 'routing', class: 'Router' })
    this._aeppic = options.Aeppic
    this._defaultLayoutId = options.defaultLayoutId
    this._navigateToDocumentUrlBuilder = options.navigateToDocumentUrlBuilder
  }

  load() {
    this._log.warn('`load` is deprecated. Use `refresh` instead.')
    return this.refresh()
  }

  async refresh() {
    const routeBuilder = new DefaultRouteListBuilder(this._aeppic)
    
    this._routes = []

    for await (const route of routeBuilder.enumerate()) {
      this._routes.push(route)
    }

    this._parseRoutes()
  }

  _parseRoutes() {
    this._parsedRoutes = new Map()

    for (const route of this._routes) {
      this._parsedRoutes.set(route, new UrlPattern(route.templateUrl))
    }
  }

  set Routes(routes: Route[]) {
    this._routes = routes
    this._parseRoutes()
  }

  get Routes() {
    return this._routes
  }

  linkToBrowserWindow(windowToLink: Window = window) {
    this._window = windowToLink
    this._log.info('Linking to window (href=%s)', this._window.location.href)

    windowToLink.onpopstate = (e) => {
      this._log.trace({ state: e.state }, 'onpopstate')

      if (e.state) {
        this._navigateToUrl(e.state.href, { omitPushState: true })
        e.preventDefault()
      }
    }

    windowToLink.onhashchange = () => {
      this._log.trace({ href: this._window.location.href }, 'onhashchange')
      this._navigateToUrl(this._window.location.href)
    }
    
    this._window.history.pushState({ href: this._window.location.href }, null, this._window.location.href)
  }

  onNavigateTo(targetDocumentId: string, parameters?: RouteParams): void {
    const documentUrl = this._navigateToDocumentUrlBuilder(targetDocumentId, parameters)
    this.navigateToUrl(documentUrl)
  }

  onLinkClicked(event: any, href: string): void {
    this.navigateToUrl(href)
  }

  navigateToUrl(url: string): void {
    this._navigateToUrl(url)
  }

  private async _navigateToUrl(url: string, { omitPushState }: { omitPushState?: boolean } = {}) {
    this._log.info('Navigate to url %s %s', url, omitPushState ? '(Not pushing state to history)' : '')

    if (!omitPushState) {
      window.history.pushState({ href: url }, null, url)
    }

    try {
      const result = await this.evaluate(url)

      this._aeppic.renderLayout(result.layout, {
        params: {
          ...result.params,
          route: {
            query: result.query,
            hash: result.hash,
            url,
          }
        }
      })
    } catch (error) {
      this._aeppic.renderLayout(this._defaultLayoutId, {
        params: {
          route: {
            url,
            error,
          }
        }
      })
    }
  }

  match(url: string): { match: Route, parameters: RouteParams } {
    if (!this._routes) {
      this._log.info('No routes configured on router')
      return null
    }

    for (const route of this._routes) {
      const parsedRoute = this._parsedRoutes.get(route)

      if (parsedRoute) {
        const matchedParameters = parsedRoute.match(url)

        if (matchedParameters) {
          return { match: route, parameters: matchedParameters }
        }
      }
    }
  }

  async evaluate(navigationUrl: string, specifiedOptions: EvaluationOptions = {}): Promise<RouteMatchResult> {
    const url = ensureRelativeUrl(navigationUrl)
  
    const options = { ...DEFAULT_EVALUATION_OPTIONS, ...specifiedOptions }

    let   log = this._log.child({ url })
    
    const [baseUrl, queryString, hash] = splitUrl(url) 

    const queryParameters = queryParse(queryString)
    const hashParameters = queryParse(hash)

    const route = this.match(baseUrl)

    const defaultResult: RouteMatchResult = {
      layout: this._defaultLayoutId,
      url,
      query: queryParameters,
      hash: hashParameters,
      params: (route && route.parameters) || {}  
    }

    if (!route) {
      log.info({ url }, 'No matching route found for url: %s', url)      
      return defaultResult
    } else {
      log = log.child({ route: await this._aeppic.asReference(route.match.document), parameters: route.parameters, query: queryParameters, hash: hashParameters })

      log.trace({ matchedRoute: route }, 'Matched `%s` to route "%s" (%s@%s)', url, route.match.document.data.name, route.match.document.id, route.match.document.v)

      const dynamicRouteAction = route.match.dynamicAction

      if (!dynamicRouteAction) {
        const assignedLayout = await this._aeppic.get(<Types.Reference> route.match.document.data.layout)

        if (!assignedLayout) {
          log.warn({ route }, 'Matched `%s` to route "%s" (%s@%s) with NO layout configured or layout not resolvable', url, route.match.document.data.name, route.match.document.id, route.match.document.v)
          throw new Errors.InvalidRouteConfiguration(url)
        }

        return {
          ...defaultResult,
          layout: await this._aeppic.asReference(assignedLayout)
        }
      } else {
        const context = {
          route: {
            url,
            document: route.match.document,
            params: route.parameters,
            query: queryParameters,
            hash: hashParameters,
          },
        }

        log.trace({ context }, 'Executing action %s@%s', dynamicRouteAction.id, dynamicRouteAction.v)

        const MAX_ROUTER_LOG_SIZE = 1000
        
        let actionLogger: Logger
        let inMemoryLog: InMemoryLog

        if (options.retainLog) {
          inMemoryLog = new InMemoryLog({ size: MAX_ROUTER_LOG_SIZE, defaults: { scope: 'routing', source: 'action', action: await this._aeppic.asReference(dynamicRouteAction) }})
          actionLogger = inMemoryLog.child()
        } else {
          actionLogger = log.child({ source: 'action', action: await this._aeppic.asReference(dynamicRouteAction) })
        }


        const actionResults = await runActions([ dynamicRouteAction ], { context }, { Logger: actionLogger })

        const primaryResult = actionResults[0]

        if (!primaryResult.ok) {
          log.error({ result: primaryResult, context, action: await this._aeppic.asReference(dynamicRouteAction) }, 'Error \'%s\' executing Action %s@%s', primaryResult.error, dynamicRouteAction.id, dynamicRouteAction.v)
          throw new Errors.DynamicRouteProcessing(url)
        } 

        if (primaryResult.writes && primaryResult.writes.length > 0) {
          log.error({ result: primaryResult, context, actionRef: this._aeppic.asReference(dynamicRouteAction) }, 'Error executing Action %s@%s. It tried to write to Aeppic', dynamicRouteAction.id, dynamicRouteAction.v)
          throw new Errors.DynamicRouteWrite(url)
        }

        const logMessages = options.retainLog ? Array.from(inMemoryLog.enumerate()) : undefined
        
        log.trace({ result: primaryResult, context, action: await this._aeppic.asReference(dynamicRouteAction), log: logMessages }, 'Executed Action %s@%s', dynamicRouteAction.id, dynamicRouteAction.v)

        const routeResult: RouteMatchResult = {
          ...defaultResult,
          layout: primaryResult.value.layout,
          params: primaryResult.value.params,
          redirect: primaryResult.value.redirect,
          log: logMessages,
        }

        log.debug({ routeResult }, 'Evaluated route %s@%s dynamically with action %s@%s', route.match.document.id, route.match.document.v, dynamicRouteAction.id, dynamicRouteAction.v )

        return routeResult
      }
    }
  }
}

function splitUrl(url: string) {
  const indexOfQuery = url.indexOf('?')

  if (indexOfQuery < 0) {
    const indexOfHash = url.indexOf('#')

    if (indexOfHash < 0) {
      return [url, '', '']
    }

    const baseUrl = url.slice(0, indexOfHash)
    const suffix = url.slice(indexOfHash + 1)

    return [baseUrl, '', suffix]
  } else {
    const baseUrl = url.slice(0, indexOfQuery)
    const suffix = url.slice(indexOfQuery + 1)

    const indexOfHash = suffix.indexOf('#')

    if (indexOfHash < 0) {
      return [baseUrl, suffix, '']
    }
    
    return [baseUrl, suffix.slice(0, indexOfHash), suffix.slice(indexOfHash + 1)]
  }
}

function ensureRelativeUrl(url: string) {
  if (url.toLowerCase().startsWith('http')) {
    const parsedUrl = new URL(url)
    return parsedUrl.pathname + parsedUrl.search + parsedUrl.hash
  } else {
    return url
  }
}

// function toQueryParams(parameters?: RouteParams) {
//   if (!parameters) {
//     return ''
//   }

//   const args = []

//   for (const [name, value] of Object.entries(parameters)) {
//     args.push(`${name}=${value}`)
//   }
// }
// const ABSOLUTE_URL_REGEX: RegExp = /(http|https|ftp):\/\//i

// function isExternalLink(url: string) {
//   return ABSOLUTE_URL_REGEX.test(url.toLowerCase())
// }
