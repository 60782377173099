import { DateTime, Duration as DateTimeDuration, Interval as DateTimeInterval } from 'luxon'

import { runCode, RunOptions } from '../../dynamic/dynamic-code.js'
import { CommandAeppicInterface } from './executor.js'

export interface ConditionResult {
  ok: boolean
  error?: string
  value?: any
}

export interface ConditionOptions {
  noIsolation?: boolean
}

export type ScriptFunction = {
  ok: true
  fn: any
} | 
{
  ok: false
} 

const DEFAULT_CONDITION_OPTIONS: RunOptions = {
  isolation: 'best',
  functionExecution: 'sync'
}

export class ConditionExecutor {
  private _aeppic: CommandAeppicInterface

  constructor({ Aeppic }: { Aeppic: CommandAeppicInterface }) {
    this._aeppic = Aeppic
    Object.freeze(this)
  }

  async allPassing(conditions, target, conditionParameters, options: ConditionOptions = {}) {
    const targetDocument = await this._aeppic.get(target)
    
    if (!targetDocument) {
      throw new Error(`Unknown target or target not accessible (${JSON.stringify(target)})`)
    }

    const context = {
      target: targetDocument,
      params: conditionParameters || {}
    }

    return this._runConditions(conditions, context, { isolation: options.noIsolation ? 'none' : 'best' })
  }

  private async _runConditions(conditions, context, options) {
    const asyncResults: ConditionResult[] = conditions.map(condition => this._runCondition(condition, context, options))
    const results = await Promise.all(asyncResults)
    
    return results.every(result => result.ok && !!result.value)
  }

  private async _runCondition(condition, context, specifiedOptions): Promise<ConditionResult> {
    const options = { ...DEFAULT_CONDITION_OPTIONS, ...specifiedOptions }

    const globals = {
      context,
      params: context.params,
      DateTime,
      DateTimeDuration,
      DateTimeInterval,
      Math,
      setTimeout: RaiseUnsupportedFunction,
      setInterval: RaiseUnsupportedFunction,
    }

    let result: ConditionResult = { ok: false }

    result = await runCode(condition, globals, options)

    return result
  }
}

function RaiseUnsupportedFunction() {
  throw new Error('Unsupported function error')
}
