import type * as Types from '@aeppic/types'
import { FormBasedLookup, LookupEntry, LookupFunction, LookupTrace, ancestorTraversal, checkInReferencedFolders, form, getFullFormAncestry, lookForFoldersOfType, parseSelector } from './form-oriented-lookup.js'
import type { AeppicInterface } from '../aeppic.js'

const DESIGN_FORM = 'design-form'
const DESIGN_FOLDER_FORM = 'design-folder'
const SYSTEM_FALLBACK_FOLDER: LookupEntry = 'fallback-designs'

const DEFAULT_FIND_OPTIONS = {
  sort: 'created.at',
  fields: [
    "namespace",
    "name",
  ]
}

const FORMS_WITH_REFERENCED_DESIGNS: Types.DocumentId[] = [
  'form-folder',
  'application-form',
  'root-folder-form',
]

// Strategies
//
// Ids or Functions that will be called in order to find the control
//
// Each Id is a folder id to look in.
//
// Each function returns an AsyncIterableIterator of the folder ids
// to look in.
const DESIGN_FOLDER_LOOKUP_ORDER_DEFAULT: LookupEntry[] = [
  SYSTEM_FALLBACK_FOLDER,
]

const DESIGN_FOLDER_LOOKUP_ORDER_FULL: LookupEntry[] = [
  form,
  ancestorTraversal(
    getFullFormAncestry,
    [
      lookForDesignFolders(),
      checkInReferencedFolders(
        'designs',
        FORMS_WITH_REFERENCED_DESIGNS
      )
    ],
  ),
  ...DESIGN_FOLDER_LOOKUP_ORDER_DEFAULT
]

const WARNED_ABOUT_DESIGN_LOOKUP = new Set()

export enum DesignMatchStrategy {
  FULL,
  DEFAULT_ONLY
}

type DesignLookupInfo = {}

export class DesignLookup {
  private _lookup: FormBasedLookup<DesignLookupInfo>

  constructor(private aeppic: AeppicInterface, private _strategy: DesignMatchStrategy) {
    const strategy =
        _strategy == DesignMatchStrategy.FULL ? DESIGN_FOLDER_LOOKUP_ORDER_FULL
      : _strategy == DesignMatchStrategy.DEFAULT_ONLY ? DESIGN_FOLDER_LOOKUP_ORDER_DEFAULT
      : DESIGN_FOLDER_LOOKUP_ORDER_FULL

    this._lookup = new FormBasedLookup(
      aeppic,
      'design', strategy, findBestMatchingDesignInFolder
    )
  }

  async findDesignDocument(formId: Types.DocumentId, selector: string, lookupTrace?:LookupTrace): Promise<Types.Document|null> {
    let { namespace, name } = parseSelector(selector)

    if (lookupTrace) {
      lookupTrace.namespace = namespace
      lookupTrace.name = name
    }

    return this._lookup.find(formId, namespace, name, null, lookupTrace)
  }
}

async function findBestMatchingDesignInFolder(aeppic: AeppicInterface, parentId: Types.DocumentId, formId: Types.DocumentId, namespace: string, name: string, trace?: LookupTrace): Promise<Types.Document> {
  const designName = namespace ? namespace + ':' + name : name

  let query = aeppic.Query.children(parentId)
    .form(DESIGN_FORM)


  if (name == '*' && trace) {
    trace.addStep({ operation: 'find', documentId: parentId, additionalInfo: { query } })
    return null
  }

  query = query
    .where('data.name').is(designName)
    
  const design = await aeppic.findOne(query, DEFAULT_FIND_OPTIONS)

  if (design) {
    trace?.addStep({ operation: 'find:match', documentId: parentId, additionalInfo: { query, design } })
    return design
  }

  trace?.addStep({ operation: 'find:match:empty', documentId: parentId, additionalInfo: { query } })
  return null
}

function lookForDesignFolders(): LookupFunction {
  return lookForFoldersOfType(DESIGN_FOLDER_FORM, DEFAULT_FIND_OPTIONS)
}