import * as Types from '@aeppic/types'

import { EventEmitter } from  '@aeppic/shared/event-emitter'

export type REMOTE_DEVELOPER_MODE = 'None' | 'Client' | 'Server'

export default class Developer extends EventEmitter {

  private Aeppic = null
  private _aeppicServerAdapter
  private _remoteDeveloperMode: REMOTE_DEVELOPER_MODE = 'None'

  private _discoveredElements: HTMLElement[] = []
  private _inspectorDiscoveryPath = []

  constructor(Aeppic) {
    super()

    this.Aeppic = Aeppic
    this._aeppicServerAdapter = Aeppic._serverAdapter

    this._setupRemoteDeveloper()
  }

  destroy() {
    this.removeAllListeners()
  }

  get isServerInRemoteDeveloperMode() {
    return this._remoteDeveloperMode === 'Server'
  }

  private _setupRemoteDeveloper() {
    if (!this._aeppicServerAdapter) {
      return
    }

    // server side
    this.Aeppic.Model.watchAllRevisions((document, reason) => {
      if (this._remoteDeveloperMode !== 'Server') {
        return
      }
      
      const simplifiedDocument = {
        id: document.id, 
        f: document.f,
        p: document.p,
        data: document.data
      }
      
      const data = {
        type: 'revision',
        document: simplifiedDocument,
        reason: reason
      }
      this._aeppicServerAdapter.sendToDeveloperWebsocket(data)
    })

    // client side
    this.on('inspector-path-updated', () => {
      if (this._remoteDeveloperMode !== 'Client') {
        return
      }

      const message = {
        type: 'inspector-path-updated',
        inspectorPath: this._inspectorDiscoveryPath
      }
      this._aeppicServerAdapter.sendToDeveloperWebsocket(message)
    })

    this._aeppicServerAdapter.on('developer:message', async (message) => {
      // client side
      if (message.type === 'revision' && this._remoteDeveloperMode === 'Client') {
        const editableDocument = await this.Aeppic.edit(message.document.id)
        if (editableDocument) {
          editableDocument.setData(message.document.data, { cloneFromDefault: false })
          this.Aeppic.Model.notifyWatchersAboutRevision(editableDocument, message.reason)
        }
      }
      // client side
      else if (message.type === 'navigate' && this._remoteDeveloperMode === 'Client') {
        this.Aeppic.navigateTo(message.documentId)
      }
      // server side
      else if (message.type === 'inspector-path-updated' && this._remoteDeveloperMode === 'Server') {
        this.setInspectorDiscoveryPath(message.inspectorPath, [])
      }
      // both sides
      else if (message.type === 'remote-message') {
        this.emit(message.message, message.data)
      }
    })  
  }

  public enableRemoteDeveloper(sessionId: string, mode: REMOTE_DEVELOPER_MODE) {
    if (!this._aeppicServerAdapter) {
      console.error('No serverAdapter for remote Developer')
      return
    }

    if (this._remoteDeveloperMode !== 'None') {
      this._aeppicServerAdapter.disconnectDeveloper()
    }

    this._remoteDeveloperMode = mode
    this._aeppicServerAdapter.connectDeveloper(sessionId)
  }

  public disableRemoteDeveloper() {
    this._remoteDeveloperMode = 'None'

    if (this._aeppicServerAdapter) {
      this._aeppicServerAdapter.disconnectDeveloper()
    }
  }

  public get remoteDeveloperMode() {
    return this._remoteDeveloperMode
  }

  public navigateTo(documentIdentifier: Types.Document | Types.IdOrReference) {
    if (this._remoteDeveloperMode !== 'Server') {
      this.Aeppic.navigateTo(documentIdentifier)
    }
    else if (this._aeppicServerAdapter) {
      const documentId = typeof documentIdentifier === 'string' ? documentIdentifier : documentIdentifier.id

      const message = {
        type: 'navigate',
        documentId: documentId
      }
      this._aeppicServerAdapter.sendToDeveloperWebsocket(message)
    }
  }

  public remoteEmit(message, data) {
    const wsMessage = {
      type: 'remote-message',
      message: message,
      data: data
    }
    this._aeppicServerAdapter.sendToDeveloperWebsocket(wsMessage)
  }

  
  //
  // Inspector discovery path
  // ========================
  //

  get inspectorPath() {
    return this._inspectorDiscoveryPath
  }

  setInspectorDiscoveryPath(discoveryPathInfo, elements, options?) {
    this._discoveredElements = elements
    this._inspectorDiscoveryPath = discoveryPathInfo
    this.emit('inspector-path-updated', this._discoveredElements, options)
  }

  private getInspectedElementInfo(elementId) {
    for (let i = 0; i < this._inspectorDiscoveryPath.length; i++) {
      if (this._inspectorDiscoveryPath[i].id === elementId) {
        const element = this._discoveredElements[i]
        const rect = element.getBoundingClientRect()

        return {
          top: Math.floor(rect.top),
          left: Math.floor(rect.left),
          width: Math.floor(rect.width),
          height: Math.floor(rect.height)
        }
      }
    }
    return null
  }

  buildInspectorDiscoveryPath(elements: HTMLElement[]) {
    const path = []

    for (let el of elements) {
      let entry = this._buildInspectorDiscoveryPathEntry(el)
      path.push(entry)
    }

    return path
  }

  private _buildInspectorDiscoveryPathEntry(element) {
    let entry = {
      fullTag: this._elementToString(element),
      tagName: element.tagName && element.tagName.toLowerCase(),
      attributes: this._buildAttributesArrayForElement(element),
      type: 'Element',
      documentId: null,
      formId: null,
      designId : null,
      layoutId: null,
      listId: null,
      controlId: null,
      id: this.Aeppic.uuid()
    }

    const component = this.Aeppic.lookupComponentForElement(element)
    const dynamicComponent = this._getDynamicComponent(component)

    if (this._elementHasClass(element, 'ae-design')) {
      entry.type = 'Design'
      entry.designId = dynamicComponent && dynamicComponent && dynamicComponent.document.id
      entry.documentId = component && component.documentToBeRendered && component.documentToBeRendered.id
      const formId = component && component.documentToBeRendered && component.documentToBeRendered.f.id
      entry.formId = formId
    }
    else if (this._elementHasClass(element, 'ae-list')) {
      entry.type = 'List'
      entry.listId = dynamicComponent && dynamicComponent && dynamicComponent.document.id
    }
    else if (this._elementHasClass(element, 'ae-form')) {
      entry.type = 'Form'
      entry.formId = component && component.formToRender && component.formToRender.id
      entry.layoutId = component && component.$children[0] && component.$children[0].dynamicComponent 
                    && component.$children[0].dynamicComponent.document && component.$children[0].dynamicComponent.document.id 
    }
    else if (this._elementHasClass(element, 'ae-layout') && !this._elementHasClass(element, 'ae-form')) {
      entry.type = 'Layout'
      entry.layoutId = dynamicComponent && dynamicComponent.document && dynamicComponent.document.id
    }
    else if (this._elementHasClass(element, 'ae-form-section')) {
      entry.type = 'Form Section'
    }
    else if (this._elementHasClass(element, 'ae-control')) {
      entry.type = 'Control'
      entry.controlId = dynamicComponent && dynamicComponent.document && dynamicComponent.document.id
    }
    else if (this._elementHasClass(element, 'ae-splitter')) {
      entry.type = 'Splitter'
    }
    // else if (this._elementHasClass(element, 'ae-tabs')) {
    //   entry.type = 'Tabs'
    // }
    else if (this._elementHasClass(element, 'ae-query')) {
      entry.type = 'Query'
    }

    return entry
  }

  private _elementToString(element) {
    let attributes = ''
    for (let a of (element.attributes || [])) {
      attributes += ` ${a.name}=${a.value}`
    }

    return `<${element.tagName && element.tagName.toLowerCase()}${attributes}>`
  }

  private _buildAttributesArrayForElement(element) {
    const result = []

    for (let a of (element.attributes || [])) {
      result.push({
        name: a.name,
        value: a.value
      })
    }

    return result
  }

  private _elementHasClass(element: HTMLElement, className: string) {
    return element && element.classList && element.classList.contains(className)
  }

  private _getDynamicComponent(component: any) {
    if (!component) {
      return null
    }

    const direct = component.dynamicComponent 

    if (direct) {
      return direct
    }

    if (component.$children?.length === 1) {
      const [ singleChild ] = component.$children
      return singleChild.dynamicComponent
    }

    return null
  }
}
