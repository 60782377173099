import * as Types from '@aeppic/types'
import Vue from '../../externals/vue.js'

import editFunctions from '../utils/edit-ref-functions'

const NUMBER_REGEX = /^\s*[0-9]+[0-9\.]*\s*$/g
const QUOTED_STRING_REGEX = /^\s*[\'\"]{1}.*[\'\"]{1}\s*$/g

export default {
  props: ['names', 'values'],

  data() {
    const variableNames = this.names.split(',')
    const variableValues = this.values.split(',')
    
    let vars = {}

    for (let i = 0; i < variableNames.length; i += 1) {
      const name = variableNames[i]
      let value = variableValues[i] || null

      if (value === null) {
        value = ''
      } else if (value === '{}') {
        value = {}
      } else if (value.match(NUMBER_REGEX)) {
        value = parseFloat(value)
      } else if (value.match(QUOTED_STRING_REGEX)) {
        value = value.substr(1, value.length - 2)
      } else if (value === 'true') {
        value = true
      } else if (value === 'false') {
        value = false
      } else {
        // console.error(`Values must be quoted for string, true, false or just be numbers. Index: ${i}. All values disabled to ensure no other follow-up errors are hidden.`)
        vars = {}
        break
      }

      vars[name] = value
    }

    return { vars }
  },
  render(createElement) { 
    return createElement('div', { 'class': 'ae-vars' }, this.$scopedSlots.default(this.vars))
  },
  staticRenderFns: [],
  methods: {
    ...editFunctions
  }
}
