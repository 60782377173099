import { debounce } from '../utils/debounce'

const handlers = []

export default {
  install(Vue, options) {
    const aeppic = options.aeppic    

    Vue.directive('responsive', { 
      bind(el: any, binding: any) {
        const device = aeppic.Device
        
        el.___resizeHandler = null
        el.___resizeObserver = null
        el.___resizeInterval = null

        const { MIN_MEDIUM_SIZE, MIN_LARGE_SIZE } = aeppic.ResponsiveSizes
        
        const debouncedUpdateHandler = debounce(aeOnResizeHandler, 250)
        const raF = window.requestAnimationFrame || (<any>window).webkitRequestAnimationFrame || function(cb) {setTimeout(cb, 10)}
        
        let pending = false

        function aeOnResizeHandler() {
          if (pending) {
            return
          }
          pending = true

          raF(() => {
            pending = false

            console.log('aeOnResizeHandler');

            const elementArea = el.getBoundingClientRect()
            let size

            if (elementArea.width >= MIN_LARGE_SIZE) {
              size = 'large'
            } else if (elementArea.width >= MIN_MEDIUM_SIZE) {
              size = 'medium'
            } else {
              size = 'small'
            }

            let className = `ae-${size}`

            if (!el.classList.contains(className)) {
              if (!el.classList.contains(className)) {
                el.classList.remove('ae-large', 'ae-medium', 'ae-small')
                el.classList.add(className)

                if (binding && binding.value && typeof binding.value === 'function') {
                  binding.value(className, size)
                }
              }
            }
          })
        }

        if ('ResizeObserver' in (window as any)) {
          el.___resizeObserver = new ResizeObserver(debouncedUpdateHandler)
          el.___resizeObserver.observe(el)
          debouncedUpdateHandler()
        }

        if (!el.___resizeObserver && device === 'Desktop') {
          el.___resizeHandler = debouncedUpdateHandler
          window.addEventListener('resize', debouncedUpdateHandler)
          el.___resizeInterval = setInterval(el.___resizeHandler, 550)
        }

        if (device === 'Phone') {
          el.classList.add('ae-small', 'ae-phone')
        } else {
          if (device === 'Desktop') {
            el.classList.add('ae-desktop')
          } else {
            el.classList.add('ae-tablet')
          }
        }
      },
      unbind(el: any) {
        if (typeof window !== 'undefined') {
          if (el.___resizeObserver) {
            el.___resizeObserver.disconnect()
          } 

          if (el.___resizeHandler) {
            window.removeEventListener('resize', el.___resizeHandler)
          }

          if (el.___resizeInterval) {
            clearInterval(el.___resizeInterval)
          }
        }
      }
    })
  }
}