import { Logger, SimpleConsoleLogger } from '../model/log.js';

let WARNED_ABOUT: Map<string, Set<string>> = null

export class Warn {
  private _log: Logger

  constructor(log: Logger = SimpleConsoleLogger) {
    this._log = log.child({ class: 'Warn' })
  }

  once(namespace: string, key: string, ...args: any[]) {
    if (!WARNED_ABOUT) {
      WARNED_ABOUT = new Map()
    }

    let set: Set<string> = WARNED_ABOUT.get(namespace)

    if (!set) {
      set = new Set()
      WARNED_ABOUT.set(namespace, set)
    }

    if (!set.has(key)) {
      set.add(key)
      this._log.warn.apply(this._log, args)
    }
  }
}