import { Logger } from '@aeppic/types'
import Aeppic from './aeppic.js'
import { lastOneWins } from '@aeppic/shared/async-calling-strategies'

export type FeatureMap = Map<string, FeatureSettings>

export type FeatureNamespace = {
  [featureName: string]: FeatureSettings
}

export type FeatureSettings = {
  enabled: boolean
  description?: string
  options?: any
  deprecated?: boolean
  deprecationNotice?: string
}

export type Options = {
  override?: { [key: string]: boolean }
  Logger: Logger
}

export class Features {
  private _log: Options['Logger']
  private _warned = new Set<string>()
  private _used = new Map<string, number>()
  private _enabled: Set<string>

  static async buildFromServer(fetch: typeof global.fetch, options: Options & { lookupUrl: string }) {
    const response = await fetch(options.lookupUrl)
    const features = await response.json()
    
    return new Features(features, options)
  }

  constructor(private _features: FeatureNamespace, options: Options) {
    if (!_features) {
      throw new Error('Argument exception')
    }
    
    this._log = options.Logger.child({ class: 'Features' })

    const enabledFeatures = Object.entries(_features)
      .filter(([name, settings]) => {
        const override = options.override?.[name]

        if (override != null) {
          return override === true
        }

        return settings.enabled === true
      })

    this._enabled = new Set(enabledFeatures.map(([name]) => name))
  }

  
  isEnabled(name: string) {
    return this._enabled.has(name)
  }

  getOptionsFor(name: string) {
    const feature = this._features[name]
    return feature?.options ?? {}
  }

  use(name: string, { warnOnly = false } = {}) {
    const feature = this._features[name]

    if (!feature) {
      this._warnAboutUnknownFeatureUsageOnce(name)
      this._log.warn('Using unconfigured feature %s.', name)
      return
    }

    const count = this._used.get(name) ?? 0
    this._used.set(name, count + 1)

    this._logUsageOnce(name)
    this._warnAboutDeprecationOnce(name, feature)

    const enabled = this._enabled.has(name)
    
    if (!enabled) {
      if (warnOnly) {
        this._log.warn('Feature %s is not enabled.', name)
      } else {
        this._log.error('Trying to use disabled feature %s.', name)
        throw new Error(`Cannot use feature ${name}. It is not enabled.`)
      }
    }
  }

  private _warnAboutUnknownFeatureUsageOnce(name: string) {
    if (!this._warned.has('unknown:' + name)) {
      this._log.warn(`Feature '${name}' is unknown.`)
      this._warned.add('deprecation:' + name)
    }
  }

  private _warnAboutDeprecationOnce(name: string, feature: FeatureSettings) {
    const isDeprecated = feature?.deprecated === true

    if (!isDeprecated) {
      return
    }

    if (!this._warned.has('deprecation:' + name)) {
      this._log.warn(`Feature ${name} is deprecated. ${feature.deprecationNotice ?? ''}`)
      this._warned.add('deprecation:' + name)
    }
  }

  private _logUsageOnce(name: string) {
    if (this._warned.has('usage:' + name)) {
      this._log.info('Using feature %s.', name)
      this._warned.add('usage:' + name)
    }
  }

  public list() {
    const stats: {
      [featureName: string]: {
        enabled,
        description: string
        used: number,
      }
    } = {}

    Object.entries(this._features).forEach(([name, settings]) => {
      stats[name] = {
        enabled: this._enabled.has(name),
        description: settings.description,
        used: this._used[name],
      }
    })

    return stats
  }

  public getDescription(name: string) {
    return this._features[name]?.description ?? ''
  }

  disable(name: string) {
    this._enabled.delete(name)
  }

  enable(name: string) {
    this._enabled.add(name)
  }
}