// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
export function storageAvailable(type) {
  if (typeof window === 'undefined') {
    return false
  }

  let storage

  try {
    storage = window[type]

    if (!storage) {
      return false
    }

    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)

    return true
  } catch (e) {
    return e instanceof DOMException && (
      // everything except Firefox
      e.code === 22 ||
      // Firefox
      e.code === 1014 ||
      // test name field too, because code might not be present
      // everything except Firefox
      e.name === 'QuotaExceededError' ||
      // Firefox
      e.name === 'NS_ERROR_DOM_QUOTA_REACHED' ) &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
  }
}

export class Storage {
  private _storage: typeof window.localStorage

  constructor(private _type) {
    if (storageAvailable(this._type)) {
      this._storage = <any>window[this._type]
    }
  }

  get isAvailable() {
    return !!this._storage
  }

  setValue(key, value) {
    if (typeof value === 'string') {
      this.setItem(key, value)
    } else {
      this.setItem(key, JSON.stringify({ value: value }))
    }
  }

  getValue(key) {
    const value = this.getItem(key)
    
    if (value && value[0] === '{') {
      return JSON.parse(value).value
    } else {
      return value
    }
  }

  setItem(key, value) {
    if (this._storage) {
      this._storage.setItem(key, value)
    }
  }

  getItem(key) {
    if (this._storage) {
      return this._storage.getItem(key)
    } else {
      return
    }
  }

  removeItem(key) {
    if (this._storage) {
      this._storage.removeItem(key)
    }
  }

  clear() {
    if (this._storage) {
      this._storage.clear()
    }
  }

  key(n) {
    if (this._storage) {
      return this._storage.key(n)
    } else {
      return null
    }
  }
}
