
import { compileStyle, loadStyle } from '../utils/css'

export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ae-root",attrs:{"data-ae-version":_vm.Aeppic.Version,"tabindex":"-1"}},[(_vm.Aeppic.Root.type === 'layout')?_c('ae-layout',{attrs:{"watch-layout-revisions":false,"layout-id":_vm.Aeppic.Root.layout.id,"params":_vm.Aeppic.Root.params}}):_vm._e()],1)},
  staticRenderFns: [],
  /* tslint:enable */
  
  inject: ['getAeppicContext'],
  data() {
    return {
      Aeppic: this.getAeppicContext('ae-root'),
      stopWatchingRoot: null,
      stopWatchingStylesheet: []
    }
  },
  beforeDestroy() {
    this.Aeppic.release()
  },
  async mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  
    await this.load()

    if (this.Aeppic.Root.params.watchRoot !== false) {
      this.stopWatchingRoot = this.watchRoot()
      this.watchStylesheets()
    }
  },
  methods: {
    watchRoot() {
      const watcher = this.Aeppic.watch('root', async (document) => {
        this.stopWatchingStylesheets()
        await this.load()
        this.watchStylesheets()
      })

      return watcher.stop
    },
    async watchStylesheets() {
      if (!this.Aeppic.flags.watchUIRevisions.enabled) {
        return
      }

      if (this.Aeppic.flags.disableStylesheets.enabled) {
        return
      }
      
      const root = await this.Aeppic.get('root')
      
      if (root && root.data.stylesheets) {
        for (const stylesheetRef of root.data.stylesheets) {
          const stop = this.watchStylesheet(stylesheetRef.id)
          this.stopWatchingStylesheet.push(stop)
        }
      }
    },
    watchStylesheet(stylesheetId) {
    
      const watcher = this.Aeppic.watchRevisions(stylesheetId, async (stylesheet) => {
        let css = await compileStyle(stylesheet.data.css, stylesheet.data.type)
        loadStyle(css, stylesheet.id, 'ae-root', stylesheet.data.type)
      })

      return watcher.stop
    },
    stopWatchingStylesheets() {
      let stop
      while (stop = this.stopWatchingStylesheet.shift()) {
        stop()
      }
    },
    async load() {
      const root = await this.Aeppic.get('root')

      // if (root.data.packages) {
      //   const packages = await this.aeppic.Packages.loadAll(root.data.packages)
      // }

      if (root && root.data.stylesheets) {
        let sortIndex = 0
        
        for (const stylesheetRef of root.data.stylesheets) {
          if (this.Aeppic.flags.disableStylesheets.enabled) {
            break
          }
          
          const stylesheet = await this.Aeppic.get(stylesheetRef)

          if (stylesheet) {

            let css = await compileStyle(stylesheet.data.css, stylesheet.data.type)
            loadStyle(css, stylesheet.id, 'ae-root', sortIndex)
          } else {
            console.warn('missing stylesheet', stylesheetRef)
          }

          sortIndex += 1
        }
      }
    }
  }
  // created() {
  //   console.log('root:created', this.Aeppic.root.type, this.Aeppic.root.layout.id)
  //   this.$watch('Aeppic.root.type', ()=>{
  //     console.log('Aeppic.root.type:modified', this.Aeppic.root.type, this.Aeppic.root.layout.id)
  //   })
  //    this.$watch('Aeppic.root.layout.id', ()=>{
  //     console.log('Aeppic.root.layout.id:modified', this.Aeppic.root.layout.id)
  //   })
  // }
}
