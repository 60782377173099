declare const zip: any

export default class ZipWriter {

  private _zipWriter

  constructor() {
  }

  public open() {
    const self = this

    return new Promise<void>((resolve, reject) => {
      zip.createWriter(new zip.BlobWriter(), (writer) => {
        self._zipWriter = writer
        resolve()
      }, reject)
    })
  }

  public close() {
    if (!this._zipWriter) {
      return
    }

    const self = this

    return new Promise((resolve, reject) => {
      self._zipWriter.close((blob) => {
        self._zipWriter = null
        resolve(blob)
      }, reject)
    })
  }

  public addText(fileName, text) {

    if (!this._zipWriter) {
      throw new Error('ZipWriter must be opened before adding content')
    }

    const self = this
    return new Promise<void>((resolve, reject) => {
      self._zipWriter.add(fileName, new zip.TextReader(text), () => {
        resolve()
      })
    })
  }

  public addBlob(fileName, blob) {

    if (!this._zipWriter) {
      throw new Error('ZipWriter must be opened before adding content')
    }

    const self = this
    return new Promise<void>((resolve, reject) => {
      self._zipWriter.add(fileName, new zip.BlobReader(blob), () => {
        resolve()
      })
    })
  }

  public addUrlContent(fileName, url) {

    if (!this._zipWriter) {
      throw new Error('ZipWriter must be opened before adding content')
    }

    const self = this
    return new Promise<void>((resolve, reject) => {
      self._zipWriter.add(fileName, new zip.HttpReader(url), () => {
        resolve()
      })
    })
  }
}
