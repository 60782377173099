import * as yaml from 'yaml'

export { marked as markdown } from 'marked' 
export { default as nextSemanticVersion } from './versioning.js'

export { yaml }

const VERSION = __PACKAGE_VERSION__
export { VERSION }

export { parse } from './parse.js'

export function validate() {
  return {}
}
