import { buildDeferred } from './defer.js'

export interface ExecuteFunction<T> {
  (): Promise<T>
}

const cacheGroups = new Map<string, Map<string, any|Promise<any>>>()

export function once<T>(group: string, id: string, fn: ExecuteFunction<T>): T|Promise<T> {
  let cacheGroup = cacheGroups.get(group) 

  if (!cacheGroup) {
    cacheGroup = new Map()
    cacheGroups.set(group, cacheGroup)
  }

  const existingPromiseOrResult = cacheGroup.get(id)

  if (existingPromiseOrResult) {
    // console.log('Reused existing promise for', group, id, existingPromise)
    return existingPromiseOrResult
  } else {
    const promiseReturnedByFunction = fn()

    cacheGroup.set(id, promiseReturnedByFunction)

    promiseReturnedByFunction.then((result) => {
      cacheGroup.set(id, result)
      return result
    }, () => {
      cacheGroup.delete(id)
    })

    return promiseReturnedByFunction
  }
}

export function clear(group: string) {
  cacheGroups.delete(group)
}

export class OnceCache<T, LookupParam> {
  private _cache = new Map<string, T|Promise<T>>()

  constructor(private _name: string, private _lookupFunction: (param: LookupParam) => Promise<T>) {
  }

  clear(prefix?: string) {
    if (!prefix) {
      this._cache.clear()
    } else {
      for (const key of this._cache.keys()) {
        if (key.startsWith(prefix)) {
          this._cache.delete(key)
        }
      }
    }
  }

  async get(key: string, param: LookupParam): Promise<T> {
    const entry = this._cache.get(key)

    if (entry || entry === null) {
      return entry
    }

    const deferred = buildDeferred<T>()
    this._cache.set(key, deferred.promise)

    try {
      const value: T = await this._lookupFunction(param)
      this._cache.set(key, value)
      deferred.resolve(value)
      return value
    } catch (error) {
      console.error('Could not access', key, 'in', this._name, error)
      this._cache.delete(key)
      deferred.resolve(null)
      return null
    }
  }
}