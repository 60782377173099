  export function* enumerateClassMethodsAndProperties(Class): Iterable<{name: string, descriptor: any}> {
    for (let [name, descriptor] of Object.entries((<any>Object).getOwnPropertyDescriptors(Class.prototype))) {
      if (name === 'constructor') {
        continue
      }

      if ((<any>descriptor).value && (<any>descriptor).value instanceof Function) {
        yield { name, descriptor }
      }

      if ((<any>descriptor).get || (<any>descriptor).set) {
        yield { name, descriptor }
      }
    }
  }
