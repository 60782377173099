import * as Types from '@aeppic/types'

import { runDynamicCode } from  '@aeppic/shared/dynamic/dynamic-code'

import { NamedExports } from '../packages/types.js'
import createControllerClass from  '../create-controller-class.js'

export interface ComponentDefinition {
  type: string
  name: string
  identifier: string
  packages: {
    modules: NamedExports
    components: NamedExports
  },
  document: Types.Document
  variablesToExpose: string // In addition to 'locals'
  globals?,
  componentSourceTemplate: string,
}

const BlockMap = new Map()
const CSS_BLOCK_MARKER = /_CSS|_BLOCK/g
const CSS_BLOCK_MARKER_LEGACY = /_BLOCK/g
const TEMPLATE_MARKER = /__TEMPLATE__/
const METHODS_MARKER = /__METHODS__/
const LOCALS_MARKER = /__NEW_LOCALS__/

const DESTROY_CONTROLLER_MARKER = /__DESTROY_CONTROLLER__/g

class EmptyControllerClass {}
class FailedControllerClass {
  private note = 'Placeholder controller due to compile error'
}

export class DynamicComponentBuilder {

  constructor(private componentDefinition: ComponentDefinition) {
  }

  public build() {
    const name = this.componentDefinition.name
    const identifier = this.componentDefinition.identifier
    const variablesToExpose = this.componentDefinition.variablesToExpose
    const packages = this.componentDefinition.packages
    
    const codeGroup = `ae-${this.componentDefinition.type}s`

    const sourceGenerator = new ComponentSourceGenerator(this.componentDefinition)
    const sources = sourceGenerator.generate()

    const parts: any = {
      css: sources.css
    }

    const globals = {
      packages: packages.modules
    }

    const definitionDocumentName = `${this.componentDefinition.type}Document`
    globals[definitionDocumentName] = this.componentDefinition.document

    let controllerClass = null

    if (sources.controller) {
      controllerClass = createControllerClass(`${codeGroup}/controllers`, name, sources.controller, variablesToExpose, globals)
      
      if (controllerClass == null) {
        controllerClass = FailedControllerClass
      }
    }

    if (controllerClass == null) {
      controllerClass = EmptyControllerClass
    }

    const componentGlobals = {
      components: packages.components,
      packages: packages.modules,
      controllerClass,
    }
    componentGlobals[definitionDocumentName] = this.componentDefinition.document
    
    parts.component = runDynamicCode(`${codeGroup}/components`, name, sources.component, componentGlobals)
    return parts
  }
}

class ComponentSourceGenerator {
  private BLOCK: string

  constructor(private componentDefinition: ComponentDefinition) {
    let blockId = BlockMap.get(componentDefinition.document.id)

    if (!blockId) {
      blockId = BlockMap.size + 1
      BlockMap.set(componentDefinition.document.id, blockId)
    }

    this.BLOCK = `ae-block-${blockId}`
  }

  generate() {
    let sources = {
      css: this.generateCss(),
      component: `return (${this.generateVueComponentSource()})`,
      controller: this.generateController(),
    }

    return sources
  }

  private generateCss() {
    const cssTemplate = (<string>this.componentDefinition.document.data.css)

    if (!cssTemplate || cssTemplate === '') {
      return null
    }

    if (cssTemplate.match(CSS_BLOCK_MARKER_LEGACY)) {
      // tslint:disable-next-line    
      console.warn('deprecated: CSS still contains legacy `_BLOCK` marker. Please replace with `_CSS`',  this.componentDefinition.document)
    }

    const cssPrefix = `/* ${this.componentDefinition.type} : ${this.componentDefinition.document.data.name} (${this.componentDefinition.document.id}@${this.componentDefinition.document.v}) */`
    return cssPrefix + '\n' + cssTemplate.replace(CSS_BLOCK_MARKER, this.BLOCK)
  }

  private generateController() {
    const controller = (<string>this.componentDefinition.document.data.controller) || ''

    if (controller.match(CSS_BLOCK_MARKER_LEGACY)) {
      // tslint:disable-next-line    
      console.warn('deprecated: Controller still contains legacy `_BLOCK` marker. Please replace with `_CSS`',  this.componentDefinition.document)
    }

    return controller.replace(CSS_BLOCK_MARKER, this.BLOCK)
  }

  /**
   * Generate source but ONLY of the `{...}` object itself, not
   * of the fluff around it like import statements or other
   * required content for creating a full source file.
   */
  private generateVueComponentSource() {
    const definitionDocument = this.componentDefinition.document
    const type = this.componentDefinition.type
    const cssClass = `ae-${type}`
    
    const innerTemplate = (<string>(definitionDocument.data.template)).replace(CSS_BLOCK_MARKER, this.BLOCK)    
    const fullControlTemplate = `<div class="${cssClass} ${this.BLOCK}" data-ae-${type}-id="${definitionDocument.id}" data-ae-${type}-v="${definitionDocument.v}">${innerTemplate}</div>`

    if (innerTemplate.match(CSS_BLOCK_MARKER_LEGACY)) {
      // tslint:disable-next-line    
      console.warn('deprecated: HTML Template still contains legacy `_BLOCK` marker. Please replace with `_CSS`',  this.componentDefinition.document)
    }

    const NEW_LOCALS = `Aeppic.createReactiveObjectProxy({})`

    const componentSource = (this.componentDefinition.componentSourceTemplate
      .replace(TEMPLATE_MARKER, '`' + fullControlTemplate + '`')
      .replace(METHODS_MARKER, DOMIdMethodTemplate)
      .replace(LOCALS_MARKER, NEW_LOCALS)
      .replace(DESTROY_CONTROLLER_MARKER, destroyControllerTemplate)
    )
      
    return componentSource
  }
}

const destroyControllerTemplate = 
`if (this.controller && this.controller.destroy) {
  try {
    this.controller.destroy()
  } catch (error) {
    console.error('Error destroying controller', error)
  }
}

this.controller = null
this.locals = null
`

const DOMIdMethodTemplate = 
`id(uniqueNameInsideScope) {
  if (!this.aeDOMIds) {
    this.aeDOMIds = new Map()
  }
  let id = this.aeDOMIds.get(uniqueNameInsideScope)
  if (!id) {
    id = this.Aeppic.newDOMId()
    this.aeDOMIds.set(uniqueNameInsideScope, id)
  }
  return id
}
`
