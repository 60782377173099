import { createApp } from './app.js'

import Aeppic from 'aeppic'
import { uuid } from 'aeppic'

function merge(target, defaults = {}) {
  for (const key of Object.keys(defaults)) {
    if (key in target) {
      continue
    } else {
      target[key] = defaults[key]
    }
  }
  return target
}

const consoleLogFunction = function(level, logFunctionName) {
  const consoleLogFunctionName = logFunctionName || level

  return (...args) => {
    const defaultParams = merge({ level, at: Date.now() }, this?.defaultParams ?? {})

    const [paramsObject, messageTemplate, params] = 
      (typeof args[0] === 'string')   ? [ defaultParams,                 args[0], args.slice(1) ]
                                      : [ merge(args[0], defaultParams), args[1], args.slice(2) ]

    const formattedMessage = format(messageTemplate, params)

    console[consoleLogFunctionName](`${level.toUpperCase()}: ${formattedMessage}`, paramsObject)
  }    
}

function format(template, ...params) {
  const replacer = /(%[ds]{1})/g
  const parts = []
  
  let   matchInfo 
  let   position = 0

  while ((matchInfo = replacer.exec(template)) !== null) {
    const section = template.substring(position, matchInfo.index)
    position = replacer.lastIndex

    parts.push(section)
    const param = params.shift()

    if (param != null) {
      parts.push(param)
    } else if (param === null) {
      parts.push('<null>')
    } else {
      parts.push('<undefined>')
    }
  }

  if (replacer.lastIndex >= 0) {
    parts.push(template.substring(position))
  }

  if (parts.length === 0) {
    return template
  }

  return parts.join('').trim()
}

const Logger = {
  defaultParams: {},
  trace: consoleLogFunction('trace'),
  debug: consoleLogFunction('debug'),
  info: consoleLogFunction('info'),
  warn: consoleLogFunction('warn'),
  error: consoleLogFunction('error'),
  fatal: consoleLogFunction('fatal', 'error'),
  child(childParams) {
    return merge({ defaultParams: merge(childParams, this.defaultParams) }, this)
  },
}

export function init({ Aeppic, uuid }) {
  const options = {
    url: window.location.href,
    el: '#app',
    boot: {
      src: '/api/docs/_boot',
    },
    server: '/api',
    removeAfterRender: '#ae-site-loading',
    searchAllFields: false,
    vueComponentOptions,
    flags: {
      logServerLoads: false,
      logQueryRefresh: false,
    },
    Logger
  }

  const { app, aeppic } = createApp({ Aeppic, uuid }, options)

  // Install aeppic globally
  // window.Aeppic = aeppic.contextify('global')

  // prime the store with server-initialized state.
  // the state is determined during SSR and inlined in the page markup.
  if (window.__INITIAL_STATE__) {
    // store.replaceState(window.__INITIAL_STATE__)
  }
}

const vueComponentOptions = {
  aeIcon: {
    baseIconPath: 'libs/fontawesome-pro-5.15.4-web/svgs',
    baseClassToUrlExtension: {
      fa: 'solid',
      far: 'regular',
      fas: 'solid',
      fal: 'light',
      fab: 'brands',
      fad: 'duotone',
    },
    noIconNames: [
      'xs',
      'sm',
      'lg',
      '2x',
      '3x',
      '4x',
      '5x',
      '6x',
      '7x',
      '8x',
      '9x',
      '10x',
      'fw',
      'border',
      'pull-right',
      'pull-left',
      'spin',
      'pulse',
      'inverse',
      'w-1',
      'w-2',
      'w-3',
      'w-4',
      'w-5',
      'w-6',
      'w-7',
      'w-8',
      'w-9',
      'w-10',
      'w-11',
      'w-12',
      'w-13',
      'w-14',
      'w-15',
      'w-16',
      'w-17',
      'w-18',
      'w-19',
      'w-20',
    ]
  }
}

init({ Aeppic, uuid })
