export async function getCastingShare(aeppic, routeMatch) {
  if (routeMatch.params && routeMatch.params.sharedCastingId) {
    const id = normalizeId(routeMatch.params.sharedCastingId)

    return findShare(aeppic, id)
  }
}

export async function getModelShare(aeppic, routeMatch) {
  if (routeMatch.params && routeMatch.params.sharedModelId) {
    const id = normalizeId(routeMatch.params.sharedModelId)

    return findShare(aeppic, id)
  }
}

function normalizeId(id) {
  return id.trim()
}

async function findShare(aeppic, id) {
  const sharedDocument = await aeppic.find(`f.id:804bed89-803a-4090-8b1b-857cab8ec6dd data.identifier:${id}`)

  if (sharedDocument && sharedDocument[0]) {
    // console.log('Found shared document', sharedDocument[0])
    return sharedDocument[0]
  } else {
    // console.error(`no share found for '${id}'`)
  }
}
