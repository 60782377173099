import * as Types from '@aeppic/types'

import uuid from '@aeppic/shared/uuid'

import IGenericResource from './resource'
import { Context } from './contextify'
import { AeppicInterface } from './aeppic.js'

export class Content implements IGenericResource {
  private _objectUrlCache: Map<string, any> = new Map()
  
  constructor(private _aeppic: AeppicInterface, private _apiPrefix: string, private _urlToDataCache:  Map<string, any>) {}

  getSrc(document: Types.Document, fieldName: string, options: { downloadFileName?: string, noCache?: boolean } = {}): string {
    return this._getSrcFromDocument(document, fieldName, 'dataUrl', options)
  }

  _getSrcFromDocument(document: Types.Document, fieldName: string, urlName, { downloadFileName, noCache }: { downloadFileName?: string, noCache?: boolean } = {}): string {
    if (typeof arguments[3] === 'string') {
      console.error('getSrc signature has changed')
    }

    const dataUrl = this._url(document, fieldName, urlName)

    if ('string' === typeof dataUrl) {
      if (dataUrl.startsWith('aeppic') || dataUrl.startsWith('store://')) {
        const cachedContent = this._objectUrlCache.get(dataUrl)
        const dataCache = this._urlToDataCache.get(dataUrl)

        const fieldType = this._fieldType(document, fieldName)

        if (!noCache && dataCache && 'string' === typeof fieldType && (fieldType.startsWith('image') || fieldType.startsWith('application/pdf'))) {
          if ('string' === typeof cachedContent && (cachedContent.startsWith('data:') || cachedContent.startsWith('blob:'))) {
            return cachedContent
          }

          if ('string' === typeof dataCache && (dataCache.startsWith('data:') || dataCache.startsWith('blob:'))) {
            this._objectUrlCache.set(dataUrl, dataCache)
            return dataCache
          }

          const objectURL = URL.createObjectURL(dataCache)
          this._objectUrlCache.set(dataUrl, objectURL)
          return objectURL
        }

        return this._getUrl(document, fieldName, urlName, { noCache, downloadFileName })
      }

      return dataUrl
    }
  }
  getUrl(document: Types.Document, fieldName: string, options: { noCache?: boolean } = {}): string {
    return this._getUrl(document, fieldName, 'dataUrl', options )
  }

  _getUrl(document: Types.Document, fieldName: string, urlName: string, { noCache, downloadFileName }: { downloadFileName?: string, noCache?: boolean } = {}): string {
    const url = this._dataUrl(document, fieldName)

    if ('string' === typeof url) {
      if (url.startsWith('aeppic') || url.startsWith('store://')) {
        return `${this._apiPrefix}/docs/${document.id}/fields/${fieldName}/_content${downloadFileName ?  '/' + downloadFileName : '' }?version=${document.v}&${this._urlAsQueryParam(url)}${noCache ? '&cache_burst=' + uuid() : ''}`
      }
    }
  }

  getThumbnailSrc(document: Types.Document, fieldName: string, options: { height?: string, width?: string } = { height: '', width: ''}): string {
    const thumbnailUrl = this._getSrcFromDocument(document, fieldName, 'thumbnailUrl')

    if (thumbnailUrl) {
      return thumbnailUrl
    }

    const type = this._fieldType(document, fieldName)

    if (!type) {
      return null
    }

    const dataUrl = this._dataUrl(document, fieldName)

    return `${this._apiPrefix}/services/5af240ae-f20f-4554-becd-6a83c8e7ec52/thumbnail/${document.id}?version=${document.v}&fieldName=${encodeURIComponent(fieldName)}&dataUrl=${encodeURIComponent(dataUrl)}&width=${options.width}&height=${options.height}`
  }

  getPreviewSrc(document: Types.Document, fieldName: string, options: { downloadFileName?: string, format?: 'pdf'|'jpg', firstPage?: number, lastPage?: number } = { format: 'pdf' } ) {
    const fieldType = this._fieldType(document, fieldName)
    const expectedType = options.format === 'pdf' ? 'application/pdf' : 'image/'

    if (typeof fieldType === 'string' && fieldType.startsWith(expectedType)) {
      return this.getSrc(document, fieldName, { downloadFileName: options.downloadFileName })
    }
    
    return this._converterUrl(document, fieldName, options.format, { downloadFileName: options.downloadFileName, firstPage: options.firstPage, lastPage: options.lastPage })
  }

  getPdfUrl(document: Types.Document, fieldName: string, options: { downloadFileName?: string, firstPage?: number, lastPage?: number } = {}) {
    const downloadFileName = typeof options === 'string' ? options : options.downloadFileName

    if (typeof options === 'string') {
      console.error('getPdfUrl signature has changed')
    }

    const fieldType = this._fieldType(document, fieldName)

    if (typeof fieldType === 'string' && fieldType.startsWith('application/pdf')) {
      return this.getSrc(document, fieldName, { downloadFileName })
    }
    
    return this._converterUrl(document, fieldName, 'pdf', { downloadFileName, firstPage: options.firstPage, lastPage: options.lastPage })
  }

  async fetch(document: Types.Document, fieldName: string) {
    const url = this.getSrc(document, fieldName)
    return this._aeppic.fetch(url)
  }

  async getStream(document: Types.Document, fieldName: string) {
    const response = await this.fetch(document, fieldName)

    if (response.ok) {
      return response.body
    }
  }

  async getBuffer(document: Types.Document, fieldName: string) {
    const response = await this.fetch(document, fieldName)

    if (response.ok) {
      if ('arrayBuffer' in response) {
        return response.arrayBuffer()
      } else if ('buffer' in response) {
        return (response as any).buffer()
      } else {
        throw new Error('Response does not have arrayBuffer or buffer method')
      }
    }
  }
  
  _converterUrl(document: Types.Document, fieldName: string, toFormat: 'pdf'|'jpg', options: { downloadFileName?: string, firstPage?: number, lastPage?: number } = {}) {
    const lastPageParam = options.lastPage ? `&${options.lastPage}` : ''
    const firstPageParam = options.firstPage ? `&${options.firstPage}` : ''
    const nameParam = options.downloadFileName ? `&${options.downloadFileName}` : ''

    return `${this._apiPrefix}/docs/${document.id}/fields/${fieldName}/convert?${this._urlAsQueryParam(this._dataUrl(document, fieldName))}&version=${document.v}&format=${toFormat}${firstPageParam}${lastPageParam}${nameParam}`
  }

  _dataUrl(document: Types.Document, fieldName: string) {
    return this._url(document, fieldName, 'dataUrl')
  }

  _url(document: Types.Document, fieldName: string, urlName: string) {
    const field: any = document && document.data && document.data[fieldName]

    if (field && urlName in field) {
      return field[urlName]
    }

    return null
  }
  
  _urlAsQueryParam(url: string|null): string {
    if (typeof url === 'string') {
      return `dataUrl=${encodeURIComponent(url)}`
    }
    return ''
  }
  
  _fieldType(document: Types.Document, fieldName: string) {
    const field = <any>document.data[fieldName]

    if (field && 'type' in field) {
      return field.type
    }
    return null
  }

  release() {}
}
