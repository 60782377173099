import * as Types from '@aeppic/types'
import { Logger, InMemoryLog } from '../model/log.js'
import { runActions } from '../dynamic/run-action.js'
import type { AeppicInterface } from './aeppic.js'
import type { ActionResult } from '../dynamic/run-action.js'

const MAX_ACTIONS_LOG_SIZE = 100

export interface ActionContext {
  [key: string]: any
}

export class Actions {
  private _log: Logger
  private _inMemoryLog: InMemoryLog

  constructor(private _aeppic: AeppicInterface, options: { log?: Logger } = {}) {
    if (options.log) {
      this._log = options.log.child({ scope: 'Action' })
    } else {
      this._inMemoryLog = new InMemoryLog({ size: MAX_ACTIONS_LOG_SIZE, defaults: { scope: 'Action' } })
      this._log = this._inMemoryLog.child({ source: 'Action', built: Date.now() })
    }
  }

  // get RecentLog() {
  //   return this._log
  // }

  async run(actionIdentifiers: (Types.IdOrReference|Types.Document)[], context: ActionContext): Promise<ActionResult[]> {
    const actions = await this._aeppic.getAll(actionIdentifiers)
    const actionLogger = this._log.child({ source: 'Action', started: Date.now() })
    
    const results = await runActions(actions, {
      Aeppic: this._aeppic,
      context,
      log: actionLogger
    }, { Logger: actionLogger })

    return results
  }
}
