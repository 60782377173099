/**
 * @param params Strings to check
 * @returns First non empty string (trimmed) or undefined
 */
export function getFirstNonEmptyString(...params: string[]) {
  for (const string of params) {
    if (string && string.trim().length > 0) {
      return string
    }
  }
}