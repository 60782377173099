export class SerialPromiseExecutor {
  private _runningExecution = null
  private _nextPromiseCallback = null

  /**
   * **Overwrites** the next promise to be executed.
   * Already running promise will be awaited.
   * @param promiseCallback: Callback to execute next
   */
  public async runNext(promiseCallback: () => Promise<any> ) {
    if (this._runningExecution) {
      this._nextPromiseCallback = promiseCallback
    } else {
      this._runningExecution = this._execute(promiseCallback)
    }

    return this._runningExecution
  }

  private async _execute(promiseCallback) {
    const executor = await new Promise(async (resolve, reject) => {
      try {
        const result = await promiseCallback()
        resolve(result)
      } catch (error) {
        reject(error)
      } finally {
        this._runningExecution = null
      }
    })

    if (this._nextPromiseCallback) {
      this._runningExecution = this._execute(this._nextPromiseCallback)

      this._nextPromiseCallback = null
    }

    return executor
  }
}
