import * as Types from '@aeppic/types'
import { markdown, parse as formdown } from '@aeppic/forms-parser'

import safeStringify from 'fast-safe-stringify'
import FileSaver from 'file-saver'
import anime from 'animejs'

import { DateTime, Duration, Interval, Info as LuxonInfo, Settings as DateTimeSettings } from 'luxon'
import * as Luxon from 'luxon'

import uuid from '@aeppic/shared/uuid'
import { waitForNextTick, waitForNextMicroTick } from '@aeppic/shared/wait-for-tick'
import { UniqueItemProcessingQueue } from '@aeppic/shared/processing-queue'
import { setTimeoutNoKeepAlive } from '@aeppic/shared/timer'
import { buildDeferred } from '@aeppic/shared/defer'
import { deprecated } from '@aeppic/shared/deprecation'
import { EventEmitter } from '@aeppic/shared/event-emitter'
import { default as DirectCache } from '@aeppic/shared/cache'
import { formatToHumanReadableWithLuxon } from '@aeppic/shared/date-formatting'
import { lastOneWins } from '@aeppic/shared/async-calling-strategies.js'

import Vue from '../externals/vue.js'

import { IServerAdapter, IDocumentLookup } from '../shared/types/server-adapter.js'
import Popup from './popup.js'
import { debounce } from './utils/debounce.js'
import { isReference, isDocument, isStamp } from '../model/is.js'
import { resolveDocumentPath } from '../model/resolve-path.js'
import ZipWriter from './utils/zip-writer.js'
import { readJsonlObjects } from './utils/jsonl-reader.js'
import { Cache } from './cache/index.js'
import { Offline } from './offline.js'

import Changes from './changes.js'
import Translator from './utils/translator.js'
import Preferences from './utils/preferences.js'
import Developer from './utils/developer.js'
import History from './utils/history.js'
import { compareDocumentTrees, compareDocumentSubTrees, DefaultDocumentAdapter, DifferenceNode } from './utils/compare-tree.js'
import buildCompleteSearchString from './utils/build-query-string.js'
import sort from './utils/sort.js'

import resolveField from '../model/query/resolve-field.js'
import deriveChildId from './utils/derive-child-id.js'

import { Logger, buildLogger } from '../model/log.js'

import { Content } from './content.js'
import { Storage } from './storage.js'

import { IQuerySubscription, QuerySubscription } from './query.js'
import { Commands, ICommands } from './commands/index.js'

import type {
  ModelOptions,
  CloneOptions,
  NewDocumentOptions,
  ChangeDocumentFormOptions,
  IDocumentWatcher,
  DocumentWatchCallback,
  DocumentsWatchCallback,
  IQueryWatcher,
  DocumentLookupCallback,
  ImportOperation,
  ImportResult,
  FindOptions,
  FieldSortInfo,
  FileField,
  StampOptions,
  Write,
} from '../model'

import {
  default as Model,
  ChangeType,
  Form,
  Change,
  EditableDocument,
  importIntoModel,
  parseDateRange,
  getDocumentId,
  isAdmin,
  Query,
  toQueryAndOptions,
  Feature as ModelFeature
} from '../model'

export { Write }

import Release from './release.js'
import RootComponent from './components/ae-root.js'

import LayoutComponent from './components/ae-layout.js'
import LayoutSelectorComponent from './components/ae-layout-selector.js'
import DesignComponent from './components/ae-design.js'
import DesignSelectorComponent from './components/ae-design-selector.js'
import ListComponent from './components/ae-list.js'
import ListSelectorComponent from './components/ae-list-selector.js'
import QueryComponent from './components/ae-query.js'
import VarsComponent from './components/ae-vars.js'
import ControlComponent from './components/ae-control.js'
import ControlSelectorComponent from './components/ae-control-selector.js'
import FormComponent from './components/ae-form.js'
import FormSectionComponent from './components/ae-form-section.js'
import FormSectionNextComponent from './components/ae-form-section-next.js'
import FormParagraphComponent from './components/ae-form-paragraph.js'
import FormFieldComponent from './components/ae-form-field.js'
import FormPreviewComponent from './components/ae-form-preview.js'
import HeaderComponent from './components/ae-header.js'
import LinkComponent from './components/ae-link.js'
import SplitterComponent from './components/ae-splitter.js'
import TabsComponent from './components/ae-tabs.js'
import ExpandableComponent from './components/ae-expandable.js'
import PopupComponent from './components/ae-popup.js'
import SelectComponent from './components/ae-select.js'
import DialogComponent from './components/ae-dialog.js'
import IconComponent from './components/ae-icon.js'
import FetchComponent from './components/ae-fetch.js'

import DragAndDropExtension from './directives/v-drag-and-drop.js'
import FocusDirective from './directives/v-focus.js'
import ResponsiveExtension from './directives/v-responsive.js'
import TranslateExtension from './directives/v-translate.js'
import VisibilityDirective from './directives/v-observe-visibility.js'

import { loadStyle, unloadStyle } from './utils/css.js'
// import { loadScript, unloadScript, Group as ScriptGroup } from './utils/script.js'
import ReactiveObjectProxy from '../shared/reactive-object-proxy.js'
import { Context, contextify } from './contextify/index.js'

import { Packages } from './packages/packages.js'
import type { Options as PackagesOptions } from './packages/packages.js'
import { FeatureNamespace, Features } from './features.js'

import { Uploads, IUploadJobRequest } from './uploads/index.js'

import { ServerAdapter } from './server-adapter.js'
import { IRouter, RouteParams } from './router.js'
import { AccessRightCache } from './access-cache.js'
import { Designs } from './dynamic/design-lookup.js'
import { QueryScopes, Scopes } from '../model/query/fluent.js'
import { MatchOptions, isQueryOfPartialDocuments } from '../model/query/index.js'
import { CommandAeppicInterface } from './commands/executor.js'
import { Actions } from './actions.js'
import { Warn } from './warn.js'
import { GetOptions } from '../model/index.js'
import { enableProxyMode } from '../model/editable-document.js'

const MOBILE_DETECT_UA = /Mobile/
const PAD_DETECT_UA = /iPad/
const PHONE_DETECT_UA = /iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/

const MIN_MEDIUM_SIZE = 600
const MIN_LARGE_SIZE = 1280
const MAX_DATA_URL_LENGTH = 128

const DEFAULT_MIN_REFRESH_DELAY_IN_MS = 1000

const WELL_KNOWN_CLASSES = {
  /**
   * ExpandRegion marks a DOM node which
   * is the top-level DOM node that will become the
   * container for any expanded element inside it
   */
  ExpandRegion: 'ae-expand-region',
}

declare const TextEncoder: any
declare const TextDecoder: any

type FlagSettings = {
  enabled?: boolean,
  options?: object,
}

/**
 * @public
 */
export interface Flag extends FlagSettings {
  enabled: boolean,
  title?: string,
  description?: string,
  options?: object,
}

/**
 * @public
 */
export interface Flags {
  watchUIRevisions?: Flag
  discoverElements?: Flag
  disableStylesheets?: Flag
  logServerLoads?: Flag
  logQueryRefresh?: Flag
  cacheControls?: Flag
}

type FlagNames = keyof Flags

/**
 * @public
 */
export type FlagStatus = {
  [key in FlagNames]?: boolean | FlagSettings
}

const DEFAULT_FLAGS: Flags = {
  watchUIRevisions: {
    title: 'Watch UI Revisions',
    enabled: true,
    description: 'When enabled all designs/layouts/controls/lists/forms will update while live while being edited',
  },
  discoverElements: {
    title: 'Discover Elements',
    enabled: true,
    description: 'Pressing CTRL-SHIFT while moving mouse discovers the element hierarchy',
  },
  disableStylesheets: {
    title: 'Disable Stylesheets',
    enabled: false,
    description: 'Don\'t load or render stylesheets',
  },
  logServerLoads: {
    title: 'Log all server data load operations',
    enabled: false,
    description: 'Add a log entry whenever Aeppic needs to load data from the server'
  },
  logQueryRefresh: {
    title: 'Log all query subscription refreshes',
    enabled: false,
    description: 'Add a log entry whenever Aeppic refreshes local query subscriptions'
  },
  cacheControls: {
    title: 'Cache Control lookups',
    enabled: true,
    description: 'Cache Control lookups'
  }
}

const NO_AUTH = { loggedIn: false, accountId: null, backedBy: null, impersonatedBy: null, groupAccountId: null, profile: null, info: null }

export interface DocumentReadStream {
  read(): Types.Document
}

export interface DocumentSourceFunction {
  (): DocumentReadStream | Promise<DocumentReadStream>
}

export interface VueComponentOptions {
  aeIcon: AeIconOptions
}

export interface AeIconOptions {
  baseIconPath: string
  baseClassToUrlExtension: Object
  noIconNames: Array<string>
}

//#region FileEditEvent
type LocalFilePath = string

interface FileChangeEvent {
  type: 'files:changed'
  document: Types.Reference
  fieldName: string
  localFilePath: LocalFilePath
  editInfo: DocumentEditInfo
  detectedAt: number
}

interface FileUploadEvent {
  type: 'upload:started' | 'upload:canceling' | 'upload:canceled' | 'upload:finished' | 'upload:failed'
  document: Types.Reference
  fieldName: string
  localFilePath: LocalFilePath
  uploadInfo: DocumentUploadInfo
  detectedAt: number
}

type FileEditEvent = FileChangeEvent | FileUploadEvent
//#endregion FileEditEvent

interface DocumentUploadInfo {
  document: Types.Reference
  fieldName: string
  uploadKey: string
  filePath: string
  targetUrl: string 
  totalTimeout: Duration
  uploadFileFieldName: string
  accessToken: string
  fields: {
    [fieldName: string]: string
  }
}


interface DocumentEditInfo {
  document: Types.Reference
  fieldName: string
  url: string
  uploadUrl: string
  uploadFileFieldName: string
  mimeType: string
  fields: {
    [fieldName: string]: string
  },
  explicitFieldName: string
  accessToken: string
}

interface ClientEditInfo {
  event: 'files:edit'
  payload:
    {
      Upload:
        {
          Started: [LocalFilePath, DocumentUploadInfo]
        }
        |
        {
          Canceling: [LocalFilePath, DocumentUploadInfo]
        }
        |
        {
          Canceled: [LocalFilePath, DocumentUploadInfo]
        }
        |
        {
          Finished: [LocalFilePath, DocumentUploadInfo]
        }
        |
        {
          Failed: [LocalFilePath, DocumentUploadInfo]
        }
    }
    |
    {
      Changed: [LocalFilePath, DocumentEditInfo]
    }
}

//#region AeppicClientHostInterface
interface AeppicClientHostInterface {
  type: string,
  version: string,
  matches: (semver: string) => boolean,
  features: {
    [featureGroupName: string]: {
      [featureName: string]: boolean
    }
  },
  files: {
    openFile(
      url: string,
      fileName: string,
      documentInformation: {
        id: string,
        v: string,
        fieldName: string,
        mimeType: string,
      }): void,
    editFile(
      url: string,
      fileName: string,
      documentInformation: {
        id: string,
        v: string,
        fieldName: string,
        mimeType: string,
        uploadFormFields?: {
          [fieldName: string]: string
        },
        uploadFileFieldName?: string,
        uploadUrl?: string,
      }): void,
    subscribeToEditEvents(callback: (editInfo: ClientEditInfo) => void): () => void,
  }
}
//#endregion AeppicClientHostInterface

export type DEVICE = 'Phone' | 'Tablet' | 'Desktop'
export type RESPONSIVE_LEVELS = 'Small' | 'Medium' | 'Large'

const DEFAULT_MAXIMUM_CONCURRENT_LOOKUPS = 5

export interface Options {
  /**
   * Element or query-selector to define
   * the root mount point.
   *
   */
  el?: string | HTMLElement,

  /**
   * Address of the Server API Endpoint. Defaults to /api
   */
  server?: string

  /**
   * User Agent override. If not provided the it will be read
   * from the window 
   */
  userAgent?: string

  /**
   * device informs designs/layouts what device to render
   * for. This is automatically extracted from the userAgent by
   * default
   */
  device?: DEVICE

  /**
   * Optionally override the fetch function to use
   * will fall-back to the global fetch function if not
   * defined
   *
   */
  fetch?: (input: RequestInfo, init?: RequestInit) => Promise<Response>

  /**
   * Optionally override the WebSocket implementation to use
   * will fall-back to the global WebSocket if not
   * defined
   *
   */
  WebSocket?: typeof WebSocket

  /**
   * Defaults to true and will call /api/auth/info to verify login status
   */
  auth?: boolean | { username: string, password: string }

  /**
   * Documents specified in here are loaded into the model
   * before the loaded callback is called and the view model
   * is instantiated
   *
   *    */
  boot?: LoadOptions,

  /**
   * Documents specified in here are loaded after the loaded
   * callback is called and happens in the background.
   *
   * If a document is requested after boot that is not yet available
   * the documents specified here are waited for
   *
   *    */
  load?: LoadOptions,

  /**
   * Callback when linking to the dom and importing
   * documents defined in `boot` is completed
   *
   */
  booted?: (Aeppic) => void,

  /**
   * Callback when linking to the dom and importing
   * documents defined in `load` is completed
   *
   */
  loaded?: (Aeppic) => void,

  /**
   * The persistence adapter is used to retrieve documents that are required (parents, forms)
   * or requested via get / search.
   *
   * TODO: Will be used instead of `documentLookup`
   *
   *    */
  persistenceAdapter?: IServerAdapter

  /**
   * When documents are imported, for example as part of boot or documents specified
   * through these options, it is possible that the dataset provided is not
   * complete and more data is required before being able to fully import a document.
   *
   * This can either be a missing parent or a missing form version and is resolved
   * using this callback. The callback expects at least the specified data, but it
   * is ok to return more. Eg. all ancestors instead of just the immediate parent.
   *
   * Usually this is not required, since it defaults to either waiting
   * for the specified `documents` to be completely loaded or makes use of the
   * provided `persistenceAdapter` to fetch more information.
   *
   *    */
  documentLookup?: DocumentLookupCallback

  /**
   * Instance of PackageLoader to use for loading source files of packages.
   * Will fallback to a default loader if not defined.
   *
   *    */
  packageLoader?: any // TODO: IPackageLoader

  /**
   *
   */
  flags?: FlagStatus

  /**
   * After load is completed remove the DOM element selected by this
   */
  removeAfterBoot?: HTMLElement | string

  /**
   * After load is completed remove the DOM element selected by this
   */
  removeAfterLoad?: HTMLElement | string

  /**
   * Remove the specified DOM element the first time renderLayout is finished
   */
  removeAfterRender?: HTMLElement | string


  /**
   * DateTime behavior
   */
  dontThrowOnInvalidDateTime?: boolean

  /** 
   * 
   */
  concurrentUploadLimit?: number

  /**
   * Do NOT automatically connect to server API and sync changes
   */
  noConnect?: boolean

  /**
   * Tell Model to set timestamps on new documents or changed documents (`document.t`).
   * 
   * This should be 
   */
  setTimestamps?: boolean

  defaultPageLimit?: number,

  maximumConcurrentLookupCalls?: number,

  searchAllFields?: boolean,

  vueComponentOptions?: VueComponentOptions
  Logger?: Logger

  /*
   * A prefix to use to find packages or a function to return an url to get a file from
   * 
   * In case of a string its the prefix to use which defaults to `/api/packages` and gets
   * combined with /${package.id}@${package.v}/files/` and the file path relative
   * to the package.
   * 
   * In case of a function it 
   * 
   */
  packagesLookupUrl?: PackagesOptions['packagesLookupUrl']

  features?: FeatureNamespace

  /**
   * Usually an url path under the api. 
   */
  featuresLookupPath?: string
}


/**
 * Data to govern loading of static data.
 *
 * If a cacheIdentifier is specified the src is only
 * used when no previous matching cache identifier
 * is available.
 */
export interface LoadOptions {
  cacheIdentifier?: string,
  maxAgeInSeconds?: number
  src?: string | DocumentSourceFunction,
  transform?: (event: any) => Promise<any>
}

export interface LayoutRenderOptions {
  /**
   * Custom parameters of any shape i.e { paramA: 'test' } that
   * can be bound to from the render template
   *
   *    */
  params: object
  noWait?: boolean
}

const DEFAULT_LAYOUT_OPTIONS: LayoutRenderOptions = {
  params: {}
}

export interface DrillIntoOptions {
  source?: HTMLElement
}

const DOMEventSubscriberIdentifierKey = (typeof Symbol === 'undefined') ? '__ae_event_handler_id' : Symbol('Event Subscriber Identifier')

const DOMEvents = {
  WillNavigate: 'ae-will-navigate',
  DrillInto: 'ae-drill-into',
  Navigated: 'ae-navigated',
}

export { DOMEvents }

export interface ModelExport {
  name: string,
  extension: string,
  data: string | Blob | Buffer,
}

export interface ModelExportOptions {
  json?: 'lines' | 'array',
  format?: 'text' | 'blob' | 'buffer',
  rootDocumentId?: string,
  rootDocumentIds?: string[],
  compress?: boolean,
  includeUnusedForms?: boolean,
  includeBinaryContent?: boolean,
  transportManifestId?: string
}

const DEFAULT_MODEL_EXPORT_OPTIONS: ModelExportOptions = {
  json: 'lines',
  format: 'blob',
  rootDocumentId: 'root',
  rootDocumentIds: [],
  compress: false,
  includeUnusedForms: false,
  includeBinaryContent: false,
  transportManifestId: null
}


export interface ModelDownloadOptions {
  json?: 'lines' | 'array',
  filename?: string,
  extension?: string,
  rootDocumentId?: string,
  rootDocumentIds?: string[],
  compress?: boolean,
  includeUnusedForms?: boolean,
  includeBinaryContent?: boolean,
  transportManifestId?: string

}

const DEFAULT_MODEL_DOWNLOAD_OPTIONS: ModelDownloadOptions = {
  json: 'lines',
  filename: '',
  rootDocumentId: 'root',
  rootDocumentIds: [],
  compress: true,
  includeUnusedForms: true,
  includeBinaryContent: false,
  transportManifestId: null
}

const CSS_BLOCK_MARKER = /_CSS/g

const DEFAULT_OPTIONS: Options = {
  auth: true,
  featuresLookupPath: '/v4/features/namespaces/aeppic',
}

const DOCUMENT_CACHE_SIZE = 500
const DOCUMENT_CACHE_EXPIRE = 60

type QueryCacheEntry = {
  expires?: number
}

interface AsyncFunction {
  (): Promise<void>
}

enum ChangeInspectionResult {
  Skip = 0,
  RequiresEvictionWithPotentialRefresh = 1,
  Import = 2,
  RefreshQueries = 4,
  HardDelete = 8,
  ImportAfterRightsCheck = 16,
  ImportAndRefreshQueries = 6,
}

export default class Aeppic {
  private _id: string = uuid()

  private _model: Model = null
  private _documentCache: DirectCache<Types.Document> = null
  private _activeModelQueries = new Map<string, Promise<Types.Document[]>>()
  private _activeServerQueries = new Map<string, Promise<Types.Document[]>>()
  private _queryInModelMap = new Map<string, QueryCacheEntry>()

  private _vue: Vue = null
  private _vueComponentOptions: VueComponentOptions
  private _environment = process.env.NODE_ENV

  private _localStorage = new Storage('localStorage')
  private _sessionStorage = new Storage('sessionStorage')
  private _commands: ICommands = null
  private _actions: Actions = null

  private _authenticateWithServer: boolean
  private _authenticationCookies: string = null

  private _fetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>

  private _generatedDataUrlCount: number = 0
  private _urlToDataCache: Map<string, any> = new Map()

  private _uploads: Uploads
  private _apiUrl: string
  private _log: Logger
  private _warn: Warn

  private _features: Features

  // private _loadedPackages: PackageCache
  private _status: {
    stage: 'BOOTING' | 'BOOTED' | 'LOADING' | 'LOADED'
  }

  private _flags: Flags = {}

  private _lookup: DocumentLookupCallback = null

  private _modelIncomplete = true
  private _serverAdapter: IServerAdapter = null
  private _hasRightCache = new AccessRightCache()

  private _globals: any = {}
  private _packages: Packages = null

  private _discoveredElements: HTMLElement[] = []

  private _inspectorDiscoveryPath = []
  private _emitter = new EventEmitter()
  private _defaultReactiveHandler = null
  private _cache: Cache = null
  private _offline: Offline = null


  private _changes: Changes = null
  private _translator = null
  private _preferences = null
  private _designs: Designs = null
  private _developer = null
  private _history = null
  private _rootContext: Context = null
  private _context: Context = null

  private _allContexts = []

  private _userAgent = ''
  private _resizeHandler = null

  private _dynamic = {
    auth: NO_AUTH,
    connectionStatus: {
      connected: false,
      interrupted: false,
      error: false,
      errorMessage: ''
    },
    querySubscriptions: <QuerySubscription[]>[],
    concurrentLookups: <Promise<void>[]>[],
    pendingPersistenceAdapterNextLookup: <Promise<void>>null,
    pendingPersistenceAdapterLookups: <IDocumentLookup[]>[],
    pendingPersistenceAdapterLookupsSet: new Set<string>(),
    pendingPersistenceAdapterNonImportLookups: <IDocumentLookup[]>[],
    pendingLookups: <string[]>[],
    pendingChanges: <Change[]>[],
    pendingBooted: <{ promise: Promise<void>, resolve: Function }>null,
    pendingLoaded: <{ promise: Promise<void>, resolve: Function }>null,
    router: <IRouter>null,
    lastDOMId: 0,
    wasDestroyed: false,
    firstLayoutRendered: false,
    queryCount: 0,
    refreshPending: false,
    watcherCleanupPending: false,
    lastRefresh: 0,
    device: <DEVICE>'Desktop',
    unsubscribeFromEditEvents: <() => void> null,
    // responsivenessLevel:  <RESPONSIVE_LEVELS> 'Small',
  }

  private _importQueue = new UniqueItemProcessingQueue<Types.Document>(
    'ImportDocumentQueue',
    {
      keyLookup: (document) => document.id,
      itemProcessingAction: (batch) => this.import(batch as ImportOperation[]),
      itemExcludeCondition: (document) => {
        const existingDocument = this._model.get(document)

        if (existingDocument && existingDocument.v === document.v && existingDocument.t === document.t) {
          return true
        }

        return false
      },
      maxProcessingBatchSize: 100,
      minTimeBetweenProcessingBatchesInMs: 1,
    })

  private _root = {
    type: 'none',
    layout: {
      id: ''
    },
    params: {},
    history: {
      historic: false,
      timestamp: '',
    }
  }

  private _onlySeeingAnonymousData = true

  private _options: Options

  private _deprecationWarnings = new Set()

  static load(specifiedOptions: Options): Promise<Aeppic> {
    const loading = buildDeferred<Aeppic>()
    // tslint-disable-next-line
    const aeppic = new Aeppic({ ...specifiedOptions, loaded: (a) => loading.resolve(a) })
    return loading.promise
  }

  constructor(specifiedOptions: Options) {
    const options: Options = { ...DEFAULT_OPTIONS, ...specifiedOptions }

    this._options = options
    this._log = buildLogger(options.Logger, { class: 'Aeppic' })
    this._warn = new Warn(this._log)

    this._rootContext = this._buildRootContext()
    this._context = this._rootContext

    this._cache = new Cache(this)
    this._offline = new Offline(this)
    this._documentCache = new DirectCache(DOCUMENT_CACHE_SIZE, DOCUMENT_CACHE_EXPIRE)

    DateTimeSettings.throwOnInvalid = (options.dontThrowOnInvalidDateTime === true) ? false : true
    DateTimeSettings.defaultLocale = typeof navigator !== 'undefined' ? navigator.language : 'en-US'

    // console.log('Aeppic:create')
    this._authenticateWithServer = !(options.auth === false)
    this._apiUrl = options.server

    this._fetch = this._bindFetch(options.fetch)
    this._flags = mergeFlags(options.flags, DEFAULT_FLAGS)
    this._status = { stage: 'BOOTING' }
    
    this._serverAdapter = this._buildServerAdapter(options)
    this._serverAdapter?.setClientId(this._id)

    const uploadOptions: any = {}

    if (options.concurrentUploadLimit != null) {
      uploadOptions.concurrentUploadLimitPerChannel = options.concurrentUploadLimit
    }

    this._uploads = new Uploads(this._serverAdapter, uploadOptions)
    this._ensureUploadFollowUp(this._uploads)

    this._lookup = options.documentLookup
    this._defaultReactiveHandler = this._buildDefaultReactiveHandler()

    if (!this._lookup) {
      if (this._serverAdapter) {
        this._lookup = async (request) => {
          const documentLookups: IDocumentLookup[] = []

          for (let i = 0; i < request.length; i += 2) {
            const id = request[i]
            const v = request[i + 1]
            documentLookups.push({ id, v })

            if (v !== null) {
              documentLookups.push({ id, v: null })
            }
          }

          return this._serverAdapter.getDocuments(documentLookups)
        }
      } else {
        // console.warn('No lookup defined.')
        this._lookup = async () => []
      }
    }

    const self = this

    this._actions = new Actions(this)
    this._commands = new Commands(this, this._createCommandExecutorOptions(<ICommands><any>this._serverAdapter))

    this._changes = new Changes(this)
    this._packages = new Packages(this._createPackagesOptions(this._options))
    this._translator = new Translator()
    this._history = new History(this)

    this._detectDevice(options.userAgent, options.device)

    this._vueComponentOptions = { ...options.vueComponentOptions }

    //
    // All fields/properties of Aeppic must have been at
    // least been set to null before this point
    //
    // Object.preventExtensions(this)
    this._load(options).then(() => {
      Object.freeze(this)
      options.loaded?.(this)
    })
  }

  private _getLocalTemporaryAnonymousAccountId() {
    return ''

    const knownAnonymousId = this._localStorage.getItem('ae-temporary-anonymous-id')

    if (knownAnonymousId) {
      return knownAnonymousId
    } else {
      const id = uuid()
      this._localStorage.setItem('ae-temporary-anonymous-id', id)
      return id
    }
  }

  private _buildRootContext() {
    return new Context('Aeppic', {
      Logger: this._log,
      Query: Scopes
    })
  }

  private _buildServerAdapter(options: Options) {
    if (options.persistenceAdapter) {
      if (!options.persistenceAdapter.on) {
        throw new Error('PersistenceManager must implement basic EventEmitter')
      }


      return options.persistenceAdapter
    } else if (options.server) {
      const adapter = new ServerAdapter(options.server, this._fetch, null, options.WebSocket)
      adapter.setOffline(this._offline)

      let self = this

      adapter.on('change', (change) => {
        if (!self._authenticateWithServer || (self.Auth && self.Auth.loggedIn)) {
          self._importChanges([change])
          self._emitter.emit('change', change)
        }
      })

      adapter.on('connection:status:changed', (status) => {
        self._dynamic.connectionStatus.connected = status.connected
        self._dynamic.connectionStatus.interrupted = status.interrupted
        self._dynamic.connectionStatus.error = status.error
        self._dynamic.connectionStatus.errorMessage = status.errorMessage

        self._emitter.emit('connection:status:changed', self._dynamic.connectionStatus)
      })

      adapter.on('server:changed', () => self._emitter.emit('server:changed'))
      adapter.on('server:message', (message) => self._emitter.emit('server:message', message))

      adapter.on('changes:missed', () => self._emitter.emit('changes:missed'))
      adapter.on('changes:missed-found', () => self._emitter.emit('changes:missed-found'))

      return adapter
    } else {
      return null
    }
  }

  private async _importChanges(changes: any[]) {
    let queryRefreshRequired = false

    const allDocumentsToImport: (Types.Document|Promise<Types.Document>)[] = []

    for (const update of changes) {
      const document = update.document as Types.Document

      if (!document) {
        continue
      }

      const check = this._inspectChangeToImport(update)

      if (check === ChangeInspectionResult.Skip) {
        continue
      }

      if (check & ChangeInspectionResult.RefreshQueries) {
        queryRefreshRequired = true
      }

      if (check === ChangeInspectionResult.HardDelete) {
        this._model.deleteHard(document.id)
        continue
      }

      if (check & ChangeInspectionResult.RequiresEvictionWithPotentialRefresh) {
        const wasEvicted = this._model.evict(document.id)

        if (wasEvicted) {
          queryRefreshRequired = true
        }

        continue
      }

      if (check & ChangeInspectionResult.ImportAfterRightsCheck) {
        const documentIfRight = this.hasRight(document).then(hasRight => hasRight ? document : null)
        allDocumentsToImport.push(documentIfRight)
        continue
      }
      
      if (check & ChangeInspectionResult.Import) {
        allDocumentsToImport.push(document)
      }      
    }

    const documentsToImport = await Promise.all(allDocumentsToImport)

    for (const documentToImport of documentsToImport) {
      if (documentToImport) {
        this._model.import(documentToImport)
      }
    }

    if (queryRefreshRequired) {
      this._scheduleRefresh()
    }
  }

  private _inspectChangeToImport({ document, type }: { document: Types.Document, type: string }): ChangeInspectionResult {
    const isRoot = document.id === 'root'
      
    if (isRoot) {
      // Changes to root should always get imported and cause a full refresh
      return ChangeInspectionResult.ImportAndRefreshQueries
    }

    // A hard delete is rather rare, do a full refresh
    if (type === 'deleted-hard') {
      return ChangeInspectionResult.HardDelete | ChangeInspectionResult.RefreshQueries
    }

    const formId = document.f?.id

    if (!formId) {
      return ChangeInspectionResult.Skip
    }

    // If the document is a form, import it and refresh. Does not 
    // happen that often anyway.
    const documentFormIsForm = 'form' === formId

    if (documentFormIsForm) {
      return ChangeInspectionResult.ImportAndRefreshQueries
    }

    // There are a number of security related changes which could influence all queries.
    const isChangeToCurrentAccount = this.Account?.id == document.id

    if (isChangeToCurrentAccount) {
      return ChangeInspectionResult.ImportAndRefreshQueries 
    }

    const isGroupChange = 'group' === formId

    if (isGroupChange) {
      return ChangeInspectionResult.ImportAndRefreshQueries
    }

    const isUnknownParent = !this.Model.get(document.p)   

    // locks and keys may may be sent in the order LOCKED_DOCUMENT -> LOCK -> KEY.
    // Once the LOCK for the LOCKED_DOCUMENT was imported, the key couldn't be imported because the account did not have access to the LOCKED_DOCUMENT yet.
    // => always import keys and locks without access checks
    const isKeyChange = 'key-form' === formId
    const isLockChange = 'lock-form' === formId

    // If the change changed a lock
    if (isKeyChange || isLockChange) {
      if (isUnknownParent) {
        return ChangeInspectionResult.RefreshQueries
      } else {
        return ChangeInspectionResult.ImportAndRefreshQueries
      }
    }

    // If the document moved away from a previously known parent (not the recycler)
    // we should import and check rights.
    //
    // If it did come from the recycler it matters where it is moved to (which is handled below)
    const previousParent = document.previous?.p
    const previousParentKnownAndNotRecycler = previousParent && previousParent !== 'recycler' && !!this._model.get(previousParent)

    if (previousParentKnownAndNotRecycler) {
      // The document moved so we might need to check auth
      return ChangeInspectionResult.ImportAfterRightsCheck | ChangeInspectionResult.RefreshQueries
    }

    // If the document is moved into the recycler (and we did not know the parent before) we can skip
    // the import just evict to be sure
    if (document.p === 'recycler') {
      return ChangeInspectionResult.RequiresEvictionWithPotentialRefresh
    } 

    // If the document was moved and the parent is unknown and the previous parent wasn't known either
    // (see check above) we can skip the import
    if (isUnknownParent) {
      return ChangeInspectionResult.RequiresEvictionWithPotentialRefresh
    }

    const isDocumentUnknownToThisLocalModel = !!this._model.get(document)

    // If the document already exists in the model we wont check for rights,
    // and assume read access, but if it is unknown we need to check
    if (isDocumentUnknownToThisLocalModel) {
      return ChangeInspectionResult.ImportAfterRightsCheck | ChangeInspectionResult.RefreshQueries
    }

    // This updates a known document in the model. 
    // TODO: Insert into queries more cleverly instead of running a refresh
    return ChangeInspectionResult.ImportAndRefreshQueries
  }

  private _createCommandExecutorOptions(server: ICommands): { serverExecutor: ICommands } {
    return {
      serverExecutor: server
    }
  }

  private _createPackagesOptions(options: Options): PackagesOptions {
    return {
      Aeppic: this,
      // transform: options.packageTransform,
    packagesLookupUrl: options.packagesLookupUrl ?? '/api/packages',
      fetch: (...args) => (<any>this._fetch)(...args),
    }
  }

  private _createModelOptions(createFileURL, setTimestamps, defaultPageLimit, searchAllFields, usesServerAuthentication) {
    const options: ModelOptions = {
      clientId: this._id,
      createFileURL,
      setTimestamps,
    }

    if (usesServerAuthentication) {
      // There is no need to filter client side reads when the server already
      // filters the received data out
      options.dontFilterReads = true
    }

    if (defaultPageLimit != null) {
      options.defaultPageLimit = defaultPageLimit
    }

    if (searchAllFields) {
      options.searchAllFields = searchAllFields
    }

    // Configure Features
    options.features = []

    if (this._features.isEnabled('meta-modified')) {
      options.features.push(ModelFeature.MetaModified)
    }

    return options
  }

  private _ensureUploadFollowUp(uploads: Uploads) {
    uploads.on('uploaded', (document) => {
      const pendingChanges = this._dynamic.pendingChanges
      this._dynamic.pendingChanges = []

      this._tryToWriteChangesToPersistenceAdapter(pendingChanges)
    })
  }

  /**
   * Return the function to be used to build object urls from Files/Blobs and Node.js Buffers/Streams
   */
  private _buildAddToDataCacheFunction(clientId: string): typeof URL.createObjectURL {
    let self = this

    // In a Node.js context we need to simulate this
    return (object: any, options?: any) => {
      if (typeof object === 'string' &&
        object.startsWith('data:') &&
        object.length <= MAX_DATA_URL_LENGTH) {
        return object
      }
     
      const url = self._buildNewFileDataURL()
      self._urlToDataCache.set(url, object)
      return url
    }
  }

  private _buildNewFileDataURL() {
    this._generatedDataUrlCount++
    return `aeppic-local://${this._id}/${this._generatedDataUrlCount}`
  }

  get translator() {
    this._deprecated('Aeppic.translator is deprecated. Use Translator instead. Property was renamed.')
    return this.Translator
  }

  public translate(...keys) {
    return this._translator.translate(...keys)
  }


  public async newAeppic(objectUrlToDocumentsJsonlBlob, rootElementSelector) {
    return new Promise((resolve, reject) => {
      const newAeppicInstance = new Aeppic({
        auth: false,
        el: rootElementSelector,
        boot: {
          src: 'loaded-jsonl',
        },
        booted: async () => resolve(newAeppicInstance),
        fetch: async () => await this._fetch(objectUrlToDocumentsJsonlBlob, { credentials: 'include' })
      })
    })
  }

  public async compareDocumentTrees(otherAeppicInstance, rootDocumentIds, cbProgress, options?: { findOptions?: FindOptions }) {
    const thisAeppicInstance = this
    const adapter = new DefaultDocumentAdapter(otherAeppicInstance, thisAeppicInstance, cbProgress)

    if (Array.isArray(rootDocumentIds)) {
      // new method signature
      return compareDocumentSubTrees(rootDocumentIds, adapter, options)
    }
    else {
      // old method signature
      const rootDocumentId = rootDocumentIds
      const rootDocument1 = await adapter.getDocumentAsync(1, rootDocumentId)
      const rootDocument2 = await adapter.getDocumentAsync(2, rootDocumentId)

      return compareDocumentTrees(rootDocument1, rootDocument2, adapter, options)
    }
  }


  get flags() { return this._flags }

  public setTimeout(callback, timeout?: number, ...args): any {
    if (this.context.isReleased) {
      return null
    }

    const id = setTimeout(() => {
      this.context.releaseUnnamedResource('TIMER', id)
      callback()
    }, timeout, ...args)

    this.context.addUnnamedResource('TIMER', id)

    return id
  }


  public clearTimeout(timeoutId) {
    this.context.releaseUnnamedResource('TIMER', timeoutId)
    clearTimeout(timeoutId)
  }

  public setInterval(callback, interval?: number, ...args) {
    if (this.context.isReleased) {
      return
    }

    const id = setInterval(callback, interval, ...args)
    this.context.addUnnamedResource('INTERVAL', id)
    return id
  }

  public clearInterval(intervalId) {
    this.context.releaseUnnamedResource('INTERVAL', intervalId)
    clearInterval(intervalId)
  }

  debounce(callback, wait?, context = this) {
    return debounce(callback, wait, context, (cb, timeout, ...args) => this.setTimeout(cb, timeout, ...args))
  }

  newDOMId() {
    this._dynamic.lastDOMId += 1
    return 'ae_i_' + this._dynamic.lastDOMId
  }

  get wellKnownClasses() {
    return WELL_KNOWN_CLASSES
  }

  get Log() {
    return this.context.Log || this._log
  }

  get Warn() {
    return this._warn
  }

  set Router(router: IRouter) {
    this.Log.debug('setting router')
    this._dynamic.router = router
  }

  get Router(): IRouter {
    return this._dynamic.router
  }

  static get ModelConstructor() {
    return Model
  }

  public static get Version() { return Release.VERSION }
  public get Version() { return Aeppic.Version }

  public get Status() { return this._status }
  public get Root() { return this._root }
  public get Model() { return this._model }
  public get Cache() { return this._cache }
  public get Offline() { return this._offline }

  private _bindFetch(customFetch: typeof fetch): (input: RequestInfo, init?: RequestInit) => Promise<Response> {
    if (customFetch) {
      return (input: RequestInfo, init?: RequestInit) => customFetch(input, init)
    } else {
      const globalFetch = typeof fetch !== 'undefined' ? fetch : null

      if (!globalFetch) {
        // console.warn('No fetch defined')
        return null
      } 

      return (input: RequestInfo, init?: RequestInit) => globalFetch(input, init)
    } 
  }

  public get Auth() {
    return this._dynamic.auth
  }

  public get Account() {
    if (this._dynamic.auth) {
      return this._model.get(this._dynamic.auth.accountId)
    }
  }

  public get GroupAccount() {
    if (this._dynamic.auth) {
      return this._model.get(this._dynamic.auth.groupAccountId)
    }
  }

  public get HomePage() {
    if (this._dynamic.auth && this._dynamic.auth.info && this._dynamic.auth.info.defaultPage && this._dynamic.auth.info.defaultPage.id) {
      return this._dynamic.auth.info.defaultPage
    } else {
      return { id: 'root' }
    }
  }

  public get ProfilePage() {
    if (this._dynamic.auth && this._dynamic.auth.profile) {
      return this._dynamic.auth.profile
    } else {
      return null
    }
  }

  public async getProfilePageForAccount(accountIdentifier: Types.IdOrReference | Types.Document) {
    const account = await this.get(accountIdentifier)

    if (!account || account.f.id !== 'account') {
      return null
    }

    if (account.data.parentIsProfile) {
      return this._get(account.p)
    }

    if (account.data.profilePage) {
      return this._get(<Types.Reference>account.data.profilePage)
    }

    return null
  }

  public async getAccountForProfilePage(profilePageIdentifier: Types.IdOrReference | Types.Document) {
    const profile = await this.get(profilePageIdentifier)

    if (!profile) {
      return null
    }

    const asProfileParent = await this.findOne(`p:${profile.id} f.id:account data.parentIsProfile:true`, { includeHidden: true })

    if (asProfileParent) {
      return asProfileParent
    }

    const asProfileRef = await this.findOne(`f.id:account data.profilePage.id:${profile.id}`, { includeHidden: true })

    if (asProfileRef) {
      return asProfileRef
    }

    return null
  }

  public async navigateHome() {
    if (!this._dynamic.router) {
      return
    }

    this._dynamic.router.navigateToUrl('/')
  }

  public async navigateToProfile() {
    const page = await this.get(this.ProfilePage)

    if (page) {
      this.navigateTo(page.id)
    }
  }

  public async sleep(ms: number) {
    return new Promise((resolve) => this.setTimeout(resolve, ms))
  }

  public async login(username: string, password: string, remember = false, loadData = true, impersonationToken?: string) {
    this._hasRightCache.clear()

    if (this._authenticateWithServer) {
      let headers: any

      if (typeof Headers !== 'undefined') {
        headers = new Headers()
        headers.append('Accept', 'application/json')
        headers.append('Content-Type', 'application/x-www-form-urlencoded')
      } else {
        headers = {}
        headers['Accept'] = 'application/json'
        headers['Content-Type'] = 'application/x-www-form-urlencoded'
      }

      let body = ''

      if (impersonationToken) {
        body = `impersonationToken=${encodeURIComponent(impersonationToken)}`
      } else {
        body = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}` + (remember ? `&remember=true` : '')
      }

      const loginUrl = this._getApiUrl('/auth/_login')

      const response = await this._fetch(loginUrl, { method: 'POST', headers, body, credentials: 'include' })

      if (response.ok) {
        if (this._apiUrl.startsWith('http')) {
          const cookies = getCookies(response)
          const cookieValues = cookies.map(c => c.replace(/;.*$/, ''))
          this._authenticationCookies = cookieValues.join('; ')
          this._serverAdapter.setCookies(this._authenticationCookies)
        }

        const payload = await response.json()

        this._dynamic.auth = JSON.parse(JSON.stringify(NO_AUTH))
        this._dynamic.auth.loggedIn = true
        this._dynamic.auth.accountId = payload.accountId
        this._dynamic.auth.backedBy = payload.backedBy
        this._dynamic.auth.impersonatedBy = payload.impersonatedBy

        this._dynamic.auth.profile = payload.profile
        this._dynamic.auth.info = payload.info

        this._onlySeeingAnonymousData = false
        this._model.setAccountInfo(this._dynamic.auth.accountId, this._dynamic.auth.backedBy, this._dynamic.auth.impersonatedBy)

        await this._get([this._dynamic.auth.accountId, this._dynamic.auth.groupAccountId, this._dynamic.auth.backedBy, this._dynamic.auth.impersonatedBy])

        if (loadData) {
          await this._loadDocumentsAfterLogin()
        }

        this._emitter.emit('login', this._dynamic.auth)

        return {
          ok: true,
          auth: this._dynamic.auth,
          status: response.status
        }
      } else {
        return {
          ok: false,
          status: response.status
        }
      }
    }
  }

  private async _loadSecurity() {
    // if (this._dynamic.auth) {
    //   // TODO: Not required when rights calls executed against server
    //   const groupsPromise = this.find('f.id:group', { size: 5000 })
    //   const keysPromise = this.find('f.id:key-form', { size: 5000 })

    //   const [groups, keys] = await Promise.all([groupsPromise, keysPromise])

    //   this.Log.debug('Loaded security #%d groups and #%d keys', groups.length, keys.length)
    // }
  }

  private _getApiUrl(route = '') {
    if (this._apiUrl) {
      return this._apiUrl + route
    } else {
      return route
    }
  }

  public async logout() {
    this._hasRightCache.clear()

    if (this._authenticateWithServer) {
      const logoutUrl = this._getApiUrl('/auth/_logout')
      const response = await this._fetch(logoutUrl, { method: 'POST', body: '', credentials: 'include' })

      if (!response.ok) {
        console.error('Could not logout correctly (No Server connection?)')
        return
      }

      // NOT needed as long as reload is triggered below
      // this._evictAuthenticatedServerData()
    }

    if (this._serverAdapter) {
      // This does NOT reset cookies for group accounts etc yet. This will therefore
      // cause issues in node.js usage. logout is not really supported for that
      // use case 
      this._serverAdapter.setCookies(null)
    }

    this._model.setAccountInfo(this._getLocalTemporaryAnonymousAccountId(), 'anonymous')

    await this._updateAuthInfo()
    this.navigateHome()

    this._emitter.emit('logout', this._dynamic.auth)

    // If window is not available, we are in a node.js context and
    // reload is not required

    if (isInBrowser()) {
      setTimeout(() => {
        (<any>window.location.reload)(true)
      }, 5)
    }
  }

  public reload(clearCache = true) {
    if (isInBrowser()) {
      (<any>window.location.reload)(clearCache)
    }
  }

  private _evictAuthenticatedServerData() {
    const keepRootChildren = ['system', 'apps', 'recycler']

    const rootChildren = this._model.find(`p:root`, { sort: null })
    const rootChildrenToEvict = rootChildren.filter(d => keepRootChildren.indexOf(d.id) === -1)

    for (const childToEvict of rootChildrenToEvict) {
      this._model.evict(childToEvict)
    }

    this._model.evict('recycler', { onlyDescendants: true })

    this._onlySeeingAnonymousData = true

    this._scheduleRefresh()
  }

  // TODO: Check behavior in local mode
  private async _updateAuthInfo() {
    this._hasRightCache.clear()

    if (this._authenticateWithServer) {
      const authInfoUrl = this._getApiUrl('/auth/info')
      const response = await this._fetch(authInfoUrl, { credentials: 'include' })

      if (response.ok) {
        this._dynamic.auth = await response.json()

        if (this._dynamic.auth.loggedIn || this._dynamic.auth.backedBy) {
          this._onlySeeingAnonymousData = false
          this._model.setAccountInfo(this._dynamic.auth.accountId, this._dynamic.auth.backedBy, this._dynamic.auth.impersonatedBy)
        } else {
          this._onlySeeingAnonymousData = true
          this._dynamic.auth = JSON.parse(JSON.stringify(NO_AUTH))
        }
      } else {
        this._dynamic.auth = JSON.parse(JSON.stringify(NO_AUTH))
        this._onlySeeingAnonymousData = true
      }
    } else {
      this._onlySeeingAnonymousData = false
      this._dynamic.auth = JSON.parse(JSON.stringify(NO_AUTH))
    }
  }

  public download(url = '/api') {
    return this._fetchFromServerApi('GET', url)
  }

  private _fetchFromServerApi(method = 'GET', url = '/api/docs/_all', body?) {
    const headers: any = this._buildHeaders()

    if (typeof Headers !== 'undefined') {
      if (!this._authenticationCookies) {
        (<Headers>headers).delete('Cookie')
      } else {
        (<Headers>headers).append('Cookie', this._authenticationCookies)
      }
    } else {
      if (!this._authenticationCookies) {
        delete headers.Cookie
      } else {
        headers['Cookie'] = this._authenticationCookies
      }
    }

    return this.fetch(url, { method, headers, body, credentials: 'include' })
  }

  private _buildHeaders() {
    if (typeof Headers !== 'undefined') {
      const headers = new Headers()
      headers.append('Accept', 'application/json')
      headers.append('Content-Type', 'application/json')
      return headers
    } else {
      const headers = {}
      headers['Accept'] = 'application/json'
      headers['Content-Type'] = 'application/json'
      return headers
  }
  }

  private async _load(options: Options) {
    // Load Features
    if (options.features) {
      this._features = new Features(options.features, { Logger: this._log })
    } else if (this._fetch) {
      const lookupUrl = this._getApiUrl(options.featuresLookupPath)
      this._features = await Features.buildFromServer(this._fetch, { Logger: this._log, lookupUrl })
    } else {
      this._features = new Features({}, { Logger: this._log })
    }

    if (this._features.isEnabled('editable-data-validation')) {
      enableProxyMode()
    }

    // Load Model
    const createFileURL = this._buildAddToDataCacheFunction(this._id)

    this._model = new Model(this._createModelOptions(createFileURL, options.setTimestamps, options.defaultPageLimit, options.searchAllFields, this._authenticateWithServer))
    this._model.setAccountInfo(this._getLocalTemporaryAnonymousAccountId(), 'anonymous')

    this._preferences = new Preferences(this)
    await this._preferences.delayUntilLoaded()

    // Initialize designs
    this._designs = new Designs(this)

    // Initialize developer tools
    this._developer = new Developer(this)
    
    // Initialize vue (which can now access an empty and as of yet unauthorized model)
    this._initializeVue(options.el)

    // Login
    if (hasLoginInfo(options.auth)) {
      await this.login(options.auth.username, options.auth.password, false, false)
    } else {
      await this._updateAuthInfo()
    }

    await waitForNextMicroTick()

    // Load boot documents
    if (options.boot) {
      await this._loadDocuments(options.boot, this._lookup)
    }

    this._status.stage = 'BOOTED'

    if (this._dynamic.pendingBooted) {
      this._dynamic.pendingBooted.resolve()
      this._dynamic.pendingBooted = null
    }

    removeElement(options.removeAfterBoot)

    await this._loadSecurity()

    if (options.booted) {
      this._scheduleRefresh()
      options.booted(this)
    }

    // Load remaining documents
    this._status.stage = 'LOADING'

    if (options.load) {
      await this._loadDocuments(options.load, this._lookup, 100)
      this._modelIncomplete = false
    }

    this._status.stage = 'LOADED'

    removeElement(options.removeAfterLoad)

    await this._get([this._dynamic.auth.accountId, this._dynamic.auth.groupAccountId, this._dynamic.auth.backedBy, this._dynamic.auth.impersonatedBy])

    if (this._dynamic.pendingLoaded) {
      this._dynamic.pendingLoaded.resolve()
      this._dynamic.pendingLoaded = null
    }

    if (this._serverAdapter && !options.noConnect) {
      this._serverAdapter.connect(/* TODO: communicate which changes have already been received during boot/load in order to ignore those */)
      this._modelIncomplete = true
    } else {
      this._modelIncomplete = false
    }

    this._scheduleRefresh()
  }

  public async _loadDocumentsAfterLogin() {
    if (this._options.load) {
      return this._loadDocuments(this._options.load, this._lookup, 109)
    } else {
      return this._loadSecurity()
    }
  }

  private _detectDevice(userAgent: string, device: DEVICE) {
    if (userAgent) {
      this._userAgent = userAgent
    } else if (typeof navigator !== 'undefined') {
      this._userAgent = navigator.userAgent
    }

    if (device) {
      this._dynamic.device = device
    } else {
      if (MOBILE_DETECT_UA.test(this._userAgent)) {
        this._dynamic.device = 'Phone'
      } else if (PHONE_DETECT_UA) {
        if (PAD_DETECT_UA.test(this._userAgent)) {
          this._dynamic.device = 'Tablet'
        } else if (PHONE_DETECT_UA.test(this._userAgent)) {
          this._dynamic.device = 'Tablet'
        }
      } else {
        this._dynamic.device = 'Desktop'
      }

      if (isInBrowser()) {
        if (this._dynamic.device === 'Tablet') {
          const clientRect = window.document.body.getBoundingClientRect()
          if (clientRect.width >= MIN_LARGE_SIZE) {
            this._dynamic.device = 'Desktop'
          }
        }
      }
    }
  }

  public get ConnectionStatus() {
    return this._dynamic.connectionStatus
  }

  public get Device() {
    return this._dynamic.device
  }

  public get ResponsiveSizes() {
    return { MIN_MEDIUM_SIZE, MIN_LARGE_SIZE }
  }

  private _initializeVue(mountElement?: string | HTMLElement) {
    // TODO: Offer a way to opt out of Vue support.
    //       e.g by setting el explicitly to null or something similar
    // if (mountElement) {
    //   return
    // }

    if (isBrowserButVeryOldBrowser()) {
      console.error('Browser not supported')
    }

    const aeppic = this

    this._registerExtensions()
    this._registerGlobalComponents()
    this._registerGlobalDirectives()

    let mountElementId = null
    if (typeof mountElement === 'string') {
      if (mountElement[0] === '#') {
        mountElementId = mountElement.substr(1)
      }
    } else if (mountElement) {
      mountElementId = mountElement.id
    }

    if (!mountElementId) {
      mountElementId = 'app'
    }

    this._vue = new Vue(<any>{
      el: mountElement,
      /**
       * Build a reactive and data-bound Aeppic object that every sub-component can inject and use
       *
       * @returns
       */
      data() {
        return {
          Aeppic: aeppic,
        }
      },
      /**
       * Provide objects that all descendant components can use for injection
       * See https://vuejs.org/v2/api/#provide-inject.
       *
       * The provided objects themselves are not automatically reactive so
       * we expose a getter to return the reactive data instance defined above
       *
       * @returns
       */
      provide() {
        return {
          getAeppicContext(contextName: string, queryScopeRoot: Types.DocumentId | Types.Document) {
            return aeppic.contextify(contextName, queryScopeRoot)
          }
        }
      },
      /**
       * Only render the root-component
       *
       * @returns
       */
      render: function (createElement) { return createElement('root-component', { attrs: { id: mountElementId } }) },
      /**
       *
       */
      mounted() {
        aeppic._rootContext.rootElement = this.$el

        // We need to sleep here to ensure aeppic actually has the vue instance registered
        if (!aeppic._dynamic) {
          return
        }

        aeppic._addDOMEventListener('TranslateDrillIntoIntoNavigateToFallback', DOMEvents.DrillInto, (event: any) => {
          // TODO: log
          const { detail } = event
          aeppic.navigateTo(detail.targetDocument)
        })

        if (aeppic.flags.discoverElements.enabled) {
          const optionsSupported = isPassiveOnHandlersSupported()
          this.passiveCaptureOptions = optionsSupported ? { capture: true, passive: true } : true

          let monitorElement = false

          const updateDiscoveryPath = (event) => {
            if (window.requestAnimationFrame) {
              window.requestAnimationFrame(() => {
                this.setInspectorDiscoveryPath(event)
              })
            } else {
              this.setInspectorDiscoveryPath(event)
            }
          }

          this.discoverElementsAtMousePosition = debounce(updateDiscoveryPath, 10)

          this.startMonitoring = (event) => {
            if (monitorElement) {
              return
            }

            monitorElement = true

            aeppic._addDOMEventListener('[Dev] Monitor Mouse Movements', 'mousemove', this.discoverElementsAtMousePosition, this.passiveCaptureOptions)
            aeppic._addDOMEventListener('[Dev] Monitor Mouse Clicks', 'click', this.inspectElementAtMousePosition, true)

            updateDiscoveryPath(event)
          }

          this.stopMonitoring = () => {
            if (!monitorElement) {
              return
            }

            monitorElement = false

            aeppic._removeDOMEventListener('[Dev] Monitor Mouse Movements')
            aeppic._removeDOMEventListener('[Dev] Monitor Mouse Clicks')
          }

          this.enableDisable = (event) => {
            if (isDiscoverKeyCombination(event)) {
              this.startMonitoring(event)
            } else {
              this.stopMonitoring()
            }
          }

          this.inspectElementAtMousePosition = (event) => {
            if (isDiscoverKeyCombination(event)) {
              this.setInspectorDiscoveryPath(event, { inspect: true })
              event.stopPropagation()
              event.preventDefault()
            } else {
              this.stopMonitoring()
            }
          }

          aeppic._addDOMEventListener('[Dev] Monitor Key Down', 'keydown', this.enableDisable, this.passiveCaptureOptions)
          aeppic._addDOMEventListener('[Dev] Monitor Key Up', 'keyup', this.enableDisable, this.passiveCaptureOptions)
        }
      },
      beforeDestroy() {
        if (this.$el) {
          this.stopMonitoring()
          aeppic._removeAllDOMEventListeners()
          this._dynamic?.unsubscribeFromEditEvents?.()
        }
      },
      methods: {
        setInspectorDiscoveryPath(event, options) {
          const target: Element = event.target

          const pathElements =
            'path' in event ? event.path
              // : 'composedPath' in event ? event.composedPath()  // This is weirdly [] all the time
              : composedPath(target)

          const path = aeppic.Developer.buildInspectorDiscoveryPath(pathElements)
          aeppic.Developer.setInspectorDiscoveryPath(path, pathElements, options)
        }
      },
      /**
       * Make the root component available without registering it globally
       */
      components: {
        'root-component': RootComponent
      }
    })
  }

  public lookupComponentForElement(element: HTMLElement) {
    // __vue__ is not reliable due to children/parent hidden component
    // mismatches
    //
    // Do not use:
    //
    // if (element['__vue__']?.$el === element) {
    //   return element['__vue__']
    // }

    for (const component of this._recurseComponents()) {
      if (component.$el === element) {
        return component
      }
    }
  }

  private *_recurseComponents(rootComponent = this._vue) {
    yield rootComponent

    for (const child of rootComponent.$children) {
      yield* this._recurseComponents(child)
    }
  }

  triggerResize() {
    if (isInBrowser()) {
      if (window.dispatchEvent && typeof Event !== 'undefined') {
        window.dispatchEvent(new Event('resize'))
      }
    }
  }

  contextify(name: string, queryRoot?: Types.Document | Types.DocumentId) {
    const queryRootId = getDocumentId(queryRoot)
    const contexifiedInstance = contextify(this, name, { Logger: this._log, Query: new QueryScopes(queryRootId) })
    const context = contexifiedInstance.context

    if (this._allContexts.indexOf(context) >= 0) {
      console.warn('context already known ?!', context)
    }

    this._allContexts.push(context)

    return contexifiedInstance
  }

  setContextRootElement(rootElement: HTMLElement) {
    if (!rootElement) {
      throw new Error('Must set to valid HTML Element')
    }

    const context = this.context

    if (context === this._rootContext) {
      throw new Error('Cannot set context root element on non contextified root Aeppic instance')
    }

    context.rootElement = rootElement
  }

  private _addDOMEventListener(id: string, name: string, callback, options?) {
    if (this.context.domElementListeners.has(id)) {
      throw new Error('Registering multiple handlers for same event not supported. Mostly to ensure no mistakes when de-registering')
    }

    const element = this.context.rootElement

    this.context.domElementListeners.set(id, { name, callback, options })
    element.addEventListener(name, callback, options)
  }

  private _removeDOMEventListener(id: string) {
    const listener = this.context.domElementListeners.get(id)
    this.context.domElementListeners.delete(id)

    const element = this.context.rootElement
    element.removeEventListener(listener.name, listener.callback, listener.options)
  }

  private _removeAllDOMEventListeners() {
    const element = this.context.rootElement

    for (const listener of this.context.domElementListeners.values()) {
      element.removeEventListener(listener.name, listener.callback, listener.options)
    }

    this.context.domElementListeners.clear()
  }

  release() {
    if (this._dynamic.wasDestroyed) {
      return
    }

    if (this.context === this._rootContext) {
      throw new Error('Root context must be destroyed not released')
    }

    this._release()
  }

  private _release() {
    const context = this.context

    this._removeAllDOMEventListeners()
    context.release()

    if (context !== this._rootContext) {
      removeFromArray(this._allContexts, context)
    }
  }

  isDOMEvent(eventName: string) {
    for (const name of Object.values(DOMEvents)) {
      if (name === eventName) {
        return true
      }
    }
    return false
  }
  /*
   * Events 
   */
  on(event, cb): any {
    return this.addEventListener(event, cb)
  }
  off(event, cb): any {
    return this.removeEventListener(event, cb)
  }

  addEventListener(event, cb): any {
    if (this.isDOMEvent(event)) {
      const existingId = cb[DOMEventSubscriberIdentifierKey]

      if (existingId) {
        this._log.error('Could not add event listener for DOM event. The callback was already registered before')
      }

      const id = cb[DOMEventSubscriberIdentifierKey] = uuid()
      this._addDOMEventListener(id, event, cb, { passive: true })
      return
    }

    return this._emitter.addListener(event, cb)
  }

  removeEventListener(event, cb): any {
    if (this.isDOMEvent(event)) {
      const id = cb[DOMEventSubscriberIdentifierKey]

      if (!id) {
        this._log.error('Could not remove event listener for DOM event. The callback was not registered before')
        return
      }

      this._removeDOMEventListener(id)

      delete cb[DOMEventSubscriberIdentifierKey]
    }

    return this._emitter.removeListener(event, cb)
  }

  /*
   * `window` Support
   */

  async loadStylesheet(stylesheetId, cssBlockId = '') {
    const stylesheet = await this.get(stylesheetId)
    const css = <string>stylesheet.data.css

    let patchedCss = css.replace(CSS_BLOCK_MARKER, cssBlockId)

    loadStyle(css, stylesheetId)
  }

  unloadStylesheet(stylesheetId) {
    unloadStyle(stylesheetId)
  }

  // async loadScript(src, group: ScriptGroup = 'ae-dynamic', sortIndex = null) {
  //   return loadScript(src, group, sortIndex)
  // }

  // unloadScript(src) {
  //   return unloadScript(src)
  // }


  public get navigator() {
    if (typeof navigator !== 'undefined') {
      return {
        userAgent: navigator.userAgent
      }
    } else {
      return {
        userAgent: 'nodejs'
      }
    }
  }

  navigateBack() {
    if (isInBrowser()) {
      return window.history.back()
    }
  }

  navigateForward() {
    if (isInBrowser()) {
      return window.history.forward()
    }
  }

  /*
   * Model exports functionality
   */

  public *recurse(startDocument: Types.Document): IterableIterator<Types.Document> {
    return yield* this._model.recurse(startDocument)
  }

  public async downloadModel(requestedOptions: ModelDownloadOptions, cbProgress?: (progress: number, subtask: string) => void) {
    const options: ModelDownloadOptions = { ...DEFAULT_MODEL_DOWNLOAD_OPTIONS, ...requestedOptions }

    const exportOptions: ModelExportOptions = {
      format: 'blob',
      compress: options.compress,
      rootDocumentId: options.rootDocumentId,
      rootDocumentIds: options.rootDocumentIds,
      json: options.json,
      includeUnusedForms: options.includeUnusedForms,
      includeBinaryContent: options.includeBinaryContent,
      transportManifestId: options.transportManifestId
    }

    const result = await this.exportModel(exportOptions, cbProgress)

    let filename = options.filename || result.name
    if (options.extension) {
      filename = filename + options.extension
    }
    else {
      filename = filename + result.extension + (options.compress ? '.zip' : '')
    }

    FileSaver.saveAs(result.data, filename)
  }

  async exportForms(parentDocumentId = 'root', { alsoFilterSystem = false, includeFn = null }: { alsoFilterSystem?: Boolean, includeFn?: (id: string) => Boolean } = {}): Promise<(Change | Types.Document)[]> {
    return this._model.exportForms(parentDocumentId, { alsoFilterSystem, includeFn })
  }

  public async exportModel(requestedOptions: ModelExportOptions, cbProgress?: (progress: number, subtask: string) => void): Promise<ModelExport> {
    const options: ModelExportOptions = { ...DEFAULT_MODEL_EXPORT_OPTIONS, ...requestedOptions }
    const manifest = await this._createTransportManifest(options)

    let rootDocumentIds = []
    let excludeSystemBinaries = false

    // always include system, currently required by merge-tool 
    if (!manifest.rootDocumentIds.includes('system') && !manifest.rootDocumentIds.includes('root')) {
      rootDocumentIds.push('system', ...manifest.rootDocumentIds)
      excludeSystemBinaries = true
    }
    else {
      rootDocumentIds = manifest.rootDocumentIds
      excludeSystemBinaries = false
    }

    const { prefix, separator, suffix, stringify, extension, mime } = getJsonFormattingOptions(options.json)
    const lines = []
    const contentUrls = new Map()

    let total = 0
    let processed = 0
    let progress = 0

    if (rootDocumentIds.length === 1 && rootDocumentIds[0] === 'root') {
      total = this.Model.numberOfDocuments
    }
    else {
      for (const rootDocumentId of rootDocumentIds) {
        const descendants = await this.find(`a:${rootDocumentId}`)
        total += descendants.length
      }
    }

    // Prefix the export with dynamically created (multi-document) manifest
    for (const doc of manifest.manifestDocuments) {
      lines.push(stringify(doc))
    }

    // Only export forms that are actually used by default
    // unless they are inside of system and we want to export from 'root' downwards
    const formExportOptions = {
      excludeLatest: false,
      alsoFilterSystem: !rootDocumentIds.includes('root'),
      includeFn: (formId: string) => (
        this._model.some(`f.id:${formId}`)
      )
    }

    if (manifest.includeUnusedForms) {
      formExportOptions.alsoFilterSystem = false
      formExportOptions.includeFn = null
    }

    for (const rootDocumentId of rootDocumentIds) {
      for (const form of this._model.exportForms(rootDocumentId, formExportOptions)) {
        lines.push(stringify(form))
      }
    }

    for (const ancestor of await this._exportAncestors(rootDocumentIds)) {
      lines.push(stringify(ancestor))
    }

    for (const rootDocumentId of rootDocumentIds) {
      for (const entry of this._model.export(rootDocumentId)) {
        lines.push(stringify(entry))

        if (manifest.includeBinaryContent[rootDocumentId]) {
          if (!(rootDocumentId === 'system' && excludeSystemBinaries)) {
            for (const urlEntry of await this._getContentUrls(entry)) {
              contentUrls.set(urlEntry.dataUrl, urlEntry)
            }
          }
        }

        processed++
        const p = total ? Math.floor(processed / total * 100) : 0

        if ((p !== progress) && cbProgress) {
          progress = p
          cbProgress(progress, 'exporting documents')
        }

        // TODO: Export previous versions in descending order (e.g 4 -> 3 -> 2. Import will ignore them or use them for history)
      }
    }

    const text = prefix + lines.join(separator) + suffix

    let result: ModelExport = null

    if (options.compress) {
      result = {
        name: manifest.name,
        extension,
        data: await this._createExportZip(`documents${extension}`, text, 'content', contentUrls, (progress) => {
          cbProgress && cbProgress(progress, 'exporting file content')
        })
      }
    } else if (options.format === 'text') {
      result = {
        name: manifest.name,
        extension,
        data: text,
      }
    } else if (options.format === 'buffer') {
      result = {
        name: manifest.name,
        extension,
        data: Buffer.from(text)
      }
    } else {
      const encoder = new TextEncoder()
      const uint8array = encoder.encode(text)

      result = {
        name: manifest.name,
        extension,
        data: new Blob([uint8array], { type: mime })
      }
    }

    return result
  }

  private async _createTransportManifest(options): Promise<any> {
    const result = {
      name: '',
      manifestDocuments: [],
      rootDocumentIds: [],
      includeBinaryContent: {},
      includeUnusedForms: false
    }

    const sourceMachine = '<n/a>'
    const exportDate = (new Date()).toISOString()

    //
    // clone the specified manifest
    //
    if (options.transportManifestId) {

      if (await this.get(options.transportManifestId)) {
        const clonedDocs = await this.cloneDeep(options.transportManifestId, 'root', { save: false })
        const manifest = clonedDocs.root

        manifest.data.sourceMachine = sourceMachine
        manifest.data.exportDate = exportDate
        manifest.data.active = true
        result.manifestDocuments.push(manifest.cloneAsDocument())
        result.name = manifest.data.name
        result.includeUnusedForms = manifest.data.includeUnusedForms

        for (const manifestEntryRef of manifest.data.rootDocuments) {
          const manifestEntry = clonedDocs.documents.find(d => d.id === manifestEntryRef.id)

          result.manifestDocuments.push(manifestEntry.cloneAsDocument())

          const rootRef = manifestEntry.data.rootDocument as Types.Reference

          if (rootRef && rootRef.id) {
            result.rootDocumentIds.push(rootRef.id)
            result.includeBinaryContent[rootRef.id] = !!manifestEntry.data.includeBinaryContent
          }
        }

        return result
      }
      else {
        console.error('invalid transport manifest id.')
      }
    }

    //
    // alternatively dynamically create a default manifest from the specified options
    //
    const rootDocumentIds = options.rootDocumentIds.length ? options.rootDocumentIds : [options.rootDocumentId]

    result.rootDocumentIds = rootDocumentIds

    const manifest = await this.new('1327bd9b-34e5-4c38-bc43-5e709d8c01a2', 'root')
    let manifestEntry = null
    manifest.data.active = true
    manifest.data.sourceMachine = sourceMachine
    manifest.data.exportDate = exportDate
    manifest.data.includeUnusedForms = options.includeUnusedForms

    for (const rootDocumentId of rootDocumentIds) {
      const rootDocument = await this.get(rootDocumentId)

      if (!rootDocument) {
        continue
      }

      if (!manifest.data.name) {
        manifest.data.name = rootDocument.data.name || ''
      }

      manifestEntry = await this.new('573a7cb1-789b-4600-94a1-95edc76fc34c', manifest, { allowMissingParent: true })
      manifest.addReference('rootDocuments', manifestEntry)
      manifestEntry.addReference('rootDocument', rootDocument)
      manifestEntry.data.includeBinaryContent = options.includeBinaryContent

      result.manifestDocuments.push(manifestEntry.cloneAsDocument())
      result.includeBinaryContent[rootDocumentId] = options.includeBinaryContent
    }

    if (manifest.data.name && manifest.data.rootDocuments.length > 1) {
      manifest.data.name = `${manifest.data.name} + ${manifest.data.rootDocuments.length - 1} other${manifest.data.rootDocuments.length > 2 ? 's' : ''}`
    }
    else if (!manifest.data.name) {
      manifest.data.name = 'export-manifest'
    }

    result.name = manifest.data.name
    result.includeUnusedForms = manifest.data.includeUnusedForms
    result.manifestDocuments.unshift(manifest.cloneAsDocument())

    result.manifestDocuments.push((await this.getFormForDocument(manifest)).document)
    manifestEntry && result.manifestDocuments.push((await this.getFormForDocument(manifestEntry)).document)
    return result
  }

  private async _exportAncestors(identifiers: Types.IdOrReference[]) {

    const result = new Set()

    for (let identifier of identifiers) {
      const document = await this.get(identifier)

      if (!document || !document.a) {
        continue
      }

      for (let aId of ['root'].concat(document.a)) {
        const a = await this.get(aId)
        result.add(a)
      }
    }

    return result.values()
  }

  private async _getContentUrls(document: Types.Document) {
    const result = []
    const form = await this.getFormForDocument(document)

    if (!form) {
      return result
    }

    for (let field of form.fields) {
      if (field.subType === 'file' || field.subType === 'image') {
        const fileField = document.data[field.name] as FileField
        if (fileField && fileField.dataUrl) {
          result.push({
            name: fileField.dataUrl.startsWith('data:') ? `data-uri-${document.id}-${field.name}` : ascii2hex(fileField.dataUrl),
            dataUrl: fileField.dataUrl,
            src: this.Content.getSrc(document, field.name)
          })
        }
      }
    }

    return result
  }

  private async _createExportZip(documentsFileName, documentsFileText, contentFolderName, contentUrls, cbProgress?): Promise<Blob> {
    let total = contentUrls.size + 1
    let processed = 0
    let progress

    const zip = new ZipWriter()

    await zip.open()
    await zip.addText(documentsFileName, documentsFileText)
    processed++
    cbProgress && cbProgress(Math.floor(processed / total * 100))

    for (let entry of contentUrls.values()) {
      const response = await this._fetchFromServerApi('GET', entry.src)

      if (!response.ok) {
        console.error(`failed to fetch ${entry.src}`)
        continue
      }
      const data = await response.blob()
      const path = `${contentFolderName}/${entry.name}`
      await zip.addBlob(path, data)
      // await zip.addUrlContent(path, entry.src)
      processed++
      let p = Math.floor(processed / total * 100)
      if ((p !== progress) && cbProgress) {
        progress = p
        cbProgress(progress)
      }
    }

    return await zip.close() as Blob
  }

  public async canEdit(identifier: Types.IdOrReference) {
    if (isAdmin(this.Account)) {
      return true
    }
    
    return this.hasRight(identifier, 'aa3ba2de-4b65-4152-ab1d-655e21de9efd')
  }

  @deprecated('Aeppic', 'hasAccess', 'hasRight')
  public async hasAccess(identifier: Types.IdOrReference, rightIdentifier?: Types.IdOrReference) {
    if (rightIdentifier == null) {
      return this.hasRight(identifier)
    } else if (rightIdentifier === '') {
      throw new Error('Empty right identifier not supported')
    } else {
      return this.hasRight(identifier, [rightIdentifier])
    }
  }

  /**
   * Checks whether the current account has access to at least **one** of the rights specified.
   * If no rights are specified (undefined parameter) only read access is checked.
   * 
   * @param target 
   * @param rights
   */
  public async hasRight(target: Types.IdOrReference): Promise<boolean>
  public async hasRight(target: Types.IdOrReference, rightIdentifier: Types.IdOrReference): Promise<boolean>
  public async hasRight(target: Types.IdOrReference, rightIdentifiers: Types.IdOrReference[]): Promise<boolean>
  public async hasRight(target: Types.IdOrReference, arg2?: Types.IdOrReference|Types.IdOrReference[]): Promise<boolean> {
    await this._ensureLoaded()

    if (arg2 == null) {
      return this._checkReadAccessOnly(target)
    } 

    const rights = Array.isArray(arg2) ? arg2 : [arg2]

    return this._checkAccessWithRights(target, rights)
  }

  private async _checkReadAccessOnly(targetIdentifier: Types.IdOrReference) {
    const targetId = getDocumentId(targetIdentifier)
    
    const canCheckLocally = !this._modelIncomplete
    const canCheckRemotely = this._serverAdapter != null

    if (canCheckLocally || !canCheckRemotely) {
      if (!canCheckRemotely) {
        console.warn('Cannot check read access correctly. Model is not complete with no server adapter, but will try anyway')
      }
      return this._model.hasRight(targetIdentifier)
    } else if (canCheckRemotely) {
      return this._serverAdapter.hasRight(targetId, null)
    } else {
      console.error('Cannot check read access')
    }
    return false
  }

  private async _checkAccessWithRights(targetIdentifier: Types.IdOrReference, rightIdentifiers: Types.IdOrReference[]) {
    const canCheckLocally = !this._modelIncomplete
    const canCheckRemotely = this._serverAdapter != null

    const documentId = getDocumentId(targetIdentifier)
    const rightIds = rightIdentifiers.map(getDocumentId)

    const accessRightsNotCached = []

    // Check cache for each right first
    for (const rightId of rightIds) {
      const cachedAccessResult = this._hasRightCache.get(documentId, rightId)

      // We only need one
      if (cachedAccessResult === true) {
        return true
      }

      if (cachedAccessResult === false) {
        continue
      }

      accessRightsNotCached.push(rightId)
    }

    if (canCheckLocally || !canCheckRemotely) {
      if (!canCheckRemotely) {
        console.warn('Cannot check rights correctly. Model is not complete with no server adapter, but will try anyway')
      }
      
      for (const rightId of accessRightsNotCached) {
        const hasRight = await this._model.hasRight(targetIdentifier, rightId)
        this._hasRightCache.set(documentId, rightId, hasRight)

        if (hasRight) {
          return true
        }
      }
    } else if (canCheckRemotely) {
      // All rights not yet cached need to be checked with the server adapter when available or locally
      const pendingChecks = accessRightsNotCached.map(rightId => {
        if (canCheckRemotely) {
          return {
            rightId,
            request: this._serverAdapter.hasRight(documentId, [rightId])
          }
        }

        if (!canCheckLocally) {
          console.warn('Cannot check access correctly as the model is incomplete. Trying anyway since we have no adapter')
        }

        return {
          rightId,
          request: this._model.hasRight(documentId, [rightId])
        }
      })

      const evaluatedRights = await Promise.all(pendingChecks.map(p => p.request))

      for (const check of pendingChecks) {
        this._hasRightCache.set(documentId, check.rightId, await check.request)
      }

      return evaluatedRights.some(Boolean)
    } else {
      console.error('Cannot check rights')
      return true
    }
  }

  private _registerGlobalComponents() {
    Vue.component('ae-layout', LayoutComponent)
    Vue.component('ae-layout-selector', <any>LayoutSelectorComponent)
    Vue.component('ae-design', DesignComponent)
    Vue.component('ae-design-selector', <any>DesignSelectorComponent)
    Vue.component('ae-list', <any>ListComponent)
    Vue.component('ae-list-selector', <any>ListSelectorComponent)
    Vue.component('ae-query', QueryComponent)
    Vue.component('ae-vars', VarsComponent)
    Vue.component('ae-control', ControlComponent)
    Vue.component('ae-control-selector', <any>ControlSelectorComponent)
    Vue.component('ae-splitter', SplitterComponent)
    Vue.component('ae-tabs', TabsComponent)
    Vue.component('ae-expandable', ExpandableComponent)
    Vue.component('ae-popup', PopupComponent)
    Vue.component('ae-select', SelectComponent)
    Vue.component('ae-dialog', DialogComponent)
    Vue.component('ae-icon', IconComponent)
    Vue.component('ae-fetch', FetchComponent)
    Vue.component('ae-link', LinkComponent)
    Vue.component('ae-form', <any>FormComponent)
    Vue.component('ae-form-preview', <any>FormPreviewComponent)
    Vue.component('ae-form-section', <any>FormSectionComponent)
    Vue.component('ae-form-section-next', <any>FormSectionNextComponent)
    Vue.component('ae-form-field', <any>FormFieldComponent)
    Vue.component('ae-form-paragraph', <any>FormParagraphComponent)

    Vue.component('ae-header', <any>HeaderComponent)


    // Vue.component('ae-field', FieldComponent)
    // Vue.component(Dialog.name, Dialog)
  }

  private _registerGlobalDirectives() {
    Vue.directive('focus', FocusDirective)
    Vue.directive('observe-visibility', VisibilityDirective)
  }

  private _registerExtensions() {
    Vue.use(DragAndDropExtension)
    Vue.use(TranslateExtension, { aeppic: this.contextify('v-translate') })
    Vue.use(ResponsiveExtension, { aeppic: this.contextify('v-responsive') })
  }

  public get wasDestroyed() {
    return this._dynamic.wasDestroyed
  }

  public destroy() {
    if (this.context !== this._rootContext) {
      throw new Error('Contextified Aeppic instances should be released not destroyed')
    }

    this._dynamic.wasDestroyed = true
    this._dynamic.refreshPending = false

    try {
      this._vue?.$destroy()
    } catch (error) {
      console.error('ERROR destroying vue instance', error)
    }

    this._release()

    this._model.clear()

    this._emitter.removeAllListeners()

    if (this._serverAdapter && this._serverAdapter.disconnect) {
      this._serverAdapter.disconnect()
      
      if (this.Developer?.remoteDeveloperMode !== 'None') {
        this._serverAdapter?.disconnectDeveloper()
      }
    }

    this.Developer?.destroy()
  }

  private get destroyed() { return this._dynamic.wasDestroyed }

  private async _loadDocuments(options: LoadOptions, documentLookupCallback: DocumentLookupCallback, burst = 0) {
    if (!options.src) {
      return
    }

    if (typeof options.src === 'string') {
      return this._loadDocumentsFromUrl(options.src, options, documentLookupCallback, burst)
    } else {
      return this._loadDocumentsFromFunction(options.src, options, documentLookupCallback, burst)
    }
  }

  private async _loadDocumentsFromUrl(src: string, options: LoadOptions, documentLookupCallback: DocumentLookupCallback, burst = 0) {
    let importFirstSequenceOfFormsIntoFormsDirectoryOnly = false
    let rootDocumentHasPassed = false
    let indexOfRootObjectInBatch = -1

    const BATCH_SIZE = 300

    const response: Response | any = await this._fetchFromServerApi('GET', src)

    if ('ok' in response && !response.ok) {
      throw new Error(`Could not GET data from server (${response.status})`)
    }

    let start = Date.now()

    // const manifestLines = []
    let batch = []

    for await (const { lineNo, object: rawEventLine } of readJsonlObjects(response)) {
      const event = options.transform ? await options.transform(rawEventLine) : rawEventLine

      const isDocumentDataEvent = event.data && event.id && event.f && event.f.id

      if (!rootDocumentHasPassed) {
        const isManifestEntryLine = isDocumentDataEvent
          && event.f.id === '1327bd9b-34e5-4c38-bc43-5e709d8c01a2'
          && event.data.active

        if (isManifestEntryLine) {
          // manifestLines.push(line)

          // The presence of a manifest indicates the export has been preformed with 
          // the new code-base which may include detached form-versions which should
          // be imported into the model with formsDirectoryOnly flag. 
          //
          // this distinction can be removed once all code-bases have been updated
          importFirstSequenceOfFormsIntoFormsDirectoryOnly = true
          continue
        }

        const isFormDocumentEvent = event.f?.id === 'form'

        if (isFormDocumentEvent) {
          importFirstSequenceOfFormsIntoFormsDirectoryOnly = true
        }
      }

      const isRootDocument = event.id === 'root'

      if (isRootDocument && indexOfRootObjectInBatch === -1) {
        rootDocumentHasPassed = true
        indexOfRootObjectInBatch = batch.length
      }

      batch.push(event)

      if (batch.length < BATCH_SIZE) {
        continue
      }

      const batchToImport = batch
      batch = []

      if (importFirstSequenceOfFormsIntoFormsDirectoryOnly) {
        await this._importIntoFormsDirectoryOnlyOrIntoModelToo(batchToImport, rootDocumentHasPassed, indexOfRootObjectInBatch, { documentLookupCallback })
        indexOfRootObjectInBatch = -1
      } else {
        await this.import(batchToImport, { documentLookupCallback })
      }

      if (burst) {
        const now = Date.now()
        const timeSpentOnLastImport = now - start

        if (timeSpentOnLastImport > burst) {
          await this.waitForNextAnimationFrame()
          start = Date.now()
        }
      }
    }

    if (importFirstSequenceOfFormsIntoFormsDirectoryOnly) {
      await this._importIntoFormsDirectoryOnlyOrIntoModelToo(batch, rootDocumentHasPassed, indexOfRootObjectInBatch, { documentLookupCallback })
    } else {
      await this.import(batch, { documentLookupCallback })
    }
  }

  private async _loadDocumentsFromFunction(srcFunction: DocumentSourceFunction, options: LoadOptions, documentLookupCallback: DocumentLookupCallback, burst = 0) {
    const arrayOfEvents = []
    const stream = await srcFunction()

    let currentDocument: any

    while (currentDocument = stream.read()) {
      arrayOfEvents.push(currentDocument)
    }

    let events: any[]

    if (options.transform) {
      events = await options.transform(arrayOfEvents)
    } else {
      events = arrayOfEvents
    }

    await this.import(events, { documentLookupCallback })
  }

  private async _importIntoFormsDirectoryOnlyOrIntoModelToo(batch, rootDocumentHasPassed, indexOfRootObjectInBatch, options) {
    if (!rootDocumentHasPassed) {
      await this.import(batch, { ...options, addIntoFormsDirectoryOnly: true })
    }
    else if (rootDocumentHasPassed && indexOfRootObjectInBatch >= 0) {
      let intoFormsDirectoryOnlyBatch = batch.slice(0, indexOfRootObjectInBatch)
      let intoModelTooBatch = batch.slice(indexOfRootObjectInBatch, batch.length)

      await this.import(intoFormsDirectoryOnlyBatch, { ...options, addIntoFormsDirectoryOnly: true })
      await this.import(intoModelTooBatch, options)
    }
    else {
      await this.import(batch, options)
    }
  }

  public waitForNextAnimationFrame() {
    return new Promise((resolve) => {
      if (isInBrowser() && window.requestAnimationFrame) {
        window.requestAnimationFrame(resolve)
      } else {
        setTimeout(resolve, 0)
      }
    })
  }

  public waitForNextTick() {
    return waitForNextTick()
  }

  public waitForNextMicroTick() {
    return waitForNextMicroTick()
  }

  public get el() {
    return this._rootContext.rootElement
  }

  public mount(elementOrSelector: string | HTMLElement) {
    this._vue.$mount(elementOrSelector)
  }

  public async clone(identiferOrReference: Types.IdOrReference, options?: CloneOptions): Promise<EditableDocument>
  public async clone(identiferOrReference: Types.IdOrReference, parentIdentiferOrReference?: Types.IdOrReference, options?: CloneOptions): Promise<EditableDocument>
  public async clone(identiferOrReference: Types.IdOrReference, ...args: any[]): Promise<EditableDocument> {
    const parentIdentiferOrReferenceOrOptions = args[1]
    const cloneToParent = parentIdentiferOrReferenceOrOptions && isReference(parentIdentiferOrReferenceOrOptions)

    const [document] = await this.getAll([
      identiferOrReference,
      cloneToParent && parentIdentiferOrReferenceOrOptions
    ])

    await this.getFormForDocument(document)

    return this._model.clone(identiferOrReference, ...args)
  }

  async cloneDeep(identiferOrReference: Types.IdOrReference, parentIdentiferOrReferenceOrEditableDocument: Types.IdOrReference | EditableDocument, options: { save?: boolean, formFieldsNotToClone?: Object, identifiersNotToClone?: Types.IdOrReference[], readonly?: boolean, hidden?: boolean } = {}) {
    const identifier = isReference(identiferOrReference) ? identiferOrReference.id : identiferOrReference

    let descendantsQuery = `a:${identifier}`

    // TODO: exclude identifiersNotToClone already in query
    // if (options?.identifiersNotToClone) {
    //   const identifierNotToClone = options.identifiersNotToClone.map(identifier => isReference(identifier) ? identifier.id : identifier)
    //   descendantsQuery = `${descendantsQuery} AND NOT (${identifierNotToClone.map(id => `id:${id} OR a:${id}`)})`
    // }

    const [ensuredIdentifierAndParent, ensuredDescendents] = await Promise.all([
      this.getAll([identiferOrReference, parentIdentiferOrReferenceOrEditableDocument]),
      this.find(descendantsQuery, { size: Number.MAX_SAFE_INTEGER })
    ])

    // NOTE: parent in ensuredIdentifierAndParent is null, if it was an EditableDocument
    await Promise.all([...ensuredIdentifierAndParent, ...ensuredDescendents].map(document => document && this.getFormForDocument(document)))

    const result = this._model.cloneDeep(identiferOrReference, parentIdentiferOrReferenceOrEditableDocument, { formFieldsNotToClone: options.formFieldsNotToClone, identifiersNotToClone: options.identifiersNotToClone, readonly: options.readonly })

    if (options && options.save) {
      this.saveAll(result.documents)
    }

    return result
  }

  async new(formId: string, parent: Types.IdOrReference, options?: NewDocumentOptions): Promise<EditableDocument> {
    await this.getAll([formId, parent])

    const latestForm = await this.getForm(formId)

    if (!latestForm) {
      throw new Error(`Form '${formId}' not found`)
    }

    const skipDefaults = options?.skipDefaults === true
    const useDefaults = !skipDefaults
    const defaults = latestForm.document.data.defaults

    if (useDefaults && defaults) {
      switch (defaults) {
        case 'TEMPLATE':
          // Ensure that the template is loaded
          const templateDocument = await this.get(latestForm.document.data.newTemplate as Types.Reference)
          await this.getFormForDocument(templateDocument)
          break;
        case 'ACTION':
          // Load the action and run it
          const action = await this.get(latestForm.document.data.newAction as Types.Reference)
          const [result] = await this.Actions.run([action], { parent, form: latestForm })

          if (result.ok) {
            if (this.isEditableDocument(result.value)) {
              const newDocument = result.value
              this._model.commit(newDocument)
              return newDocument
            } else {
              throw new Error(`Action '${action.data.name} (${action.id})' did not return an editable document`)
            }
          } else {
            throw new Error(`Action '${action.data.name} (${action.id})' failed: ${result.error}`)
          }
          break
        default:
          this.Log.warn("Document form (%s '%s') wants defaults, but those are not yet defined. Falling through to standard behavior", formId, latestForm.data.name)
      }
    }

    const newDocument = this._model.new(formId, parent, options)
    this._model.commit(newDocument)

    return newDocument
  }

  async import(operationsParameter: ImportOperation | ImportOperation[], options?: { addIntoFormsDirectoryOnly?: boolean, documentLookupCallback?: DocumentLookupCallback }): Promise<ImportResult> {
    const operations: ImportOperation[] =
      Array.isArray(operationsParameter) ? operationsParameter : [operationsParameter]

    if (operations.length === 0) {
      return { added: 0, failed: [], cycles: 0, ignored: 0, deleted: 0 }
    }

    const importOptions = { documentLookupCallback: this._lookup, ...options }

    const result = await importIntoModel(this._model, operations, importOptions)

    if (result && result.added && result.deleted) {
      this._scheduleRefresh()
      await this._model.flushAllPendingNotifications()
    }

    return result
  }

  commit(document: EditableDocument) {
    if (!EditableDocument.isEditableDocument(document)) {
      throw new Error('Argument must be an EditableDocument')
    }

    // TODO: Extract files if required
    this._startPendingFieldUploads(document)
    this._model.commit(document)
  }

  private _startPendingFieldUploads(document: EditableDocument) {
    const uploadFields = document.retrieveUploads()

    if (uploadFields) {
      for (const fieldName of uploadFields) {
        this._triggerUploadOfFileField(document, fieldName)
      }
    }
  }

  private _triggerUploadOfFileField(document: EditableDocument, fieldName) {
    const field = document.data[fieldName]
    const content = this._urlToDataCache.get(field.dataUrl)

    if (content) {
      this._uploads.add({ document, fieldName, dataUrl: field.dataUrl, content })
    }
  }

  async readAsBuffer(field: FileField): Promise<Buffer> {
    if (typeof Buffer === 'undefined') {
      throw new Error('Buffer not supported')
    }

    const cachedContent = this._urlToDataCache.get(field.dataUrl)

    if (cachedContent) {
      if (Buffer.isBuffer(cachedContent)) {
        return cachedContent
      } else {
        throw new Error('not yet supported')
      }
    } else {
      throw new Error('not yet supported')
    }
  }

  /**
   * @internal
   *
   * This function should NOT be used by user code directly
   */
  knowsLocally(identifier: Types.IdOrReference): boolean {
    let document = this._model.get(identifier)
    return document != null
  }

  isFlagEnabled(flagName: FlagNames) {
    return this._flags[flagName].enabled
  }

  // Document Modification Methods
  public sendToWebsocket(message) {
    if (this._serverAdapter) {
      this._serverAdapter.sendToWebsocket(message)
    }
  }

  async stamp(target: Types.Document, stampType: Types.IdOrReference | EditableDocument, options?: StampOptions) {
    await this.get('stamp')

    if (!EditableDocument.isEditableDocument(stampType)) {
      await this.get(stampType)
    }
    if (options && options.workflow) {
      await this.get(options.workflow)
    }

    const changes = this._model.stamp(target, stampType, options)

    this._handleChanges(changes)
  }

  async verifyStamp(target: Types.Document, stampOrStampDocument: Types.Stamp | Types.Document) {
    let document = null

    if (isStamp(stampOrStampDocument)) {
      document = await this.get(stampOrStampDocument.id)
    } else {
      document = await this.get(stampOrStampDocument)
    }

    return this._model.verifyStamp(target, document)
  }


  /**
   * 
   * @param document 
   */
  save(document: EditableDocument): void {
    if (!EditableDocument.isEditableDocument(document)) {
      throw new Error('Argument must be an EditableDocument')
    }

    if (!this._model.hasValidAncestry(document)) {
      this._changes.remember(document)
      return
    }

    this._changes.forget(document)

    this._startPendingFieldUploads(document)
    const changes = this._model.save(document)
    this._handleChanges(changes)

    const children = this._changes.removeRememberedChildren(document)

    for (const child of children) {
      this.save(child)
    }
  }

  saveDetachedVersion(document: Types.Document): void {
    if (EditableDocument.isEditableDocument(document)) {
      this._startPendingFieldUploads(document as EditableDocument)
    }

    const changes = this._model.saveDetachedVersion(document)
    this._handleChanges(changes)
  }

  saveAll(documents: EditableDocument[]): void {
    const changes = []

    for (const d of documents) {
      if (!EditableDocument.isEditableDocument(d)) {
        throw new Error('Argument must be an EditableDocument')
      }

      this._startPendingFieldUploads(d)
      const currentChanges = this._model.save(d)

      if (currentChanges) {
        changes.push(...currentChanges)
      }
    }

    this._handleChanges(changes)
  }

  change(identifier: Types.IdOrReference, targetForm: Types.IdOrReference, options?: ChangeDocumentFormOptions): void {
    this._change(identifier, targetForm, options)
  }

  private async _change(identifier: Types.IdOrReference, targetForm: Types.IdOrReference, options?: ChangeDocumentFormOptions) {
    const documentToChange = await this.get(identifier)

    const originalFormRequest = this.getFormForDocument(documentToChange)
    const targetFormRequest = this.getForm(targetForm)

    await Promise.all([originalFormRequest, targetFormRequest])

    const changes = this._model.change(identifier, targetForm, options)
    this._handleChanges(changes)
  }

  private _handleChanges(changes: Change[] | null | undefined) {
    if (!changes || changes.length === 0) {
      return
    }

    for (const change of changes) {
      if (change.document.f.id === 'design-form') {
        this.Designs.clearCache()
      }
    }

    if (this._serverAdapter) {
      this._tryToWriteChangesToPersistenceAdapter(changes)
      this._scheduleRefresh(1000)
    } else {
      this._scheduleRefresh()
    }
  }

  private _tryToWriteChangesToPersistenceAdapter(changes: Change[]) {
    const { pendingChanges, changesThatCanBeWritten } = this._categorizeChangesForPersistence(changes)

    this._serverAdapter.writeChanges(changesThatCanBeWritten)
    this._dynamic.pendingChanges.push(...pendingChanges)
  }

  private _categorizeChangesForPersistence(changes: Change[]) {
    const changesThatCanBeWritten = []
    const pendingChanges = []

    for (const change of changes) {
      if (!(change.type === ChangeType.ADDED_DETACHED_VERSION) && !this._model.hasValidAncestry(change.document)) {
        pendingChanges.push(change)
      } else if (this.Uploads.isUploadAssociatedWithDocument(change.document)) {
        pendingChanges.push(change)
      } else {
        changesThatCanBeWritten.push(change)
      }
    }

    return {
      changesThatCanBeWritten,
      pendingChanges
    }
  }

  async canUpgrade(identifier: Types.IdOrReference) {
    const document = await this.get(identifier)

    return this._model.canUpgrade(document)
  }

  upgrade(identifier: Types.IdOrReference): void {
    this._upgrade(identifier)
  }

  private async _upgrade(identifier: Types.IdOrReference) {
    // TODO: this should try to get the document first
    // await this.get(identifier)
    const documentToUpgrade = await this.get(identifier)

    const originalFormRequest = this.getFormForDocument(documentToUpgrade)
    const mostRecentFormRequest = this.getForm(documentToUpgrade.f.id)

    await Promise.all([originalFormRequest, mostRecentFormRequest])

    const changes = this._model.upgrade(identifier)
    this._handleChanges(changes)
  }

  delete(identifier: Types.IdOrReference): void {
    this._delete(identifier)
  }

  private async _delete(identifier: Types.IdOrReference) {
    if (typeof identifier !== 'string') {
      identifier = identifier.id
    }

    const document = await this.get(identifier)

    if (document) {
      const documentForm = await this.getFormForDocument(document)
      if (!documentForm) {
        console.error('Could not find form for document')
        return
      }

      const changes = this._model.delete(document.id)
      this._handleChanges(changes)
    }
  }

  deleteHard(identifier: Types.IdOrReference): void {
    this._deleteHard(identifier)
  }

  private async _deleteHard(identifier: Types.IdOrReference) {
    if (typeof identifier !== 'string') {
      identifier = identifier.id
    }

    const document = await this.get(identifier)

    if (document) {
      const changes = this._model.deleteHard(document.id)
      this._handleChanges(changes)
    }
  }

  move(identifier: Types.IdOrReference, newParentIdentifier: Types.IdOrReference): void {
    this._move(identifier, newParentIdentifier)
  }

  private async _move(identifier: Types.IdOrReference, newParentIdentifier: Types.IdOrReference) {
    const document = await this.get(identifier)
    const newParent = await this.get(newParentIdentifier)

    if (document && newParent) {
      const [documentForm, newParentForm] = await Promise.all([this.getFormForDocument(document), this.getFormForDocument(newParent)])

      if (!documentForm || !newParentForm) {
        console.error('Could not find forms for documents')
        return
      }

      const changes = this._model.move(document.id, newParent.id)
      this._handleChanges(changes)
    } else {
      // TODO: LOG ERROR ?
    }
  }

  async restoreFromRecycler(documentId, parentId?: string) {
    await this.getAll([documentId, parentId])

    const changes = this._model.restoreFromRecycler(documentId, parentId)
    this._handleChanges(changes)
  }

  restoreSingleDocumentFromRecycler(documentId, parentId?: string) {
    // NOTE: this should try to get documentId and parentId
    // await this.getAll([documentId, parentId])

    const changes = this._model.restoreSingleDocumentFromRecycler(documentId, parentId)
    this._handleChanges(changes)
  }

  buildTemporaryForm(formDocument: Types.Document): Form {
    return this._model.buildTemporaryForm(formDocument)
  }

  buildTemporaryEditableDocument(form: Form): EditableDocument {
    return this._model.buildTemporaryEditableDocument(form)
  }

  isEditableDocument(document: Types.Document): document is EditableDocument {
    return EditableDocument.isEditableDocument(document)
  }

  loadStyle(style) {
    return loadStyle(style)
  }

  /**
   * Returns a reference to the specified document (loading the document if necessary)
   * based on best currently available data (for version and text field)
   */
  async asReference(identifier: Types.IdOrReference | Types.Document | string) {
    if (isDocument(identifier) || typeof identifier === 'string') {
      const document = await this.get(identifier)
      return this._model.asReference(document)
    } else {
      return { id: identifier.id, v: identifier.v, text: <string>identifier.text }
    }
  }

  async get(identifier: Types.IdOrReference | Types.Document, options?: GetOptions): Promise<Types.Document> {
    if (!identifier) {
      return null
    }

    const modelDocument = this.Model.get(identifier, options)

    if (modelDocument) {
      return modelDocument
    }

    await this._ensureBooted()
    return (<Promise<Types.Document>>this._get(identifier, options))
  }

  async getAll(identifiers: (Types.IdOrReference | Types.Document)[]): Promise<Types.Document[]> {
    if (!identifiers) {
      return []
    }

    await this._ensureBooted()

    if (!Array.isArray(identifiers)) {
      console.error('getAll requires an array of identifiers to retrieve. Variable argument list is no longer supported')
      return []
    }

    return <Promise<Types.Document[]>>this._get(identifiers)
  }

  async hasChildren(identifier: Types.IdOrReference): Promise<Boolean> {
    await this.findOne(`p:${identifier}`)
    return this._model.hasChildren(identifier)
  }

  async getForm(identifier: Types.IdOrReference): Promise<Form> {
    if (isReference(identifier)) {
      const knownForm = this._model.getForm(identifier)

      if (knownForm) {
        return knownForm
      } else {
        return this._loadFormVersion(identifier.id, identifier.v)
      }
    } else {
      return this._loadFormVersion(identifier)
    }
  }

  private async _loadFormVersion(id: string, v?: string) {
    if (!v) {
      const existing = this._model.get(id)
      if (existing) {
        return this._model.getMostRecentForm(id)
      }
    } else {
      const existing = this._model.getForm({ id, v })
      if (existing) {
        return this._model.getForm({ id, v })
      }
    }

    const requests = [
      this._loadFromPersistenceAdapter(id)
    ]

    if (v) {
      requests.push(this._loadFromPersistenceAdapter(id, v))
    }

    await Promise.all(requests)

    if (v) {
      return this._model.getForm({ id, v })
    } else {
      return this._model.getMostRecentForm(id)
    }
  }

  async getFormForDocument(document: Types.Document | EditableDocument): Promise<Form> {
    if (EditableDocument.isEditableDocument(document)) {
      return document.form
    }

    return this.getForm(document.f)
  }

  /** @deprecated Use getFormForDocument instead */
  @deprecated('getFormForDocument', 'Use getFormForDocument instead')
  getDocumentForm(document: Types.Document): any {
    return this._model.getDocumentForm(document)
  }

  async getFormVersions(formIdentifier: Types.IdOrReference): Promise<string[]> {
    if (this._serverAdapter) {
      return this._serverAdapter.getFormVersions(formIdentifier)
    } else {
      return this._model.getFormVersions(formIdentifier)
    }
  }

  private async _get(identifier: Types.IdOrReference | Types.Document, options?: GetOptions): Promise<Types.Document>
  private async _get(identifier: (Types.IdOrReference | Types.Document)[], options?: GetOptions): Promise<Types.Document[]>
  private async _get(identifier: Types.IdOrReference | Types.Document | (Types.IdOrReference | Types.Document)[], options?: GetOptions): Promise<Types.Document | Types.Document[]> {
    if (identifier == null) {
      return null
    }

    const identifiers = Array.isArray(identifier) ? identifier : [identifier]

    const documents = identifiers.map(async (ident) => {
      if (!ident) {
        return null
      }

      let document = this._model.get(ident, options)

      if (document) {
        return document
      }

      // const documentId = getDocumentId(ident)

      // document = await this._documentCache.lookup(documentId)

      // if (document) {
      //   return document
      // }
      await this._ensureLoaded()

      document = this._model.get(ident, options)

      if (document) {
        return document
      }

      if (this._modelIncomplete) {
        const documentId = getDocumentId(ident)
        await this._loadFromPersistenceAdapter(documentId, null, options)

        document = this._model.get(documentId, options) // || await this._documentCache.lookup(documentId)

        if (this.isFlagEnabled('logServerLoads')) {
          if (document) {
            this._log.debug({ flag: 'logServerLoads', document }, 'Loaded document %s (%s) at %s', document.id, document.data.name, document.a)
          } else {
            this._log.debug({ flag: 'logServerLoads' }, 'Could not load document %s', documentId)
          }
        }

        return document
      }

      return null
    })

    if (Array.isArray(identifier)) {
      return Promise.all(documents)
    }

    return documents[0]
  }

  public watch(identifier: Types.IdOrReference, callback: DocumentWatchCallback): IDocumentWatcher {
    if (!identifier) {
      return null
    }

    if (this.context.isReleased) {
      throw new Error('Context already released')
    }

    const watcher = this._model.watch(identifier, callback)

    const originalStop = watcher.stop.bind(watcher)

    watcher.stop = () => {
      originalStop()
      this.context.releaseUnnamedResource('DOCUMENT_WATCHER', watcher)
      this._scheduleWatcherCleanup()
    }

    this.context.addUnnamedResource('DOCUMENT_WATCHER', watcher)

    return watcher
  }

  /**
   * @deprecated
   *
   * Use watchMatchingDocuments instead
   * 
   * @param  query
   * @param  cb
   * @returns A watcher
   */
  public watchQuery(query, cb: DocumentWatchCallback): IQueryWatcher {
    this._deprecated('Use `watchMatchingDocuments` instead')
    return this.watchMatchingDocuments(query, cb)
  }

  /**
   * Watch all documents being changed in the underlying model and call
   * `cb` when they match the conditions of `query`
   * 
   * This is less resource intensive then subscribing to a query when
   * the initial state or a list of all matches is not required 
   *
   * @param query
   * @param cb
   * @returns 
   */
  public watchMatchingDocuments(query, cb: DocumentWatchCallback): IQueryWatcher {
    const watcher = this._model.watchMatchingDocuments(query, cb)
    const originalStop = watcher.stop.bind(watcher)

    watcher.stop = () => {
      originalStop()
      this.context.releaseUnnamedResource('QUERY_WATCHER', watcher)
      this._scheduleWatcherCleanup()
    }

    this.context.addUnnamedResource('QUERY_WATCHER', watcher)

    return watcher
  }

  public watchAllDocuments(callback: DocumentWatchCallback): IDocumentWatcher {
    return this._model.watchAll(callback)
  }

  public watchAll(identifiers: Types.IdOrReference[], callback: DocumentsWatchCallback): IDocumentWatcher {
    let documents = []

    const cb = (newDocument, changes) => {
      if (changes.deletedHard) {
        // remove
        const index = findDocumentIndex(documents, newDocument)
        if (index >= 0) {
          documents.splice(index, 1)
        }
      } else {
        const index = findDocumentIndex(documents, newDocument)

        if (index >= 0) {
          documents.splice(index, 1, newDocument)
        } else {
          documents.push(newDocument)
        }
      }

      callback(documents)
    }

    const watchers = identifiers.map((identifier) => {
      return this.watch(identifier, cb)
    })

    return (<IDocumentWatcher>{
      stop() {
        for (const watcher of watchers) {
          watcher.stop()
        }
      }
    })
  }

  watchRevisions(identifier: Types.IdOrReference, callback: DocumentWatchCallback): IDocumentWatcher {
    const watcher = this._model.watchRevisions(identifier, callback)

    const originalStop = watcher.stop.bind(watcher)

    watcher.stop = () => {
      originalStop()
      this.context.releaseUnnamedResource('DOCUMENT_WATCHER_REV', watcher)
      this._scheduleWatcherCleanup()
    }

    this.context.addUnnamedResource('DOCUMENT_WATCHER_REV', watcher)

    return watcher
  }

  integrate(editableDocument: EditableDocument, changedDocument: Types.Document) {
    this._model.mergeChangesIntoEditableDocument(editableDocument, changedDocument)
  }

  private async _ensureLoaded() {
    if (this._status.stage !== 'LOADED') {
      await this._delayUntilLoaded()
    }
  }

  private async _ensureBooted() {
    if (this._status.stage === 'BOOTING') {
      await this._delayUntilBooted()
    }
  }

  async edit(identifier: Types.IdOrReference, options?: GetOptions): Promise<EditableDocument> {
    const document = await this.get(identifier)

    if (!document) {
      throw new Error(`Unknown document ${identifier.toString()}`)
    }

    await this.getFormForDocument(document)

    return this._model.edit(document)
  }

  async editAll(identifiers: (Types.IdOrReference | Types.Document)[]): Promise<EditableDocument[]> {
    if (!Array.isArray(identifiers)) {
      console.error('editAll requires an array of identifiers to retrieve. Variable argument list is no longer supported')
      return []
    }

    const edits = identifiers.map(v => this.edit(v))
    return Promise.all(edits)
  }

  private _delayUntilLoaded() {
    if (!this._dynamic.pendingLoaded) {
      let resolve = null
      const promise = new Promise<void>(resolveCb => resolve = resolveCb)
      this._dynamic.pendingLoaded = { promise, resolve }
    }

    return this._dynamic.pendingLoaded.promise
  }

  private _delayUntilBooted() {
    if (!this._dynamic.pendingBooted) {
      let resolve = null
      const promise = new Promise<void>(resolveCb => resolve = resolveCb)
      this._dynamic.pendingBooted = { promise, resolve }
    }

    return this._dynamic.pendingBooted.promise
  }

  private _scheduleWatcherCleanup(refreshIn = 5) {
    if (this._dynamic.wasDestroyed) {
      return
    }

    if (!this._dynamic.watcherCleanupPending) {
      this._dynamic.watcherCleanupPending = true

      setTimeout(() => {
        this._dynamic.watcherCleanupPending = false

        this._model.cleanupWatchers()
        this._cleanupQueryWatchers()
      }, refreshIn)
    }
  }

  private _cleanupQueryWatchers() {
    const subscriptions = this._dynamic.querySubscriptions
    this._dynamic.querySubscriptions = []

    for (const s of subscriptions) {
      if (!s.cancelled) {
        this._dynamic.querySubscriptions.push(s)
      }
    }
  }

  private _scheduleRefresh(refreshIn = 0) {
    if (!this._dynamic || this._dynamic.wasDestroyed) {
      return
    }

    if (!this._dynamic.refreshPending) {
      this._dynamic.refreshPending = true

      setTimeoutNoKeepAlive(() => {
        if (!this._dynamic) {
          return
        }

        if (this._dynamic.refreshPending) {
          this._dynamic.refreshPending = false
          this._refresh()
        }
      }, refreshIn)
    }
  }

  private _refresh() {
    const now = new Date().valueOf()
    const timeSinceLastRefresh = now - this._dynamic.lastRefresh

    if (timeSinceLastRefresh < DEFAULT_MIN_REFRESH_DELAY_IN_MS) {
      const dueIn = (this._dynamic.lastRefresh + DEFAULT_MIN_REFRESH_DELAY_IN_MS - now) + 10
      this._scheduleRefresh(dueIn)
      return
    }

    this._dynamic.lastRefresh = now
    
    if (this._dynamic.querySubscriptions.length === 0) {
      return
    }

    // Run all refreshes in parallel. Any server calls
    // get batched anyway.
    const subscriptions = this._dynamic.querySubscriptions
    this._dynamic.querySubscriptions = []

    if (this.isFlagEnabled('logQueryRefresh')) {
      this._log.debug({ flag: 'logQueryRefresh', timeSinceLastRefresh }, 'Refreshing #%d queries ...', subscriptions.length)
    }


    for (const s of subscriptions) {
      if (!s.cancelled) {
        this._refreshQuerySubscription(s)
        this._dynamic.querySubscriptions.push(s)
      }
    }
  }

  private async _refreshQuerySubscription(s: QuerySubscription) {
    await s.refresh(this.find(s.queryString, s.findOptions))
  }

  /**
   *
   *
   * @private
   * @param documentId
   * @param version    Only declare version when the specific version is necessary
   * @returns A promise resolved when finish
   */
  private async _loadFromPersistenceAdapter(documentId: string, version?: string, options?: GetOptions): Promise<void> {
    if (this._dynamic.wasDestroyed) {
      return null
    }

    if (!documentId) {
      // this.Log.warn('Attempt to load empty documentId')
      return null
    }

    if (typeof documentId !== 'string') {
      this.Log.warn('Attempt to load documentId that is not a string', documentId)
      return null
    }

    if (this._serverAdapter) {
      const lookupIdentifier = `${documentId}@${version}`

      if (this._dynamic.pendingPersistenceAdapterNextLookup) {
        const alreadyScheduled = this._dynamic.pendingPersistenceAdapterLookupsSet.has(lookupIdentifier)

        if (alreadyScheduled) {
          return this._dynamic.pendingPersistenceAdapterNextLookup
        }
      }

      const lookups = this._dynamic.pendingPersistenceAdapterLookups // (options && options.noImport === true) ? this._dynamic.pendingPersistenceAdapterNonImportLookups : this._dynamic.pendingPersistenceAdapterLookups

      lookups.push({
        id: documentId,
        v: version
      })

      this._dynamic.pendingPersistenceAdapterLookupsSet.add(lookupIdentifier)

      return this._scheduleLookupInPersistenceAdapter()
    } else {
      this._dynamic.pendingLookups.push(documentId)
      this._dynamic.pendingLookups.push(null)

      return this._scheduleLookup()
    }
  }

  private _scheduleLookupInPersistenceAdapter() {
    if (this._dynamic.pendingPersistenceAdapterNextLookup) {
      return this._dynamic.pendingPersistenceAdapterNextLookup
    }

    const asyncExecution = this._executeAsyncLimited(async () => {
      const lookupInfo = this._dynamic.pendingPersistenceAdapterLookups
      this._dynamic.pendingPersistenceAdapterLookups = []
      this._dynamic.pendingPersistenceAdapterNextLookup = null
      this._dynamic.pendingPersistenceAdapterLookupsSet = new Set()

      if (lookupInfo.length > 0) {
        const documents = await this._performLookupInPersistenceAdapter(lookupInfo)
        await importIntoModel(this._model, documents, { documentLookupCallback: this._lookup })
      }

    })

    asyncExecution.catch((error: any) => {
      console.error('Error performing lookup', error)
    })

    this._dynamic.pendingPersistenceAdapterNextLookup = asyncExecution

    return asyncExecution
  }

  private async _executeAsyncLimited(callback: AsyncFunction) {
    const maximumConcurrentLookupCalls = this._options.maximumConcurrentLookupCalls || DEFAULT_MAXIMUM_CONCURRENT_LOOKUPS

    let executeAfter

    if (this._dynamic.concurrentLookups.length < maximumConcurrentLookupCalls) {
      executeAfter = Promise.resolve()
    } else {
      const lastLookup = this._dynamic.concurrentLookups[this._dynamic.concurrentLookups.length - 1]
      executeAfter = lastLookup
    }

    return executeAfter.then(() => {
      return this._executeAsync(callback)
    })
  }

  private async _executeAsync(callback: AsyncFunction) {
    const nextExecution = waitForNextTick()
    this._dynamic.concurrentLookups.push(nextExecution)

    return nextExecution.then(callback).finally(() => {
      const index = this._dynamic.concurrentLookups.findIndex(p => p === nextExecution)

      if (index >= 0) {
        this._dynamic.concurrentLookups.splice(index, 1)
      } else {
        console.warn('CONCURRENT LOOKUP ALREADY REMOVED?')
      }
    })
  }

  private async _performLookupInPersistenceAdapter(lookupInfo: IDocumentLookup[]) {
    // if (lookupInfo.length === 0) {
    //   return
    // }    
    if (this.isFlagEnabled('logServerLoads')) {
      this._log.debug({ flag: 'logServerLoads', lookupInfo }, 'Loading #%d documents ...', lookupInfo.length)
    }

    const documents = await this._serverAdapter.getDocuments(lookupInfo)

    if (this.isFlagEnabled('logServerLoads')) {
      this._log.debug({ flag: 'logServerLoads', lookupInfo, documents }, 'Loaded #%d documents via persistence adapter', documents.length)
    }

    for (let i = 0; i < lookupInfo.length; i += 1) {
      const li = lookupInfo[i]
      const d = documents[i]

      if (!d) {
        // tslint:disable-next-line
        // console.warn('could not find', li.id, li.v)
        // tslint:disable-next-line
        // console.log(lookupInfo, documents)
      } else if (li.id !== d.id) {
        // tslint:disable-next-line
        console.error('found wrong document', li.id, d.id)
      } else if (li.v && li.v !== d.v) {
        // tslint:disable-next-line
        console.error('found wrong document version', li.id, li.v, d.id, d.v)
      } else {
      }
    }

    return documents
  }

  private async _scheduleLookup() {
    if (this._dynamic.wasDestroyed) {
      return
    }

    await waitForNextMicroTick()

    if (this._dynamic.pendingLookups.length === 0) {
      return
    }

    const lookupInfo = this._dynamic.pendingLookups
    this._dynamic.pendingLookups = []

    const documents = await this._lookup(lookupInfo)
    await importIntoModel(this._model, documents, { documentLookupCallback: this._lookup })
  }

  async query(queryIdentifier: Types.IdOrReference | Types.Document, rootDocumentIdentifier: Types.IdOrReference, filter?: string, options?: FindOptions): Promise<Types.Document[]> {
    const optionsParam = typeof filter === 'string' ? options : filter
    const filterParam = typeof filter === 'string' ? filter : null

    const [queryDocument] = await this.getAll([queryIdentifier, rootDocumentIdentifier])

    const searchString = this._queryDocumentToSearchString(queryDocument, rootDocumentIdentifier, filterParam)

    const opts = {
      sort: <string>queryDocument.data.sort,
      ...optionsParam
    }

    return this.find(searchString, opts)
  }

  /**
   * Resolves a document path starting from a given document. 
   * 
   * @param start The starting document ID or reference.
   * @param path The path to resolve (e.g data.ref -> data.ref -> modified.by).
   *             It must end with a reference field
   * 
   * @returns A promise that resolves to the document at the end of the path or null
   */
  async resolve(start: Types.IdOrReference, path: string): Promise<Types.Document> {
    return resolveDocumentPath(start, path, this)
  }

  /**
   * Resolves a document path starting from a given document.
   * 
   * @param start The starting document ID or reference.
   * @param path  The path to resolve (e.g data.ref -> data.myRef -> modified.by).
   *              It must end with a reference field
   * 
   * @returns A promise that resolves to the document at the end of the path as an EditableDocument or null
   */
  async resolveEdit(start: Types.IdOrReference, path: string): Promise<Types.Document> {
    const document = await resolveDocumentPath(start, path, this)

    if (document) {
      return this.edit(document)
    } else {
      return null
    }
  }

  /**
   * Resolves a document path starting from a given document and then resolves a field at the end of the path.
   * 
   * @param start The starting document ID or reference.
   * @param path The path to resolve (e.g data.ref -> data.myRef -> modified.by -> data.name).
   * 
   * @returns A promise that resolves to the document at the end of the path or null
   */
  async resolveToField(start: Types.IdOrReference, path: string): Promise<any> {
    const parts = path.split('->')
    const lastPart = parts.pop()
    const remainingParts = parts.join('->')

    const document = await this.resolve(start, remainingParts)
    
    if (document) {
      return resolveField(document, lastPart.trim())
    } else {
      return null
    }
  }

  /**
   * Find documents given a Query object and some options
   * 
   * @param query 
   * @param options 
   * 
   * {@label FindWithQueryAndOptions}
   */
  find(query: Query.Query, options?: FindOptions): Promise<Types.Document[]>

  /**
   * Find documents given a QueryBuilder. The query builder will be finalized
   * with this call and cannot be used as a builder afterwards
   * 
   * @param query 
   * @param options 
   *
   * @beta
   */
  find(query: Query.QueryBuilder, options?: FindOptions): Promise<Types.Document[]>
  find(query: string, options?: FindOptions): Promise<Types.Document[]>
  find(query: string, filterString?: string, options?: FindOptions): Promise<Types.Document[]>
  find(queryArgument: string | Query.Query | Query.QueryBuilder, param1?: string | FindOptions, param2?: FindOptions): Promise<Types.Document[]> {
    const { query, options } = toQueryAndOptions(queryArgument, param1, param2)
    return this._find(query, options)
  }

  async findOne(query: Query.Query, options?: FindOptions): Promise<Types.Document>
  async findOne(query: Query.QueryBuilder, options?: FindOptions): Promise<Types.Document>
  async findOne(query: string, options?: FindOptions): Promise<Types.Document>
  async findOne(query: string, filterString?: string, options?: FindOptions): Promise<Types.Document>
  async findOne(queryArgument: string | Query.Query | Query.QueryBuilder, param1?: string | FindOptions, param2?: FindOptions): Promise<Types.Document> {
    const { query, options } = toQueryAndOptions(queryArgument, param1, param2)

    const limitedOptions = { ...options, size: 1 }
    const documents = await this._find(query, limitedOptions)
    return documents[0]
  }

  private async _find(query: Query.Query, options?: FindOptions): Promise<Types.Document[]> {
    if (query.graph.conditions.length === 0) {
      console.warn('Empty query')
      return []
    }

    const cacheableQuery = { ...query, options: { ...query.options, nowUtcTz: '' } }
    const queryCacheKey = this._calculateQueryCacheKey(cacheableQuery, options)

    const searchInModel = (this._serverAdapter == null)

    // TODO: Can be refactored to make more clear. E.g a once<T> or limit<T> function
    if (searchInModel) {
      const activePromise = this._activeModelQueries.get(queryCacheKey)

      if (activePromise) {
        this.Log.debug({ queryCacheKey }, 'Reusing active model query')
        return activePromise
      }

      const deferred = buildDeferred<Types.Document[]>()
      this._activeModelQueries.set(queryCacheKey, deferred.promise)

      waitForNextMicroTick().then(() => {
        try {
          const documents = this._model.find(query, options)
          this._activeModelQueries.delete(queryCacheKey)
          deferred.resolve(documents)
        } catch (error) {
          this._activeModelQueries.delete(queryCacheKey)
          this.Log.error({ query, options, error: error.toString(), errorStack: error.stack }, 'Could not execute query against model: %s', error.toString())
          deferred.resolve([])
        }
      })

      return deferred.promise
    } else {
      const activePromise = this._activeServerQueries.get(queryCacheKey)

      if (activePromise) {
        // this.Log.debug({ queryCacheKey }, 'Reusing active server query')
        return activePromise
      }

      const deferred = buildDeferred<Types.Document[]>()
      this._activeServerQueries.set(queryCacheKey, deferred.promise)

      waitForNextMicroTick().then(async () => {
        try {
          if (this.isFlagEnabled('logServerLoads')) {
            this._log.debug({ flag: 'logServerLoads', query }, 'Finding documents: %s', query)
          }

          const documents = await this._serverAdapter.find(query, options)

          if (!isQueryOfPartialDocuments(query, options)) {
            await this._importQueue.enqueue(documents)
          }

          this._activeServerQueries.delete(queryCacheKey)
          deferred.resolve(documents)
        } catch (error) {
          this._activeServerQueries.delete(queryCacheKey)
          this.Log.error({ query, options, error: error.toString(), errorStack: error.stack }, 'Could not execute query against server: %s', error.toString())
          deferred.resolve([])
        }
      })

      return deferred.promise
    }
  }

  private _calculateQueryCacheKey(query: Query.Query, options: FindOptions = {}) {
    const cacheKey = JSON.stringify(query) + '|' + (options ? JSON.stringify(options) : '')
    return cacheKey
  }

  async match(identifier: Types.IdOrReference | Types.Document, queryString: string, options?: Query.MatchOptions): Promise<Boolean> {
    const document = await this.get(identifier)
    if (document) {
      await this.getFormForDocument(document)
    }

    return this._model.match(document, queryString, options)
  }

  async matchAll(identifiers: (Types.IdOrReference | Types.Document)[], queryString: string, options?: Query.MatchOptions): Promise<Boolean[]> {
    const documents = await this.getAll(identifiers)

    return documents.map(d => this._model.match(d, queryString, options))
  }

  subscribeQuery(identifier: Types.IdOrReference | Types.Document, rootDocumentIdentifier: Types.IdOrReference, filter?: string, cb?: (documents: Types.Document[]) => {}): IQuerySubscription {
    if (this.context.isReleased) {
      throw new Error('Context already released')
    }

    const cbParam = typeof filter === 'function' ? filter : cb
    const filterParam = typeof filter === 'string' ? filter : null

    if (this.knowsLocally(identifier) && this.knowsLocally(rootDocumentIdentifier)) {
      const searchString = this._queryDocumentToSearchString(identifier, rootDocumentIdentifier, filterParam)
      return this.subscribe(searchString, cbParam)
    }

    const subscription = this.subscribe('', cbParam)

    this._get([identifier, rootDocumentIdentifier]).then(() => {
      const searchString = this._queryDocumentToSearchString(identifier, rootDocumentIdentifier, filterParam)
      subscription.updateQuery(searchString)
    })

    return subscription
  }

  updateSubscription(subscription: IQuerySubscription, queryString: string, cb?: (documents: Types.Document[]) => {}): IQuerySubscription
  updateSubscription(subscription: IQuerySubscription, queryString: string, options?: FindOptions, cb?: (documents: Types.Document[]) => {}): IQuerySubscription
  updateSubscription(subscription: IQuerySubscription, queryString: string, param2?: any, param3?: any): IQuerySubscription {
    if (subscription) {
      const isCallback = typeof param2 === 'function'
      const options = isCallback ? {} : param2
      const changed = subscription.updateQuery(queryString, options)

      if (changed) {
        subscription.refresh(this.find(queryString, options))
      }

      return subscription
    } else {
      return this.subscribe(queryString, param2, param3)
    }
  }

  subscribe(queryString: string, cb?: (documents: Types.Document[]) => {}): IQuerySubscription
  subscribe(queryString: string, options?: FindOptions, cb?: (documents: Types.Document[]) => {}): IQuerySubscription
  subscribe(queryString: string, param2?: any, param3?: any) {
    let options: FindOptions
    let cb: Function

    if (param2 && typeof param2 !== 'function') {
      options = param2
      cb = param3
    } else {
      cb = param2
    }

    if (this.context.isReleased) {
      throw new Error('Context already released')
    }

    const localData = this._modelIncomplete ? [] : this._model.find(queryString, options)

    const newSubscription = new QuerySubscription(queryString, options, localData)
    this._dynamic.querySubscriptions.push(newSubscription)

    if (this._modelIncomplete) {
      newSubscription.refresh(this.find(queryString, options))
    }

    const originalCancel = newSubscription.cancel.bind(newSubscription)

    newSubscription.cancel = () => {
      originalCancel()
      this.context.releaseUnnamedResource('QUERY_SUBSCRIPTION', newSubscription)
    }

    this.context.addUnnamedResource('QUERY_SUBSCRIPTION', newSubscription)

    if (cb) {
      newSubscription.on('refreshed', (info) => {
        cb(newSubscription.documents, info)
      })
    }

    return newSubscription
  }

  private _queryDocumentToSearchString(identifier: Types.IdOrReference | Types.Document, rootDocumentIdentifier: Types.IdOrReference, filter?: string): string {
    const queryDocument = this._model.get(identifier)
    const rootDocument = this._model.get(rootDocumentIdentifier)

    return buildCompleteSearchString(queryDocument.data.query, filter, queryDocument.data.type, rootDocument, queryDocument)
  }

  async renderLayout(layoutIdentifier: Types.IdOrReference, options: LayoutRenderOptions = DEFAULT_LAYOUT_OPTIONS) {
    await this._ensureLoaded()

    this.Root.type = 'layout'
    this.Root.layout.id = getDocumentId(layoutIdentifier)
    this.Root.params = options.params

    const dontWaitForRenderPasses = options && options.noWait

    if (!dontWaitForRenderPasses) {
      await this.nextRenderPass() // Wait for ae-root to get the right layout
      await this.nextRenderPass() // The generic layout component needs to be build first (due to packages this is happening async)
      await this.nextRenderPass() // The layout is read asynchronously from the model so there is an extra step
      await this.nextRenderPass() // Ensure the specific dynamic layout component to be actually built
      await this.nextRenderPass()
      await this.nextRenderPass()
    }

    if (!this._dynamic.firstLayoutRendered) {
      if (this._options.removeAfterRender) {
        await this.nextRenderPass() // Ensure the specific dynamic layout component to be actually built
        removeElement(this._options.removeAfterRender)
      }

      this._dynamic.firstLayoutRendered = true
    }

    if (isInBrowser()) {
      this.dispatchDOMEvent(DOMEvents.Navigated, options.params)
    }
  }

  public onLinkClicked(e, href) {
    if (!this._dynamic.router) {
      return
    }

    if (this._dynamic.router.onLinkClicked) {
      this._dynamic.router.onLinkClicked(e, href)
    }
  }

  public copyToClipboard(text: string) {
    if (typeof text !== 'string') {
      throw new Error('only text supported')
    }

    if (!this.el) {
      throw new Error('Cannot copy to clipboard when not bound to DOM')
    }

    const textArea = document.createElement('textarea')
    const style = textArea.style

    style.position = 'fixed'
    style.top = '0'
    style.left = '0'
    style.width = '2em'
    style.height = '2em'
    style.padding = '0'
    style.border = 'none'
    style.outline = 'none'
    style.boxShadow = 'none'
    style.background = 'transparent'

    textArea.value = text

    this.el.appendChild(textArea)

    textArea.select()

    let successful = false

    try {
      successful = document.execCommand('copy')
    } catch (error) {
      // tslint:disable-next-line    
      console.error('Could not copy to clipboard', error)
    }

    textArea.remove()
    return successful
  }

  public navigateTo(documentIdentifier: Types.Document | Types.IdOrReference, navigationParams?: RouteParams) {
    const documentId = getDocumentId(documentIdentifier)

    if (!this._dynamic.router) {
      return
    }

    if (this.context.isReleased) {
      throw new Error('Context already released')
    }

    const shouldContinue = this.dispatchDOMEvent(DOMEvents.WillNavigate, { ...navigationParams, documentId })

    if (shouldContinue) {
      if (this._dynamic.router.onNavigateTo) {
        this._dynamic.router.onNavigateTo(documentId, navigationParams)
      } else if (this._dynamic.router.onLinkClicked) {
        this._dynamic.router.onLinkClicked(null, documentId)
      }
    }
  }

  public drillInto(documentIdentifier: string | Types.Document | EditableDocument, options: DrillIntoOptions = {}) {

    if (this.context.isReleased) {
      throw new Error('Context already released')
    }


    const context = this.context

    const eventDetail = {
      targetDocument: documentIdentifier,
      sourceContext: context,
      sourceElement: options.source || this.context.rootElement,
      ...options,
    }

    this.dispatchDOMEvent(DOMEvents.DrillInto, eventDetail)
  }

  public get DOMEvents() {
    return DOMEvents
  }

  public dispatchDOMEvent(name: string, detail: object) {
    const rootElement = this.context.rootElement
    const event = new CustomEvent(name, { detail, bubbles: true, cancelable: true })

    if (rootElement && rootElement.dispatchEvent) {
      return rootElement.dispatchEvent(event)
    } else {
      console.error(`Cannot emit ${name} event from Aeppic instance without HTML element root to dispatch from in context`)
    }

    return true
  }

  async nextRenderPass(cb?: Function): Promise<void> {
    if (this.context.isReleased) {
      throw new Error('Context already released')
    }

    await Vue.nextTick()

    if (cb) {
      cb()
    }
  }



  // private findTarget(targetNodeIdentifierOrElement ?: string | Element) {
  //   if (!targetNodeIdentifierOrElement) {
  //     if (!this._el) {
  //       throw new Errors.NoTargetElementSpecified()
  //     }
  //     return this._el
  //   }

  //   const target = findElement(targetNodeIdentifierOrElement)

  //   if (!target) {
  //     throw new Errors.TargetElementNotFound(targetNodeIdentifierOrElement)
  //   }

  //   return target
  // }

  public openFile(url: string, fileName: string) {
    // TODO: add interfaces. Improve check. See src/package-loaders/load-package-filesystem
    const win: any = window

    if (!win.host || !win.host.exports) {
      return
    }

    win.host.exports.openFile(url, fileName, (err) => {
      if (err) {
        throw new Error(`[DEBUG]: openFile failed for ${url} ${fileName}: ${err.toString()}`)
      }
      // console.log(`[DEBUG]: openFile finished for for ${url} ${fileName}`)
    })
  }

  public async editFile(url: string, fileName: string, documentInformation = { id: '', v: '', fieldName: '', mimeType: '' }) {
    const win: any = window

    if (!win.host) {
      console.warn('Cannot edit file. No host found')
      return
    }

    if (win.host.type === 'aeppic-client') {
      return this._editFileAeppicClient(url, fileName, documentInformation)
    }

    return this._editFileElectron(url, fileName, documentInformation)
  }

  _editFileAeppicClient(url: string, fileName: string, documentInformation = { id: '', v: '', fieldName: '', mimeType: '' }) {
    const document = this._model.get(documentInformation.id)

    if (!document) {
      console.warn('Cannot edit file. Document not found')
      return
    }

    const field = document.data[documentInformation.fieldName] as FileField

    if (!field) {
      console.warn('Cannot edit file. Field not found')
      return
    }

    if (Array.isArray(field)) {
      console.warn('Cannot edit file. Field is an array')
      return
    }

    if (!field.dataUrl || field.dataUrl.startsWith('data:') || field.dataUrl.startsWith('blob:') || field.dataUrl.startsWith('file:')) {
      console.warn('Cannot edit file. Field dataUrl is not a downloadable file url')
      return
    }

    if ('host' in window === false) {
      console.warn('Cannot edit file. No host found')
      return
    }

    const host = (<any>window).host as AeppicClientHostInterface

    if (host.type !== 'aeppic-client') {
      console.warn('Cannot edit file. No aeppic-client host found')
      return
    }

    if (!host.matches('^1.3.7')) {
      console.warn('Cannot edit file. aeppic-client host version is too old')
      return
    }

    if (!host.features.files.edit) {
      console.error('Cannot edit file. Host does not allow editing files')
      return
    }

    const extendedDocumentInformation = {
      ...documentInformation,
      dataUrl: field.dataUrl,
    }

    if (!host.files.subscribeToEditEvents) {
      console.error('Cannot edit file. No host.files.subscribeToEditEvents found')
      return
    }
    
    //
    // If the host is not yet subscribed to edit events, do so now
    //
    if (!this._dynamic.unsubscribeFromEditEvents) {
      //
      // The events are coming from the host and will be rewritten
      // as `FileEditEvents` to remove the nested structure due to enum
      // usage in the host.
      //
      this._dynamic.unsubscribeFromEditEvents = host.files.subscribeToEditEvents(async (editEvent) => {
        // TODO: Serialize these events (in case many events come)
        const { payload } = editEvent

        let event: FileEditEvent = null
    
        function setEventData(type: string, info) {
          const uploadEvents = ['upload:started', 'upload:canceling', 'upload:canceled', 'upload:failed', 'upload:finished']
          const fileEvents = ['file:changed']
       
          if (uploadEvents.includes(type)) {
            const uploadInfo = info as DocumentUploadInfo

            event = {
              type: type as FileEditEvent['type'],
              localFilePath: uploadInfo.filePath,
              document: uploadInfo.document,
              fieldName: uploadInfo.fieldName,
              uploadInfo: uploadInfo,
              detectedAt: new Date().valueOf(),
            } as FileUploadEvent
          } else if (fileEvents.includes(type)) {
            const [filePath, editInfo]:[LocalFilePath, DocumentEditInfo] = info

            event = {
              type: type as FileEditEvent['type'],
              localFilePath: filePath,
              document: editInfo.document,
              fieldName: editInfo.fieldName,
              editInfo: editInfo,
              detectedAt: new Date().valueOf(),
            } as FileChangeEvent
          } else {
            console.warn('Unknown event type', type)
          }
        };
    
        if ('Upload' in payload) {
          const uploadStatusKey = Object.keys(payload.Upload)[0]
          const eventType = `upload:${uploadStatusKey}`.toLowerCase()
          setEventData(eventType, payload.Upload[uploadStatusKey])

          // If an upload was finished we need to update the document
          if (eventType === 'upload:finished') {
            this._log.debug({ event }, 'Merging uploaded file into document')

            const { document, fieldName, uploadInfo } = event as FileUploadEvent
            const editableDocument = await this.edit(document.id)
            let fileField = editableDocument.data[fieldName] as FileField
            fileField.dataUrl = uploadInfo.fields.dataUrl
            this.save(editableDocument)
          }
        } else if ('Changed' in payload) {
          setEventData('file:changed', payload.Changed)
        }

        if (!event) {
          console.warn('Unknown edit event or could not parse', editEvent)
          return
        }
    
        this._emitter.emit('client:files:edited',event);
      });
    }

    return host.files.editFile(url, fileName, extendedDocumentInformation)
  }

  _editFileElectron(url: string, fileName: string, documentInformation = { id: '', v: '', fieldName: '', mimeType: '' }) {
    const win: any = window

    if (!win.host.exports) {
      console.warn('Cannot edit file. No host.exports found')
      return
    }

    const fileChangedCallback = async (err, result: any = {}) => {
      if (err) {
        throw new Error(`[DEBUG]: editFile failed for ${result && result.document ? result.document.id : '"unknown id"'}: "${err.toString()}"`)
      }

      const editableDocument = await this.edit(result.document.id)
      if (!editableDocument) {
        throw new Error(`[DEBUG]: editFile could not find edited document ${result.document.id}`)
      }
      const fieldName = result.document.fieldName

      editableDocument.setFile(fieldName, result.changedFileBuffer, {
        name: editableDocument.data[fieldName].name,
        type: editableDocument.data[fieldName].type
      })
      this.save(editableDocument)
    }

    win.host.exports.editFile(url, fileName, documentInformation, fileChangedCallback)
  }

  public createReactiveObjectProxy(object, handler?) {
    const defaultHandler = this._defaultReactiveHandler
    return ReactiveObjectProxy.build(object, defaultHandler || handler)
  }

  private _buildDefaultReactiveHandler() {
    return {
      onNewProperty: (target, name, value) => {
        Vue.set(target, name, value)
      },
      onDocumentWatcher: (target, name, watcher: IDocumentWatcher) => {
        watcher.on('updated', (document) => {
          target[name] = document
        })
      }
    }
  }

  private get context() { return this._context }

  private get allResources() {
    return {
      named: this._allContexts.map(c => c.namedResources).join(),
      unnamed: this._allContexts.map(c => c.unnamedResources).join(),
    }
  }

  private get resources() {
    const context = this.context

    return {
      named: context.namedResources,
      unnamed: context.unnamedResources
    }
  }

  /*
   * Public helper functions
   */
  uuid() {
    return uuid()
  }


  public sort(list: any[], cb?)
  public sort(list: any[], options?: FieldSortInfo)
  public sort(list: any[], param?) {
    this._deprecated('Aeppic.sort is deprecated. Use Aeppic.Functions.sort')

    if (typeof param === 'function') {
      this._deprecated('Aeppic.sort with callback is deprecated. Provide FieldSortInfo or use <Prototype>Array.sort')
      return list.sort(param)
    }

    return sort(list, param)
  }

  public fetch(url, options: { credentials?, method?, body?, headers?} = {}) {
    const defaultOptions = { ...options }

    if (typeof defaultOptions.credentials === 'undefined') {
      defaultOptions.credentials = 'include'
    }

    return this._fetch(url, defaultOptions)
  }

  /*
   * Exposed Objects for managing advanced state or download
   * content etc.
   */
  public get Query() {
    return this._context.Query
  }

  public get Packages() {
    return this._packages
  }

  /** @deprecated */
  public get packages() {
    this._deprecated('Aeppic.packages is deprecated. Use Aeppic.Packages instead. Dont use it to store global data or packages')
    return this._globals
  }

  get Features() {
    if (!this._features) {
      throw new Error('Accessing Features is only possible AFTER the boot. It has not initialized yet')
    }
    return this._features
  }

  get Changes() {
    return this._changes
  }

  get Translator() {
    return this._translator
  }

  get Preferences() {
    return this._preferences
  }

  get Designs() {
    return this._designs
  }

  get Developer() {
    return this._developer
  }

  get History() {
    return this._history
  }

  get Actions() { return this._actions }

  get Commands() { return this._commands }

  get Uploads() { return this._uploads }

  get Content() {
    const context = this.context
    let content = context.getNamedResource('GENERIC_RESOURCE', 'CONTENT')

    if (!content) {
      content = new Content(this, this._getApiUrl(), this._urlToDataCache)
      context.addNamedResource('GENERIC_RESOURCE', 'CONTENT', content)
    }

    return content
  }


  get LocalStorage() {
    return this._localStorage
  }

  get SessionStorage() {
    return this._sessionStorage
  }

  public get Math() {
    return Math
  }

  public get Anime() {
    return anime
  }

  /**
   * @deprecated
   *
   * Use `DateTime` instead
   */
  public get Date() {
    // tslint:disable-next-line
    this._deprecated('Use DateTime instead')
    return { format: () => { } }
  }

  public get DateTime() {
    return DateTime
  }

  public get DateTimeDuration() {
    return Duration
  }

  public get DateTimeInterval() {
    return Interval
  }

  public get DateTimeInfo() {
    return LuxonInfo
  }

  public setDateTimeLocale(locale) {
    DateTimeSettings.defaultLocale = locale || 'en-US'
  }

  public get Modules() {
    return {
      FileSaver,
      ...Popup,
    }
  }

  public get Parser() {
    return {
      markdown,
      formdown,
      dateRange: parseDateRange,
    }
  }

  public get Functions() {
    return {
      resolveField,
      sort,
      deriveChildId,
    }
  }

  public get Formatting() {
    return {
      formatPastDateTime: (datetime: string, options = {}) => {
        let locale = this.Account?.data.locale as string

        if (locale === 'auto') {
          locale = null
        }

        return formatToHumanReadableWithLuxon(Luxon, datetime, { locale, ...options })
      },
    }
  }

  /*
   * Vue specific helper options or  functions (for advanced use-cases e.g in external packages) 
   */
  public get Vue() {
    return this._vue
  }

  public get VueComponentOptions() {
    return this._vueComponentOptions
  }

  // TODO: Move into caller
  public createVueComponentElement(name, props, temporaryAttributes) {
    const self = this

    const Child = Vue.component(name)
    const componentContainer = new Vue({
      render(createElement) {
        return createElement('div', {
          attrs: temporaryAttributes
        }, [
          createElement(name, {
            props
          })
        ])
      },
      provide() {
        return {
          getAeppicContext(contextName) {
            return (<any>self._vue).Aeppic.contextify(contextName)
          }
        }
      },
      components: {
        Child
      }
    })
    componentContainer.$mount()
    return componentContainer
  }

  public applyWrites(writes: Write[]) {
    for (const write of writes) {
      try {
        (<any>this[write.type])(...write.arguments)
      } catch (error) {
        console.error('Error applying write', write, error)
      }
    }
  }
  private _deprecated(message) {
    if (!this._deprecationWarnings.has(message)) {
      this._deprecationWarnings.add(message)
      this.Log.warn('DEPRECATED', message)
    }
  }
}


declare const zip: any



async function zipText(fileName, fileContent): Promise<Blob> {
  const zip = new ZipWriter()

  await zip.open()
  await zip.addText(fileName, fileContent)
  return await zip.close() as Blob
}

function ascii2hex(str) {
  const arr = []
  for (let i = 0, l = str.length; i < l; i++) {
    let hex = Number(str.charCodeAt(i)).toString(16)
    arr.push(hex)
  }
  return arr.join('')
}

function getJsonFormattingOptions(mode: 'lines' | 'array') {
  if (mode === 'lines') {
    return { prefix: '', separator: '\n', suffix: '', extension: '.jsonl', mime: 'plain/text', stringify: safeStringify }
  } else if (mode === 'array') {
    return { prefix: '[', separator: ',', suffix: ']', extension: '.json', mime: 'application/json', stringify: o => JSON.stringify(o, null, 2) }
  } else {
    throw new Error('Unsupported json formatting option')
  }
}

function exposeVueToOtherLibraries() {
  if (typeof window !== 'undefined') {
    (<any>window)['Vue'] = Vue
  }
}

exposeVueToOtherLibraries()

// function defineNonReactiveProperties(target, properties) {
//   for (const k in properties) {
//     const value = properties[k]

//     const opts: any = {
//       configurable: false,
//       enumerable: true,
//       writable: true,
//       value
//     }

//     Object.defineProperty(target, k, opts)
//   }
// }


function removeFromArray(array, entry) {
  for (let i = array.length - 1; i >= 0; i -= 1) {
    if (array[i] === entry) {
      array.splice(i, 1)
      return
    }
  }
  // tslint:disable-next-line
  console.warn('could not find entry')
}

function extractItemsAndNestedItemsInAnArrays(items: any[]) {
  const allItems = []

  for (const rootItem of items) {
    if (Array.isArray(rootItem)) {
      allItems.push(...rootItem)
    } else {
      allItems.push(rootItem)
    }
  }

  return allItems
}

function findDocumentIndex(documents, documentToFind) {
  for (let i = 0, l = documents.length; i < l; i += 1) {
    const document = documents[i]
    if (document.id === documentToFind.id) {
      return i
    }
  }
  return -1
}

function isBrowserButVeryOldBrowser() {
  if (isInBrowser() && !('visibilityState' in document)) {
    return true
  }
}

function isInBrowser() {
  return typeof document !== 'undefined' && typeof window !== 'undefined'
}

function hasLoginInfo(val: any): val is { username: string, password: string } {
  if (!val) {
    return false
  }

  if (val === true || val === false) {
    return false
  }

  if (val.username) {
    return true
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Safely_detecting_option_support
function isPassiveOnHandlersSupported() {
  let passiveSupported = false

  try {
    const options = {
      get passive() { // This function will be called when the browser
        //   attempts to access the passive property.
        passiveSupported = true
        return false
      }
    };

    (window.addEventListener as any)('ae-passive-test', null, options)
      (window.removeEventListener as any)('ae-passive-test', null, options)
  } catch (err) {
    passiveSupported = false
  }

  return passiveSupported
}

export type AeppicInterface = Pick<Aeppic,
  'new' | 'edit' | 'editAll' | 'get' | 'getAll' | 'save' | 'saveAll' | 'getForm' | 'fetch' |
  'move' | 'delete' | 'deleteHard' | 'upgrade' | 'clone' | 'cloneDeep' | 'Query' | 'exportForms' |
  'change' | 'find' | 'findOne' | 'query' | 'integrate' | 'resolve' | 'resolveEdit' | 'resolveToField' | 'DateTime' | 'DateTimeDuration' | 'DateTimeInterval' | 'isEditableDocument' |
  'asReference' | 'getFormForDocument' | 'getFormVersions' | 'hasChildren' | 'canUpgrade' | 'uuid' | 'download' | 'hasRight' | 
  'Log' | 'Warn' |
  'Commands' | 'Actions' | 'Preferences' | 'Log' | 'HomePage' | 'applyWrites' | 'stamp' | 'verifyStamp' | 'Translator' | 'translate' | 'getProfilePageForAccount' | 'getAccountForProfilePage' |
  'Formatting' | 'Features' >

export type AeppicUiInterface = AeppicInterface & Pick<Aeppic, 'drillInto' | 'navigateTo' | 'navigateBack' | 'Cache' | 'Features' | 'Account' | 'Functions' >

export function isUiInterface(api: AeppicInterface | CommandAeppicInterface): api is AeppicUiInterface {
  return !!(api as any).navigateTo
}

/** @deprecated */
export type AeppicApi = AeppicInterface

function getCookies(response) {
  if (!response || !response.headers) {
    return []
  } else if (response.headers.raw) {
    return response.headers.raw()['set-cookie']
  } else if (response.headers.entries) {
    // tslint:disable-next-line
    console.error('Untested response headers cookie parsing path')
    const cookies = []
    for (const [key, value] of <Map<string, any>>response.headers.entries) {
      if (key.toLowerCase() === 'set-cookie') {
        cookies.push(value)
      }
    }
    return cookies
  } else {
    // tslint:disable-next-line
    console.error('Unsupported response headers')
    return []
  }
}


function composedPath(target: Node) {
  function getParents(node: Node, memo = []) {
    const parentNode = node.parentNode

    if (!parentNode) {
      return memo
    }
    else {
      return getParents(parentNode, memo.concat(parentNode))
    }
  }

  return [target].concat(getParents(target))
}


function isDiscoverKeyCombination(event) {
  return isDOMInspectKeyCombination(event) && !elementIsAnActivelyFocusedTextElement(event.target)
}

function isDOMInspectKeyCombination(event: MouseEvent) {
  return (event.ctrlKey || event.metaKey) && event.shiftKey
}

function elementIsAnActivelyFocusedTextElement(target: Element) {
  if (target === document.activeElement) {
    if (target.nodeName === 'input' || target.nodeName === 'textarea') {
      return true
    }
  }
  return false
}

function removeElement(elementSelector: string | HTMLElement) {
  if (elementSelector && typeof document !== 'undefined') {
    const el = (typeof elementSelector === 'string') ? document.querySelector(elementSelector) : elementSelector

    if (el) {
      el.remove()
    }
  }
}

function mergeFlags(flags: FlagStatus = {}, defaults: Flags = {}): Flags {
  const result: Flags = {}

  for (const [name, value] of Object.entries(defaults)) {
    result[name] = { ...value }

    const flagStatus: boolean | FlagSettings = flags[<FlagNames>name]

    if (flagStatus == null) {
      continue
    }

    if (typeof flagStatus === 'boolean') {
      result[name].enabled = flagStatus
    } else if (typeof flagStatus === 'object') {
      result[name].enabled = flagStatus.enabled == null ? value.enabled : flagStatus.enabled
      result[name].options = { ...value.options, ...flagStatus.options }
    }
  }

  return result
}

function isQuerySubscription(subscription: IQuerySubscription): subscription is QuerySubscription {
  return ('refresh' in subscription)
}
