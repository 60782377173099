export function findAncestorWithClass(node, ...classes: string[]) {

  const classesToFind = [...classes]
  let el = node.parentNode
  
  while (el) {

    for (const className of classesToFind) {
      if (el.classList && el.classList.contains(className)) {
        return el
      }
    }

    el = el.parentNode
  }

  return null
}
