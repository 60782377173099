import { ByteBuffer } from './byte-buffer.js'

// define isArrayBufferView
export function isArrayBufferView(x: any) {
  return x && isArrayBuffer(x.buffer) && x.byteLength !== undefined
}

export function isArrayBuffer(x: any) {
  return typeof ArrayBuffer !== 'undefined' && x instanceof ArrayBuffer
}

export function hasWideChar(str: string) {
  for (let i = 0; i < str.length; i += 1 ) {
    if (str.charCodeAt(i) >>> 8) {
      return true
    }
  }

  return false
}

/**
 * Creates a buffer that stores bytes. A value may be given to put into the
 * buffer that is either a string of bytes or a UTF-16 string that will
 * be encoded using UTF-8 (to do the latter, specify 'utf8' as the encoding).
 *
 * @param [input] the bytes to wrap (as a string) or a UTF-16 string to encode
 *          as UTF-8.
 * @param [encoding] (default: 'raw', other: 'utf8').
 */
export function createBuffer(input?, encoding = 'raw') {
  // TODO: deprecate, use new ByteBuffer() instead
  if (input !== undefined && encoding === 'utf8') {
    input = encodeUtf8(input)
  }

  return new ByteBuffer(input)
}

/**
 * Fills a string with a particular value. If you want the string to be a byte
 * string, pass in String.fromCharCode(theByte).
 *
 * @param c the character to fill the string with, use String.fromCharCode
 *          to fill the string with a byte value.
 * @param n the number of characters of value c to fill with.
 *
 * @return the filled string.
 */
export function fillString(c: string, n: number) {
  let s = ''

  while (n > 0) {
    if (n & 1) {
      s += c
    }

    n >>>= 1
    
    if (n > 0) {
      c += c
    }
  }

  return s
}

/**
 * UTF-8 encodes the given UTF-16 encoded string (a standard JavaScript
 * string). Non-ASCII characters will be encoded as multiple bytes according
 * to UTF-8.
 *
 * @param str the string to encode.
 *
 * @return the UTF-8 encoded string.
 */
export function encodeUtf8(str: string) {
  return unescape(encodeURIComponent(str))
}

/**
 * Decodes a UTF-8 encoded string into a UTF-16 string.
 *
 * @param str the string to decode.
 *
 * @return the UTF-16 encoded string (standard JavaScript string).
 */
export function decodeUtf8(str: string) {
  return decodeURIComponent(escape(str))
}
