import * as Types from '@aeppic/types'

import { EventEmitter, IEventEmitter } from '@aeppic/shared/event-emitter'
import { waitForNextIdle } from '@aeppic/shared/wait-for-tick'

import { NotificationReason  } from './notification-reason.js'
export { NotificationReason }

export interface IDocumentWatcher extends IEventEmitter {
  // notify(document: Types.Document, reason: NotificationReason)
  stop()
}

export interface DocumentWatchCallback  {
  (document: Types.Document, reason: NotificationReason)
}

export interface DocumentsWatchCallback {
  (documents: Types.Document[])
}

export class DocumentWatcher extends EventEmitter implements IDocumentWatcher {
  public active = true

  constructor(private _callback: DocumentWatchCallback) {
    super()
    this.stop = this.stop.bind(this)
  }

  notify(document: Types.Document, reason: NotificationReason) {
    if (!this.active) {
      return
    }

    if (this._callback) {
      try {
        this._callback(document, reason)
      } catch (error) {
        console.error(`Error within documentWatcher callback: ${error.toString()}`, error.stack)
      }
    }

    this.emit('updated', document)
  }

  stop() {
    this.active = false
    this._callback = null
  }
}

export interface PendingNotification {
  document: Types.Document
  reason: NotificationReason
}

export class Notifier {
  private _allWatchers = new Map<string, DocumentWatcher[]>()
  private _pendingNotifications: PendingNotification[] = []
  private _pendingFlush: Promise<void> = null

  constructor() {}

  watch(watchedDocumentId: string, callback: DocumentWatchCallback): IDocumentWatcher {
    let watchers = this._allWatchers.get(watchedDocumentId)

    if (!watchers) {
      watchers = []
      this._allWatchers.set(watchedDocumentId, watchers)
    }

    const newWatcher = new DocumentWatcher(callback)
    watchers.push(newWatcher)

    return newWatcher
  }

  watchAll(callback: DocumentWatchCallback): IDocumentWatcher {
    return this.watch('_all', callback)
  }

  notifyWatchers(document: Types.Document, reason: NotificationReason = NotificationReason.Updated) {
    if (this._allWatchers.size === 0) {
      return
    }

    if (!this._allWatchers.has(document.id) && !this._allWatchers.has('_all')) {
      return
    }
    
    this._pendingNotifications.push({ document, reason })
    return this.flushPendingNotifications()
  }

  notifySpecificWatcher(watcher: IDocumentWatcher, document: Types.Document, reason: NotificationReason) {
    const documentWatcher = <DocumentWatcher>watcher

    waitForNextIdle().then(() => {
     documentWatcher.notify(document, reason)
    })
  }

  public cleanup() {
    for (const watcherList of this._allWatchers.values()) {
      const activeWatchers = watcherList.filter(w => w.active)
      watcherList.length = 0
      watcherList.push(...activeWatchers)  
    }
  }

  public flushPendingNotifications() {
    return waitForNextIdle().then(() => this._publishPendingNotifications())
  } 

  private _publishPendingNotifications() {
    const notifications = this._pendingNotifications
    this._pendingNotifications = []

    for (const n of notifications) {
      this._notifyWatchers(n)
    }
  }

  private _notifyWatchers({ document, reason }: PendingNotification) {
    const specificWatchers = this._allWatchers.get(document.id) || []
    const generalWatchers = this._allWatchers.get('_all') || []
    const watchers = specificWatchers.concat(generalWatchers)

    if (!watchers) {
      return
    }

    let nonActiveWatchersDetected = false

    for (const w of watchers) {
      if (w.active) {
        w.notify(document, reason)
      } else {
        nonActiveWatchersDetected = true
      }
    }

    if (nonActiveWatchersDetected) {
      let activeWatchers = []

      for (const w of watchers) {
        if (w.active) {
          activeWatchers.push(w)
        }
      }

      watchers.length = 0
      this._allWatchers.set(document.id, activeWatchers)
    }
  }
} 
