import * as Types from '@aeppic/types'
import { readJsonlObjects } from './jsonl-reader'

export default class History {
  private Aeppic = null

  constructor(Aeppic) {
    this.Aeppic = Aeppic
  }

  async latest(identifier: Types.IdOrReference| Types.Document): Promise<Types.Document > {

    const id = getDocumentId(identifier)

    if (!id) {
      return null
    }

    const response: Response = await this.Aeppic._fetchFromServerApi('GET', `/api/history/${id}/_latest`)

    if (!response.ok) {
      return null
    }

    const text = await response.text()
    const document = JSON.parse(text)
    return document
  }

  async getRecentVersions(identifier: Types.IdOrReference| Types.Document, { limit } = { limit: null } ): Promise<Types.Document[]> {
    const versions = []
    
    for await (const version of this.enumerateRecentVersions(identifier)) {
      versions.push(version)

      if (limit && limit.length >= versions.length) {
        break
      }
    }

    return versions
  }

  async *enumerateRecentVersions(identifier: Types.IdOrReference| Types.Document): AsyncIterableIterator<Types.Document> {
    const id = getDocumentId(identifier)

    if (!id) {
      return null
    }

    const response: Response = await this.Aeppic._fetchFromServerApi('GET', `/api/docs/${id}/history`)

    if (!response.ok) {
      return []
    }

    for await (const { object: version } of readJsonlObjects(response) ) {
      yield version as Types.Document
    }
  }
}

function getDocumentId(identifier: Types.IdOrReference): string {
  let id

  if (typeof identifier === 'string') {
    id = identifier
  } else {
    id = identifier.id
  }

  return id
}
