/**
 * Create a derived document identifier based on a parent document identifier and
 * some unique parts.
 * 
 * @example
 * 
 * ```
 * // Derive a unique id for a helper document a service creates
 * const childId = deriveChildId(parentId, serviceInstanceId, id_for_my_type_of_data_class)
 * ```
 * 
 * @param parentId string - The document id of the parent document this document will be placed under
 * @param parts string[] - Some unique strings to disambiguate the child from other children
 * 
 * @returns A concatenated string to be a safe document id based on the individual parts
 */
export default function deriveChildId(parentId: string, ...parts: string[]): string {
  if (!parentId || parentId.length === 0 || parts.length === 0) {
    throw new Error('Missing parts to derive a stable child id')
  }

  if (parts.findIndex(v => !v || v.length == 0) !== -1) {
    throw new Error('Empty part. All parts must contain at least one character each')
  }

  return `D-${parentId}$${parts.map(encodeURIComponent).join('$')}`
}
