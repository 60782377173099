export default {
  install: (Vue, options) => {
    Vue.directive('translate', {
      bind (el, binding, vnode) {
        const Aeppic = options.aeppic

        if (!binding.value || (typeof(binding.value) !== 'string') ) {
          return
        }

        let keys = null
        if (binding.value.indexOf('|') >= 0) {
          keys = binding.value.split('|').map((key) => key.trim())
        }
        else {
          keys = binding.value.split(',').map((key) => key.trim())
        }
        
        const propertyToTranslate = binding.arg ? binding.arg : 'innerText'
        const fallbackValue = el[propertyToTranslate]

        const fallback = []
        
        if (fallbackValue && (typeof(fallbackValue) === 'string')) {
          fallback.push(fallbackValue)
        }

        const translation = Aeppic.translate(...keys, ...fallback)
        el[propertyToTranslate] = translation

        if (!Aeppic.Translator.devMode) {
          return
        }

        // add classes for styling
        el.classList.add('ae-translate')

        if (Aeppic.Translator.canTranslate(...keys)) {
          el.classList.add('ae-translate-ok')
          el.classList.remove('ae-translate-missing')
        }
        else {
          el.classList.add('ae-translate-missing')
          el.classList.remove('ae-translate-ok')
        }

        // prettify
        const displayNameOfPropertyToTranslate = propertyToTranslate === 'innerText' ? 'text' : propertyToTranslate

        // add keys to title of element
        const rows = el.title.split('\n')
        let keyTooltipAlreadyAdded = false
          
        for (const row of rows) {
          const regExp = new RegExp(`^${displayNameOfPropertyToTranslate}:`)
          if (regExp.test(row)) {
            keyTooltipAlreadyAdded = true
            break
          }
        }

        if (!keyTooltipAlreadyAdded) {
          el.title = (el.title ? `${el.title}\n` : '') + `${displayNameOfPropertyToTranslate}: ${binding.value}` 
        }
      }
    })
  }
}
