import * as forms from '@aeppic/forms-parser'
import { lookupDefaultControlName } from '../utils/control-names'

export function renderSection(formInfo, section, options = {
  renderTitle: true,
  includeSubsections: true
}) {
  const parts = []

  const sectionStyleBinding = createDynamicStyleBindings(section)
  const sectionClassBinding = createDynamicClassBindings(section)
  const customSectionLayoutInfos = getCustomLayoutsForSection(section)
  
  parts.push('<div v-if="false">This never shows</div>')
  for (let info of customSectionLayoutInfos) {
    parts.push(`<ae-layout v-else-if="shouldApplyStyleToSection('${info.style}', ${section.index})" :layout-id="'${info.layoutId}'" 
                  :param-form="form" 
                  :param-document="document" 
                  :param-section="section" 
                  :param-readonly="readonly"
                  :param-include-subsections="includeSubsections" 
                  :param-render-title="includeTitle" 
                  :param-field-conditions="fieldConditions"
                  ${sectionStyleBinding} ${sectionClassBinding}>
                </ae-layout>`)
  }
  parts.push(`<div class="ae-form-section" v-else-if="!isSectionHidden(${section.index})" ${sectionStyleBinding} ${sectionClassBinding}>`)
  if (options.renderTitle) {
    parts.push(renderSectionTitle(formInfo, section))
  }
  parts.push(renderParagraphs(formInfo, section.paragraphs, section))
  if (options.includeSubsections) {
    parts.push(renderSectionMatrix(formInfo, section.style, section.directSubsections) || renderSubsections(formInfo, section.directSubsections))
  }
  parts.push('</div>')
  return parts.join('\n')
}

function getCustomLayoutsForSection(section) {
  
  const styleNameRegExp = /^layout(-\d+)?$/    // layout or layout-1
  const result = []

  if (!section && section.style) {
    return result
  }

  for (let styleName in section.style) {
    if (styleNameRegExp.test(styleName)) {
      result.push({
        style: styleName,
        layoutId: section.style[styleName].value
      })
    }
  }
  
  return result
}


function renderSectionMatrix(formInfo, style, sections) {
  const matrix = buildSectionMatrix(sections)

  if (!matrix) {
    return null
  }

  // render matrix
  let html = ''

  const xDim = matrix.length - 1
  const yDim = matrix[1].length - 1

  const borderClass = (style && style.border && style.border.value === 'none') ? '' : 'ae-form-table-border'

  html += `<table class="ae-form-table ${borderClass}">`

  for (let y = 1; y <= yDim; y += 1) {
    html += '<tr>'

    for (let x = 1; x <= xDim; x += 1) {
      if (matrix[x][y].bin && !(matrix[x][y].colspan || matrix[x][y].rowspan)) {
        // spanned cell (skip)
        continue
      }

      const colWidth = Math.round( (matrix[x][y].colspan || 1) * 100 / xDim)
      const cellStyle = `style="width:${colWidth}%"`

      if (matrix[x][y].colspan || matrix[x][y].rowspan) {
        // spanning cell 
        html += `<td colspan="${matrix[x][y].colspan}" rowspan="${matrix[x][y].rowspan}" ${cellStyle}>`
      } else {
        // regular cell
        html += `<td ${cellStyle}>`
      }

      // cell content
      const content = matrix[x][y].bin ? matrix[x][y].bin.content : matrix[x][y].content

      for (const subsection of content) {
        html += renderSection(formInfo, subsection)
      }

      html += '</td>'
    }
    html += '</tr>'
  }

  html += '</table>'
  return html
}

function renderSubsections(formInfo, sections) {
  let html = ''

  for (const section of sections) {
    html += renderSection(formInfo, section)
  }

  return html
}

function renderSectionTitle(formInfo, section) {
  // render section title
  if (section.title && section.level <= 6) {
    const titleOffset = section.offsetExcludeDirectives
    const titleLength = section.offsetExcludeTitle - section.offsetExcludeDirectives
    return renderTextWithPlaceholders(formInfo, titleOffset, titleLength, section)
  } else {
    return ''
  }
}

function renderTextWithPlaceholders(formInfo, renderOffset, renderLength, section) {
  const markupSpans = []
  let currentOffset = 0
  let span

  if (renderOffset === undefined || renderOffset === null) {
    renderOffset = 0
  }

  if (renderLength === undefined || renderLength === null) {
    renderLength = formInfo.definition.length
  }

  const definition = formInfo.definition.slice(renderOffset, renderOffset + renderLength)

  for (const placeholder of formInfo.placeholders) {
    if ( placeholder.offset - renderOffset < 0) {
      // placeholder lies before section to render
      continue
    }
    if ( placeholder.offset + placeholder.length - renderOffset > renderLength) {
      // placeholder extends beyond section to render
      break
    }
    if ( placeholder.offset - renderOffset > renderLength) {
      // placeholder lies after section to render
      break
    }

    // add the span of markup before the placeholder
    span = definition.slice(currentOffset, placeholder.offset - renderOffset)
    span = addTranslationToMarkupSpan(span)
    markupSpans.push(span)

    // determine the width
    // TODO: DRY, unify code duplication with renderLineOfFields ....
    let widthSetting = '150px'

    if (placeholder.style && placeholder.style.width && /^\d+\.?\d*\s*(px|em|rem|\%)$/.test(placeholder.style.width.value)) {
      // width in px or em only, for now...
      widthSetting = placeholder.style.width.value
    }

    // replace the placeholder
    const fieldHtml = renderField(formInfo, placeholder, section, 'INLINE')
    const html = `<span style="display:inline-block line-height:1 width:${widthSetting}">${fieldHtml}</span>`
    markupSpans.push(html)
    
    // TODO: addToSet(result.styles, snippet.styles)
    // TODO: addToSet(result.scripts, snippet.scripts)

    // advance the processing offset to after the placeholder
    currentOffset = placeholder.offset + placeholder.length - renderOffset
  }

  // add the span of markup after the last placeholder
  span = definition.slice(currentOffset, definition.length)
  span = addTranslationToMarkupSpan(span)
  markupSpans.push(span)

  return forms.markdown(markupSpans.join(''))
}

function addTranslationToMarkupSpan(span) {
  const INLINE_TRANSLATION_REGEXP = /translate:[^|\n]+(\|[^|\n]+)*/g
  return span.replace(INLINE_TRANSLATION_REGEXP, m => `{{${snippetForTranslation(m)}}}` )
}

function renderParagraphs(formInfo, paragraphs, section) {
  let html = ''

  for (const paragraph of paragraphs) {
    html += renderParagraph(formInfo, paragraph, section)  
  }

  return html
}

function renderParagraph(formInfo, paragraphOrParagraphId, section) {
  let paragraph = paragraphOrParagraphId

  if (typeof(paragraphOrParagraphId) === 'string') {
    paragraph = findParagraphById(formInfo, paragraphOrParagraphId)
    
    if (!paragraph) {
      // console.log('iris-forms-renderer: paragraph not found in form definition: ' + paragraphOrParagraphId)
      return ''
    }
  }

  if (paragraph.hasText) {
    const offset = paragraph.offsetExcludeDirectives
    const length = paragraph.length - (paragraph.offsetExcludeDirectives - paragraph.offset)
    return renderTextWithPlaceholders(formInfo, offset, length, section)
  } else if (paragraph.hasPlaceholders) {
    return renderLineOfFields(formInfo, paragraph, section)
  } else {
    return ''
  }
}

function findParagraphById(formInfo, paragraphId) {
  console.log('usage of renderParagraphById')

  for (const section of formInfo.sections) {
    const paragraph = findStyledItemById(section.paragraphs, paragraphId)

    if (paragraph) {
      return paragraph
    }
  }
  return null
}

function renderLineOfFields(formInfo, paragraph, section) {
  let html = ''

  let currentLineNumber = 0
  html += '<div class="ae-row">'
  
  for (const placeholder of paragraph.placeholders) {
    const ln = getLineNumber(formInfo.definition, paragraph.offset, placeholder.offset)

    if (ln > currentLineNumber) {
      html += '</div><div class="ae-row">'
      currentLineNumber = ln
    }

    const styles = []
    
    if (placeholder.style) {
      if (placeholder.style.width && /^\d+$/.test(placeholder.style.width.value)) {
        styles.push(`flex:${placeholder.style.width.value / 100.0}`)
      } else if ( placeholder.style && placeholder.style.width && /^\d+\.?\d*\s*(px|em|rem|\%)$/.test(placeholder.style.width.value) ) {
        // width in px or em only, for now...
        styles.push(`max-width:${placeholder.style.width.value}`)
      }

      // if (placeholder.style['container-css']) {
      //   styles.push(`${placeholder.style['container-css'].value}`)
      // }
    }

    const staticContainerStyle = styles.length ? `style="${styles.join(';')}"` : ''
    const dynamicContainerStyle = createDynamicStyleBindings(placeholder)

    const formClassBindings = {
      'ae-form-cell--readonly' : `isPlaceholderReadonly(${section.index},${placeholder.index})`,
      'ae-form-cell--edit': `!isPlaceholderReadonly(${section.index},${placeholder.index})`,
      'ae-form-cell--hidden': `isPlaceholderHidden(${placeholder.index})`
    }

    const dynamicContainerClasses = createDynamicClassBindings(placeholder, formClassBindings) 

    html += `\n  <div v-if="!isPlaceholderHidden(${placeholder.index})" class="ae-col ae-control-container ae-form-cell" ${dynamicContainerClasses} ${staticContainerStyle} ${dynamicContainerStyle}>`
    // appendToSnippet(result, snippetForPlaceholder(formInfo, placeholder, section))
    // var fieldHtml = snippetForPlaceholder(formInfo, placeholder, section)
    html += renderField(formInfo, placeholder, section)
    html += '\n  </div>'
  }

  html += '\n</div>'

  return html
}


function createDynamicClassBindings(sectionOrPlaceholder, additionalBindings = {}) {

  const styleNameRegExp = /^class(-\d+)?$/    // class or class-1
  const isSection = ['ROOT', 'VIRTUAL', 'SETEXT', 'ATX'].includes(sectionOrPlaceholder.type)
  const testFunction = isSection ? 'shouldApplyStyleToSection' : 'shouldApplyStyleToPlaceholder'

  const placeholder = sectionOrPlaceholder

  if (!placeholder.style) {
    return ''
  }

  let result = []

  for (let className in additionalBindings) {
    result.push({
      cssClass: className,
      condition: additionalBindings[className]
    })
  }

  for (let placeholderStyleName in placeholder.style) {
    if (!styleNameRegExp.test(placeholderStyleName)) {
      continue
    }
  
    const placeholderStyle = placeholder.style[placeholderStyleName]
    let r = {
      cssClass: placeholderStyle.value,
      condition: placeholderStyle.condition ? `${testFunction}('${placeholderStyleName}', ${placeholder.index})` : true
    }

    result.push(r)
  }

  if (!result.length) {
    return ''
  }

  let classBindings =  ''

  for (let r of result) {
    classBindings += `'${r.cssClass}': ${r.condition}, `
  }

  return `:class="{${classBindings}}"`
}


function createDynamicStyleBindings(sectionOrPlaceholder) {

  const styleNameRegExp = /^style(-\d+)?$/    // style or style-1
  const isSection = ['ROOT', 'VIRTUAL', 'SETEXT', 'ATX'].includes(sectionOrPlaceholder.type)
  const testFunction = isSection ? 'shouldApplyStyleToSection' : 'shouldApplyStyleToPlaceholder'

  const placeholder = sectionOrPlaceholder

  if (!placeholder.style) {
    return ''
  }

  let result = []

  for (let placeholderStyleName in placeholder.style) {
    // container-css is deprecated. use style instead
    if (!placeholderStyleName.startsWith('container-css') && !styleNameRegExp.test(placeholderStyleName)) {
      continue
    }
  
    const placeholderStyle = placeholder.style[placeholderStyleName]

    // TODO: might be to simple for all cases...
    const cssStyles = placeholderStyle.value.split(';')

    for (let cssStyle of cssStyles) {

      const parts = cssStyle.split(':')
      if (parts.length !== 2) {
        // TODO: again, the simple css parsing is too simple here...
        console.error(`could not parse style: ${cssStyle}, skipping`)
        continue
      }
      const cssStyleName = parts[0]
      const cssStyleValue = parts[1]

      let r = result.find( r => r.styleName === cssStyleName )
      if (!r) {
        r = {
          styleName: cssStyleName,
          conditional: '',
          default: ''
        }
        result.push(r)
      }

      if (placeholderStyle.condition) {
        r.conditional += `${testFunction}('${placeholderStyleName}', ${placeholder.index}) ? '${cssStyleValue}' : `
      }
      else {
        r.default =  cssStyleValue
      }
    }  
  }

  if (!result.length) {
    return ''
  }

  let styleBindings =  ''

  for (let r of result) {
    styleBindings += `'${r.styleName}':${r.conditional}'${r.default || 'inherit'}', `
  }

  return `:style="{${styleBindings}}"`
}

function snippetForPlaceholder(formInfo, placeholder, section, controlContainer?) {
  if (placeholder.field) {
    return snippetForField(formInfo, placeholder, section, controlContainer)
  } else if (placeholder.control) {
    return snippetForControl(formInfo, placeholder, section, controlContainer)
  } else {
    return ''
  }
}

function snippetForControl(formInfo, placeholder, section, controlContainer?) {
  return `<ae-control :document="document" :form="form" :readonly="isPlaceholderReadonly(${section.index},${placeholder.index})" name="${placeholder.control.fullName}" :placeholder="getPlaceholder(${placeholder.index})" class="ae-form-cell__control" :class="{ 'ae-form-cell__control--readonly': isPlaceholderReadonly(${section.index},${placeholder.index}), 'ae-form-cell__control--edit': !isPlaceholderReadonly(${section.index},${placeholder.index}) }"></ae-control>`
}

function snippetForField(formInfo, placeholder, section, controlContainer?) {
  const controlName = placeholder.control ? placeholder.control.fullName : lookupDefaultControlName(placeholder.field)
  const containerClass = controlContainer ? `ae-control-container-${controlContainer}` : ''
  return `<ae-control name="${controlName}" class="ae-form-cell__control ${containerClass.toLowerCase()}" :class="{ 'ae-form-cell__control--readonly': isPlaceholderReadonly(${section.index},${placeholder.index}), 'ae-form-cell__control--edit': !isPlaceholderReadonly(${section.index},${placeholder.index}) }" :label="${snippetForTranslation(placeholder.label)}" :document="document" :form="form" field-name="${placeholder.field.name}" :readonly="isPlaceholderReadonly(${section.index},${placeholder.index})" :placeholder="getPlaceholder(${placeholder.index})"></ae-control>`
}

function snippetForHelp(formInfo, placeholder, section) {
  return `<ae-control name="help" class="ae-form-cell__help" :class="{ 'ae-form-cell__help--readonly': isPlaceholderReadonly(${section.index},${placeholder.index}), 'ae-form-cell__help--edit': !isPlaceholderReadonly(${section.index},${placeholder.index}) }" :label="${snippetForTranslation(placeholder.label)}" :document="document" :form="form" field-name="${placeholder.field.name}" :readonly="isPlaceholderReadonly(${section.index},${placeholder.index})" :placeholder="getPlaceholder(${placeholder.index})"></ae-control>`
}

function snippetForTranslation(text) {
  if (text.startsWith('translate:')) {
    // const keys = text.substring(10).split('|').map(s => s.trim())
    // return `Aeppic.translate('${keys.join('\', \'')}')`
    const escapedText = text.replace(/'/g, '\\\'')
    return `Aeppic.translate('${escapedText}')`
  }
  else {
    return `'${text}'`
  }
}

function getLineNumber(definition, startOffset, currentOffset) {
  let lineNumber = 0

  for (let pos = startOffset; pos < currentOffset; pos += 1) {
    if (definition[pos] === '\n') { 
      lineNumber += 1
    }
  }

  return lineNumber
}

function renderField(formInfo, placeholderOrFieldOrIdOrFieldName, section, controlContainer?) { // , controlNamespace, control, controlParams) {
  let html = ''

  const placeholder = findPlaceholderByFieldOrIdOrFieldName(formInfo, placeholderOrFieldOrIdOrFieldName)

  if (!placeholder) {
    return html
  }

  // TODO:controls
  // if (control) {
  //   // console.log('renderField(): changing control on single field rendering not implemented yet');
  //   placeholder = _.clone(placeholder);

  //   placeholder.control = {
  //     fullName: (controlNamespace ? controlNamespace + ':' + control : control),
  //     namespace: controlNamespace,
  //     name: control,
  //     parameters: {}
  //   }

  //   for (const param in controlParams) {
  //     placeholder.control.parameters[param] = {
  //       value: controlParams[param]
  //     }
  //   }
  // }

  const help = placeholder.field && placeholder.field.settings && placeholder.field.settings.help && (placeholder.field.settings.help.text || placeholder.field.settings.help.html)

  if (placeholder.label && (controlContainer !== 'INLINE')) {
    html += `<label class="ae-form-cell__label" :title="${snippetForTranslation(placeholder.label)}">{{${snippetForTranslation(placeholder.label)}}}${help ? snippetForHelp(formInfo, placeholder, section) : ''}</label>`
    html += snippetForPlaceholder(formInfo, placeholder, section, controlContainer)
  } else if (help) {
    html += snippetForPlaceholder(formInfo, placeholder, section, controlContainer)
    html += snippetForHelp(formInfo, placeholder, section)
  }
  else {
    html += snippetForPlaceholder(formInfo, placeholder, section, controlContainer)
  }
  
  

  // const container = section,  || result.$container || 'SIMPLE'
  // const style = placeholder.style && placeholder.style['container-css'] ? placeholder.style['container-css'].value : null
  // TODO: wrapSnippetInsection, (result, container, style, help)

  // if (result.$container) {
  //   delete result.$container
  // }

  // if (result.$context) {
  //   result.$context.container = {
  //     type: container
  //   }

  //   //TODO: wrapSnippetWithContext(result, result.$context, (container === 'INLINE'))
  //   delete result.$context
  // }

  return html
}

function findPlaceholderByFieldOrIdOrFieldName(formInfo, placeholderOrFieldOrIdOrFieldName) {
  let fieldName

  if (typeof placeholderOrFieldOrIdOrFieldName === 'string') {
    fieldName = placeholderOrFieldOrIdOrFieldName
  } else if (placeholderOrFieldOrIdOrFieldName && placeholderOrFieldOrIdOrFieldName.name) {
    fieldName = placeholderOrFieldOrIdOrFieldName.name
  } else {
    return placeholderOrFieldOrIdOrFieldName
  }

  const result = findStyledItemById(formInfo.placeholders, fieldName)

  if (result) {
    return result
  }

  for (const placeholder of formInfo.placeholders) {
    if (placeholder.field && placeholder.field.name === fieldName) {
      return placeholder
    }
  }

  return null
}

function findStyledItemById(styledItems, itemId) {
  for (const item of styledItems) {
    if (item.style && item.style.id && item.style.id.value === itemId) {
      return item
    }
  }

  return null
}

function buildSectionMatrix(sections) {
  // check if there is any matrix information included
  let maxX = 1
  let maxY = 1

  for (const s of sections) {
    if (s.style) {
      const x = s.style.x ? parseInt(s.style.x.value, 10) : 1
      const y = s.style.y ? parseInt(s.style.y.value, 10) : 1
      const w = s.style.w ? parseInt(s.style.w.value, 10) : 1
      const h = s.style.h ? parseInt(s.style.h.value, 10) : 1
      
      if ( x + w - 1 > maxX) {
        maxX = x + w - 1
      }

      if ( y + h - 1 > maxY) {
        maxY = y + h - 1
      }
    }
  }

  // no matrix needed
  if (maxX === 1 && maxY === 1) {
    return null
  }

  // build the matrix
  const matrix = []

  for (let x = 1; x <= maxX; x += 1) {
    matrix[x] = []

    for (let y = 1; y <= maxY; y += 1) {
      matrix[x][y] = {
        content: []
      }
    }
  }

  // sort the sections into the cells
  for (const section of sections) {
    const x = section.style && section.style.x ? parseInt(section.style.x.value, 10) : 1
    const y = section.style && section.style.y ? parseInt(section.style.y.value, 10) : 1
    const w = section.style && section.style.w ? parseInt(section.style.w.value, 10) : 1
    const h = section.style && section.style.h ? parseInt(section.style.h.value, 10) : 1

    // create bin if needed and possible
    if ((w > 1) || (h > 1)) {
      let canCreateBin = true
      // check
      for (let xb = x; canCreateBin && (xb < x + w); xb += 1) {
        for (let yb = y; canCreateBin && (yb < y + h); yb += 1) {
          canCreateBin = ! matrix[xb][yb].bin
        }
      }
      // create
      if (canCreateBin) {
        const bin = {
          content: []
        }

        for (let xb = x; canCreateBin && (xb < x + w); xb += 1) {
          for (let yb = y; canCreateBin && (yb < y + h); yb += 1) {
            matrix[xb][yb].bin = bin
            Array.prototype.push.apply(matrix[xb][yb].bin.content, matrix[xb][yb].content)
            matrix[xb][yb].content.length = 0
          }
        }

        matrix[x][y].colspan = w
        matrix[x][y].rowspan = h
      }
    }

    // insert
    if (matrix[x][y].bin) {
      matrix[x][y].bin.content.push(section)
    } else {
      matrix[x][y].content.push(section)
    }
  }

  return matrix
}
