import * as Types from '@aeppic/types'
import parseTimespan from 'parse-duration'
import { DateTime } from 'luxon'

import { sleep } from '@aeppic/shared/sleep'
import { setTimeoutNoKeepAlive } from '@aeppic/shared/timer'

import { hasId } from './is.js'

export const RECYCLER_ID = 'recycler'

export interface RecycledDocument {
  document: Types.Document
  originalParentId?: string
}

export interface Options {
  maxRetentionAge?: string|number
  skipPurgingOnImport?: boolean
}

export class Recycler {
  private _rootDocuments = new Map<string, RecycledDocument>()
  private _allDocuments = new Map<string, Types.Document>()
  private _maxRetentionAge: number
  private _skipPurgingOnImport: boolean
  private _purgeInProgress = false
  private _purgeScheduled: ReturnType<typeof setTimeout>
  
  constructor({ maxRetentionAge = '1d', skipPurgingOnImport = false }: Options = {}) {
    this._maxRetentionAge = parseTimespan((maxRetentionAge || 0) as string)
    this._skipPurgingOnImport = skipPurgingOnImport
  }

  private _schedulePurge() {
    if (this._purgeScheduled) {
      return
    }

    if (this._maxRetentionAge > 0) {
      this._purgeScheduled = setTimeoutNoKeepAlive(() => this.purge(), parseTimespan('199 minutes'))
    }
  }

  clear() {
    if (this._purgeScheduled) {
      clearTimeout(this._purgeScheduled)
      this._purgeScheduled = null
    }

    this._rootDocuments.clear()
    this._allDocuments.clear()
  }

  public async purge({ sleepInterval = 100, purgeBlockSize = 500 } = {}) {
    if (this._purgeInProgress) {
      return
    }

    try {
      let purgeCounter = 0
      let iterations = 0

      // console.log(`Recycler purging...`)

      for (const { document } of this._rootDocuments.values()) {
        if (this._canBePurged(document)) {
          // console.log('Purging', document.id, document.t, document.modified.at, ageInMs, this._maxRetentionAge)
          const deletedDocsCount = this.remove(document.id)
          purgeCounter += deletedDocsCount
        }

        if (purgeBlockSize) {
          if (iterations % purgeBlockSize === 0) {
            await sleep(sleepInterval)
          }
        }
        
        iterations ++
      }

      for (const document of this._allDocuments.values()) {
        const documentIsRecycledRootDocument = document.p === RECYCLER_ID

        if (!documentIsRecycledRootDocument && this._canBePurged(document)) {
          const recycledRootId = document.a[1]

          const knownRoot = recycledRootId && this._rootDocuments.get(recycledRootId)

          if (!knownRoot) {
            const deletedDocsCount = this.remove(document.id)
            // console.log('Purging', document.id, document.t, document.modified.at, ageInMs, this._maxRetentionAge)
            purgeCounter += deletedDocsCount
          }
        }

        if (purgeBlockSize) {
          if (iterations % purgeBlockSize === 0) {
            await sleep(sleepInterval)
          }
        }

        iterations ++
      }

      // console.log(`Recycler purge finished. Removed ${purgeCounter} documents. Documents in recycler #${this._allDocuments.size}`)
    } finally {
      this._purgeInProgress = false
    }
  }

  private _canBePurged(document: Types.Document) {
    const recycleDate = document.t ? DateTime.fromMillis(document.t) : DateTime.fromISO(document.modified.at)
    const ageInMs = -recycleDate.diffNow().toMillis()
    return (ageInMs > this._maxRetentionAge)
  }

  public remove(id: string): number {
    if (!this.contains(id)) {
      return 0
    }

    this._rootDocuments.delete(id)
    this._allDocuments.delete(id)
  
    let counter = 1

    for (const [otherId, document] of this._allDocuments) {
      if (document && document.a && document.a[1] === id) {
        if (this._rootDocuments.has(otherId)) {
          console.warn('Should not be a root entry (since it is a descendant of one)', document, id)
          this._rootDocuments.delete(otherId)
        }

        this._allDocuments.delete(otherId)
        counter++
      }
    }

    return counter
  }

  public contains(documentId: string) {
    return this._allDocuments.has(documentId)
  }

  public add(document: Types.Document, from?: string) {
    if (!this._skipPurgingOnImport) {
      if (this._canBePurged(document)) {
        return
      }
    }

    if (document.p === RECYCLER_ID) {
      this._rootDocuments.set(document.id, { document, originalParentId: from })
    } else {
      const isInRecycler = document.a[0] === RECYCLER_ID
      
      if (!isInRecycler) {
        // console.log('Document does not have recycler ancestry but was added to it', document.id, document.a)
        return
      }
    }

    this._allDocuments.set(document.id, document)
    this._schedulePurge()
  }

  public getRecycledInfo(identifier: Types.IdOrReference | Types.Document) {
    if (!identifier) {
      throw new Error('Cannot get document without id')
    }

    if (hasId(identifier)) {
      return this._rootDocuments.get(identifier.id) || null
    } else {
      return this._rootDocuments.get(identifier) || null
    }
  }

  public *restore(rootIdentifier: Types.IdOrReference | Types.Document): IterableIterator<{ parent?: string, document: Types.Document }> {
    const rootDocumentInfo = this.getRecycledInfo(rootIdentifier)

    if (!rootDocumentInfo) {
      return
    }

    const rootDocumentId = rootDocumentInfo.document.id
    
    this._rootDocuments.delete(rootDocumentId)
    yield { parent: rootDocumentInfo.originalParentId, document: rootDocumentInfo.document }

    for (const d of this._allDocuments.values()) {
      if (d.a[1] === rootDocumentId) {
        yield { document: d }
        this._allDocuments.delete(d.id)
      }
    }
  }

  public replace(identifier: Types.IdOrReference, replacementDocument: Types.Document) {
    const id = hasId(identifier) ? identifier.id : identifier

    if (!this.contains(id)) {
      return
    }

    const info = this._rootDocuments.get(id)

    if (info) {
      info.document = replacementDocument
      this._rootDocuments.delete(id)
      this._rootDocuments.set(replacementDocument.id, info)
    }

    this._allDocuments.delete(id)
    this._allDocuments.set(replacementDocument.id, replacementDocument)
  }

  public get size() {
    return this._allDocuments.size
  }
  
  public documents(): IterableIterator<Types.Document> {
    return this._allDocuments.values()
  }
  
  public get numberOfRootDocuments() {
    return this._allDocuments.size
  }

  public *rootDocuments(): IterableIterator<Types.Document> {
    for (const info of this._rootDocuments.values()) {
      yield info.document
    } 
  }

  public get(identifier: Types.IdOrReference | Types.Document): Types.Document {
    if (!identifier) {
      throw new Error('Cannot get document without id')
    }

    if (!hasId(identifier)) {
      return this._allDocuments.get(identifier) || null
    }

    return this._allDocuments.get(identifier.id) || null
  }

  public *enumerateChildren(parentIdentifier: Types.IdOrReference): IterableIterator<Types.Document > {
    yield *this.enumerateDescendants(parentIdentifier, { childrenOnly: true })
  }

  public *enumerateDescendants(parentIdentifier: Types.IdOrReference, { childrenOnly = false } = {} ): IterableIterator<Types.Document > {
    const parentId = hasId(parentIdentifier) ? parentIdentifier.id : parentIdentifier
    
    for (const doc of this._allDocuments.values()) {
      if (doc.p === parentId) {
        yield doc

        if (!childrenOnly) {
          yield *this.enumerateDescendants(doc.id)
        }
      }
    }
  }
}
