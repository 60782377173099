import * as Types from '@aeppic/types'

interface IndexInclusionFilter {
  (object: any): boolean
}

export type GeneralIndexOptions = {
  /**
   * A function which can override whether a 
   * document should become part of the index
   * at all
   **/
  inclusionFilter?: IndexInclusionFilter
}

export type PrefixTextIndexOptions = {
  type: 'prefix',
  length: number
}

export type TypedOptions = PrefixTextIndexOptions

export type IndexMap = {
  [fieldPath: string]: Index
}

export type IndexOptions = GeneralIndexOptions & (TypedOptions|{})

export class Index {
  private _valuesToEntries = new Map<any, Set<string>>()
  private _entriesToValues = new Map<string, any[]>()
  private _isSuperSet = false
  private _prefixLength: number = null

  constructor(private _fieldPath: string, private _options: IndexOptions = {}) {
    if ('type' in this._options) {
      switch (this._options.type) {
        case 'prefix':
          this._isSuperSet = true
          this._prefixLength = this._options.length
          break
      }
    }
  }

  get fieldPath() {
    return this._fieldPath
  }

  get prefixLength() {
    return this._prefixLength
  }

  public get isSuperSet() {
    return this._isSuperSet
  }

  public size() {
    return this._valuesToEntries.size
  }

  public entryCount() { 
    return this._entriesToValues.size
  }

  public addIfMatches(entry: Types.Document) {
    if (this._options.inclusionFilter) {
      if (!this._options.inclusionFilter(entry)) {
        return
      }
    }

    const fieldValue = resolveField(entry, this._fieldPath)
    
    if (fieldValue != null && fieldValue !== '') {
      this._add(fieldValue, entry)
    }
  }

  has(id: string) {
    return this._entriesToValues.has(id)
  }
  
  private _add(value: any, entry: Types.Document) {
    const mappedValues = Array.isArray(value) ?
                            value.map(v => this._mapValue(v)) :
                            [this._mapValue(value)]

    for (const mappedValue of mappedValues) {
      this._addSingleValue(mappedValue, entry)
    }

    // if (!mappedValues) {
    //   console.log(value, mappedValues, entry)
    // }

    this._entriesToValues.set(entry.id, mappedValues)
  }

  private _addSingleValue(value: any, entry: Types.Document) {
    const list = this._getEntryList(value)
    list.add(entry.id)
  }

  public remove(id: string) {
    const values = this._entriesToValues.get(id)
    this._entriesToValues.delete(id)

    if (values == null) {
      return
    }
    
    // console.log(values)
    for (const singleMappedValue of values) {
      this._removeSingleValue(singleMappedValue, id)
    }
    // console.log(values)
  }
  
  private _removeSingleValue(singleValue: any, entryId: string) {
    const entries = this._getEntryList(singleValue)
    entries.delete(entryId)

    if (entries.size === 0) {
      this._valuesToEntries.delete(singleValue)
    }
  }

  private _getEntryList(value: any) {
    let entries = this._valuesToEntries.get(value)
    
    if (!entries) {
      entries = new Set<string>()
      this._valuesToEntries.set(value, entries)
    }

    return entries
  }

  private _mapValue(value: any) {
    if (typeof value !== 'string') {
      value = value.toString()
    }

    if ('type' in this._options) {
      switch (this._options.type) {
        case 'prefix':
          if (value.length > this._options.length) {
            return value.substr(0, this._options.length).toLowerCase()
          } else {
            return value.toLowerCase()
          }
      }
    }

    return value
  }

  public getEntries(value: any): Set<string> {
    const mappedValue = this._mapValue(value)
    return this._valuesToEntries.get(mappedValue)
  }
}

function resolveField(object: object, path: string) {
  const parts = path.split('.')
  let value: any = object

  for (const part of parts) {
    if (value == null) {
      return undefined
    }

    if (Array.isArray(value)) {
      return value.map(v => v[part])
    } else {
      value = value[part]
    }
  }

  if (value === object) {
    value = undefined
  }

  // console.log('resolveField', object, path, parts, value)
  return value
}

// function removeFromArray(array: any[], entry) {
//   const index = array.indexOf(entry)
//   array.splice(index, 1)
// }

// function getHighest(values: any[]) {
//   if (!values || values.length === 0) {
//     throw new Error('Invalid call')
//   }

//   let highest = undefined

//   for (let i = 0; i < values.length; i++) {
//     const value = values[i]

//     if (highest === undefined) {
//       highest = value 
//     } else if (highest < value) {
//       highest = value
//     }
//   }

//   return highest
// }

export function intersectSets(sets: Set<string>[]): string[] {
  const sortedSets = new Array<Set<string>>(sets.length)
  
  for (let i = 0; i < sets.length; i ++) {
    const set = sets[i]

    if (!set || set.size === 0) {
      return []
    }

    sortedSets[i] = set
  }
  
  if (sortedSets.length === 0) {
    return []
  }

  sortedSets.sort((a, b) => a.size - b.size)
  
  const intersectionEnumerator = intersect(sortedSets)
  return Array.from(intersectionEnumerator)
}

function intersect<T>(sets: Set<T>[]): Set<T> {
  if (sets.length === 0) {
    return 
  } else if (sets.length === 1) {
    return sets[0]
  } else {
    const set = sets.shift()
    const intersected = new Set<T>()

    for (const key of set) {
      let isInLowerSets = true

      for (const set of sets) {
        if (!set.has(key)) {
          isInLowerSets = false
          break
        }
      }

      if (isInLowerSets) {
        intersected.add(key)
      }
    }

    return intersected
  }
}
