export class UnknownDocumentError extends Error {
  constructor(documentId: string) {
    super(`Unknown document ${documentId}`)
  }
}

export class NoTargetElementSpecified extends Error {
  constructor() {
    super(`Either define { el } in constructor or pass as argument`)
  }
}

export class TargetElementNotFound extends Error {
  constructor(selector) {
    super(`QuerySelector '${selector}' does not specify a valid html element`)
  }
}

export class RouteNotFound extends Error {}
export class DynamicRouteProcessing extends Error {}
export class DynamicRouteWrite extends Error {}

export class InvalidRouteConfiguration extends Error {}
