import type * as Types from '@aeppic/types'
import type Aeppic from '../aeppic.js'

import { ParsedField } from '@aeppic/forms-parser'
import { FormBasedLookup, LookupEntry, LookupFunction, LookupTrace, ancestorTraversal, checkInReferencedFolders, form, getFullFormAncestry, lookForFoldersOfType, parseSelector } from './form-oriented-lookup.js'
import type { AeppicInterface } from '../aeppic.js'

const CONTROL_FORM = 'control-form'
const CONTROL_FOLDER_FORM = 'control-folder'

const DEFAULT_FIND_OPTIONS = {
  limit: 100,
  sort: 'created.at',
  fields: []
}

export enum ControlMatchStrategy {
  FULL,
  SYSTEM,
  DEFAULT_ONLY
}

type FieldLookupInfo = {
  field: ParsedField
}

const SYSTEM_CONTROLS_FOLDER = 'controls-folder'
const SYSTEM_CONTROLS_DEFAULT_FOLDER = '91f205bc-9989-4fbe-8966-bd4ba9777c26'

// Strategies
//
// Ids or Functions that will be called in order to find the control
//
// Each Id is a folder id to look in.
//
// Each function returns an AsyncIterableIterator of the folder ids
// to look in.
const CONTROL_FOLDER_LOOKUP_ORDER_DEFAULT: LookupEntry[] = [
  SYSTEM_CONTROLS_DEFAULT_FOLDER,
]

const CONTROL_FOLDER_LOOKUP_ORDER_SYSTEM: LookupEntry[] = [
  SYSTEM_CONTROLS_FOLDER,
  ...CONTROL_FOLDER_LOOKUP_ORDER_DEFAULT
]

const FORMS_WITH_REFERENCED_CONTROLS: Types.DocumentId[] = [
  'form-folder',
  'application-form',
  'root-folder-form',
]

const CONTROL_FOLDER_LOOKUP_ORDER_FULL: LookupEntry[] = [
  form,
  ancestorTraversal(
    getFullFormAncestry,
    [
      lookForControlFolders(),
      checkInReferencedFolders(
        'controls',
        FORMS_WITH_REFERENCED_CONTROLS
      )
    ],
  ),
  ...CONTROL_FOLDER_LOOKUP_ORDER_SYSTEM
]

export class ControlLookup {
  private _lookup: FormBasedLookup<FieldLookupInfo>
  private _strategy: LookupEntry[]
  
  constructor(private aeppic: AeppicInterface, strategy: ControlMatchStrategy) {
    this._strategy =
        strategy == ControlMatchStrategy.FULL ? CONTROL_FOLDER_LOOKUP_ORDER_FULL
      : strategy == ControlMatchStrategy.SYSTEM ? CONTROL_FOLDER_LOOKUP_ORDER_SYSTEM
      : strategy == ControlMatchStrategy.DEFAULT_ONLY ? CONTROL_FOLDER_LOOKUP_ORDER_DEFAULT
      : CONTROL_FOLDER_LOOKUP_ORDER_FULL

    this._lookup = new FormBasedLookup(
      aeppic,
      'control', this._strategy, findBestMatchingControlInFolder
    )
  }
  
  async findControlDocument(formId: Types.DocumentId, field: ParsedField|null, controlSelector: string, lookupTrace?: LookupTrace): Promise<Types.Document|null> {
    let { namespace, name } = parseSelector(controlSelector)  

    // Standlone controls need a name
    if (!field && !name) {
      return null
    }

    if (name.trim() == '') {  
      name = field.subType
    }

    if (lookupTrace) {
      lookupTrace.namespace = namespace
      lookupTrace.name = name
      lookupTrace.field = field
    }
    
    const extra: FieldLookupInfo = { field }

    return this._lookup.find(formId, namespace, name, extra, lookupTrace)
  }

}

async function findBestMatchingControlInFolder(aeppic: AeppicInterface, parentId: Types.DocumentId, _formId: Types.DocumentId, namespace: string, name: string, extra: FieldLookupInfo, trace?: LookupTrace): Promise<Types.Document> {
  let query = aeppic.Query.children(parentId)
    .form(CONTROL_FORM)
    
  if (extra.field) {
    if (extra.field.cardinality) {
      query = query.where('data.cardinality').isOneOf(['BOTH', 'MULTIPLE'])
    } else {
      query = query.where('data.cardinality').isOneOf(['BOTH', 'SINGLE'])
    }

    const types = []

    if (extra.field.subType == 'select') {
      types.push('select_any')
      types.push(`select_${extra.field.type}`)
    } else {
      types.push(extra.field.subType)
    }

    if (extra.field.type == 'string') {
      types.push('string')
    }    

    query = query.subQuery(q => {
      q.where('data.limitTypes').is(false)
      .or
      .where('data.types').isOneOf(types)
    })
  } else {
    query = query.where('data.standalone').isOneOf(['SUPPORTED','REQUIRED'])
  }
  
  if (name === '*' && trace) {
    trace.addStep({ operation: 'find', documentId: parentId, additionalInfo: { query: query, options: DEFAULT_FIND_OPTIONS } })
    return null
  }

  query = query
    .where('data.namespace').is(namespace)
    .where('data.name').is(name)

  const controls = await aeppic.find(query, DEFAULT_FIND_OPTIONS) 
  const isStandalone = !extra.field

  for (const control of controls) {
    if (!isStandalone) {
    }
    // if (field && control.data.cardinalityLimitation) {
    //   const { min, max } = control.data.cardinalityLimitation

    //   if (field.cardinality.min < min || field.cardinality.max > max) {
    //     continue
    //   }
    // }

    trace?.addStep({ operation: 'find:match', documentId: parentId, additionalInfo: { query, controls: controls.length, control } })
    return control
  }

  trace?.addStep({ operation: 'find', documentId: parentId, additionalInfo: { query: query, options: DEFAULT_FIND_OPTIONS, controls } })
  return null
}

// Check in each child of type 'control-folder' under the document 
function lookForControlFolders(): LookupFunction {
  return lookForFoldersOfType(CONTROL_FOLDER_FORM, DEFAULT_FIND_OPTIONS)
}
