
import { EMPTY_ITEM_THEME } from '../form/theme.js'
import { getFirstNonEmptyString }  from '../utils/lookup.js'

let sectionRenderId = 1

export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.section && _vm.isVisible)?_c('ae-layout',{key:_vm.sectionRenderId,class:_vm.sectionTheme.classes,attrs:{"layout-id":_vm.layoutIdToUse,"param-document":_vm.document,"param-theme":_vm.theme,"param-section-theme":_vm.sectionTheme,"param-form":_vm.form,"param-section":_vm.section,"param-paragraphs":_vm.paragraphs,"param-sub-sections":_vm.subSections,"param-readonly":_vm.readonly,"param-hide-title":_vm.hideTitle,"watch-layout":_vm.watchLayout,"watch-layout-revisions":_vm.watchLayoutRevisions}}):_vm._e()},
  staticRenderFns: [],
  /* tslint:enable */
  
  // inject: ['getAeppicContext'],
  props: {
    section: Object, // TODO: Also allow id and index into form
    document: Object, // Must be an editable document
    // TODO: Also allow documentId: String 
    form: Object,
    theme: Object,
    layoutId: String,
    watchLayout: {
      type: Boolean,
      default: true
    },
    watchLayoutRevisions: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    // TODO: add theme
  },
  data() {
    return {
      sectionRenderId: `ae-form-section_${(sectionRenderId ++).toString().padStart(12,'0')}`,
    }
  },
  computed: {
    sectionTheme() {
      return this.theme?.sections.levels[this.section.level] ?? EMPTY_ITEM_THEME
    },
    paragraphs() {
      return this.section.paragraphs
    },
    subSections() {
      return this.section.subSections
    },
    layoutIdToUse() {
      return getFirstNonEmptyString(this.layoutId, this.sectionTheme.layoutId, '4dfdf094-32b3-41fe-a2eb-50a809c39d7d')
    },
    isVisible() {
      return this.section.isVisible
    }
  },
  // mounted() {
  //   this.Aeppic.setContextRootElement(this.$el)
  // },
}
