
import { debounce } from '../utils/debounce'

export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.renderError)?_c('pre',{staticClass:"ae-error"},[_vm._v(_vm._s(_vm.renderError))]):(_vm.formToRender && _vm.documentToRender)?_c('ae-form',{key:_vm.documentToRender.id,attrs:{"form":_vm.formToRender,"document":_vm.documentToRender,"layout-id":_vm.layoutId,"readonly":_vm.readonly}}):_vm._e()},
  staticRenderFns: [],
  /* tslint:enable */
  
  inject: ['getAeppicContext'],
  props: {
    layoutId: {
      type: String
    },
    readonly: {
      type: Boolean,
      default: false
    },
    form: {
      type: Object
    },
    formId: {
      type: String
    },
  },
  data() {
    return {
      Aeppic: this.getAeppicContext('ae-form-preview'),
      documentToRender: null,
      formToRender: null,
      recentlyChangedFormDocument: null,
      renderError: null,
    }
  },
  created() {
    this.rerenderFormThrottled = debounce(this.rerenderForm, 1000)
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  
    if (!this.form) {
      this.startWatchingForFormDocumentsChanges()
    }
  },
  beforeDestroy() {
    this.Aeppic.release()
  },
  methods: {
    async rerenderForm() {
      let form = null

      if (this.formId) {
        form = await this.Aeppic.get(this.formId)
      } else if (this.form) {
        form = this.form
      } else if (this.recentlyChangedFormDocument) {
        form = this.recentlyChangedFormDocument
      }

      if (form) {
        this.formToRender = this.Aeppic.buildTemporaryForm(form)
      }

      const ticks = Date.now()

      if (this.formToRender) { 
        this.documentToRender = this.Aeppic.buildTemporaryEditableDocument(this.formToRender)       
      } else {
        this.documentToRender = null
      }
    },
    startWatchingForFormDocumentsChanges() {
      this._formDocumentsWatcher = this.Aeppic.watchMatchingDocuments('f.id:form', async (document, changes) => {
        if (changes.deleted || changes.hardDeleted) {
          return 
        }

        this.recentlyChangedFormDocument = document

        this.stopWatchingRevisions()
        this.startWatchingRevisions(this.recentlyChangedFormDocument.id)
        this.rerenderFormThrottled()
      })
    },
    stopWatchingForFormDocumentsChanges() {
      if (this._formDocumentsWatcher) {
        this._formDocumentsWatcher.stop()
        this._formDocumentsWatcher = null
      }
    },
    startWatchingRevisions(documentIdToWatch) {
      if (!this._formRevisionsWatcher) {
        this._formRevisionsWatcher = this.Aeppic.watchRevisions(documentIdToWatch, (document) => {
          this.recentlyChangedFormDocument = document
          this.rerenderFormThrottled()
        })
      }
    },
    stopWatchingRevisions() {
      if (this._formRevisionsWatcher) {
        this._formRevisionsWatcher.stop()
        this._formRevisionsWatcher = null
      }
    },
  },
  watch: {
    formId() {
        this.stopWatchingRevisions()          
        if (this.formId) {
          this.startWatchingRevisions(this.formId)
        }
        this.rerenderFormThrottled()
    },
    form: {
      handler() {
        this.stopWatchingRevisions()          
        if (this.form) {
          this.startWatchingRevisions(this.form.id)
        }
        this.rerenderFormThrottled()
      },
      deep: true
    },
    recentlyChangedFormDocument: {
      handler() {
        this.stopWatchingRevisions()

        if (this.recentlyChangedFormDocument) {
          this.startWatchingRevisions(this.recentlyChangedFormDocument.id)
        }
      },
      deep: true
    }
  }
}
