import * as Types from '@aeppic/types'
import { Condition, ExplicitOperator, FieldMatchNode, isFieldMatchNode, MatchExpression, MatchExpressionMultiMatch, QueryParameters } from './types'
import { Index, IndexMap } from '../indexing/document-index'

type DocumentMatchIteratorWeight = {
  type: Symbol
  /**
   * The cost is an approximation of the number of documents that might need to be enumerated
   * and thus require testing 
   */
  cost: number
}

export const IterateNoDocuments = Symbol('IterateOverNoDocuments')
export const IterateAllDocuments = Symbol('IterateOverAllDocuments')
export const IterateOverSubIterators = Symbol('IterateOverSubIterators')
export const IterateOverId = Symbol('IterateOverId')
export const IterateOverIndex = Symbol('IterateOverIndex')

type DocumentMatchIteratorAllDocuments = DocumentMatchIteratorWeight

type DocumentMatchIteratorUsingId = DocumentMatchIteratorWeight & {
  id: string
}

type DocumentMatchIteratorUsingIndex = DocumentMatchIteratorWeight & {
  key: string
  index: Index
  set: Set<Types.DocumentId>
}

type DocumentMatchIteratorOverNoDocuments = DocumentMatchIteratorUsingIndex

type DocumentMatchIteratorJoined= DocumentMatchIteratorWeight & {
  operator: 'intersect'|'union'
  iterators: (DocumentMatchIteratorUsingIndex|DocumentMatchIteratorJoined|DocumentMatchIteratorUsingId)[]
}

export type DocumentMatchIterator = DocumentMatchIteratorUsingId|DocumentMatchIteratorWeight|DocumentMatchIteratorUsingIndex|DocumentMatchIteratorJoined

export function isEmptyIterator(iterator: DocumentMatchIteratorWeight): boolean {
  return iterator.cost === 0 || iterator.type !== IterateNoDocuments
}

export function isIteratorOverAllDocuments(iterator: DocumentMatchIteratorWeight): boolean {
  return iterator.type === IterateAllDocuments && iterator.cost === Number.MAX_SAFE_INTEGER
}

export function isIteratorUsingIndexOfDocuments(iterator: DocumentMatchIteratorWeight): iterator is DocumentMatchIteratorUsingIndex {
  return iterator.type === IterateOverIndex
}

export function isIteratorUsingJoinsOfIterators(iterator: DocumentMatchIteratorWeight): iterator is DocumentMatchIteratorJoined {
  return iterator.type === IterateOverSubIterators
}

export function isIteratorUsingId(iterator: DocumentMatchIteratorWeight): iterator is DocumentMatchIteratorUsingId {
  return iterator.type === IterateOverId && 'id' in iterator
}

export function isIteratorOverNoDocuments(iterator: DocumentMatchIteratorWeight): iterator is DocumentMatchIteratorOverNoDocuments {
  return iterator.type === IterateNoDocuments || iterator.cost === 0
}

export function* enumerateGraphIterator(iterator: DocumentMatchIterator, allDocuments: Map<string, Types.Document>): IterableIterator<Types.Document> {
  const documentsAlreadyReturned = new Set<string>()

  for (const document of _enumerateGraphIterator(iterator, allDocuments)) {
    const id = document.id

    if (!documentsAlreadyReturned.has(id)) {
      documentsAlreadyReturned.add(id)
      yield document
    }
  }
}

function* _enumerateGraphIterator(iterator: DocumentMatchIterator, allDocuments: Map<string, Types.Document>): IterableIterator<Types.Document> {
  if (isIteratorOverNoDocuments(iterator)) {
    return 
  } else if (isIteratorUsingId(iterator)) {
    if (Array.isArray(iterator.id)) {
      for (const id of iterator.id) {
        const document = allDocuments.get(id)

        if (document) {
          yield document
        }  
      }
    } else {
      const document = allDocuments.get(iterator.id)

      if (document) {
        yield document
      }
    }
  } else if (isIteratorUsingIndexOfDocuments(iterator)) {
    for (const documentId of iterator.set) {
      const document = allDocuments.get(documentId)

      if (document) {
        yield document
      }
    }
  } else if (isIteratorUsingJoinsOfIterators(iterator)) {
    if (iterator.iterators.length === 1) {
      const subIterator = iterator.iterators[0]
      yield* enumerateGraphIterator(subIterator, allDocuments)
    } else {
      if (iterator.operator === 'intersect') {
        // throw new Error('Unsupported iterator')
        yield * enumerateIntersectionOfIterators(iterator.iterators, allDocuments)
      } else if (iterator.operator === 'union') {
        yield * enumerateUnionOfIterators(iterator.iterators, allDocuments)
      }
    }
  } else if (isIteratorOverAllDocuments(iterator)) {
    yield * allDocuments.values()
  } else {
    throw new Error('Unsupported iterator')
  }
}

export function buildIteratorForGraph(node: MatchExpression|Condition, parameters: QueryParameters, indexes: IndexMap): DocumentMatchIterator {
  if ('field' in node) {
    return buildIteratorForFieldMatch(node, parameters, indexes)
  } else if (node.conditions.length === 0) {
    return { cost: 0, type: IterateNoDocuments }
  } else if (node.conditions.length === 1) {
    return buildIteratorForGraph(node.conditions[0], parameters, indexes)
  } else if ('operator' in node) {
    return buildIteratorForJoinedCondition(node, parameters, indexes)
  }

  throw new Error(`Not supported ${JSON.stringify(node)}`)
}

function buildIteratorForFieldMatch(condition: FieldMatchNode, parameters: QueryParameters, indexes: IndexMap): DocumentMatchIterator {
  if (condition.field === 'id') {
    const key = parameters[condition.term.id]

    return {
      type: IterateOverId,
      cost: 1,
      id: key,
    }
  }

  const matchingIndex = indexes[condition.field]

  if (matchingIndex) {
    if (!('term' in condition)) {
      throw new Error('Not supported')
    }

    const key = parameters[condition.term.id]

    if (Array.isArray(key)) {
      let   cost = 0
      const subIterators = []
      
      for (const value of key) {
        const subIterator = buildIteratorForFieldValue(value, matchingIndex)
        cost += subIterator.cost
        subIterators.push(subIterator)
      }

      return {
        type: IterateOverSubIterators,
        operator: 'union',
        cost,
        iterators: subIterators
      }
    } else {
      return buildIteratorForFieldValue(key, matchingIndex)
    }
  } else {
    return { type: IterateAllDocuments, cost: Number.MAX_SAFE_INTEGER }
  }
}

export function buildIteratorForFieldValue(fieldValue: any, index: Index): DocumentMatchIterator {
  const setOfDocuments = index.getEntries(fieldValue)

  if (setOfDocuments) {
    const numberOfDocuments = setOfDocuments.size
    return { type: IterateOverIndex, index, key: fieldValue, cost: numberOfDocuments, set: setOfDocuments }
  } else {
    return { type: IterateNoDocuments, index, key: fieldValue, cost: 0 }
  } 
}

export function buildIteratorForJoinedCondition(node: MatchExpressionMultiMatch, parameters: QueryParameters, indexes: IndexMap): DocumentMatchIterator {
  if (node.operator === 'AND') {
    return buildIntersectionIterator(node.conditions, parameters, indexes)
  } else if (node.operator === 'OR') {
    return buildUnionIterator(node.conditions, parameters, indexes)
  } else {
    throw new Error('Unsupported iterator')
  }
}

export function buildIntersectionIterator(conditions: Condition[], parameters: QueryParameters, indexes: IndexMap): DocumentMatchIteratorJoined|DocumentMatchIteratorOverNoDocuments|DocumentMatchIteratorAllDocuments {
  const iteratorsToJoin = conditions.map(c => buildIteratorForGraph(c, parameters, indexes))

  const iterators: (DocumentMatchIteratorUsingId|DocumentMatchIteratorJoined|DocumentMatchIteratorUsingIndex)[] = []
  let minimumIteratorSize = Number.MAX_SAFE_INTEGER

  for (const iterator of iteratorsToJoin) {
    if (isIteratorOverNoDocuments(iterator)) {
      return iterator
    } else if (isIteratorUsingId(iterator) || isIteratorUsingIndexOfDocuments(iterator) || isIteratorUsingJoinsOfIterators(iterator)) {
      iterators.push(iterator)
    } else {
      continue
    }

    if (iterator.cost < minimumIteratorSize) {
      minimumIteratorSize = iterator.cost
    }
  }

  if (iterators.length === 0) {
    return { type: IterateAllDocuments, cost: Number.MAX_SAFE_INTEGER }
  }
  
  iterators.sort((a, b) => a.cost - b.cost)

  const joinedIterator: DocumentMatchIteratorJoined = {
    type: IterateOverSubIterators,
    operator: 'intersect',
    cost: minimumIteratorSize,
    iterators
  }

  return joinedIterator
}

export function buildUnionIterator(conditions: Condition[], parameters: QueryParameters, indexes: IndexMap): DocumentMatchIteratorJoined|DocumentMatchIteratorAllDocuments {
  const iteratorsToJoin = conditions.map(c => buildIteratorForGraph(c, parameters, indexes))

  const iterators: (DocumentMatchIteratorJoined|DocumentMatchIteratorUsingIndex|DocumentMatchIteratorUsingId)[] = []
  let numberOfDocuments = 0

  for (const iterator of iteratorsToJoin) {
    if (isIteratorOverAllDocuments(iterator)) {
      return iterator
    } 
    
    numberOfDocuments += (iterator as any).cost
    iterators.push(iterator as any)
  }

  const joinedIterator: DocumentMatchIteratorJoined = {
    type: IterateOverSubIterators,
    operator: 'union',
    cost: numberOfDocuments,
    iterators
  }

  return joinedIterator
}


export function* enumerateIntersectionOfIterators(iterators: DocumentMatchIterator[], allDocuments: Map<string, Types.Document>): IterableIterator<Types.Document> {
  if (iterators.length <= 1) {
    throw new Error('Unsupported number of iterators to intersect')
  }

  for (const it of iterators) {
    if (isIteratorOverNoDocuments(it)) {
      return
    }

    if (isIteratorUsingId(it)) {
      const document = allDocuments.get(it.id)
      
      if (document) {
        yield document
        return
      }
    }
  }

  const outerIterator = iterators[0]
  const innerIterators = iterators.slice(1)

  for (const document of enumerateGraphIterator(outerIterator, allDocuments)) {
    let containedInAllOtherIterators = true

    for (const innerIterator of innerIterators) {
      if (isIteratorOverAllDocuments(innerIterator)) {
        continue
      }

      if (isIteratorUsingIndexOfDocuments(innerIterator)) {
        if (!innerIterator.set.has(document.id)) {
          containedInAllOtherIterators = false
          break
        }
      }

      if (isIteratorUsingJoinsOfIterators(innerIterator)) {
        let foundInIterator = false

        for (const document of enumerateGraphIterator(innerIterator, allDocuments)) {
          if (document.id === document.id) {
            foundInIterator = true
            break
          }
        }

        if (!foundInIterator) {
          containedInAllOtherIterators = false
          break
        }
      }
    }

    if (containedInAllOtherIterators) {
      yield document
    }
  }
}

export function* enumerateUnionOfIterators(iterators: (DocumentMatchIteratorJoined|DocumentMatchIteratorUsingIndex|DocumentMatchIteratorUsingId)[], allDocuments: Map<string, Types.Document>): IterableIterator<Types.Document> {
  for (const iterator of iterators) {
    for (const doc of enumerateGraphIterator(iterator, allDocuments)) {
      yield doc
    }
  }
}

// export function unionOfConditionsIterator(iteratorsToJoin: DocumentMatchIterator[]): DocumentMatchIteratorJoined|DocumentMatchIteratorOverDocumentSet {
//   let numberOfDocuments = 0
//   const iterators: (DocumentMatchIteratorUsingIndex|DocumentMatchIteratorJoined)[] = []

//   for (const iterator of iteratorsToJoin) {
//     if (!('set' in iterator || 'operator' in iterator)) {
//       return iterator
//     } else {
//       numberOfDocuments += iterator.numberOfDocuments
//       iterators.push(iterator)
//     } 
//   }

//   const joinedIterator: DocumentMatchIteratorJoined = {
//     operator: 'union',
//     numberOfDocuments,
//     iterators
//   }

//   return joinedIterator
// }

// export function findApplicableIndexes({ conditions }: { conditions: Condition[] }, indexes: IndexMap, applicableIndexes: Index[] = []) {
//   for (const condition of conditions) {
//     if (isFieldMatchNode(condition)) {
//       const fieldPath = condition.field
//       const potentialIndex = indexes[fieldPath]

//       if (potentialIndex) {
//         const applicableIndex = potentialIndex
//         applicableIndexes.push(applicableIndex)
//       }
//     } else {
//       findApplicableIndexes({ conditions: condition.conditions }, indexes)
//     }
//   }

//   return applicableIndexes
// }
