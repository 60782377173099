export default <any> {
  inject: ['getAeppicContext'],
  props: ['href'],
  render(h) {
    const href = this.href
    const Aeppic = this.getAeppicContext('ae-link')

    const data = <any>{
      Aeppic,
      attrs: {
        href
      },
      on: {
        click(e) {
          Aeppic.onLinkClicked(e, href)
        }
      }
    }

    return h('a', data, this.$slots.default)
  }
}
