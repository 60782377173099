
export class FieldFormatter {
  lowercase(value: string) {
    return value.toLowerCase()
  }
  uppercase(value: string) {
    return value.toUpperCase()
  }
  capitalize(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1)
  }
  trim(value: string) {
    return value.trim()
  }
  length(value: string, maxLength: number) {
    return value.slice(0, maxLength)
  }
  exclude(value: string, ...excludedCharacters: string[]) {
    // if the excludedCharacter starts with / and ends with / or /i, it is a regular expression
    const excludedCharactersRegex = excludedCharacters.map(char => {
      if (char.startsWith('/') && char.endsWith('/')) {
        return new RegExp(char.slice(1, -1))
      }
      if (char.startsWith('/') && char.endsWith('/i')) {
        return new RegExp(char.slice(1, -2), 'i')
      }
      return char
    })
    
    return value.split('').filter(char => !excludedCharactersRegex.some(excludedChar => {
      if (excludedChar instanceof RegExp) {
        return excludedChar.test(char)
      }
      return excludedChar === char
    })).join('')
  }
  allow(value: string, ...allowedCharacters: string[]) {
    // if the allowedCharacter starts with / and ends with / or /i, it is a regular expression
    const allowedCharactersRegex = allowedCharacters.map(char => {
      if (char.startsWith('/') && char.endsWith('/')) {
        return new RegExp(char.slice(1, -1))
      }
      if (char.startsWith('/') && char.endsWith('/i')) {
        return new RegExp(char.slice(1, -2), 'i')
      }
      return char
    })

    return value.split('').filter(char => allowedCharactersRegex.some(allowedChar => {
      if (allowedChar instanceof RegExp) {
        return allowedChar.test(char)
      }
      return allowedChar === char
    })).join('')
  }
  replace(value: string, searchValue: string|RegExp, replaceValue: string) {
    return value.replace(searchValue, replaceValue)
  }
  prefix(value, prefix) {
    if (value.startsWith(prefix)) {
      return value
    }
    return prefix + value
  }
  suffix(value, suffix) {
    if (value.endsWith(suffix)) {
      return value
    }
    return value + suffix
  }
  pattern(value, pattern) {
    return formatStringByPattern(value, pattern)
  }
}

let knownFunctions = null

export function getKnownFieldFormatterFunctions() {
  if (!knownFunctions) {
    knownFunctions = Object.getOwnPropertyNames(FieldFormatter.prototype).filter(name => name !== 'constructor')
  }

  return knownFunctions
}

export function isKnownFieldFormatterFunction(name: string) {
  return getKnownFieldFormatterFunctions().includes(name)
}

const PATTERN_TOKENS = {
  '0': {pattern: /\d/, _default: '0'},
  '9': {pattern: /\d/, optional: true},
  // '#': {pattern: /\d/, optional: true, recursive: true},
  'A': {pattern: /[a-zA-Z0-9]/},
  'S': {pattern: /[a-zA-Z]/},
  'U': {pattern: /[a-zA-Z]/, transform: function(c) { return c.toLocaleUpperCase(); }},
  'L': {pattern: /[a-zA-Z]/, transform: function(c) { return c.toLocaleLowerCase(); }},
  // '$': {escape: true}
}

function formatStringByPattern(value: string, pattern: string) { // , options: { reverse: boolean } = { reverse: false }) {
  let patternArray = pattern.split('')
  let valueArray = value.split('')

  let formattedValue = ''
  let valueIndex = 0

  for (const pattern of patternArray) {
    if (valueIndex >= valueArray.length) {
      break
    }

    let token = PATTERN_TOKENS[pattern]

    if (token) {
      let valueChar = valueArray[valueIndex]

      if (token.pattern.test(valueChar)) {
        formattedValue += token.transform ? token.transform(valueChar) : valueChar
        valueIndex++
      } else if (token.optional) {
        valueIndex++
      } else {
        formattedValue += token._default ?? ''
      }
    } else {
      formattedValue += pattern

      if (valueArray[valueIndex] === pattern) {
        valueIndex++
      }
    }
  }

  return formattedValue
}