import Aeppic from './aeppic.js'
import Types from '@aeppic/types'

// export class ChangeScope {
//   constructor(private _ancestorId: string) {

//   }

//   public get pendingDocuments() {
//     return 0
//   }
// } 

export default class Changes {
  // private _scopes = new Map<string, ChangeScope>()
  private _remembered = new Map<string, Types.Document>()

  constructor(private aeppic: Aeppic) {
  }

  // public newScope(ancestorId) {
  //   const s = new ChangeScope(ancestorId)
  //   this._scopes[ancestorId] = s
  //   return s
  // }

  public remember(document: Types.Document) {
    this._remembered.set(document.id, document)
  }

  public forget(document: Types.Document) {
    this._remembered.delete(document.id)
  }

  public removeRememberedChildren(parent: Types.Document) {
    const children = []

    for (const entry of this._remembered.values()) {
      if (entry.p === parent.id) {
        children.push(entry)
      }
    }

    for (const child of children) {
      this._remembered.delete(child.id)
    }

    return children
  }
}
