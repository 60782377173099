export function sleep(ms: number = 10, { keepReference = false } = {}) {
  let resolve = null 
  const promise = new Promise(r => resolve = r)

  const returnValue: any = setTimeout(resolve, ms)

  if (!keepReference && typeof returnValue === 'object') {
    returnValue?.unref?.()
  }

  return promise
}
