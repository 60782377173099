import { parse as generatedParse } from './parser-generated.js'
import completeTypeParsing from './types-parser.js'

export function parse(definition) {
  const uncommented = removeComments(definition + '\n')
  const formInfo = generatedParse(uncommented.definition)
  completeTypeParsing(formInfo)
  return formInfo
}

// Remove all lines starting with `//`.
// Return the modified definition and the index of each line removed.
function removeComments(definition) {
  const lines = definition.split('\n')
  const removed = []

  for (let i = 0; i < lines.length; i++) {
    if (lines[i].startsWith('//')) {
      lines[i] = ''
      removed.push(i)
    }
  }

  for (const i of removed.reverse()) {
    lines.splice(i, 1)
  }

  return { definition: lines.join('\n'), removed }
}
