import * as Types from '@aeppic/types'
import { Index, IndexMap, IndexOptions, intersectSets } from './document-index.js'

export class DocumentIndexer {
  private _indexes: IndexMap = {}

  constructor() {}

  clear() {
    this._indexes = {}
  }

  // Only supports string or number fields for now
  createIndex(fieldPath: string, options?: IndexOptions) {
    this._indexes[fieldPath] = new Index(fieldPath, options)
  }

  get indexMap() {
    return this._indexes
  }

  add(...documents: Types.Document[]) {
    for (const index of Object.values(this._indexes)) {
      for (const d of documents) {
        if (d.id == null) {
          throw new Error('Documents MUST have a non-empty id field')
        }
        index.addIfMatches(d)
      }
    }
  }

  remove(document: Types.Document) {
    for (const index of Object.values(this._indexes)) {
      index.remove(document.id)

      if (index.has(document.id)) {
        console.error('Document still in index', document.id, index.fieldPath)
      }
    }
  }

  has(documentId: string) {
    for (const index of Object.values(this._indexes)) {
      if (index.has(documentId)) {
        return true
      }
    }

    return false
  }
}
