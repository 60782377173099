import { AeppicInterface } from '../aeppic.js'
import { Query } from '../../model'

export type Policy = {
  name: string
  reason: string
  queries: (Query.Query|Query.QueryBuilder)[],
  refresh: 'onboot'
  strategy: 'stale-subscriptions'
  evict: 'never'
}


export class Cache {
  private _policies: PolicyList = new PolicyList()

  constructor(private _aeppic: AeppicInterface) {    
  }

  addPolicy(policy: Policy) {
    const cachedPolicy: CachedPolicy =  {
      ...policy,
      queries: policy.queries.map(q => Query.toQuery(q)),
    }
    this._policies.addPolicy(cachedPolicy)
  }

  clear() {
    this._policies.clear()
  }
}

type CachedPolicy = {
  name: string
  reason: string
  queries: Query.Query[],
  refresh: 'onboot'
  strategy: 'stale-subscriptions'
  evict: 'never'
}

class PolicyList {
  private _policies: {
    [key: string]: CachedPolicy
  } = {}

  constructor() {
    this._restoreFromLocalStorage()
  }

  clear() {
    this._policies = {}
    this._storeInLocalStorage()
  }

  addPolicy(policy: CachedPolicy) {
    this._policies[policy.name] = policy
    this._storeInLocalStorage()
  }

  private _storeInLocalStorage() {
    if (typeof globalThis.localStorage === 'undefined' || !globalThis.localStorage) {
      // console.warn('No local storage available, cannot store cache policies');
      return;
    }
    
    globalThis.localStorage.setItem('policies', JSON.stringify(this._policies))
  }

  private _restoreFromLocalStorage() {
    if (typeof globalThis.localStorage === 'undefined' || !globalThis.localStorage) {
      // console.warn('No local storage available, cannot restore cache policies')
      return;
    }

    const policies = globalThis.localStorage.getItem('policies')

    if (policies) {
      try {
        this._policies = JSON.parse(policies)
      } catch (error) {
        console.error('Error parsing cache policies from local storage', error)
      }
    }
  }
}