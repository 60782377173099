import type Aeppic from 'aeppic/index.js'

import * as Types from '@aeppic/types'
import { buildDeferred, Deferred } from '@aeppic/shared/defer'
import { isDocument } from '../../model/is.js'

export class DocumentLookupCache {
  private _cache = new Map<string,Types.Document|Deferred<Types.Document>>()

  constructor(private _aeppic: Aeppic, type: string) {
    // this._aeppic.watchMatchingDocuments(`f.id:${type}`, (document) => {
    //   console.warn('DocumentLookupCache: clearing cache for type', type)
    //   this._cache.clear()
    // })
  }

  get(key: string): Promise<Types.Document>|Types.Document|null|undefined {
    const entry = this._cache.get(key)

    if (entry === null) {
      return null
    }

    if (entry) {
      if (isDocument(entry as any)) {
        return entry as Types.Document
      } else {
        const deferred = entry as Deferred<Types.Document>
        return deferred.promise
      }
    } else {
      const deferred = buildDeferred<Types.Document>()
      this._cache.set(key, deferred)
      return undefined
    }
  }

  set(key: string, document: Types.Document|null) {
    const entry = this._cache.get(key)
    this._cache.set(key, document)

    if (entry) {
      if (!isDocument(entry as any)) {
        const deferred = entry as Deferred<Types.Document>
        deferred.resolve(document)
      }
    }
  }
}