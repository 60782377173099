import * as util from './util.js'

/**
 * Creates a SHA-256 message digest object.
 *
 * @return a message digest object.
 */
export function create() {
  // do initialization as necessary
  if (!_initialized) {
    _init()
  }

  // SHA-256 state contains eight 32-bit integers
  let _state = null

  // input buffer
  let _input = util.createBuffer()

  // used for word storage
  const _w = new Array(64)

  // message digest object
  let md: any = {
    algorithm: 'sha256',
    blockLength: 64,
    digestLength: 32,
    // 56-bit length of message so far (does not including padding)
    messageLength: 0,
    // true 64-bit message length as two 32-bit ints
    messageLength64: [0, 0]
  }

  /**
   * Starts the digest.
   *
   * @return this digest object.
   */
  md.start = function() {
    md.messageLength = 0
    md.messageLength64 = [0, 0]
    _input = util.createBuffer()
    _state = {
      h0: 0x6A09E667,
      h1: 0xBB67AE85,
      h2: 0x3C6EF372,
      h3: 0xA54FF53A,
      h4: 0x510E527F,
      h5: 0x9B05688C,
      h6: 0x1F83D9AB,
      h7: 0x5BE0CD19
    }
    return md
  }
  // start digest automatically for first time
  md.start()

  /**
   * Updates the digest with the given message input. The given input can
   * treated as raw input (no encoding will be applied) or an encoding of
   * 'utf8' maybe given to encode the input using UTF-8.
   *
   * @param msg the message input to update with.
   * @param encoding the encoding to use (default: 'raw', other: 'utf8').
   *
   * @return this digest object.
   */
  md.update = function(msg, encoding) {
    if (encoding === 'utf8') {
      msg = util.encodeUtf8(msg)
    }

    // update message length
    md.messageLength += msg.length
    md.messageLength64[0] += (msg.length / 0x100000000) >>> 0
    md.messageLength64[1] += msg.length >>> 0

    // add bytes to input buffer
    _input.putBytes(msg)

    // process bytes
    _update(_state, _w, _input)

    // compact input buffer every 2K or if empty
    if (_input.read > 2048 || _input.length() === 0) {
      _input.compact()
    }

    return md
  }

  /**
   * Produces the digest.
   *
   * @return a byte buffer containing the digest value.
   */
  md.digest = function(format = 'hex') {
    /* Note: Here we copy the remaining bytes in the input buffer and
    add the appropriate SHA-256 padding. Then we do the final update
    on a copy of the state so that if the user wants to get
    intermediate digests they can do so. */

    /* Determine the number of bytes that must be added to the message
    to ensure its length is congruent to 448 mod 512. In other words,
    the data to be digested must be a multiple of 512 bits (or 128 bytes).
    This data includes the message, some padding, and the length of the
    message. Since the length of the message will be encoded as 8 bytes (64
    bits), that means that the last segment of the data must have 56 bytes
    (448 bits) of message and padding. Therefore, the length of the message
    plus the padding must be congruent to 448 mod 512 because
    512 - 128 = 448.

    In order to fill up the message length it must be filled with
    padding that begins with 1 bit followed by all 0 bits. Padding
    must *always* be present, so if the message length is already
    congruent to 448 mod 512, then 512 padding bits must be added. */

    // 512 bits == 64 bytes, 448 bits == 56 bytes, 64 bits = 8 bytes
    // _padding starts with 1 byte with first bit is set in it which
    // is byte value 128, then there may be up to 63 other pad bytes
    const padBytes = util.createBuffer()
    padBytes.putBytes(_input.bytes())
    // 64 - (remaining msg + 8 bytes msg length) mod 64
    padBytes.putBytes(
      _padding.substr(0, 64 - ((md.messageLength64[1] + 8) & 0x3F)))

    /* Now append length of the message. The length is appended in bits
    as a 64-bit number in big-endian order. Since we store the length in
    bytes, we must multiply the 64-bit length by 8 (or left shift by 3). */
    padBytes.putInt32(
      (md.messageLength64[0] << 3) | (md.messageLength64[0] >>> 28))
    padBytes.putInt32(md.messageLength64[1] << 3)
    const s2 = {
      h0: _state.h0,
      h1: _state.h1,
      h2: _state.h2,
      h3: _state.h3,
      h4: _state.h4,
      h5: _state.h5,
      h6: _state.h6,
      h7: _state.h7
    }
    _update(s2, _w, padBytes)
    const rval = util.createBuffer()
    rval.putInt32(s2.h0)
    rval.putInt32(s2.h1)
    rval.putInt32(s2.h2)
    rval.putInt32(s2.h3)
    rval.putInt32(s2.h4)
    rval.putInt32(s2.h5)
    rval.putInt32(s2.h6)
    rval.putInt32(s2.h7)

    if (format === 'hex') {
      return rval.toHex()
    }

    return rval
  }

  return md
}

// sha-256 padding bytes not initialized yet
let _padding = null
let _initialized = false

// table of constants
let _k = null

/**
 * Initializes the constant tables.
 */
function _init() {
  // create padding
  _padding = String.fromCharCode(128)
  _padding += util.fillString(String.fromCharCode(0x00), 64)

  // create K table for SHA-256
  _k = [
    0x428a2f98, 0x71374491, 0xb5c0fbcf, 0xe9b5dba5,
    0x3956c25b, 0x59f111f1, 0x923f82a4, 0xab1c5ed5,
    0xd807aa98, 0x12835b01, 0x243185be, 0x550c7dc3,
    0x72be5d74, 0x80deb1fe, 0x9bdc06a7, 0xc19bf174,
    0xe49b69c1, 0xefbe4786, 0x0fc19dc6, 0x240ca1cc,
    0x2de92c6f, 0x4a7484aa, 0x5cb0a9dc, 0x76f988da,
    0x983e5152, 0xa831c66d, 0xb00327c8, 0xbf597fc7,
    0xc6e00bf3, 0xd5a79147, 0x06ca6351, 0x14292967,
    0x27b70a85, 0x2e1b2138, 0x4d2c6dfc, 0x53380d13,
    0x650a7354, 0x766a0abb, 0x81c2c92e, 0x92722c85,
    0xa2bfe8a1, 0xa81a664b, 0xc24b8b70, 0xc76c51a3,
    0xd192e819, 0xd6990624, 0xf40e3585, 0x106aa070,
    0x19a4c116, 0x1e376c08, 0x2748774c, 0x34b0bcb5,
    0x391c0cb3, 0x4ed8aa4a, 0x5b9cca4f, 0x682e6ff3,
    0x748f82ee, 0x78a5636f, 0x84c87814, 0x8cc70208,
    0x90befffa, 0xa4506ceb, 0xbef9a3f7, 0xc67178f2]

  // now initialized
  _initialized = true
}

/**
 * Updates a SHA-256 state with the given byte buffer.
 *
 * @param s the SHA-256 state to update.
 * @param w the array to use to store words.
 * @param bytes the byte buffer to update with.
 */
function _update(s, w, bytes) {
  // consume 512 bit (64 byte) chunks
  let t1, t2, s0, s1, ch, maj, i, a, b, c, d, e, f, g, h
  let len = bytes.length()
  
  while (len >= 64) {
    // the w array will be populated with sixteen 32-bit big-endian words
    // and then extended into 64 32-bit words according to SHA-256
    for (i = 0; i < 16; i += 1) {
      w[i] = bytes.getInt32()
    }
    for (; i < 64; i += 1) {
      // XOR word 2 words ago rot right 17, rot right 19, shft right 10
      t1 = w[i - 2]
      t1 =
        ((t1 >>> 17) | (t1 << 15)) ^
        ((t1 >>> 19) | (t1 << 13)) ^
        (t1 >>> 10)
      // XOR word 15 words ago rot right 7, rot right 18, shft right 3
      t2 = w[i - 15]
      t2 =
        ((t2 >>> 7) | (t2 << 25)) ^
        ((t2 >>> 18) | (t2 << 14)) ^
        (t2 >>> 3)
      // sum(t1, word 7 ago, t2, word 16 ago) modulo 2^32
      w[i] = (t1 + w[i - 7] + t2 + w[i - 16]) | 0
    }

    // initialize hash value for this chunk
    a = s.h0
    b = s.h1
    c = s.h2
    d = s.h3
    e = s.h4
    f = s.h5
    g = s.h6
    h = s.h7

    // round function
    for (i = 0; i < 64; ++i) {
      // Sum1(e)
      s1 =
        ((e >>> 6) | (e << 26)) ^
        ((e >>> 11) | (e << 21)) ^
        ((e >>> 25) | (e << 7))
      // Ch(e, f, g) (optimized the same way as SHA-1)
      ch = g ^ (e & (f ^ g))
      // Sum0(a)
      s0 =
        ((a >>> 2) | (a << 30)) ^
        ((a >>> 13) | (a << 19)) ^
        ((a >>> 22) | (a << 10))
      // Maj(a, b, c) (optimized the same way as SHA-1)
      maj = (a & b) | (c & (a ^ b))

      // main algorithm
      t1 = h + s1 + ch + _k[i] + w[i]
      t2 = s0 + maj
      h = g
      g = f
      f = e
      e = (d + t1) | 0
      d = c
      c = b
      b = a
      a = (t1 + t2) | 0
    }

    // update hash state
    s.h0 = (s.h0 + a) | 0
    s.h1 = (s.h1 + b) | 0
    s.h2 = (s.h2 + c) | 0
    s.h3 = (s.h3 + d) | 0
    s.h4 = (s.h4 + e) | 0
    s.h5 = (s.h5 + f) | 0
    s.h6 = (s.h6 + g) | 0
    s.h7 = (s.h7 + h) | 0
    len -= 64
  }
}
