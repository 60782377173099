import { camelCase } from '@aeppic/shared/camel-case'
import Vue from '../../externals/vue.js'

const PARAM_PREFIX = 'param'

export default function createParamRewriterFunctionalComponent(wrappedComponentName) {
  return {
    functional: true,
    render: function (createElement, context) {
      const data = context.data

      data.props = { ...data.attrs, ...data.props, params: jsonifyParams(context.props) }

      const extractedAttrs = extractDataAttrs(data.attrs)

      // overwrite attrs by extracted data-* attrs
      const propertiesWithDataAttrs = { 
        ...data,
        attrs: extractedAttrs
      }
      
      return createElement(
          Vue.component(wrappedComponentName),
          propertiesWithDataAttrs,
          context.children
        )
    }
  }
}

/**
 * Extract HTML attributes of data-* style
 * @param attrs Vue functional component context.attrs
 */
function extractDataAttrs(attrs) {
  if (!attrs) {
    return attrs
  }

  const extracted = {}

  for (const key of Object.keys(attrs)) {
    if (!key.startsWith('data-')) {
      continue
    }

    extracted[key] = attrs[key]
  }

  return extracted
}

function jsonifyParams(params) {
  const paramObject = {}

  for (const name in params) {
    if (name === 'params') {
      continue
    }
    
    if (name.indexOf(PARAM_PREFIX) === 0 && name.length !== PARAM_PREFIX.length) {
      const paramName = name.substr(PARAM_PREFIX.length)
      const firstCharacter = paramName[0].toLowerCase()
      const camelCasedName = firstCharacter + paramName.substr(1)

      const value = verifyRegularToStringFunction(params, name)

      paramObject[camelCasedName] = value
    }
  }

  // params specified via json object instead of param-* style
  const props = params.params

  if (props) {
    if (typeof props !== 'string') {
      for (const name in props) {
        const value = verifyRegularToStringFunction(props, name)

        paramObject[name] = value
      }
    }
  }

  return paramObject
}

function verifyRegularToStringFunction(params, name) {
  const value = params[name]

  if (value && typeof value.toString !== 'function') {
    console.warn(`ignoring param "${name}" since it seems not to have a regular prototype: toString() function missing`)
    return null
  }

  return value
}
