export default {
  startEditing(refName) {
    if (!this.$refs[refName]) {
      return
    }

    return this.$refs[refName].startEditing()
  },
  cancelEditing(refName) {
    if (!this.$refs[refName]) {
      return
    }

    return this.$refs[refName].cancelEditing()
  },
  saveAndStopEditing(refName) {
    if (!this.$refs[refName]) {
      return
    }

    return this.$refs[refName].saveAndStopEditing()
  },
  save(refName) {
    if (!this.$refs[refName]) {
      return
    }

    return this.$refs[refName].save()
  }
}
