import { EventEmitter } from  '@aeppic/shared/event-emitter'

import { EditableDocument } from '.' 

type Level = 'fatal' | 'error' | 'warn' | 'info' | 'debug' | 'trace'
type LevelWithSilent = Level | 'silent'

type SerializerFn = (value: any) => any
// type WriteFn = (o: object) => void;

/* tslint:disable:no-console */

export const SimpleConsoleLogger: Logger = {
  child(_bindings: LoggerOptions): Logger {
    return this
  },
  trace(message: any, ...args: any[]) {
    console.trace(message, ...args)
  },
  debug(message: any, ...args: any[]) {
    console.debug(message, ...args)
  },
  info(message: any, ...args: any[]) {
    console.log(message, ...args)
  },
  warn(message: any, ...args: any[]) {
    console.warn(message, ...args)
  },
  error(message: any, ...args: any[]) {
    console.error(message, ...args)
  },
  fatal(message: any, ...args: any[]) {
    console.error(message, ...args)
  },
  flush() {
    // noop   
  }
}

type LoggerOptions = {
  [key: string]: any
  level?: string
  serializers?: {
    [key: string]: SerializerFn;
  }
}

export interface LogFunction {
  (msg: string, ...args: any[]): void
  (obj: object, msg?: string, ...args: any[]): void
}

export interface BaseLogger {
    /**
     * Creates a child logger, setting all key-value pairs in `bindings` as properties in the log lines. All serializers will be applied to the given pair.
     * Child loggers use the same output stream as the parent and inherit the current log level of the parent at the time they are spawned.
     * From v2.x.x the log level of a child is mutable (whereas in v1.x.x it was immutable), and can be set independently of the parent.
     * If a `level` property is present in the object passed to `child` it will override the child logger level.
     *
     * @param bindings: an object of key-value pairs to include in log lines as properties.
     * @returns a child logger instance.
     */
    child(bindings: LoggerOptions): Logger

    /**
     * Log at `'fatal'` level the given msg. If the first argument is an object, all its properties will be included in the JSON line.
     * If more args follows `msg`, these will be used to format `msg` using `util.format`.
     *
     * @param obj: object to be serialized
     * @param msg: the log message to write
     * @param ...args: format string values when `msg` is a format string
     */
    fatal: LogFunction
    /**
     * Log at `'error'` level the given msg. If the first argument is an object, all its properties will be included in the JSON line.
     * If more args follows `msg`, these will be used to format `msg` using `util.format`.
     *
     * @param obj: object to be serialized
     * @param msg: the log message to write
     * @param ...args: format string values when `msg` is a format string
     */
    error: LogFunction
    /**
     * Log at `'warn'` level the given msg. If the first argument is an object, all its properties will be included in the JSON line.
     * If more args follows `msg`, these will be used to format `msg` using `util.format`.
     *
     * @param obj: object to be serialized
     * @param msg: the log message to write
     * @param ...args: format string values when `msg` is a format string
     */
    warn: LogFunction
    /**
     * Log at `'info'` level the given msg. If the first argument is an object, all its properties will be included in the JSON line.
     * If more args follows `msg`, these will be used to format `msg` using `util.format`.
     *
     * @param obj: object to be serialized
     * @param msg: the log message to write
     * @param ...args: format string values when `msg` is a format string
     */
    info: LogFunction
    /**
     * Log at `'debug'` level the given msg. If the first argument is an object, all its properties will be included in the JSON line.
     * If more args follows `msg`, these will be used to format `msg` using `util.format`.
     *
     * @param obj: object to be serialized
     * @param msg: the log message to write
     * @param ...args: format string values when `msg` is a format string
     */
    debug: LogFunction
    /**
     * Log at `'trace'` level the given msg. If the first argument is an object, all its properties will be included in the JSON line.
     * If more args follows `msg`, these will be used to format `msg` using `util.format`.
     *
     * @param obj: object to be serialized
     * @param msg: the log message to write
     * @param ...args: format string values when `msg` is a format string
     */
    trace: LogFunction

    /**
     * Flushes the content of the buffer in extreme mode. It has no effect if extreme mode is not enabled.
     */
    flush(): void
}

export type Logger = BaseLogger
let defaultLogLevel: string = 'warn'

export function setDefaultLogLevel(level: string) {
  defaultLogLevel = level
}

function NoLogOp() {
  return () => {}
}

// NOTE: Would be nice to have a global for NoLogOp or VoidLogger
//      but due to the way the module is loaded, it's not possible
//      as they would not be initialized before calling buildLogger by other modules
function VoidLogger() {
  return {
    fatal: NoLogOp(),
    error: NoLogOp(),
    warn: NoLogOp(),
    info: NoLogOp(),
    debug: NoLogOp(),
    trace: NoLogOp(),
    child: () => VoidLogger(),
    flush: () => {}
  } as Logger
}

export { VoidLogger }

export function buildLogger(scope: string): Logger
export function buildLogger(baseLogger: Logger, options: LoggerOptions): Logger
export function buildLogger(baseLogger: Logger, scope: string, options: LoggerOptions): Logger
export function buildLogger(_param1: string|Logger, _param2?: string|LoggerOptions, param3?: LoggerOptions): Logger {
  const baseLogger = typeof _param1 === 'string' ? VoidLogger() : _param1 ?? VoidLogger()
  const scope = typeof _param1 === 'string' ? _param1 : typeof _param2 === 'string' ? _param2 : undefined
  const options = typeof _param2 === 'object' ? _param2 : typeof param3 === 'object' ? param3 : {}

  options.scope = scope

  return baseLogger.child(options)
}

const FORMAT_REGULAR_EXPRESSION = /(%?)(%([jds]))/g

export interface LogEntry {
  level: number
  time: number
  defaults: object
  params: any[] 
}

export interface LogLine {
  level: number
  time: number
  msg?: string
  [key: string]: any
}

export const LogLevels = {
  fatal: 60,
  error: 50,
  warn: 40,
  info: 30,
  debug: 20,
  trace: 10
}



export interface InMemoryLogOptions {
  size?: number
  defaults?: object
}

const IN_MEMORY_DEFAULT_LOG_OPTIONS = {
  size: 1000,
  defaults: null,
}

export class InMemoryLog {
  private _store: LogEntry[]
  private _globalDefaults = []
  private _index = -1
  private _options: InMemoryLogOptions

  constructor(options: InMemoryLogOptions = {}) {
    this._options = { ...IN_MEMORY_DEFAULT_LOG_OPTIONS, ...options }
    
    if (options.size) {
      this._store = new Array(options.size)
      this._index = this._store.length
    } else {
      this._store = []
    }
  }

  public child(defaultObject: object = null): Logger {
    // return this._logger
    const log: any = createLogFunction(this, defaultObject)
    log.trace = createLogFunction(this, defaultObject, LogLevels.trace)
    log.debug = createLogFunction(this, defaultObject, LogLevels.debug)
    log.info = createLogFunction(this, defaultObject, LogLevels.info)
    log.warn = createLogFunction(this, defaultObject, LogLevels.warn)
    log.error = createLogFunction(this, defaultObject, LogLevels.error)
    log.fatal = createLogFunction(this, defaultObject, LogLevels.fatal)

    log.child = (childDefaultObject: object) => {
      const combinedDefault = { ...defaultObject, ...childDefaultObject }
      return this.child(combinedDefault)
    }

    return <Logger> log
  }

  public write(entry: LogEntry) {
    if (this._index === -1) {
      // Always store in reverse order
      this._store.unshift(entry)
    } else {
      if (this._index === 0) {
        this._index = this._store.length 
      }

      this._index -= 1
      this._store[this._index] = entry
    }
  }

  public *enumerate(count = 100): IterableIterator<LogLine> {
    return yield* Array.from(this.enumerateBackwards(100)).reverse()
  }

  public *enumerateBackwards(count = 100): IterableIterator<LogLine> {
    // Since all data is already stored backwards this means enumerating forwards beginning
    // at the index 
    const startIndex = this._index === -1 ? 0 : this._index
    let circled = false

    let returnedCount = 0
   
    for (let index = startIndex ;; index += 1) {
      if (index === this._store.length) {
        index = 0
        circled = true
      }
      
      if (circled && index === startIndex) {
        break
      }

      const entry = this._store[index]

      if (!entry) {
        return
      }

      const entryParams = entry.params
      let item
      let formattedMessage

      if (typeof entryParams[0] === 'string') {
        item = null
        const message = entryParams[0]
        const messageParameters = entryParams.slice(1)
        formattedMessage = format(message, messageParameters)
      } else if (typeof entryParams[1] === 'string') {
        item = entryParams[0]
        const message = entryParams[1]
        const messageParameters = entryParams.slice(2)
        formattedMessage = format(message, messageParameters)
      } else if (entryParams[1]) {
        console.error('If first parameter is not a string, second parameter can only be a string', entryParams)
        continue
      } else {
        item = entryParams[0]
      }

      const logLine: LogLine = { ...entry.defaults, ...item, ...this._options.defaults, msg: formattedMessage || '',  level: entry.level, time: entry.time }

      yield logLine
      returnedCount ++

      if (returnedCount >= count) {
        return <LogLine> { ...entry.defaults, ...this._options.defaults, msg: 'LOG LIMIT REACHED', level: LogLevels.debug, time: Date.now() }
      }
    }
  }
}

function createLogFunction(log: InMemoryLog, defaults: object, level: number = LogLevels.info): LogFunction {
  return function logFunction(...params) {
    const cleanedParams = params.map(clean)
    log.write({ defaults, level, time: Date.now(), params: cleanedParams })
  }
}

function clean(obj: any) {
  if (EditableDocument.isEditableDocument(obj)) {
    // TODO: CLEAN sensitive fields, shorten content
    return obj.cloneAsDocument()
  } else {
    return obj
  }
}

function format(formatString: string, ...args: any[]) {
  if (args.length) {
    formatString = formatString.replace(FORMAT_REGULAR_EXPRESSION, (match, escaped, ptn, flag) => {
      let arg = args.shift()

      switch (flag) {
        case 's':
          arg = '' + arg
          break
        case 'd':
          arg = Number(arg)
          break
        case 'j':
          arg = JSON.stringify(arg)
          break
      }

      if (!escaped) {
        return arg 
      }

      args.unshift(arg)
      
      return match
    })
  }

  // arguments remain after formatting
  if (args.length) {
    formatString += ' ' + args.join(' ')
  }

  // update escaped %% values
  formatString = formatString.replace(/%{2,2}/g, '%')

  return '' + formatString
}
