import * as Types from '@aeppic/types'
import { once, clear as clearOnceCache } from '@aeppic/shared/once'
import { assert } from '@aeppic/shared/assert'

import Aeppic from '../aeppic.js'


import { isDocument } from '../../model/is.js'
import { loadPackageInfo } from './loader.js'

import { importSourceFile } from './import.js'
import { importCommonJSExportsFromScript } from './import-commonjs.js'

import type { ImportPackageFileFunction, PackageInfo, NamedExports, PackageFileLoadInfo, PackageExports } from './types.js'

export interface PackageFileUrlLookupFunction {
  (packageReference: Types.Reference, relativeFilePath: string): string
}

export interface Options {
  Aeppic: Aeppic,
  // transform: (packageDocument: Types.Document) => Types.Document
  packagesLookupUrl: string|PackageFileUrlLookupFunction
  fetch?: typeof fetch
  importOverride?: (uri: string) => Promise<unknown>
  globals?: object
}

interface LookupPackageFile {
  (packageReference: Types.Reference, relativeFilePath: string): {
    fileUrl: string;
    nonDottedRelativeFilePath: string;
  }
}

const PACKAGE_ONCE_CACHE = 'PACKAGE'

export class Packages {
  private _aeppic: Aeppic
  private _importPackageFile: ImportPackageFileFunction
  private _fetch: Options['fetch']
  private _importOverride: Options['importOverride']
  private _globals: Options['globals']
  private _lookupPackageFile: LookupPackageFile

  constructor({ Aeppic, packagesLookupUrl = '/api/packages', fetch, importOverride, globals }: Options) {
    this._aeppic = Aeppic
    this._fetch = fetch
    this._importOverride = importOverride
    this._globals = globals

    this._lookupPackageFile = this._buildPackageFileUrlLookupFunction(packagesLookupUrl)
    this._importPackageFile = this._buildPackageFileLoader()    
  }

  public getUrlForPackageFile(packageReference: Types.Reference, relativeFilePath: string) {
    const { fileUrl } = this._lookupPackageFile(packageReference, relativeFilePath)
    return fileUrl
  }

  private _buildPackageFileUrlLookupFunction(packagesLookupUrl: Options['packagesLookupUrl']) {
    if (typeof packagesLookupUrl === 'string') {
      let packagesLookupUrlPrefix = null
      packagesLookupUrlPrefix = packagesLookupUrl
      
      if (!packagesLookupUrlPrefix.endsWith('/')) {
        packagesLookupUrlPrefix = `${packagesLookupUrlPrefix}/`
      }
      
      return (packageReference: Types.Reference, relativeFilePath: string) => {
        const nonDottedRelativeFilePath = makeNonDottedPath(relativeFilePath)
        const fileUrl = `${packagesLookupUrlPrefix}${packageReference.id}@${packageReference.v}/files/${nonDottedRelativeFilePath}`

        return {
          fileUrl,
          nonDottedRelativeFilePath,
        }
      }
    } else {
      return (packageReference: Types.Reference, relativeFilePath: string) => {
        const nonDottedRelativeFilePath = makeNonDottedPath(relativeFilePath)

        return {
          fileUrl: packagesLookupUrl(packageReference, nonDottedRelativeFilePath),
          nonDottedRelativeFilePath,
        }
      }
    }
  }
  
  public async loadAll(packageRefs: Types.Reference[]): Promise<PackageInfo[]> {
    const documents = await this._aeppic.getAll(packageRefs)
    return Promise.all(documents.map(d => this.loadSingle(d)))
  }

  public async loadSingle(packageRefOrDocument: Types.Document|Types.Reference): Promise<PackageInfo> {
    const packageDocument = isDocument(packageRefOrDocument) ? packageRefOrDocument : await this._aeppic.get(packageRefOrDocument)
    const uniquePackageCacheKey = `pkg:${packageDocument.id}@${packageDocument.v}`

    return once(
      PACKAGE_ONCE_CACHE,
      uniquePackageCacheKey,
      async () => {
        let packageInfo: PackageInfo = null

        try {
          packageInfo = await loadPackageInfo(packageDocument, { importPackageFile: this._importPackageFile.bind(this) })
        } catch (error) {
          this._logPackageInfoLoadError(packageDocument, error)
        }

        return packageInfo
      })
  }
  
  public async importSourceFile(packageInfo: PackageInfo, path: string = '.'): Promise<PackageExports> {
    const uniqueSourceFileKey = `src:${packageInfo.identifier}/${path}`
    
    return once(
      PACKAGE_ONCE_CACHE,
      uniqueSourceFileKey,
      () => {
        return this._importSourceFile(packageInfo, path)
      }
    )
  }

  private _importSourceFile(packageInfo: PackageInfo, path: string = '.'): Promise<PackageExports> {
    return importSourceFile(packageInfo, path, {
      importFunction: this._importPackageFile.bind(this),
      pathToUrl: this.getUrlForPackageFile.bind(this),
    })
  }

  private _logPackageInfoLoadError(packageDocument: Types.Document, error: any) {
    console.error('Error loading package', packageDocument, error)
  }


  private _buildPackageFileLoader() {   
    const aeppic = this._aeppic
    const fetch = this._fetch
    const globals = this._globals

    return async function loadFunction(packageReference: Types.Reference, fileInfo: PackageFileLoadInfo) {
      const { fileUrl, nonDottedRelativeFilePath } = this._lookupPackageFile(packageReference, fileInfo.filePath)
      const packageIdentifier = `${packageReference.id}@${packageReference.v}`

      // NOTE: Could be unified across multiple files
      const contextifiedAeppic = aeppic.contextify(`package://${packageIdentifier}/${nonDottedRelativeFilePath}`)

      if (fileInfo.type === 'module') {
        return this._import(fileUrl)
      }

      const response = await fetch(fileUrl)

      if (response.ok) {
        const text = await response.text()
        
        if (fileInfo.type === 'commonjs') {
          assert(aeppic.Vue)

          return importCommonJSExportsFromScript(text, {
            scriptIdentifier: `${packageIdentifier}/${nonDottedRelativeFilePath}`,
            globals: { Aeppic: contextifiedAeppic, ...getBrowserContextGlobals(), ...globals },
            requires: { vue: aeppic.Vue, aeppic: contextifiedAeppic },
          })
        } else if (fileInfo.type === 'json') {
          return text
        }
        /* c8 ignore start */
        else {
          throw new Error(`Unsupported type to import: ${fileUrl} => type: ${fileInfo.type}`)
        }
        /* c8 ignore stop */
      } else {
        throw new Error(`File ${fileUrl} could not be fetched (${response.statusText})`)
      }
    }
  }

  _import(fileUrl: string): Promise<unknown> {
    // `import` is a keyword and not a function, thus we cannot pass
    // it in but must fallback to an override function during testing
    // this also means we cannot calculate coverage over the import line
    // if we dont actually import

    /* c8 ignore start */
    if (this._importOverride) {
      return this._importOverride(fileUrl)
    } else {
      return import(fileUrl)
    }
    /* c8 ignore stop */
  }
}

function getBrowserContextGlobals() {
  if (typeof window === 'undefined') {
    return {}
  }
  /* c8 ignore start */
  else {
    return {
      window,
      document,
      require: window.require,
    }
  }
  /* c8 ignore stop */
}

function makeNonDottedPath(packageRelativeFilePath: string) {
  const relativeFilePath = packageRelativeFilePath
  assert(relativeFilePath.startsWith('./'))

  const nonDottedRelativeFilePath = relativeFilePath.substr(2)
  return nonDottedRelativeFilePath
}
