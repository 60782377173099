export default function getNow() {
  const nowDate = new Date()

  const localTimezoneOffset = -nowDate.getTimezoneOffset()
  const diff = localTimezoneOffset >= 0 ? '+' : '-'

  const localDate = nowDate.getFullYear()
    + '-' + pad(nowDate.getMonth() + 1)
    + '-' + pad(nowDate.getDate())
    + 'T' + pad(nowDate.getHours())
    + ':' + pad(nowDate.getMinutes()) 
    + ':' + pad(nowDate.getSeconds()) 
    + diff + pad(localTimezoneOffset / 60) 
    + ':' + pad(localTimezoneOffset % 60)

  return { isoTz: localDate, ticks: nowDate.valueOf() }
}

function pad(num) {
  const norm = Math.abs(Math.floor(num))
  return (norm < 10 ? '0' : '') + norm
}
