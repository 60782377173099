import type * as Types from '@aeppic/types'
import { Form, IFormFieldAtPlaceholder } from 'model/form.js'
import { FindOptions } from 'model/model.js'
import { ParsedField } from '@aeppic/forms-parser'
import type { AeppicInterface } from '../aeppic.js'

export type LookupFunction = (aeppic: AeppicInterface, formId: Types.DocumentId, currentDocument?: Types.Document, lookupTrace?: LookupTrace) => AsyncIterableIterator<Types.DocumentId>
export type LookupEntry = Types.DocumentId|LookupFunction

export class LookupTrace {
  private _steps: LookupTraceStep[] = []
  
  public formId: Types.DocumentId
  public namespace: String
  public name: String
  public field?: ParsedField|null

  addStep(step: LookupTraceStep) {
    this._steps.push(step)
  }

  get steps() {
    return this._steps
  }
} 

export type LookupTraceStep = {
  operation: string,
  documentId: Types.DocumentId,
  additionalInfo?: any
}


export class FormBasedLookup<T> {
  private _warnedAbout = new Set()

  constructor(
    private _aeppic: AeppicInterface,
    private _type: string,
    private _strategy: LookupEntry[],
    private _folderSearchFunction: (aeppic: AeppicInterface, folderId: Types.DocumentId, formId: Types.DocumentId, namespace: string, name: string, extra: T, lookupTrace?: LookupTrace) => Promise<Types.Document>,
  ) {
  }

  async find(formId: Types.DocumentId, namespace: string, name: string, extra: T|null, lookupTrace?:LookupTrace): Promise<Types.Document|null> {
    if (typeof formId != 'string') {
      throw new Error('formId must be a string')
    }
    
    const control = await this._findThin(formId, namespace, name, extra, lookupTrace)

    if (control) {
      return this._aeppic.get(control.id)
    } else {
      const key = `${formId}:${namespace}:${name}`
      
      if (!this._warnedAbout.has(key)) {
        this._warnedAbout.add(key)
        console.warn(`aeppic: Could not find ${this._type} for selector ${namespace}:${name} in form ${formId}`)
      }

      return null
    }
  }

  // Find a control document in the system (only returns a subset of the data)
  async _findThin(formId: Types.DocumentId, namespace: string = '', name: string, extra: T, lookupTrace?:LookupTrace): Promise<Types.Document|null> {
    const checkedFolders = new Set()

    for (const lookup of this._strategy) {
      let control = null

      if (typeof lookup == 'string') {
        const folderId = lookup

        if (checkedFolders.has(folderId)) {
          lookupTrace?.addStep({ operation: `find:${this._type}:already-checked`, documentId: folderId })
          continue
        }

        checkedFolders.add(folderId)
        control = await this._folderSearchFunction(this._aeppic, folderId, formId, namespace, name, extra, lookupTrace)
      } else {
        for await (const parentId of lookup(this._aeppic, formId, null, lookupTrace)) {
          if (checkedFolders.has(parentId)) {
            lookupTrace?.addStep({ operation: `find:${this._type}:already-checked`, documentId: parentId })
            continue
          }
  
          checkedFolders.add(parentId)

          control = await this._folderSearchFunction(this._aeppic, parentId, formId, namespace, name, extra, lookupTrace)

          if (control) {
            break
          }
        }
      }

      if (control) {
        return control
      }
    }
    
    return null
  }
}

export function ancestorTraversal(ancestorFinder: (aeppic: AeppicInterface, formId: Types.DocumentId) => Promise<Types.DocumentId[]>, fns: LookupFunction[]): any {
  return async function *ancestorTraversal(aeppic: AeppicInterface, formId: Types.DocumentId, _: any, trace: LookupTrace): AsyncIterableIterator<Types.DocumentId> {
    const ancestors = await ancestorFinder(aeppic, formId)

    if (!ancestors) {
      trace?.addStep({ operation: 'ancestorTraversal:no-ancestors', documentId: formId })
      return
    }

    const ancestorsInReverseOrder = [...ancestors]
    ancestorsInReverseOrder.reverse()

    trace?.addStep({ operation: 'ancestorTraversal', documentId: formId, additionalInfo: { start: formId, ancestorsInReverseOrder } })

    for (const ancestorId of ancestorsInReverseOrder) {
      let parent = await aeppic.get(ancestorId)

      if (!parent) {
        continue
      }

      trace?.addStep({ operation: 'ancestorTraversal:step:checks:begin', documentId: ancestorId, additionalInfo: { functions: fns.length } })

      for (const fn of fns) {
        yield * await fn(aeppic, formId, parent, trace)
      }

      trace?.addStep({ operation: 'ancestorTraversal:step:checks:end', documentId: ancestorId })
    }
  }
}

export function checkInReferencedFolders(fieldName: string, allowedFormIds: Types.DocumentId[]): LookupFunction {
  return async function *checkInReferencedFolders(aeppic: AeppicInterface, formId: Types.DocumentId, document: Types.Document, trace: LookupTrace): AsyncIterableIterator<Types.DocumentId> {
    // Some forms contains a reference to a number of control folders to look in
    if (!allowedFormIds.includes(document.f.id)) {
      trace?.addStep({ operation: 'checkInReferencedFolders:irrelevant-form', documentId: document.id, additionalInfo: { formId: document.f.id } })
      return
    }

    const references: Types.ReferenceField[] = document.data[fieldName] as Types.ReferenceField[] ?? []

    trace?.addStep({ operation: 'checkInReferencedFolders:form', documentId: document.id, additionalInfo: { references }})

    for (const reference of references) {
      yield reference.id
    }
  }
}


// Check in each child of type 'control-folder' under the document 
export function lookForFoldersOfType(formType: string, findOptions: FindOptions): LookupFunction {
  return async function *lookForFolders(aeppic: AeppicInterface, formId: Types.DocumentId, document: Types.Document, trace: LookupTrace): AsyncIterableIterator<Types.DocumentId> {
    const folders = await aeppic.find(aeppic.Query.children(document).form(formType), { ...findOptions, fields: ['name'] })
    
    trace?.addStep({ operation: 'lookForFolders', documentId: document.id, additionalInfo: { folders: folders.map(cf => cf.id) } })

    for (const folder of folders) {
      yield folder.id
    }
  }
}

export async function *form(aeppic: AeppicInterface, formId: Types.DocumentId, _: any, trace: LookupTrace): AsyncIterableIterator<Types.DocumentId> {
  trace?.addStep({ operation: 'checkUnderForm', documentId: formId })    
  yield formId
}

export async function getFullFormAncestry(aeppic: AeppicInterface, formId: Types.DocumentId): Promise<Types.DocumentId[]> {
  // NOTE: Aeppic.get does not support partial data yet, and forms are
  // pretty big
  const form = await aeppic.findOne(aeppic.Query.global().where('id').is(formId), { fields: [], sort: 'created.at' })

  if (!form) {
    return null
  }

  return ['root', ...form.a]
}

export function parseSelector(selector: string): { namespace: string, name: string } {
  if (!selector) {
    return { namespace: '', name: '' }
  }

  if (typeof selector != 'string') {
    console.error('Selector must be a string', typeof selector)
    throw new Error('Selector must be a string')
  }

  const parts = selector.split(':', 2).map(s => s.trim())

  if (parts.length == 1) {
    return { namespace: '', name: parts[0] }
  } else {
    return { namespace: parts[0], name: parts[1] }
  }
}