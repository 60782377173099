import * as Types from '@aeppic/types'
import IChangeReporter from '../shared/types/change-reporter.js'

export enum ChangeType {
  UPDATED = 'updated',
  INSERT_OR_UPDATE = 'insertOrUpdate',
  DELETED = 'deleted',
  UNDELETED = 'undeleted',
  DELETED_HARD = 'deleted-hard',
  MOVED = 'moved',
  CHANGED_FORM = 'changed-form',
  ADDED_DETACHED_VERSION = 'added-detached-version',
}

export interface Change {
  type: ChangeType
  document: Types.Document

  count?: number
  isSingleUndelete?: boolean

  undeletePlaceholderId?: string
}

export interface ChangeSet {
  changes: Change[]
}

let changeCount = 0
export default class ChangeTracker implements IChangeReporter {
  private _changes: Change[]

  startTracking() {
    this._changes = [] 
  }

  stopTracking(): Change[] {
    if (this._changes == null) {
      throw new Error('Tracking was not started')
    }

    return this._changes
  }

  /**
   * 
   *
   * @param document
   */
  updated(document: Types.Document, { insertOrUpdate = false }) {
    if (this._changes) {
      const type = insertOrUpdate === false ? ChangeType.UPDATED : ChangeType.INSERT_OR_UPDATE 
      this._changes.push({ count: changeCount++, type, document })
    }
  }

  /**
   * Document was moved 
   */
  moved(document: Types.Document) {
    if (this._changes) {
      this._changes.push({ count: changeCount++, type: ChangeType.MOVED, document })
    }
  }

  /**
   * Document Form was changed
   */
  changedForm(document: Types.Document) {
    if (this._changes) {
      this._changes.push({ count: changeCount++, type: ChangeType.CHANGED_FORM, document })
    }
  }
  
  /**
   * Delete of document (moved to the recycler but raises different events and
   * will also mean creating some more documents around it one day) 
   */
  deleted(document: Types.Document) {
    if (this._changes) {
      this._changes.push({ count: changeCount++, type: ChangeType.DELETED, document })
    }
  }

  /**
   * Undelete of document (the document is removed from the recycler and saved with potentially
   * different data than it originally had when deleted) 
   */
  undeleted(document: Types.Document, single = false, placeholderId?: string) {
    if (this._changes) {
      const change = {
        type: ChangeType.UNDELETED,
        document,
        isSingleUndelete: single,
        undeletePlaceholderId: placeholderId
      }
      this._changes.push(change)  
    }
  }
  
  /**
   * Document was fully deleted
   */
  deletedHard(document: Types.Document) {
    if (this._changes) {
      this._changes.push({ count: changeCount++, type: ChangeType.DELETED_HARD, document })
    }
  }

  /**
   * A detached Document Version was added
   */
  addedDetachedVersion(document: Types.Document) {
    if (this._changes) {
      this._changes.push({ count: changeCount++, type: ChangeType.ADDED_DETACHED_VERSION, document })
    }
  }
}
