import { fibonacci } from '../utils/backoff'

export interface ConnectedEvent {
  // wasReconnection: boolean
  // msSinceDisconnect?: number 
}

export interface Options {
  protocol?: string
  connected?: () => void
  disconnected?: () => void
  interrupted?: () => void
  connectionFailed?: (error) => void
  
  messageReceived?: (data) => void
  textReceived?: (text: string) => void
  binaryReceived?: (binary: ArrayBuffer) => void
  
  WebSocket?: typeof WebSocket
}

export class WebSocketConnection {
  private _backoff: any
  private _shouldBeConnected = false
  private _isConnecting = false
  // private _connectedAt: number
  private _ws: WebSocket
  private _WebSocket: typeof WebSocket

  constructor(private _serverUrl: string, private _options: Options) {
    if (_options.WebSocket) {
      this._WebSocket = _options.WebSocket
    } else {
      if (typeof WebSocket === 'undefined') {
        throw new Error('WebSocket not defined and not available in global scope')
      }
      this._WebSocket = WebSocket
    }

    this._backoff = fibonacci({
      randomisationFactor: 0.1,
      initialDelay: 10,
      maxDelay: 5000
    })

    this._backoff.on('ready', (...args) => {
      if (this._shouldBeConnected) {
        // tslint:disable-next-line
        console.log('reconnection attempt', ...args)
        this.connect()
      }
    })
  }

  get isConnected() {return this._ws != null}

  send(message) {
    if (!this.isConnected) {
      throw new Error('not connected')
    }
    this._ws.send(message)
  }

  connect() {
    if (this._isConnecting || this.isConnected) {
      return
    }

    this._shouldBeConnected = true
    this._isConnecting = true

    this._connect(() => {
      this._isConnecting = false

      if (this._options.connected) {
        this._options.connected()
      }
    })
  }

  private _connect(successfulConnectionCallback: () => any) {
    const ws = new this._WebSocket(this._serverUrl, this._options.protocol)
    ws.binaryType = 'arraybuffer'

    ws.onopen = () => {
      this._ws = ws
      this._backoff.reset()
      successfulConnectionCallback()
    }

    ws.onerror = (error) => {
      if (this.isConnected && this._options.interrupted) {
        this._options.interrupted()
      } else if (!this.isConnected && this._options.connectionFailed) {
        this._options.connectionFailed(error)
      }

      if (this._shouldBeConnected) {
        this._backoff.backoff()
      }
    }

    ws.onclose = () => {      
      this._isConnecting = false
      
      if (this._shouldBeConnected) {
        // tslint:disable-next-line
        if (this.isConnected) {
          this._backoff.backoff()
          
          if (this._options.interrupted) {
            this._options.interrupted()
          }
        }
      } else {
        // tslint:disable-next-line
        console.log('disconnected', this._serverUrl)
        
        if (this._options.disconnected) {
          this._options.disconnected()
        }
      }

      this._ws = null
    }

    ws.onmessage = (message: MessageEvent) => {
      if (!this._ws) {
        return
      }

      if (this._options.messageReceived) {
        this._options.messageReceived(message)
      }

      const data = message.data

      if (!data) {
        return
      }

      if (typeof data === 'string') {
        if (this._options.textReceived) {
          this._options.textReceived(data)
        }
      } else {
        if (this._options.binaryReceived) {
          this._options.binaryReceived(<any>data)
        }          
      }
    }

  }

  disconnect() {
    this._backoff.reset()
    this._shouldBeConnected = false
    this._disconnect()
  }

  private _disconnect() {
    if (this._ws) {
      this._ws.close()
      this._ws = null
    }
  }
}
