
type PromiseResolveFunction<T> = (value?: T | PromiseLike<T>) => void
type PromiseRejectFunction = (reason?: any) => void

export type Deferred<T> = {
  promise: Promise<T>
  resolve: PromiseResolveFunction<T>
  reject: PromiseRejectFunction
}

export function buildDeferred<T>(): Deferred<T> {
  let reject: PromiseResolveFunction<T>
  let resolve: PromiseRejectFunction
  const promise = new Promise<T>((re, rj) => {resolve = re, reject = rj})
  return { promise, resolve, reject }
}
