import * as util from './util.js'

const _MAX_CONSTRUCTED_STRING_LENGTH = 4096

// TODO: set ByteBuffer to best available backing
export class ByteBuffer  {
  public data = ''
  public read = 0
  private _constructedStringLength: number

  /**
   * Constructor for a binary string backed byte buffer.
   *
   * @param [b] the bytes to wrap (either encoded as string, one byte per
   *          character, or as an ArrayBuffer or Typed Array).
   */
  constructor(b: any) {
    // TODO: update to match DataBuffer API
    if (typeof b === 'string') {
      this.data = b
    } else if (util.isArrayBuffer(b) || util.isArrayBufferView(b)) {
      // convert native buffer to forge buffer
      // FIXME: support native buffers internally instead
      const arr = new Uint8Array(b)
      try {
        this.data = String.fromCharCode.apply(null, arr)
      } catch (e) {
        for (let i = 0; i < arr.length; ++i) {
          this.putByte(arr[i])
        }
      }
    } else if (b instanceof ByteBuffer ||
      (typeof b === 'object' && typeof b.data === 'string' &&
      typeof b.read === 'number')) {
      // copy existing buffer
      this.data = b.data
      this.read = b.read
    }

    // used for v8 optimization
    this._constructedStringLength = 0
  }

  /* Note: This is an optimization for V8-based browsers. When V8 concatenates
    a string, the strings are only joined logically using a "cons string" or
    "constructed/concatenated string". These containers keep references to one
    another and can result in very large memory usage. For example, if a 2MB
    string is constructed by concatenating 4 bytes together at a time, the
    memory usage will be ~44MB so ~22x increase. The strings are only joined
    together when an operation requiring their joining takes place, such as
    substr(). This function is called when adding data to this buffer to ensure
    these types of strings are periodically joined to reduce the memory
    footprint. */
  _optimizeConstructedString(x) {
    this._constructedStringLength += x
    if (this._constructedStringLength > _MAX_CONSTRUCTED_STRING_LENGTH) {
      // this substr() should cause the constructed string to join
      this.data.substr(0, 1)
      this._constructedStringLength = 0
    }
  }

  /**
   * Gets the number of bytes in this buffer.
   *
   * @return the number of bytes in this buffer.
   */
  length() {
    return this.data.length - this.read
  }

  /**
   * Gets whether or not this buffer is empty.
   *
   * @return true if this buffer is empty, false if not.
   */
  isEmpty() {
    return this.length() <= 0
  }

  /**
   * Puts a byte in this buffer.
   *
   * @param b the byte to put.
   *
   * @return this buffer.
   */
  putByte(b) {
    return this.putBytes(String.fromCharCode(b))
  }

  /**
   * Puts a byte in this buffer N times.
   *
   * @param b the byte to put.
   * @param n the number of bytes of value b to put.
   *
   * @return this buffer.
   */
  fillWithByte(b, n) {
    b = String.fromCharCode(b)
    
    let d = this.data

    while (n > 0) {
      if (n & 1) {
        d += b
      }
      n >>>= 1
      if (n > 0) {
        b += b
      }
    }
    this.data = d
    this._optimizeConstructedString(n)
    return this
  }

  /**
   * Puts bytes in this buffer.
   *
   * @param bytes the bytes (as a UTF-8 encoded string) to put.
   *
   * @return this buffer.
   */
  putBytes(bytes) {
    this.data += bytes
    this._optimizeConstructedString(bytes.length)
    return this
  }

  /**
   * Puts a UTF-16 encoded string into this buffer.
   *
   * @param str the string to put.
   *
   * @return this buffer.
   */
  putString(str) {
    return this.putBytes(util.encodeUtf8(str))
  }

  /**
   * Puts a 16-bit integer in this buffer in big-endian order.
   *
   * @param i the 16-bit integer.
   *
   * @return this buffer.
   */
  putInt16(i) {
    return this.putBytes(
      String.fromCharCode(i >> 8 & 0xFF) +
      String.fromCharCode(i & 0xFF))
  }

  /**
   * Puts a 24-bit integer in this buffer in big-endian order.
   *
   * @param i the 24-bit integer.
   *
   * @return this buffer.
   */
  putInt24(i) {
    return this.putBytes(
      String.fromCharCode(i >> 16 & 0xFF) +
      String.fromCharCode(i >> 8 & 0xFF) +
      String.fromCharCode(i & 0xFF))
  }

  /**
   * Puts a 32-bit integer in this buffer in big-endian order.
   *
   * @param i the 32-bit integer.
   *
   * @return this buffer.
   */
  putInt32(i) {
    return this.putBytes(
      String.fromCharCode(i >> 24 & 0xFF) +
      String.fromCharCode(i >> 16 & 0xFF) +
      String.fromCharCode(i >> 8 & 0xFF) +
      String.fromCharCode(i & 0xFF))
  }

  /**
   * Puts a 16-bit integer in this buffer in little-endian order.
   *
   * @param i the 16-bit integer.
   *
   * @return this buffer.
   */
  putInt16Le(i) {
    return this.putBytes(
      String.fromCharCode(i & 0xFF) +
      String.fromCharCode(i >> 8 & 0xFF))
  }

  /**
   * Puts a 24-bit integer in this buffer in little-endian order.
   *
   * @param i the 24-bit integer.
   *
   * @return this buffer.
   */
  putInt24Le(i) {
    return this.putBytes(
      String.fromCharCode(i & 0xFF) +
      String.fromCharCode(i >> 8 & 0xFF) +
      String.fromCharCode(i >> 16 & 0xFF))
  }

  /**
   * Puts a 32-bit integer in this buffer in little-endian order.
   *
   * @param i the 32-bit integer.
   *
   * @return this buffer.
   */
  putInt32Le(i) {
    return this.putBytes(
      String.fromCharCode(i & 0xFF) +
      String.fromCharCode(i >> 8 & 0xFF) +
      String.fromCharCode(i >> 16 & 0xFF) +
      String.fromCharCode(i >> 24 & 0xFF))
  }

  /**
   * Puts an n-bit integer in this buffer in big-endian order.
   *
   * @param i the n-bit integer.
   * @param n the number of bits in the integer.
   *
   * @return this buffer.
   */
  putInt(i, n) {
    let bytes = ''
    do {
      n -= 8
      bytes += String.fromCharCode((i >> n) & 0xFF)
    } while (n > 0)
    return this.putBytes(bytes)
  }

  /**
   * Puts a signed n-bit integer in this buffer in big-endian order. Two's
   * complement representation is used.
   *
   * @param i the n-bit integer.
   * @param n the number of bits in the integer.
   *
   * @return this buffer.
   */
  putSignedInt(i, n) {
    if (i < 0) {
      i += 2 << (n - 1)
    }
    return this.putInt(i, n)
  }

  /**
   * Puts the given buffer into this buffer.
   *
   * @param buffer the buffer to put into this one.
   *
   * @return this buffer.
   */
  putBuffer(buffer) {
    return this.putBytes(buffer.getBytes())
  }

  /**
   * Gets a byte from this buffer and advances the read pointer by 1.
   *
   * @return the byte.
   */
  getByte() {
    return this.data.charCodeAt(this.read++)
  }

  /**
   * Gets a uint16 from this buffer in big-endian order and advances the read
   * pointer by 2.
   *
   * @return the uint16.
   */
  getInt16() {
    let rval = (
      this.data.charCodeAt(this.read) << 8 ^
      this.data.charCodeAt(this.read + 1))
    this.read += 2
    return rval
  }

  /**
   * Gets a uint24 from this buffer in big-endian order and advances the read
   * pointer by 3.
   *
   * @return the uint24.
   */
  getInt24() {
    let rval = (
      this.data.charCodeAt(this.read) << 16 ^
      this.data.charCodeAt(this.read + 1) << 8 ^
      this.data.charCodeAt(this.read + 2))
    this.read += 3
    return rval
  }

  /**
   * Gets a uint32 from this buffer in big-endian order and advances the read
   * pointer by 4.
   *
   * @return the word.
   */
  getInt32() {
    let rval = (
      this.data.charCodeAt(this.read) << 24 ^
      this.data.charCodeAt(this.read + 1) << 16 ^
      this.data.charCodeAt(this.read + 2) << 8 ^
      this.data.charCodeAt(this.read + 3))
    this.read += 4
    return rval
  }

  /**
   * Gets a uint16 from this buffer in little-endian order and advances the read
   * pointer by 2.
   *
   * @return the uint16.
   */
  getInt16Le() {
    let rval = (
      this.data.charCodeAt(this.read) ^
      this.data.charCodeAt(this.read + 1) << 8)
    this.read += 2
    return rval
  }

  /**
   * Gets a uint24 from this buffer in little-endian order and advances the read
   * pointer by 3.
   *
   * @return the uint24.
   */
  getInt24Le() {
    let rval = (
      this.data.charCodeAt(this.read) ^
      this.data.charCodeAt(this.read + 1) << 8 ^
      this.data.charCodeAt(this.read + 2) << 16)
    this.read += 3
    return rval
  }

  /**
   * Gets a uint32 from this buffer in little-endian order and advances the read
   * pointer by 4.
   *
   * @return the word.
   */
  getInt32Le() {
    let rval = (
      this.data.charCodeAt(this.read) ^
      this.data.charCodeAt(this.read + 1) << 8 ^
      this.data.charCodeAt(this.read + 2) << 16 ^
      this.data.charCodeAt(this.read + 3) << 24)
    this.read += 4
    return rval
  }

  /**
   * Gets an n-bit integer from this buffer in big-endian order and advances the
   * read pointer by n/8.
   *
   * @param n the number of bits in the integer.
   *
   * @return the integer.
   */
  getInt(n) {
    let rval = 0
    do {
      rval = (rval << 8) + this.data.charCodeAt(this.read++)
      n -= 8
    } while (n > 0)
    return rval
  }

  /**
   * Gets a signed n-bit integer from this buffer in big-endian order, using
   * two's complement, and advances the read pointer by n/8.
   *
   * @param n the number of bits in the integer.
   *
   * @return the integer.
   */
  getSignedInt(n) {
    let x = this.getInt(n)
    let max = 2 << (n - 2)
    if (x >= max) {
      x -= max << 1
    }
    return x
  }

  /**
   * Reads bytes out into a UTF-8 string and clears them from the buffer.
   *
   * @param count the number of bytes to read, undefined or null for all.
   *
   * @return a UTF-8 string of bytes.
   */
  getBytes(count) {
    let rval
    if (count) {
      // read count bytes
      count = Math.min(this.length(), count)
      rval = this.data.slice(this.read, this.read + count)
      this.read += count
    } else if (count === 0) {
      rval = ''
    } else {
      // read all bytes, optimize to only copy when needed
      rval = (this.read === 0) ? this.data : this.data.slice(this.read)
      this.clear()
    }
    return rval
  }

  /**
   * Gets a UTF-8 encoded string of the bytes from this buffer without modifying
   * the read pointer.
   *
   * @param count the number of bytes to get, omit to get all.
   *
   * @return a string full of UTF-8 encoded characters.
   */
  bytes(count?) {
    return (typeof(count) === 'undefined' ?
      this.data.slice(this.read) :
      this.data.slice(this.read, this.read + count))
  }

  /**
   * Gets a byte at the given index without modifying the read pointer.
   *
   * @param i the byte index.
   *
   * @return the byte.
   */
  at(i) {
    return this.data.charCodeAt(this.read + i)
  }

  /**
   * Puts a byte at the given index without modifying the read pointer.
   *
   * @param i the byte index.
   * @param b the byte to put.
   *
   * @return this buffer.
   */
  setAt(i, b) {
    this.data = this.data.substr(0, this.read + i) +
      String.fromCharCode(b) +
      this.data.substr(this.read + i + 1)
    return this
  }

  /**
   * Gets the last byte without modifying the read pointer.
   *
   * @return the last byte.
   */
  last() {
    return this.data.charCodeAt(this.data.length - 1)
  }

  /**
   * Creates a copy of this buffer.
   *
   * @return the copy.
   */
  copy() {
    let c = util.createBuffer(this.data)
    c.read = this.read
    return c
  }

  /**
   * Compacts this buffer.
   *
   * @return this buffer.
   */
  compact() {
    if (this.read > 0) {
      this.data = this.data.slice(this.read)
      this.read = 0
    }
    return this
  }

  /**
   * Clears this buffer.
   *
   * @return this buffer.
   */
  clear() {
    this.data = ''
    this.read = 0
    return this
  }

  /**
   * Shortens this buffer by trimming bytes off of the end of this buffer.
   *
   * @param count the number of bytes to trim off.
   *
   * @return this buffer.
   */
  truncate(count) {
    let len = Math.max(0, this.length() - count)
    this.data = this.data.substr(this.read, len)
    this.read = 0
    return this
  }

  /**
   * Converts this buffer to a hexadecimal string.
   *
   * @return a hexadecimal string.
   */
  toHex() {
    let rval = ''

    for (let i = this.read; i < this.data.length; i += 1) {
      let b = this.data.charCodeAt(i)
      if (b < 16) {
        rval += '0'
      }
      rval += b.toString(16)
    }
    return rval
  }

  /**
   * Converts this buffer to a UTF-16 string (standard JavaScript string).
   *
   * @return a UTF-16 string.
   */
  toString() {
    return util.decodeUtf8(this.bytes())
  }
}
/** End Buffer w/BinaryString backing */


/** Buffer w/UInt8Array backing */

/**
 * FIXME: Experimental. Do not use yet.
 *
 * Constructor for an ArrayBuffer-backed byte buffer.
 *
 * The buffer may be constructed from a string, an ArrayBuffer, DataView, or a
 * TypedArray.
 *
 * If a string is given, its encoding should be provided as an option,
 * otherwise it will default to 'binary'. A 'binary' string is encoded such
 * that each character is one byte in length and size.
 *
 * If an ArrayBuffer, DataView, or TypedArray is given, it will be used
 * *directly* without any copying. Note that, if a write to the buffer requires
 * more space, the buffer will allocate a new backing ArrayBuffer to
 * accommodate. The starting read and write offsets for the buffer may be
 * given as options.
 *
 * @param [b] the initial bytes for this buffer.
 * @param options the options to use:
 *          [readOffset] the starting read offset to use (default: 0).
 *          [writeOffset] the starting write offset to use (default: the
 *            length of the first parameter).
 *          [growSize] the minimum amount, in bytes, to grow the buffer by to
 *            accommodate writes (default: 1024).
 *          [encoding] the encoding ('binary', 'utf8', 'utf16', 'hex') for the
 *            first parameter, if it is a string (default: 'binary').
 */
/** End Buffer w/UInt8Array backing */
