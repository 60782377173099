import QueryParser from '@aeppic/query-parser'

import { QueryRefiner } from './refining.js'
import { DefaultOperator, QueryGraphNode, Query } from './types.js'

import { assert } from '@aeppic/shared/assert'

// const QueryParserCache = new Map<number, ParsedQuery>()

const INVALID_USE_OF_NOT_MATCH = /.*NOT\s*\(.*/

type ParseOptions = {
  defaultOperator?: DefaultOperator
  dontOptimize?: boolean
}

export function parse(queryString: string, { defaultOperator = 'AND' }: ParseOptions = {}): Query {
  const parsedGraph = lowlevelParse(queryString, { defaultOperator })
  return QueryRefiner.process(parsedGraph)
}

export function lowlevelParse(queryString: string, { defaultOperator = 'AND' }: ParseOptions = {}): QueryGraphNode {
  // const queryFunctionId = lookupQueryId(queryString)
  // const parsedQuery = QueryParserCache.get(queryFunctionId)

  // if (parsedQuery) {
  //   return parsedQuery
  // }
  if (INVALID_USE_OF_NOT_MATCH.test(queryString)) {
    throw new Error('NOT can only be used on individual terms not on groups in parentheses')
  }

  const parsedQueryGraph = QueryParser.parse(queryString)
  postProcessGraph(parsedQueryGraph, defaultOperator)
  return parsedQueryGraph
}

function postProcessGraph(node: QueryGraphNode, defaultOperator: DefaultOperator) {
  assert(node)

  if ('operator' in node) {
    if (node.operator === '<implicit>') {
      node.operator = defaultOperator
    } else if (node.operator === 'NOT') {
      node.operator = 'AND'
      negateTerm(node.right, defaultOperator)
    }
  }

  if ('left' in node) {
    postProcessGraph(node.left, defaultOperator)
  }

  if ('right' in node) {
    postProcessGraph(node.right, defaultOperator)
  }
}

function negateTerm(node: QueryGraphNode, defaultOperator: DefaultOperator) {
  assert(node)

  if ('field' in node) {
    if (node.fieldPrefix === '-') {
      node.fieldPrefix = ''
    } else {
      assert(node.termPrefix !== '-') 
      node.termPrefix = '-'
    }
  } else {
    if (node.operator === 'NOT') {
      node.operator = 'AND'
      negateTerm(node.left, defaultOperator)
      negateTerm(node.right, defaultOperator)
    } else {
      negateTerm(node.left, defaultOperator)
    }
  }
}
