import * as Types from '@aeppic/types'

import { EventEmitter } from  '@aeppic/shared/event-emitter'

import Vue from '../../externals/vue.js'
import Aeppic from '..'

import { EditableDocument } from '../../model'
import * as Watch from '../../model/document-watch' 
import * as QueryWatch from '../../model/query-watch' 

import { DynamicComponentBuilder } from './dynamic-component-builder'

import { loadStyle, loadStyleFromUrl } from '../utils/css'

import type { PackageInfo, PackageExports } from '../packages/types.js'

export interface DynamicComponentOptions {
  formId: string
  template: string
  exposedSymbols: string
  watch: Boolean
  watchRevisions: Boolean,
  debounce?: Boolean,
  debounceTimeout?: Number
}

export interface VueError {
  err: unknown
  vm: unknown
  info: unknown
}

export interface SyncCallback {
  (componentHasChanged: boolean): void
}

class VueLog {
  private previousHandler: (err: unknown, vm: unknown, info: unknown) => void = null
  private messages: VueError[] = []

  constructor(private level = 'error') {
    this.previousHandler = Vue.config[level + 'Handler']
    Vue.config[level + 'Handler'] = this._log.bind(this)
  }

  private _log(err: unknown, vm: unknown, info: unknown) {
    this.messages.push({ err, vm, info })
    
    if (this.previousHandler) {
      this.previousHandler(err, vm, info)
    }
  }

  start() {
    this.reset()
    Vue.config[this.level + 'Handler'] = this._log.bind(this)
  }

  stop() {
    Vue.config[this.level + 'Handler'] = this.previousHandler
  }

  reset() {
    this.messages.length = 0
  }

  get hasEntries() {
    return this.messages.length > 0
  }

  print() {
    for (const message of this.messages) {
      console[this.level](message)
    }
  }

  asText() {
    return this.messages.map(e => e.err).join('\n')
  }
}

let compilerErrorLog = null 
let compilerWarnLog = null 

const INITIAL_REFRESH_BOUNCE = 100
const MAX_REFRESH_BOUNCE = 60 * 1000
const AVERAGE_MINIMUM_REFRESH_INTERVAL = 2000

export default class DynamicComponent extends EventEmitter  {
  private componentId: string = null
  private componentDocument: Types.Document = null

  private componentDocumentsWatcher: QueryWatch.IQueryWatcher = null
  private componentDocumentRevisionsWatcher: Watch.IDocumentWatcher = null

  private _lastRefreshAt: number
  private _refreshRequestedAt: number
  private _refreshAt: number
  private _pendingRefresh: Promise<void>
  private _pendingRefreshComponent: Types.Document

  private _refreshBounce = INITIAL_REFRESH_BOUNCE
  private _destroyed = false
  
  constructor(private type: string, private aeppic: Aeppic, private options: DynamicComponentOptions) {
    super()

    if (compilerErrorLog == null) {
      compilerErrorLog = new VueLog('error')
    }
    if (compilerWarnLog == null) {
      compilerWarnLog = new VueLog('warn')
    }

    if (options.watch) {
      this.startWatchingForChangesToDocumentsOfForm(options.formId)
    }
    // if (options.debounce) {
    //   this.refresh = debounceAsync(this._refresh, options.debounceTimeout || 1000, this)
    // } else {
    //   this.refresh = this._refresh.bind(this)
    // }
  }
  
  public get isRefreshPending() {return !!this._pendingRefresh}
  public get timeUntilRefresh() {return this._refreshAt ? Math.max(0, this._refreshAt - Date.now()) : 0}

  private scheduleAutoRefresh(componentDocument: Types.Document) {
    this._pendingRefreshComponent = componentDocument
    
    if (!this._pendingRefresh) {
      this._refreshRequestedAt = Date.now()
      this._refreshAt = this._refreshRequestedAt + this._refreshBounce

      this._pendingRefresh = new Promise((resolve) => {
        setTimeout(() => {
          if (this._pendingRefresh === null) {
            return
          }

          this._pendingRefresh = null

          if (this._lastRefreshAt) {
            const timeSinceLastRefresh = Date.now() - this._lastRefreshAt

            if (timeSinceLastRefresh < AVERAGE_MINIMUM_REFRESH_INTERVAL) {
              this._refreshBounce *= 2
              this._refreshBounce = Math.min(MAX_REFRESH_BOUNCE, this._refreshBounce)
            } else if (timeSinceLastRefresh > INITIAL_REFRESH_BOUNCE) {
              this._refreshBounce /= 4
              this._refreshBounce = Math.max(INITIAL_REFRESH_BOUNCE, this._refreshBounce)
            }
          }

          this.refresh(this._pendingRefreshComponent)
          this._pendingRefreshComponent = null
        }, this._refreshBounce)
      })
    }

    return this._pendingRefresh
  }

  public reset() {
    this.stopWatchingComponentDocumentRevisions()
    this.componentId = null
    this.componentDocument = null
  }

  public destroy() {
    this.stopWatchingForComponentDocumentsOfFormChanges()
    this.reset()

    this._destroyed = true
  }

  get id() {
    return this.componentId
  }

  get document() {
    return this.componentDocument
  }

  /**
   * 
   * @param componentDocument 
   * @param syncCallback - When it is necessary to execute some code in the same
   *                       tick as the component.id change then use this callback 
   * 
   */
  async refresh(componentDocumentOrId: Types.Document|Types.DocumentId, syncCallback?: SyncCallback) {
    if (typeof componentDocumentOrId === 'string') {
      const componentDocument = await this.aeppic.get(componentDocumentOrId)
      return this._refresh(componentDocument, syncCallback)
    } else {
      return this._refresh(componentDocumentOrId, syncCallback)
    }
  }

  async _refresh(componentDocument: Types.Document, syncCallback?: SyncCallback) {
    if (this._destroyed) {
      return
    }

    this._pendingRefresh = null
    this._refreshAt = null
    this._refreshRequestedAt = null
    this._lastRefreshAt = Date.now()
    
    if (!componentDocument) {
      this.reset()
      return
    }

    const componentId = this.generateComponentId(componentDocument)

    const componentHasChanged = componentId !== this.componentId

    if (componentHasChanged) {
      await this.loadComponent(componentId, componentDocument)
      this.componentId = componentId
      this.componentDocument = componentDocument

      this.startWatchingComponentDocumentRevisions()
    }

    if (syncCallback) {
      syncCallback(componentHasChanged)
    }
}

  private generateComponentId(componentDocument: Types.Document) {
    let id = `ae-${this.type}--${componentDocument.id}_${componentDocument.v}`

    if (EditableDocument.isEditableDocument(componentDocument)) {
      if (componentDocument.editVersion) {
        id += `_rev_${(componentDocument).editVersion}`
      }
    }

    return id
  } 

  private async loadComponent(identifier: string, componentDocument: any) {
    if (Vue.component(identifier)) {
      return
    }

    const name = await this._createName(componentDocument)
    const packageInfos = await this.aeppic.Packages.loadAll(componentDocument.data.packages)

    for (const packageInfo of packageInfos || []) {
      let i = 0

      for (const styleRelativeUrl of packageInfo.styles || [])  {
        const styleUrl = this.aeppic.Packages.getUrlForPackageFile(packageInfo.document, styleRelativeUrl)

        loadStyleFromUrl(styleUrl, styleUrl, packageInfo.identifier, i++)
      }
    }

    const flattenedPackages = await this._importPackages(packageInfos)

    const builder = new DynamicComponentBuilder({
      type: this.type,
      name,
      identifier,
      packages: flattenedPackages,
      document: componentDocument,
      componentSourceTemplate: this.options.template,
      variablesToExpose: this.options.exposedSymbols,
    })

    const parts = builder.build()

    if (parts.css) {
      if (!this.aeppic.flags.disableStylesheets.enabled) {
        loadStyle(parts.css, componentDocument.id)
      }
    }

    compilerErrorLog.start()
    compilerWarnLog.start()

    try {
      const result = Vue.compile(parts.component.template, { outputSourceRange: true })
      parts.component.renderError = this.renderError
      parts.component.render = result.render
      parts.component.staticRenderFns = result.staticRenderFns
      delete parts.component.template

      if (compilerErrorLog.hasEntries || compilerWarnLog.hasEntries) {
        compilerErrorLog.print()
        compilerWarnLog.print()
        const errorText = `
${this.id ? this.id : result && result.render && result.render.toString().slice(0, 299) }${this.id ? ':' : '... (limited to 300 characters)'}
${compilerErrorLog.asText()}

${compilerWarnLog.asText()}`
        parts.component.render = (h) => this.renderError(h, errorText)
      }

      Vue.component(identifier, parts.component)
    } catch (error) {
      // tslint:disable-next-line    
      console.error(`Could not compile component ${identifier}`, error)
    } finally {
      compilerErrorLog.stop()
      compilerWarnLog.stop()
    }
  }
  
  private async _importPackages(packageInfos: PackageInfo[]) {
    const packages = {
      modules: {},
      components: {},
    }

    for (const info of packageInfos) {
      // NOTE: Currently we only suport the main export
      if (info.exports['.']) {
        let { exports, components } = await this.aeppic.Packages.importSourceFile(info, '.')
        
        if (info.exportsRoot) {
          exports = { [info.exportsRoot]: exports }
        } 

        packages.modules = { ...packages.modules, ...exports }
        packages.components = { ...packages.components, ...components }
      }
    }

    return packages
  }

  async _createName(componentDocument: Types.Document) {
    const ancestors = await this.aeppic.getAll(componentDocument.a || [])
    const path = ancestors.map(a => a.data.name).join('-')
    // const type = this.aeppic.getDocumentForm(componentDocument).data.name
    const name = componentDocument.data.name
    
    return `${name} (${path})`
  }

  startWatchingForChangesToDocumentsOfForm(formId) {
    this.stopWatchingForComponentDocumentsOfFormChanges()

    if (!this.options.watch) {
      return
    }

    this.componentDocumentsWatcher = this.aeppic.watchMatchingDocuments(this.aeppic.Query.global().form(formId), async (document, changes) => {
      if (!this.componentDocument) {
        return
      }

      const isCurrentlyUsedComponentDocument = (document.id === this.componentDocument.id)

      if (!isCurrentlyUsedComponentDocument) {
        return
      }

      if (changes.updated) {
        if (document.p === 'recycler') {
          this.reset()
          this.emit('invalidated')
        }

        this._refresh(document)
      } else if (changes.deletedHard) {
        this.reset()
        this.emit('invalidated')
      } 
    })
  }

  stopWatchingForComponentDocumentsOfFormChanges() {
    if (this.componentDocumentsWatcher) {
      this.componentDocumentsWatcher.stop()
      this.componentDocumentsWatcher = null
    }
  }

  startWatchingComponentDocumentRevisions() {
    this.stopWatchingComponentDocumentRevisions()
    
    if (!this.options.watch || !this.options.watchRevisions) {
      return
    }

    if (!this.aeppic.flags.watchUIRevisions.enabled) {
      return
    }

    if (this.componentDocument) {
      this.componentDocumentRevisionsWatcher = this.aeppic.watchRevisions(this.componentDocument.id, (document) => {
        this.scheduleAutoRefresh(document)
      })
    }
  }

  stopWatchingComponentDocumentRevisions() {
    if (this.componentDocumentRevisionsWatcher) {
      this.componentDocumentRevisionsWatcher.stop()
      this.componentDocumentRevisionsWatcher = null
    }
  }

  renderError(createElement, err) {
    const self = this
    const vnode = (<any>this).$vnode

    let componentId = ''
    if (vnode && vnode.tag) {
      const result = /--(.*)_/.exec(vnode.tag)

      if (result && result[1]) {
        componentId = result[1]
      }
    }

    return createElement('div', {
      staticClass: 'ae-error',
      style: {
          color: 'red'
      }
    }, [
      createElement('a', {
        domProps: {
          innerHTML: 'click to navigate to failing component',
        },
        on: {
          click: function () {
            (<any>self).Aeppic.navigateTo(componentId)
          }
        }
      }), createElement('pre', [
        `tag: ${vnode && vnode.tag}\n\n`,
        'error:\n',
        err.stack || err
        ]
      )
    ])
  }
}


// function compilePackages(packageInfos: PackageInfo[]) {

// }

// function flattenPackages(compiledPackages: Awaited<ReturnType<typeof compilePackages>>) {

// }

// type Awaited<T> = T extends PromiseLike<infer U> ? U : T
