import * as Types from '@aeppic/types'
import Aeppic from '../aeppic'

export enum PositionComparison {
  SAME = 'SAME',
  MISSING_IN_1 = 'MISSING_IN_1',
  MISSING_IN_2 = 'MISSING_IN_2',
  MOVED_HERE_IN_1 = 'MOVED_HERE_IN_1',
  MOVED_HERE_IN_2 = 'MOVED_HERE_IN_2',
  PARENT_MOVED = 'PARENT_MOVED'
}

export enum DataComparison {
  SAME = 'SAME',
  DIFFERENT = 'DIFFERENT'
}

export enum FormComparison {
  SAME = 'SAME',
  DIFFERENT = 'DIFFERENT',
  DIFFERENT_VERSION = 'DIFFERENT_VERSION'
}

export interface DifferenceNode {
  document1: any,
  document2: any,
  positionComparison: PositionComparison,
  dataComparison: DataComparison,
  formComparison: FormComparison,
  differenceInfo?: any,
  differentDescendantsCount: number,
  children: DifferenceNode[],
  $operation?: any,
  $resultDocument?: any
} 


export enum SourceNumber {
  ONE = 1,
  TWO = 2
}

export interface DocumentAdapter {
  getDocumentAsync(source: SourceNumber, id): any,
  getChildDocumentsAsync(source: SourceNumber, document, options?: any): any,
  compareDocuments(document1, document2): Promise<{ dataComparison: DataComparison, formComparison: FormComparison, differenceInfo: any}>
  documentsProcessed(count: number)
}

//
// old entry function
//
export async function compareDocumentTrees(rootDocument1: any, rootDocument2: any, adapter: DocumentAdapter, options?) {
  return await _compareDocumentSubTrees(rootDocument1, rootDocument2, null, adapter, options)
}

//
// new entry function 
//
export async function compareDocumentSubTrees(rootDocumentIds: string[], adapter: DocumentAdapter, options?) {
  
  const root1 = await adapter.getDocumentAsync(SourceNumber.ONE, 'root')
  const root2 = await adapter.getDocumentAsync(SourceNumber.TWO, 'root')

  if (!root1 || !root2) {
    throw 'for subtree comparison both trees must start at root'
  }

  return await _compareDocumentSubTrees(root1, root2, rootDocumentIds, adapter, options)
}

export async function _compareDocumentSubTrees(document1: any, document2: any, subTreeRootIds: string[], adapter: DocumentAdapter, optionsParam?) {
  const resultNode: DifferenceNode = {
    document1: document1,
    document2: document2,
    positionComparison: null,
    dataComparison: null,
    formComparison: null,
    differenceInfo: null,
    differentDescendantsCount: 0,
    children: [],
    $operation: null,
    $resultDocument: null,
  }
  
  if (!document1 && !document2) {
    throw 'nothing to compare'
  }

  const processThisNode = !subTreeRootIds || subTreeRootIds.includes((document1 || document2).id)

  if (processThisNode) {
    try {
      if (document1 && !document2) {
        await evaluateMissingDocument(resultNode, SourceNumber.TWO, document1, adapter)
        adapter.documentsProcessed(1)
      }
      else if (!document1 && document2) {
        await evaluateMissingDocument(resultNode, SourceNumber.ONE, document2, adapter)
        adapter.documentsProcessed(1)
      }
      else {
        resultNode.positionComparison = PositionComparison.SAME
        await compareDocuments(resultNode, document1, document2, adapter)
        adapter.documentsProcessed(2)
      }
    } catch (error) {
      console.error('Could not process node', error.toString(), document1, document2)
      return null
    }

    subTreeRootIds = null
  }

  if (processThisNode || await isAncestorOfSubTreeRootNode((document1 || document2).id, subTreeRootIds, adapter)) {
    const options = Object.assign({}, optionsParam)
    resultNode.children = await compareChildren(document1, document2, subTreeRootIds, adapter, options)
  } else {
    return null
  }

  resultNode.differentDescendantsCount = calculateDifferentDescendantsCount(resultNode.children)
  return resultNode
}

async function isAncestorOfSubTreeRootNode(documentId, subTreeRootIds, adapter) {
  if (!subTreeRootIds) {
    throw 'no sub tree root nodes specified'
  }

  if (documentId === 'root') {
    return true
  }

  for (const rootId of subTreeRootIds) {
    const rootDoc1 = await adapter.getDocumentAsync(SourceNumber.ONE, rootId)
    const rootDoc2 = await adapter.getDocumentAsync(SourceNumber.TWO, rootId)

    if (!rootDoc1 || !rootDoc2) {
      console.error('the ancestors of the root document ids must be contained in both trees')
      return false
    }

    if (rootDoc1.a.includes(documentId)) {
      return true
    }
  }

  return false
}

function calculateDifferentDescendantsCount(differenceNodes) {
  let result = 0

  for (const node of differenceNodes) {
    if (  (node.dataComparison && (node.dataComparison !== DataComparison.SAME)) 
       || (node.formComparison && (node.formComparison !== FormComparison.SAME))
       || (node.positionComparison && (node.positionComparison === PositionComparison.MISSING_IN_1)) 
       || (node.positionComparison && (node.positionComparison === PositionComparison.MISSING_IN_2)) 
       || (node.positionComparison && (node.positionComparison === PositionComparison.MOVED_HERE_IN_1))
       || (node.positionComparison && (node.positionComparison === PositionComparison.MOVED_HERE_IN_2))) {
      result ++
    }

    result += node.differentDescendantsCount
  }

  return result
}

async function evaluateMissingDocument(resultNode: DifferenceNode, missingInSource: SourceNumber, document: any, adapter: DocumentAdapter) {
  const movedDocument = await adapter.getDocumentAsync(missingInSource, document.id)

  if (movedDocument) {

    if (document.p === movedDocument.p) {
      resultNode.positionComparison = PositionComparison.PARENT_MOVED
    }
    else if (missingInSource === SourceNumber.ONE) {
      resultNode.positionComparison = PositionComparison.MOVED_HERE_IN_2
    }
    else if (missingInSource === SourceNumber.TWO) {
      resultNode.positionComparison = PositionComparison.MOVED_HERE_IN_1
    }
     
    // resultNode.positionComparison = (document.p !== movedDocument.p) ? PositionComparison.MOVED : PositionComparison.PARENT_MOVED
    
    resultNode[(missingInSource === SourceNumber.ONE ? 'document1' : 'document2')] = movedDocument
    await compareDocuments(resultNode, resultNode.document1, resultNode.document2, adapter)
  }
  else if (missingInSource === SourceNumber.ONE) {
    resultNode.positionComparison = PositionComparison.MISSING_IN_1
  }
  else if (missingInSource === SourceNumber.TWO) {
    resultNode.positionComparison = PositionComparison.MISSING_IN_2
  }
}

async function compareDocuments(resultNode: DifferenceNode, document1: Types.Document, document2: Types.Document, adapter: DocumentAdapter) {
  if (document1.id !== document2.id) {
    throw 'Comparing of documents with different ids not possible'
  }

  const result = await adapter.compareDocuments(document1, document2)

  resultNode.dataComparison = result.dataComparison
  resultNode.formComparison = result.formComparison
  resultNode.differenceInfo = result.differenceInfo
}

async function compareChildren(rootDocument1: Types.Document, rootDocument2: Types.Document, subTreeRootIds: string[], adapter: DocumentAdapter, options: any) {
  const childrenOfDocument1 = rootDocument1 ? await adapter.getChildDocumentsAsync(SourceNumber.ONE, rootDocument1, options.findOptions) : []
  const childrenOfDocument2 = rootDocument2 ? await adapter.getChildDocumentsAsync(SourceNumber.TWO, rootDocument2, options.findOptions) : []

  const pairsById = {}

  for (const child1 of childrenOfDocument1) {
    if (!pairsById[child1.id]) {
      pairsById[child1.id] = {}
    }
    
    pairsById[child1.id].child1 = child1
  }

  for (const child2 of childrenOfDocument2) {
    if (!pairsById[child2.id]) {
      pairsById[child2.id] = {}
    }
    
    pairsById[child2.id].child2 = child2
  }

  const resultNodes = []
  
  for (const id in pairsById) {
    const pair = pairsById[id]

    const resultNode: DifferenceNode = await _compareDocumentSubTrees(pair.child1, pair.child2, subTreeRootIds, adapter, options)
    if (resultNode) {
      resultNodes.push(resultNode)
    }
  }

  return resultNodes
}


export class DefaultDocumentAdapter implements DocumentAdapter {
    private _aeppics: Aeppic[] = []

    private _lastExecutionInterruption = 0

    private _totalDocuments = 0
    private _documentsProcessed = 0
    private _progress = 0
    private _cbProgress

    constructor(aeppic1: Aeppic, aeppic2: Aeppic, cbProgress) {
      this._aeppics[1] = aeppic1
      this._aeppics[2] = aeppic2
      this._cbProgress = cbProgress

      this._totalDocuments = aeppic1.Model.numberOfDocuments + aeppic2.Model.numberOfDocuments
    }
  
    async getDocumentAsync(source: SourceNumber, id: string) {
      const document = await this._aeppics[source].get(id)

      if (!document || (document.f.id === 'form' && document.a.length === 0)) {
        // missing doc or assume detached form version
        return null
      }
      else {
        return document
      }
    }
  
    async getChildDocumentsAsync(source: SourceNumber, parentDoc, findOptions?: any) {
      await this.interruptExecution(200, 0)

      const limit = 50
      const limitedResult = []

      for (const child of this._aeppics[source].Model.enumerateChildren(parentDoc)) {
        limitedResult.push(child)

        if (limitedResult.length > limit) {
          // too many children. return the original iterator
          return this._aeppics[source].Model.enumerateChildren(parentDoc)
        }
      }

      // if less than limit children are found, return them sorted
      return limitedResult.sort((a, b) => (a.data.name || 'zzzz').localeCompare(b.data.name || 'zzzz'))
  
    }
  
    async compareDocuments(document1: Types.Document, document2: Types.Document): Promise<{ dataComparison: DataComparison, formComparison: FormComparison, differenceInfo: any}> {
      const result = {
        dataComparison: DataComparison.SAME,
        formComparison: FormComparison.SAME,
        differenceInfo: {
          fields: [],
          newer: null
        }
      }

      const form1 = await this._aeppics[1].getFormForDocument(document1)
      const form2 = await this._aeppics[2].getFormForDocument(document2)

      if (form1.id !== form2.id) {
        result.formComparison = FormComparison.DIFFERENT
      }
      else if (form1.v !== form2.v) {
        result.formComparison = FormComparison.DIFFERENT_VERSION
      }
     
      for (const field of form1.fields) {
        if (form1.hasFieldChanged(field, document1.data[field.name], document2.data[field.name], {compareStrict: false})) {
          result.dataComparison = DataComparison.DIFFERENT
          result.differenceInfo.fields.push(field.name)
        }
      }

      const t1 = document1.t || new Date(document1.modified.at).getTime()
      const t2 = document2.t || new Date(document2.modified.at).getTime()

      if (t1 > t2) { 
        result.differenceInfo.newer = SourceNumber.ONE
      }
      else if (t1 < t2) { 
        result.differenceInfo.newer = SourceNumber.TWO
 }
     
      return result
    }

    documentsProcessed(count: number) {
      this._documentsProcessed += count

      const progress = Math.floor((this._totalDocuments === 0 ? 0 : this._documentsProcessed / this._totalDocuments) * 100)

      if (progress !== this._progress) {
        this._progress = progress
        if (this._cbProgress) {
          this._cbProgress(this._progress)
        }
      }
    }

    async interruptExecution(afterMilliseconds: number, forMilliseconds: number) {
      if (!this._lastExecutionInterruption) {
        this._lastExecutionInterruption = Date.now()
      }

      const runningSince = Date.now() - this._lastExecutionInterruption

      if (runningSince > afterMilliseconds) {
        await this.timeout(forMilliseconds)
        this._lastExecutionInterruption = Date.now()
      }
    }

    timeout(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms))
    }
  }

