export default class LookupList {
  private _lookups: string[] = []
  
  constructor() {}

  push(idToAdd: string, versionOfIt: string) {
    if (this._alreadyAdded(idToAdd, versionOfIt)) {
      return
    } else {
      this._lookups.push(idToAdd, versionOfIt)
    }
  }

  _alreadyAdded(idToAdd: string, versionOfIt: string) {
    for (let i = 0; i < this._lookups.length; i += 2) {
      const id = this._lookups[i]
      const v = this._lookups[i + 1]
      
      if (idToAdd === id) {
        if (versionOfIt === v) {
          return true
        } else if (!versionOfIt) {
          return true
        }
      }
    }

    return false
  }

  get entries() {
    return this._lookups
  }
}

