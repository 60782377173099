export function setIntervalNoKeepAlive(handler: TimerHandler, timeout?: number, ...args: any[]): any {
  const timer = setInterval(handler, timeout, ...args)
  unrefTimer(timer)
  return timer
}

export function setTimeoutNoKeepAlive(handler: TimerHandler, timeout?: number, ...args: any[]): any {
  const timer = setTimeout(handler, timeout, ...args)
  unrefTimer(timer)
  return timer
}

function unrefTimer(object: any) {
  if (typeof object === 'object') {
    object?.unref?.()
  }
}
