import * as Types from '@aeppic/types'

import { EventEmitter, IEventEmitter } from '@aeppic/shared/event-emitter'
import { waitForNextIdle } from '@aeppic/shared/wait-for-tick'

import type { FilterFunction } from './filter.js'

import { NotificationReason  } from './notification-reason.js'
export { NotificationReason }

export interface IQueryWatcher extends IEventEmitter {
  stop()
}

export interface QueryWatchCallback {
  (document: Types.Document, reason: NotificationReason)
}

export class QueryWatcher extends EventEmitter implements IQueryWatcher  {
  public active = true

  constructor(private _callback: QueryWatchCallback) {
    super()
    this.stop = this.stop.bind(this)
  }

  notify(document: Types.Document, reason: NotificationReason) {
    if (!this.active) {
      return false
    }

    try {
      this._callback(document, reason)
    } catch (e) {
      return false
    }

    this.emit('updated', document)

    return true
  }

  stop() {
    this.active = false
  }
}

export interface PendingNotification {
  document: Types.Document
  reason: NotificationReason
}

export class Notifier {
  private _allFilterFunctions = new Map<string, FilterFunction<Types.Document>>()
  private _allWatchers = new Map<string, QueryWatcher[]>()
  private _pendingNotifications: PendingNotification[] = []
  // private _matches

  constructor() {}

  add(filterFn: FilterFunction<Types.Document>, callback: QueryWatchCallback): IQueryWatcher {
    let watchers = this.getWatchers(filterFn)

    const newWatcher = new QueryWatcher(callback)
    watchers.push(newWatcher)

    return newWatcher
  }

  public cleanup() {
    for (const watcherList of this._allWatchers.values()) {
      const activeWatchers = watcherList.filter(w => w.active)
      watcherList.length = 0
      watcherList.push(...activeWatchers)  
    }
  }

  private getWatchers(filterFn: FilterFunction<Types.Document>) {
    const identifier = filterFn.identifier || filterFn.toString()

    if (!this._allFilterFunctions.has(identifier)) {
      this._allFilterFunctions.set(identifier, filterFn) 
    }

    const watchers = this._allWatchers.get(identifier)

    if (watchers) {
      return watchers
    } else {
      let newWatchers = []
      this._allWatchers.set(identifier, newWatchers)
      return newWatchers
    }
  }

  notifyWatchers(document: Types.Document, reason: NotificationReason = NotificationReason.Updated) {
    if (this._allWatchers.size === 0 || this._allFilterFunctions.size === 0) {
      return
    }

    this._pendingNotifications.push({ document, reason })

    return this.flushPendingNotifications()
  }

  public flushPendingNotifications() {
    return waitForNextIdle().then(() => this._publishPendingNotifications())
  }

  private _publishPendingNotifications() {
    const notifications = this._pendingNotifications
    this._pendingNotifications = []

    if (notifications.length === 0) {
      return
    }

    for (const [identifier, filterFn] of this._allFilterFunctions.entries()) {
      for (const n of notifications) {
        const watchers = this._allWatchers.get(identifier)

        if (filterFn(n.document)) {
          this._notifyWatchers(watchers, n)
        }
      }
    }
  }

  /**
   * Note: this function will remove inactive watchers from the passed in watchers array
   * 
   * @param watchers 
   * @param options - { document, reason } 
   */
  private _notifyWatchers(watchers: QueryWatcher[], { document, reason }: PendingNotification) {
    let nonActiveWatchersDetected = false

    for (const w of watchers) {
      if (w.active) {
        w.notify(document, reason)
      } else {
        nonActiveWatchersDetected = true
      }
    }

    if (nonActiveWatchersDetected) {
      let activeWatchers = []

      for (const w of watchers) {
        if (w.active) {
          activeWatchers.push(w)
        }
      }

      watchers.length = 0
      watchers.push(...activeWatchers)
    }
  }
} 
