import * as Types from '@aeppic/types'
import { EditableDocument } from './editable-document'
import type { Change } from './change-tracker.js'

export function isNamedDocument(document: Types.Document): document is Types.DocumentWithName {
  return ('name' in document.data && typeof document.data.name === 'string')
}

/* c8 ignore start */

export function isForm(document: Types.Document|EditableDocument) {
  return document.f.id === 'form'
}

export function isReference(identifier: Types.IdOrReference | Types.Document): identifier is Types.Reference {
  if (typeof identifier === 'string') {
    return false
  } else {
    return 'id' in identifier && 'v' in identifier && !!identifier.id && !!identifier.v
  }
}

export function isStrictReference(identifier: Types.IdOrReference | Types.Document): identifier is Types.Reference {
  if (typeof identifier === 'string') {
    return false
  } else if (isDocument(identifier)) {
    return false
  } else {
    return 'id' in identifier && 'v' in identifier && !!identifier.id && !!identifier.v
  }
}

export function hasId(identifier: Types.IdOrReference | Types.Document): identifier is Types.Reference|Types.Document {
  if (typeof identifier === 'string') {
    return false
  } else {
    return (!!identifier && !!identifier.id)
  }
}

export function isReferenceField(identifier: Types.IdOrReference | Types.Document): identifier is Types.Reference {
  return !isDocument(identifier) && isReference(identifier)
}

export function isDocument(identifier: Types.IdOrReference | Types.Document|Change): identifier is Types.Document {
  return (<Types.Document>identifier).id && (<Types.Document>identifier).data != null
}

export function isStamp(identifier: Types.Stamp | Types.Document): identifier is Types.Stamp {
  if (typeof identifier === 'string') {
    return false
  }

  return 'type' in identifier && 'added' in identifier && 'id' in identifier
}
