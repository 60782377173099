import * as Types from '@aeppic/types'

import { EventEmitter } from  '@aeppic/shared/event-emitter'
import { waitForNextTick } from  '@aeppic/shared/wait-for-tick'

import { IServerAdapter } from  '../../shared/types/server-adapter.js'

export interface IUploadJobRequest {
  document: Types.Document
  fieldName: string
  dataUrl: string
  content?: any
}

export interface IUploadJob extends IUploadJobRequest {
  id?: number
  status?: 'pending'|'starting'|'started'|'failed'|'finished'
  progressPercent?: number
}

export interface Options {
  concurrentUploadLimitPerChannel?: number
}

const DEFAULT_OPTIONS: Options = {
  concurrentUploadLimitPerChannel: 3
}

export class Uploads extends EventEmitter {
  private _options: Options

  private _uploadCounter = 0
  private _pendingUploads: IUploadJob[] = []
  private _activeUploads = new Map<string, IUploadJob>()
  private _recentUploads: WeakSet<IUploadJob> = new WeakSet()
  private _activeUploadsCounter = 0

  constructor(private _persistenceAdapter: IServerAdapter, specifiedOptions: Options = {}) {
    super()

    if (!this._persistenceAdapter) {
      return
    }
    
    this._options = { ...DEFAULT_OPTIONS, ...specifiedOptions }

    this._persistenceAdapter.on('uploaded', (error, dataUrl) => {
      this._handleUploadResult(error, dataUrl)
    })

    this._persistenceAdapter.on('upload:progress', (dataUrl, percent) => {
      this._handleUploadProgress(dataUrl, percent)
    })
  }

  get isUploading() {
    return this._activeUploadsCounter > -1 && this._activeUploads.size > 0
  }

  getAll() {
    return Array.from(this._enumerateAllUploads())
  }

  getUploadStatusForField(document: Types.Document|string, fieldName: string ) {
    let latestUpload: IUploadJob

    for (const upload of this._enumerateUploadsForSpecificDocument(document)) {
      if (upload.fieldName === fieldName) {
        if (!latestUpload || latestUpload.id < upload.id) {
          latestUpload = upload
        }
      }
    }

    return latestUpload
  }

  getUploadStatusForDocument(document: Types.Document|string) {
    const uploads = Array.from(this._enumerateUploadsAssociatedWithDocument(document))

    if (uploads.length > 0) {
      return uploads
    } else {
      return null
    }
  }

  private *_enumerateUploadsForSpecificDocument(document: Types.Document|string) {
    const documentId = typeof document === 'string' ? document : document.id
    
    for (const upload of this._enumerateAllUploads()) {
      if (upload.document.id === documentId) {
        yield upload
      }
    }    
  }

  isUploadAssociatedWithDocument(ancestorDocument: Types.Document|string) {
    for (const upload of this._enumerateUploadsAssociatedWithDocument(ancestorDocument)) {
      return true
    }

    return false
  }

  private *_enumerateUploadsAssociatedWithDocument(ancestorDocument: Types.Document|string) {
    const ancestorDocumentId = typeof ancestorDocument === 'string' ? ancestorDocument : ancestorDocument.id

    for (const upload of this._enumerateAllUploads()) {
      if (ancestorDocumentId === 'root') {
        yield upload
      }

      if (upload.document.id === ancestorDocumentId) {
        yield upload
      }
     
      if (upload.document.p === ancestorDocumentId) {
        yield upload
      }
      
      if (upload.document.a && Array.isArray(upload.document.a)) {
        for (const a of upload.document.a) {
          if (a === ancestorDocumentId) {
            yield upload
          }
        }
      }
    } 
  }

  isDocumentUploading(document: Types.Document, fieldName?: string) {
    for (const upload of this._enumerateUploadsForSpecificDocument(document)) {
      if (fieldName) {
        if (upload.fieldName === fieldName) {
          return true
        }
      } else {
        return true
      }
    }

    return false
  }

  private *_enumerateAllUploads() {
    yield* this._activeUploads.values()
    yield* this._pendingUploads
  }

  public add(request: IUploadJobRequest) {
    if (!this._persistenceAdapter) {
      console.warn('Cannot uploads since PersistenceAdapter is not configured')
      return
    }

    const uploadJob: IUploadJob = request
    
    const id = ++this._uploadCounter
    uploadJob.id = id
    uploadJob.status = 'pending'
    uploadJob.progressPercent = 0

    this._pendingUploads.push(uploadJob)
    this._processUploads()
  }

  private _processUploads() {
    if (this._pendingUploads.length === 0) {
      return
    }

    while (this._activeUploads.size < this._options.concurrentUploadLimitPerChannel && this._pendingUploads.length > 0) {
      const uploadJob = this._pendingUploads.shift()
      this._persistenceAdapter.upload(uploadJob.dataUrl, uploadJob.content)
      uploadJob.status = 'starting'
      uploadJob.progressPercent = 0
      
      this._activeUploads.set(uploadJob.dataUrl, uploadJob)
      this._activeUploadsCounter += 1
    }
  }

  private _handleUploadResult(error: Error, dataUrl: string) {
    // console.log(`Upload result for ${dataUrl}`, error)
    
    const uploadJob = this._activeUploads.get(dataUrl) 

    // console.log(`Upload job for ${dataUrl}`, uploadJob)

    this._activeUploads.delete(dataUrl)
    this._activeUploadsCounter -= 1

    if (uploadJob) {
      if (error) {
        uploadJob.status = 'failed'
        // TODO: log errors (retry)

        this.add(uploadJob)
      } else {
        uploadJob.status = 'finished'
        uploadJob.progressPercent = 100

        this._recentUploads.add(uploadJob)

        this.emit('uploaded', uploadJob.document)
      }
    }
    
    waitForNextTick().then(() => {
      this._processUploads()
    })
  }

  private _handleUploadProgress(dataUrl: string, percent?: number) {
    // console.log(`Upload progress result for ${dataUrl} ${percent}`)
    
    const uploadJob = this._activeUploads.get(dataUrl) 

    // console.log(`Upload job for ${dataUrl}`, uploadJob)

    if (uploadJob) {
      uploadJob.status = 'started'

      if (percent != null) {
        uploadJob.progressPercent = Math.min(1, Math.max(0, percent))
      }
    }
  }
}
