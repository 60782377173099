
import * as Types from '@aeppic/types'
import uuid from '@aeppic/shared/uuid'
import { ParsedPlaceholder, ParsedTags } from '@aeppic/forms-parser'

import { Form, IFormSection, IFormSectionParagraph, FormConditionResults, IFormParagraphItem, IFormFieldAtPlaceholder, IFormParagraphTextItem, IFormStandaloneControl, StyleParameters } from '../../model/form.js'

export type FormViewOptions = {
  /**
   * Filter options to remove elements fully from the rendering
   */
  only?: {
    /**
     * Names of fields to show
     */
    fields?: string[],

  },
  /**
   * Start rendering at a specific section index
   */
  startWithSection?: number,
  show?: {
    /**
     * Set to `true` to show undefined sections (sections with only undefined fields all the way down)
     */
    undefinedSections?: boolean,
    
    /**
     * Set to `true` to show undefined fields (fields with undefined value)
     */
    undefinedFields?: boolean,

    /**
     * Set to `true` to set isVisible on an item to true even if `hidden` status is calculated to be false for a field
     */
    hiddenFields?: boolean
    
    /**
     * Set to `true` to set isVisible on an item to true even if `hidden` status is calculated to be false for a section
     */
    hiddenSections?: boolean
  }
}

export class FormView {
  private _form: Form
  private _document: Types.Document
  
  private _rootSection: FormSectionView

  constructor(document: Types.Document, form: Form, options: FormViewOptions = {}) {
    this._form = form
    this._document = document

    if (options.startWithSection) {
      const section = this.getSection(this._form.rootSection, options.startWithSection)

      if (!section) {
        throw new Error(`Section with index ${options.startWithSection} not found`)
      }
      this._rootSection = new FormSectionView(section, options)
    } else {
      this._rootSection = new FormSectionView(this._form.rootSection, options)
    }
  }

  public refresh(conditionResults: FormConditionResults) {
    this._rootSection.setConditionResults(conditionResults, this._form, this._document)
  }
  
  public get rootSection() {
    return this._rootSection
  }

  public getField(name: string) {
  }

  private getSection(section: IFormSection, index: number) {
    if (section.index === index) {
      return section
    }

    return section.subSections.find(section => this.getSection(section, index))
  }
}

interface IConditionallyStyledFormItemView {
  readonly style: any
  readonly isVisible: boolean
  readonly isReadonly: boolean
  /**
   * Unique id for this item useable as key for v-for
   */
  readonly key: string|number
  setConditionResults(conditionResults: FormConditionResults, form: Form, document: Types.Document): void
}

class AbstractConditionallyStyledFormItemView implements IConditionallyStyledFormItemView {
  private _isHidden = false
  private _isReadonly = false
  private _key: string = null
  
  constructor(
    private _placeholder: ParsedPlaceholder,
    protected _viewOptions: FormViewOptions
    ) {}

  public get key(): string|number {
    if (this._key === null) {
      this._key = uuid()
    }
    return this._key
  }

  public get placeholder() { return this._placeholder }
  public get style() { return this._placeholder.style ?? {} }
  
  public setConditionResults(conditionResults: FormConditionResults, form: Form, document: Types.Document) {
    let isHidden = shouldApplyStyle(this.style, 'hidden', conditionResults)  
    
    if (isHidden !== this._isHidden) {
      this._isHidden = isHidden
    }

    let isReadonly = shouldApplyStyle(this.style, 'readonly', conditionResults)  

    if (isReadonly !== this._isReadonly) {
      this._isReadonly = isReadonly
    }
  }

  public get isReadonly() {
    return this._isReadonly
  }
  
  public get isVisible() {
    if (this._viewOptions?.show?.hiddenFields === true) {
      return true
    }

    return !this._isHidden
  }

  // protected get viewOptions() {
  //   return this._viewOptions
  // }
}

class FormSectionView extends AbstractConditionallyStyledFormItemView implements IFormSection {
  private _paragraphs: FormSectionParagraphView[]
  private _subSections: FormSectionView[]
  private _unfilteredSection: IFormSection
  private _isOptional: boolean
  private _isUndefined: boolean

  constructor(section: IFormSection, viewOptions: FormViewOptions) {
    super(section.style, viewOptions)

    this._unfilteredSection = section
    this._paragraphs = section.paragraphs.map(p => new FormSectionParagraphView(p, viewOptions))
    this._subSections = section.subSections.map(s => new FormSectionView(s, viewOptions))
  }

  public get key() {
    return this._unfilteredSection.index
  }
  
  public get index(): number {
    return this._unfilteredSection.index
  }

  public get title(): string {
    return this._unfilteredSection.title
  }

  public get style(): string {
    return this._unfilteredSection.style
  }

  public get tags(): ParsedTags {
    return this._unfilteredSection.tags
  }

  public get type() {
    return this._unfilteredSection.type
  }

  public get level(): number {
    return this._unfilteredSection.level
  }

  public get offset(): number {
    return this._unfilteredSection.offset
  }

  public get paragraphs() {
    return this._paragraphs
  }

  public get subSections() {
    return this._subSections
  }

  public get isOptional() {
    return this._isOptional
  }

  public get isUndefined() {
    return this._isUndefined
  }

  public get isVisible() {
    const showUndefined = this._viewOptions?.show?.undefinedSections ?? false

    if (!showUndefined) {
      if (this.isUndefined) {
        return false
      }
    }

    return super.isVisible
  }

  public setConditionResults(conditionResults: FormConditionResults, form: Form, document: Types.Document) {
    this._isUndefined = form.isUndefinedSection(document, this._unfilteredSection.index)
    this._isOptional = form.isTaggedSection(document, ['optional'], this._unfilteredSection.index)

    super.setConditionResults(conditionResults, form, document)

    for (const p of this._paragraphs) {
      p.setConditionResults(conditionResults, form, document)
    }

    for (const section of this._subSections) {
      section.setConditionResults(conditionResults, form, document)
    }
  }
}

type FormParagraphViewItem = ReturnType<typeof buildViewItem>

class FormSectionParagraphView implements IFormSectionParagraph, IConditionallyStyledFormItemView {
  private _unfilteredParagraph: IFormSectionParagraph
  private _items: FormParagraphViewItem[]
  private _isVisible: boolean
  private _isReadonly: boolean
  
  constructor(paragraph: IFormSectionParagraph, viewOptions: FormViewOptions) {
    this._unfilteredParagraph = paragraph
    
    const itemsInView = filterItems(paragraph.items, viewOptions).map(i => buildViewItem(i, viewOptions))
    this._items = itemsInView.filter(i => !!i)
    
    this._updateVisibility()
  }
  
  public get key() { return this.offset }
  public get offset() { return this._unfilteredParagraph.offset }
  public get type() { return this._unfilteredParagraph.type }
  public get items() { return this._items }
  public get style() { return {} }

  public setConditionResults(conditionResults: FormConditionResults, form: Form, document: Types.Document) {
    for (const item of this._items) {
      if ('setConditionResults' in item) {
        item.setConditionResults(conditionResults, form, document)
      }
    }

    this._updateVisibility()
    this._updateReadonly()
  }

  public get isVisible() { return this._isVisible }

  private _updateVisibility() {
    const isVisible = this._items.some(item => item.isVisible === true)

    if (isVisible !== this._isVisible) {
      this._isVisible = this._items.some(item => item.isVisible === true)
    }
  }

  public get isReadonly() { return this._isReadonly }

  private _updateReadonly() {
    const isReadonly = this._items.every(item => item.isReadonly === true)

    if (isReadonly !== this._isReadonly) {
      this._isReadonly = this._items.every(item => item.isReadonly === true)
    }
  }
}

function filterItems(items: IFormParagraphItem[], view: FormViewOptions) {
  return items.filter(e => isElementIncludedInView(e, view))
}

function isElementIncludedInView(item: IFormParagraphItem, view: FormViewOptions) {
  if (view) {
    if (item.type === 'FIELD') {
      if (Array.isArray(view.only?.fields)) {
        const isExplicitelyIncluded = view.only.fields.includes(item.name)
        return isExplicitelyIncluded
      }
    }
  }

  return true
}

function buildViewItem(item: IFormParagraphItem, viewOptions: FormViewOptions) {
  if (item.type === 'FIELD') {
    return new FormFieldAtPlaceholderView(item, viewOptions)
  } else if (item.type == 'TEXT') {
    return new FormParagraphTextItemView(item, viewOptions)
  } else if (item.type == 'STANDALONE_CONTROL') {
    return new FormStandaloneControlView(item, viewOptions)
  } else {
    console.warn('unknown view item', item)
  }
}
// export const EMPTY_VIEW: View = {}

class FormFieldAtPlaceholderView extends AbstractConditionallyStyledFormItemView implements IFormFieldAtPlaceholder {
  public readonly type = 'FIELD'
  private _isUndefined = false
  private _isOptional = false
  
  constructor(
    private _field: IFormFieldAtPlaceholder,
    viewOptions: FormViewOptions
    )
  {
    super(_field.placeholder, viewOptions)
  }
   
  public get name() { return this._field.name }
  public get label() { return this._field.label }
  public get controlName() { return this._field.controlName }
  public get controlParams() { return this._field.controlParams }
  public get offset() { return this._field.offset }
  public get isUndefined() { return this._isUndefined }
  public get isOptional() { return this._isOptional }
  
  public get isVisible() {
    const showUndefined = this._viewOptions?.show?.undefinedFields ?? false

    if (!showUndefined) {
      if (this.isUndefined) {
        return false
      }
    }

    return super.isVisible
  }

  public setConditionResults(conditionResults: FormConditionResults, form: Form, document: Types.Document) {
    this._isUndefined = form.isUndefinedField(document, this.name)
    this._isOptional = form.isTaggedField(this.name, ['optional'])

    super.setConditionResults(conditionResults, form, document)
  }
}

class FormParagraphTextItemView implements IFormParagraphTextItem {
  constructor(
    private _text: IFormParagraphTextItem,
    private _viewOptions: FormViewOptions)
    {}

  public get type() { return this._text.type }
  public get html() { return this._text.html }
  public get markdown() { return this._text.markdown }
  public get isVisible() { return true }
  public get isReadonly() { return true }
}

class FormStandaloneControlView extends AbstractConditionallyStyledFormItemView implements IFormStandaloneControl {
  constructor(
    private _standaloneControl: IFormStandaloneControl,
    viewOptions: FormViewOptions
    )
  {
    super(_standaloneControl.placeholder, viewOptions)  
  }

  public get name() { return this._standaloneControl.name }
  public get type() { return this._standaloneControl.type }
  public get parameters() { return this._standaloneControl.parameters }
}

// function parseFromDocument(document: Types.Document, layoutFieldName: string, classesFieldName: string): ItemTheme {
//   if (!document) {
//     return {
//       classes: [],
//       layoutId: null
//     }
//   }

//   const layoutReferenceField = document.data[layoutFieldName] as Types.Reference
//   const classesField = document.data[classesFieldName] as string ?? ''

//   return {
//     classes: classesField.split(' '),
//     layoutId: layoutReferenceField?.id,
//   }
// }

function shouldApplyStyle(style: StyleParameters, styleName: string, evaluatedConditions: FormConditionResults): boolean {
  const hasTheStyleDefined = Boolean(style?.[styleName])
  
  const isConditionalStyle = hasTheStyleDefined && Boolean(style[styleName].condition)
  const isConditionMet = !isConditionalStyle || !evaluatedConditions || evaluatedConditions[style[styleName].condition.id]

  return hasTheStyleDefined && isConditionMet
}
