import { runDynamicCode } from '@aeppic/shared/dynamic/dynamic-code'
import { enumerateClassMethodsAndProperties } from '@aeppic/shared/class-iterator'

const RENAMED_CONSTRUCTOR = '__constructor__'

const DEFAULT_HIDDEN_GLOBALS = {
  window: undefined,
  document: undefined,
  // setTimeout: undefined,
  // setInterval: undefined,
  chrome: undefined,
}


const THIS_GUARD_INSTRUCTION = `/* Aeppic.verify('controller-this', this) */`
const AWAIT_DETECTOR = /\bawait\b/

export default function createControllerClass(codeGroup, name, controllerClassCode, variablesToExpose, globals) {
  const constructorExpression = /\s*constructor\s*\(/
  const classCodeWithEscapedConstructor = controllerClassCode.replace(constructorExpression, RENAMED_CONSTRUCTOR + '(' )

  const classCode = classCodeWithEscapedConstructor.trim()
  
  // const stripComments = /(?:\/\/(?:\\\n|[^\n])*\n)|(?:\/\*[\s\S]*?\*\/)|((?:R"([^(\\\s]{0,16})\([^)]*\)\2")|(?:@"[^"]*?")|(?:"(?:\?\?'|\\\\|\\"|\\\n|[^"])*?")|(?:'(?:\\\\|\\'|\\\n|[^'])*?'))/g
  let code
  
  if (classCode[0] === 'c' && classCode.indexOf('class') === 0) {
    code = `${classCode}`
  } else {
    code = `class ControllerDefinition {
        ${classCode}
      }
    `
  }

  code = `return ${code}` 

  const controllerDefinitionClass = runDynamicCode(`${codeGroup}/definitions`, name, code, { ...globals, ...DEFAULT_HIDDEN_GLOBALS })
  
  if (!controllerDefinitionClass) {
    return null
  }
  
  const rewrittenClassCode = createControllerClassFromDefinition(controllerDefinitionClass, variablesToExpose)

  let controllerClass = null

  if (rewrittenClassCode) {
    controllerClass = runDynamicCode(codeGroup, name, rewrittenClassCode, { ...globals, ...DEFAULT_HIDDEN_GLOBALS })
  }

  return controllerClass
}

function createControllerClassFromDefinition(DefinitionClass, variablesToExpose) {
  if (!DefinitionClass) {
    return null
  }

  const setLocalsInstruction = `const { ${variablesToExpose } } = this.__variables\n`

  const methodsAndProperties = []
  let constructorCode = ''

  for (const { name, descriptor } of enumerateClassMethodsAndProperties(DefinitionClass)) {
    if (name === RENAMED_CONSTRUCTOR) {
      constructorCode = rewriteConstructor(descriptor.value)
    } else if (descriptor.value) {
      methodsAndProperties.push( rewriteMethod(name, descriptor.value) )
    } else if (descriptor.get || descriptor.set) {
      if (descriptor.get) {
        methodsAndProperties.push( rewriteGetter(name, descriptor.get) )
      }
      if (descriptor.set) {
        methodsAndProperties.push( rewriteSetter(name, descriptor.set) )
      }
    }
  }

  if (constructorCode.match(AWAIT_DETECTOR)) {
    // tslint:disable-next-line    
    console.warn('You might have used the await keyword in a constructor. `await` cannot be used directly in a constructor. If you want to perform asynchronous work here you can call an `async` function from within the constructor which can use await')
  }

  const code = `  
return class Controller {
  constructor(variables) {
    this.__variables = variables

    ${setLocalsInstruction}

    try {
      ${constructorCode}
    } catch(error) {
      console.error('Error running Controller constructor() code', error)
    }

    if (this.init && typeof this.init === 'function') {
      try {
        this.init()
      } catch(error) {
        console.error('Error running Controller init() code', error)
      }
    }
  }

  ${methodsAndProperties.join('\n  ')}
}
`

  return code

  function rewriteConstructor(fn) {
    return getFunctionBody(fn.toString())
  }

  function rewriteMethod(name, fn) {
    const isAsync = fn.constructor.name === 'AsyncFunction'
    const methodAsString = fn.toString()

    const args = getFunctionArguments(methodAsString)
    const body = getFunctionBody(methodAsString)


    const saferBody = safeBody(body)


    return `${isAsync ? 'async ' : ''}${name} (${args}) {\n    ${THIS_GUARD_INSTRUCTION}\n    ${setLocalsInstruction}${saferBody}\n  }`
  }

  function safeBody(body) {
    const logAccessOfIdOnUndefined = `if (error?.name === 'TypeError' && error?.message?.includes('read properties of undefined') && error?.message?.includes("'id'")) { console.warn('Attempting to access "id" on undefined variable. Make sure code uses "?." syntax. E.g in formulas {hidden if optionalField?.id }', error.stack); return; }`
    const logError = `console.error('Error in controller method/getter/setter', error, error.stack)`
    return `try {\n    ${body}\n    } catch (error) {\n    ${logAccessOfIdOnUndefined}\n    ${logError}\n}`
  }

  function rewriteGetter(name, fn) {
    const isAsync = fn.constructor.name === 'AsyncFunction'
    const methodAsString = fn.toString()
    const body = getFunctionBody(methodAsString)

    const saferBody = safeBody(body)

    return `get ${name}() {\n    ${THIS_GUARD_INSTRUCTION}\n    ${setLocalsInstruction}\n    ${saferBody}\n  }`
  }

  function rewriteSetter(name, fn) {
    const methodAsString = fn.toString()

    const args = getFunctionArguments(methodAsString)
    const body = getFunctionBody(methodAsString)

    return `set ${name}(${args}) {\n    ${THIS_GUARD_INSTRUCTION}\n    ${setLocalsInstruction}${body}\n  }\n`
  }

  function getFunctionBody(functionAsString) {
    let body = functionAsString.slice(functionAsString.indexOf('{') + 1, functionAsString.lastIndexOf('}'))
    
    const reindentedBody = body.replace(/\n  /g, '\n    ')

    return reindentedBody
  }

  function getFunctionArguments(functionAsString) {
    // First match everything inside the function argument parens.
    return functionAsString.match(/.*?\(([^)]*)\)/)[1]
  }

}
