import { QuerySubscription } from '../aeppic/query'
import { DocumentWatcher } from '../model/document-watch'

export default class ReactiveObjectProxy {
  static build(object, settings) {
    if (typeof Proxy !== 'undefined') {
      return buildProxy(object, settings)
    } else {
      // tslint:disable-next-line    
      console.error('Proxy not available in environment')
      return object
    }
  }
}

function buildProxy(object, settings) {
  return new Proxy(object, new ReactiveProxyHandler(settings))  
}

class ReactiveProxyHandler implements ProxyHandler<any> {
  constructor(private settings) {
  }

  get(target, propertyKey: string) {
    if (target.hasOwnProperty(propertyKey)) {
      return target[propertyKey]
    } else {
      return null
    }
  }
  
  set(target, propertyKey: string, value: any): boolean {
    if (value && value.then) {
      value.then((resolvedValue) => {
        this._set(target, propertyKey, resolvedValue)
      }, (error) => {
        if (this.settings.onPromiseError) {
          this.settings.onPromiseError(target, propertyKey, error)
        } else {
          console.error('Error resolving promise for key', propertyKey, target, error)
          this._set(target, propertyKey, null)
        }
      })
    } else if (value && value.constructor === DocumentWatcher) {
      this.settings.onDocumentWatcher(target, propertyKey, value)
    } else if (value && value.constructor === QuerySubscription) {
      this._set(target, propertyKey, value.documents)
    } else {
      this._set(target, propertyKey, value)
    }

    return true
  }

  _set(target, propertyKey: string, value: any) {
    if (!target.hasOwnProperty(propertyKey)) {
      this.settings.onNewProperty(target, propertyKey, value)
    } else {
      target[propertyKey] = value
    }
  }
}

