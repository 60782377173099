// import loopProtect from './loop-protect'
import md5 from '../md5.js'
import now from '../now.js'

type FunctionBuildInfo = ReturnType<typeof buildInfo>

export class CompilationError extends Error {
  constructor(message: string, public info: FunctionBuildInfo) {
    super(message)
  }
}

export function runDynamicCode(codePath: string, name: string, code: string, globals = {}): any {
  const globalNames = Object.getOwnPropertyNames(globals)
  const info = buildInfo(codePath, name, md5.hash(code))
  const fullDefinition = `// INFO: ${info.prefix}\n\n${code}\n//# sourceURL=${info.displayName}`

  let fn: any

  try {
    fn = new Function(...globalNames, fullDefinition)
    fn.aeInfo = info
    fn.displayName = info.displayName
  } catch (compileError) {
    // tslint:disable-next-line no-console
    console.error('Error preparing dynamic code', compileError, fullDefinition)
    throw new CompilationError('Failed compilation', info)
  }

  try {
    const globalValues = globalNames.map(name => globals[name])
    return fn(...globalValues)
  } catch (compileError) {
    // tslint:disable-next-line no-console
    console.error('Error running dynamic code', compileError, fullDefinition)
    throw new CompilationError('Failed compilation', info)
  }
}

function buildInfo(path: string, name: string, hash: string) {
  const escapedPath = path
    .split('/')
    .map(encodeURI)
    .join('/')
    
  const escapedName = encodeURI(name)
  const compiledAt = now().isoTz

  const info = {
    name,
    displayName: `dynamic://aeppic/${escapedPath}/${escapedName}`, // + encodeURI(` (Compiled:${compiledAt}, hash:${hash})`),
    hash,
    compiledAt,
    prefix: `${name} (${path}) Compiled:${compiledAt} Hash:${hash}`
  }

  return info
}
