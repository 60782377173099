export default {
  render: function (createElement: any) {
    if (this.level > 0 && this.level <= 6) {
      return createElement(
        'h' + this.level,   // tag name
        this.$slots.default // array of children
      )
    } else {
      // Do not render root title (always empty anyway) or deeply nested headers (they are only for documentation and segmentation)
      //
      // return createElement(
      //   'div',
      //   this.$slots.default // array of children
      // )  
    }
  },
  props: {
    level: {
      type: Number,
      required: true
    }
  }
}