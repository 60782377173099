import * as Types from '@aeppic/types'
import { buildDeferred, Deferred } from '@aeppic/shared/defer'

import { Query, QueryBuilder, Scopes } from '../../model/query/index.js'
import type Aeppic from 'aeppic/aeppic.js'
import { DesignLookup, DesignMatchStrategy } from 'aeppic/lookup/index.js'
import { isDocument } from 'model/is.js'
import { DocumentLookupCache } from 'aeppic/components/cache.js'
import { OnceCache } from '@aeppic/shared/once'

export function findFirstDocumentMatchingName(namedDocument: Types.Document[], names: string[]) {
  const designsByName = new Map(namedDocument.map(d => [d.data.name, d]))

  for (const name of names) {
    const bestMatch = designsByName.get(name)

    if (bestMatch) { 
      return bestMatch
    }
  }

  return null
}

export class Designs {
  private _designLookupCache: OnceCache<Types.Document, { formId: string, names: string }>
  private _useFullStrategy: boolean = false

  constructor(private _aeppic: Aeppic) {
    const lookupOptions = this._aeppic.Features.getOptionsFor('design-lookup')
    
    this._useFullStrategy = (lookupOptions?.strategy === 'full')

    if (this._useFullStrategy) {
      this._designLookupCache = new OnceCache('design-lookup', async ({ formId, names }) => {
        const controlLookupInfo = await this._aeppic.fetch(`/api/forms/${formId}/design?names=${encodeURIComponent(names)}`)

        if (!controlLookupInfo.ok) { 
          this._aeppic.Warn.once('design-lookup:fetch', `${formId}-${names}`, `Failed to fetch design for ${formId} with names ${names}`)
          throw new Error(`Failed to fetch design for ${formId} with names ${names}`)
        }

        const result = await controlLookupInfo.json() as { designId: string }
        const designId = result?.designId 

        // const classicDesign = await this._getDesignWithClassicLookup(formId, names)

        // if (!classicDesign && !designId) {
        //   return null
        // }

        // if (classicDesign && !designId) {
        //   this._aeppic.Warn.once('design-lookup:missing:compared-to-classic', `${formId}-${names}`, `Failed to find design for ${formId} with names ${names}. Classic did find design ${classicDesign.id} (${classicDesign.data.name})`)
        //   return classicDesign
        // }

        // if (!designDocumentId) {
        //   return null
        // }

        const design = await this._aeppic.get(designId)

        if (!design) {
          this._aeppic.Warn.once('design-lookup:missing:could-not-get', `${formId}-${names}`, `Failed to find design for ${formId} with names ${names}`)
          // return classicDesign
        }

        // if (classicDesign.id != design.id) {
        //   this._aeppic.Warn.once('design-lookup:missing:mismatch', `${formId}-${names}`, `Classic found design ${classicDesign.id} (${classicDesign.data.name}) does not match fetched design ${design.id} (${design.data.name}). Using form: ${formId} with names ${names}`)
        //   return classicDesign
        // }

        return design

      })
    } else {
      this._designLookupCache = new OnceCache('design-document', async ({ formId, names }) => {
        return this._getDesignWithClassicLookup(formId, names)
      })
    }
  }

  async _getDesignWithClassicLookup(formId: string, names: string) {
    const trimmedNames = names.split(',').filter(n => !!n).map(n => n.trim())
      
    const bestMatchUnderForm = await this._findBestMatchAtLocation(formId, trimmedNames)

    if (bestMatchUnderForm) {
      return bestMatchUnderForm
    }

    const bestMatchUnderFallbacks = await this._findBestMatchAtLocation('fallback-designs', trimmedNames)
    return bestMatchUnderFallbacks
  }

  clearCache() {
    this._designLookupCache.clear()
  }

  async findDesignForDocument(document: Types.Document, names: string): Promise<Types.Document> {
    const cachedDocumentId = await this._designLookupCache?.get(`${document.f.id}|${names}`, { formId: document.f.id, names })

    if (cachedDocumentId === null) {
      return null
    } else if (cachedDocumentId) {
      return await this._aeppic.get(cachedDocumentId)
    } else {
      this._aeppic.Warn.once('design-lookup:access', `${document.f.id}|${names}`, `Failed to find design for ${document.f.id} with names ${names}`)
    }
  }

  async _findBestMatchAtLocation(locationId: string, names: string[]) {
    if (names.length === 0) {
      return null
    }

    const q = Scopes.children(locationId)
              .form('design-form')
              .where('data.name').isOneOf(names)

    const designsFromSource = await this._aeppic.find(q, { sort: 'modified.at' })

    for (const name of names) {
      const bestMatch = designsFromSource.find(d => d.data.name === name)

      if (bestMatch) {
        return bestMatch
      }
    }

    return null
  }
}
