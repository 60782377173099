let deprecatedLogged = new WeakSet<Function>()

export function resetWarnings() {
  deprecatedLogged = new WeakSet<Function>()
}

// We do require the old name and the class name as they would change during minification
export function deprecated(className: string, oldName: string, newName?: string): Function
export function deprecated(theFunction: Function, oldName: string, newName?: string): void
export function deprecated(arg1: string|Function, arg2: string, arg3?: string): Function|void {
  if (typeof arg1 === 'string') {
    const className = arg1
    const oldName = arg2
    const newName = arg3

    return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
      if (!descriptor) {
        return
      }

      const oldNameFull = [className, oldName].filter(Boolean).join('.')
      const newNameFull = newName ? [className, newName].filter(Boolean).join('.') : ''
      
      if (descriptor.get) {
        const originalGet = descriptor.get

        descriptor.get = function () {
          log(originalGet, oldNameFull, newNameFull)
          return originalGet.apply(this)
        }
      }
      
      if (descriptor.set) {
        const originalSet = descriptor.set

        descriptor.set = function (...args: any[]) {
          log(originalSet, oldNameFull, newNameFull)
          return originalSet.apply(this, args)
        }
      }
      
      if (typeof descriptor.value === 'function') {
        const originalMethod = descriptor.value

        descriptor.value = function (...args: any[]) {
          log(originalMethod, oldNameFull, newNameFull)
          return originalMethod.apply(this, args)
        }
      }
    }
  } else {
    const target = arg1
    const oldName = arg2
    const newName = arg3

    log(target, oldName, newName)
  }
}

function log(target: any, oldName: string, newName: string) {
  if (!deprecatedLogged.has(target)) {
    console.warn(`Warning: '${oldName}' is deprecated.` + (newName ? ` Please use '${newName}' instead.` : ''))
    deprecatedLogged.add(target)
  }
}
