export class NotificationReason {
  public updated = false
  public deletedHard = false
  public revised = false
  public stampChanged = false
  public stampAdded = false
  public stampRemoved = false
  // public inheritanceChanged = false
  public deleted = false

  constructor({
    updated = false,
    deletedHard = false,
    revised = false,
    stampChanged = false,
    // inheritanceChanged = false,
    deleted = false,
    stampAdded = false,
    stampRemoved = false,
  }) {
    this.updated = updated
    this.deletedHard = deletedHard
    this.revised = revised
    this.stampChanged = stampChanged
    this.stampAdded = stampAdded
    this.stampRemoved = stampRemoved

    // this.inheritanceChanged = inheritanceChanged
    this.deleted = deleted
  }

  public static None = new NotificationReason({})

  // public static Added = new DocumentChange({ updated: true/*, added: true */ })
  public static Updated = new NotificationReason({ updated: true })
  // public static Moved = new DocumentChange({ updated: true/*, moved: true*/ })
  public static Deleted = new NotificationReason({ deleted: true })
  public static DeletedHard = new NotificationReason({ deleted: true, deletedHard: true })
  
  // public static AddedRevision = new DocumentChange({ updated: true, added: true, revised: true })
  public static Revised = new NotificationReason({ updated: true, revised: true })
  public static StampAdded = new NotificationReason({ stampChanged: true, stampAdded: true })
  public static StampRemoved = new NotificationReason({ stampChanged: true, stampRemoved: true })
  // public static InheritanceChanged = new NotificationReason({ inheritanceChanged: true })
}
