import Types from '@aeppic/types'
import Cache from '@aeppic/shared/cache'
import { assert } from '@aeppic/shared/assert'

import { isUiInterface, AeppicInterface, AeppicUiInterface } from '../aeppic.js'
import type { CommandAeppicInterface, ExecuteOptions } from './executor.js'
import { CommandExecutor } from './executor.js'
import { ConditionExecutor, ConditionOptions } from './conditions.js'
import { EditableDocument } from '../../model/index.js'
import { isDocument, isReference } from '../../model/is.js'
import { right } from '@popperjs/core/index.js'
import { ALL } from 'dns'

export type { ExecuteOptions }

const MAX_PARAMETER_JSON_LENGTH = 1 * 1024 * 1024
const MAX_PARAMETER_JSON_WARN_LENGTH = 0.5 * 1024 * 1024
const COMMAND_LOG_SIZE = 1000

const ALL_EXECUTION_RIGHT_ID = 'aa3ba2de-4b65-4152-ab1d-655e21de9efd'

type CommandResult = {
  ok: boolean
  value?: any
  error?: any
}

export class Commands implements ICommands {
  private _commandExecutor: CommandExecutor
  private _conditionExecutor: ConditionExecutor
  private _hasAccessCache = new Cache(100, 1000)

  constructor(private _aeppic: CommandAeppicInterface|AeppicUiInterface, private _options: { serverExecutor?: ICommands } = {}) {
    this._commandExecutor = new CommandExecutor({ Aeppic: _aeppic })
    this._conditionExecutor = new ConditionExecutor({ Aeppic: _aeppic })
    Object.freeze(this)
  }

  async execute(commandIdentifier: Types.Document | Types.IdOrReference, target: Types.IdOrReference | EditableDocument, specifiedParameters: any, options: ExecuteOptions = {}) {
    const { command, result } = await this._execute(commandIdentifier, target, specifiedParameters, options)

    if (!options?.navigation) {
      return result
    }

    if (!result?.ok) {
      return result
    }

    const returnedADocument = isReference(result.value) || isDocument(result.value)

    if (!returnedADocument) {
      return result
    }

    if (isUiInterface(this._aeppic)) {
      const commandNavigation = command.data.navigation

      switch (commandNavigation) {
        case 'drillInto':
          this._aeppic.drillInto(result.value)
          break
        case 'navigate':
          this._aeppic.navigateTo(result.value)
          break
        case 'back':
          this._aeppic.navigateBack()
          break
        case 'dialog':
          console.error('Dialog command navigation not implemented yet')
          break
        case 'no':
        default:
      }
    }

    return result
  }

  async _execute(commandIdentifier: Types.Document | Types.IdOrReference, target: Types.IdOrReference | EditableDocument, specifiedParameters: any, options: ExecuteOptions = {}): Promise<{ command: Types.Document, result: CommandResult }> {
    const commandParameters = specifiedParameters || {}

    const command = await this._getCommand(commandIdentifier)

    if (!command) {
      throw new Error('Unknown command or command not accessible')
    }

    verifyParameters(commandParameters)
    const clonedParameters = cloneDeep(commandParameters)

    let execution: Promise<CommandResult>

    if (runOnServer(command)) {
      if (!this._options.serverExecutor) {
        execution = this._commandExecutor.execute(command, target, clonedParameters, options)
      } else {
        execution = this._options.serverExecutor.execute(command, target, clonedParameters, options)
      }
    } else {
      execution = this._commandExecutor.execute(command, target, clonedParameters, options)
    }

    return { command, result: await execution }
  }

  _isFeatureEnabled(featureName: string) {
    if (isUiInterface(this._aeppic)) {
      return this._aeppic.Features.isEnabled(featureName)
    }

    return false
  }

  _getOptionsForFeature(featureName: string) {
    if (isUiInterface(this._aeppic)) {
      return this._aeppic.Features.getOptionsFor(featureName)
    }

    return {}
  }

  async hasRightToExecute(commandIdentifier: Types.Document | Types.IdOrReference, targetIdentifier: Types.IdOrReference | EditableDocument) {
    const command = await this._aeppic.get(commandIdentifier)

    if (!command) {
      throw new Error('Could not locate command')
    }

    const target = EditableDocument.isEditableDocument(targetIdentifier) ? targetIdentifier : await this._aeppic.get(targetIdentifier)

    if (!target) {
      throw new Error('Could not locate target')
    }

    if (!this._isFeatureEnabled('commands-rights-verification')) {
      return true
    }

    const hasAllCommandExecutionRights = await this._hasAccessCache.lookup(`r:${ALL_EXECUTION_RIGHT_ID}:${target.id}`, async () => {
      if (this._isFeatureEnabled('commands-rights-verification-ignore-allExecutionRights')) {
        const options = this._getOptionsForFeature('commands-rights-verification-ignore-allExecutionRights') as { accounts?: string[] }

        if (isUiInterface(this._aeppic)) {
          if (options?.accounts?.includes(this._aeppic.Account.id)) {
            return false
          }
        }
      }

      return this._aeppic.hasRight(target, ALL_EXECUTION_RIGHT_ID)
    })

    if (hasAllCommandExecutionRights) {
      return true
    }

    return this._hasAccessCache.lookup(`c:${command.id}:${target.id}`, async () => {
      const queryForRights = this._aeppic.Query.global().form('command-execution-right-form').where('data.allowAllCommands').is(true).or.where('data.allowedCommands').is(command.id)
      const rightsGrantingAccessToCommand = await this._aeppic.find(queryForRights)
      const rightsAlsoMatchingTarget = rightsGrantingAccessToCommand.filter(right => doesRightApplyToTarget(right, target))

      console.log('command', command, 'target', target)
      console.log('rightsGrantingAccessToCommand', rightsGrantingAccessToCommand)
      console.log('rightsAlsoMatchingTarget', rightsAlsoMatchingTarget)

      // if (!rightsGrantingAccessToCommand.length) {
      //   return false
      // }

      // if (!rightsAlsoMatchingTarget.length) {
      //   return false
      // }

      //const rightsFilteredAgainstCommand = rightsFilteredAgainstTarget.filter(right => doesRightApplyToCommand(right, command))
      const hasAccessToAnyOfThoseRightsForTheTarget = await this._aeppic.hasRight(target, rightsAlsoMatchingTarget)
      return hasAccessToAnyOfThoseRightsForTheTarget
    })
  }

  async isVisible(commandIdentifier: Types.Document | Types.IdOrReference, target: Types.IdOrReference | EditableDocument, specifiedParameters: any, options: ConditionOptions = {}) {
    if (!commandIdentifier || !target) {
      throw new Error('Missing commandIdentifier or target')
    }

    const command = await this._getCommand(commandIdentifier)

    if (!command) {
      throw new Error('Unknown command or command not accessible')
    }

    const conditions = await this._getVisibleConditions(command)

    if (!conditions.length) {
      return true
    }

    const conditionParameters = specifiedParameters || {}

    verifyParameters(conditionParameters)
    const clonedParameters = cloneDeep(conditionParameters)

    return this._conditionExecutor.allPassing(conditions, target, clonedParameters, options)
  }

  async isEnabled(commandIdentifier: Types.Document | Types.IdOrReference, target: Types.IdOrReference | EditableDocument, specifiedParameters: any, options: ConditionOptions = {}) {
    if (!commandIdentifier || !target) {
      throw new Error('Missing commandIdentifier or target')
    }

    const command = await this._getCommand(commandIdentifier)

    if (!command) {
      throw new Error('Unknown command or command not accessible')
    }

    const conditions = await this._getEnabledConditions(command)

    if (!conditions.length) {
      return true
    }

    const conditionParameters = specifiedParameters || {}

    verifyParameters(conditionParameters)
    const clonedParameters = cloneDeep(conditionParameters)

    return this._conditionExecutor.allPassing(conditions, target, clonedParameters, options)
  }

  private _getCommand(commandIdentifier: Types.Document | Types.IdOrReference) {
    if (isDocument(commandIdentifier)) {
      if (EditableDocument.isEditableDocument(commandIdentifier)) {
        return commandIdentifier.cloneAsDocument()
      } else {
        return commandIdentifier
      }
    } else {
      return this._aeppic.get(commandIdentifier)
    }
  }

  private async _getVisibleConditions(command: Types.Document) {
    return this._getConditions(command, 'visibleConditions')
  }

  private async _getEnabledConditions(command: Types.Document) {
    return this._getConditions(command, 'enabledConditions')
  }

  private async _getConditions(command, fieldName) {
    if (!(fieldName in command.data)) {
      return []
    }

    if (!(<Types.ReferenceField[]>command.data[fieldName]).length) {
      return []
    }

    const conditions = await this._aeppic.getAll(<Types.ReferenceField[]>command.data[fieldName])
    return conditions.filter(c => c != null)
  }
}

export type ICommands = Pick<Commands, 'execute'>

function runOnServer(command: Types.Document) {
  return command.data.serverOnly || (command.data.account && (<Types.Reference>command.data.account).id)
}

function verifyParameters(parameters: any) {
  for (const name of Object.keys(parameters)) {
    const value = parameters[name]

    if (EditableDocument.isEditableDocument(value)) {
      throw new Error('Cannot pass an EditableDocument as parameters. Use cloneAsDocument() to clone EditableDocuments first.')
    }
  }
}

function cloneDeep(obj) {
  const json = JSON.stringify(obj)

  if (json.length > MAX_PARAMETER_JSON_WARN_LENGTH) {
    console.warn(`Command parameters when stringified should not exceed #${MAX_PARAMETER_JSON_WARN_LENGTH} bytes and will cause an exception at #${MAX_PARAMETER_JSON_LENGTH}. These are #${json.length} bytes big.`)
  }

  if (json.length > MAX_PARAMETER_JSON_LENGTH) {
    throw new Error(`Command parameters exceed stringified maximum size (${json.length} > ${MAX_PARAMETER_JSON_LENGTH})`)
  }

  return JSON.parse(json)
}

function doesRightApplyToTarget(right: Types.Document, target: Types.Document) {
  assert(right?.f.id === 'command-execution-right-form')

  if (right.data.applyToAllForms) {
    return true
  }

  const targetFormsRightIsLimitedTo: Types.ReferenceField[] = right.data.targetForms as Types.ReferenceField[] ?? []

  if (targetFormsRightIsLimitedTo.some(ref => ref.id === target.f.id)) {
    return true
  }

  return false
}

function doesRightApplyToCommand(right: Types.Document, command: Types.Document) {
  assert(right?.f.id === 'command-execution-right-form')

  if (right.data.allowAllCommands) {
    return true
  }

  const commandsRightIsLimitedTo: Types.ReferenceField[] = right.data.allowedCommands as Types.ReferenceField[] ?? []

  if (commandsRightIsLimitedTo.some(ref => ref.id === command.id)) {
    return true
  }

  return false
}