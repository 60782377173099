import Types from '@aeppic/types'

import { runDynamicCode } from  '@aeppic/shared/dynamic/dynamic-code'

import Cache from '@aeppic/shared/cache'
import { EditableDocument } from '../model' 
import { Logger} from '../model/log'

export type Script = {
  ok: true
  script: any
} | {
  ok: false
  error: string
}

export type ScriptFunction = {
  ok: true
  fn: any
} | 
{
  ok: false
  error: string
}

export interface RunOptions {
  isolation: 'best' | 'vm' | 'none',
  Logger?: Logger
  functionExecution?: 'async' | 'sync'
}

export interface RunResult {
  ok: boolean
  error?: string
  value?: any
}

export const DEFAULT_RUN_OPTIONS: RunOptions = {
  isolation: 'best',
  functionExecution: 'async'
}

const SIZE = 1024 
const CACHE_EXPIRE_IN_SECONDS = 1 * 60 * 60

const scriptCache = new Cache<Script>(SIZE, CACHE_EXPIRE_IN_SECONDS)
const fnScriptCache = new Cache<ScriptFunction>(SIZE, CACHE_EXPIRE_IN_SECONDS)

function isRunningInNode(): boolean {
  return (typeof process !== 'undefined') && (process.versions) && (process.versions.node) && true
}

export async function runCode(dynamicCodeDocument: Types.Document, globals: any, { isolation, Logger, functionExecution }: RunOptions = DEFAULT_RUN_OPTIONS): Promise<RunResult> {
  if (!('code' in dynamicCodeDocument.data)) {
    console.error('dynamicCodeDocument: field `code` is required', )

    return { ok: false }
  }

  let result: RunResult = null

  if (isRunningInNode()) {
    if ((isolation === 'best' || isolation === 'vm')) {
      result = await _runCodeWithVM(dynamicCodeDocument, globals, functionExecution)
    } else if (isolation === 'none') {
      result = await _runCodeWithNoIsolation(dynamicCodeDocument, globals, functionExecution)
    } else {
      result = { ok: false }
    }
  } else {
    if (isolation !== 'none') {
      Logger && Logger.debug({ type: 'dynamic-code:run:warn' }, `Requested isolation level '${isolation}' but will run isolation level 'none'`)
    }

    result = await _runCodeWithNoIsolation(dynamicCodeDocument, globals, functionExecution)
  }

  return result
}

async function _runCodeWithNoIsolation(dynamicCodeDocument: Types.Document|EditableDocument, globals: any, functionExecution = DEFAULT_RUN_OPTIONS.functionExecution): Promise<RunResult> {
  const executeAsync = functionExecution === 'async'

  const cacheKey = calculateCacheKey(dynamicCodeDocument)
  const generatedFileName = generatedFileNameForDynamicCode(dynamicCodeDocument)
  const injectedGlobalNames = Object.getOwnPropertyNames(globals)
  
  const functionLookup = await fnScriptCache.lookup(cacheKey, () => {
    const injectedGlobals = { window: null, global: null }

    const code = dynamicCodeDocument.data.code
    const wrappedCode = executeAsync ? wrapAsyncCode(code, dynamicCodeDocument.id) : wrapSyncCode(code, dynamicCodeDocument.id)
    
    const injectedGlobalParameters = injectedGlobalNames.join(', ')

    const functionScriptContent = executeAsync ? `'use strict';\nreturn (resolve, reject, ${injectedGlobalParameters}) => { ${wrappedCode} }` : `'use strict';\nreturn (${injectedGlobalParameters}) => { return ${wrappedCode} }`

    try {
      const codeFunction = runDynamicCode('dynamic-code', generatedFileName, functionScriptContent, injectedGlobals)
      return {
        ok: true,
        fn: codeFunction,
      }
    } catch (compileError) {
      // TODO: Log Compilation Error
      const stack = cleanStack(compileError.stack, 'aeppic')

      console.error('Error compiling', stack)
      
      return {
        ok: false,
        error: 'Could not compile dynamic-code: ' + compileError.toString() + '\n' + stack
      }
    }
  })

  if (functionLookup.ok) {
    let value = null

    const injectedGlobals = injectedGlobalNames.map(name => globals[name])

    if (executeAsync) {
      value = await new Promise((resolve, reject) => {
        try {
          return functionLookup.fn(resolve, reject, ...injectedGlobals)
        } catch (runtimeError) {
          const stack = cleanStack(runtimeError.stack, 'function.js')
          
          console.error('Error running', stack)
          
          return {
            ok: false,
            error: 'Error running dynamic-code: ' + runtimeError.toString() + '\n' + stack
          }
        }
      })
    } else {
      try {
        value = functionLookup.fn(...injectedGlobals)
        if (value instanceof Promise) {
          return {
            ok: false,
            error: 'Error running dynamic-code: synchronous execution but Promise returned'
          }
        }
      } catch (runtimeError) {
        const stack = cleanStack(runtimeError.stack, 'function.js')
        
        console.error('Error running', stack)
        
        return {
          ok: false,
          error: 'Error running dynamic-code: ' + runtimeError.toString() + '\n' + stack
        }
      }
    }

    return {
      ok: true,
      value,
    }
  } else {
    return functionLookup
  }
}

async function _runCodeWithVM(dynamicCodeDocument: Types.Document | EditableDocument, globals: any, functionExecution = DEFAULT_RUN_OPTIONS.functionExecution): Promise <RunResult> {
  const executeAsync = functionExecution === 'async'

  const cacheKey = calculateCacheKey(dynamicCodeDocument)
  const vm = await import('vm')

  const CODE_LINE_OFFSET = -2

  const scriptLookup = await scriptCache.lookup(cacheKey, () => {
    const generatedFileName = generatedFileNameForDynamicCode(dynamicCodeDocument)
    try {
      const code = dynamicCodeDocument.data.code
      const wrappedCode = executeAsync ? wrapAsyncCode(code, dynamicCodeDocument.id) : wrapSyncCode(code, dynamicCodeDocument.id)

      const script = new vm.Script(wrappedCode, { filename: generatedFileName, lineOffset: CODE_LINE_OFFSET })
     
      return {
        ok: true,
        script
      }
    } catch (compileError) {
      // TODO: Log Compilation Error
      const stack = cleanStack(compileError.stack, 'aeppic')

      console.error('Error compiling', stack)
      
      return {
        ok: false,
        error: 'Could not compile dynamic-code: ' + compileError.toString() + '\n' + stack
      }
    }
  })

  if (scriptLookup.ok) {
    const contextifiedGlobals = vm.createContext(globals)

    let scriptPromise: Promise<any>

    if (executeAsync) {
      scriptPromise = new Promise((resolve, reject) => {
        globals.resolve = resolve
        globals.reject = reject
      })
    }
      
    const scriptResult = scriptLookup.script.runInNewContext(contextifiedGlobals, { timeout: 30000, displayErrors: true, lineOffset: CODE_LINE_OFFSET })
    // console.log(executeAsync, scriptLookup.script, scriptResult)
    
    try {
      let value

      if (executeAsync) {
        value = await scriptPromise
      } else {
        value = scriptResult
        
        // 'instanceof value === Promise' is not applicable here since the script is running in a different (vm-)context
        const isPromiseResult = value.constructor && (value.constructor.name === 'Promise' || value.constructor.name === 'AsyncFunction')

        if (isPromiseResult) {
          return {
            ok: false,
            error: 'Error running dynamic-code: synchronous execution but Promise returned'
          }
        }
      }

      return {
        ok: true,
        value,
      }
    } catch (runtimeError) {
      const stack = cleanStack(runtimeError.stack, 'vm.js')
      
      console.error('Error running', stack)
      
      return {
        ok: false,
        error: 'Error running dynamic-code: ' + runtimeError.toString() + '\n' + stack,
      }
    }
  } else {
    return scriptLookup
  }
}

function wrapAsyncCode(code: string, logActionId: string) {
  return `'use strict';\nresolve((async function DynamicCode(){'use strict';\n${code}\n})());`
}

function wrapSyncCode(code: string, logActionId: string) {
  return `(function DynamicCode(){'use strict';\n${code}\n})();`
}

function cleanStack(stack: string, cutOffWord: string): string {
  const lines = stack.split(/\r\n|\n|\r/)
  const cleanedStackLines = []

  for (const line of lines) {
    if (line.indexOf(cutOffWord) >= 0) {
      break
    } else {
      cleanedStackLines.push(line)
    }
  }

  return cleanedStackLines.join('\n')
}


function calculateCacheKey(dynamicCodeDocument: Types.Document) {
  return <string> dynamicCodeDocument.data.code
}

function generatedFileNameForDynamicCode(dynamicCodeDocument: Types.Document|EditableDocument) {
  const nameSuffix = `/${dynamicCodeDocument.data.label || dynamicCodeDocument.data.name}`
  const baseName = `aeppic/${dynamicCodeDocument.f.id}/${dynamicCodeDocument.id}${nameSuffix}`

  if (EditableDocument.isEditableDocument(dynamicCodeDocument)) {
    return `${baseName}__${dynamicCodeDocument.editVersion}.js` 
  } else {
    return baseName + '.js'
  }
}
