import * as Types from '@aeppic/types'
import { assert } from '@aeppic/shared/assert'

import { AeppicInterface } from './aeppic.js'
import type { Write, SaveOptions, NewDocumentOptions, FindOptions, FilterFunction, StampOptions, Change } from '../model/index.js'
import { EditableDocument, Form, Query, toQueryAndOptions, getDocumentId } from '../model/index.js'
import { buildLogger, Logger } from '../model/log.js'
import { CommandAeppicInterface } from './commands/executor.js'
import { Warn } from './warn.js'

type Interface<T> = {
  [P in keyof T]: T[P]
}

export interface Options {
  writes?: Write[]
  Logger?: Logger
}

/**
 * This class wraps an Aeppic instance by wrapping all calls usable
 * for an Action. All writes are kept in a log passed into the constructor
 * but NOT applied to the Aeppic instance. 
 * 
 * This class is used as the Aeppic instance inside of `Actions` such as
 * used by Business Rules or Commands 
 * 
 *  * @class WriteLockedAeppic
 * @extends {AeppicInterface}
 */
export class WriteLockedAeppic implements CommandAeppicInterface {
  private _hasPrivateWrites: boolean
  private _writes: Write[]
  private _log: Logger
  private _warn: Warn
  private _documents: Map<string, EditableDocument> = new Map()

  constructor(private _aeppic: AeppicInterface|CommandAeppicInterface, { writes, Logger }: Options = {}) {
    if (!_aeppic) {
      throw new Error('Missing argument exception')
    }

    if (writes == null) {
      this._hasPrivateWrites = true
      this._writes = []
    } else {
      this._hasPrivateWrites = false
      this._writes = writes
    }

    this._log = buildLogger(Logger || _aeppic.Log, { class: 'WriteLockedAeppic' })
    this._warn = new Warn(this._log)
  }

  public fetch(url, options: { credentials?, method?, body?, headers?} = {}) {
    return this._aeppic.fetch(url, options)
  }

  get Log() {
    return this._log
  }

  get Warn() {
    return this._warn
  }

  get Formatting() {
    return this._aeppic.Formatting
  }

  get Features() {
    return this._aeppic.Features
  }

  flush() {
    if (!this._hasPrivateWrites) {
      throw new Error('This instance of WriteLockedAeppic does not have its own private write buffer and cannot be flushed individually')
    }
    
    this._aeppic.applyWrites(this._writes)
    this._writes = []
  }

  async new(formIdentifier: string, parent: Types.IdOrReference, options ?: NewDocumentOptions): Promise<EditableDocument> {
    const newDocument = await this._aeppic.new(formIdentifier, parent, options)
    this._log.trace({ type: 'document:new', id: newDocument.id, f: newDocument.f, p: newDocument.p }, 'New document')
    return newDocument
  }

  public download(url: string) {
    return this._aeppic.download(url)
  }

  save(document: EditableDocument) {
    if (!EditableDocument.isEditableDocument(document as any)) {
      throw new Error(`Can only save editable documents (document:${document?.id})`)
    }
    this._log.trace({ type: 'document:save', id: document.id, f: document.f, p: document.p, data: document.data }, 'Saving document %s', document.id)
    this._writes.push({type: 'save', arguments: [document]})
    this._documents.set(document.id, document)
  }

  saveAll(documents: EditableDocument[]) {
    for (const document of documents) {
      if (!EditableDocument.isEditableDocument(document as any)) {
        throw new Error(`Can only save editable documents (document:${document?.id})`)
      }
    }

    this._log.trace({ type: 'document:saveAll', count: documents.length }, 'Save documents')
    this._writes.push({type: 'saveAll', arguments: [documents]})
  }

  change(identifier: Types.IdOrReference, targetForm: Types.IdOrReference): void {
    this._log.trace({ type: 'document:change', identifier, targetForm }, 'Changing document')
    this._writes.push({type: 'change', arguments: [identifier, targetForm]})
  }

  async canUpgrade(identifier: Types.IdOrReference) {
    return this._aeppic.canUpgrade(identifier)
  }

  upgrade(identifier: Types.IdOrReference): void {
    this._log.trace({ type: 'document:upgrade', identifier }, 'Upgrading document')
    this._writes.push({type: 'upgrade', arguments: [identifier]})
  }

  delete(identifier: Types.IdOrReference): void {
    this._log.trace({ type: 'document:delete', identifier }, 'Deleting document')
    this._writes.push({type: 'delete', arguments: [identifier]})
  }

  deleteHard(identifier: Types.IdOrReference): void {
    this._log.trace({ type: 'document:deleteHard', identifier }, 'Deleting document (hard)')
    this._writes.push({type: 'deleteHard', arguments: [identifier]})
  }

  asReference(identifier: Types.IdOrReference|Types.Document|string) {
    return this._aeppic.asReference(identifier)
  }

  move(identifier: Types.IdOrReference, newParentIdentifier: Types.IdOrReference): void {
    this._log.trace({ type: 'document:move', identifier, parent: newParentIdentifier }, 'Moving document')
    this._writes.push({type: 'move', arguments: [identifier, newParentIdentifier]})
  }

  async clone(identiferOrReference: Types.IdOrReference, options?: { id?: string, fieldsNotToClone?: string[] }): Promise<EditableDocument>
  async clone(identiferOrReference: Types.IdOrReference, parentIdentiferOrReference?: Types.IdOrReference, options?: { id?: string, fieldsNotToClone?: string[] }): Promise<EditableDocument>
  async clone(identiferOrReference: Types.IdOrReference, ...args: any[]): Promise<EditableDocument> {
    this._log.trace({ type: 'document:clone', identifier: identiferOrReference, args }, 'Cloning document')
    return this._aeppic.clone(identiferOrReference, ...args)
  }
 
  async cloneDeep(identiferOrReference: Types.IdOrReference, parentIdentiferOrReference: Types.IdOrReference, options: { save?: boolean, formFieldsNotToClone?: Object, identifiersNotToClone?: Types.IdOrReference[] } = {}) {
    this._log.trace({ type: 'document:cloneDeep', identifier: identiferOrReference, parent: parentIdentiferOrReference }, 'Deep cloning document')

    if (options && !options.save) {
      return this._aeppic.cloneDeep(identiferOrReference, parentIdentiferOrReference, options)
    } else if (options && options.save) {
      const result = await this._aeppic.cloneDeep(identiferOrReference, parentIdentiferOrReference, { ...options, save: false })
      this.saveAll(result.documents)
      return result
    }
  }

  // buildTemporaryForm(formDocument: Types.Document): Form {
  //   return this._aeppic.buildTemporaryForm(formDocument)
  // }

  // buildTemporaryEditableDocument(form: Form): EditableDocument {
  //   return this._aeppic.buildTemporaryEditableDocument(form)
  // }

  isEditableDocument(document: Types.Document): document is EditableDocument {
    return EditableDocument.isEditableDocument(document)
  }

  get Query() {
    return this._aeppic.Query
  }

  async hasRight(targetDocumentIdentifier: string|Types.Reference, desiredRight?: Types.IdOrReference): Promise<boolean>
  async hasRight(targetDocumentIdentifier: string|Types.Reference, desiredRight?: Types.IdOrReference[]): Promise<boolean>
  async hasRight(targetDocumentIdentifier: string|Types.Reference, arg2?: Types.IdOrReference|Types.IdOrReference[]): Promise<boolean> {
    return this._aeppic.hasRight(targetDocumentIdentifier, arg2 as any)
  }

  
  async get(identifier: Types.IdOrReference|Types.Document): Promise<Types.Document> {
    const documentId = getDocumentId(identifier)

    if (!documentId) {
      return null
    }
    
    const previouslySavedDocument = this._documents.get(documentId)

    if (previouslySavedDocument) {
      return previouslySavedDocument.cloneAsDocument()
    }

    const doc = await this._aeppic.get(identifier)

    this._log.trace({ type: 'document:get', document: doc }, 'Getting document', identifier)

    return doc
  }

  async getAll(identifiers: (Types.IdOrReference|Types.Document)[]): Promise<Types.Document[]> {
    if (!identifiers) {
      return []
    }

    const ids = identifiers.map(identifier => getDocumentId(identifier))
    const documents = ids.map(id => id ? this._documents.get(id) : null).filter(Boolean)

    if (documents.length === ids.length) {
      return Promise.resolve(documents.map(doc => doc.cloneAsDocument()))
    }

    const previouslySavedDocuments = new Map(documents.map(doc => [doc.id, doc]))

    const remainingDocumentsToBeFetched = ids.filter(id => !previouslySavedDocuments.has(id))
    const fetchedDocuments = await this._aeppic.getAll(remainingDocumentsToBeFetched)

    assert(fetchedDocuments.length + previouslySavedDocuments.size === ids.length, 'There is a mismatch between the number of documents fetched and the number of documents requested')

    const result = ids.map(id => {
      const previouslySavedDocument = previouslySavedDocuments.get(id)

      if (previouslySavedDocument) {
        return previouslySavedDocument.cloneAsDocument()
      } else {
        return fetchedDocuments.shift()
      }
    })

    assert(fetchedDocuments.length === 0, 'There are still documents that where not used')

    return result
  }

  hasChildren(identifier: Types.IdOrReference): Promise<Boolean> {
    return this._aeppic.hasChildren(identifier)
  }

  async getFormForDocument(document: Types.Document): Promise<Form> {
    return this._aeppic.getFormForDocument(document)
  }

  async getForm(identifier: Types.IdOrReference): Promise<Form> {
    return this._aeppic.getForm(identifier)
  }

  async getFormVersions(formIdentifier: Types.IdOrReference): Promise<string[]> {
    return this._aeppic.getFormVersions(formIdentifier)
  }

  async resolve(identifier: Types.IdOrReference, path: string): Promise<Types.Document> {
    return this._aeppic.resolve(identifier,path)
  }
  
  async resolveEdit(identifier: Types.IdOrReference, path: string): Promise<Types.Document> {
    return this._aeppic.resolveEdit(identifier,path)
  }

  async resolveToField(identifier: Types.IdOrReference, path: string): Promise<any> {
    return this._aeppic.resolveToField(identifier,path)
  }
  
  async exportForms(parentDocumentId = 'root', { alsoFilterSystem = false, includeFn = null }: { alsoFilterSystem?: Boolean, includeFn?: (id: string) => Boolean } = {}): Promise<(Change | Types.Document)[]> {
    return this._aeppic.exportForms(parentDocumentId, { alsoFilterSystem, includeFn })
  }

  async find(query: Query.Query, options?: FindOptions): Promise<Types.Document[]>
  async find(query: Query.QueryBuilder, options?: FindOptions): Promise<Types.Document[]>
  async find(query: string, options?: FindOptions): Promise<Types.Document[]>
  async find(query: string, filterString?: string, options?: FindOptions): Promise<Types.Document[]>
  async find(queryArgument: string | Query.Query | Query.QueryBuilder, param1?: string | FindOptions, param2?: FindOptions): Promise<Types.Document[]> {
    const { query, options } = toQueryAndOptions(queryArgument, param1, param2)
    const documents = await this._aeppic.find(query, options)
    this._log.trace({ type: 'find', documents, query, options }, 'Found #%d documents searching', documents.length)
    return documents
  }

  async findOne(query: Query.Query, options?: FindOptions): Promise<Types.Document>
  async findOne(query: Query.QueryBuilder, options?: FindOptions): Promise<Types.Document>
  async findOne(query: string, options?: FindOptions): Promise<Types.Document>
  async findOne(query: string, filterString?: string, options?: FindOptions): Promise<Types.Document>
  async findOne(queryArgument: string | Query.Query | Query.QueryBuilder, param1?: string | FindOptions, param2?: FindOptions): Promise<Types.Document> {
    const { query, options } = toQueryAndOptions(queryArgument, param1, param2)
    const results = await this.find(query, {...options, size: 1 })
    return results[0]
  }

  query(identifier: Types.IdOrReference | Types.Document, rootDocumentIdentifier: Types.IdOrReference, filter ?: string, options ?: FindOptions): Promise<Types.Document[]> {
    return this._aeppic.query(identifier, rootDocumentIdentifier, filter, options)
  }

  integrate(editableDocument: EditableDocument, changedDocument: Types.Document) {
    return this._aeppic.integrate(editableDocument, changedDocument)
  }

  async edit(identifier: Types.IdOrReference): Promise <EditableDocument> {
    const [document] = await this.editAll([identifier])
    return document
  }

  async editAll(identifiers: (Types.IdOrReference|Types.Document)[]): Promise <EditableDocument[]> {
    const ids = identifiers.map(identifier => typeof identifier === 'string' ? identifier : identifier.id)
    const documents = ids.map(id => this._documents.get(id)).filter(Boolean)

    if (documents.length === ids.length) {
      return Promise.resolve(documents.map(doc => {
        const document = doc.cloneAsDocument()
        return new EditableDocument(document, doc.form)
      }))
    }

    const previouslySavedDocuments = new Map(documents.map(doc => [doc.id, doc]))
    const remainingDocumentsToBeEdited = ids.filter(id => !previouslySavedDocuments.has(id))

    const fetchedDocuments = await this._aeppic.editAll(remainingDocumentsToBeEdited)

    assert(fetchedDocuments.length + previouslySavedDocuments.size === ids.length, 'There is a mismatch between the number of documents fetched and the number of documents requested')

    const result = ids.map(id => {
      const previouslySavedDocument = previouslySavedDocuments.get(id)

      if (previouslySavedDocument) {
        return new EditableDocument(previouslySavedDocument.cloneAsDocument(), previouslySavedDocument.form)
      } else {
        return fetchedDocuments.shift()
      }
    })

    assert(fetchedDocuments.length === 0, 'There are still documents that where not used')

    return result
  }
  
  async stamp(target: Types.Document, stamp: Types.IdOrReference | EditableDocument, options?: StampOptions) {
    this._log.trace({ type: 'document:stamp', target, stamp, options }, 'Stamping document')
    this._writes.push({ type: 'stamp', arguments: [target, stamp, options] })
  }

  verifyStamp(target: Types.Document, stampDocument: Types.Document) {
    return this._aeppic.verifyStamp(target, stampDocument)
  }

  translate(...keys) {
    return this._aeppic.translate(...keys)
  }

  get DateTime() {
    return this._aeppic.DateTime
  }

  get DateTimeDuration() {
    return this._aeppic.DateTimeDuration
  }

  get DateTimeInterval() {
    return this._aeppic.DateTimeInterval
  }

  uuid() {
    return this._aeppic.uuid()
  }

  get Preferences() {
    return this._aeppic.Preferences
  }

  get HomePage() {
    return this._aeppic.HomePage
  }
  get Translator() {
    return this._aeppic.Translator
  }
  applyWrites(writes: Write[]) {
    return this._aeppic.applyWrites(writes)
  }
  async getProfilePageForAccount(accountIdentifier: Types.IdOrReference|Types.Document)  {
    return this._aeppic.getProfilePageForAccount(accountIdentifier)
  }
  async getAccountForProfilePage(profilePageIdentifier: Types.IdOrReference|Types.Document)  {
    return this._aeppic.getAccountForProfilePage(profilePageIdentifier)
  }
}
