import * as Types from '@aeppic/types'

/*
 * TS believes BTreeModule has a default export (which is true) and correctly
 * want to import that. NodeJS though (when just running through a TS remover
 * as ts-node (transpile) via ava does it), imports the module.exports
 * thus the types don't match. Recasting here via explicit default property
 * access to make it match
 */
import BTreeModule from 'sorted-btree'
const BTree: typeof BTreeModule = (BTreeModule as any).default ?? BTreeModule

// import type { BTree as BTreeType } from 'sorted-btree' 
interface ValueLookupFunction<T> {
  (document: Types.Document): T
}
 
function IdLookup(document: Types.Document) {
  return document.id
}

export class BTreeDocumentIndex<T> {
  private _documents = new BTree<T, Types.Document[]>()
  
  private _fieldPath: string
  private _valueLookup: ValueLookupFunction<T>

  constructor(fieldPath: string) {
    this._fieldPath = fieldPath
    this._valueLookup = compileValueLookupFunction<T>(fieldPath)
  }

  clear() {
    this._documents.clear()
  }

  get fieldPath() {
    return this._fieldPath
  }

  add(document: Types.Document) {
    const key = this._valueLookup(document)
    let docs = this._documents.get(key)

    if (!docs) {
      docs = [document]
      this._documents.set(key, docs)
    } else {
      docs.push(document)
    }
  }

  get minId() {
    return this._documents.minKey()
  }

  get maxId() {
    return this._documents.maxKey()
  }

  get size() {
    return this._documents.size
  }

  remove(document: Types.Document) {
    const key = this._valueLookup(document)

    const docs = this._documents.get(key)

    if (docs) {
      const index = docs.indexOf(document)

      if (index >= 0) {
        if (docs.length === 1) {
          this._documents.delete(key)
        } else {
          docs.splice(index, 1)
        }
      }
    }
  }

  get(key: T) {
    return this._documents.get(key)
  }

  getNumberOfMatchesForKey(key: T): number {
    const docs = this._documents.get(key)

    if (!docs) {
      return 0
    } else {
      return docs.length
    }
  }

  *enumerateMatches(key: T): Iterator<Types.Document> {
    const docs = this._documents.get(key)

    if (docs) {
      yield * docs
    }
  }
  *enumerateDocuments() {
    yield * this.enumerateDocumentsInKeyRange()
  }

  *enumerateDocumentsInKeyRange(low?: T, high?: T) {
    for (const entry of this.enumerateEntriesInRange(low, high)) {
      yield * entry[1]
    }
  }

  *enumerateEntries() {
    yield * this.enumerateEntriesInRange()
  }
  
  *enumerateEntriesInRange(low?: T, high?: T): IterableIterator<[T, Types.Document[]]> {
    const entry: [T, Types.Document[]] = <any>[]
    const iterator = this._documents.entries(low, entry)
    
    for (;;) {
      const { done } = iterator.next()

      if (done) {
        break
      }

      if (high != null && entry[0] > high) {
        break
      }

      yield entry
    }
  }
}

function compileValueLookupFunction<T>(fieldPath: string) {
  return new Function('document', `return document.${fieldPath}`) as ValueLookupFunction<T>
}
