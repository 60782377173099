
export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _vm._t("default",function(){return [_c('div',{staticClass:"ae-form-paragraph",attrs:{"data-ae-paragraph-type":_vm.type}},[_vm._t("debug",null,{"items":_vm.items,"type":_vm.type}),_vm._v(" "),_vm._t("all",function(){return [_vm._l((_vm.items),function(item){return [(item.type === 'FIELD')?_vm._t("field",function(){return [(item.isVisible)?_c('ae-form-field',{style:(_vm.getItemStyle(item)),attrs:{"field":item,"document":_vm.document,"form":_vm.form,"placeholder":item.placeholder,"params":item.controlParams,"theme":_vm.theme,"readonly":_vm.readonly || item.isReadonly}}):_vm._e()]},{"field":item,"document":_vm.document,"form":_vm.form}):(item.type === 'STANDALONE_CONTROL')?_vm._t("control",function(){return [(item.isVisible)?_c('ae-control',{class:item.classes,style:(_vm.getItemStyle(item)),attrs:{"readonly":_vm.readonly || item.isReadonly,"name":item.name,"document":_vm.document,"form":_vm.form,"placeholder":item.placeholder,"params":item.parameters,"theme":_vm.theme}}):_vm._e()]},{"control":item,"document":_vm.document,"form":_vm.form}):(item.type === 'TEXT')?_vm._t("text",function(){return [_c('span',{domProps:{"innerHTML":_vm._s(item.html)}})]},{"text":item,"document":_vm.document,"form":_vm.form}):_vm._t("unknown",function(){return [_c('span',{staticClass:"ae-dev-error ae-dev-error--warn"},[_vm._v("unknown item type "),_c('pre',[_vm._v(_vm._s(item.type))]),_vm._v("in paragraph.")])]})]})]},{"items":_vm.items,"type":_vm.type,"document":_vm.document,"form":_vm.form})],2)]},{"items":_vm.items,"type":_vm.type,"document":_vm.document,"form":_vm.form})},
  staticRenderFns: [],
  /* tslint:enable */
  
  // inject: ['getAeppicContext'],
  props: {
    paragraph: Object, // TODO: Also allow id and index into form
    document: Object, // Must be an editable document
    // TODO: Also allow documentId: String 
    form: {
      type: Object,
      default: null
    },
    theme: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
    itemStyle: {
      type: Object,
      default: null,
    }
    // TODO: add theme
  },
  data() {
    return {
    }
  },
  methods: {
    getItemStyle(item) {
      const style = { ...(this.itemStyle ?? { flex: '1 1 auto' }) }
      const width = item.placeholder?.style?.width?.value
      
      if (width) {
        const isWidthOnlyDigits = /^\d+$/.test(width)
        style.maxWidth = width + (isWidthOnlyDigits ? '%' : '')
      }

      return style
    },
  },
  computed: {
    type() {
      return this.paragraph?.type
    },
    items() {
      return (this.paragraph?.items) ?? []
    },
  },
  mounted() {
    // this.Aeppic.setContextRootElement(this.$el)
  },
}
