let waitTimer: Promise<void> = null
let idleWaitTimer: Promise<void> = null
let idleAnimationTimer: Promise<void> = null

const IDLE_MAXIMUM_WAIT = 50

export function waitForNextTick(): Promise<void> {
  if (!waitTimer) {
    waitTimer = new Promise((resolve) => {
      setTimeout(() => {
        waitTimer = null
        resolve()
      }, 0)
    })
  }
  
  return waitTimer
}

const nextMicroTickTimer: Promise<void> = Promise.resolve()

export function waitForNextMicroTick(): Promise<void> {
  return nextMicroTickTimer.then()
}

export function waitForNextRefresh(): Promise<void> {
  return nextMicroTickTimer.then()
}

export function waitForNextAnimationIdle() {
  if (!idleAnimationTimer) {
    idleAnimationTimer = new Promise((resolve) => {
      const rAF = typeof window !== 'undefined' ? window.requestAnimationFrame || (<any>window).webkitRequestAnimationFrame : null
      
      if (rAF) {
        rAF(() => {
          idleAnimationTimer = null
          resolve()
        })
      } else {
        setTimeout(() => {
          idleAnimationTimer = null
          resolve()
        }, 0)
      }
    })
  }

  return idleAnimationTimer
}

export function waitForNextIdle() {
  if (!idleWaitTimer) {
    idleWaitTimer = new Promise((resolve) => {
      const rIC = typeof window !== 'undefined' ? (<any>window).requestIdleCallback : null

      if (rIC) {
        rIC(() => {
          idleWaitTimer = null
          resolve()
        }, { timeout: IDLE_MAXIMUM_WAIT })
      } else {
        waitForNextAnimationIdle().then(() => {
          idleWaitTimer = null
          resolve()
        })
      }
    })
  }

  return idleWaitTimer
}
