import isEqual from 'lodash-es/isEqual.js'
import { parse } from './parse.js'

export default function nextSemanticVersion(definition1, definition2) {
  if (definition1 === definition2) {
    return ''
  }

  const form1 = parse(definition1)
  const form2 = parse(definition2)

  // are all fields of form1 still present in form2 ?
  for (const fieldName of Object.keys(form1.schema.properties)) {
    const fieldSchema1 = form1.schema.properties[fieldName]
    const fieldSchema2 = form2.schema.properties[fieldName]

    if (!fieldSchema2) {
      return 'major'
    }

    // are any fields defined differently ?
    if (!isEqual(fieldSchema1, fieldSchema2)) {
      return 'major'  
    }
  }

  // the new form is downward compatible, are there new fields?
  if (Object.keys(form2.schema.properties).length > Object.keys(form1.schema.properties).length) {
    return 'minor'
  } 
  
  return 'revision'
}
