import * as Types from '@aeppic/types'

const PREFERENCES_FID = '643508ca-fc18-4d2e-bb13-b3c3f2ac053b'
const SYSTEM_PREFERENCES_FID = '50c9b51e-f477-48c0-94a9-b1445b287fd7'

/*
 * Preferences
 * 
 * CAUTION: Any search for preferences is cached immediately (!), unless options.noCache=true.
 * 
 * An active caching has the following implications:
 * - This existence of a preference will never update
 * - The actual preferenceDocument and it's data is live
 */
export default class Preferences {
  // TODO: do not build a this.CACHE here, but rely on Aeppic.find this.CACHE
  private CACHE = null
  private Aeppic = null
  private systemPreferences = null
  private _delayUntilLoaded = { promise: null, resolve: null }

  constructor(Aeppic, { noCache } = { noCache: false }) {
    this.Aeppic = Aeppic

    if (noCache) {
      this.CACHE = {
        has() {
          return false
        },
        get() { },
        set() { },
      }
    } else {
      this.CACHE = new Map()
    }

    this.init()
  }

  private async init() {
    let resolve = null
    const promise = new Promise<void>(resolveCb => resolve = resolveCb)
    this._delayUntilLoaded = { promise, resolve }

    const systemPreferences = await this.Aeppic.find(`f.id:${SYSTEM_PREFERENCES_FID}`, { size: 2, sort: 'id' })

    if (systemPreferences.length > 1) {
      console.error('More than 1 systemPreference found. Using first result.', systemPreferences)
    }

    this.systemPreferences = systemPreferences[0]

    if (this._delayUntilLoaded) {
      this._delayUntilLoaded.resolve()
      this._delayUntilLoaded = null
    }
  }

  delayUntilLoaded() {
    if (this._delayUntilLoaded) {
      return this._delayUntilLoaded.promise
    }

    return null
  }

  async getSetting(key: string) {
    return this.getSettingForDocument(key, null)
  }

  async getSettingForDocument(key: string, document?: Types.Document) {
    const { userSettings, globalSettings } = await this.getSettingsDocumentsForDocument(key, document)

    const settingKey = decomposeSetting(key)

    const userSettingAvailable = (userSettings && userSettings.data.hasOwnProperty(settingKey) && !this.isEmptyField(userSettings.data[settingKey]))
    if (userSettingAvailable) {
      return userSettings.data[settingKey]
    }

    const globalSettingAvailable = (globalSettings && globalSettings.data.hasOwnProperty(settingKey) && !this.isEmptyField(globalSettings.data[settingKey]))
    if (globalSettingAvailable) {
      return globalSettings.data[settingKey]
    }

    return null
  }

  async getSettingsDocuments(key: string) {
    return this.getSettingsDocumentsForDocument(key, null)
  }

  async getSettingsDocumentsForDocument(key: string, document?: Types.Document) {
    const result = {
      globalSettings: null,
      userSettings: null
    }

    const { preferencesDocument, activeSetting, defaultUserSetting } = await this.lookupSettingsForDocument(key, document)
    if (!activeSetting && !defaultUserSetting) {
      return result
    }

    result.globalSettings = activeSetting || null

    const currentAccount = this.Aeppic.Account
    if (currentAccount) {
      result.userSettings = await this.getSettingsSpecificForAccount(preferencesDocument, defaultUserSetting, currentAccount)
    }

    return result
  }

  /**
   * Retrieves a setting by provided document and fallbacks to global preferences
   * @param key - 
   * @param document - if provided, a local setting will be return if available
   */
  private async lookupSettingsForDocument(key, document?): Promise<any> {
    await this.delayUntilLoaded()

    const ensuredDocument = document && await this.Aeppic.get(document)
    const preferencesQuery = getPreferencesQuery(key)

    let mostNearAncestorPreferenceDocumentQuery = ''
    let systemPreferenceSearch = this.systemPreferences && `p:${this.systemPreferences.id} ${preferencesQuery}`

    if (ensuredDocument) {
      const ancestorsSearchPart = [`p:${ensuredDocument.id}`, ...(ensuredDocument.a ?? []).map(a => `p:${a}`)].join(' OR ')
      mostNearAncestorPreferenceDocumentQuery = `${preferencesQuery} (${ancestorsSearchPart})`
    }

    const preferenceDocumentQuery = mostNearAncestorPreferenceDocumentQuery || systemPreferenceSearch

    if (!preferenceDocumentQuery) {
      console.warn(`No preferenceDocumentQuery possible. Either provide an existing document or ensure systemPreferences (f.id:${SYSTEM_PREFERENCES_FID}) are existing.`)
      return {}
    }

    if (this.CACHE.has(preferenceDocumentQuery)) {
      return this.getSettingsOfPreference(this.CACHE.get(preferenceDocumentQuery))
    }

    let settings = await this.Aeppic.findOne(preferenceDocumentQuery, { sort: [{ field: 'a_depth', descending: true }, { field: 'id' }] })

    const fallbackToSystemPreferences = !settings && mostNearAncestorPreferenceDocumentQuery

    if (fallbackToSystemPreferences) {
      if (systemPreferenceSearch) {
        settings = await this.Aeppic.findOne(systemPreferenceSearch, { sort: [{ field: 'a_depth', descending: true }, { field: 'id' }] })
      } else {
        console.warn('No systemPreferences found. No fallback possible.')
      }
    }

    this.CACHE.set(preferenceDocumentQuery, settings)

    if (settings) {
      return this.getSettingsOfPreference(settings)
    }

    return {}
  }

  private async getSettingsOfPreference(preferencesDocument) {
    const ensuredPreferenceDocument = await this.Aeppic.get(preferencesDocument)

    const [activeSetting, defaultUserSetting] = await this.Aeppic.getAll([
      ensuredPreferenceDocument && ensuredPreferenceDocument.data.activeSettings.id ? ensuredPreferenceDocument && ensuredPreferenceDocument.data.activeSettings.id : null,
      ensuredPreferenceDocument && ensuredPreferenceDocument.data.defaultUserSettings.length ? ensuredPreferenceDocument.data.defaultUserSettings[0].id : null
    ])

    const result = {
      preferencesDocument: ensuredPreferenceDocument,
      activeSetting,
      defaultUserSetting
    }

    return result
  }

  private async getSettingsSpecificForAccount(preferencesDocument, defaultUserSettings, account) {
    if (!defaultUserSettings) {
      return null
    }

    const query = `f.id:${defaultUserSettings.f.id} AND p:${preferencesDocument.id} AND created.by:${account.id}`
    if (this.CACHE.has(query)) {
      // NOTE: use get for retrieving the setting, which existed at the time of first query
      // TOOD: improve behaviour for once existing, but then deleted settings. Currently this leads to ongoing calls to the server
      const ensuredSettings = this.CACHE.get(query)
      return this.Aeppic.get(ensuredSettings)
    }

    const accountSpecificSettings = await this.Aeppic.findOne(query) || null
    this.CACHE.set(query, accountSpecificSettings)
    return accountSpecificSettings
  }

  private isEmptyField(field) {
    // TODO: more sophisticated logic here. should be in form.ts
    return field == null || field === ''
  }
}

function getPreferencesQuery(key) {
  const decomposedKey = decomposeSettingDocumentKey(key)

  if (!decomposedKey.namespace) {
    return `f.id:${PREFERENCES_FID} AND data.name:"${decomposedKey.name}"`
  }

  return `f.id:${PREFERENCES_FID} AND data.namespace:"${decomposedKey.namespace}" AND data.name:"${decomposedKey.name}"`
}

function decomposeSetting(key) {
  // [namespace:]name:setting
  const parts = key.split(':')

  return parts.slice(-1)[0]
}

function decomposeSettingDocumentKey(key) {
  // [namespace:]name[:notUsedSetting]
  const parts = key.split(':')

  const result = {
    namespace: null,
    name: null
  }

  if (parts.length === 1) {
    result.name = parts[0]
  } else {
    result.namespace = parts[0]
    result.name = parts[1]
  }

  return result
}
