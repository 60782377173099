export function debounce(callback, wait?, context = this, timeoutFn: any = setTimeout) {
  let timeout = null 
  let callbackArgs = null
  
  const later = () => callback.apply(context, callbackArgs)
  
  return function(...args) {
    callbackArgs = args
    clearTimeout(timeout)
    timeout = timeoutFn(later, wait || '250')
  }
}

export function debounceAsync(callback, wait?, context = this): () => Promise<void> {
  let timeout = null 
  let callbackArgs: any[] = null

  let resolve: (result: any) => void = null
  let promise: Promise<void> = null
  
  const debouncedFunction = function(...args) {
    callbackArgs = args
    if (promise) {
      return promise    
    } else {
      clearTimeout(timeout)
      timeout = setTimeout(later, wait || '250')
    }
  }

  const later = () => {
    if (!promise) {
      promise = new Promise((r) => resolve = r)

      const args = callbackArgs
      callbackArgs = null

      resolve(new Promise((r) => {
        promise = null
        
        try {
          callback.apply(context, args)
        } catch (error) {
          // tslint:disable-next-line    
          console.error('Error running debounced function')
        }

        if (callbackArgs) {
          debouncedFunction.apply(context, args)
        }
      }))
    }
  }

  return debouncedFunction
}

