import UrlPattern from 'url-pattern'

export async function checkRoutes(Aeppic, url) {
  const urlWithoutBase = removeBaseFromUrl(url)  
  const urlWithJustPath = removeQueryOrHash(urlWithoutBase)

  const root = await Aeppic.get('root')

  if (!root) {
    return null
  }

  if (!root.data.routingTables) {
    return null
  }

  const tables = await Aeppic.getAll(root.data.routingTables)

  for (const table of tables) {
    if (!table) {
      continue
    }
    
    const routes  = await Aeppic.getAll(table.data.routes)

    for (const route of routes) {
      if (route && route.data.route) {
        try {
          const pattern = new UrlPattern(route.data.route)
          const match = pattern.match(urlWithJustPath)

          if (match) {
            return { route, params: match }
          }
        } catch (error) {
          Aeppic.Log.error('Error parsing route', route.id, route.data.route, error)
        }
      }
    }
  }

  return null
}

function removeQueryOrHash(url) {
  const queryIndex = url.indexOf('?') 
  const hashIndex = url.indexOf('#') 

  const startOfQueryOrHash = queryIndex >= 0 ? queryIndex : hashIndex

  if (startOfQueryOrHash >= 0) {
    return url.slice(0, startOfQueryOrHash)
  } else {
    return url
  }
}

function removeBaseFromUrl(url) {
  if (url.indexOf('/') === 0) {
    return url
  } else {
    const indexOfHost = url.indexOf('://') + 3
    const urlAfterProtocol = url.slice(indexOfHost)
    const indexOfLimitedUrl = urlAfterProtocol.indexOf('/')
    return urlAfterProtocol.slice(indexOfLimitedUrl)
  }
}
