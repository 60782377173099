import EventEmitter from './event-emitter-implementation.js'

export { EventEmitter }

export interface IEventEmitter {
 
  /**
   * Adds a listener function to the specified event.
   * The listener will not be added if it is a duplicate.
   * If the listener returns true then it will be removed after it is called.
   * If you pass a regular expression as the event name then the listener will be added to all events that match it.
   *
   * @param {string|RegExp} event Name of the event to attach the listener to.
   * @param {Function} listener Method to be called when the event is emitted.
   * If the function returns true then it will be removed after calling.
   * @return {EventEmitter} Current instance of EventEmitter for chaining.
   */
  addListener(event: string, listener: Function): EventEmitter

  /**
   * Adds a listener function to the specified event.
   * The listener will not be added if it is a duplicate.
   * If the listener returns true then it will be removed after it is called.
   * If you pass a regular expression as the event name then the listener will be added to all events that match it.
   *
   * @param {string|RegExp} event Name of the event to attach the listener to.
   * @param {Function} listener Method to be called when the event is emitted.
   * If the function returns true then it will be removed after calling.
   * @return {EventEmitter} Current instance of EventEmitter for chaining.
   */
  addListener(event: RegExp, listener: Function): EventEmitter

  /**
   * Adds a listener function to the specified event.
   * The listener will not be added if it is a duplicate.
   * If the listener returns true then it will be removed after it is called.
   * If you pass a regular expression as the event name then the listener will be added to all events that match it.
   *
   * @param {string|RegExp} event Name of the event to attach the listener to.
   * @param {Function} listener Method to be called when the event is emitted.
   * If the function returns true then it will be removed after calling.
   * @return {EventEmitter} Current instance of EventEmitter for chaining.
   */
  on(event: string, listener: Function): EventEmitter

  /**
   * Adds a listener function to the specified event.
   * The listener will not be added if it is a duplicate.
   * If the listener returns true then it will be removed after it is called.
   * If you pass a regular expression as the event name then the listener will be added to all events that match it.
   *
   * @param {string|RegExp} event Name of the event to attach the listener to.
   * @param {Function} listener Method to be called when the event is emitted.
   * If the function returns true then it will be removed after calling.
   * @return {EventEmitter} Current instance of EventEmitter for chaining.
   */
  on(event: RegExp, listener: Function): EventEmitter

  /**
   * Removes a listener function from the specified event.
   * When passed a regular expression as the event name, it will remove the listener from all events that match it.
   *
   * @param {String|RegExp} event Name of the event to remove the listener from.
   * @param {Function} listener Method to remove from the event.
   * @return {EventEmitter} Current instance of EventEmitter for chaining.
   */
  off(event: string, listener: Function): EventEmitter

  /**
   * Removes a listener function from the specified event.
   * When passed a regular expression as the event name, it will remove the listener from all events that match it.
   *
   * @param {String|RegExp} event Name of the event to remove the listener from.
   * @param {Function} listener Method to remove from the event.
   * @return {EventEmitter} Current instance of EventEmitter for chaining.
   */
  off(event: RegExp, listener: Function): EventEmitter
  /**
   * Semi-alias of addListener. It will add a listener that will be
   * automatically removed after it's first execution.
   *
   * @param event {string|RegExp} Name of the event to attach the listener to.
   * @param listener {Function} Method to be called when the event is emitted.
   * If the function returns true then it will be removed after calling.
   * @return {EventEmitter} Current instance of EventEmitter for chaining.
   */
  once(event: string, listener: Function): EventEmitter

  /**
   * Semi-alias of addListener. It will add a listener that will be
   * automatically removed after it's first execution.
   *
   * @param event {string|RegExp} Name of the event to attach the listener to.
   * @param listener {Function} Method to be called when the event is emitted.
   * If the function returns true then it will be removed after calling.
   * @return {EventEmitter} Current instance of EventEmitter for chaining.
   */
  once(event: RegExp, listener: Function): EventEmitter
 
  /**
   * Removes a listener function from the specified event.
   * When passed a regular expression as the event name, it will remove the listener from all events that match it.
   *
   * @param {String|RegExp} event Name of the event to remove the listener from.
   * @param {Function} listener Method to remove from the event.
   * @return {EventEmitter} Current instance of EventEmitter for chaining.
   */
  removeListener(event: string, listener: Function): EventEmitter

  /**
   * Removes a listener function from the specified event.
   * When passed a regular expression as the event name, it will remove the listener from all events that match it.
   *
   * @param {String|RegExp} event Name of the event to remove the listener from.
   * @param {Function} listener Method to remove from the event.
   * @return {EventEmitter} Current instance of EventEmitter for chaining.
   */
  removeListener(event: RegExp, listener: Function): EventEmitter

  /**
   * Alias of removeEvent.
   *
   * Added to mirror the node API.
   */
  removeAllListeners(event?: string): EventEmitter

  /**
   * Alias of removeEvent.
   *
   * Added to mirror the node API.
   */
  removeAllListeners(event?: RegExp): EventEmitter

  /**
   * Subtly different from emitEvent in that it will pass its arguments on to the listeners,
   * as opposed to taking a single array of arguments to pass on.
   * As with emitEvent, you can pass a regex in place of the event name to emit to all events that match it.
   *
   * @param {String|RegExp} event Name of the event to emit and execute listeners for.
   * @param {... any[]} args Optional additional arguments to be passed to each listener.
   * @return {EventEmitter} Current instance of EventEmitter for chaining.
   */
  emit(event: string, ...args: any[]): EventEmitter

  /**
   * Subtly different from emitEvent in that it will pass its arguments on to the listeners,
   * as opposed to taking a single array of arguments to pass on.
   * As with emitEvent, you can pass a regex in place of the event name to emit to all events that match it.
   *
   * @param {String|RegExp} event Name of the event to emit and execute listeners for.
   * @param {... any[]} args Optional additional arguments to be passed to each listener.
   * @return {EventEmitter} Current instance of EventEmitter for chaining.
   */
  emit(event: RegExp, ...args: any[]): EventEmitter
}

