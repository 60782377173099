// export class QueryBuilder {
import * as Types from '@aeppic/types'

import getNow from  '@aeppic/shared/now'

import * as QueryTypes from './types.js'
import { isReference } from 'model/is.js'

export type ScopedQueryMatchOptions = QueryTypes.MatchOptions & {
  allowUnscopedQuery?: boolean
}

export interface SubQueryDefinitionFunction {
  (subQueryBuilder: QueryBuilder): void
}

type QueryScope = {
  field: string
  value: string
}

export function isQueryBuilder(builder: any): builder is QueryBuilder {
  return builder instanceof QueryBuilder
}

export function toQuery(query: QueryTypes.Query|QueryBuilder): QueryTypes.Query {
  if (isQueryBuilder(query)) {
    return query.toQuery()
  }
  return query
}

type DocumentMinimalReference = {
  id: Types.DocumentId
}

export class QueryScopes {
  constructor(private _defaultParentId?: string) {}

  global(options: QueryTypes.MatchOptions = {}) {
    return new ScopedQueryBuilder(null, { ...options, allowUnscopedQuery: true })
  }

  // Ancestors(matchOptions?: QueryTypes.MatchOptions): ScopedQueryBuilder
  // Ancestors(rootDocumentId: Types.DocumentId, matchOptions?: QueryTypes.MatchOptions): ScopedQueryBuilder
  // Ancestors(arg1: QueryTypes.MatchOptions|Types.DocumentId, matchOptions?: QueryTypes.MatchOptions): ScopedQueryBuilder {
  //   let rootDocumentId = this._defaultParentId
    
  //   if (typeof arg1 === 'string') {
  //     rootDocumentId = arg1
  //   } else {
  //     matchOptions = arg1
  //   }

  //   if (!rootDocumentId) {
  //     throw new Error('Missing parentId')
  //   }

  //   const scope: QueryScope = {
  //     field: 'id',
  //     value: new QueryBuilder().where('id').is(rootDocumentId).select(d => d.a)
  //   }

  //   return new ScopedQueryBuilder(scope, matchOptions)
  // }
  
  children(matchOptions?: QueryTypes.MatchOptions): ScopedQueryBuilder
  children(parent: DocumentMinimalReference, matchOptions?: QueryTypes.MatchOptions): ScopedQueryBuilder
  children(parentId: Types.DocumentId, matchOptions?: QueryTypes.MatchOptions): ScopedQueryBuilder
  children(arg1: QueryTypes.MatchOptions|Types.DocumentId|DocumentMinimalReference, matchOptions?: QueryTypes.MatchOptions): ScopedQueryBuilder {
    let parentId = this._defaultParentId
    
    if (typeof arg1 === 'string') {
      parentId = arg1
    } else if (arg1 && 'id' in arg1) {
      parentId = arg1.id
    } else {
      matchOptions = arg1 as any
    }

    if (!parentId) {
      throw new Error('Missing parentId')
    }

    const childrenCondition: QueryScope = {
      field: 'p',
      value: parentId
    }

    return new ScopedQueryBuilder(childrenCondition, matchOptions)
  }

  descendants(matchOptions?: QueryTypes.MatchOptions): ScopedQueryBuilder
  descendants(ancestor: DocumentMinimalReference, matchOptions?: QueryTypes.MatchOptions): ScopedQueryBuilder
  descendants(ancestorId: Types.DocumentId, matchOptions?: QueryTypes.MatchOptions): ScopedQueryBuilder
  descendants(arg1: QueryTypes.MatchOptions|Types.DocumentId|DocumentMinimalReference, matchOptions?: QueryTypes.MatchOptions): ScopedQueryBuilder {
    let ancestorId = this._defaultParentId
    
    if (typeof arg1 === 'string') {
      ancestorId = arg1
    } else if (arg1 && 'id' in arg1) {
      ancestorId = arg1.id
    } else {
      matchOptions = arg1 as any
    }

    if (!ancestorId) {
      throw new Error('Missing parentId')
    }

    const descendantCondition: QueryScope = {
      field: 'a',
      value: ancestorId
    }

    return new ScopedQueryBuilder(descendantCondition, matchOptions)
  }
}

export const Scopes = new QueryScopes()
export type IScopes = typeof Scopes

type QueryBuilderOptions = { queryParameters?: Parameters } & QueryTypes.MatchOptions

class Parameters {
  private _parameterCount = 0
  private _parameters: QueryTypes.QueryParameters = []

  add(value: string|number|boolean|(string|number|boolean)[]): QueryTypes.QueryParameterReference {
    const nextParameterId = this._parameterCount ++
    
    const parameter = { id: nextParameterId }
    this._parameters[nextParameterId] = value

    return parameter
  }

  toQueryParameters(): QueryTypes.QueryParameters {
    return this._parameters
  }
}

export class QueryBuilder {
  private _expression: QueryTypes.MatchExpression

  private _matchOptions: QueryTypes.MatchOptions
  private _finalized = false
  private _appendNextConditionAsOr = false
  private _parameters: Parameters
  
  constructor({ queryParameters, ...matchOptions }: QueryBuilderOptions = {}) {
    this._expression = {
      conditions: [],
    } 
    this._matchOptions = matchOptions

    if (!this._matchOptions.nowUtcTz) {
      this._matchOptions.nowUtcTz = getNow().isoTz
    }

    this._parameters = queryParameters || new Parameters()
  }

  // TODO: Include clone with matching spec
  //
  // clone(): QueryBuilder {
  //   const clone = new QueryBuilder()
  //   clone._expression = JSON.parse(JSON.stringify(this._expression))
  //   clone._matchOptions = JSON.parse(JSON.stringify(this._matchOptions))
  //   clone._finalized = JSON.parse(JSON.stringify(this._finalized))
  //   clone._appendNextConditionAsOr = JSON.parse(JSON.stringify(this._appendNextConditionAsOr))
  //   clone._parameters = JSON.parse(JSON.stringify(this._parameters))
  //   return clone
  // }
  
  addParameter(value: string|number|boolean|(string|number|boolean)[]): QueryTypes.QueryParameterReference {
    assertNotFinalized(this)
    return this._parameters.add(value)
  }

  get finalized() {
    return this._finalized
  }

  where(fieldPath: string): FieldCondition {
    assertNotFinalized(this)
    return new FieldCondition(fieldPath, this)
  }

  form(formId: string): QueryBuilder {
    return this.where('f.id').is(formId)    
  }

  forms(formIds: string[]): QueryBuilder {
    return this.where('f.id').isOneOf(formIds)
  }

  stamp(stampTypeId: string) {
    return this.form('stamp').where('data.type.id').is(stampTypeId)
  }

  stamps(stampTypeIds: string[]|undefined) {
    let q = this.form('stamp')
    
    if (stampTypeIds) {
      return q.where('data.type.id').isOneOf(stampTypeIds)
    } else {
      return q
    }
  }

  stamped(stampTypeId: string|string[]) {
    const stampIdsToCheck = Array.isArray(stampTypeId) ? stampTypeId : [stampTypeId]
    return this.where('stamps.type.id').isOneOf(stampIdsToCheck)
  }

  get or(): QueryBuilder {
    assertNotFinalized(this)
    this._appendNextConditionAsOr = true
    return this
  }

  subQuery(subQueryDefinitionFunction: SubQueryDefinitionFunction): QueryBuilder {
    assertNotFinalized(this)

    const subQueryBuilder = new QueryBuilder({ queryParameters: this._parameters })
    subQueryDefinitionFunction(subQueryBuilder)
    
    const subQuery = subQueryBuilder.toQuery()
    
    if (subQuery.graph.conditions.length === 0) {
      return this
    }

    if (isAndExpression(subQuery.graph) && isAndExpression(this._expression)) {
      this._expression.conditions.push(...subQuery.graph.conditions)
    } else {
      return this.appendCondition(subQuery.graph)
    }

    return this
  }

  appendCondition(condition: QueryTypes.Condition) {
    assertNotFinalized(this)

    if (!('operator' in this._expression)) {
      if (this._appendNextConditionAsOr) {
        this._expression = {
          operator: 'OR',
          conditions: this._expression.conditions
        }
        this._appendNextConditionAsOr = false
      } else {
        if (this._expression.conditions.length > 0) {
          this._expression = {
            operator: 'AND',
            conditions: this._expression.conditions
          }
        }
      }
      this._expression.conditions.push(condition)
    } else if (this._appendNextConditionAsOr) {
      if (this._expression.operator === 'AND') {
        const previousANDExpression = this._expression
        this._expression = {
          operator: 'OR',
          conditions: [
            previousANDExpression,
            condition,
          ]
        }
      } else {
        this._expression.conditions.push(condition)
      }
      this._appendNextConditionAsOr = false
    } else if (this._expression.operator === 'OR') {
      const lastCondition = this._expression.conditions[this._expression.conditions.length - 1]

      if (QueryTypes.isFieldMatchNode(lastCondition)) {
        const newAndCondition: QueryTypes.MatchExpressionMultiMatch = {
          operator: 'AND',
          conditions: [
            lastCondition,
            condition
          ]
        }
        this._expression.conditions[this._expression.conditions.length - 1] = newAndCondition
      } else {
        lastCondition.conditions.push(condition)
      }
    } else {
      // this._expression.operator === 'AND'
      this._expression.conditions.push(condition)
    }

    return this
  }
  
  toQuery(): QueryTypes.Query {
    this._finalized = true
    return { graph: this._expression, parameters: this._parameters.toQueryParameters(), options: this._matchOptions }
  }
}

export class ScopedQueryBuilder extends QueryBuilder {
  private _scopeField: string
  private _scopeParameter: QueryTypes.QueryParameterReference
  
  constructor(scope: QueryScope, { allowUnscopedQuery = false, ...matchOptions }: ScopedQueryMatchOptions = {} ) {
    super(matchOptions)

    if (scope) {
      this._scopeField = scope.field
      this._scopeParameter = this.addParameter(scope.value)
    } else if (!allowUnscopedQuery) {
      throw new Error('Missing query scope. Either supply it or use option allowUnscopedQuery option')
    }
  }
  
  toQuery(): QueryTypes.Query {
    if (this._scopeField) {
      const query = super.toQuery()
      ensureFieldMatchesParameter(query, this._scopeField, this._scopeParameter)
      return query
    } else {
      return super.toQuery()
    }
  }
}

function ensureFieldMatchesParameter(query: QueryTypes.Query, field: string, parameter: QueryTypes.QueryParameterReference) {
  const { graph } = query

  const newFieldCondition: QueryTypes.FieldMatchNode = {
    field,
    term: parameter
  }

  if (isAndExpression(graph)) {
    graph.conditions.unshift(newFieldCondition)

    if (graph.conditions.length > 1) {
      (graph as QueryTypes.MatchExpressionMultiMatch).operator = 'AND'
    }
  } else {
    query.graph = {
      operator: 'AND',
      conditions: [
        newFieldCondition,
        {
          operator: 'OR',
          conditions: graph.conditions
        }
      ]
    }
  }
}

class FieldCondition {
  constructor(private _fieldPath: string, private _builder: QueryBuilder) {}

  is(value: number|string|boolean) {
    const parameter = this._builder.addParameter(value)
    // const parameter = this._builder.addParameter(value.toString())
    return this._isParameter(parameter)
  }

  isOneOf(values: (number|string|boolean)[]) {
    const nonNullValues = values.filter(v => v != null)
    const parameter = this._builder.addParameter(nonNullValues)
    return this._isParameter(parameter)
  }
  
  contains(value: string) {
    const parameter = this._builder.addParameter('*' + value + '*')
    return this._isParameter(parameter)
  } 

  containsOneOf(values: string[]) {
    const parameter = this._builder.addParameter(values.map(v => '*' + v + '*'))
    return this._isParameter(parameter)
  }

  startsWith(value: string) {
    const parameter = this._builder.addParameter(value + '*')
    return this._isParameter(parameter)
  }

  startsWithOneOf(values: string[]) {
    const parameter = this._builder.addParameter(values.map(v => v + '*'))
    return this._isParameter(parameter)
  }

  endsWith(value: string) {
    const parameter = this._builder.addParameter('*' + value)
    return this._isParameter(parameter)
  }

  endsWithOneOf(values: string[]) {
    const parameter = this._builder.addParameter(values.map(v => '*' + v))
    return this._isParameter(parameter)
  }

  private _isParameter(parameter: QueryTypes.QueryParameterReference) {
    const condition = {
      field: this._fieldPath,
      term: parameter
    }

    return this._builder.appendCondition(condition)
  }

  isInRange(minValue: number|string, maxValue: number|string) {
    const termMin = minValue == null ? '*' : minValue.toString()
    const termMax = maxValue == null ? '*' : maxValue.toString()

    const minVariable = this._builder.addParameter(termMin)
    const maxVariable = this._builder.addParameter(termMax)

    const condition: QueryTypes.Condition = {
      field: this._fieldPath,
      term_min: minVariable,
      term_max: maxVariable,
    }

    return this._builder.appendCondition(condition)
  }

  isTruthy() {
    return this.isInRange('*', '*')
  }
}

function assertNotFinalized(object: { finalized: boolean } ) {
  if (object.finalized) {
    throw new Error('Cannot perform this operation when already finalized')
  }
}

function isAndExpression(expression: QueryTypes.MatchExpression) {
  if (!('operator' in expression)) {
    return true
  }

  if (expression.operator === 'OR') {
    return false
  } else {
    return true
  }
}

// function isOrExpression(expression: QueryTypes.MatchExpression) {
//   return !isAndExpression(expression)
// }

// function assert(condition: any, message?: string) {
//   if (!condition) {
//     throw new Error(message||'Assertion failed')
//   }
// }

