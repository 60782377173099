
  import tippy from 'tippy.js'
  import { findAncestorWithClass } from '../utils/find-ancestor'


  export default {
  /* tslint:disable */
  render () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],ref:"popup",staticClass:"ae-popup",style:(_vm.style)},[_vm._t("default",null,{"close":_vm.close,"position":_vm.position,"data":_vm.data}),_vm._v(" "),(_vm.visible)?_vm._t("popup",null,{"close":_vm.close,"position":_vm.position,"data":_vm.data}):_vm._e()],2)])},
  staticRenderFns: [],
  /* tslint:enable */
  
    data: () => ({
      visible: false,
      data: null,
      style: {},
    }),
    props: {
      placement: {
        type: String,
        default: 'auto'
      },
      disabled: {
        type: Boolean,
        default: false
      },
      onClose: {
        type: Function,
        default: null
      },
      closeDelay: {
        type: Number,
        default: 0
      },
      clickable: {
        type: Boolean,
        default: true,
      },
      maxWidth: {
        type: null,
        default: 350,
      },
      arrow: {
        default: true,
      },
      theme: {
        type: String,
        default: 'ae-tippy-theme',
      },
    },
    watch: {
      disabled() {
        if (this.disabled) {
          this.closeImmediately()
        }
      },
    },
    methods: {
      toggle(event: Event, data: any) {
        if (this.visible) {
          this.close()
        } else {
          this.show(event, data)
        }
      },
      close() {
        if (this.closeDelay) {
          this._closeTimeout = setTimeout(() => {
              this.closeImmediately()
          }, this.closeDelay)
        } else {
          this.closeImmediately()
        }
      },
      open(...args) {
        console.warn('deprecated: Use show instead')
        this.show(...args)
      },
      hide() {
        this.close()
      },
      show(event: Event, data: any) {
        if (this.disabled) {
          return
        }

        if (!event) {
          console.error('Provide a target to AePopup.show')
          return
        }

        const target = event.target

        let targetNode = target

        if (typeof target === 'string') {
          // is CSS selector
          targetNode = document.querySelector(target)
        }

        this.data = data

        this._show(targetNode)
      },
      showAtPosition(event: MouseEvent = null, data: any) {
        console.warn('deprecated: use show instead of showAtPosition')

        this.show(event, data)
      },
      cancelClose() {
        if (this._closeTimeout) {
          clearTimeout(this._closeTimeout)
          this._closeTimeout = null
        }
      },
      position() {
        if (this._tippy?.popperInstance) {
          this._tippy.popperInstance.update()
        }
      },
    },
    created() {
      this._closeTimeout = null

      this._show = async (target) => {
        this.cancelClose()

        if (!this._tippy) {
          // this._containingPopup = findAncestorWithClass(target, 'ae-popup')

          this._tippy = tippy(target, {
            content: this.$refs.popup,
            theme: this.theme,
            interactive: this.clickable,
            delay: [50, 100],
            trigger: 'manual',
            maxWidth: this.maxWidth,
            interactiveBorder: 50,
            arrow: this.arrow,
            placement: this.placement,
            hideOnClick: 'toggle',
            appendTo: () => 
               this.$refs.popup.ownerDocument.querySelector('.ae-dialog-region') || this.$refs.popup.ownerDocument.querySelector('.ae-root') || this.$refs.popup.ownerDocument,
            onShown: () => {
              this.position()

              this.$emit('show')
            },
            onClickOutside: (instance, event) => {
              // This prevents closing the parent popup when nesting popups (because they are not actually nested, but siblings)
              if (findAncestorWithClass(event.target, 'ae-popup')) {
                return
              }

              this.close()
            },
          })
        }
        
        this.visible = true

        this._tippy.show()
      }
        
      this.closeImmediately = () => {
        this.cancelClose()

        this.visible = false
        this._baseNode = null

        this.$nextTick(() => {
          this.unregister()

          this.$emit('close')
        })
      }

      this.unregister = () => {
        if (this._tippy) {
          this._tippy.destroy()
          this._tippy = null
        }
      }
    },
    beforeDestroy() {
      this.closeImmediately()
      this.unregister()
    }
  }
