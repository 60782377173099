export default  
 /*
 * Generated by PEG.js 0.10.0.
 *
 * http://pegjs.org/
 */
(function() {
  "use strict";

  function peg$subclass(child, parent) {
    function ctor() { this.constructor = child; }
    ctor.prototype = parent.prototype;
    child.prototype = new ctor();
  }

  function peg$SyntaxError(message, expected, found, location) {
    this.message  = message;
    this.expected = expected;
    this.found    = found;
    this.location = location;
    this.name     = "SyntaxError";

    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, peg$SyntaxError);
    }
  }

  peg$subclass(peg$SyntaxError, Error);

  peg$SyntaxError.buildMessage = function(expected, found) {
    var DESCRIBE_EXPECTATION_FNS = {
          literal: function(expectation) {
            return "\"" + literalEscape(expectation.text) + "\"";
          },

          "class": function(expectation) {
            var escapedParts = "",
                i;

            for (i = 0; i < expectation.parts.length; i++) {
              escapedParts += expectation.parts[i] instanceof Array
                ? classEscape(expectation.parts[i][0]) + "-" + classEscape(expectation.parts[i][1])
                : classEscape(expectation.parts[i]);
            }

            return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
          },

          any: function(expectation) {
            return "any character";
          },

          end: function(expectation) {
            return "end of input";
          },

          other: function(expectation) {
            return expectation.description;
          }
        };

    function hex(ch) {
      return ch.charCodeAt(0).toString(16).toUpperCase();
    }

    function literalEscape(s) {
      return s
        .replace(/\\/g, '\\\\')
        .replace(/"/g,  '\\"')
        .replace(/\0/g, '\\0')
        .replace(/\t/g, '\\t')
        .replace(/\n/g, '\\n')
        .replace(/\r/g, '\\r')
        .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
        .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
    }

    function classEscape(s) {
      return s
        .replace(/\\/g, '\\\\')
        .replace(/\]/g, '\\]')
        .replace(/\^/g, '\\^')
        .replace(/-/g,  '\\-')
        .replace(/\0/g, '\\0')
        .replace(/\t/g, '\\t')
        .replace(/\n/g, '\\n')
        .replace(/\r/g, '\\r')
        .replace(/[\x00-\x0F]/g,          function(ch) { return '\\x0' + hex(ch); })
        .replace(/[\x10-\x1F\x7F-\x9F]/g, function(ch) { return '\\x'  + hex(ch); });
    }

    function describeExpectation(expectation) {
      return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
    }

    function describeExpected(expected) {
      var descriptions = new Array(expected.length),
          i, j;

      for (i = 0; i < expected.length; i++) {
        descriptions[i] = describeExpectation(expected[i]);
      }

      descriptions.sort();

      if (descriptions.length > 0) {
        for (i = 1, j = 1; i < descriptions.length; i++) {
          if (descriptions[i - 1] !== descriptions[i]) {
            descriptions[j] = descriptions[i];
            j++;
          }
        }
        descriptions.length = j;
      }

      switch (descriptions.length) {
        case 1:
          return descriptions[0];

        case 2:
          return descriptions[0] + " or " + descriptions[1];

        default:
          return descriptions.slice(0, -1).join(", ")
            + ", or "
            + descriptions[descriptions.length - 1];
      }
    }

    function describeFound(found) {
      return found ? "\"" + literalEscape(found) + "\"" : "end of input";
    }

    return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
  };

  function peg$parse(input, options) {
    options = options !== void 0 ? options : {};

    var peg$FAILED = {},

        peg$startRuleIndices = { start: 0 },
        peg$startRuleIndex   = 0,

        peg$consts = [
          function(node) {
                return node[0];
            },
          function() {
                return {};
            },
          function(left, operator, right) {
                var node= {
                    'left':left
                    };

                var right =
                        right.length == 0
                        ? null
                        : right[0]['right'] == null
                            ? right[0]['left']
                            : right[0];

                if (right != null)
                {
                    node['operator'] = operator==''? '<implicit>' : operator[0];
                    node['right'] = right;
                }

                return node;
            },
          function(field_exp) {
                return field_exp;
            },
          "(",
          peg$literalExpectation("(", false),
          ")",
          peg$literalExpectation(")", false),
          function(op, fieldname, range) {
                range['field'] =
                    fieldname == ''
                        ? "<implicit>"
                        : fieldname;
            range['fieldPrefix'] = op;
                return range;
            },
          function(op, fieldname, node) {
                node['field'] = fieldname;
                node['fieldPrefix'] = op;
                return node;
            },
          function(op, fieldname, term) {
                var fieldexp = {
                  field:
                        fieldname == ''
                            ? "<implicit>"
                            : fieldname,
                  fieldPrefix: op
                     
                };

                for(var key in term)
                    fieldexp[key] = term[key];

                return fieldexp;
            },
          ":",
          peg$literalExpectation(":", false),
          function(fieldname) {
                return fieldname;
            },
          ".",
          peg$literalExpectation(".", false),
          function(head, tail) {
              return head + '.' + tail;
            },
          /^[a-z_]/i,
          peg$classExpectation([["a", "z"], "_"], false, true),
          /^[a-z0-9_]/i,
          peg$classExpectation([["a", "z"], ["0", "9"], "_"], false, true),
          function(initialCharacter, otherCharacters) { return initialCharacter+otherCharacters.join(''); },
          function(op, term, proximity, boost) {
                var result = { 
                    'term': term.term,
                    'termQuotes': term.termQuotes
                };

                if('' != proximity)
                {
                    result['proximity'] = proximity;
                }
                if('' != boost)
                {
                    result['boost'] = boost;
                }
                if('' != op)
                {
                    result['termPrefix'] = op;
                }

                return result;
            },
          function(op, term, similarity, boost) {
                var result = { 
                    'term': term.term,
                    'termQuotes': term.termQuotes
                };

                if('' != similarity)
                {
                    result['similarity'] = similarity;
                }
                if('' != boost)
                {
                    result['boost'] = boost;
                }
                if('' != op)
                {
                    result['termPrefix'] = op;
                }
                return result;
            },
          /^[0-9\-T:Z]/,
          peg$classExpectation([["0", "9"], "-", "T", ":", "Z"], false, false),
          function(term) { return REGEXP_ISO_DATE.test(term.join('')) },
          /^[a-z0-9\/+\-]/i,
          peg$classExpectation([["a", "z"], ["0", "9"], "/", "+", "-"], false, true),
          function(term, modifiers) {
                return {
                    term: term.join('') + modifiers.join(''),
                    termQuotes: 'none'
                }
            },
          /^[^: \t\r\n\f{}()"'\^~[\]]/,
          peg$classExpectation([":", " ", "\t", "\r", "\n", "\f", "{", "}", "(", ")", "\"", "'", "^", "~", "[", "]"], true, false),
          function(term) {
                return {
                    term: term.join(''),
                    termQuotes: 'none'
                };
            },
          "'",
          peg$literalExpectation("'", false),
          /^[^']/,
          peg$classExpectation(["'"], true, false),
          function(term) {
                return {
                    term: term.join(''),
                    termQuotes: 'single'
                };
            },
          "\"",
          peg$literalExpectation("\"", false),
          /^[^"]/,
          peg$classExpectation(["\""], true, false),
          function(term) {
                return {
                    term: term.join(''),
                    termQuotes: 'double'
                };
            },
          "~",
          peg$literalExpectation("~", false),
          function(proximity) {
                return proximity;
            },
          "^",
          peg$literalExpectation("^", false),
          function(boost) {
                return boost;
            },
          function(fuzziness) {
                return fuzziness == '' ? 0.5 : fuzziness;
            },
          "0.",
          peg$literalExpectation("0.", false),
          /^[0-9]/,
          peg$classExpectation([["0", "9"]], false, false),
          function(val) {
                return parseFloat("0." + val.join(''));
            },
          function(val) {
                return parseInt(val.join(''));
            },
          "[",
          peg$literalExpectation("[", false),
          "TO",
          peg$literalExpectation("TO", false),
          "]",
          peg$literalExpectation("]", false),
          function(term_min, term_max) {
                return {
                    'term_min': term_min.term,
                    'term_max': term_max.term,
                    'inclusive': true
                };
            },
          "{",
          peg$literalExpectation("{", false),
          "}",
          peg$literalExpectation("}", false),
          function(term_min, term_max) {
                return {
                    'term_min': term_min.term,
                    'term_max': term_max.term,
                    'inclusive': false
                };
            },
          function(operator) {
                return operator;
            },
          "AND NOT",
          peg$literalExpectation("AND NOT", false),
          function() { return 'NOT' },
          "OR",
          peg$literalExpectation("OR", false),
          "AND",
          peg$literalExpectation("AND", false),
          "NOT",
          peg$literalExpectation("NOT", false),
          "||",
          peg$literalExpectation("||", false),
          function() { return 'OR'; },
          "&&",
          peg$literalExpectation("&&", false),
          function() { return 'AND'; },
          "+",
          peg$literalExpectation("+", false),
          "-",
          peg$literalExpectation("-", false),
          peg$otherExpectation("whitespace"),
          /^[ \t\r\n\f]/,
          peg$classExpectation([" ", "\t", "\r", "\n", "\f"], false, false),
          peg$anyExpectation()
        ],

        peg$bytecode = [
          peg$decode("%$;90#*;9&/>#$;!/&#0#*;!&&&#/($8\": \"! )(\"'#&'#.F &%$;90#*;9&/& 8!:!! ).. &%;:/& 8!:!! )"),
          peg$decode("%;\"/J#$;40#*;4&/:$$;!0#*;!&/*$8#:\"##\"! )(#'#(\"'#&'#"),
          peg$decode("%;$/8#$;90#*;9&/($8\":#\"!!)(\"'#&'#.# &;#"),
          peg$decode("%2$\"\"6$7%/]#$;!/&#0#*;!&&&#/G$2&\"\"6&7'/8$$;90#*;9&/($8$: $!\")($'#(#'#(\"'#&'#"),
          peg$decode("%;7.\" &\"/A#;%.\" &\"/3$;3/*$8#:(##\"! )(#'#(\"'#&'#.~ &%;7.\" &\"/<#;%/3$;#/*$8#:)##\"! )(#'#(\"'#&'#.P &%;7.\" &\"/A#;%.\" &\"/3$;(/*$8#:*##\"! )(#'#(\"'#&'#"),
          peg$decode("%;&/G#2+\"\"6+7,/8$$;90#*;9&/($8#:-#!\")(#'#(\"'#&'#"),
          peg$decode("%;'/A#2.\"\"6.7//2$;&/)$8#:0#\"\" )(#'#(\"'#&'#.# &;'"),
          peg$decode("%41\"\"5!72/E#$43\"\"5!740)*43\"\"5!74&/)$8\":5\"\"! )(\"'#&'#"),
          peg$decode("%;7.\" &\"/`#;*/W$;-.\" &\"/I$;..\" &\"/;$$;90#*;9&/+$8%:6%$$#\"!)(%'#($'#(#'#(\"'#&'#.o &%;7.\" &\"/`#;)/W$;/.\" &\"/I$;..\" &\"/;$$;90#*;9&/+$8%:7%$$#\"!)(%'#($'#(#'#(\"'#&'#"),
          peg$decode("%$48\"\"5!79/,#0)*48\"\"5!79&&&#/Y#9:: ! -\"\"&!&#/E$$4;\"\"5!7<0)*4;\"\"5!7<&/)$8#:=#\"\" )(#'#(\"'#&'#.` &%%<;5=.##&&!&'#/J#$4>\"\"5!7?/,#0)*4>\"\"5!7?&&&#/($8\":@\"! )(\"'#&'#"),
          peg$decode(";,.# &;+"),
          peg$decode("%2A\"\"6A7B/Y#$4C\"\"5!7D/,#0)*4C\"\"5!7D&&&#/7$2A\"\"6A7B/($8#:E#!!)(#'#(\"'#&'#"),
          peg$decode("%2F\"\"6F7G/Y#$4H\"\"5!7I/,#0)*4H\"\"5!7I&&&#/7$2F\"\"6F7G/($8#:J#!!)(#'#(\"'#&'#"),
          peg$decode("%2K\"\"6K7L/1#;2/($8\":M\"! )(\"'#&'#"),
          peg$decode("%2N\"\"6N7O/1#;0/($8\":P\"! )(\"'#&'#"),
          peg$decode("%2K\"\"6K7L/6#;1.\" &\"/($8\":Q\"! )(\"'#&'#"),
          peg$decode(";1.# &;2"),
          peg$decode("%2R\"\"6R7S/J#$4T\"\"5!7U/,#0)*4T\"\"5!7U&&&#/($8\":V\"! )(\"'#&'#"),
          peg$decode("%$4T\"\"5!7U/,#0)*4T\"\"5!7U&&&#/' 8!:W!! )"),
          peg$decode("%2X\"\"6X7Y/\x7F#;)/v$$;90#*;9&/f$2Z\"\"6Z7[/W$$;9/&#0#*;9&&&#/A$;)/8$2\\\"\"6\\7]/)$8':^'\"%!)(''#(&'#(%'#($'#(#'#(\"'#&'#.\x8F &%2_\"\"6_7`/\x7F#;)/v$$;90#*;9&/f$2Z\"\"6Z7[/W$$;9/&#0#*;9&&&#/A$;)/8$2a\"\"6a7b/)$8':c'\"%!)(''#(&'#(%'#($'#(#'#(\"'#&'#"),
          peg$decode("%$;90#*;9&/\\#%<;6=.##&&!&'#/G$;5/>$$;9/&#0#*;9&&&#/($8$:d$!!)($'#(#'#(\"'#&'#.K &%$;90#*;9&/:#;5/1$;:/($8#:d#!!)(#'#(\"'#&'#"),
          peg$decode("%2e\"\"6e7f/& 8!:g! ).o &2h\"\"6h7i.c &2j\"\"6j7k.W &2l\"\"6l7m.K &%2n\"\"6n7o/& 8!:p! ).4 &%2q\"\"6q7r/& 8!:s! )"),
          peg$decode("%;5/5#;9/,$;5/#$+#)(#'#(\"'#&'#"),
          peg$decode("%$;90#*;9&/1#;8/($8\":d\"! )(\"'#&'#"),
          peg$decode("2t\"\"6t7u.) &2v\"\"6v7w"),
          peg$decode("<$4y\"\"5!7z/,#0)*4y\"\"5!7z&&&#=.\" 7x"),
          peg$decode("%<1\"\"5!7{=.##&&!&'#")
        ],

        peg$currPos          = 0,
        peg$savedPos         = 0,
        peg$posDetailsCache  = [{ line: 1, column: 1 }],
        peg$maxFailPos       = 0,
        peg$maxFailExpected  = [],
        peg$silentFails      = 0,

        peg$result;

    if ("startRule" in options) {
      if (!(options.startRule in peg$startRuleIndices)) {
        throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
      }

      peg$startRuleIndex = peg$startRuleIndices[options.startRule];
    }

    function text() {
      return input.substring(peg$savedPos, peg$currPos);
    }

    function location() {
      return peg$computeLocation(peg$savedPos, peg$currPos);
    }

    function expected(description, location) {
      location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

      throw peg$buildStructuredError(
        [peg$otherExpectation(description)],
        input.substring(peg$savedPos, peg$currPos),
        location
      );
    }

    function error(message, location) {
      location = location !== void 0 ? location : peg$computeLocation(peg$savedPos, peg$currPos)

      throw peg$buildSimpleError(message, location);
    }

    function peg$literalExpectation(text, ignoreCase) {
      return { type: "literal", text: text, ignoreCase: ignoreCase };
    }

    function peg$classExpectation(parts, inverted, ignoreCase) {
      return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
    }

    function peg$anyExpectation() {
      return { type: "any" };
    }

    function peg$endExpectation() {
      return { type: "end" };
    }

    function peg$otherExpectation(description) {
      return { type: "other", description: description };
    }

    function peg$computePosDetails(pos) {
      var details = peg$posDetailsCache[pos], p;

      if (details) {
        return details;
      } else {
        p = pos - 1;
        while (!peg$posDetailsCache[p]) {
          p--;
        }

        details = peg$posDetailsCache[p];
        details = {
          line:   details.line,
          column: details.column
        };

        while (p < pos) {
          if (input.charCodeAt(p) === 10) {
            details.line++;
            details.column = 1;
          } else {
            details.column++;
          }

          p++;
        }

        peg$posDetailsCache[pos] = details;
        return details;
      }
    }

    function peg$computeLocation(startPos, endPos) {
      var startPosDetails = peg$computePosDetails(startPos),
          endPosDetails   = peg$computePosDetails(endPos);

      return {
        start: {
          offset: startPos,
          line:   startPosDetails.line,
          column: startPosDetails.column
        },
        end: {
          offset: endPos,
          line:   endPosDetails.line,
          column: endPosDetails.column
        }
      };
    }

    function peg$fail(expected) {
      if (peg$currPos < peg$maxFailPos) { return; }

      if (peg$currPos > peg$maxFailPos) {
        peg$maxFailPos = peg$currPos;
        peg$maxFailExpected = [];
      }

      peg$maxFailExpected.push(expected);
    }

    function peg$buildSimpleError(message, location) {
      return new peg$SyntaxError(message, null, null, location);
    }

    function peg$buildStructuredError(expected, found, location) {
      return new peg$SyntaxError(
        peg$SyntaxError.buildMessage(expected, found),
        expected,
        found,
        location
      );
    }

    function peg$decode(s) {
      var bc = new Array(s.length), i;

      for (i = 0; i < s.length; i++) {
        bc[i] = s.charCodeAt(i) - 32;
      }

      return bc;
    }

    function peg$parseRule(index) {
      var bc    = peg$bytecode[index],
          ip    = 0,
          ips   = [],
          end   = bc.length,
          ends  = [],
          stack = [],
          params, i;

      while (true) {
        while (ip < end) {
          switch (bc[ip]) {
            case 0:
              stack.push(peg$consts[bc[ip + 1]]);
              ip += 2;
              break;

            case 1:
              stack.push(void 0);
              ip++;
              break;

            case 2:
              stack.push(null);
              ip++;
              break;

            case 3:
              stack.push(peg$FAILED);
              ip++;
              break;

            case 4:
              stack.push([]);
              ip++;
              break;

            case 5:
              stack.push(peg$currPos);
              ip++;
              break;

            case 6:
              stack.pop();
              ip++;
              break;

            case 7:
              peg$currPos = stack.pop();
              ip++;
              break;

            case 8:
              stack.length -= bc[ip + 1];
              ip += 2;
              break;

            case 9:
              stack.splice(-2, 1);
              ip++;
              break;

            case 10:
              stack[stack.length - 2].push(stack.pop());
              ip++;
              break;

            case 11:
              stack.push(stack.splice(stack.length - bc[ip + 1], bc[ip + 1]));
              ip += 2;
              break;

            case 12:
              stack.push(input.substring(stack.pop(), peg$currPos));
              ip++;
              break;

            case 13:
              ends.push(end);
              ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

              if (stack[stack.length - 1]) {
                end = ip + 3 + bc[ip + 1];
                ip += 3;
              } else {
                end = ip + 3 + bc[ip + 1] + bc[ip + 2];
                ip += 3 + bc[ip + 1];
              }

              break;

            case 14:
              ends.push(end);
              ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

              if (stack[stack.length - 1] === peg$FAILED) {
                end = ip + 3 + bc[ip + 1];
                ip += 3;
              } else {
                end = ip + 3 + bc[ip + 1] + bc[ip + 2];
                ip += 3 + bc[ip + 1];
              }

              break;

            case 15:
              ends.push(end);
              ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

              if (stack[stack.length - 1] !== peg$FAILED) {
                end = ip + 3 + bc[ip + 1];
                ip += 3;
              } else {
                end = ip + 3 + bc[ip + 1] + bc[ip + 2];
                ip += 3 + bc[ip + 1];
              }

              break;

            case 16:
              if (stack[stack.length - 1] !== peg$FAILED) {
                ends.push(end);
                ips.push(ip);

                end = ip + 2 + bc[ip + 1];
                ip += 2;
              } else {
                ip += 2 + bc[ip + 1];
              }

              break;

            case 17:
              ends.push(end);
              ips.push(ip + 3 + bc[ip + 1] + bc[ip + 2]);

              if (input.length > peg$currPos) {
                end = ip + 3 + bc[ip + 1];
                ip += 3;
              } else {
                end = ip + 3 + bc[ip + 1] + bc[ip + 2];
                ip += 3 + bc[ip + 1];
              }

              break;

            case 18:
              ends.push(end);
              ips.push(ip + 4 + bc[ip + 2] + bc[ip + 3]);

              if (input.substr(peg$currPos, peg$consts[bc[ip + 1]].length) === peg$consts[bc[ip + 1]]) {
                end = ip + 4 + bc[ip + 2];
                ip += 4;
              } else {
                end = ip + 4 + bc[ip + 2] + bc[ip + 3];
                ip += 4 + bc[ip + 2];
              }

              break;

            case 19:
              ends.push(end);
              ips.push(ip + 4 + bc[ip + 2] + bc[ip + 3]);

              if (input.substr(peg$currPos, peg$consts[bc[ip + 1]].length).toLowerCase() === peg$consts[bc[ip + 1]]) {
                end = ip + 4 + bc[ip + 2];
                ip += 4;
              } else {
                end = ip + 4 + bc[ip + 2] + bc[ip + 3];
                ip += 4 + bc[ip + 2];
              }

              break;

            case 20:
              ends.push(end);
              ips.push(ip + 4 + bc[ip + 2] + bc[ip + 3]);

              if (peg$consts[bc[ip + 1]].test(input.charAt(peg$currPos))) {
                end = ip + 4 + bc[ip + 2];
                ip += 4;
              } else {
                end = ip + 4 + bc[ip + 2] + bc[ip + 3];
                ip += 4 + bc[ip + 2];
              }

              break;

            case 21:
              stack.push(input.substr(peg$currPos, bc[ip + 1]));
              peg$currPos += bc[ip + 1];
              ip += 2;
              break;

            case 22:
              stack.push(peg$consts[bc[ip + 1]]);
              peg$currPos += peg$consts[bc[ip + 1]].length;
              ip += 2;
              break;

            case 23:
              stack.push(peg$FAILED);
              if (peg$silentFails === 0) {
                peg$fail(peg$consts[bc[ip + 1]]);
              }
              ip += 2;
              break;

            case 24:
              peg$savedPos = stack[stack.length - 1 - bc[ip + 1]];
              ip += 2;
              break;

            case 25:
              peg$savedPos = peg$currPos;
              ip++;
              break;

            case 26:
              params = bc.slice(ip + 4, ip + 4 + bc[ip + 3]);
              for (i = 0; i < bc[ip + 3]; i++) {
                params[i] = stack[stack.length - 1 - params[i]];
              }

              stack.splice(
                stack.length - bc[ip + 2],
                bc[ip + 2],
                peg$consts[bc[ip + 1]].apply(null, params)
              );

              ip += 4 + bc[ip + 3];
              break;

            case 27:
              stack.push(peg$parseRule(bc[ip + 1]));
              ip += 2;
              break;

            case 28:
              peg$silentFails++;
              ip++;
              break;

            case 29:
              peg$silentFails--;
              ip++;
              break;

            default:
              throw new Error("Invalid opcode: " + bc[ip] + ".");
          }
        }

        if (ends.length > 0) {
          end = ends.pop();
          ip = ips.pop();
        } else {
          break;
        }
      }

      return stack[0];
    }


         const REGEXP_ISO_DATE = /(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?/
     

    peg$result = peg$parseRule(peg$startRuleIndex);

    if (peg$result !== peg$FAILED && peg$currPos === input.length) {
      return peg$result;
    } else {
      if (peg$result !== peg$FAILED && peg$currPos < input.length) {
        peg$fail(peg$endExpectation());
      }

      throw peg$buildStructuredError(
        peg$maxFailExpected,
        peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
        peg$maxFailPos < input.length
          ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
          : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
      );
    }
  }

  return {
    SyntaxError: peg$SyntaxError,
    parse:       peg$parse
  };
})();
