import DynamicComponent from '../dynamic/dynamic-component'
import createEventForwarders from '../utils/create-event-forwarders'

import buildCompleteSearchString from '../utils/build-query-string'
import { isValidTypeOrNull } from '../utils/build-query-string'

function anyDocumentDifferent(documentsA, documentsB) {
  const changed = documentsA.length !== documentsB.length || 
                  documentsB.some((document, index) => document?.id !== documentsA[index]?.id || document?.v !== documentsA[index]?.v)

  return changed
} 

export default {
  /* tslint:disable */
  render() {
    const _vm = this
    const _h = _vm.$createElement
    const _c = _vm._self._c || _h

    return (_vm.setupDone && _vm.dynamicComponent) ? _c(_vm.dynamicComponent.id,
      {
        tag: 'component',
        key: this.componentKey,
        props: {
          params: _vm.params,
          list: _vm.dynamicComponent.document,
          documents: _vm.documentsToBindTo,
          rootDocument: _vm.rootDocument,
          queryDocument: _vm.queryDocument,
          searchOptions: _vm.combinedSearchOptions,
          filter: _vm.combinedFilter,
          suspended: _vm.listSuspended,
          suspendList: _vm.suspendList,
          unsuspendList: _vm.unsuspendList,
          style: 'width: 100%',
        },
        on: createEventForwarders(_vm._events, _vm)
      }) : _vm._e()
  },
  renderError(h, err) {
    return h('pre', { staticClass: 'ae-error', style: { color: 'red' } }, 'ae-list:' + this.listId + ' ' + err.stack)
  },
  staticRenderFns: [],
  /* tslint:enable */
  inject: ['getAeppicContext'],
  props: {
    listId: {
      type: String,
      required: true
    },
    params: Object,
    search: String,
    sort: null,
    searchOptions: Object,
    queryId: String,
    queryFilter: {
      type: String,
      validator: function (value) {
        console.warn('[ae-list-selector]: queryFilter is deprecated. use "filter" instead')
        return true
      }
    },
    filter: String,
    rootDocumentId: {
      type: String,
      default: 'root'
    },
    documents: Array,
    watchList: {
      type: Boolean,
      default: null,
    },
    watchListRevisions: {
      type: Boolean,
      default: null
    },
    watchDocuments: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      validator: isValidTypeOrNull
    },
    suspend: {
      type: Boolean,
      default: false
    },
  },
  data() {
    const Aeppic = this.getAeppicContext('ae-list-selector')

    const isDeveloper = Aeppic.Account && Aeppic.Account.data.allowDevMode

    const watch = typeof this.watchList === 'boolean' ? this.watchList : isDeveloper
    const watchRevisions = Aeppic.Developer.isServerInRemoteDeveloperMode ? false
      : typeof this.watchControlRevisions === 'boolean' ? this.watchControlRevisions
        : isDeveloper

    const dynamicComponent = new DynamicComponent('list', Aeppic, {
      formId: 'list-form',
      template: LIST_COMPONENT_TEMPLATE,
      exposedSymbols: 'locals, Aeppic, Math, DateTime, Anime, params, watchParam, $el, $refs, $listeners, list, documents, $emit, $on, rootDocument, setSorting, setSize, setSearchOptions, queryDocument, searchOptions, setFilter, filter, suspended, suspendList, unsuspendList',
      watch,
      watchRevisions
    })
 
    return {
      Aeppic,
      dynamicComponent,
      subscription: null,
      rootDocument: null,
      queryDocument: null,
      sortedDocuments: [],
      sortedWatchedDocuments: [],
      watchers: null,
      manualSearchSize: null,
      manualSearchSort: null,
      manualSearchOptions: null,
      manualFilter: null,
      subscriptionDocuments: [],
      setupDone: false,
      listSuspended: false,
    }
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  },
  async created() {
    this.componentKey = this.Aeppic.uuid()
    this.listSuspended = this.suspend || false

    this.dynamicComponent.on('invalidated', () => this.loadListComponent())

    const [queryDocument, rootDocument] = await this.Aeppic.getAll([this.queryId, this.rootDocumentId])
    
    this.queryDocument = queryDocument
    this.rootDocument = rootDocument
    
    await this.setupSearch()

    this.loadListComponent()

    this.setupDone = true
  },
  computed: {
    documentsToBindTo() {
      // Access subscription to correctly add dependency for reactivity
      const hasSubscription = !!this.subscription

      if (hasSubscription) {
        return this.subscriptionDocuments
      }

      if (this.documents && this.watchDocuments) {
        return this.sortedWatchedDocuments
      }

      // sortedDocuments depends on the active sortInfo, which might be none
      if (this.sortedDocuments.length) {
        return this.sortedDocuments
      }

      return []
    },
    combinedSearchOptions() {
      const sort = this.computedSearchSort
      const size = this.computedSearchSize
      const tag = this.listId

      // FIXME: sort: undefined will always override
      return { ...this.searchOptions, ...this.manualSearchOptions, ...{ sort, size, tag } }
    },
    computedSearchSize() {
      const calculatedSearchSize = (this.searchOptions && this.searchOptions.size) || (this.queryDocument && this.queryDocument.data.pageSize)
      const searchSize = this.manualSearchSize || calculatedSearchSize

      if (Number.isNaN(searchSize)) {
        return null
      }

      return searchSize
    },
    computedSearchSort() {
      return this.manualSearchSort || this.sort || null
    },
    combinedFilter() {
      if (this.filterOrQueryFilter && this.manualFilter) {
        return `${this.filterOrQueryFilter} AND ${this.manualFilter}`
      }

      return this.filterOrQueryFilter || this.manualFilter
    },
    filterOrQueryFilter() {
      return this.filter || this.queryFilter
    },
    completeSearchString() {
      return buildCompleteSearchString(this.search, this.combinedFilter, this.type, this.rootDocument, this.queryDocument)
    },
  },
  methods: {
    async setupSearch() {
      this.verifyProperties()
      
      if (this.documentsWatcher) {
        this.teardownDocumentsWatchers()
      }

      if (this.listSuspended) {
        return
      }

      if (!this.documents) {
        if (this.rootDocumentId) {
          this.subscription = this.Aeppic.updateSubscription(this.subscription, this.completeSearchString, this.combinedSearchOptions, (documents, info) => {
            this.subscriptionDocuments = documents

            this.emitDynamicComponent('ae-documents-changed', documents)
          })

          return
        }
      }

      if (this.subscription) {
        this.subscription.cancel()
      }

      this.subscription = null

      const newDocuments = []

      // TODO: allow documents filtering also if watchDocuments is false. 
      //        Also remove console.log which tells the user not to mix documents and filter
      if (this.documents) {
        if (!this.watchDocuments) {
          newDocuments.push(...this.documents)
        } else {
          const matchingDocumentsResolverPromises = []
    
          for (let i = 0; i < this.documents.length; i++) {
            const documentOrReference = this.documents[i]
    
            const resolveMatchingDocumentPromise = new Promise(async (resolve) => {
              const document = await this.Aeppic.get(documentOrReference)
    
              if (!document) {
                // NOTE: A match cannot be determined without accessing the document. It might be deleted or locked without access. Current behavior is to render it anyway.
                resolve(documentOrReference)
                return
              }
    
              const matches = await this.Aeppic.match(documentOrReference, this.combinedFilter, this.combinedSearchOptions)
    
              if (matches) {
                resolve(document)
                return
              }
    
              resolve(null)
            })
    
            matchingDocumentsResolverPromises.push(resolveMatchingDocumentPromise)
          }
    
          const matchingDocuments = await Promise.all(matchingDocumentsResolverPromises)
          
          newDocuments.push(...matchingDocuments)
        }
      }
      
      const sortedDocuments = this.sortDocuments(newDocuments.filter(d => d != null))

      // check if new documents changed and only then emit the 'ae-documents-changed' event
      const changed = anyDocumentDifferent(this.documentsToBindTo, sortedDocuments)

      if (!changed) {
        return
      }

      if (!this.watchDocuments) {
        this.sortedDocuments = sortedDocuments
      } else {
        this.sortedWatchedDocuments = sortedDocuments

        this.setupDocumentsWatchers()
      }
      
      if (this.setupDone) {
        this.emitDynamicComponent('ae-documents-changed', sortedDocuments)
      }
    },
    setupDocumentsWatchers() {
      this.teardownDocumentsWatchers()

      if (!this.sortedWatchedDocuments.length) {
        return
      }

      const documentIds = this.sortedWatchedDocuments.filter(d => !!d).map(d => d.id)
      const query = this.Aeppic.Query.global().where('id').isOneOf(documentIds)

      this.documentsWatcher = this.Aeppic.subscribe(query, (documents) => {
        if (!anyDocumentDifferent(this.documentsToBindTo, documents)) {
          return
        }

        this.setupSearch()
      })
    },
    teardownDocumentsWatchers() {
      if (this.documentsWatcher) {
        this.documentsWatcher.cancel()
        this.documentsWatcher = null
      }
    },
    sortDocuments(documents) {
      if (!documents) {
        return []
      }

      return this.Aeppic.sort(documents, this.combinedSearchOptions.sort)
    },
    verifyProperties() {
      /* tslint:disable:no-console */
      if (this.search && (this.queryId || this.documents) ||
        this.queryId && (this.search || this.documents) ||
        this.documents && (this.search || this.queryId)) {
        console.warn(`[WARN]: Invalid prop: Do not mix "search", "queryId" and/or "documents".`)
      }
      if (this.documents && this.filter) {
        console.warn('[WARN]: Invalid prop: Do not mix "documents" and "filter"')
      }
      if (this.queryFilter && !this.queryId) {
        console.warn(`[WARN]: Invalid prop: "queryFilter" requires "queryId".`)
      }
      /* tslint:enable:no-console */
    },
    async loadListComponent() {
      if (this.listId) {
        const listDocument = await this.Aeppic.get(this.listId)
        this.dynamicComponent.refresh(listDocument)
      } else {
        this.dynamicComponent.reset()
      }
    },
    emitDynamicComponent(name, value) {
      this.$emit(name, value)

      // FIXME: somehow this is not needed anymore. With using this, the event is emitted twice!
      //   // children[0] === dynamicComponent
      //   if (this.$children[0]) {
      //     // side-effect: this.$emit is called by eventForwarder applied to dynamicComponent
      //     this.$children[0].$emit(name, value)
      //   }
    },
    suspendList() {
      this.listSuspended = true
    },
    unsuspendList() {
      this.listSuspended = false
    },
  },
  beforeDestroy() {
    this.dynamicComponent.destroy()
    this.Aeppic.release()
  },
  watch: {
    listId() {
      this.loadListComponent()
    },
    suspend(isSuspended) {
      if (isSuspended === this.listSuspended) {
        return
      }

      if (isSuspended) {
        this.suspendList()
      } else {
        this.unsuspendList()
      }
    },
    listSuspended() {
      this.setupSearch()
    },
    watchDocuments() {
      this.setupSearch()
    },
    search() {
      this.setupSearch()
    },
    async queryId(newValue) {
      this.queryDocument = await this.Aeppic.get(newValue)

      this.setupSearch()
    },
    queryFilter() {
      this.setupSearch()
    },
    async rootDocumentId(newValue) {
      this.rootDocument = await this.Aeppic.get(newValue)

      this.setupSearch()
    },
    combinedSearchOptions(value) {
      this.setupSearch()

      this.emitDynamicComponent('ae-search-changed', value)
    },
    combinedFilter(value) {
      this.setupSearch()

      this.emitDynamicComponent('ae-filter-changed', value)
    },
    documents(value) {
      this.setupSearch()
    },
  }
}

// FIXME: add $on-update event for controller after subscription change
const LIST_COMPONENT_TEMPLATE = `{
  template: __TEMPLATE__,
  inject: ['getAeppicContext'],
  props: ['params', 'list', 'documents', 'rootDocument', 'queryDocument', 'searchOptions', 'filter', 'suspended', 'suspendList', 'unsuspendList',],
  methods: {
    __METHODS__,
    translate(...args) {
      return this.Aeppic.translate(...args)
    },
    watchParam(name, cb, options) {
      if (!name) {
        return
      }

      let lastValue = undefined

      const cbWithPreCheck = (newValue, oldValue) => {
        if (Array.isArray(newValue) || lastValue !== newValue) {
          cb(newValue, oldValue)
        }

        lastValue = newValue
      }
      
      return this.$watch('params.' + name, cbWithPreCheck, options)
    }
  },
  beforeCreate() {
    this.setSearchSize = (newValue) => {
      this.$parent.manualSearchSize = newValue
    }

    this.setSearchSort = (newValue) => {
      this.$parent.manualSearchSort = newValue
    }

    this.setSearchOptions = (newValue) => {
      this.$parent.manualSearchOptions = newValue
    }

    this.setFilter = (newValue) => {
      this.$parent.manualFilter = newValue
    }
  },
  data() {
    const Aeppic = this.getAeppicContext('ae-list')

    const self = this
    const locals = __NEW_LOCALS__
    const handlers = []

    const controllerVariables = {
      locals,
      Aeppic,
      get DateTime() { return Aeppic.DateTime },
      get Math() { return Aeppic.Math },
      get Anime() { return Aeppic.Anime },
      get params() { return self.params },
      get documents() { return self.documents },
      get list() { return self.list },
      get rootDocument() { return self.rootDocument },
      get queryDocument() { return self.queryDocument },
      get searchOptions() { return self.searchOptions },
      get filter() { return self.filter },
      get $el() { return self.$el },
      get $refs() { return self.$refs },
      $listeners: self.$listeners,
      $emit(...args) { return self.$emit(...args) },
      $on(...args) { 
        self.$parent.$on(...args)
        self.$on(...args)

        handlers.push(args)
      },
      watchParam(...args) { return self.watchParam(...args) },
      setSize(value) { self.setSearchSize(value) },
      setSorting(value) { self.setSearchSort(value) },
      setSearchOptions(value) { self.setSearchOptions(value) },
      setFilter(value) { self.setFilter(value) },
      suspendList() { self.suspendList() },
      unsuspendList() { self.unsuspendList() },
      get suspended() { return self.suspended }
    }

    const controller = controllerClass ? new controllerClass(controllerVariables) : null

    return {
      Aeppic,
      controller,
      locals,
      packages,
      handlers
    }
  },
  mounted() {
    this.Aeppic.setContextRootElement(this.$el)
  },
  computed: {
    Math() { return this.Aeppic.Math },
    DateTime() { return this.Aeppic.DateTime },
    Anime() { return this.Aeppic.Anime },
  },
  beforeDestroy() {
    __DESTROY_CONTROLLER__
   
    for (const handlerArgs of this.handlers) {
      this.$off(...handlerArgs) // remove all controller registered listeners (own scope)
      this.$parent.$off(...handlerArgs) // remove all controller registered listeners (parent scope)
    }

    this.handlers = []

    this.Aeppic.release()
    this.Aeppic = null
    this.packages = null
  },
  components
}`
