import { buildDeferred, Deferred } from './defer'
import { setIntervalNoKeepAlive } from './timer'
import { waitForNextTick } from './wait-for-tick'

export interface BatchProcessingAction<T> {
  (batch: T[]): Promise<any>
}

export interface KeyLookup<T> {
  (item: T): string
}

export interface FilterCondition<T> {
  (item: T): boolean
}

export type ItemProcessingQueueOptions<T> = {
  keyLookup: KeyLookup<T>
  maxProcessingBatchSize?: number
  itemExcludeCondition?: FilterCondition<T>

  /** Minimum of 50 by default */
  minTimeBetweenProcessingBatchesInMs?: number,
}

export type ProcessAsUniqueItemsByKey<T> = {
  itemProcessingAction: BatchProcessingAction<T>
}

export type ProcessInGroupsByKey<T> = {
  groupProcessingAction: BatchProcessingAction<T[]>
}

export type UniqueItemProcessingQueueOptions<T> = 
  (ProcessInGroupsByKey<T> | ProcessAsUniqueItemsByKey<T>)
  & ItemProcessingQueueOptions<T>

export class UniqueItemProcessingQueue<T> {
  private _queue = new Map<string, T|T[]>()
  private _queueProcessingDone = buildDeferred<void>()

  private _minTimeBetweenProcessingBatchesInMs: number
  private _maxProcessingBatchSize: number

  private _action: BatchProcessingAction<T|T[]>

  private _itemExcludeCondition: FilterCondition<T>
  private _keyLookup: KeyLookup<T>
  private _groupByKey: boolean
  
  private _isProcessing = false
  private _lastImport = 0
  private _nextProcessingScheduled = null

  private _name: string

  constructor(name: string, options: UniqueItemProcessingQueueOptions<T>) {
    this._name = name
    
    if ('itemProcessingAction' in options) {
      this._groupByKey = false
      this._action = options.itemProcessingAction
    } else if ('groupProcessingAction' in options) {
      this._groupByKey = true
      this._action = options.groupProcessingAction
    } else {
      throw new Error('Options missing processing action')
    }

    this._itemExcludeCondition = options.itemExcludeCondition
    this._keyLookup = options.keyLookup
    
    this._minTimeBetweenProcessingBatchesInMs = Math.max(options.minTimeBetweenProcessingBatchesInMs ?? 0, 50)
    this._maxProcessingBatchSize = options.maxProcessingBatchSize ?? 100

    setIntervalNoKeepAlive(() => {
      this._performHealthCheck()
    }, this._minTimeBetweenProcessingBatchesInMs * 1.5)
  }

  _performHealthCheck() {
    if (this._isProcessing) {
      const timeSinceStart = Date.now() - this._lastImport
      
      if (timeSinceStart > this._minTimeBetweenProcessingBatchesInMs * 10) {
        console.warn(`Queue '${this._name}' not healthy. Started processing at ${timeSinceStart}. #${this._queue.size} pending items`)
      }
    }
  }
  
  enqueue(itemOrItems: T|T[]): Promise<void> {
    if (Array.isArray(itemOrItems)) {
      return this._queueForProcessing(itemOrItems)
    } else {
      return this._queueForProcessing([itemOrItems])
    }
  }

  _queueForProcessing(items: T[]): Promise<void> {
    let enqueuedItems = false

    for (const item of items) {
      if (this._itemExcludeCondition?.(item)) {
        continue
      }
      
      const key = this._keyLookup(item)

      if (this._groupByKey) {
        const existingItems = this._queue.get(key) as T[]

        if (existingItems) {
          existingItems.push(item)
        } else {
          this._queue.set(key, [ item ])
        }
      } else {
        this._queue.set(key, item)
      }

      enqueuedItems = true
    }

    if (!enqueuedItems) {
      return Promise.resolve()
    }

    const { promise } = this._queueProcessingDone
    this._process()   
    return promise
  }

  async _process() {
    if (this._isProcessing || this._queue.size === 0) {
      return
    }

    const requiresProcessingNow = this._queue.size >= this._maxProcessingBatchSize
    
    if (this._nextProcessingScheduled) {
      if (requiresProcessingNow) {
        clearTimeout(this._nextProcessingScheduled)
        this._nextProcessingScheduled = null
      } else  {
        return
      }
    }

    const timeSinceLastProcessing = Math.min(Date.now() - this._lastImport, this._minTimeBetweenProcessingBatchesInMs)

    if (!requiresProcessingNow && timeSinceLastProcessing < this._minTimeBetweenProcessingBatchesInMs) {
      const optimalWaitTime = this._minTimeBetweenProcessingBatchesInMs - timeSinceLastProcessing

      this._nextProcessingScheduled = setTimeout(() => {
        this._nextProcessingScheduled = null
        this._process()
      }, optimalWaitTime)

      if (typeof this._nextProcessingScheduled === 'object') {
        this._nextProcessingScheduled.unref()
      }
      
      console.warn('Delaying next processing batch for', optimalWaitTime, 'ms')
      return
    }

    const items = Array.from(this._queue.values())
    this._queue.clear()

    const queueProcessed = this._queueProcessingDone
    this._queueProcessingDone = buildDeferred()

    try {
      this._lastImport = Date.now()
      const nonExcludedItems = this._filterItems(items)

      if (nonExcludedItems.length > 0) {
        this._isProcessing = true
        await this._action(nonExcludedItems)
      }
    } catch (error) {
      console.error('Error processing queue', error)
    } finally {
      this._isProcessing = false
      queueProcessed.resolve()

      waitForNextTick().then(() => this._process())
    }
  }

  private _filterItems(items: (T|T[])[]): (T|T[])[] {
    if (!this._itemExcludeCondition) {
      return items
    }

    if (this._groupByKey) {
      const nonExcludedItemGroups: T[][] = []

      for (const itemGroup of items as T[][]) {
        const nonExcludedGroup = []

        for (const item of itemGroup) {
          const excluded = this._itemExcludeCondition?.(item) === true

          if (!excluded) {
            nonExcludedGroup.push(item)
          }
        }

        if (nonExcludedGroup.length > 0) {
          nonExcludedItemGroups.push(nonExcludedGroup)
        }
      }

      return nonExcludedItemGroups
    } else {
      return (items as T[]).filter(item => this._itemExcludeCondition?.(item) === true ? false : true)
    }
  }
}
